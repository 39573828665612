import GetProductsUseCase from "../domain/use-cases/products/GetProductsUseCase"
import DiModule from "../../../../sqadmin/lib/di/DiModule"
import ProductsCoreDataDiModule from "./ProductsCoreDataDiModule"
import GetProductUseCase from "../domain/use-cases/products/GetProductUseCase"
import CreateProductUseCase from "../domain/use-cases/products/CreateProductUseCase"
import UpdateProductUseCase from "../domain/use-cases/products/UpdateProductUseCase"
import DestroyProductUseCase from "../domain/use-cases/products/DestroyProductUseCase"
import CreateProductImageUseCase from "../domain/use-cases/images/CreateProductImageUseCase"

export default interface ProductsCoreDomainDiModule {
  provideGetProductsUseCase(): GetProductsUseCase

  provideGetProductUseCase(): GetProductUseCase

  provideCreateProductUseCase(): CreateProductUseCase

  provideUpdateProductUseCase(): UpdateProductUseCase

  provideDestroyProductUseCase(): DestroyProductUseCase

  provideCreateImageUseCase(): CreateProductImageUseCase
}

export class DefaultProductsCoreDomainDiModule extends DiModule implements ProductsCoreDomainDiModule {
  private readonly productsCoreDataDiModule: ProductsCoreDataDiModule

  constructor(parameters: {
    readonly productsCoreDataDiModule: ProductsCoreDataDiModule
  }) {
    super()
    this.productsCoreDataDiModule = parameters.productsCoreDataDiModule
  }

  provideCreateImageUseCase(): CreateProductImageUseCase {
    return this.single(
      CreateProductImageUseCase.name,
      () => new CreateProductImageUseCase({
        productImagesRepository: this.productsCoreDataDiModule.provideProductImagesRepository()
      })
    )
  }

  provideGetProductsUseCase(): GetProductsUseCase {
    return this.single(
      GetProductsUseCase.name,
      () => new GetProductsUseCase({
        productsRepository: this.productsCoreDataDiModule.provideProductsRepository()
      })
    )
  }

  provideGetProductUseCase(): GetProductUseCase {
    return this.single(
      GetProductUseCase.name,
      () => new GetProductUseCase({
        productsRepository: this.productsCoreDataDiModule.provideProductsRepository()
      })
    )
  }

  provideCreateProductUseCase(): CreateProductUseCase {
    return this.single(
      CreateProductUseCase.name,
      () => new CreateProductUseCase({
        productsRepository: this.productsCoreDataDiModule.provideProductsRepository()
      })
    )
  }

  provideUpdateProductUseCase(): UpdateProductUseCase {
    return this.single(
      UpdateProductUseCase.name,
      () => new UpdateProductUseCase({
        productsRepository: this.productsCoreDataDiModule.provideProductsRepository()
      })
    )
  }

  provideDestroyProductUseCase(): DestroyProductUseCase {
    return this.single(
      DestroyProductUseCase.name,
      () => new DestroyProductUseCase({
        productsRepository: this.productsCoreDataDiModule.provideProductsRepository()
      })
    )
  }
}
