import { UpdateObjectResult } from "../../../../../../sqadmin/features/objects/domain/results/UpdateObjectResult"
import OrdersRepository from "../../repositories/OrdersRepository"
import Order from "../../../../../core/domain/entities/orders/Order"
import OrderError from "../../../../../core/domain/entities/orders/OrderError"

export default class UpdateOrderUseCase {
  private readonly ordersRepository: OrdersRepository

  constructor(parameters: {
    readonly ordersRepository: OrdersRepository
  }) {
    this.ordersRepository = parameters.ordersRepository
  }

  async call(
    parameters: UpdateOrderParameters
  ): Promise<UpdateObjectResult<Order, OrderError>> {
    return await this.ordersRepository.updateOrder(parameters)
  }
}

export interface UpdateOrderParameters {
  readonly orderId: number
  readonly order: Order
}
