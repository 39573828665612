import { Expose, Type } from "class-transformer"
import PlaceErrorsObject from "../../../domain/entities/places/PlaceErrorsObject"
import NetworkPlaceErrorsObjectAttributes from "./NetworkPlaceErrorsObjectAttributes"
import NetworkGeoLocationErrorsObject from "../geo-locations/NetworkGeoLocationErrorsObject"

export default class NetworkPlaceErrorsObject implements PlaceErrorsObject {
  @Expose()
  @Type(() => NetworkPlaceErrorsObjectAttributes)
  readonly attributes: NetworkPlaceErrorsObjectAttributes | null | undefined

  @Expose()
  @Type(() => NetworkGeoLocationErrorsObject)
  readonly geoLocation: NetworkGeoLocationErrorsObject | null | undefined
}
