import NetworkOrderComment from "../entities/order-comments/NetworkOrderComment"
import OrderComment from "../../domain/entities/order-comments/OrderComment"

export default class OrderCommentsMapper {
  mapNetworkToDomain({
    orderComment
  }: {
    readonly orderComment: NetworkOrderComment
  }): OrderComment {
    return {
      id: orderComment.id,
      value: orderComment.value
    }
  }

  mapDomainToNetwork({
    orderComment
  }: {
    readonly orderComment: OrderComment
  }): NetworkOrderComment {
    return new NetworkOrderComment({
      id: orderComment.id,
      value: orderComment.value
    })
  }
}
