import OrderProcessingStatusesRepository from "../../repositories/OrderProcessingStatusesRepository"
import { GetObjectsPageResult } from "../../../../../../sqadmin/features/objects/domain/results/GetObjectsPageResult"
import OrderProcessingStatus from "../../../../../core/domain/entities/order-processing-statuses/OrderProcessingStatus"
import OrderProcessingStatusesFilter from "../../../../../core/domain/entities/order-processing-statuses/OrderProcessingStatusesFilter"
import Sort from "../../../../../../sqadmin/features/objects/presentation/entities/tables/Sort"
import LastItemPagination from "../../../../../core/domain/entities/paginations/LastItemPagination"

export default class GetOrderProcessingStatusesUseCase {
  private readonly orderProcessingStatusesRepository: OrderProcessingStatusesRepository

  constructor(parameters: {
    readonly orderProcessingStatusesRepository: OrderProcessingStatusesRepository
  }) {
    this.orderProcessingStatusesRepository = parameters.orderProcessingStatusesRepository
  }

  async call(parameters: GetOrderProcessingStatusesParameters): Promise<GetObjectsPageResult<OrderProcessingStatus>> {
    return this.orderProcessingStatusesRepository.getOrderProcessingStatuses(parameters)
  }
}

export interface GetOrderProcessingStatusesParameters {
  readonly filter?: OrderProcessingStatusesFilter
  readonly sort?: Sort
  readonly pagination?: LastItemPagination
}
