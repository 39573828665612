import { DestroyObjectResult } from "../../../../../sqadmin/features/objects/domain/results/DestroyObjectResult"
import OptionError from "../../../../core/domain/entities/options/OptionError"
import OptionsRepository from "../repositories/OptionsRepository"

export default class DestroyOptionUseCase {
  private readonly optionsRepository: OptionsRepository

  constructor(parameters: {
    readonly optionsRepository: OptionsRepository
  }) {
    this.optionsRepository = parameters.optionsRepository
  }

  async call(parameters: {
    readonly optionId: number
  }): Promise<DestroyObjectResult<OptionError>> {
    return await this.optionsRepository.destroyOption(parameters)
  }
}
