import React from "react"
import AppDateRangePickerComponent
  from "../../../../../../core/presentation/components/app-date-range-picker/AppDateRangePickerComponent"
import {
  DateRangeFormFieldViewState
} from "../../../entities/form-fields/form-field-by-type/DateRangeFormField"
import { DateRange } from "../../../../../../lib/date-range/DateRange"

export default function DateRangeFormFieldComponent({
  fieldViewState,
  isDisabled
}: {
  readonly fieldViewState: DateRangeFormFieldViewState
  readonly isDisabled: boolean
}) {
  function handleOnChange(dateRange?: DateRange | null) {
    fieldViewState.onChange(dateRange)
  }

  return (
    <AppDateRangePickerComponent
      dateRange={fieldViewState.value ?? null}
      disabled={isDisabled}
      onDateRangeChanged={handleOnChange}
    />
  )
}
