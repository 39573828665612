import { Expose, Type } from "class-transformer"
import NetworkBonusPointsRule from "../bonus-points-rules/NetworkBonusPointsRule"

export default class NetworkBonusProgramRule {
  @Expose()
  readonly id: number | null | undefined

  @Expose()
  @Type(() => NetworkBonusPointsRule)
  readonly bonusPointsRules: NetworkBonusPointsRule[] | null | undefined

  constructor(parameters?: {
    readonly id: number | null | undefined
    readonly bonusPointsRules: NetworkBonusPointsRule[] | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.id = parameters.id
    this.bonusPointsRules = parameters.bonusPointsRules
  }
}
