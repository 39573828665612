import React from "react"
import DiModule from "../../../../../sqadmin/lib/di/DiModule"
import ObjectsDomainDiModule from "../../../../../sqadmin/features/objects/di/modules/ObjectsDomainDiModule"
import CoreI18nDiModule from "../../../../core/di/modules/CoreI18nDiModule"
import autoBind from "auto-bind"
import ObjectsPresentationLogicParameters
  from "../../../../../sqadmin/features/objects/presentation/entities/objects/ObjectsPresentationLogicParameters"
import OrderReceivingMethodsPage from "../../presentation/pages/order-receiving-methods/OrderReceivingMethodsPage"
import OrderReceivingMethodPage from "../../presentation/pages/order-receiving-method/OrderReceivingMethodPage"
import OrderReceivingMethodsViewModel
  from "../../presentation/pages/order-receiving-methods/OrderReceivingMethodsViewModel"
import OrderReceivingMethodViewModel
  from "../../presentation/pages/order-receiving-method/OrderReceivingMethodViewModel"
import OrderReceivingMethodsCoreDomainDiModule
  from "../../../order-receiving-methods-core/di/OrderReceivingMethodsCoreDomainDiModule"
import SettlementsCoreDomainDiModule from "../../../settlements-core/di/SettlementsCoreDomainDiModule"

export default interface OrderReceivingMethodsPresentationDiModule {
  provideOrderReceivingMethodsPage(): React.ReactNode

  provideOrderReceivingMethodPage(): React.ReactNode
}

export class DefaultOrderReceivingMethodsPresentationDiModule
  extends DiModule
  implements OrderReceivingMethodsPresentationDiModule {

  private readonly objectsDomainDiModule: ObjectsDomainDiModule
  private readonly orderReceivingMethodsCoreDomainDiModule: OrderReceivingMethodsCoreDomainDiModule
  private readonly settlementsCoreDomainDiModule: SettlementsCoreDomainDiModule
  private readonly coreI18nDiModule: CoreI18nDiModule

  constructor(parameters: {
    readonly objectsDomainDiModule: ObjectsDomainDiModule
    readonly orderReceivingMethodsCoreDomainDiModule: OrderReceivingMethodsCoreDomainDiModule
    readonly settlementsCoreDomainDiModule: SettlementsCoreDomainDiModule
    readonly coreI18nDiModule: CoreI18nDiModule
  }) {
    super()
    autoBind(this)
    this.objectsDomainDiModule = parameters.objectsDomainDiModule
    this.orderReceivingMethodsCoreDomainDiModule = parameters.orderReceivingMethodsCoreDomainDiModule
    this.settlementsCoreDomainDiModule = parameters.settlementsCoreDomainDiModule
    this.coreI18nDiModule = parameters.coreI18nDiModule
  }

  provideOrderReceivingMethodsPage(): React.ReactNode {
    return <OrderReceivingMethodsPage provideViewModel={this.provideOrderReceivingMethodsViewModel} />
  }

  provideOrderReceivingMethodPage(): React.ReactNode {
    return <OrderReceivingMethodPage provideViewModel={this.provideOrderReceivingMethodViewModel} />
  }

  private provideOrderReceivingMethodsViewModel({
    objectsPresentationLogicParameters
  }: {
    readonly objectsPresentationLogicParameters: ObjectsPresentationLogicParameters
  }): OrderReceivingMethodsViewModel {
    return new OrderReceivingMethodsViewModel({
      coreI18n: this.coreI18nDiModule.provideCoreI18n(),
      objectsPresentationLogicParameters,
      subscribeToObjectsEventsUseCase: this.objectsDomainDiModule.provideSubscribeToObjectsEventsUseCase({
        key: "orderReceivingMethods"
      }),
      unsubscribeFromObjectsEventsUseCase: this.objectsDomainDiModule.provideUnsubscribeFromObjectsEventsUseCase({
        key: "orderReceivingMethods"
      }),
      getOrderReceivingMethodsUseCase: this.orderReceivingMethodsCoreDomainDiModule
        .provideGetOrderReceivingMethodsUseCase()
    })
  }

  private provideOrderReceivingMethodViewModel({
    orderReceivingMethodId
  }: {
    readonly orderReceivingMethodId?: number
  }): OrderReceivingMethodViewModel {
    return new OrderReceivingMethodViewModel({
      coreI18n: this.coreI18nDiModule.provideCoreI18n(),
      broadcastObjectsEventUseCase: this.objectsDomainDiModule.provideBroadcastObjectsEventUseCase({
        key: "orderReceivingMethods"
      }),
      getOrderReceivingMethodUseCase: this.orderReceivingMethodsCoreDomainDiModule
        .provideGetOrderReceivingMethodUseCase(),
      createOrderReceivingMethodUseCase: this.orderReceivingMethodsCoreDomainDiModule
        .provideCreateOrderReceivingMethodUseCase(),
      updateOrderReceivingMethodUseCase: this.orderReceivingMethodsCoreDomainDiModule
        .provideUpdateOrderReceivingMethodUseCase(),
      destroyOrderReceivingMethodUseCase: this.orderReceivingMethodsCoreDomainDiModule
        .provideDestroyOrderReceivingMethodUseCase(),
      getSettlementsUseCase: this.settlementsCoreDomainDiModule.provideGetSettlementsUseCase(),
      getOrderShippingTariffsUseCase: this.orderReceivingMethodsCoreDomainDiModule
        .provideGetOrderShippingTariffsUseCase(),
      orderReceivingMethodId: orderReceivingMethodId
    })
  }
}
