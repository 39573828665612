import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import { useCoreTextProvider } from "../../../../../core/presentation/contexts/CoreTextProviderContext"
import { useNumberObjectId } from "../../../../../../sqadmin/features/objects/presentation/hooks/useObjectId"
import { useViewModel } from "../../../../../../sqadmin/lib/view-model/ViewModel"
import ObjectComponent from "../../../../../../sqadmin/features/objects/presentation/components/object/ObjectComponent"
import React from "react"
import PaymentStatusViewModel from "./PaymentStatusViewModel"

export interface PaymentStatusPageProps {
  readonly provideViewModel: (
    parameters: { readonly paymentStatusId?: number }
  ) => PaymentStatusViewModel
}

export default function PaymentStatusPage({
  provideViewModel
}: PaymentStatusPageProps) {
  const coreTextProvider: CoreTextProvider = useCoreTextProvider()
  const paymentStatusId: number | undefined = useNumberObjectId()

  const viewModel: PaymentStatusViewModel = useViewModel(() => provideViewModel({
    paymentStatusId: paymentStatusId
  }))

  return (
    <ObjectComponent
      newObjectTitle={coreTextProvider.newPaymentStatus()}
      existedObjectTitle={coreTextProvider.paymentStatus()}
      observableObjectViewState={viewModel.observableObjectViewState}
      onObjectViewEvent={viewModel.onViewObjectEvent}
    />
  )
}
