import React from "react"
import DiModule from "../../../../../sqadmin/lib/di/DiModule"
import ObjectsPresentationLogicParameters
  from "../../../../../sqadmin/features/objects/presentation/entities/objects/ObjectsPresentationLogicParameters"
import OptionsViewModel from "../../presentation/pages/options-page/OptionsViewModel"
import ObjectsDomainDiModule from "../../../../../sqadmin/features/objects/di/modules/ObjectsDomainDiModule"
import autoBind from "auto-bind"
import OptionsDomainDiModule from "./OptionsDomainDiModule"
import OptionsPage from "../../presentation/pages/options-page/OptionsPage"
import OptionViewModel from "../../presentation/pages/option-page/OptionViewModel"
import OptionPage from "../../presentation/pages/option-page/OptionPage"
import CoreI18nDiModule from "../../../../core/di/modules/CoreI18nDiModule"

export default interface OptionsPresentationDiModule {
  provideOptionsPage(): React.ReactNode

  provideOptionPage(): React.ReactNode
}

export class DefaultOptionsPresentationDiModule
  extends DiModule
  implements OptionsPresentationDiModule {

  private readonly objectsDomainDiModule: ObjectsDomainDiModule
  private readonly optionsDomainDiModule: OptionsDomainDiModule
  private readonly coreI18nDiModule: CoreI18nDiModule

  constructor(parameters: {
    readonly objectsDomainDiModule: ObjectsDomainDiModule
    readonly optionsDomainDiModule: OptionsDomainDiModule
    readonly coreI18nDiModule: CoreI18nDiModule
  }) {
    super()
    autoBind(this)
    this.objectsDomainDiModule = parameters.objectsDomainDiModule
    this.optionsDomainDiModule = parameters.optionsDomainDiModule
    this.coreI18nDiModule = parameters.coreI18nDiModule
  }

  provideOptionsPage(): React.ReactNode {
    return <OptionsPage provideViewModel={this.provideOptionsViewModel} />
  }

  provideOptionPage(): React.ReactNode {
    return <OptionPage provideViewModel={this.provideOptionViewModel} />
  }

  private provideOptionsViewModel({
    objectsPresentationLogicParameters
  }: {
    readonly objectsPresentationLogicParameters: ObjectsPresentationLogicParameters
  }): OptionsViewModel {
    return new OptionsViewModel({
      objectsPresentationLogicParameters,
      coreI18n: this.coreI18nDiModule.provideCoreI18n(),
      subscribeToObjectsEventsUseCase: this.objectsDomainDiModule.provideSubscribeToObjectsEventsUseCase({
        key: "options"
      }),
      unsubscribeFromObjectsEventsUseCase: this.objectsDomainDiModule.provideUnsubscribeFromObjectsEventsUseCase({
        key: "options"
      }),
      getOptionsUseCase: this.optionsDomainDiModule.provideGetOptionsUseCase()
    })
  }

  private provideOptionViewModel({
    optionId
  }: {
    readonly optionId?: number
  }): OptionViewModel {
    return new OptionViewModel({
      coreI18n: this.coreI18nDiModule.provideCoreI18n(),
      broadcastObjectsEventUseCase: this.objectsDomainDiModule.provideBroadcastObjectsEventUseCase({
        key: "options"
      }),
      getOptionUseCase: this.optionsDomainDiModule.provideGetOptionUseCase(),
      createOptionUseCase: this.optionsDomainDiModule.provideCreateOptionUseCase(),
      updateOptionUseCase: this.optionsDomainDiModule.provideUpdateOptionUseCase(),
      destroyOptionUseCase: this.optionsDomainDiModule.provideDestroyOptionUseCase(),
      optionId
    })
  }
}
