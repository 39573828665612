import { Expose, Type } from "class-transformer"
import BonusPointsRuleErrorsObject from "../../../domain/entities/bonus-points-rules/BonusPointsRuleErrorsObject"
import NetworkBonusPointsRuleErrorsObjectAttributes from "./NetworkBonusPointsRuleErrorsObjectAttributes"
import NetworkCalculatorErrorsObject from "../calculators/NetworkCalculatorErrorsObject"

export default class NetworkBonusPointsRuleErrorsObject implements BonusPointsRuleErrorsObject {

  @Expose()
  @Type(() => NetworkBonusPointsRuleErrorsObjectAttributes)
  readonly attributes: NetworkBonusPointsRuleErrorsObjectAttributes | null | undefined

  @Expose()
  readonly clientId: string | null | undefined

  @Expose()
  @Type(() => NetworkCalculatorErrorsObject)
  readonly calculator: NetworkCalculatorErrorsObject | null | undefined
}
