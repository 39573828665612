import { Expose, Type } from "class-transformer"
import LinkErrorsObject from "../../../domain/entities/links/LinkErrorsObject"
import NetworkLinkErrorsObjectAttributes from "./NetworkLinkErrorsObjectAttributes"

export default class NetworkLinkErrorsObject implements LinkErrorsObject {
  @Expose()
  readonly clientId: string | null | undefined

  @Expose()
  @Type(() => NetworkLinkErrorsObjectAttributes)
  readonly attributes: NetworkLinkErrorsObjectAttributes | null | undefined
}
