import { Expose, Type } from "class-transformer"
import BlockProductCategoryErrorsObject
  from "../../../domain/entities/block-product-categories/BlockProductCategoryErrorsObject"
import NetworkBlockProductCategoryErrorsObjectAttributes from "./NetworkBlockProductCategoryErrorsObjectAttributes"

export default class NetworkBlockProductCategoryErrorsObject implements BlockProductCategoryErrorsObject {
  @Expose()
  @Type(() => NetworkBlockProductCategoryErrorsObjectAttributes)
  readonly attributes: NetworkBlockProductCategoryErrorsObjectAttributes | null | undefined

  @Expose()
  readonly clientId: string | null | undefined
}
