import ViewModel from "../../../../../../sqadmin/lib/view-model/ViewModel"
import SubscribeToObjectsEventsUseCase
  from "../../../../../../sqadmin/features/objects/domain/use-cases/objects/SubscribeToObjectsEventsUseCase"
import UnsubscribeFromObjectsEventsUseCase
  from "../../../../../../sqadmin/features/objects/domain/use-cases/objects/UnsubscribeFromObjectsEventsUseCase"
import ObjectsPresentationLogicParameters
  from "../../../../../../sqadmin/features/objects/presentation/entities/objects/ObjectsPresentationLogicParameters"
import ObjectsPresentationLogic
  from "../../../../../../sqadmin/features/objects/presentation/presentation-logics/ObjectsPresentationLogic"
import { StateObservable } from "../../../../../../sqadmin/lib/view-model/StateObservable"
import {
  ObjectsPageViewState
} from "../../../../../../sqadmin/features/objects/presentation/view-states/ObjectsViewState"
import { ActionObservable } from "../../../../../../sqadmin/lib/view-model/ActionObservable"
import {
  ObjectsViewAction
} from "../../../../../../sqadmin/features/objects/presentation/view-actions/ObjectsViewAction"
import autoBind from "auto-bind"
import ObjectsViewEvent from "../../../../../../sqadmin/features/objects/presentation/view-events/ObjectsViewEvent"
import TableColumn from "../../../../../../sqadmin/features/objects/presentation/entities/tables/TableColumn"
import NumberTableValue
  from "../../../../../../sqadmin/features/objects/presentation/entities/tables/table-value-by-type/NumberTableValue"
import TextTableValue
  from "../../../../../../sqadmin/features/objects/presentation/entities/tables/table-value-by-type/TextTableValue"
import CoreI18n from "../../../../../core/i18n/CoreI18n"
import GetPaymentStatusesUseCase from "../../../../payment-statuses-core/domain/use-cases/GetPaymentStatusesUseCase"
import PaymentStatus from "../../../../../core/domain/entities/payment-statuses/PaymentStatus"
import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"

export default class PaymentStatusesViewModel extends ViewModel {
  private readonly coreI18n: CoreI18n
  private readonly subscribeToObjectsEventsUseCase: SubscribeToObjectsEventsUseCase
  private readonly unsubscribeFromObjectsEventsUseCase: UnsubscribeFromObjectsEventsUseCase
  private readonly getPaymentStatusesUseCase: GetPaymentStatusesUseCase
  private readonly objectsPresentationLogicParameters: ObjectsPresentationLogicParameters
  private readonly objectsPresentationLogic: ObjectsPresentationLogic<PaymentStatus>

  readonly observableObjectsPageViewState: StateObservable<ObjectsPageViewState<PaymentStatus>>
  readonly observableObjectsViewAction: ActionObservable<ObjectsViewAction>

  constructor(parameters: {
    readonly coreI18n: CoreI18n
    readonly subscribeToObjectsEventsUseCase: SubscribeToObjectsEventsUseCase
    readonly unsubscribeFromObjectsEventsUseCase: UnsubscribeFromObjectsEventsUseCase
    readonly getPaymentStatusesUseCase: GetPaymentStatusesUseCase
    readonly objectsPresentationLogicParameters: ObjectsPresentationLogicParameters
  }) {
    super()
    autoBind(this)
    this.coreI18n = parameters.coreI18n
    this.subscribeToObjectsEventsUseCase = parameters.subscribeToObjectsEventsUseCase
    this.unsubscribeFromObjectsEventsUseCase = parameters.unsubscribeFromObjectsEventsUseCase
    this.getPaymentStatusesUseCase = parameters.getPaymentStatusesUseCase
    this.objectsPresentationLogicParameters = parameters.objectsPresentationLogicParameters
    this.objectsPresentationLogic = this.createObjectsPresentationLogic()
    this.observableObjectsPageViewState = this.objectsPresentationLogic.observableObjectsPageViewState
    this.observableObjectsViewAction = this.objectsPresentationLogic.observableObjectsViewAction
  }

  protected onDestroy() {
    super.onDestroy()
    this.objectsPresentationLogic.destroy()
  }

  onObjectsViewEvent(objectsViewEvent: ObjectsViewEvent) {
    this.objectsPresentationLogic.onObjectsViewEvent(objectsViewEvent)
  }

  private createObjectsPresentationLogic(): ObjectsPresentationLogic<PaymentStatus> {
    const coreTextProvider: CoreTextProvider = this.coreI18n.getTextProvider()
    return new ObjectsPresentationLogic<PaymentStatus>({
      ...this.objectsPresentationLogicParameters,
      subscribeToObjectsEventsUseCase: this.subscribeToObjectsEventsUseCase,
      unsubscribeFromObjectsEventsUseCase: this.unsubscribeFromObjectsEventsUseCase,
      getObjects: async({
        query,
        sort,
        lastObject: lastOrderSubStatus
      }) => {
        return await this.getPaymentStatusesUseCase.call({
          filter: { query },
          sort,
          pagination: { id: lastOrderSubStatus?.id ?? undefined }
        })
      },
      getObjectId: (orderSubStatus: PaymentStatus) => orderSubStatus.id!.toString(),
      columns: [
        new TableColumn<PaymentStatus>({
          title: coreTextProvider.id(),
          createValue: (orderSubStatus: PaymentStatus) => new NumberTableValue({
            value: orderSubStatus.id
          })
        }),
        new TableColumn<PaymentStatus>({
          title: coreTextProvider.name(),
          createValue: (paymentStatus: PaymentStatus) => new TextTableValue({
            value: paymentStatus.name
          })
        }),
        new TableColumn<PaymentStatus>({
          title: coreTextProvider.nameForUser(),
          createValue: (paymentStatus: PaymentStatus) => new TextTableValue({
            value: paymentStatus.displayName
          })
        }),
        new TableColumn<PaymentStatus>({
          title: coreTextProvider.externalCode(),
          createValue: (paymentStatus: PaymentStatus) => new TextTableValue({
            value: paymentStatus.externalCode
          })
        })
      ]
    })
  }
}
