import TableValue from "../TableValue"
import DateTimeFormatter from "../../../../../../lib/DateTimeFormatter"
import isBlank from "../../../../../../lib/isBlank"
import assertNever from "../../../../../../lib/assertNever"

export enum DateTimeTableValueFormat {
  DATE,
  DATE_TIME
}

export default class DateTimeTableValue extends TableValue {
  private readonly value: Date | null | undefined
  private readonly timeZone?: string | null
  private readonly format: DateTimeTableValueFormat

  constructor(parameters: {
    readonly url?: string | null
    readonly value: Date | null | undefined
    readonly format?: DateTimeTableValueFormat | null
    readonly timeZone?: string | null
  }) {
    super(parameters)
    this.value = parameters.value
    this.format = parameters.format ?? DateTimeTableValueFormat.DATE_TIME
    this.timeZone = parameters.timeZone
  }

  getValue() {
    if (isBlank(this.value)) return ""

    const formatter = new DateTimeFormatter({
      date: this.value,
      timeZone: this.timeZone
    })

    switch (this.format) {
      case DateTimeTableValueFormat.DATE:
        return formatter.formatDate()
      case DateTimeTableValueFormat.DATE_TIME:
        return formatter.formatDateTime()
      default:
        assertNever(this.format)
    }
  }
}
