import { CreateObjectResult } from "../../../../sqadmin/features/objects/domain/results/CreateObjectResult"
import Image from "../../../core/domain/entities/images/Image"
import ImageError from "../../../core/domain/entities/images/ImageError"
import CreateObjectNetworkResult from "../../../core/data/results/CreateObjectNetworkResult"
import NetworkImage from "../../../core/data/entities/images/NetworkImage"
import NetworkImageError from "../../../core/data/entities/images/NetworkImageError"
import ImagesMapper from "../../../core/data/mappers/ImagesMapper"
import BannerImagesRepository from "../domain/repositories/BannerImagesRepository"
import BannerImagesNetworkSource from "../../../core/data/sources/network/BannerImagesNetworkSource"

export default class DefaultBannerImagesRepository implements BannerImagesRepository {
  private readonly bannerImagesNetworkSource: BannerImagesNetworkSource

  constructor(parameters: {
    readonly bannerImagesNetworkSource: BannerImagesNetworkSource
  }) {
    this.bannerImagesNetworkSource = parameters.bannerImagesNetworkSource
  }

  async createImage({
    file
  }: {
    readonly file: File
  }): Promise<CreateObjectResult<Image, ImageError>> {
    const result: CreateObjectNetworkResult<NetworkImage, NetworkImageError> =
      await this.bannerImagesNetworkSource.createImage({ file })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new ImagesMapper().mapNetworkToDomain({
            image: result.data
          })
        }
      default:
        return result
    }
  }

}
