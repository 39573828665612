import { Expose, Type } from "class-transformer"
import NetworkProductCategory from "../product-categories/NetworkProductCategory"

export default class NetworkBlockProductCategory {
  @Expose()
  readonly position: number | null | undefined

  @Expose()
  readonly productCategoryId: number | null | undefined

  @Expose()
  @Type(() => NetworkProductCategory)
  readonly productCategory: NetworkProductCategory | null | undefined

  @Expose()
  readonly clientId: string | null | undefined

  constructor(parameters?: {
    readonly position: number | null | undefined
    readonly productCategoryId: number | null | undefined
    readonly productCategory: NetworkProductCategory | null | undefined
    readonly clientId: string | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.position = parameters.position
    this.productCategoryId = parameters.productCategoryId
    this.productCategory = parameters.productCategory
    this.clientId = parameters.clientId
  }
}
