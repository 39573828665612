import DiModule from "../../../../../sqadmin/lib/di/DiModule"
import React from "react"
import CoreI18nDiModule from "../../../../core/di/modules/CoreI18nDiModule"
import ObjectsDomainDiModule from "../../../../../sqadmin/features/objects/di/modules/ObjectsDomainDiModule"
import ProductCategoryPage from "../../presentation/pages/product-category-page/ProductCategoryPage"
import ProductCategoryViewModel from "../../presentation/pages/product-category-page/ProductCategoryViewModel"
import ProductCategoriesViewModel from "../../presentation/pages/product-categories-page/ProductCategoriesViewModel"
import ObjectsPresentationLogicParameters
  from "../../../../../sqadmin/features/objects/presentation/entities/objects/ObjectsPresentationLogicParameters"
import ProductCategoriesPage from "../../presentation/pages/product-categories-page/ProductCategoriesPage"
import autoBind from "auto-bind"
import BonusProgramLevelsCoreDomainDiModule
  from "../../../bonus-program-levels-core/di/BonusProgramLevelsCoreDomainDiModule"
import ProductCategoriesCoreDomainDiModule
  from "../../../product-categories-core/di/ProductCategoriesCoreDomainDiModule"

export default interface ProductCategoriesPresentationDiModule {
  provideProductCategoriesPage(): React.ReactNode

  provideProductCategoryPage(): React.ReactNode
}

export class DefaultProductCategoriesPresentationDiModule
  extends DiModule
  implements ProductCategoriesPresentationDiModule {

  private readonly objectsDomainDiModule: ObjectsDomainDiModule
  private readonly coreI18nDiModule: CoreI18nDiModule
  private readonly bonusProgramLevelsCoreDomainDiModule: BonusProgramLevelsCoreDomainDiModule
  private readonly productCategoriesCoreDomainDiModule: ProductCategoriesCoreDomainDiModule

  constructor(parameters: {
    readonly objectsDomainDiModule: ObjectsDomainDiModule
    readonly coreI18nDiModule: CoreI18nDiModule
    readonly bonusProgramLevelsCoreDomainDiModule: BonusProgramLevelsCoreDomainDiModule
    readonly productCategoriesCoreDomainDiModule: ProductCategoriesCoreDomainDiModule
  }) {
    super()
    autoBind(this)
    this.objectsDomainDiModule = parameters.objectsDomainDiModule
    this.coreI18nDiModule = parameters.coreI18nDiModule
    this.bonusProgramLevelsCoreDomainDiModule = parameters.bonusProgramLevelsCoreDomainDiModule
    this.productCategoriesCoreDomainDiModule = parameters.productCategoriesCoreDomainDiModule
  }

  provideProductCategoriesPage(): React.ReactNode {
    return <ProductCategoriesPage provideViewModel={this.provideProductCategoriesViewModel} />
  }

  provideProductCategoryPage(): React.ReactNode {
    return <ProductCategoryPage provideViewModel={this.provideProductCategoryViewModel} />
  }

  private provideProductCategoryViewModel({
    productCategoryId
  }: {
    readonly productCategoryId?: number
  }): ProductCategoryViewModel {
    return new ProductCategoryViewModel({
      coreI18n: this.coreI18nDiModule.provideCoreI18n(),
      broadcastObjectsEventUseCase: this.objectsDomainDiModule.provideBroadcastObjectsEventUseCase({
        key: "productCategories"
      }),
      getProductCategoryUseCase: this.productCategoriesCoreDomainDiModule.provideGetProductCategoryUseCase(),
      createProductCategoryUseCase: this.productCategoriesCoreDomainDiModule.provideCreateProductCategoryUseCase(),
      updateProductCategoryUseCase: this.productCategoriesCoreDomainDiModule.provideUpdateProductCategoryUseCase(),
      destroyProductCategoryUseCase: this.productCategoriesCoreDomainDiModule.provideDestroyProductCategoryUseCase(),
      createProductCategoryImageUseCase:
        this.productCategoriesCoreDomainDiModule.provideCreateProductCategoryImageUseCase(),
      getBonusProgramLevelsUseCase: this.bonusProgramLevelsCoreDomainDiModule.provideGetBonusProgramLevelsUseCase(),
      productCategoryId
    })
  }

  private provideProductCategoriesViewModel({
    objectsPresentationLogicParameters
  }: {
    readonly objectsPresentationLogicParameters: ObjectsPresentationLogicParameters
  }): ProductCategoriesViewModel {
    return new ProductCategoriesViewModel({
      objectsPresentationLogicParameters,
      coreI18n: this.coreI18nDiModule.provideCoreI18n(),
      subscribeToObjectsEventsUseCase: this.objectsDomainDiModule.provideSubscribeToObjectsEventsUseCase({
        key: "productCategories"
      }),
      unsubscribeFromObjectsEventsUseCase: this.objectsDomainDiModule.provideUnsubscribeFromObjectsEventsUseCase({
        key: "productCategories"
      }),
      getProductCategoriesUseCase: this.productCategoriesCoreDomainDiModule.provideGetProductCategoriesUseCase()
    })
  }
}
