import { DestroyObjectResult } from "../../../../../sqadmin/features/objects/domain/results/DestroyObjectResult"
import PropertiesRepository from "../repositories/PropertiesRepository"
import PropertyError from "../../../../core/domain/entities/properties/PropertyError"

export default class DestroyPropertyUseCase {
  private readonly propertiesRepository: PropertiesRepository

  constructor(parameters: {
    readonly propertiesRepository: PropertiesRepository
  }) {
    this.propertiesRepository = parameters.propertiesRepository
  }

  async call(parameters: DestroyPropertyParameters): Promise<DestroyPropertyResult> {
    return await this.propertiesRepository.destroyProperty(parameters)
  }
}

export interface DestroyPropertyParameters {
  readonly propertyId: number
}

export type DestroyPropertyResult = DestroyObjectResult<PropertyError>
