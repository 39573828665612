import ProductErrorsObjectAttributes from "../../../domain/entities/products/ProductErrorsObjectAttributes"
import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../errors/NetworkAttributeError"
import AttributeError from "../../../../../sqadmin/core/domain/entities/AttributeError"

export default class NetworkProductErrorsObjectAttributes implements ProductErrorsObjectAttributes {
  @Expose()
  @Type(() => NetworkAttributeError)
  readonly name?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly externalCode?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly description?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly categoryId?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly mainVariant?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly variants?: NetworkAttributeError[] | null

  @Expose({ name: "productPropertyValues" })
  @Type(() => NetworkAttributeError)
  readonly productProperties?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly bonusProgramRule: AttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly hasBatches: AttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly isArchived: AttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly inputMultiplier: AttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly canBeOrderedWithBonusPoints: AttributeError[] | null | undefined
}
