import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../errors/NetworkAttributeError"
import BlockProductCategoryErrorsObjectAttributes
  from "../../../domain/entities/block-product-categories/BlockProductCategoryErrorsObjectAttributes"

export default class NetworkBlockProductCategoryErrorsObjectAttributes
  implements BlockProductCategoryErrorsObjectAttributes {
  @Expose()
  @Type(() => NetworkAttributeError)
  readonly position: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly productCategoryId: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly productCategory: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly clientId: NetworkAttributeError[] | null | undefined
}
