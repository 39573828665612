import React from "react"
import TextTableValue from "../../../entities/tables/table-value-by-type/TextTableValue"
import styles from "./TextTableValueComponent.module.scss"

export default function TextTableValueComponent({
  textTableValue
}: {
  readonly textTableValue: TextTableValue
}) {
  return <span className={styles.root}>{textTableValue.getValue()}</span>
}
