import { Expose, Transform } from "class-transformer"
import { Decimal } from "decimal.js"
import transformDecimal from "../../network/transformers/transformDecimal"

export default class NetworkGeoLocation {
  @Expose()
  @Transform(transformDecimal)
  readonly longitude: Decimal | null | undefined

  @Expose()
  @Transform(transformDecimal)
  readonly latitude: Decimal | null | undefined

  constructor(parameters?: {
    readonly longitude: Decimal | null | undefined
    readonly latitude: Decimal | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.longitude = parameters.longitude
    this.latitude = parameters.latitude
  }
}
