import { Expose } from "class-transformer"

export default class NetworkConfigurationEntityPropertyPolicy {

  @Expose()
  readonly canSaveOnCreate: boolean | null | undefined

  @Expose()
  readonly canSaveOnUpdate: boolean | null | undefined

  constructor(parameters?: {
    readonly canSaveOnCreate: boolean | null | undefined
    readonly canSaveOnUpdate: boolean | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.canSaveOnCreate = parameters.canSaveOnCreate
    this.canSaveOnUpdate = parameters.canSaveOnUpdate
  }
}
