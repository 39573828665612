import { Expose, Type } from "class-transformer"
import UserErrorsObject from "../../../domain/entities/users/UserErrorsObject"
import NetworkUserErrorsObjectAttributes from "./NetworkUserErrorsObjectAttributes"
import NetworkUserProfileErrorsObject from "../user-profiles/NetworkUserProfileErrorsObject"

export default class NetworkUserErrorsObject implements UserErrorsObject {
  @Expose()
  @Type(() => NetworkUserErrorsObjectAttributes)
  readonly attributes: NetworkUserErrorsObjectAttributes | null | undefined

  @Expose()
  @Type(() => NetworkUserProfileErrorsObject)
  readonly profile: NetworkUserProfileErrorsObject | null | undefined
}
