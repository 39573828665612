import { Expose, Type } from "class-transformer"
import OrderShippingMethodType from "../../../domain/entities/order-shipping-methods/OrderShippingMethodType"
import NetworkSettlement from "../settlements/NetworkSettlement"
import NetworkOrderShippingTariff from "../order-shipping-method-tariffs/NetworkOrderShippingTariff"

export default class NetworkOrderShippingMethod {
  @Expose()
  readonly id: number | null | undefined

  @Expose()
  readonly type: OrderShippingMethodType | null | undefined

  @Expose()
  readonly settlementId: number | null | undefined

  @Expose()
  @Type(() => NetworkSettlement)
  readonly settlement: NetworkSettlement | null | undefined

  @Expose()
  @Type(() => NetworkOrderShippingTariff)
  readonly orderShippingTariff: NetworkOrderShippingTariff | null | undefined

  @Expose()
  readonly orderShippingTariffId: number | null | undefined

  constructor(parameters?: {
    readonly id: number | null | undefined
    readonly type: OrderShippingMethodType | null | undefined
    readonly settlementId: number | null | undefined
    readonly settlement: NetworkSettlement | null | undefined
    readonly orderShippingTariff: NetworkOrderShippingTariff | null | undefined
    readonly orderShippingTariffId: number | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.id = parameters.id
    this.type = parameters.type
    this.settlementId = parameters.settlementId
    this.settlement = parameters.settlement
    this.orderShippingTariff = parameters.orderShippingTariff
    this.orderShippingTariffId = parameters.orderShippingTariffId
  }
}
