import NetworkPaymentProvider from "../entities/payment-providers/NetworkPaymentProvider"
import PaymentProvider from "../../domain/entities/payment-providers/PaymentProvider"

export default class PaymentProvidersMapper {
  mapNetworkToDomain({
    paymentProvider
  }: {
    readonly paymentProvider: NetworkPaymentProvider
  }): PaymentProvider {
    return {
      type: paymentProvider.type,
      name: paymentProvider.name
    }
  }

  mapDomainToNetwork({
    paymentProvider
  }: {
    readonly paymentProvider: PaymentProvider
  }): NetworkPaymentProvider {
    return new NetworkPaymentProvider({
      type: paymentProvider.type,
      name: paymentProvider.name
    })
  }
}
