import { Expose, Transform, Type } from "class-transformer"
import NetworkProductVariant from "../product-variants/NetworkProductVariant"
import NetworkProductCategory from "../product-categories/NetworkProductCategory"
import NetworkOption from "../options/NetworkOption"
import NetworkProductProperty from "../product-properties/NetworkProductProperty"
import NetworkBonusProgramRule from "../bonus-program-rules/NetworkBonusProgramRule"
import transformDecimal from "../../network/transformers/transformDecimal"
import { Decimal } from "decimal.js"

export default class NetworkProduct {
  @Expose()
  readonly id?: number | null

  @Expose()
  readonly externalCode?: string | null

  @Expose()
  readonly name?: string | null

  @Expose()
  readonly description?: string | null

  @Expose()
  readonly categoryId?: number | null

  @Expose()
  @Type(() => NetworkProductCategory)
  readonly category?: NetworkProductCategory | null

  @Expose()
  @Type(() => NetworkProductVariant)
  readonly mainVariant?: NetworkProductVariant | null

  @Expose()
  @Type(() => NetworkOption)
  readonly options?: NetworkOption[] | null

  @Expose()
  readonly optionIds?: number[] | null

  @Expose()
  @Type(() => NetworkProductVariant)
  readonly variants?: NetworkProductVariant[] | null

  @Expose({ name: "productPropertyValues" })
  @Type(() => NetworkProductProperty)
  readonly productProperties?: NetworkProductProperty[] | null

  @Expose()
  @Type(() => NetworkBonusProgramRule)
  readonly bonusProgramRule: NetworkBonusProgramRule | null | undefined

  @Expose()
  readonly hasBatches: boolean | null | undefined

  @Expose()
  readonly isArchived: boolean | null | undefined

  @Expose()
  readonly canBeOrderedWithBonusPoints: boolean | null | undefined

  @Expose()
  @Transform(transformDecimal)
  readonly inputMultiplier: Decimal | null | undefined

  constructor(parameters?: {
    readonly id?: number | null
    readonly externalCode?: string | null
    readonly name?: string | null
    readonly description?: string | null
    readonly categoryId?: number | null
    readonly category?: NetworkProductCategory | null
    readonly mainVariant?: NetworkProductVariant | null
    readonly options?: NetworkOption[] | null
    readonly optionIds?: number[] | null
    readonly variants?: NetworkProductVariant[] | null
    readonly productProperties?: NetworkProductProperty[] | null
    readonly bonusProgramRule: NetworkBonusProgramRule | null | undefined
    readonly hasBatches: boolean | null | undefined
    readonly isArchived: boolean | null | undefined
    readonly inputMultiplier: Decimal | null | undefined
    readonly canBeOrderedWithBonusPoints: boolean | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.id = parameters.id
    this.externalCode = parameters.externalCode
    this.name = parameters.name
    this.description = parameters.description
    this.categoryId = parameters.categoryId
    this.category = parameters.category
    this.mainVariant = parameters.mainVariant
    this.options = parameters.options
    this.optionIds = parameters.optionIds
    this.variants = parameters.variants
    this.productProperties = parameters.productProperties
    this.bonusProgramRule = parameters.bonusProgramRule
    this.hasBatches = parameters.hasBatches
    this.isArchived = parameters.isArchived
    this.inputMultiplier = parameters.inputMultiplier
    this.canBeOrderedWithBonusPoints = parameters.canBeOrderedWithBonusPoints
  }
}
