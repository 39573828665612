import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import { useCoreTextProvider } from "../../../../../core/presentation/contexts/CoreTextProviderContext"
import { useNumberObjectId } from "../../../../../../sqadmin/features/objects/presentation/hooks/useObjectId"
import { useViewModel } from "../../../../../../sqadmin/lib/view-model/ViewModel"
import ObjectComponent from "../../../../../../sqadmin/features/objects/presentation/components/object/ObjectComponent"
import React from "react"
import AdminViewModel from "./AdminViewModel"

export interface UserPageProps {
  readonly provideViewModel: (parameters: { readonly adminId?: number }) => AdminViewModel
}

export default function AdminPage({
  provideViewModel
}: UserPageProps) {
  const coreTextProvider: CoreTextProvider = useCoreTextProvider()
  const userId: number | undefined = useNumberObjectId()

  const viewModel: AdminViewModel = useViewModel(() => provideViewModel({
    adminId: userId
  }))

  return (
    <ObjectComponent
      newObjectTitle={coreTextProvider.newAdmin()}
      existedObjectTitle={coreTextProvider.admin()}
      observableObjectViewState={viewModel.observableObjectViewState}
      onObjectViewEvent={viewModel.onViewObjectEvent}
    />
  )
}
