import { Expose, Type } from "class-transformer"
import NetworkUserProfile from "../user-profiles/NetworkUserProfile"
import NetworkPhoneNumber from "../phone-numbers/NetworkPhoneNumber"
import NetworkBonusProgramMember from "../bonus-program-member/NetworkBonusProgramMember"
import UserRoleType from "../../../domain/entities/users/UserRoleType"

export default class NetworkUser {
  @Expose()
  readonly id: number | null | undefined

  @Expose()
  @Type(() => NetworkUserProfile)
  readonly profile: NetworkUserProfile | null | undefined

  @Expose()
  @Type(() => NetworkPhoneNumber)
  readonly phoneNumber: NetworkPhoneNumber | null | undefined

  @Expose()
  @Type(() => NetworkBonusProgramMember)
  readonly bonusProgramMember: NetworkBonusProgramMember | null | undefined

  @Expose()
  readonly emailAddress: string | null | undefined

  @Expose()
  readonly roleType: UserRoleType | null | undefined

  @Expose()
  readonly passwordConfirmation: string | null | undefined

  @Expose()
  readonly password: string | null | undefined

  @Expose()
  readonly isAdminEmailNotificationsEnabled: boolean | null | undefined

  constructor(parameters?: {
    readonly id: number | null | undefined
    readonly profile: NetworkUserProfile | null | undefined,
    readonly phoneNumber: NetworkPhoneNumber | null | undefined,
    readonly bonusProgramMember: NetworkBonusProgramMember | null | undefined
    readonly emailAddress: string | null | undefined
    readonly roleType: UserRoleType | null | undefined
    readonly passwordConfirmation: string | null | undefined
    readonly password: string | null | undefined
    readonly isAdminEmailNotificationsEnabled: boolean | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.id = parameters.id
    this.profile = parameters.profile
    this.phoneNumber = parameters.phoneNumber
    this.bonusProgramMember = parameters.bonusProgramMember
    this.emailAddress = parameters.emailAddress
    this.roleType = parameters.roleType
    this.passwordConfirmation = parameters.passwordConfirmation
    this.password = parameters.password
    this.isAdminEmailNotificationsEnabled = parameters.isAdminEmailNotificationsEnabled
  }
}
