import React from "react"
import DiModule from "../../../../../sqadmin/lib/di/DiModule"
import ObjectsDomainDiModule from "../../../../../sqadmin/features/objects/di/modules/ObjectsDomainDiModule"
import CoreI18nDiModule from "../../../../core/di/modules/CoreI18nDiModule"
import autoBind from "auto-bind"
import BonusPointsBurningRuleViewModel
  from "../../presentation/pages/bonus-points-burning-rule-page/BonusPointsBurningRuleViewModel"
import BonusPointsBurningRulePage
  from "../../presentation/pages/bonus-points-burning-rule-page/BonusPointsBurningRulePage"
import BonusPointsBurningRulesCoreDomainDiModule
  from "../../../bonus-points-burning-rules-core/di/BonusPointsBurningRulesCoreDomainDiModule"

export default interface BonusPointsBurningRulesPresentationDiModule {
  provideBonusPointsBurningRulePage(): React.ReactNode
}

export class DefaultBonusPointsBurningRulesPresentationDiModule
  extends DiModule
  implements BonusPointsBurningRulesPresentationDiModule {

  private readonly objectsDomainDiModule: ObjectsDomainDiModule
  private readonly bonusPointsBurningRulesCoreDomainDiModule: BonusPointsBurningRulesCoreDomainDiModule
  private readonly coreI18nDiModule: CoreI18nDiModule

  constructor(parameters: {
    readonly objectsDomainDiModule: ObjectsDomainDiModule
    readonly bonusPointsBurningRulesCoreDomainDiModule: BonusPointsBurningRulesCoreDomainDiModule
    readonly coreI18nDiModule: CoreI18nDiModule
  }) {
    super()
    autoBind(this)
    this.objectsDomainDiModule = parameters.objectsDomainDiModule
    this.bonusPointsBurningRulesCoreDomainDiModule = parameters.bonusPointsBurningRulesCoreDomainDiModule
    this.coreI18nDiModule = parameters.coreI18nDiModule
  }

  provideBonusPointsBurningRulePage(): React.ReactNode {
    return <BonusPointsBurningRulePage provideViewModel={this.provideBonusPointsBurningRuleViewModel} />
  }

  private provideBonusPointsBurningRuleViewModel(): BonusPointsBurningRuleViewModel {
    return new BonusPointsBurningRuleViewModel({
      coreI18n: this.coreI18nDiModule.provideCoreI18n(),
      broadcastObjectsEventUseCase: this.objectsDomainDiModule.provideBroadcastObjectsEventUseCase({
        key: "bonusPointsBurningRules"
      }),
      createOrUpdateBonusPointsBurningRuleUseCase: this.bonusPointsBurningRulesCoreDomainDiModule
        .provideCreateOrUpdateBonusPointsBurningRuleUseCase(),
      getBonusPointsBurningRuleUseCase: this.bonusPointsBurningRulesCoreDomainDiModule
        .provideGetBonusPointsBurningRuleUseCase()
    })
  }
}
