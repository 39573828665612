import DiModule from "../../../../sqadmin/lib/di/DiModule"
import CoreNetworkDiModule from "../../../core/di/modules/CoreNetworkDiModule"
import PropertyValuesRepository from "../domain/repositories/PropertyValuesRepository"
import DefaultPropertyValuesRepository from "../data/repositories/DefaultPropertyValuesRepository"
import PropertyValuesNetworkSource from "../data/sources/PropertyValuesNetworkSource"

export default interface PropertyValuesCoreDataDiModule {
  providePropertyValuesRepository(): PropertyValuesRepository
}

export class DefaultPropertyValuesCoreDataDiModule extends DiModule implements PropertyValuesCoreDataDiModule {
  private readonly sqCoreCoreNetworkDiModule: CoreNetworkDiModule

  constructor(parameters: {
    readonly sqCoreCoreNetworkDiModule: CoreNetworkDiModule
  }) {
    super()
    this.sqCoreCoreNetworkDiModule = parameters.sqCoreCoreNetworkDiModule
  }

  providePropertyValuesRepository(): PropertyValuesRepository {
    return this.single(
      DefaultPropertyValuesRepository.name,
      () => new DefaultPropertyValuesRepository({
        propertyValuesNetworkSource: this.providePropertyValuesNetworkSource()
      })
    )
  }

  private providePropertyValuesNetworkSource(): PropertyValuesNetworkSource {
    return this.single(
      PropertyValuesNetworkSource.name,
      () => new PropertyValuesNetworkSource({
        backendHttpClient: this.sqCoreCoreNetworkDiModule.provideBackendHttpClient()
      })
    )
  }
}
