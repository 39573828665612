import CoreTextProvider from "../../../i18n/CoreTextProvider"
import { useCoreTextProvider } from "../../contexts/CoreTextProviderContext"
import React from "react"
import ApplicationException from "../../../domain/exceptions/ApplicationException"
import ExceptionLocalizer from "../../services/ExceptionLocalizer"
import { TextStyle } from "../../../../design/text/TextComponent"
import AppErrorMessageComponent from "../app-error-message/AppErrorMessageComponent"

export default function ContentLoadingFailureComponent({
  exception,
  onRetryClick
}: {
  readonly exception: ApplicationException
  readonly onRetryClick: () => void
}) {
  const coreTextProvider: CoreTextProvider = useCoreTextProvider()
  return (
    <AppErrorMessageComponent
      text={new ExceptionLocalizer({ coreTextProvider: coreTextProvider }).localizeException(exception)}
      onClick={onRetryClick}
      messageTextStyle={TextStyle.BODY2_PRIMARY}
      buttonTextStyle={TextStyle.BODY2_SECONDARY}
    />
  )
}
