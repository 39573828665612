import TableValue from "../TableValue"
import { Decimal } from "decimal.js"

export default class DecimalTableValue extends TableValue {
  private readonly decimal: Decimal | null | undefined

  constructor(parameters: {
    readonly url?: string | null
    readonly decimal: Decimal | null | undefined
  }) {
    super(parameters)
    this.decimal = parameters.decimal
  }

  getValue() {
    return this.decimal
  }
}
