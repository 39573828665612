enum ApiLogItemServiceType {
  SYNCHRONIZATION = "synchronization",
  CDEK = "cdek",
  YOOKASSA = "yookassa",
  DA_DATA = "daData",
  SMS_AERO = "smsAero",
  DOLYAME = "dolyame"
}

export default ApiLogItemServiceType
