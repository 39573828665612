import { Expose, Type } from "class-transformer"
import NetworkOrderPaymentMethod from "../order-payment-methods/NetworkOrderPaymentMethod"
import NetworkPayment from "../payments/NetworkPayment"

export default class NetworkOrderPayment {
  @Expose()
  readonly id: number | null | undefined

  @Expose()
  @Type(() => NetworkOrderPaymentMethod)
  readonly method: NetworkOrderPaymentMethod | null | undefined

  @Expose()
  @Type(() => NetworkPayment)
  readonly payment: NetworkPayment | null | undefined

  constructor(parameters?: {
    readonly id: number | null | undefined
    readonly method: NetworkOrderPaymentMethod | null | undefined
    readonly payment: NetworkPayment | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.id = parameters.id
    this.method = parameters.method
    this.payment = parameters.payment
  }
}
