import { LoadObjectResult } from "../../../../../sqadmin/features/objects/domain/results/LoadObjectResult"
import ApiLogItemsRepository from "../repositories/ApiLogItemsRepository"
import ApiLogItem from "../../../../core/domain/entities/api-log-items/ApiLogItem"

export default class GetApiLogItemUseCase {
  private readonly apiLogItemsRepository: ApiLogItemsRepository

  constructor(parameters: {
    readonly apiLogItemsRepository: ApiLogItemsRepository
  }) {
    this.apiLogItemsRepository = parameters.apiLogItemsRepository
  }

  async call(parameters: {
    readonly apiLogItemId: number
  }): Promise<LoadObjectResult<ApiLogItem>> {
    return await this.apiLogItemsRepository.getApiLogItem(parameters)
  }
}
