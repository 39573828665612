import { Expose, Type } from "class-transformer"
import NetworkBonusProgramLevel from "../bonus-program-levels/NetworkBonusProgramLevel"

export default class NetworkBonusProgramMember {
  @Expose()
  readonly id: number | null | undefined

  @Expose()
  readonly bonusPointsCount: number | null | undefined

  @Expose()
  @Type(() => NetworkBonusProgramLevel)
  readonly bonusProgramLevel: NetworkBonusProgramLevel | null | undefined

  constructor(parameters?: {
    readonly id: number | null | undefined
    readonly bonusPointsCount: number | null | undefined
    readonly bonusProgramLevel: NetworkBonusProgramLevel | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.id = parameters.id
    this.bonusPointsCount = parameters.bonusPointsCount
    this.bonusProgramLevel = parameters.bonusProgramLevel
  }
}
