import { UpdateObjectResult } from "../../../../../../sqadmin/features/objects/domain/results/UpdateObjectResult"
import DashboardError from "../../../../../core/domain/entities/dashboards/DashboardError"
import Dashboard from "../../../../../core/domain/entities/dashboards/Dashboard"
import DashboardsRepository from "../../repositories/DashboardsRepository"

export default class UpdateDashboardUseCase {
  private readonly dashboardsRepository: DashboardsRepository

  constructor(parameters: {
    readonly dashboardsRepository: DashboardsRepository
  }) {
    this.dashboardsRepository = parameters.dashboardsRepository
  }

  async call(parameters: UpdateDashboardParameters): Promise<UpdateObjectResult<Dashboard, DashboardError>> {
    return await this.dashboardsRepository.updateDashboard(parameters)
  }
}

export interface UpdateDashboardParameters {
  readonly dashboardId: number
  readonly dashboard: Dashboard
}
