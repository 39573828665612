import { Expose, Type } from "class-transformer"
import MenuItemType from "../../../domain/entities/menu-items/MenuItemType"
import NetworkLink from "../links/NetworkLink"
import NetworkDashboard from "../dashboards/NetworkDashboard"
import NetworkProductsSet from "../products-sets/NetworkProductsSet"

export default class NetworkMenuItem {
  @Expose()
  readonly id: number | null | undefined

  @Expose()
  readonly position: number | null | undefined

  @Expose()
  readonly clientId: string | null | undefined

  @Expose()
  readonly title: string | null | undefined

  @Expose()
  readonly type: MenuItemType | null | undefined

  @Expose()
  @Type(() => NetworkLink)
  readonly link: NetworkLink | null | undefined

  @Expose()
  @Type(() => NetworkDashboard)
  readonly dashboard: NetworkDashboard | null | undefined

  @Expose()
  readonly dashboardId: number | null | undefined

  @Expose()
  @Type(() => NetworkProductsSet)
  readonly productsSet: NetworkProductsSet | null | undefined

  constructor(parameters?: {
    readonly id: number | null | undefined
    readonly position: number | null | undefined
    readonly clientId: string | null | undefined
    readonly title: string | null | undefined
    readonly type: MenuItemType | null | undefined
    readonly link: NetworkLink | null | undefined
    readonly dashboard: NetworkDashboard | null | undefined
    readonly dashboardId: number | null | undefined
    readonly productsSet: NetworkProductsSet | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.id = parameters.id
    this.position = parameters.position
    this.clientId = parameters.clientId
    this.title = parameters.title
    this.type = parameters.type
    this.link = parameters.link
    this.dashboard = parameters.dashboard
    this.dashboardId = parameters.dashboardId
    this.productsSet = parameters.productsSet
  }
}
