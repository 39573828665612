import NetworkProductCategory from "../../../../core/data/entities/product-categories/NetworkProductCategory"
import { Expose, Type } from "class-transformer"
import NetworkPage from "../../../../core/data/entities/pagination/NetworkPage"

export default class NetworkProductCategoriesResponseBody {
  @Expose()
  @Type(() => NetworkProductCategory)
  readonly productCategories?: NetworkProductCategory[] | null

  @Expose()
  @Type(() => NetworkPage)
  readonly page?: NetworkPage | null
}
