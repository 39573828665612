import { Expose, Type } from "class-transformer"
import NetworkPage from "../../../../core/data/entities/pagination/NetworkPage"
import NetworkSettlement from "../../../../core/data/entities/settlements/NetworkSettlement"

export default class NetworkSettlementsResponseBody {
  @Expose()
  @Type(() => NetworkSettlement)
  readonly settlements?: NetworkSettlement[] | null

  @Expose()
  @Type(() => NetworkPage)
  readonly page?: NetworkPage | null
}
