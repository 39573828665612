import BonusProgramLevel from "../../domain/entities/bonus-program-levels/BonusProgramLevel"
import NetworkBonusProgramLevel from "../entities/bonus-program-levels/NetworkBonusProgramLevel"
import BonusProgramLevelTransitionRulesMapper from "./BonusProgramLevelTransitionRulesMapper"

export default class BonusProgramLevelsMapper {
  mapNetworkToDomain({
    bonusProgramLevel
  }: {
    readonly bonusProgramLevel: NetworkBonusProgramLevel
  }): BonusProgramLevel {
    return {
      id: bonusProgramLevel.id,
      name: bonusProgramLevel.name,
      position: bonusProgramLevel.position,
      bonusPointsPercent: bonusProgramLevel.bonusPointsPercent,
      transitionRule: bonusProgramLevel.transitionRule && new BonusProgramLevelTransitionRulesMapper()
        .mapNetworkToDomain({ bonusProgramLevelTransitionRule: bonusProgramLevel.transitionRule })
    }
  }

  mapDomainToNetwork({
    bonusProgramLevel
  }: {
    readonly bonusProgramLevel: BonusProgramLevel
  }): NetworkBonusProgramLevel {
    return new NetworkBonusProgramLevel({
      id: bonusProgramLevel.id,
      name: bonusProgramLevel.name,
      position: bonusProgramLevel.position,
      bonusPointsPercent: bonusProgramLevel.bonusPointsPercent,
      transitionRule: bonusProgramLevel.transitionRule && new BonusProgramLevelTransitionRulesMapper()
        .mapDomainToNetwork({ bonusProgramLevelTransitionRule: bonusProgramLevel.transitionRule })
    })
  }
}
