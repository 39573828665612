import BackendHttpClient, { BackendHttpClientResult } from "../../../../core/data/network/BackendHttpClient"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import { instanceToPlain, plainToInstance } from "class-transformer"
import NetworkProductCategory from "../../../../core/data/entities/product-categories/NetworkProductCategory"
import NetworkExecutionError from "../../../../core/data/entities/errors/NetworkExecutionError"
import NetworkProductCategoriesResponseBody
  from "../entities/NetworkProductCategoriesResponseBody"
import NetworkProductCategoryResponseBody from "../entities/NetworkProductCategoryResponseBody"
import NetworkProductCategoryRequestBody from "../entities/NetworkProductCategoryRequestBody"
import GetObjectNetworkResult from "../../../../core/data/results/GetObjectNetworkResult"
import CreateObjectNetworkResult from "../../../../core/data/results/CreateObjectNetworkResult"
import UpdateObjectNetworkResult from "../../../../core/data/results/UpdateObjectNetworkResult"
import DestroyObjectNetworkResult from "../../../../core/data/results/DestroyObjectNetworkResult"
import NetworkProductCategoryError from "../../../../core/data/entities/product-categories/NetworkProductCategoryError"
import NetworkProductCategoriesRequestQuery
  from "../entities/NetworkProductCategoriesRequestQuery"
import SuccessExecutionResult from "../../../../../sqadmin/core/domain/results/SuccessExecutionResult"
import ErrorExecutionResult from "../../../../../sqadmin/core/domain/results/ErrorExecutionResult"
import FailureExecutionResult from "../../../../../sqadmin/core/domain/results/FailureExecutionResult"
import NetworkProductCategoriesRequestFilter
  from "../entities/NetworkProductCategoriesRequestFilter"
import NetworkLastItemPagination from "../../../../core/data/entities/pagination/NetworkLastItemPagination"

const basePath = "/admin/product_categories"

export default class ProductCategoriesNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async getProductCategories({
    filter,
    pagination,
    sort
  }: {
    readonly filter?: NetworkProductCategoriesRequestFilter | null
    readonly pagination?: NetworkLastItemPagination | null
    readonly sort?: string | null
  }): Promise<GetProductCategoriesNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: basePath,
      parameters: instanceToPlain(new NetworkProductCategoriesRequestQuery({
        filter,
        pagination,
        sort
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkProductCategoriesResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async getProductCategory({
    productCategoryId
  }: {
    readonly productCategoryId: number
  }): Promise<GetObjectNetworkResult<NetworkProductCategory>> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}/${productCategoryId}`
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkProductCategoryResponseBody, result.body).productCategory!
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async createProductCategory({
    productCategory
  }: {
    readonly productCategory: NetworkProductCategory
  }): Promise<CreateObjectNetworkResult<NetworkProductCategory, NetworkProductCategoryError>> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.POST,
      path: basePath,
      body: instanceToPlain(new NetworkProductCategoryRequestBody({
        productCategory
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkProductCategoryResponseBody, result.body).productCategory!
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkProductCategoryError, result.body)
        }
      case "failure":
        return result
    }
  }

  async updateProductCategory({
    productCategoryId,
    productCategory
  }: {
    readonly productCategoryId: number
    readonly productCategory: NetworkProductCategory
  }): Promise<UpdateObjectNetworkResult<NetworkProductCategory, NetworkProductCategoryError>> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.PUT,
      path: `${basePath}/${productCategoryId}`,
      body: instanceToPlain(new NetworkProductCategoryRequestBody({
        productCategory
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkProductCategoryResponseBody, result.body).productCategory!
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkProductCategoryError, result.body)
        }
      case "failure":
        return result
    }
  }

  async destroyProductCategory({
    productCategoryId
  }: {
    readonly productCategoryId: number
  }): Promise<DestroyObjectNetworkResult<NetworkProductCategoryError>> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.DELETE,
      path: `${basePath}/${productCategoryId}`
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: undefined
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkProductCategoryError, result.body)
        }
      case "failure":
        return result
    }
  }
}

export type GetProductCategoriesNetworkResult =
  SuccessExecutionResult<NetworkProductCategoriesResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult
