import useLocationContext from "./useLocationContext"

export default class ViewModel {

  destroy() {
    this.onDestroy()
  }

  protected onDestroy() {
    // do something
  }
}

export function useViewModel<T>(provideViewModel: () => T): T {
  return useLocationContext().getOrCreateViewModel(provideViewModel)
}
