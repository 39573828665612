import { DestroyObjectResult } from "../../../../../sqadmin/features/objects/domain/results/DestroyObjectResult"
import DiscountRulesRepository from "../repositories/DiscountRulesRepository"
import DiscountRuleError from "../../../../core/domain/entities/discount-rules/DiscountRuleError"

export default class DestroyDiscountRuleUseCase {
  private readonly discountRulesRepository: DiscountRulesRepository

  constructor(parameters: {
    readonly discountRulesRepository: DiscountRulesRepository
  }) {
    this.discountRulesRepository = parameters.discountRulesRepository
  }

  async call(parameters: {
    readonly discountRuleId: number
  }): Promise<DestroyObjectResult<DiscountRuleError>> {
    return await this.discountRulesRepository.destroyDiscountRule(parameters)
  }
}
