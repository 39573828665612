import ConfigurationEntityProperty from "../../domain/entities/configurations/ConfigurationEntityProperty"
import NetworkConfigurationEntityProperty from "../entities/configurations/NetworkConfigurationEntityProperty"
import ConfigurationEntityPropertyPoliciesMapper from "./ConfigurationEntityPropertyPoliciesMapper"

export default class ConfigurationEntityPropertiesMapper {
  mapNetworkToDomain({
    entityProperty
  }: {
    readonly entityProperty: NetworkConfigurationEntityProperty
  }): ConfigurationEntityProperty {
    return {
      name: entityProperty.name,
      policy: entityProperty.policy &&
        new ConfigurationEntityPropertyPoliciesMapper().mapNetworkToDomain({ entityPolicy: entityProperty.policy })
    }
  }

  mapDomainToNetwork({
    entityProperty
  }: {
    readonly entityProperty: ConfigurationEntityProperty
  }): NetworkConfigurationEntityProperty {
    return new NetworkConfigurationEntityProperty({
      name: entityProperty.name,
      policy: entityProperty.policy &&
        new ConfigurationEntityPropertyPoliciesMapper().mapDomainToNetwork({ entityPolicy: entityProperty.policy })
    })
  }
}
