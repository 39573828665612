import { Expose, Type } from "class-transformer"

export default class LocalSession {
  @Expose()
  @Type(() => String)
  readonly accessToken?: string | null

  constructor(parameters?: {
    readonly accessToken?: string | null
  }) {
    this.accessToken = parameters?.accessToken
  }
}
