import { v4 as uuidv4 } from "uuid"
import VariantImage from "../../domain/entities/variant-images/VariantImage"
import NetworkVariantImage from "../entities/variant-images/NetworkVariantImage"
import ImagesMapper from "./ImagesMapper"

export default class VariantImagesMapper {
  mapNetworkToDomain({
    variantImage
  }: {
    readonly variantImage: NetworkVariantImage
  }): VariantImage {
    return {
      id: variantImage.id,
      imageId: variantImage.imageId,
      position: variantImage.position,
      clientId: uuidv4(),
      image: variantImage.image && new ImagesMapper().mapNetworkToDomain({
        image: variantImage.image
      }),
      isMain: variantImage.isMain
    }
  }

  mapDomainToNetwork({
    variantImage
  }: {
    readonly variantImage: VariantImage
  }): NetworkVariantImage {
    return new NetworkVariantImage({
      id: variantImage.id,
      imageId: variantImage.imageId,
      position: variantImage.position,
      clientId: variantImage.clientId,
      image: variantImage.image && new ImagesMapper().mapDomainToNetwork({
        image: variantImage.image
      }),
      isMain: variantImage.isMain
    })
  }
}
