import { Expose, Type } from "class-transformer"
import VariantImageErrorsObject from "../../../domain/entities/variant-images/VariantImageErrorsObject"
import NetworkVariantImageErrorsObjectAttributes from "./NetworkVariantImageErrorsObjectAttributes"
import NetworkImageErrorsObject from "../images/NetworkImageErrorsObject"

export default class NetworkVariantImageErrorsObject implements VariantImageErrorsObject {
  @Expose()
  @Type(() => NetworkVariantImageErrorsObjectAttributes)
  readonly attributes: NetworkVariantImageErrorsObjectAttributes | null | undefined

  @Expose()
  @Type(() => NetworkImageErrorsObject)
  readonly image: NetworkImageErrorsObject | null | undefined

  @Expose()
  readonly clientId: string | null | undefined
}
