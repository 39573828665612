import NetworkOrderPayment from "../entities/order-payments/NetworkOrderPayment"
import OrderPayment from "../../domain/entities/order-payments/OrderPayment"
import OrderPaymentMethodsMapper from "./OrderPaymentMethodsMapper"
import PaymentsMapper from "./PaymentsMapper"

export default class OrderPaymentsMapper {
  mapNetworkToDomain({
    orderPayment
  }: {
    readonly orderPayment: NetworkOrderPayment
  }): OrderPayment {
    return {
      id: orderPayment.id,
      method: orderPayment.method && new OrderPaymentMethodsMapper()
        .mapNetworkToDomain({ orderPaymentMethod: orderPayment.method }),
      payment: orderPayment.payment && new PaymentsMapper()
        .mapNetworkToDomain({ payment: orderPayment.payment })
    }
  }

  mapDomainToNetwork({
    orderPayment
  }: {
    readonly orderPayment: OrderPayment
  }): NetworkOrderPayment {
    return new NetworkOrderPayment({
      id: orderPayment.id,
      method: orderPayment.method && new OrderPaymentMethodsMapper()
        .mapDomainToNetwork({ orderPaymentMethod: orderPayment.method }),
      payment: orderPayment.payment && new PaymentsMapper()
        .mapDomainToNetwork({ payment: orderPayment.payment })
    })
  }
}
