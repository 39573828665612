import NetworkBonusPointsRule from "../entities/bonus-points-rules/NetworkBonusPointsRule"
import BonusPointsRule from "../../domain/entities/bonus-points-rules/BonusPointsRule"
import BonusProgramLevelsMapper from "./BonusProgramLevelsMapper"
import { v4 as uuidv4 } from "uuid"
import CalculatorsMapper from "./CalculatorsMapper"

export default class BonusPointsRulesMapper {
  mapNetworkToDomain({
    bonusPointsRule
  }: {
    readonly bonusPointsRule: NetworkBonusPointsRule
  }): BonusPointsRule {
    return {
      id: bonusPointsRule.id,
      bonusProgramLevelId: bonusPointsRule.bonusProgramLevelId,
      bonusProgramLevel: bonusPointsRule.bonusProgramLevel && new BonusProgramLevelsMapper()
        .mapNetworkToDomain({ bonusProgramLevel: bonusPointsRule.bonusProgramLevel }),
      clientId: uuidv4(),
      calculator: bonusPointsRule.calculator && new CalculatorsMapper()
        .mapNetworkToDomain({ calculator: bonusPointsRule.calculator })
    }
  }

  mapDomainToNetwork({
    bonusPointsRule
  }: {
    readonly bonusPointsRule: BonusPointsRule
  }): NetworkBonusPointsRule {
    return new NetworkBonusPointsRule({
      id: bonusPointsRule.id,
      bonusProgramLevelId: bonusPointsRule.bonusProgramLevelId,
      bonusProgramLevel: bonusPointsRule.bonusProgramLevel && new BonusProgramLevelsMapper()
        .mapDomainToNetwork({ bonusProgramLevel: bonusPointsRule.bonusProgramLevel }),
      clientId: bonusPointsRule.clientId,
      calculator: bonusPointsRule.calculator && new CalculatorsMapper()
        .mapDomainToNetwork({ calculator: bonusPointsRule.calculator })
    })
  }
}
