import React from "react"
import ProductsViewModel from "./ProductsViewModel"
import ObjectsPresentationLogicParameters
  from "../../../../../../sqadmin/features/objects/presentation/entities/objects/ObjectsPresentationLogicParameters"
import { useViewModel } from "../../../../../../sqadmin/lib/view-model/ViewModel"
import useObjectsPresentationLogicParameters
  from "../../../../../../sqadmin/features/objects/presentation/hooks/useObjectsPresentationLogicParameters"
import ObjectsComponent
  from "../../../../../../sqadmin/features/objects/presentation/components/objects/ObjectsComponent"
import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import { useCoreTextProvider } from "../../../../../core/presentation/contexts/CoreTextProviderContext"
import CoreUrlProvider from "../../../../../core/presentation/services/CoreUrlProvider"
import Product from "../../../../../core/domain/entities/products/Product"

export interface ProductsPageParameters {
  readonly provideViewModel: (parameters: {
    readonly objectsPresentationLogicParameters: ObjectsPresentationLogicParameters
  }) => ProductsViewModel
}

export default function ProductsPage({
  provideViewModel
}: ProductsPageParameters) {
  const coreTextProvider: CoreTextProvider = useCoreTextProvider()
  const coreUrlProvider: CoreUrlProvider = new CoreUrlProvider()
  const objectsPresentationLogicParameters: ObjectsPresentationLogicParameters = useObjectsPresentationLogicParameters()

  const viewModel: ProductsViewModel = useViewModel(() => provideViewModel({
    objectsPresentationLogicParameters
  }))

  return (
    <ObjectsComponent
      title={coreTextProvider.products()}
      getObjectUrl={(product: Product) => coreUrlProvider.buildProductUrl({ id: product.id! })}
      newObjectUrl={coreUrlProvider.buildNewProductUrl()}
      observableObjectsPageViewState={viewModel.observableObjectsPageViewState}
      observableObjectsViewAction={viewModel.observableObjectsViewAction}
      onObjectsViewEvent={viewModel.onObjectsViewEvent}
    />
  )
}
