import React from "react"
import styles from "./ButtonComponent.module.scss"
import { Link } from "react-router-dom"
import assertNever from "../../lib/assertNever"
import isBlank from "../../lib/isBlank"

export default function ButtonComponent({
  text,
  isDisabled,
  onClick,
  url,
  type,
  leftIcon,
  buttonStyle
}: {
  readonly text?: string
  readonly url?: string
  readonly isDisabled?: boolean
  readonly onClick?: (event: React.MouseEvent) => void
  readonly buttonStyle: ButtonStyle
  readonly leftIcon?: React.ReactNode
  readonly type?: ButtonType
}) {
  function detectStyle(): string {
    switch (buttonStyle) {
      case ButtonStyle.TEXT1_PRIMARY:
        return `${styles.text1} ${styles.primary}`
      case ButtonStyle.TEXT1_ERROR:
        return `${styles.text1} ${styles.error}`
      case ButtonStyle.FILLED1_PRIMARY:
        return `${styles.filled1} ${styles.primary}`
      case ButtonStyle.FILLED1_SECONDARY:
        return `${styles.filled1} ${styles.secondary}`
      case ButtonStyle.OUTLINED1_PRIMARY:
        return `${styles.outlined1} ${styles.primary}`
      default:
        assertNever(buttonStyle)
    }
  }

  return (
    <>
      {url && (
        <Link to={url} className={detectStyle()}>
          <div>{text}</div>
        </Link>
      )}
      {!url && (
        <button
          type={type ?? ButtonType.BUTTON}
          className={`${detectStyle()} ${isBlank(text) ? ` ${styles.iconOnly}` : ""}`}
          disabled={isDisabled}
          onClick={onClick}
        >
          {text && (<div>
              {text}
            </div>
          )}
          {leftIcon && leftIcon}
        </button>
      )}
    </>
  )
}

export enum ButtonStyle {
  TEXT1_PRIMARY,
  TEXT1_ERROR,
  OUTLINED1_PRIMARY,
  FILLED1_PRIMARY,
  FILLED1_SECONDARY
}

export enum ButtonType {
  BUTTON = "button",
  SUBMIT = "submit"
}
