import NetworkDashboard from "../entities/dashboards/NetworkDashboard"
import Dashboard from "../../domain/entities/dashboards/Dashboard"
import BlocksMapper from "./BlocksMapper"

export default class DashboardsMapper {
  mapNetworkToDomain({
    dashboard
  }: {
    readonly dashboard: NetworkDashboard
  }): Dashboard {
    return {
      id: dashboard.id,
      code: dashboard.code,
      name: dashboard.name,
      blocks: dashboard.blocks && dashboard.blocks.map(block => {
        return new BlocksMapper().mapNetworkToDomain({ block })
      })
    }
  }

  mapDomainToNetwork({
    dashboard
  }: {
    readonly dashboard: Dashboard
  }): NetworkDashboard {
    return new NetworkDashboard({
      id: dashboard.id,
      code: dashboard.code,
      name: dashboard.name,
      blocks: dashboard.blocks && dashboard.blocks.map(block => {
        return new BlocksMapper().mapDomainToNetwork({ block })
      })
    })
  }
}
