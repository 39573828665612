import React from "react"
import { BooleanFormFieldViewState } from "../../../entities/form-fields/form-field-by-type/BooleanFormField"
import CheckboxComponent from "../../../../../../design/checkbox/CheckboxComponent"
import styles from "../decimal-form-field/DecimalFormFieldComponent.module.scss"

export default function BooleanFormFieldComponent({
  fieldViewState,
  isDisabled
}: {
  readonly fieldViewState: BooleanFormFieldViewState
  readonly isDisabled: boolean
}) {
  function handleOnChange(value: boolean) {
    fieldViewState.onChange(value)
  }

  return (
    <div className={styles.root}>
      <CheckboxComponent
        value={fieldViewState.value ?? false}
        isDisabled={isDisabled}
        onChange={handleOnChange}
        text={fieldViewState.text}
      />
    </div>
  )
}
