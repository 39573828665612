import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../errors/NetworkAttributeError"
import ProductsSetErrorsObjectAttributes from "../../../domain/entities/products-sets/ProductsSetErrorsObjectAttributes"

export default class NetworkProductsSetErrorsObjectAttributes implements ProductsSetErrorsObjectAttributes {
  @Expose()
  @Type(() => NetworkAttributeError)
  readonly productCategoryId: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly productsSetProducts: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly productsSetProperties: NetworkAttributeError[] | null | undefined
}
