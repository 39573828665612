import { CreateObjectResult } from "../../../../../sqadmin/features/objects/domain/results/CreateObjectResult"
import OptionsRepository from "../repositories/OptionsRepository"
import Option from "../../../../core/domain/entities/options/Option"
import OptionError from "../../../../core/domain/entities/options/OptionError"

export default class CreateOptionUseCase {
  private readonly optionsRepository: OptionsRepository

  constructor(parameters: {
    readonly optionsRepository: OptionsRepository
  }) {
    this.optionsRepository = parameters.optionsRepository
  }

  async call(parameters: {
    readonly option: Option
  }): Promise<CreateObjectResult<Option, OptionError>> {
    return await this.optionsRepository.createOption(parameters)
  }
}
