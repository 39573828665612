import React from "react"
import DiModule from "../../../../../sqadmin/lib/di/DiModule"
import ObjectsDomainDiModule from "../../../../../sqadmin/features/objects/di/modules/ObjectsDomainDiModule"
import CoreI18nDiModule from "../../../../core/di/modules/CoreI18nDiModule"
import autoBind from "auto-bind"
import ObjectsPresentationLogicParameters
  from "../../../../../sqadmin/features/objects/presentation/entities/objects/ObjectsPresentationLogicParameters"
import OrderPaymentMethodsViewModel from "../../presentation/pages/order-payment-methods-page/OrderPaymentMethodsViewModel"
import OrderPaymentMethodPage from "../../presentation/pages/order-payment-method-page/OrderPaymentMethodPage"
import OrderPaymentMethodsPage from "../../presentation/pages/order-payment-methods-page/OrderPaymentMethodsPage"
import OrderPaymentMethodViewModel from "../../presentation/pages/order-payment-method-page/OrderPaymentMethodViewModel"
import OrderPaymentMethodsCoreDomainDiModule
  from "../../../order-payment-methods-core/di/OrderPaymentMethodsCoreDomainDiModule"
import OrderReceivingMethodsCoreDomainDiModule
  from "../../../order-receiving-methods-core/di/OrderReceivingMethodsCoreDomainDiModule"

export default interface OrderPaymentMethodsPresentationDiModule {
  provideOrderPaymentMethodsPage(): React.ReactNode

  provideOrderPaymentMethodPage(): React.ReactNode
}

export class DefaultOrderPaymentMethodsPresentationDiModule
  extends DiModule
  implements OrderPaymentMethodsPresentationDiModule {

  private readonly objectsDomainDiModule: ObjectsDomainDiModule
  private readonly orderPaymentMethodsCoreDomainDiModule: OrderPaymentMethodsCoreDomainDiModule
  private readonly orderReceivingMethodsCoreDomainDiModule: OrderReceivingMethodsCoreDomainDiModule
  private readonly coreI18nDiModule: CoreI18nDiModule

  constructor(parameters: {
    readonly objectsDomainDiModule: ObjectsDomainDiModule
    readonly orderPaymentMethodsCoreDomainDiModule: OrderPaymentMethodsCoreDomainDiModule
    readonly orderReceivingMethodsCoreDomainDiModule: OrderReceivingMethodsCoreDomainDiModule
    readonly coreI18nDiModule: CoreI18nDiModule
  }) {
    super()
    autoBind(this)
    this.objectsDomainDiModule = parameters.objectsDomainDiModule
    this.orderPaymentMethodsCoreDomainDiModule = parameters.orderPaymentMethodsCoreDomainDiModule
    this.orderReceivingMethodsCoreDomainDiModule = parameters.orderReceivingMethodsCoreDomainDiModule
    this.coreI18nDiModule = parameters.coreI18nDiModule
  }

  provideOrderPaymentMethodsPage(): React.ReactNode {
    return <OrderPaymentMethodsPage provideViewModel={this.provideOrderPaymentMethodsViewModel} />
  }

  provideOrderPaymentMethodPage(): React.ReactNode {
    return <OrderPaymentMethodPage provideViewModel={this.provideOrderPaymentMethodViewModel} />
  }

  private provideOrderPaymentMethodsViewModel({
    objectsPresentationLogicParameters
  }: {
    readonly objectsPresentationLogicParameters: ObjectsPresentationLogicParameters
  }): OrderPaymentMethodsViewModel {
    return new OrderPaymentMethodsViewModel({
      coreI18n: this.coreI18nDiModule.provideCoreI18n(),
      objectsPresentationLogicParameters,
      subscribeToObjectsEventsUseCase: this.objectsDomainDiModule.provideSubscribeToObjectsEventsUseCase({
        key: "orderPaymentMethods"
      }),
      unsubscribeFromObjectsEventsUseCase: this.objectsDomainDiModule.provideUnsubscribeFromObjectsEventsUseCase({
        key: "orderPaymentMethods"
      }),
      getOrderPaymentMethodsUseCase: this.orderPaymentMethodsCoreDomainDiModule
        .provideGetOrderPaymentMethodsUseCase()
    })
  }

  private provideOrderPaymentMethodViewModel({
    orderPaymentMethodId
  }: {
    readonly orderPaymentMethodId?: number
  }): OrderPaymentMethodViewModel {
    return new OrderPaymentMethodViewModel({
      coreI18n: this.coreI18nDiModule.provideCoreI18n(),
      broadcastObjectsEventUseCase: this.objectsDomainDiModule.provideBroadcastObjectsEventUseCase({
        key: "orderPaymentMethods"
      }),
      getOrderPaymentMethodeUseCase: this.orderPaymentMethodsCoreDomainDiModule
        .provideGetOrderPaymentMethodUseCase(),
      createOrderPaymentMethodUseCase: this.orderPaymentMethodsCoreDomainDiModule
        .provideCreateOrderPaymentMethodUseCase(),
      updateOrderPaymentMethodUseCase: this.orderPaymentMethodsCoreDomainDiModule
        .provideUpdateOrderPaymentMethodUseCase(),
      destroyOrderPaymentMethodUseCase: this.orderPaymentMethodsCoreDomainDiModule
        .provideDestroyOrderPaymentMethodUseCase(),
      getPaymentProvidersUseCase: this.orderPaymentMethodsCoreDomainDiModule.provideGetPaymentProvidersUseCase(),
      getOrderReceivingMethodsUseCase: this.orderReceivingMethodsCoreDomainDiModule
        .provideGetOrderReceivingMethodsUseCase(),
      orderPaymentMethodId: orderPaymentMethodId
    })
  }
}
