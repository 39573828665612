import { RouteObject } from "react-router-dom"
import CoreUrlProvider from "../../../core/presentation/services/CoreUrlProvider"
import { objectIdUrlPart } from "../../../../sqadmin/features/objects/presentation/hooks/useObjectId"
import OrderReceivingMethodsDi from "../di/OrderReceivingMethodsDi"

export default function createOrderReceivingMethodsRoutes({
  di
}: {
  readonly di: OrderReceivingMethodsDi
}): RouteObject[] {
  const urlProvider = new CoreUrlProvider()
  return [
    {
      path: urlProvider.buildOrderReceivingMethodsPath(),
      element: di
        .sqCoreOrderReceivingMethodsDiComponent
        .orderReceivingMethodsPresentationDiModule
        .provideOrderReceivingMethodsPage()
    },
    {
      path: urlProvider.buildNewOrderReceivingMethodPath(),
      element: di
        .sqCoreOrderReceivingMethodsDiComponent
        .orderReceivingMethodsPresentationDiModule
        .provideOrderReceivingMethodPage()
    },
    {
      path: urlProvider.buildOrderReceivingMethodPath({ id: `:${objectIdUrlPart}` }),
      element: di
        .sqCoreOrderReceivingMethodsDiComponent
        .orderReceivingMethodsPresentationDiModule
        .provideOrderReceivingMethodPage()
    }
  ]
}
