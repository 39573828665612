import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../errors/NetworkAttributeError"
import {
  OrderReceivingErrorsObjectAttributes
} from "../../../domain/entities/order-receivings/OrderReceivingErrorsObjectAttributes"

export default class NetworkOrderReceivingErrorsObjectAttributes implements OrderReceivingErrorsObjectAttributes {
  @Expose()
  @Type(() => NetworkAttributeError)
  readonly id: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly place: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly method: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly settlement: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly address: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly recipient: NetworkAttributeError[] | null | undefined
}
