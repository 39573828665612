import UsersRepository from "../repositories/UsersRepository"
import UserError from "../../../../core/domain/entities/users/UserError"
import User from "../../../../core/domain/entities/users/User"
import { UpdateObjectResult } from "../../../../../sqadmin/features/objects/domain/results/UpdateObjectResult"

export default class UpdateUserUseCase {
  private readonly usersRepository: UsersRepository

  constructor(parameters: {
    readonly usersRepository: UsersRepository
  }) {
    this.usersRepository = parameters.usersRepository
  }

  async call(parameters: {
    readonly userId: number
    readonly user: User
  }): Promise<UpdateObjectResult<User, UserError>> {
    return await this.usersRepository.updateUser(parameters)
  }
}
