import { GetObjectsPageResult } from "../../../../../sqadmin/features/objects/domain/results/GetObjectsPageResult"
import Sort from "../../../../../sqadmin/features/objects/presentation/entities/tables/Sort"
import LastItemPagination from "../../../../core/domain/entities/paginations/LastItemPagination"
import ApiLogItemsRepository from "../repositories/ApiLogItemsRepository"
import ApiLogItem from "../../../../core/domain/entities/api-log-items/ApiLogItem"
import ApiLogItemsFilter from "../../../../core/domain/entities/api-log-items/ApiLogItemsFilter"

export default class GetApiLogItemsUseCase {
  private readonly apiLogItemsRepository: ApiLogItemsRepository

  constructor(parameters: {
    readonly apiLogItemsRepository: ApiLogItemsRepository
  }) {
    this.apiLogItemsRepository = parameters.apiLogItemsRepository
  }

  async call(parameters: GetApiLogItemsParameters): Promise<GetObjectsPageResult<ApiLogItem>> {
    return this.apiLogItemsRepository.getApiLogItems(parameters)
  }
}

export interface GetApiLogItemsParameters {
  readonly filter: ApiLogItemsFilter | null | undefined
  readonly sort: Sort | null | undefined
  readonly query: string | null | undefined
  readonly pagination?: LastItemPagination
}
