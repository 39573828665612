import KeyValueStorage from "../../storages/KeyValueStorage"
import LocalSession from "../../entities/local/sessions/LocalSession"
import { instanceToPlain, plainToInstance } from "class-transformer"

const sessionKey = "SESSION"

export default class SessionLocalSource {
  private readonly keyValueStorage: KeyValueStorage

  constructor(parameters: {
    readonly keyValueStorage: KeyValueStorage
  }) {
    this.keyValueStorage = parameters.keyValueStorage
  }

  isSessionExist(): boolean {
    return this.keyValueStorage.isSet(sessionKey)
  }

  saveSession({
    session
  }: {
    readonly session: LocalSession
  }) {
    const sessionJson: object = instanceToPlain(session, { strategy: "excludeAll" })
    const sessionJsonString: string = JSON.stringify(sessionJson)
    this.keyValueStorage.put(sessionKey, sessionJsonString)
  }

  getSession(): LocalSession | null {
    const sessionJsonString: string | null = this.keyValueStorage.get(sessionKey)
    return sessionJsonString === null ?
      null :
      plainToInstance(LocalSession, JSON.parse(sessionJsonString) as object)
  }

  deleteSession() {
    this.keyValueStorage.remove(sessionKey)
  }
}
