import ProductVariantErrorsObjectAttributes
  from "../../../domain/entities/product-variants/ProductVariantErrorsObjectAttributes"
import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../errors/NetworkAttributeError"

export default class NetworkProductVariantErrorsObjectAttributes implements ProductVariantErrorsObjectAttributes {

  @Expose({ name: "variantOptionValues" })
  @Type(() => NetworkAttributeError)
  readonly variantOptions?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly variantImages?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly product: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly externalCode: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly mainBatch: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly batches: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly hasBatches: NetworkAttributeError[] | null | undefined
}
