import React, { useMemo } from "react"
import AuthenticationComponent from "../../components/authentication/AuthenticationComponent"
import AuthenticationViewModel from "./AuthenticationViewModel"
import { Navigate } from "react-router-dom"
import assertNever from "../../../../../lib/assertNever"
import CoreUrlProvider from "../../../../../core/presentation/services/CoreUrlProvider"
import { useStateObservable } from "../../../../../lib/view-model/StateObservable"

interface Props {
  readonly provideViewModel: () => AuthenticationViewModel
}

export default function AuthenticationPage({ provideViewModel }: Props) {
  const viewModel = useMemo(provideViewModel, [])
  const authenticationViewState = useStateObservable(viewModel.authenticationStateObservable)
  const sqadminUrlProvider = new CoreUrlProvider()

  switch (authenticationViewState.type) {
    case "initial":
      return <></>
    case "not_authenticated":
      return (
        <AuthenticationComponent
          onEmailAddressChanged={viewModel.onEmailAddressChange}
          onPasswordChanged={viewModel.onPasswordChange}
          onAuthenticateClicked={viewModel.onAuthenticateClicked}
          user={authenticationViewState.user}
          isAuthenticating={authenticationViewState.isAuthenticating}
          authenticationError={authenticationViewState.authenticationError}
          authenticationFailureException={authenticationViewState.authenticationFailureException}
        />
      )
    case "already_authenticated":
    case "now_authenticated":
      return <Navigate to={sqadminUrlProvider.buildRootUrl()} replace={true} />
    default:
      assertNever(authenticationViewState)
  }
}
