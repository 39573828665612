import BackendHttpClient, { BackendHttpClientResult } from "../../network/BackendHttpClient"
import SuccessExecutionResult from "../../../../../sqadmin/core/domain/results/SuccessExecutionResult"
import ErrorExecutionResult from "../../../../../sqadmin/core/domain/results/ErrorExecutionResult"
import FailureExecutionResult from "../../../../../sqadmin/core/domain/results/FailureExecutionResult"
import NetworkExecutionError from "../../entities/errors/NetworkExecutionError"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import { instanceToPlain, plainToInstance } from "class-transformer"
import GetObjectNetworkResult from "../../results/GetObjectNetworkResult"
import NetworkDashboard from "../../entities/dashboards/NetworkDashboard"
import UpdateObjectNetworkResult from "../../results/UpdateObjectNetworkResult"
import NetworkDashboardError from "../../entities/dashboards/NetworkDashboardError"
import NetworkDashboardsResponseBody from "../../entities/dashboards/NetworkDashboardsResponseBody"
import NetworkDashboardResponseBody from "../../entities/dashboards/NetworkDashboardResponseBody"
import NetworkDashboardRequestBody from "../../entities/dashboards/NetworkDashboardRequestBody"
import NetworkLastItemPagination from "../../entities/pagination/NetworkLastItemPagination"
import NetworkDashboardsRequestFilter from "../../entities/dashboards/NetworkDashboardsRequestFilter"
import NetworkDashboardsRequestQuery from "../../entities/dashboards/NetworkDashboardsRequestQuery"
import CreateObjectNetworkResult from "../../results/CreateObjectNetworkResult"
import DestroyObjectNetworkResult from "../../results/DestroyObjectNetworkResult"

const basePath = "/admin/dashboards"

export default class DashboardsNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async getDashboards({
    filter,
    pagination,
    sort
  }: {
    readonly filter?: NetworkDashboardsRequestFilter | null
    readonly pagination?: NetworkLastItemPagination | null
    readonly sort?: string | null
  }): Promise<GetDashboardsNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: basePath,
      parameters: instanceToPlain(new NetworkDashboardsRequestQuery({
        filter,
        pagination,
        sort
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkDashboardsResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async getDashboard({
    dashboardId
  }: {
    readonly dashboardId: number
  }): Promise<GetObjectNetworkResult<NetworkDashboard>> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}/${dashboardId}`
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkDashboardResponseBody, result.body).dashboard!
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async updateDashboard({
    dashboardId,
    dashboard
  }: {
    readonly dashboardId: number
    readonly dashboard: NetworkDashboard
  }): Promise<UpdateObjectNetworkResult<NetworkDashboard, NetworkDashboardError>> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.PUT,
      path: `${basePath}/${dashboardId}`,
      body: instanceToPlain(new NetworkDashboardRequestBody({
        dashboard
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkDashboardResponseBody, result.body).dashboard!
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkDashboardError, result.body)
        }
      case "failure":
        return result
    }
  }

  async createDashboard({
    dashboard
  }: {
    readonly dashboard: NetworkDashboard
  }): Promise<CreateObjectNetworkResult<NetworkDashboard, NetworkDashboardError>> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.POST,
      path: basePath,
      body: instanceToPlain(new NetworkDashboardRequestBody({
        dashboard
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkDashboardResponseBody, result.body).dashboard!
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkDashboardError, result.body)
        }
      case "failure":
        return result
    }
  }

  async destroyDashboard({
    dashboardId
  }: {
    readonly dashboardId: number
  }): Promise<DestroyObjectNetworkResult<NetworkDashboardError>> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.DELETE,
      path: `${basePath}/${dashboardId}`
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: undefined
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkDashboardError, result.body)
        }
      case "failure":
        return result
    }
  }
}

export type GetDashboardsNetworkResult =
  SuccessExecutionResult<NetworkDashboardsResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult
