import React from "react"
import DiModule from "../../../../lib/di/DiModule"
import AuthenticationPage from "../../presentation/pages/authentication/AuthenticationPage"
import AuthenticationViewModel from "../../presentation/pages/authentication/AuthenticationViewModel"
import CoreDomainDiModule from "../../../../core/di/modules/CoreDomainDiModule"

export default interface AuthenticationPresentationDiModule {
  provideAuthenticationPage(): React.ReactNode
}

export class DefaultAuthenticationPresentationDiModule
  extends DiModule
  implements AuthenticationPresentationDiModule {

  private readonly coreDomainDiModule: CoreDomainDiModule

  constructor(parameters: {
    readonly coreDomainDiModule: CoreDomainDiModule
  }) {
    super()
    this.coreDomainDiModule = parameters.coreDomainDiModule
  }

  provideAuthenticationPage(): React.ReactNode {
    return (
      <AuthenticationPage
        provideViewModel={() => this.provideAuthenticationViewModel()}
      />
    )
  }

  private provideAuthenticationViewModel(): AuthenticationViewModel {
    return new AuthenticationViewModel({
      isSessionExistUseCase: this.coreDomainDiModule.provideIsSessionExistUseCase(),
      createSessionUseCase: this.coreDomainDiModule.provideCreateSessionUseCase()
    })
  }
}
