import ObjectsPresentationLogicParameters
  from "../../../../../../sqadmin/features/objects/presentation/entities/objects/ObjectsPresentationLogicParameters"
import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import { useCoreTextProvider } from "../../../../../core/presentation/contexts/CoreTextProviderContext"
import CoreUrlProvider from "../../../../../core/presentation/services/CoreUrlProvider"
import useObjectsPresentationLogicParameters
  from "../../../../../../sqadmin/features/objects/presentation/hooks/useObjectsPresentationLogicParameters"
import { useViewModel } from "../../../../../../sqadmin/lib/view-model/ViewModel"
import ObjectsComponent
  from "../../../../../../sqadmin/features/objects/presentation/components/objects/ObjectsComponent"
import React from "react"
import OrderReceivingMethod from "../../../../../core/domain/entities/order-receiving-methods/OrderReceivingMethod"
import OrderReceivingMethodsViewModel from "./OrderReceivingMethodsViewModel"

export interface OrderReceivingMethodsPageParameters {
  readonly provideViewModel: (parameters: {
    readonly objectsPresentationLogicParameters: ObjectsPresentationLogicParameters
  }) => OrderReceivingMethodsViewModel
}

export default function OrderReceivingMethodsPage({
  provideViewModel
}: OrderReceivingMethodsPageParameters) {
  const coreTextProvider: CoreTextProvider = useCoreTextProvider()
  const coreUrlProvider: CoreUrlProvider = new CoreUrlProvider()
  const objectsPresentationLogicParameters: ObjectsPresentationLogicParameters = useObjectsPresentationLogicParameters()

  const viewModel: OrderReceivingMethodsViewModel = useViewModel(() => provideViewModel({
    objectsPresentationLogicParameters
  }))

  function getRowUrl(orderReceivingMethod: OrderReceivingMethod): string {
    return coreUrlProvider.buildOrderReceivingMethodUrl({ id: orderReceivingMethod.id! })
  }

  return (
    <ObjectsComponent
      title={coreTextProvider.orderReceivingMethods()}
      getObjectUrl={getRowUrl}
      newObjectUrl={coreUrlProvider.buildNewOrderReceivingMethodUrl()}
      observableObjectsPageViewState={viewModel.observableObjectsPageViewState}
      observableObjectsViewAction={viewModel.observableObjectsViewAction}
      onObjectsViewEvent={viewModel.onObjectsViewEvent}
    />
  )
}
