import { Expose } from "class-transformer"
import NetworkDashboard from "./NetworkDashboard"

export default class NetworkDashboardRequestBody {
  @Expose()
  readonly dashboard: NetworkDashboard

  constructor(parameters: {
    readonly dashboard: NetworkDashboard
  }) {
    this.dashboard = parameters.dashboard
  }
}
