import { CreateObjectResult } from "../../../../../sqadmin/features/objects/domain/results/CreateObjectResult"
import Image from "../../../../core/domain/entities/images/Image"
import ImageError from "../../../../core/domain/entities/images/ImageError"
import ProductImagesRepository from "../../domain/repositories/ProductImagesRepository"
import CreateObjectNetworkResult from "../../../../core/data/results/CreateObjectNetworkResult"
import ProductImagesNetworkSource from "../../../../core/data/sources/network/ProductImagesNetworkSource"
import ImagesMapper from "../../../../core/data/mappers/ImagesMapper"
import NetworkImage from "../../../../core/data/entities/images/NetworkImage"
import NetworkImageError from "../../../../core/data/entities/images/NetworkImageError"

export default class DefaultProductImagesRepository implements ProductImagesRepository {
  private readonly productImagesNetworkSource: ProductImagesNetworkSource

  constructor(parameters: {
    readonly productImagesNetworkSource: ProductImagesNetworkSource
  }) {
    this.productImagesNetworkSource = parameters.productImagesNetworkSource
  }

  async createImage({
    file
  }: {
    readonly file: File
  }): Promise<CreateObjectResult<Image, ImageError>> {
    const result: CreateObjectNetworkResult<NetworkImage, NetworkImageError> =
      await this.productImagesNetworkSource.createImage({ file })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new ImagesMapper().mapNetworkToDomain({
            image: result.data
          })
        }
      default:
        return result
    }
  }

}
