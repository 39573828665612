import {
  Location,
  NavigateFunction,
  NavigationType,
  useLocation,
  useNavigate,
  useNavigationType
} from "react-router-dom"
import { useEffect } from "react"
import { v4 as uuidv4 } from "uuid"
import ViewModel from "./ViewModel"

const locationIdQueueKey = "locationIdQueue"
const viewModelByLocationId: { [key: string]: unknown } = {}

export interface LocationContextProvider {
  readonly locationId: string | undefined
  readonly getOrCreateViewModel: <ViewModel>(creator: () => ViewModel) => ViewModel
}

export default function useLocationContextProvider(): LocationContextProvider {
  const location: Location = useLocation()
  const locationId: string | undefined = location.state?.locationId as (string | undefined)
  const navigate: NavigateFunction = useNavigate()
  const navigationType: NavigationType = useNavigationType()

  useEffect(() => {
    if (locationId === undefined) {
      initAndSetLocationId()
      return
    }

    fixLocationIdQueue(locationId)
  }, [locationId])

  function initAndSetLocationId() {
    const newLocationId: string = uuidv4()

    navigate({
      pathname: location.pathname,
      search: location.search,
      hash: location.hash
    }, {
      replace: true,
      state: {
        ...location.state,
        locationId: newLocationId
      }
    })
  }

  function fixLocationIdQueue(locationId: string) {
    const locationIdQueue: string[] = getLocationIdQueue()
    const locationIdIndex: number = locationIdQueue.indexOf(locationId)
    const isKnownLocationId = locationIdIndex !== -1
    const isBack = navigationType === NavigationType.Pop && isKnownLocationId

    if (isBack) {
      const removedLocationIds: string[] = locationIdQueue.splice(locationIdIndex + 1)

      removedLocationIds.forEach((removedLocationId: string) => {
        const viewModel: ViewModel | undefined = viewModelByLocationId[removedLocationId] as (ViewModel | undefined)
        if (viewModel !== undefined) {
          viewModel.destroy()
          delete viewModelByLocationId[removedLocationId]
        }
      })
    }

    if (!isKnownLocationId) {
      locationIdQueue.push(locationId)
    }

    setLocationIdQueue(locationIdQueue)
  }

  function getOrCreateViewModel<ViewModel>(creator: () => ViewModel): ViewModel {
    let existedViewModel: ViewModel | undefined = viewModelByLocationId[locationId!] as (ViewModel | undefined)

    if (existedViewModel === undefined) {
      existedViewModel = creator()
      viewModelByLocationId[locationId!] = existedViewModel
    }

    return existedViewModel
  }

  return {
    locationId,
    getOrCreateViewModel
  }
}

function getLocationIdQueue(): string[] {
  const jsonString: string | null = sessionStorage.getItem(locationIdQueueKey)

  if (jsonString === null) {
    return []
  }

  return JSON.parse(jsonString) as string[]
}

function setLocationIdQueue(locationIdQueue: string[]) {
  sessionStorage.setItem(locationIdQueueKey, JSON.stringify(locationIdQueue))
}
