import BackendHttpClient, { BackendHttpClientResult } from "../../../../core/data/network/BackendHttpClient"
import NetworkLastItemPagination from "../../../../core/data/entities/pagination/NetworkLastItemPagination"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import { instanceToPlain, plainToInstance } from "class-transformer"
import NetworkExecutionError from "../../../../core/data/entities/errors/NetworkExecutionError"
import GetObjectNetworkResult from "../../../../core/data/results/GetObjectNetworkResult"
import UpdateObjectNetworkResult from "../../../../core/data/results/UpdateObjectNetworkResult"
import SuccessExecutionResult from "../../../../../sqadmin/core/domain/results/SuccessExecutionResult"
import ErrorExecutionResult from "../../../../../sqadmin/core/domain/results/ErrorExecutionResult"
import FailureExecutionResult from "../../../../../sqadmin/core/domain/results/FailureExecutionResult"
import NetworkUser from "../../../../core/data/entities/users/NetworkUser"
import NetworkUsersRequestFilter from "../entities/NetworkUsersRequestFilter"
import NetworkUsersRequestQuery from "../entities/NetworkUsersRequestQuery"
import NetworkUsersResponseBody from "../entities/NetworkUsersResponseBody"
import NetworkUserResponseBody from "../entities/NetworkUserResponseBody"
import NetworkUserError from "../../../../core/data/entities/users/NetworkUserError"
import NetworkUserRequestBody from "../entities/NetworkUserRequestBody"
import CreateObjectNetworkResult from "../../../../core/data/results/CreateObjectNetworkResult"
import DestroyObjectNetworkResult from "../../../../core/data/results/DestroyObjectNetworkResult"

const basePath = "/admin/users"

export default class UsersNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async getUsers({
    filter,
    pagination,
    sort
  }: {
    readonly filter?: NetworkUsersRequestFilter | null
    readonly pagination?: NetworkLastItemPagination | null
    readonly sort?: string | null
  }): Promise<GetUsersNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: basePath,
      parameters: instanceToPlain(new NetworkUsersRequestQuery({
        filter,
        pagination,
        sort
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkUsersResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async getUser({
    userId
  }: {
    readonly userId: number
  }): Promise<GetObjectNetworkResult<NetworkUser>> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}/${userId}`
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkUserResponseBody, result.body).user!
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async updateUser({
    userId,
    user
  }: {
    readonly userId: number
    readonly user: NetworkUser
  }): Promise<UpdateObjectNetworkResult<NetworkUser, NetworkUserError>> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.PUT,
      path: `${basePath}/${userId}`,
      body: instanceToPlain(new NetworkUserRequestBody({
        user
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkUserResponseBody, result.body).user!
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkUserError, result.body)
        }
      case "failure":
        return result
    }
  }

  async createUser({
    user
  }: {
    readonly user: NetworkUser
  }): Promise<CreateObjectNetworkResult<NetworkUser, NetworkUserError>> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.POST,
      path: basePath,
      body: instanceToPlain(new NetworkUserRequestBody({
        user
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkUserResponseBody, result.body).user!
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkUserError, result.body)
        }
      case "failure":
        return result
    }
  }

  async destroyUser({
    userId
  }: {
    readonly userId: number
  }): Promise<DestroyObjectNetworkResult<NetworkUserError>> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.DELETE,
      path: `${basePath}/${userId}`
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: undefined
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkUserError, result.body)
        }
      case "failure":
        return result
    }
  }
}

export type GetUsersNetworkResult =
  SuccessExecutionResult<NetworkUsersResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult
