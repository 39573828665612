import { Expose, Type } from "class-transformer"
import NetworkConfigurationEntity from "./NetworkConfigurationEntity"

export default class NetworkConfiguration {
  @Expose()
  @Type(() => NetworkConfigurationEntity)
  readonly entities: NetworkConfigurationEntity[] | null | undefined

  constructor(parameters?: {
    readonly entities: NetworkConfigurationEntity[] | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.entities = parameters.entities
  }
}
