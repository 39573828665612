import { GetObjectsPageResult } from "../../../../../sqadmin/features/objects/domain/results/GetObjectsPageResult"
import LastItemPaginationsMapper from "../../../../core/data/mappers/LastItemPaginationsMapper"
import SortMapper from "../../../../core/data/mappers/SortMapper"
import PagesMapper from "../../../../core/data/mappers/PagesMapper"
import { LoadObjectResult } from "../../../../../sqadmin/features/objects/domain/results/LoadObjectResult"
import GetObjectNetworkResult from "../../../../core/data/results/GetObjectNetworkResult"
import { CreateObjectResult } from "../../../../../sqadmin/features/objects/domain/results/CreateObjectResult"
import CreateObjectNetworkResult from "../../../../core/data/results/CreateObjectNetworkResult"
import { UpdateObjectResult } from "../../../../../sqadmin/features/objects/domain/results/UpdateObjectResult"
import UpdateObjectNetworkResult from "../../../../core/data/results/UpdateObjectNetworkResult"
import { DestroyObjectResult } from "../../../../../sqadmin/features/objects/domain/results/DestroyObjectResult"
import DestroyObjectNetworkResult from "../../../../core/data/results/DestroyObjectNetworkResult"
import OrderPaymentMethodsRepository from "../../domain/repositories/OrderPaymentMethodsRepository"
import OrderPaymentMethodsNetworkSource, {
  GetOrderPaymentMethodsNetworkResult,
  GetPaymentProvidersNetworkResult
} from "../sources/OrderPaymentMethodsNetworkSource"
import OrderPaymentMethodsFilterMapper from "../mappers/OrderPaymentMethodsFilterMapper"
import { GetOrderPaymentMethodsParameters } from "../../domain/use-cases/GetOrderPaymentMethodsUseCase"
import { UpdateOrderPaymentMethodParameters } from "../../domain/use-cases/UpdateOrderPaymentMethodUseCase"
import NetworkOrderPaymentMethod from "../../../../core/data/entities/order-payment-methods/NetworkOrderPaymentMethod"
import OrderPaymentMethodsMapper from "../../../../core/data/mappers/OrderPaymentMethodsMapper"
import OrderPaymentMethod from "../../../../core/domain/entities/order-payment-methods/OrderPaymentMethod"
import isPresent from "../../../../../sqadmin/lib/isPresent"
import OrderPaymentMethodError from "../../../../core/domain/entities/order-payment-methods/OrderPaymentMethodError"
import NetworkOrderPaymentMethodError
  from "../../../../core/data/entities/order-payment-methods/NetworkOrderPaymentMethodError"
import PaymentProvider from "../../../../core/domain/entities/payment-providers/PaymentProvider"
import NetworkPaymentProvider from "../../../../core/data/entities/payment-providers/NetworkPaymentProvider"
import PaymentProvidersMapper from "../../../../core/data/mappers/PaymentProvidersMapper"

export default class DefaultOrderPaymentMethodsRepository implements OrderPaymentMethodsRepository {
  private readonly orderPaymentMethodsNetworkSource: OrderPaymentMethodsNetworkSource

  constructor(parameters: {
    readonly orderPaymentMethodsNetworkSource: OrderPaymentMethodsNetworkSource
  }) {
    this.orderPaymentMethodsNetworkSource = parameters.orderPaymentMethodsNetworkSource
  }

  async getOrderPaymentMethods({
    query,
    pagination,
    sort
  }: GetOrderPaymentMethodsParameters): Promise<GetObjectsPageResult<OrderPaymentMethod>> {
    const result: GetOrderPaymentMethodsNetworkResult = await this.orderPaymentMethodsNetworkSource
      .getOrderPaymentMethods({
        filter: isPresent(query) ? new OrderPaymentMethodsFilterMapper().mapDomainToNetwork({ query }) : undefined,
        pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({ pagination }),
        sort: sort && new SortMapper().mapDomainToNetwork({ sort })
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: {
            objects: result.data.orderPaymentMethods!.map((orderPaymentMethod: NetworkOrderPaymentMethod) => {
              return new OrderPaymentMethodsMapper().mapNetworkToDomain({
                orderPaymentMethod: orderPaymentMethod
              })
            }),
            page: new PagesMapper().mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      default:
        return result
    }
  }

  async getOrderPaymentMethod({
    orderPaymentMethodId
  }: {
    readonly orderPaymentMethodId: number
  }): Promise<LoadObjectResult<OrderPaymentMethod>> {
    const result: GetObjectNetworkResult<NetworkOrderPaymentMethod> =
      await this.orderPaymentMethodsNetworkSource.getOrderPaymentMethod({
        orderPaymentMethodId: orderPaymentMethodId
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new OrderPaymentMethodsMapper().mapNetworkToDomain({
            orderPaymentMethod: result.data
          })
        }
      default:
        return result
    }
  }

  async createOrderPaymentMethod({
    orderPaymentMethod
  }: {
    readonly orderPaymentMethod: OrderPaymentMethod
  }): Promise<CreateObjectResult<OrderPaymentMethod, OrderPaymentMethodError>> {
    const result: CreateObjectNetworkResult<NetworkOrderPaymentMethod, NetworkOrderPaymentMethodError> =
      await this.orderPaymentMethodsNetworkSource.createOrderPaymentMethod({
        orderPaymentMethod: new OrderPaymentMethodsMapper().mapDomainToNetwork({
          orderPaymentMethod: orderPaymentMethod
        })
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new OrderPaymentMethodsMapper().mapNetworkToDomain({
            orderPaymentMethod: result.data
          })
        }
      default:
        return result
    }
  }

  async updateOrderPaymentMethod({
      orderPaymentMethodId,
      orderPaymentMethod
    }: UpdateOrderPaymentMethodParameters
  ): Promise<UpdateObjectResult<OrderPaymentMethod, OrderPaymentMethodError>> {
    const result: UpdateObjectNetworkResult<NetworkOrderPaymentMethod, NetworkOrderPaymentMethodError> =
      await this.orderPaymentMethodsNetworkSource.updateOrderPaymentMethod({
        orderPaymentMethodId: orderPaymentMethodId,
        orderPaymentMethod: new OrderPaymentMethodsMapper().mapDomainToNetwork({
          orderPaymentMethod: orderPaymentMethod
        })
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new OrderPaymentMethodsMapper().mapNetworkToDomain({
            orderPaymentMethod: result.data
          })
        }
      default:
        return result
    }
  }

  async destroyOrderPaymentMethod({
    orderPaymentMethodId
  }: {
    readonly orderPaymentMethodId: number
  }): Promise<DestroyObjectResult<OrderPaymentMethodError>> {
    const result: DestroyObjectNetworkResult<NetworkOrderPaymentMethodError> =
      await this.orderPaymentMethodsNetworkSource.destroyOrderPaymentMethod({
        orderPaymentMethodId: orderPaymentMethodId
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: undefined
        }
      default:
        return result
    }
  }

  async getPaymentProviders(): Promise<GetObjectsPageResult<PaymentProvider>> {
    const result: GetPaymentProvidersNetworkResult = await this.orderPaymentMethodsNetworkSource
      .getPaymentProviders()

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: {
            objects: result.data.paymentProviders!.map((paymentProvider: NetworkPaymentProvider) => {
              return new PaymentProvidersMapper().mapNetworkToDomain({
                paymentProvider: paymentProvider
              })
            }),
            page: new PagesMapper().mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      default:
        return result
    }
  }
}
