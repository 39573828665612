import NetworkProductVariant from "../entities/product-variants/NetworkProductVariant"
import ProductVariant from "../../domain/entities/product-variants/ProductVariant"
import { v4 as uuidv4 } from "uuid"
import VariantOptionsMapper from "./VariantOptionsMapper"
import VariantImagesMapper from "./VariantImagesMapper"
import ProductsMapper from "./ProductsMapper"
import BatchesMapper from "./BatchesMapper"

export default class ProductVariantsMapper {
  mapNetworkToDomain({
    productVariant
  }: {
    readonly productVariant: NetworkProductVariant
  }): ProductVariant {
    return {
      id: productVariant.id,
      externalCode: productVariant.externalCode,
      clientId: uuidv4(),
      variantOptions: productVariant.variantOptions && productVariant.variantOptions.map(variantOption => {
        return new VariantOptionsMapper().mapNetworkToDomain({ variantOption })
      }),
      variantImages: productVariant.variantImages && productVariant.variantImages.map(variantImage => {
        return new VariantImagesMapper().mapNetworkToDomain({ variantImage })
      }),
      mainBatch: productVariant.mainBatch && new BatchesMapper()
        .mapNetworkToDomain({ batch: productVariant.mainBatch }),
      batches: productVariant.batches && productVariant.batches.map(batch => {
        return new BatchesMapper().mapNetworkToDomain({ batch })
      }),
      product: productVariant.product && new ProductsMapper()
        .mapNetworkToDomain({ product: productVariant.product })
    }
  }

  mapDomainToNetwork({
    productVariant
  }: {
    readonly productVariant: ProductVariant
  }): NetworkProductVariant {
    return new NetworkProductVariant({
      id: productVariant.id,
      externalCode: productVariant.externalCode,
      clientId: productVariant.clientId,
      variantOptions: productVariant.variantOptions && productVariant.variantOptions.map(variantOption => {
        return new VariantOptionsMapper().mapDomainToNetwork({ variantOption })
      }),
      variantImages: productVariant.variantImages && productVariant.variantImages.map(variantImage => {
        return new VariantImagesMapper().mapDomainToNetwork({ variantImage })
      }),
      mainBatch: productVariant.mainBatch && new BatchesMapper()
        .mapDomainToNetwork({ batch: productVariant.mainBatch }),
      batches: productVariant.batches && productVariant.batches.map(batch => {
        return new BatchesMapper().mapDomainToNetwork({ batch })
      }),
      product: productVariant.product && new ProductsMapper()
        .mapDomainToNetwork({ product: productVariant.product })
    })
  }
}
