import { Expose, Type } from "class-transformer"
import NetworkProductPropertyErrorsObjectAttributes from "./NetworkProductPropertyErrorsObjectAttributes"
import ProductPropertyErrorsObject from "../../../domain/entities/product-properties/ProductPropertyErrorsObject"

export default class NetworkProductPropertyErrorsObject implements ProductPropertyErrorsObject {
  @Expose()
  readonly clientId: string | null | undefined

  @Expose()
  @Type(() => NetworkProductPropertyErrorsObjectAttributes)
  readonly attributes: NetworkProductPropertyErrorsObjectAttributes | null | undefined
}
