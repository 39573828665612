import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../errors/NetworkAttributeError"
import BonusProgramLevelErrorsObjectAttributes
  from "../../../domain/entities/bonus-program-levels/BonusProgramLevelErrorsObjectAttributes"
import AttributeError from "../../../../../sqadmin/core/domain/entities/AttributeError"

export default class NetworkBonusProgramLevelErrorsObjectAttributes implements BonusProgramLevelErrorsObjectAttributes {
  @Expose()
  @Type(() => NetworkAttributeError)
  readonly name: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly bonusPointsPercent: AttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly id: AttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly clientId: AttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly position: AttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly transitionRule: AttributeError[] | null | undefined
}
