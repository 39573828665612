import React, { useRef } from "react"
import CoreTextProvider from "../../../i18n/CoreTextProvider"
import { useCoreTextProvider } from "../../contexts/CoreTextProviderContext"
import SearchBarComponent, { SearchBarComponentRef } from "../search-bar/SearchBarComponent"
import styles from "./AppMultiSelectPickerComponent.module.scss"
import Page from "../../../domain/entities/Page"
import isPresent from "../../../../lib/isPresent"
import PaginationComponent from "../../../../features/objects/presentation/components/pagination/PaginationComponent"
import MultiSelectPickerComponent, {
  MultiSelectPickerComponentProps
} from "../multi-select-picker/MultiSelectPickerComponent"
import PickerNoDataComponent from "../picker-no-data/PickerNoDataComponent"

export default function AppMultiSelectPickerComponent(props: MultiSelectPickerComponentProps & {
  readonly query?: string | null
  readonly page?: Page
  readonly onQueryChanged: (query: string) => void
  readonly onSearchRequested: () => void
  readonly onNextPageRequested: () => void
}) {
  const searchBarRef: React.RefObject<SearchBarComponentRef> = useRef<SearchBarComponentRef>(null)
  const coreTextProvider: CoreTextProvider = useCoreTextProvider()

  function focusOnSearchBarInput() {
    searchBarRef.current?.focus()
  }

  function handleOnOpened() {
    focusOnSearchBarInput()
    props.onOpened && props.onOpened()
  }

  function requestNextPage() {
    if (!props.isLoading && isPresent(props.page) && props.page.hasMore) {
      props.onNextPageRequested()
    }
  }

  return (
    <MultiSelectPickerComponent
      {...props}
      selectButtonText={props.selectButtonText ?? coreTextProvider.select()}
      onOpened={handleOnOpened}
      aboveOptionsElement={
        <>
          <div className={styles.searchBar}>
            <SearchBarComponent
              ref={searchBarRef}
              query={props.query ?? ""}
              onQueryChanged={props.onQueryChanged}
              onSearchRequested={props.onSearchRequested}
            />
          </div>
          {!props.isLoading && props.options.length === 0 && (<PickerNoDataComponent />)}
        </>
      }
      belowOptionsElement={
        <>
          <PaginationComponent onComponentAppeared={requestNextPage} />
        </>
      }
    />
  )
}
