import React from "react"
import DecimalTableValue from "../../../entities/tables/table-value-by-type/DecimalTableValue"

export default function DecimalTableValueComponent({
  decimalTableValue
}: {
  readonly decimalTableValue: DecimalTableValue
}) {
  return <span>{decimalTableValue.getValue()?.toFixed()}</span>
}
