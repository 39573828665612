import { CreateObjectResult } from "../../../../../../sqadmin/features/objects/domain/results/CreateObjectResult"
import Image from "../../../../../core/domain/entities/images/Image"
import ImageError from "../../../../../core/domain/entities/images/ImageError"
import BannerImagesRepository from "../../repositories/BannerImagesRepository"

export default class CreateBannerImageUseCase {
  private readonly bannerImagesRepository: BannerImagesRepository

  constructor(parameters: {
    readonly bannerImagesRepository: BannerImagesRepository
  }) {
    this.bannerImagesRepository = parameters.bannerImagesRepository
  }

  async call({ file }: { readonly file: File }): Promise<CreateObjectResult<Image, ImageError>> {
    return await this.bannerImagesRepository.createImage({ file })
  }
}
