import { Expose, Type } from "class-transformer"
import NetworkLastItemPagination from "../pagination/NetworkLastItemPagination"
import NetworkOrdersRequestFilter from "./NetworkOrdersRequestFilter"

export default class NetworkOrdersRequestQuery {
  @Expose()
  @Type(() => NetworkOrdersRequestFilter)
  readonly filter: NetworkOrdersRequestFilter | null | undefined

  @Expose()
  @Type(() => NetworkLastItemPagination)
  readonly pagination: NetworkLastItemPagination | null | undefined

  @Expose()
  readonly sort: string | null | undefined

  constructor(parameters: {
    readonly filter: NetworkOrdersRequestFilter | null | undefined
    readonly pagination: NetworkLastItemPagination | null | undefined
    readonly sort: string | null | undefined
  }) {
    this.filter = parameters.filter
    this.pagination = parameters.pagination
    this.sort = parameters.sort
  }
}
