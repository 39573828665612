import ViewModel from "../../../../../../sqadmin/lib/view-model/ViewModel"
import BroadcastObjectsEventUseCase
  from "../../../../../../sqadmin/features/objects/domain/use-cases/objects/BroadcastObjectsEventUseCase"
import ObjectPresentationLogic
  from "../../../../../../sqadmin/features/objects/presentation/presentation-logics/ObjectPresentationLogic"
import { StateObservable } from "../../../../../../sqadmin/lib/view-model/StateObservable"
import { ObjectViewState } from "../../../../../../sqadmin/features/objects/presentation/view-states/ObjectViewState"
import isBlank from "../../../../../../sqadmin/lib/isBlank"
import ObjectViewEvent from "../../../../../../sqadmin/features/objects/presentation/view-events/ObjectViewEvent"
import ProductCategory from "../../../../../core/domain/entities/product-categories/ProductCategory"
import ProductCategoryError from "../../../../../core/domain/entities/product-categories/ProductCategoryError"
import ProductCategoryErrorsObject
  from "../../../../../core/domain/entities/product-categories/ProductCategoryErrorsObject"
import StringFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/StringFormField"
import autoBind from "auto-bind"
import Image from "../../../../../core/domain/entities/images/Image"
import ImageFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/ImageFormField"
import CoreI18n from "../../../../../core/i18n/CoreI18n"
import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import NumberFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/NumberFormField"
import CoreUrlProvider from "../../../../../core/presentation/services/CoreUrlProvider"
import ListFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/ListFormField"
import BonusPointsRule from "../../../../../core/domain/entities/bonus-points-rules/BonusPointsRule"
import { v4 as uuidv4 } from "uuid"
import BonusPointsRuleErrorsObject
  from "../../../../../core/domain/entities/bonus-points-rules/BonusPointsRuleErrorsObject"
import GetBonusProgramLevelsUseCase
  from "../../../../bonus-program-levels-core/domain/use-cases/GetBonusProgramLevelsUseCase"
import { BonusPointsRuleFields } from "../../../../../core/presentation/fields/BonusPointsRuleFields"
import CreateProductCategoryUseCase
  from "../../../../product-categories-core/domain/use-cases/product-categories/CreateProductCategoryUseCase"
import GetProductCategoryUseCase
  from "../../../../product-categories-core/domain/use-cases/product-categories/GetProductCategoryUseCase"
import UpdateProductCategoryUseCase
  from "../../../../product-categories-core/domain/use-cases/product-categories/UpdateProductCategoryUseCase"
import DestroyProductCategoryUseCase
  from "../../../../product-categories-core/domain/use-cases/product-categories/DestroyProductCategoryUseCase"
import CreateProductCategoryImageUseCase
  from "../../../../product-categories-core/domain/use-cases/images/CreateProductCategoryImageUseCase"

export default class ProductCategoryViewModel extends ViewModel {
  private readonly coreI18n: CoreI18n
  private readonly coreUrlProvider: CoreUrlProvider
  private readonly broadcastObjectsEventUseCase: BroadcastObjectsEventUseCase
  private readonly getProductCategoryUseCase: GetProductCategoryUseCase
  private readonly createProductCategoryUseCase: CreateProductCategoryUseCase
  private readonly updateProductCategoryUseCase: UpdateProductCategoryUseCase
  private readonly destroyProductCategoryUseCase: DestroyProductCategoryUseCase
  private readonly createProductCategoryImageUseCase: CreateProductCategoryImageUseCase
  private readonly getBonusProgramLevelsUseCase: GetBonusProgramLevelsUseCase
  private readonly productCategoryId?: number

  private readonly objectPresentationLogic: ObjectPresentationLogic<
    ProductCategory,
    ProductCategoryError,
    ProductCategoryErrorsObject
  >

  readonly observableObjectViewState: StateObservable<ObjectViewState>

  constructor(parameters: {
    readonly coreI18n: CoreI18n
    readonly broadcastObjectsEventUseCase: BroadcastObjectsEventUseCase
    readonly getProductCategoryUseCase: GetProductCategoryUseCase
    readonly createProductCategoryUseCase: CreateProductCategoryUseCase
    readonly updateProductCategoryUseCase: UpdateProductCategoryUseCase
    readonly destroyProductCategoryUseCase: DestroyProductCategoryUseCase
    readonly createProductCategoryImageUseCase: CreateProductCategoryImageUseCase
    readonly getBonusProgramLevelsUseCase: GetBonusProgramLevelsUseCase
    readonly productCategoryId?: number
  }) {
    super()
    this.coreI18n = parameters.coreI18n
    this.broadcastObjectsEventUseCase = parameters.broadcastObjectsEventUseCase
    this.getProductCategoryUseCase = parameters.getProductCategoryUseCase
    this.createProductCategoryUseCase = parameters.createProductCategoryUseCase
    this.updateProductCategoryUseCase = parameters.updateProductCategoryUseCase
    this.destroyProductCategoryUseCase = parameters.destroyProductCategoryUseCase
    this.getBonusProgramLevelsUseCase = parameters.getBonusProgramLevelsUseCase
    this.productCategoryId = parameters.productCategoryId
    this.createProductCategoryImageUseCase = parameters.createProductCategoryImageUseCase
    this.objectPresentationLogic = this.createObjectPresentationLogic()
    this.observableObjectViewState = this.objectPresentationLogic.observableObjectViewState
    this.coreUrlProvider = new CoreUrlProvider()
    autoBind(this)
  }

  onViewObjectEvent(objectViewEvent: ObjectViewEvent) {
    this.objectPresentationLogic.onObjectViewEvent(objectViewEvent)
  }

  private createObjectPresentationLogic(): ObjectPresentationLogic<
    ProductCategory,
    ProductCategoryError,
    ProductCategoryErrorsObject
  > {
    const coreTextProvider: CoreTextProvider = this.coreI18n.getTextProvider()
    return new ObjectPresentationLogic({
      // TODO: how to not pass?
      broadcastObjectsEventUseCase: this.broadcastObjectsEventUseCase,
      isNewObject: isBlank(this.productCategoryId),
      buildObject: async() => ({
        id: undefined,
        name: undefined,
        image: undefined,
        imageId: undefined,
        position: undefined,
        externalCode: undefined,
        bonusProgramRule: undefined
      }),
      getObjectUrl: (productCategory) => {
        return this.coreUrlProvider.buildProductCategoryUrl({
          id: productCategory.id!
        })
      },
      loadObject: async() => {
        return await this.getProductCategoryUseCase.call({ productCategoryId: this.productCategoryId! })
      },
      createObject: async({ object: productCategory }) => {
        return await this.createProductCategoryUseCase.call({ productCategory })
      },
      updateObject: async({ object: productCategory }) => {
        return await this.updateProductCategoryUseCase.call({
          productCategoryId: this.productCategoryId!,
          productCategory
        })
      },
      destroyObject: async() => {
        return await this.destroyProductCategoryUseCase.call({ productCategoryId: this.productCategoryId! })
      },
      getErrorsObject: ({ error: productCategoryError }) => productCategoryError
        ?.errorsObject,
      formFields: [
        new StringFormField<ProductCategory, ProductCategoryErrorsObject>({
          getTitle: () => coreTextProvider.name(),
          getValue: (productCategory: ProductCategory) => productCategory.name,
          setValue: (productCategory: ProductCategory, name: string) => ({
            ...productCategory,
            name
          }),
          getErrors: (productCategoryErrorsObject?: ProductCategoryErrorsObject) => productCategoryErrorsObject
            ?.attributes?.name
        }),
        new StringFormField<ProductCategory, ProductCategoryErrorsObject>({
          getDisabled: () => true,
          getTitle: () => coreTextProvider.externalCode(),
          getValue: (productCategory: ProductCategory) => productCategory.externalCode,
          setValue: (productCategory: ProductCategory, externalCode: string) => ({
            ...productCategory,
            externalCode
          }),
          getErrors: (productCategoryErrorsObject?: ProductCategoryErrorsObject) => productCategoryErrorsObject
            ?.attributes?.externalCode
        }),
        new NumberFormField<ProductCategory, ProductCategoryErrorsObject>({
          getTitle: () => coreTextProvider.position(),
          getValue: (productCategory: ProductCategory) => productCategory.position,
          setValue: (productCategory: ProductCategory, position: number | null) => ({
            ...productCategory,
            position
          }),
          getErrors: (productCategoryErrorsObject?: ProductCategoryErrorsObject) => productCategoryErrorsObject
            ?.attributes
            ?.position
        }),
        new ImageFormField<ProductCategory, ProductCategoryErrorsObject, Image>({
          getTitle: () => coreTextProvider.image(),
          getValue: (productCategory: ProductCategory) => productCategory.image,
          setValue: (productCategory: ProductCategory, image: Image | null | undefined) => {
            return {
              ...productCategory,
              image,
              imageId: image && image.id
            }
          },
          getErrors: (productCategoryErrorsObject?: ProductCategoryErrorsObject) => productCategoryErrorsObject
            ?.attributes?.imageId,
          getOptions: (image: Image) => {
            const imageVariant = image.listImageVariant
            return [
              {
                url: imageVariant?.url,
                width: imageVariant?.dimensions?.width,
                height: imageVariant?.dimensions?.height,
                /*когда появится от сервера превью заменить*/
                originalSizeUrl: imageVariant?.url,
                originalWidth: imageVariant?.dimensions?.width,
                originalHeight: imageVariant?.dimensions?.height,
                name: undefined
              }
            ]
          },
          createObject: async({ file }) => {
            return await this.createProductCategoryImageUseCase.call({ file })
          }
        }),
        new ListFormField<ProductCategory, ProductCategoryErrorsObject, BonusPointsRule, BonusPointsRuleErrorsObject>({
          getTitle: () => coreTextProvider.bonusPointsAccrual(),
          getValue: (productCategory: ProductCategory) => productCategory.bonusProgramRule?.bonusPointsRules,
          getErrors: (productCategoryErrorsObject?: ProductCategoryErrorsObject) => productCategoryErrorsObject
            ?.attributes
            ?.bonusProgramRule,
          setValue: (productCategory: ProductCategory, values: BonusPointsRule[] | null): ProductCategory => ({
            ...productCategory,
            bonusProgramRule: {
              ...productCategory.bonusProgramRule,
              id: productCategory.bonusProgramRule && productCategory.bonusProgramRule.id,
              bonusPointsRules: values
            }
          }),
          getNestedObjectId: (bonusPointsRule: BonusPointsRule) => bonusPointsRule.clientId!,
          getNestedObjectTitle: (_, index) => coreTextProvider.bonusPointsRule({ number: index + 1 }),
          getNestedErrorsObject: (
            bonusPointsRule: BonusPointsRule,
            productCategoryErrorsObject?: ProductCategoryErrorsObject
          ) => {
            return productCategoryErrorsObject?.bonusProgramRule?.bonusPointsRules?.find(
              (bonusPointsRuleErrorsObject: BonusPointsRuleErrorsObject) => {
                return bonusPointsRuleErrorsObject.clientId === bonusPointsRule.clientId
              })
          },
          buildNewValue: () => ({
            clientId: uuidv4(),
            calculator: undefined,
            bonusProgramLevelId: undefined,
            bonusProgramLevel: undefined,
            id: undefined
          }),
          fields: new BonusPointsRuleFields({
            coreI18n: this.coreI18n,
            getBonusProgramLevels: async({ query, lastObjectId }) => await this.getBonusProgramLevelsUseCase.call({
              filter: { query },
              pagination: { id: lastObjectId }
            })
          }).createFields()
        })
      ]
    })
  }
}
