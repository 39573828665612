import { Expose, Type } from "class-transformer"
import BonusProgramRuleErrorsObject from "../../../domain/entities/bonus-program-rules/BonusProgramRuleErrorsObject"
import NetworkBonusProgramRuleErrorsObjectAttributes from "./NetworkBonusProgramRuleErrorsObjectAttributes"
import NetworkBonusPointsRuleErrorsObject from "../bonus-points-rules/NetworkBonusPointsRuleErrorsObject"

export default class NetworkBonusProgramRuleErrorsObject implements BonusProgramRuleErrorsObject {

  @Expose()
  @Type(() => NetworkBonusProgramRuleErrorsObjectAttributes)
  readonly attributes: NetworkBonusProgramRuleErrorsObjectAttributes | null | undefined

  @Expose()
  @Type(() => NetworkBonusPointsRuleErrorsObject)
  readonly bonusPointsRules: NetworkBonusPointsRuleErrorsObject[] | null | undefined
}
