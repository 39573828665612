import FormField, { FormFieldParameters, FormFieldViewState, FormFieldViewStateParameters } from "../FormField"
import Page from "../../../../../../core/domain/entities/Page"
import SelectOption from "../../../../../../core/presentation/entities/SelectOption"
import { GetObjectsPageResult } from "../../../../domain/results/GetObjectsPageResult"

const queryInputDebounceTimeoutInMilliseconds = 300
const defaultStateId = "defaultState"

export default class SingleSelectFormField<DomainObject, ErrorsObject, OptionObject>
  extends FormField<DomainObject, ErrorsObject> {

  private readonly getObjects: GetObjectsFunction<DomainObject, OptionObject>
  private readonly getValue: (object: DomainObject) => OptionObject | null | undefined
  private readonly setValue: (object: DomainObject, value: OptionObject | null) => DomainObject
  private readonly getOptionId: (optionObject: OptionObject) => string
  private readonly getOptionText: (optionObject: OptionObject) => string | null | undefined
  private readonly getPlaceholder?: () => string | null | undefined
  private isLoadingByStateId: { [key: string]: boolean }
  private optionObjectsByStateId: { [key: string]: OptionObject[] }
  private queryByStateId: { [key: string]: string | undefined }
  private queryInputTimeoutByStateId: { [key: string]: NodeJS.Timeout }
  private lastLoadingTimestampByStateId: { [key: string]: number }
  private pageByStateId: { [key: string]: Page | undefined }
  private readonly isSearchBarVisible: boolean
  private readonly isClearButtonVisible: boolean
  private optionErrorMessage: string | null | undefined

  constructor(parameters: FormFieldParameters<DomainObject, ErrorsObject> & {
    readonly getObjects: GetObjectsFunction<DomainObject, OptionObject>
    readonly getValue: (object: DomainObject) => OptionObject | null | undefined
    readonly setValue: (object: DomainObject, value: OptionObject | null) => DomainObject
    readonly getOptionId: (optionObject: OptionObject) => string
    readonly getOptionText: (optionObject: OptionObject) => string | null | undefined
    readonly getPlaceholder?: () => string | null | undefined
    readonly isSearchBarVisible?: boolean
    readonly isClearButtonVisible?: boolean
  }) {
    super(parameters)
    this.getObjects = parameters.getObjects
    this.getValue = parameters.getValue
    this.setValue = parameters.setValue
    this.getOptionId = parameters.getOptionId
    this.getOptionText = parameters.getOptionText
    this.getPlaceholder = parameters.getPlaceholder
    this.isSearchBarVisible = parameters.isSearchBarVisible ?? true
    this.isClearButtonVisible = parameters.isClearButtonVisible ?? true
    this.isLoadingByStateId = {}
    this.optionObjectsByStateId = {}
    this.queryByStateId = {}
    this.queryInputTimeoutByStateId = {}
    this.lastLoadingTimestampByStateId = {}
    this.pageByStateId = {}
  }

  getViewState(object: DomainObject, errorsObject?: ErrorsObject): FormFieldViewState {
    const stateId: string = this.getObjectId ? this.getObjectId(object) : defaultStateId
    const selectedValue: OptionObject | null | undefined = this.getValue(object)
    const selectedOption: SelectOption<OptionObject> | null = selectedValue ? this.createOption(selectedValue) : null
    const isLoading: boolean = this.isLoadingByStateId[stateId] ?? false
    const query: string | null | undefined = this.queryByStateId[stateId]
    const page: Page | undefined = this.pageByStateId[stateId]
    const options: SelectOption<OptionObject>[] = (this.optionObjectsByStateId[stateId] ?? [])
      .map((optionObject: OptionObject) => this.createOption(optionObject))

    const loadAndShowOptions = async(): Promise<void> => {
      const timestamp: number = new Date().getTime()
      this.lastLoadingTimestampByStateId = { ...this.lastLoadingTimestampByStateId, [stateId]: timestamp }
      this.optionObjectsByStateId = { ...this.optionObjectsByStateId, [stateId]: [] }
      this.pageByStateId = { ...this.pageByStateId, [stateId]: undefined }
      this.isLoadingByStateId = { ...this.isLoadingByStateId, [stateId]: true }
      this.setAndShowLoadedObjectViewState()

      this.optionErrorMessage = undefined

      const result: GetObjectsPageResult<OptionObject> = await this.getObjects({
        query: this.queryByStateId[stateId],
        parentObject: object
      })

      const isLastLoading: boolean = timestamp === this.lastLoadingTimestampByStateId[stateId]

      if (!isLastLoading) {
        return
      }

      this.isLoadingByStateId = { ...this.isLoadingByStateId, [stateId]: false }

      switch (result.type) {
        case "error":
          this.optionErrorMessage = result.error.message
          break
        case "failure":
          // TODO: show failure
          break
        case "success":
          this.optionObjectsByStateId = { ...this.optionObjectsByStateId, [stateId]: result.data.objects }
          this.pageByStateId = { ...this.pageByStateId, [stateId]: result.data.page }
          break
      }

      this.setAndShowLoadedObjectViewState()
    }

    const loadNextOptionsPageAndShowOptions = async(): Promise<void> => {
      const timestamp: number = new Date().getTime()
      this.lastLoadingTimestampByStateId = { ...this.lastLoadingTimestampByStateId, [stateId]: timestamp }
      this.isLoadingByStateId = { ...this.isLoadingByStateId, [stateId]: true }
      this.setAndShowLoadedObjectViewState()

      const optionObjects: OptionObject[] = this.optionObjectsByStateId[stateId] ?? []
      const lastOptionObjectIndex: number = optionObjects.length - 1
      const lastOptionObject: OptionObject = optionObjects[lastOptionObjectIndex]
      this.optionErrorMessage = undefined

      const result: GetObjectsPageResult<OptionObject> = await this.getObjects({
        query: this.queryByStateId[stateId],
        lastObject: lastOptionObject,
        parentObject: object
      })

      const isLastLoading: boolean = timestamp === this.lastLoadingTimestampByStateId[stateId]

      if (!isLastLoading) {
        return
      }

      this.isLoadingByStateId = { ...this.isLoadingByStateId, [stateId]: false }

      switch (result.type) {
        case "error":
          this.optionErrorMessage = result.error.message
          break
        case "failure":
          // TODO: show failure
          break
        case "success":
          this.optionObjectsByStateId = {
            ...this.optionObjectsByStateId,
            [stateId]: [...optionObjects, ...result.data.objects]
          }
          this.pageByStateId = { ...this.pageByStateId, [stateId]: result.data.page }
          break
      }

      this.setAndShowLoadedObjectViewState()
    }

    return new SingleSelectFormFieldViewState<OptionObject>({
      ...this.getFormFieldViewStateParameters(object, errorsObject),
      selectedOption,
      isLoading,
      options,
      query,
      page,
      placeholder: this.getPlaceholder?.(),
      onSelect: (value: SelectOption<OptionObject> | null) => {
        this.setObject(this.setValue(object, value?.originalObject ?? null))
        this.setAndShowLoadedObjectViewState()
      },
      onOpened: () => {
        loadAndShowOptions().then()
      },
      onQueryChanged: (query: string) => {
        this.queryByStateId = { ...this.queryByStateId, [stateId]: query }
        this.setAndShowLoadedObjectViewState()

        clearTimeout(this.queryInputTimeoutByStateId[stateId])

        this.queryInputTimeoutByStateId = {
          ...this.queryInputTimeoutByStateId,
          [stateId]: setTimeout(() => {
            loadAndShowOptions().then()
          }, queryInputDebounceTimeoutInMilliseconds)
        }
      },
      onSearchRequested: () => {
        loadAndShowOptions().then()
      },
      onNextPageRequested: () => {
        loadNextOptionsPageAndShowOptions().then()
      },
      isSearchBarVisible: this.isSearchBarVisible,
      isClearButtonVisible: this.isClearButtonVisible,
      optionErrorMessage: this.optionErrorMessage
    })
  }

  private createOption(optionObject: OptionObject): SelectOption<OptionObject> {
    return {
      id: this.getOptionId(optionObject),
      text: this.getOptionText(optionObject),
      originalObject: optionObject
    }
  }
}

export class SingleSelectFormFieldViewState<OptionObject> extends FormFieldViewState {
  readonly selectedOption: SelectOption<OptionObject> | null
  readonly isLoading: boolean
  readonly options: SelectOption<OptionObject>[]
  readonly query?: string | null
  readonly page?: Page
  readonly onSelect: (value: SelectOption<OptionObject> | null) => void
  readonly onOpened: () => void
  readonly onSearchRequested: () => void
  readonly onQueryChanged: (query: string) => void
  readonly onNextPageRequested: () => void
  readonly isSearchBarVisible: boolean
  readonly isClearButtonVisible: boolean
  readonly placeholder: string | null | undefined
  readonly optionErrorMessage: string | null | undefined

  constructor(parameters: FormFieldViewStateParameters & {
    readonly onSelect: (value: SelectOption<OptionObject> | null) => void
    readonly onOpened: () => void
    readonly selectedOption: SelectOption<OptionObject> | null
    readonly isLoading: boolean
    readonly options: SelectOption<OptionObject>[]
    readonly query?: string | null
    readonly page?: Page
    readonly onSearchRequested: () => void
    readonly onQueryChanged: (query: string) => void
    readonly onNextPageRequested: () => void
    readonly isSearchBarVisible: boolean
    readonly isClearButtonVisible: boolean
    readonly placeholder: string | null | undefined
    readonly optionErrorMessage: string | null | undefined
  }) {
    super(parameters)
    this.onSelect = parameters.onSelect
    this.onOpened = parameters.onOpened
    this.onSearchRequested = parameters.onSearchRequested
    this.onQueryChanged = parameters.onQueryChanged
    this.onNextPageRequested = parameters.onNextPageRequested
    this.selectedOption = parameters.selectedOption
    this.isLoading = parameters.isLoading
    this.options = parameters.options
    this.query = parameters.query
    this.page = parameters.page
    this.isSearchBarVisible = parameters.isSearchBarVisible
    this.isClearButtonVisible = parameters.isClearButtonVisible
    this.placeholder = parameters.placeholder
    this.optionErrorMessage = parameters.optionErrorMessage
  }
}

export interface GetObjectsParameters<DomainObject, OptionObject> {
  readonly query?: string
  readonly lastObject?: OptionObject
  readonly parentObject: DomainObject
}

export type GetObjectsFunction<DomainObject, OptionObject> =
  (parameters: GetObjectsParameters<DomainObject, OptionObject>) => Promise<GetObjectsPageResult<OptionObject>>
