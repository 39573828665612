import React from "react"
import { DateFormFieldViewState } from "../../../entities/form-fields/form-field-by-type/DateFormField"
import AppDatePickerComponent
  from "../../../../../../core/presentation/components/app-date-picker/AppDatePickerComponent"
import styles from "./DateFormFieldComponent.module.scss"
import TextComponent, { TextStyle } from "../../../../../../design/text/TextComponent"

export default function DateFormFieldComponent({
  fieldViewState,
  isDisabled
}: {
  readonly fieldViewState: DateFormFieldViewState
  readonly isDisabled: boolean
}) {
  function handleOnChange(date: Date | null) {
    fieldViewState.onChange(date)
  }

  return (
    <div className={styles.root}>
      {
        fieldViewState.getTitle() && (
          <div className={styles.title}>
            <TextComponent textStyle={TextStyle.LABEL1_PRIMARY}>{fieldViewState.getTitle()}</TextComponent>
          </div>
        )
      }
      <AppDatePickerComponent
        value={fieldViewState.value ?? null}
        disabled={isDisabled}
        onChange={handleOnChange}
      />
    </div>
  )
}
