import { Expose } from "class-transformer"

export default class NetworkLastItemPagination {
  @Expose()
  readonly id?: number | null

  @Expose()
  readonly size?: number | null

  constructor(parameters: {
    readonly id?: number | null
    readonly size?: number | null
  }) {
    this.id = parameters.id
    this.size = parameters.size
  }
}
