import Configuration from "../../../../../../core/domain/entities/configurations/Configuration"
import ConfigurationEntityName from "../../../../../../core/domain/entities/configurations/ConfigurationEntityName"
import ConfigurationEntityPropertyName
  from "../../../../../../core/domain/entities/configurations/ConfigurationEntityPropertyName"
import { ObjectEditor } from "./ObjectEditor"

export class ProductEditor {

  private readonly productId: number | null | undefined
  private readonly objectEditor: ObjectEditor

  constructor(parameters: {
    readonly productId: number | null | undefined
  }) {
    this.productId = parameters.productId
    this.objectEditor = new ObjectEditor()
  }

  setConfiguration(configuration: Configuration) {
    this.objectEditor.init(configuration, ConfigurationEntityName.PRODUCT)
  }

  isNameDisabled() {
    return this.objectEditor.isDisabled(this.productId, ConfigurationEntityPropertyName.NAME)
  }

  isExternalCodeDisabled() {
    return this.objectEditor.isDisabled(this.productId, ConfigurationEntityPropertyName.EXTERNAL_CODE)
  }

  isDescriptionDisabled() {
    return this.objectEditor.isDisabled(this.productId, ConfigurationEntityPropertyName.DESCRIPTION)
  }

  hasBatchesDisabled() {
    return this.objectEditor.isDisabled(this.productId, ConfigurationEntityPropertyName.HAS_BATCHES)
  }

  canBeOrderedWithBonusPointsDisabled() {
    return this.objectEditor.isDisabled(
      this.productId,
      ConfigurationEntityPropertyName.CAN_BE_ORDERED_WITH_BONUS_POINTS
    )
  }

  isArchivedDisabled() {
    return this.objectEditor.isDisabled(this.productId, ConfigurationEntityPropertyName.IS_ARCHIVED)
  }

  isInputMultiplierDisabled() {
    return this.objectEditor.isDisabled(this.productId, ConfigurationEntityPropertyName.INPUT_MULTIPLIER)
  }

  isCategoryIdDisabled() {
    return this.objectEditor.isDisabled(this.productId, ConfigurationEntityPropertyName.CATEGORY_ID)
  }

  isOptionIdsDisabled() {
    return this.objectEditor.isDisabled(this.productId, ConfigurationEntityPropertyName.OPTION_IDS)
  }
}
