import OrderReceivingMethodsRepository from "../repositories/OrderReceivingMethodsRepository"
import { GetObjectsPageResult } from "../../../../../sqadmin/features/objects/domain/results/GetObjectsPageResult"
import Sort from "../../../../../sqadmin/features/objects/presentation/entities/tables/Sort"
import LastItemPagination from "../../../../core/domain/entities/paginations/LastItemPagination"
import OrderShippingTariff from "../../../../core/domain/entities/order-shipping-method-tariffs/OrderShippingTariff"
import OrderShippingTariffsFilter
  from "../../../../core/domain/entities/order-shipping-method-tariffs/OrderShippingTariffsFilter"

export default class GetOrderShippingTariffsUseCase {
  private readonly orderReceivingMethodsRepository: OrderReceivingMethodsRepository

  constructor(parameters: {
    readonly orderReceivingMethodsRepository: OrderReceivingMethodsRepository
  }) {
    this.orderReceivingMethodsRepository = parameters.orderReceivingMethodsRepository
  }

  async call(parameters: GetOrderShippingTariffsParameters): Promise<GetObjectsPageResult<OrderShippingTariff>> {
    return this.orderReceivingMethodsRepository.getOrderShippingTariffs(parameters)
  }
}

export interface GetOrderShippingTariffsParameters {
  readonly filter: OrderShippingTariffsFilter | null | undefined
  readonly sort: Sort | null | undefined
  readonly pagination: LastItemPagination | null | undefined
}
