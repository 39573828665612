import FormField, { FormFieldParameters, FormFieldViewState, FormFieldViewStateParameters } from "../FormField"
import { DateRange } from "../../../../../../lib/date-range/DateRange"

export default class DateRangeFormField<DomainObject, ErrorsObject> extends FormField<DomainObject, ErrorsObject> {
  private readonly getValue: (object: DomainObject) => DateRange | null | undefined
  private readonly setValue: (object: DomainObject, value: DateRange | null | undefined) => DomainObject

  constructor(parameters: FormFieldParameters<DomainObject, ErrorsObject> & {
    readonly getValue: (object: DomainObject) => DateRange | null | undefined
    readonly setValue: (object: DomainObject, value: DateRange | null | undefined) => DomainObject
  }) {
    super(parameters)
    this.getValue = parameters.getValue
    this.setValue = parameters.setValue
  }

  getViewState(object: DomainObject, errorsObject?: ErrorsObject): FormFieldViewState {
    return new DateRangeFormFieldViewState({
      ...this.getFormFieldViewStateParameters(object, errorsObject),
      value: this.getValue(object),
      onChange: (value: DateRange | null | undefined) => {
        this.setObject(this.setValue(object, value))
        this.setAndShowLoadedObjectViewState()
      }
    })
  }
}

export class DateRangeFormFieldViewState extends FormFieldViewState {
  readonly value: DateRange | null | undefined
  onChange: (value: DateRange | null | undefined) => void

  constructor(parameters: FormFieldViewStateParameters & {
    readonly onChange: (value: DateRange | null | undefined) => void
    readonly value: DateRange | null | undefined
  }) {
    super(parameters)
    this.value = parameters.value
    this.onChange = parameters.onChange
  }
}
