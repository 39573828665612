import { Expose, Type } from "class-transformer"
import NetworkProductsSetProduct from "../products-set-products/NetworkProductsSetProduct"
import NetworkProductCategory from "../product-categories/NetworkProductCategory"
import NetworkProductsSetProperty from "../products-set-properties/NetworkProductsSetProperty"

export default class NetworkProductsSet {
  @Expose()
  @Type(() => Number)
  readonly id: number | null | undefined

  @Expose()
  @Type(() => Number)
  readonly productCategoryId: number | null | undefined

  @Expose()
  @Type(() => Boolean)
  readonly canBeOrderedWithBonusPoints: boolean | null | undefined

  @Expose()
  @Type(() => NetworkProductCategory)
  readonly productCategory: NetworkProductCategory | null | undefined

  @Expose()
  @Type(() => NetworkProductsSetProduct)
  readonly productsSetProducts: NetworkProductsSetProduct[] | null | undefined

  @Expose()
  @Type(() => NetworkProductsSetProperty)
  readonly productsSetProperties: NetworkProductsSetProperty[] | null | undefined

  constructor(parameters?: {
    readonly id: number | null | undefined
    readonly productCategoryId: number | null | undefined
    readonly productCategory: NetworkProductCategory | null | undefined
    readonly productsSetProducts: NetworkProductsSetProduct[] | null | undefined
    readonly productsSetProperties: NetworkProductsSetProperty[] | null | undefined
    readonly canBeOrderedWithBonusPoints: boolean | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.id = parameters.id
    this.productCategoryId = parameters.productCategoryId
    this.productCategory = parameters.productCategory
    this.productsSetProducts = parameters.productsSetProducts
    this.productsSetProperties = parameters.productsSetProperties
    this.canBeOrderedWithBonusPoints = parameters.canBeOrderedWithBonusPoints
  }
}
