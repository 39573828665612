import { LoadObjectResult } from "../../../../../sqadmin/features/objects/domain/results/LoadObjectResult"
import BonusPointsBurningRulesRepository from "../repositories/BonusPointsBurningRulesRepository"
import BonusPointsBurningRule from "../../../../core/domain/entities/bonus-points-burning-rules/BonusPointsBurningRule"

export default class GetBonusPointsBurningRuleUseCase {
  private readonly bonusPointsBurningRulesRepository: BonusPointsBurningRulesRepository

  constructor(parameters: {
    readonly bonusPointsBurningRulesRepository: BonusPointsBurningRulesRepository
  }) {
    this.bonusPointsBurningRulesRepository = parameters.bonusPointsBurningRulesRepository
  }

  async call(): Promise<LoadObjectResult<BonusPointsBurningRule>> {
    return await this.bonusPointsBurningRulesRepository.getBonusPointsBurningRule()
  }
}
