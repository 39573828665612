import NetworkDimensions from "../entities/dimensions/NetworkDimensions"
import Dimensions from "../../domain/entities/dimensions/Dimensions"

export default class DimensionsMapper {
  mapNetworkToDomain({
    dimensions
  }: {
    readonly dimensions: NetworkDimensions
  }): Dimensions {
    return {
      width: dimensions.width,
      height: dimensions.height
    }
  }

  mapDomainToNetwork({
    dimensions
  }: {
    readonly dimensions: Dimensions
  }): NetworkDimensions {
    return new NetworkDimensions({
      width: dimensions.width,
      height: dimensions.height
    })
  }
}
