import { Expose, Type } from "class-transformer"
import NetworkPage from "../../../../core/data/entities/pagination/NetworkPage"
import NetworkApiLogItem from "../../../../core/data/entities/api-log-items/NetworkApiLogItem"

export default class NetworkApiLogItemsResponseBody {
  @Expose()
  @Type(() => NetworkApiLogItem)
  readonly apiLogItems: NetworkApiLogItem[] | null | undefined

  @Expose()
  @Type(() => NetworkPage)
  readonly page: NetworkPage | null | undefined
}
