import { GetObjectsPageResult } from "../../../../../sqadmin/features/objects/domain/results/GetObjectsPageResult"
import Settlement from "../../../../core/domain/entities/settlements/Settlement"
import SettlementsRepository from "../repositories/SettlementsRepository"
import LastItemPagination from "../../../../core/domain/entities/paginations/LastItemPagination"

export default class GetSettlementsUseCase {
  private readonly settlementsRepository: SettlementsRepository

  constructor(parameters: {
    readonly settlementsRepository: SettlementsRepository
  }) {
    this.settlementsRepository = parameters.settlementsRepository
  }

  async call(parameters: GetSettlementsParameters): Promise<GetSettlementsResult> {
    return await this.settlementsRepository.getSettlements(parameters)
  }
}

export interface GetSettlementsParameters {
  readonly query: string | null | undefined
  readonly pagination?: LastItemPagination
}

export type GetSettlementsResult = GetObjectsPageResult<Settlement>
