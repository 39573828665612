import BackendHttpClient, { BackendHttpClientResult } from "../../network/BackendHttpClient"
import NetworkLastItemPagination from "../../entities/pagination/NetworkLastItemPagination"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import { instanceToPlain, plainToInstance } from "class-transformer"
import NetworkExecutionError from "../../entities/errors/NetworkExecutionError"
import GetObjectNetworkResult from "../../results/GetObjectNetworkResult"
import CreateObjectNetworkResult from "../../results/CreateObjectNetworkResult"
import UpdateObjectNetworkResult from "../../results/UpdateObjectNetworkResult"
import DestroyObjectNetworkResult from "../../results/DestroyObjectNetworkResult"
import SuccessExecutionResult from "../../../../../sqadmin/core/domain/results/SuccessExecutionResult"
import ErrorExecutionResult from "../../../../../sqadmin/core/domain/results/ErrorExecutionResult"
import FailureExecutionResult from "../../../../../sqadmin/core/domain/results/FailureExecutionResult"
import NetworkOrderProcessingStatus from "../../entities/order-processing-statuses/NetworkOrderProcessingStatus"
import NetworkOrderProcessingStatusError from "../../entities/order-processing-statuses/NetworkOrderProcessingStatusError"
import NetworkOrderProcessingStatusesResponseBody
  from "../../entities/order-processing-statuses/NetworkOrderProcessingStatusesResponseBody"
import NetworkOrderProcessingStatusesRequestFilter
  from "../../entities/order-processing-statuses/NetworkOrderProcessingStatusesRequestFilter"
import NetworkOrderProcessingStatusesRequestQuery
  from "../../entities/order-processing-statuses/NetworkOrderProcessingStatusesRequestQuery"
import NetworkOrderProcessingStatusResponseBody
  from "../../entities/order-processing-statuses/NetworkOrderProcessingStatusResponseBody"
import NetworkOrderProcessingStatusRequestBody from "../../entities/order-processing-statuses/NetworkOrderProcessingStatusRequestBody"

const basePath = "/admin/order_processing_statuses"

export default class OrderProcessingStatusesNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async getOrderProcessingStatuses({
    filter,
    pagination,
    sort
  }: {
    readonly filter?: NetworkOrderProcessingStatusesRequestFilter | null
    readonly pagination?: NetworkLastItemPagination | null
    readonly sort?: string | null
  }): Promise<GetOrderProcessingStatusesNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: basePath,
      parameters: instanceToPlain(new NetworkOrderProcessingStatusesRequestQuery({
        filter,
        pagination,
        sort
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkOrderProcessingStatusesResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async getOrderProcessingStatus({
    orderProcessingStatusId
  }: {
    readonly orderProcessingStatusId: number
  }): Promise<GetObjectNetworkResult<NetworkOrderProcessingStatus>> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}/${orderProcessingStatusId}`
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkOrderProcessingStatusResponseBody, result.body).orderProcessingStatus!
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async createOrderProcessingStatus({
    orderProcessingStatus
  }: {
    readonly orderProcessingStatus: NetworkOrderProcessingStatus
  }): Promise<CreateObjectNetworkResult<NetworkOrderProcessingStatus, NetworkOrderProcessingStatusError>> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.POST,
      path: basePath,
      body: instanceToPlain(new NetworkOrderProcessingStatusRequestBody({
        orderProcessingStatus: orderProcessingStatus
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkOrderProcessingStatusResponseBody, result.body).orderProcessingStatus!
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkOrderProcessingStatusError, result.body)
        }
      case "failure":
        return result
    }
  }

  async updateOrderProcessingStatus({
    orderProcessingStatusId,
    orderProcessingStatus
  }: {
    readonly orderProcessingStatusId: number
    readonly orderProcessingStatus: NetworkOrderProcessingStatus
  }): Promise<UpdateObjectNetworkResult<NetworkOrderProcessingStatus, NetworkOrderProcessingStatusError>> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.PUT,
      path: `${basePath}/${orderProcessingStatusId}`,
      body: instanceToPlain(new NetworkOrderProcessingStatusRequestBody({
        orderProcessingStatus: orderProcessingStatus
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkOrderProcessingStatusResponseBody, result.body).orderProcessingStatus!
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkOrderProcessingStatusError, result.body)
        }
      case "failure":
        return result
    }
  }

  async destroyOrderProcessingStatus({
    orderProcessingStatusId
  }: {
    readonly orderProcessingStatusId: number
  }): Promise<DestroyObjectNetworkResult<NetworkOrderProcessingStatusError>> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.DELETE,
      path: `${basePath}/${orderProcessingStatusId}`
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: undefined
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkOrderProcessingStatusError, result.body)
        }
      case "failure":
        return result
    }
  }
}

export type GetOrderProcessingStatusesNetworkResult =
  SuccessExecutionResult<NetworkOrderProcessingStatusesResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult
