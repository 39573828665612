import PlaceViewModel from "../../../../places/presentation/pages/place-page/PlaceViewModel"
import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import { useCoreTextProvider } from "../../../../../core/presentation/contexts/CoreTextProviderContext"
import { useNumberObjectId } from "../../../../../../sqadmin/features/objects/presentation/hooks/useObjectId"
import { useViewModel } from "../../../../../../sqadmin/lib/view-model/ViewModel"
import ObjectComponent from "../../../../../../sqadmin/features/objects/presentation/components/object/ObjectComponent"
import React from "react"

export interface PlacePageProps {
  readonly provideViewModel: (parameters: { readonly placeId?: number }) => PlaceViewModel
}

export default function PlacePage({
  provideViewModel
}: PlacePageProps) {
  const coreTextProvider: CoreTextProvider = useCoreTextProvider()
  const placeId: number | undefined = useNumberObjectId()

  const viewModel: PlaceViewModel = useViewModel(() => provideViewModel({
    placeId
  }))

  return (
    <ObjectComponent
      newObjectTitle={coreTextProvider.newPlace()}
      existedObjectTitle={coreTextProvider.place()}
      observableObjectViewState={viewModel.observableObjectViewState}
      onObjectViewEvent={viewModel.onViewObjectEvent}
    />
  )
}
