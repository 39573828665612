enum ConfigurationEntityName {
  PRODUCT = "product",
  PRODUCT_VARIANT = "productVariant",
  PRODUCT_VARIANT_BATCH = "productVariantBatch",
  PRODUCT_VARIANT_BATCH_PLACE = "productVariantBatchPlace",
  PRODUCT_PROPERTY_VALUE = "productPropertyValue",
  PRODUCT_VARIANT_PRODUCT_IMAGE = "productVariantProductImage",
  PRODUCT_VARIANT_OPTION_VALUE = "productVariantOptionValue"
}

export default ConfigurationEntityName
