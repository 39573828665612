import { Params, useParams } from "react-router-dom"
import isBlank from "../../../../lib/isBlank"

export const objectIdUrlPart = "objectId"

export default function useObjectId(): string | undefined {
  const urlParams: Readonly<Params> = useParams()
  return urlParams[objectIdUrlPart]
}

export function useNumberObjectId(): number | undefined {
  const objectId: string | undefined = useObjectId()
  return isBlank(objectId) ? undefined : parseInt(objectId)
}
