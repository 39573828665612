import { CreateObjectResult } from "../../../../../sqadmin/features/objects/domain/results/CreateObjectResult"
import Place from "../../../../core/domain/entities/places/Place"
import PlaceError from "../../../../core/domain/entities/places/PlaceError"
import PlacesRepository from "../repositories/PlacesRepository"

export default class CreatePlaceUseCase {
  private readonly placesRepository: PlacesRepository

  constructor(parameters: {
    readonly placesRepository: PlacesRepository
  }) {
    this.placesRepository = parameters.placesRepository
  }

  async call(parameters: {
    readonly place: Place
  }): Promise<CreateObjectResult<Place, PlaceError>> {
    return await this.placesRepository.createPlace(parameters)
  }
}
