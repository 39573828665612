import ProductsSet from "../../domain/entities/products-sets/ProductsSet"
import NetworkProductsSet from "../entities/products-sets/NetworkProductsSet"
import ProductsSetProductsMapper from "./ProductsSetProductsMapper"
import NetworkProductsSetProduct from "../entities/products-set-products/NetworkProductsSetProduct"
import ProductCategoriesMapper from "./ProductCategoriesMapper"
import ProductsSetCondition from "../../domain/entities/products-set-condition/ProductsSetCondition"
import isPresent from "../../../../sqadmin/lib/isPresent"
import ProductsSetConditionType from "../../domain/entities/products-set-condition/ProductsSetConditionType"
import assertNever from "../../../../sqadmin/lib/assertNever"
import ProductsSetProduct from "../../domain/entities/products-set-products/ProductsSetProduct"
import { v4 as uuidv4 } from "uuid"
import ProductsSetPropertiesMapper from "./ProductsSetPropertiesMapper"
import isBlank from "../../../../sqadmin/lib/isBlank"

export default class ProductsSetsMapper {
  mapNetworkToDomain({
    productsSet
  }: {
    readonly productsSet: NetworkProductsSet
  }): ProductsSet {
    const productsSetConditions: ProductsSetCondition[] = []
    const productsSetProductsMapper = new ProductsSetProductsMapper()
    const productsSetPropertiesMapper = new ProductsSetPropertiesMapper()

    if (isPresent(productsSet.productCategoryId)) {
      productsSetConditions.push({
        type: ProductsSetConditionType.PRODUCT_CATEGORY,
        clientId: uuidv4(),
        productCategoryId: productsSet.productCategoryId,
        productCategory: productsSet.productCategory && new ProductCategoriesMapper().mapNetworkToDomain({
          productCategory: productsSet.productCategory
        })
      })
    }

    if (isPresent(productsSet.productsSetProperties) && productsSet.productsSetProperties.length > 0) {
      productsSetConditions.push({
        type: ProductsSetConditionType.PROPERTIES_VALUES,
        clientId: uuidv4(),
        productsSetProperties: productsSet.productsSetProperties
          .map((productsSetProperty) => {
            return productsSetPropertiesMapper.mapNetworkToDomain({
              productsSetProperty
            })
          })
      })
    }

    if (isPresent(productsSet.productsSetProducts) && productsSet.productsSetProducts.length > 0) {
      productsSetConditions.push({
        type: ProductsSetConditionType.PRODUCTS,
        clientId: uuidv4(),
        productsSetProducts: productsSet.productsSetProducts && productsSet.productsSetProducts
          .map((productsSetProduct: NetworkProductsSetProduct) => {
            return productsSetProductsMapper.mapNetworkToDomain({ productsSetProduct })
          })
      })
    }

    if (isPresent(productsSet.canBeOrderedWithBonusPoints) && productsSet.canBeOrderedWithBonusPoints) {
      productsSetConditions.push({
        type: ProductsSetConditionType.PRODUCTS_CAN_BE_ORDERED_WITH_BONUS_POINTS,
        clientId: uuidv4()
      })
    }

    return {
      id: productsSet.id,
      conditions: productsSetConditions,
      clientId: uuidv4()
    }
  }

  mapDomainToNetwork({
    productsSet
  }: {
    readonly productsSet: ProductsSet
  }): NetworkProductsSet {
    let productCategoryProductsSetCondition: ProductsSetCondition | null = null
    let propertyValuesProductsSetCondition: ProductsSetCondition | null = null
    let productsProductsSetCondition: ProductsSetCondition | null = null
    let canBeOrderedWithBonusPoints: boolean | null = null

    const productsSetProductsMapper = new ProductsSetProductsMapper()
    const productsSetPropertiesMapper = new ProductsSetPropertiesMapper()

    for (const productsSetCondition of productsSet.conditions ?? []) {
      if (isBlank(productsSetCondition.type)) continue

      switch (productsSetCondition.type) {
        case ProductsSetConditionType.PRODUCT_CATEGORY:
          productCategoryProductsSetCondition = productsSetCondition
          break
        case ProductsSetConditionType.PROPERTIES_VALUES:
          propertyValuesProductsSetCondition = productsSetCondition
          break
        case ProductsSetConditionType.PRODUCTS:
          productsProductsSetCondition = productsSetCondition
          break
        case ProductsSetConditionType.PRODUCTS_CAN_BE_ORDERED_WITH_BONUS_POINTS:
          canBeOrderedWithBonusPoints = true
          break
        default:
          assertNever(productsSetCondition.type)
      }
    }

    return new NetworkProductsSet({
      id: productsSet.id,
      productCategoryId: productCategoryProductsSetCondition && productCategoryProductsSetCondition.productCategoryId,
      productCategory: productCategoryProductsSetCondition &&
        productCategoryProductsSetCondition.productCategory &&
        new ProductCategoriesMapper().mapDomainToNetwork({
          productCategory: productCategoryProductsSetCondition.productCategory
        }),
      productsSetProducts: productsProductsSetCondition &&
        productsProductsSetCondition.productsSetProducts &&
        productsProductsSetCondition.productsSetProducts.map((productsSetProduct: ProductsSetProduct) => {
          return productsSetProductsMapper.mapDomainToNetwork({ productsSetProduct })
        }),
      productsSetProperties: propertyValuesProductsSetCondition &&
        propertyValuesProductsSetCondition.productsSetProperties &&
        propertyValuesProductsSetCondition.productsSetProperties.map((productsSetProperty) => {
          return productsSetPropertiesMapper.mapDomainToNetwork({
            productsSetProperty
          })
        }),
      canBeOrderedWithBonusPoints: canBeOrderedWithBonusPoints
    })
  }
}
