import { Expose, Type } from "class-transformer"
import NetworkProductsSet from "../products-sets/NetworkProductsSet"
import NetworkCalculator from "../calculators/NetworkCalculator"

export default class NetworkDiscountRule {
  @Expose()
  readonly id: number | null | undefined

  @Expose()
  readonly priority: number | null | undefined

  @Expose()
  readonly name: string | null | undefined

  @Expose()
  @Type(() => NetworkProductsSet)
  readonly productsSet: NetworkProductsSet | null | undefined

  @Expose()
  @Type(() => NetworkCalculator)
  readonly calculator: NetworkCalculator | null | undefined

  constructor(parameters?: {
    readonly id: number | null | undefined
    readonly priority: number | null | undefined
    readonly name: string | null | undefined
    readonly productsSet: NetworkProductsSet | null | undefined
    readonly calculator: NetworkCalculator | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.id = parameters.id
    this.productsSet = parameters.productsSet
    this.priority = parameters.priority
    this.calculator = parameters.calculator
    this.name = parameters.name
  }
}
