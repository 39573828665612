import React, { useState } from "react"
import styles from "./MultiSelectPickerComponent.module.scss"
import SelectOption from "../../entities/SelectOption"
import { TextInputComponent, TextInputStyle } from "../../../../design/text-input/TextInputComponent"
import AppDropdownComponent from "../app-dropdown/AppDropdownComponent"
import LoadingIndicatorComponent from "../../../../design/loading-indicator/LoadingIndicatorComponent"
import PickerItemComponent from "../picker-item/PickerItemComponent"
import isPresent from "../../../../lib/isPresent"
import { ReactComponent as ArrowDownSvg } from "../../../assets/icons/ic_arrow_down_medium.svg"
import { ReactComponent as ArrowUpSvg } from "../../../assets/icons/ic_arrow_up_medium.svg"
import { ReactComponent as RemoveSvg } from "../../../assets/icons/ic_remove.svg"
import TextComponent, { TextStyle } from "../../../../design/text/TextComponent"

export interface MultiSelectPickerComponentProps {
  readonly options: SelectOption[]
  readonly selectedOptions: SelectOption[]
  readonly disabled?: boolean
  readonly isLoading?: boolean
  readonly selectButtonText?: string
  readonly aboveOptionsElement?: React.ReactNode
  readonly belowOptionsElement?: React.ReactNode
  readonly onSelect: (option: SelectOption) => void
  readonly onSelectedItemClicked: (selectOption: SelectOption) => void
  readonly onOpened?: () => void
}

export default function MultiSelectPickerComponent({
  options,
  selectedOptions,
  disabled,
  isLoading,
  selectButtonText,
  aboveOptionsElement,
  belowOptionsElement,
  onSelect,
  onOpened,
  onSelectedItemClicked
}: MultiSelectPickerComponentProps) {
  const [isOpened, setIsOpened] = useState(false)

  function handleRequestHide() {
    setIsOpened(false)
  }

  function handleOnOpenClick() {
    if (disabled) {
      return
    }

    callOnOpened()
    setIsOpened(true)
  }

  function handleOnOptionClick(option: SelectOption) {
    onSelect(option)
    setIsOpened(false)
  }

  function isOptionSelected(option: SelectOption) {
    const foundOption = selectedOptions
      ?.find((selectedOption) => selectedOption.id === option.id)

    return isPresent(foundOption)
  }

  function callOnOpened() {
    onOpened && onOpened()
  }

  function callOnOptionClick(selectedOption: SelectOption) {
    if (disabled) {
      return
    }

    onSelectedItemClicked(selectedOption)
  }

  return (
    <div className={styles.root}>
      {selectedOptions.length > 0 && <div className={styles.selectedItemsContainer}>
        {(selectedOptions.map((selectedOption) => {
          return (
            <div
              className={[
                styles.selectedItem,
                disabled ? styles.disabled : ""
              ].join(" ")}
              key={selectedOption.id}
            >
              <TextComponent textStyle={TextStyle.LABEL1_SECONDARY}>{selectedOption.text}</TextComponent>
              <RemoveSvg onClick={() => callOnOptionClick(selectedOption)} />
            </div>
          )
        }))}
      </div>}
      <div className={styles.selectButtonContainer}>
        <div className={styles.selectButton}>
          <TextInputComponent
            cancelFocusByClick={true}
            needShowCursorPointer={true}
            isDisabled={disabled}
            placeholder={selectButtonText}
            readOnly={true}
            onClick={handleOnOpenClick}
            textInputStyle={TextInputStyle.TEXT_INPUT1_PRIMARY}
            rightIcon={isOpened ? <ArrowUpSvg /> : <ArrowDownSvg />}
          />
        </div>
        <AppDropdownComponent
          isVisible={isOpened}
          hideOnClickOutside={true}
          hasScroll={true}
          needSetWidthByParent={true}
          requestHide={handleRequestHide}
        >
          {aboveOptionsElement}
          {options.map((option: SelectOption) => (
            <div
              key={option.id}
              className={styles.option}
            >
              <PickerItemComponent
                text={option.text}
                onClick={() => handleOnOptionClick(option)}
                isSelected={isOptionSelected(option)}
              />
            </div>
          ))}
          {belowOptionsElement}
          {isLoading && <div className={styles.loadingIndicator}><LoadingIndicatorComponent /></div>}
        </AppDropdownComponent>
      </div>
    </div>
  )
}
