import React from "react"
import DiModule from "../../../../../sqadmin/lib/di/DiModule"
import ObjectsDomainDiModule from "../../../../../sqadmin/features/objects/di/modules/ObjectsDomainDiModule"
import UsersCoreDomainDiModule from "../../../users-core/di/UsersCoreDomainDiModule"
import CoreI18nDiModule from "../../../../core/di/modules/CoreI18nDiModule"
import autoBind from "auto-bind"
import ObjectsPresentationLogicParameters
  from "../../../../../sqadmin/features/objects/presentation/entities/objects/ObjectsPresentationLogicParameters"
import AdminsPage from "../../presentation/pages/admins-page/AdminsPage"
import AdminPage from "../../presentation/pages/admin-page/AdminPage"
import AdminViewModel from "../../presentation/pages/admin-page/AdminViewModel"
import AdminsViewModel from "../../presentation/pages/admins-page/AdminsViewModel"

export default interface AdminsPresentationDiModule {
  provideAdminsPage(): React.ReactNode

  provideAdminPage(): React.ReactNode
}

export class DefaultAdminsPresentationDiModule
  extends DiModule
  implements AdminsPresentationDiModule {

  private readonly objectsDomainDiModule: ObjectsDomainDiModule
  private readonly usersCoreDomainDiModule: UsersCoreDomainDiModule
  private readonly coreI18nDiModule: CoreI18nDiModule

  constructor(parameters: {
    readonly objectsDomainDiModule: ObjectsDomainDiModule
    readonly usersCoreDomainDiModule: UsersCoreDomainDiModule
    readonly coreI18nDiModule: CoreI18nDiModule
  }) {
    super()
    autoBind(this)
    this.objectsDomainDiModule = parameters.objectsDomainDiModule
    this.usersCoreDomainDiModule = parameters.usersCoreDomainDiModule
    this.coreI18nDiModule = parameters.coreI18nDiModule
  }

  provideAdminsPage(): React.ReactNode {
    return <AdminsPage provideViewModel={this.provideAdminsViewModel} />
  }

  provideAdminPage(): React.ReactNode {
    return <AdminPage provideViewModel={this.provideAdminViewModel} />
  }

  private provideAdminViewModel({ adminId }: {
    readonly adminId?: number
  }): AdminViewModel {
    return new AdminViewModel({
      coreI18n: this.coreI18nDiModule.provideCoreI18n(),
      broadcastObjectsEventUseCase: this.objectsDomainDiModule.provideBroadcastObjectsEventUseCase({
        key: "admins"
      }),
      getUserUseCase: this.usersCoreDomainDiModule.provideGetUserUseCase(),
      updateUserUseCase: this.usersCoreDomainDiModule.provideUpdateUserUseCase(),
      createUserUseCase: this.usersCoreDomainDiModule.provideCreateUserUseCase(),
      adminId
    })
  }

  private provideAdminsViewModel({
    objectsPresentationLogicParameters
  }: {
    readonly objectsPresentationLogicParameters: ObjectsPresentationLogicParameters
  }): AdminsViewModel {
    return new AdminsViewModel({
      coreI18n: this.coreI18nDiModule.provideCoreI18n(),
      objectsPresentationLogicParameters,
      subscribeToObjectsEventsUseCase: this.objectsDomainDiModule.provideSubscribeToObjectsEventsUseCase({
        key: "admins"
      }),
      unsubscribeFromObjectsEventsUseCase: this.objectsDomainDiModule.provideUnsubscribeFromObjectsEventsUseCase({
        key: "admins"
      }),
      getUsersUseCase: this.usersCoreDomainDiModule.provideGetUsersUseCase()
    })
  }
}
