import { RouteObject } from "react-router-dom"
import { objectIdUrlPart } from "../../../../sqadmin/features/objects/presentation/hooks/useObjectId"
import CoreUrlProvider from "../../../core/presentation/services/CoreUrlProvider"
import OrdersDi from "../di/OrdersDi"

export default function createOrdersRoutes({
  di
}: {
  readonly di: OrdersDi
}): RouteObject[] {
  const urlProvider = new CoreUrlProvider()
  return [
    {
      path: urlProvider.buildOrdersPath(),
      element: di
        .sqCoreOrdersDiComponent
        .ordersPresentationDiModule
        .provideOrdersPage()
    },
    {
      path: urlProvider.buildOrderPath({ id: `:${objectIdUrlPart}` }),
      element: di
        .sqCoreOrdersDiComponent
        .ordersPresentationDiModule
        .provideOrderPage()
    }
  ]
}
