import OptionsNetworkSource, {
  GetOptionsNetworkResult
} from "../../../../core/data/sources/network/OptionsNetworkSource"
import { GetOptionsParameters } from "../../../options/domain/use-cases/GetOptionsUseCase"
import { GetObjectsPageResult } from "../../../../../sqadmin/features/objects/domain/results/GetObjectsPageResult"
import Option from "../../../../core/domain/entities/options/Option"
import OptionsFiltersMapper from "../../../../core/data/mappers/OptionsFiltersMapper"
import LastItemPaginationsMapper from "../../../../core/data/mappers/LastItemPaginationsMapper"
import SortMapper from "../../../../core/data/mappers/SortMapper"
import OptionsMapper from "../../../../core/data/mappers/OptionsMapper"
import PagesMapper from "../../../../core/data/mappers/PagesMapper"
import OptionsRepository from "../../domain/repositories/OptionsRepository"
import { LoadObjectResult } from "../../../../../sqadmin/features/objects/domain/results/LoadObjectResult"
import GetObjectNetworkResult from "../../../../core/data/results/GetObjectNetworkResult"
import NetworkOption from "../../../../core/data/entities/options/NetworkOption"

export default class DefaultOptionsRepository implements OptionsRepository {
  private readonly optionsNetworkSource: OptionsNetworkSource

  constructor(parameters: {
    readonly optionsNetworkSource: OptionsNetworkSource
  }) {
    this.optionsNetworkSource = parameters.optionsNetworkSource
  }

  async getOptions({
    filter,
    pagination,
    sort
  }: GetOptionsParameters): Promise<GetObjectsPageResult<Option>> {
    const result: GetOptionsNetworkResult = await this.optionsNetworkSource.getOptions({
      filter: filter && new OptionsFiltersMapper().mapDomainToNetwork({ filter }),
      pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({ pagination }),
      sort: sort && new SortMapper().mapDomainToNetwork({ sort })
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: {
            objects: result.data.options!.map((option: Option) => {
              return new OptionsMapper().mapNetworkToDomain({
                option: option
              })
            }),
            page: new PagesMapper().mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      default:
        return result
    }
  }

  async getOption({
    optionId
  }: {
    readonly optionId: number
  }): Promise<LoadObjectResult<Option>> {
    const result: GetObjectNetworkResult<NetworkOption> =
      await this.optionsNetworkSource.getOption({
        optionId
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new OptionsMapper().mapNetworkToDomain({
            option: result.data
          })
        }
      default:
        return result
    }
  }
}
