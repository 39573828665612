import ViewModel from "../../../../../../sqadmin/lib/view-model/ViewModel"
import SubscribeToObjectsEventsUseCase
  from "../../../../../../sqadmin/features/objects/domain/use-cases/objects/SubscribeToObjectsEventsUseCase"
import UnsubscribeFromObjectsEventsUseCase
  from "../../../../../../sqadmin/features/objects/domain/use-cases/objects/UnsubscribeFromObjectsEventsUseCase"
import ObjectsPresentationLogicParameters
  from "../../../../../../sqadmin/features/objects/presentation/entities/objects/ObjectsPresentationLogicParameters"
import ObjectsPresentationLogic
  from "../../../../../../sqadmin/features/objects/presentation/presentation-logics/ObjectsPresentationLogic"
import { StateObservable } from "../../../../../../sqadmin/lib/view-model/StateObservable"
import {
  ObjectsPageViewState
} from "../../../../../../sqadmin/features/objects/presentation/view-states/ObjectsViewState"
import { ActionObservable } from "../../../../../../sqadmin/lib/view-model/ActionObservable"
import {
  ObjectsViewAction
} from "../../../../../../sqadmin/features/objects/presentation/view-actions/ObjectsViewAction"
import autoBind from "auto-bind"
import ObjectsViewEvent from "../../../../../../sqadmin/features/objects/presentation/view-events/ObjectsViewEvent"
import TableColumn from "../../../../../../sqadmin/features/objects/presentation/entities/tables/TableColumn"
import NumberTableValue
  from "../../../../../../sqadmin/features/objects/presentation/entities/tables/table-value-by-type/NumberTableValue"
import TextTableValue
  from "../../../../../../sqadmin/features/objects/presentation/entities/tables/table-value-by-type/TextTableValue"
import Place from "../../../../../core/domain/entities/places/Place"
import GetPlacesUseCase from "../../../domian/use-cases/GetPlacesUseCase"
import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import CoreI18n from "../../../../../core/i18n/CoreI18n"

export default class PlacesViewModel extends ViewModel {
  private readonly coreI18n: CoreI18n
  private readonly subscribeToObjectsEventsUseCase: SubscribeToObjectsEventsUseCase
  private readonly unsubscribeFromObjectsEventsUseCase: UnsubscribeFromObjectsEventsUseCase
  private readonly getPlacesUseCase: GetPlacesUseCase
  private readonly objectsPresentationLogicParameters: ObjectsPresentationLogicParameters
  private readonly objectsPresentationLogic: ObjectsPresentationLogic<Place>

  readonly observableObjectsPageViewState: StateObservable<ObjectsPageViewState<Place>>
  readonly observableObjectsViewAction: ActionObservable<ObjectsViewAction>

  constructor(parameters: {
    readonly coreI18n: CoreI18n
    readonly subscribeToObjectsEventsUseCase: SubscribeToObjectsEventsUseCase
    readonly unsubscribeFromObjectsEventsUseCase: UnsubscribeFromObjectsEventsUseCase
    readonly getPlacesUseCase: GetPlacesUseCase
    readonly objectsPresentationLogicParameters: ObjectsPresentationLogicParameters
  }) {
    super()
    autoBind(this)
    this.coreI18n = parameters.coreI18n
    this.subscribeToObjectsEventsUseCase = parameters.subscribeToObjectsEventsUseCase
    this.unsubscribeFromObjectsEventsUseCase = parameters.unsubscribeFromObjectsEventsUseCase
    this.getPlacesUseCase = parameters.getPlacesUseCase
    this.objectsPresentationLogicParameters = parameters.objectsPresentationLogicParameters
    this.objectsPresentationLogic = this.createObjectsPresentationLogic()
    this.observableObjectsPageViewState = this.objectsPresentationLogic.observableObjectsPageViewState
    this.observableObjectsViewAction = this.objectsPresentationLogic.observableObjectsViewAction
  }

  protected onDestroy() {
    super.onDestroy()
    this.objectsPresentationLogic.destroy()
  }

  onObjectsViewEvent(objectsViewEvent: ObjectsViewEvent) {
    this.objectsPresentationLogic.onObjectsViewEvent(objectsViewEvent)
  }

  private createObjectsPresentationLogic(): ObjectsPresentationLogic<Place> {
    const coreTextProvider: CoreTextProvider = this.coreI18n.getTextProvider()
    return new ObjectsPresentationLogic<Place>({
      ...this.objectsPresentationLogicParameters,
      subscribeToObjectsEventsUseCase: this.subscribeToObjectsEventsUseCase,
      unsubscribeFromObjectsEventsUseCase: this.unsubscribeFromObjectsEventsUseCase,
      getObjects: async({
        query,
        sort,
        lastObject: lastPlace
      }) => {
        return await this.getPlacesUseCase.call({
          filter: { query },
          sort,
          pagination: { id: lastPlace?.id ?? undefined }
        })
      },
      getObjectId: (place: Place) => place.id!.toString(),
      columns: [
        new TableColumn<Place>({
          title: coreTextProvider.id(),
          createValue: (place: Place) => new NumberTableValue({
            value: place.id
          })
        }),
        new TableColumn<Place>({
          title: coreTextProvider.externalCode(),
          createValue: (place: Place) => new TextTableValue({
            value: place.externalCode
          })
        }),
        new TableColumn<Place>({
          title: coreTextProvider.name(),
          createValue: (place: Place) => new TextTableValue({
            value: place.name
          })
        })
      ]
    })
  }
}
