import { UpdateObjectResult } from "../../../../../sqadmin/features/objects/domain/results/UpdateObjectResult"
import OrderProcessingStatus from "../../../../core/domain/entities/order-processing-statuses/OrderProcessingStatus"
import OrderProcessingStatusError from "../../../../core/domain/entities/order-processing-statuses/OrderProcessingStatusError"
import OrderProcessingStatusesRepository from "../repositories/OrderProcessingStatusesRepository"

export default class UpdateOrderProcessingStatusUseCase {
  private readonly orderProcessingStatusesRepository: OrderProcessingStatusesRepository

  constructor(parameters: {
    readonly orderProcessingStatusesRepository: OrderProcessingStatusesRepository
  }) {
    this.orderProcessingStatusesRepository = parameters.orderProcessingStatusesRepository
  }

  async call(
    parameters: UpdateOrderProcessingStatusParameters
  ): Promise<UpdateObjectResult<OrderProcessingStatus, OrderProcessingStatusError>> {
    return await this.orderProcessingStatusesRepository.updateOrderProcessingStatus(parameters)
  }
}

export interface UpdateOrderProcessingStatusParameters {
  readonly orderProcessingStatusId: number
  readonly orderProcessingStatus: OrderProcessingStatus
}
