import { Expose, Type } from "class-transformer"
import NetworkOptionValueErrorsObjectAttributes from "./NetworkOptionValueErrorsObjectAttributes"
import OptionValueErrorsObject from "../../../domain/entities/option-values/OptionValueErrorsObject"

export default class NetworkOptionValueErrorsObject implements OptionValueErrorsObject {
  @Expose()
  readonly clientId: string | null | undefined

  @Expose()
  @Type(() => NetworkOptionValueErrorsObjectAttributes)
  readonly attributes: NetworkOptionValueErrorsObjectAttributes | null | undefined
}
