import { DestroyObjectResult } from "../../../../../../sqadmin/features/objects/domain/results/DestroyObjectResult"
import OrdersRepository from "../../repositories/OrdersRepository"
import OrderError from "../../../../../core/domain/entities/orders/OrderError"

export default class DestroyOrderUseCase {
  private readonly ordersRepository: OrdersRepository

  constructor(parameters: {
    readonly ordersRepository: OrdersRepository
  }) {
    this.ordersRepository = parameters.ordersRepository
  }

  async call(parameters: {
    readonly orderId: number
  }): Promise<DestroyObjectResult<OrderError>> {
    return await this.ordersRepository.destroyOrder(parameters)
  }
}
