import React, { useMemo } from "react"
import RootComponent from "../../components/root/RootComponent"
import RootViewModel from "./RootViewModel"
import { Navigate } from "react-router-dom"
import assertNever from "../../../../../lib/assertNever"
import CoreUrlProvider from "../../../../../core/presentation/services/CoreUrlProvider"
import useLocationContextProvider, {
  LocationContextProvider
} from "../../../../../lib/view-model/useLocationContextProvider"
import { useStateObservable } from "../../../../../lib/view-model/StateObservable"

interface Props {
  readonly provideViewModel: () => RootViewModel
}

export default function RootPage({ provideViewModel }: Props) {
  const locationContextProvider: LocationContextProvider = useLocationContextProvider()
  const sqadminUrlProvider = new CoreUrlProvider()
  const viewModel = useMemo<RootViewModel>(provideViewModel, [])
  const rootViewState = useStateObservable(viewModel.rootStateObservable)

  switch (rootViewState.type) {
    case "initial":
      return <></>
    case "authenticated":
      return (
        <RootComponent
          menuItems={rootViewState.menuItems}
          onLogoutClicked={viewModel.onLogoutClicked}
          locationContextProvider={locationContextProvider}
        />
      )
    case "not_authenticated":
      return <Navigate to={sqadminUrlProvider.buildAuthenticationUrl()} replace={true} />
    default:
      assertNever(rootViewState)
  }
}
