import wrapElement from "./wrapElement"

export default function getScrollParent(element: HTMLElement): HTMLElement | null {
  const parentElement: HTMLElement | null = element.parentElement

  if (parentElement === null) {
    return null
  }

  const parentElementScrollHeight = wrapElement(parentElement)!
    .getHeightWithoutAbsoluteAndFixedChildren()

  if (parentElementScrollHeight > parentElement.offsetHeight) {
    return parentElement
  } else {
    return getScrollParent(parentElement)
  }
}
