import BackendHttpClient, { BackendHttpClientResult } from "../../network/BackendHttpClient"
import CreateObjectNetworkResult from "../../results/CreateObjectNetworkResult"
import Image from "../../../domain/entities/images/Image"
import ExecutionError from "../../../../../sqadmin/core/domain/entities/ExecutionError"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import { plainToInstance } from "class-transformer"
import NetworkImageErrorObject from "../../entities/images/NetworkImageErrorObject"
import NetworkBannerImageResponseBody from "../../entities/banner-images/NetworkBannerImageResponseBody"

const basePath = "/admin/banner_images"

export default class BannerImagesNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async createImage({
    file
  }: {
    readonly file: File
  }): Promise<CreateObjectNetworkResult<Image, ExecutionError>> {
    const formData = new FormData()
    formData.append("bannerImage[file]", file, file.name)

    const result: BackendHttpClientResult = await this.backendHttpClient.executeFormDataRequest({
      type: HttpRequestType.POST,
      path: basePath,
      formData: formData
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkBannerImageResponseBody, result.body).bannerImage!
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkImageErrorObject, result.body)
        }
      case "failure":
        return result
    }
  }
}
