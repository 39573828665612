import { Expose, Transform, Type } from "class-transformer"
import NetworkProductsSet from "../products-sets/NetworkProductsSet"
import BonusProgramLevelTransitionRuleType
  from "../../../domain/entities/bonus-program-level-transition-rules/BonusProgramLevelTransitionRuleType"
import BonusProgramLevelTransitionRulePeriodType
  from "../../../domain/entities/bonus-program-level-transition-rules/BonusProgramLevelTransitionRulePeriodType"
import BonusProgramLevelTransitionRuleCheckingValueType
  from "../../../domain/entities/bonus-program-level-transition-rules/BonusProgramLevelTransitionRuleCheckingValueType"
import transformDecimal from "../../network/transformers/transformDecimal"
import { Decimal } from "decimal.js"

export default class NetworkBonusProgramLevelTransitionRule {

  @Expose()
  @Type(() => NetworkProductsSet)
  readonly productsSets: NetworkProductsSet[] | null | undefined

  @Expose()
  readonly type: BonusProgramLevelTransitionRuleType | null | undefined

  @Expose()
  readonly periodType: BonusProgramLevelTransitionRulePeriodType | null | undefined

  @Expose()
  readonly periodsCount: number | null | undefined

  @Expose()
  readonly checkingValueType: BonusProgramLevelTransitionRuleCheckingValueType | null | undefined

  @Expose()
  @Transform(transformDecimal)
  readonly checkingValue: Decimal | null | undefined

  constructor(parameters?: {
    readonly productsSets: NetworkProductsSet[] | null | undefined
    readonly type: BonusProgramLevelTransitionRuleType | null | undefined
    readonly periodType: BonusProgramLevelTransitionRulePeriodType | null | undefined
    readonly periodsCount: number | null | undefined
    readonly checkingValueType: BonusProgramLevelTransitionRuleCheckingValueType | null | undefined
    readonly checkingValue: Decimal | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.type = parameters.type
    this.periodType = parameters.periodType
    this.periodsCount = parameters.periodsCount
    this.checkingValueType = parameters.checkingValueType
    this.checkingValue = parameters.checkingValue
    this.productsSets = parameters.productsSets
  }
}
