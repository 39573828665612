import OrderReceivingMethod from "../../../../core/domain/entities/order-receiving-methods/OrderReceivingMethod"
import { CreateObjectResult } from "../../../../../sqadmin/features/objects/domain/results/CreateObjectResult"
import OrderReceivingMethodsRepository from "../repositories/OrderReceivingMethodsRepository"
import OrderReceivingMethodError
  from "../../../../core/domain/entities/order-receiving-methods/OrderReceivingMethodError"

export default class CreateOrderReceivingMethodUseCase {
  private readonly orderReceivingMethodsRepository: OrderReceivingMethodsRepository

  constructor(parameters: {
    readonly orderReceivingMethodsRepository: OrderReceivingMethodsRepository
  }) {
    this.orderReceivingMethodsRepository = parameters.orderReceivingMethodsRepository
  }

  async call(parameters: {
    readonly orderReceivingMethod: OrderReceivingMethod
  }): Promise<CreateObjectResult<OrderReceivingMethod, OrderReceivingMethodError>> {
    return await this.orderReceivingMethodsRepository.createOrderReceivingMethod(parameters)
  }
}
