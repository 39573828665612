import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../errors/NetworkAttributeError"
import OptionValueErrorsObjectAttributes from "../../../domain/entities/option-values/OptionValueErrorsObjectAttributes"

export default class NetworkOptionValueErrorsObjectAttributes implements OptionValueErrorsObjectAttributes {
  @Expose()
  @Type(() => NetworkAttributeError)
  readonly name: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly externalCode: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly position: NetworkAttributeError[] | null | undefined
}
