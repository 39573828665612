import { GetObjectsPageResult } from "../../../../../sqadmin/features/objects/domain/results/GetObjectsPageResult"
import LastItemPaginationsMapper from "../../../../core/data/mappers/LastItemPaginationsMapper"
import SortMapper from "../../../../core/data/mappers/SortMapper"
import PagesMapper from "../../../../core/data/mappers/PagesMapper"
import { LoadObjectResult } from "../../../../../sqadmin/features/objects/domain/results/LoadObjectResult"
import GetObjectNetworkResult from "../../../../core/data/results/GetObjectNetworkResult"
import ApiLogItemsRepository from "../../domain/repositories/ApiLogItemsRepository"
import { GetApiLogItemsParameters } from "../../domain/use-cases/GetApiLogItemsUseCase"
import ApiLogItem from "../../../../core/domain/entities/api-log-items/ApiLogItem"
import NetworkApiLogItem from "../../../../core/data/entities/api-log-items/NetworkApiLogItem"
import ApiLogItemsNetworkSource, { GetApiLogItemsNetworkResult } from "../sources/ApiLogItemsNetworkSource"
import ApiLogItemsFiltersMapper from "../mappers/ApiLogItemsFiltersMapper"
import ApiLogItemsMapper from "../../../../core/data/mappers/ApiLogItemsMapper"

export default class DefaultApiLogItemsRepository implements ApiLogItemsRepository {
  private readonly apiLogItemsNetworkSource: ApiLogItemsNetworkSource

  constructor(parameters: {
    readonly apiLogItemsNetworkSource: ApiLogItemsNetworkSource
  }) {
    this.apiLogItemsNetworkSource = parameters.apiLogItemsNetworkSource
  }

  async getApiLogItems({
    filter,
    query,
    pagination,
    sort
  }: GetApiLogItemsParameters): Promise<GetObjectsPageResult<ApiLogItem>> {
    const result: GetApiLogItemsNetworkResult = await this.apiLogItemsNetworkSource.getApiLogItems({
      filter: filter && new ApiLogItemsFiltersMapper().mapDomainToNetwork({ filter, query }),
      pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({ pagination }),
      sort: sort && new SortMapper().mapDomainToNetwork({ sort })
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: {
            objects: result.data.apiLogItems!.map((apiLogItem: NetworkApiLogItem) => {
              return new ApiLogItemsMapper().mapNetworkToDomain({
                apiLogItem: apiLogItem
              })
            }),
            page: new PagesMapper().mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      default:
        return result
    }
  }

  async getApiLogItem({
    apiLogItemId
  }: {
    readonly apiLogItemId: number
  }): Promise<LoadObjectResult<ApiLogItem>> {
    const result: GetObjectNetworkResult<NetworkApiLogItem> =
      await this.apiLogItemsNetworkSource.getApiLogItem({
        apiLogItemId: apiLogItemId
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new ApiLogItemsMapper().mapNetworkToDomain({
            apiLogItem: result.data
          })
        }
      default:
        return result
    }
  }
}
