import TableValue from "./TableValue"
import { v4 as uuidv4 } from "uuid"

export default class TableColumn<DomainObject> {
  private readonly id: string
  private readonly title: string
  private readonly isSortingAvailable?: boolean

  readonly createValue: (object: DomainObject) => TableValue | null

  constructor(parameters: {
    readonly isSortingAvailable?: boolean
    readonly createValue: (object: DomainObject) => TableValue | null
    readonly title: string
  }) {
    this.id = uuidv4()
    this.isSortingAvailable = parameters.isSortingAvailable
    this.createValue = parameters.createValue
    this.title = parameters.title
  }

  getTitle() {
    return this.title
  }

  getId() {
    return this.id
  }

  getSortingAvailable() {
    return this.isSortingAvailable
  }
}
