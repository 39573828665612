import React, { useState } from "react"
import styles from "./SelectPickerComponent.module.scss"
import SelectOption from "../../entities/SelectOption"
import ButtonComponent, { ButtonStyle } from "../../../../design/button/ButtonComponent"
import { ReactComponent as ClearSvg } from "../../../assets/icons/ic_clear.svg"
import { ReactComponent as ArrowDownSvg } from "../../../assets/icons/ic_arrow_down_medium.svg"
import { ReactComponent as ArrowUpSvg } from "../../../assets/icons/ic_arrow_up_medium.svg"
import { TextInputComponent, TextInputStyle } from "../../../../design/text-input/TextInputComponent"
import AppDropdownComponent from "../app-dropdown/AppDropdownComponent"
import PickerItemComponent from "../picker-item/PickerItemComponent"
import LoadingIndicatorComponent from "../../../../design/loading-indicator/LoadingIndicatorComponent"

export interface SelectPickerComponentProps {
  readonly options: SelectOption[]
  readonly selectedOption: SelectOption | null
  readonly disabled?: boolean
  readonly clearable?: boolean
  readonly isLoading?: boolean
  readonly hasError?: boolean
  readonly selectButtonText: string | null | undefined
  readonly aboveOptionsElement?: React.ReactNode
  readonly belowOptionsElement?: React.ReactNode
  readonly onChange: (option: SelectOption | null) => void
  readonly onOpened?: () => void
}

export default function SelectPickerComponent({
  options,
  selectedOption,
  disabled,
  clearable,
  isLoading,
  selectButtonText,
  aboveOptionsElement,
  belowOptionsElement,
  onChange,
  onOpened,
  hasError
}: SelectPickerComponentProps) {
  const [isOpened, setIsOpened] = useState(false)

  function handleRequestHide() {
    setIsOpened(false)
  }

  function handleOnOpenClick() {
    if (disabled) {
      return
    }

    callOnOpened()
    setIsOpened(true)
  }

  function handleOnClearClick() {
    onChange(null)
  }

  function handleOnOptionClick(option: SelectOption) {
    onChange(option)
    setIsOpened(false)
  }

  function isOptionSelected(option: SelectOption) {
    return option.id === selectedOption?.id
  }

  function callOnOpened() {
    onOpened && onOpened()
  }

  // todo избавиться от стилей и перенесести в lib
  return (
    <div className={styles.root}>
      <div className={styles.selectButtonContainer}>
        <div className={styles.selectButton}>
          <TextInputComponent
            cancelFocusByClick={true}
            needShowCursorPointer={true}
            isDisabled={disabled}
            placeholder={selectButtonText}
            value={selectedOption && selectedOption.text?.replace(/\n/g, " ")}
            readOnly={true}
            onClick={handleOnOpenClick}
            textInputStyle={TextInputStyle.TEXT_INPUT1_PRIMARY}
            hasError={hasError}
            rightIcon={isOpened ? <ArrowUpSvg /> : <ArrowDownSvg />}
          />
        </div>

        <AppDropdownComponent
          isVisible={isOpened}
          hideOnClickOutside={true}
          hasScroll={true}
          needSetWidthByParent={true}
          requestHide={handleRequestHide}
        >
          {aboveOptionsElement}
          {options.map((option: SelectOption) => (
            <div
              key={option.id}
              className={styles.option}
            >
              <PickerItemComponent
                text={option.text}
                onClick={() => handleOnOptionClick(option)}
                isSelected={isOptionSelected(option)}
              />

            </div>
          ))}
          {belowOptionsElement}
          {isLoading && <div className={styles.loadingIndicator}><LoadingIndicatorComponent /></div>}
        </AppDropdownComponent>
      </div>
      {clearable && (
        <div className={styles.clearButton}>
          <ButtonComponent
            buttonStyle={ButtonStyle.OUTLINED1_PRIMARY}
            isDisabled={disabled}
            onClick={handleOnClearClick}
            leftIcon={<ClearSvg />}
          />
        </div>
      )}
    </div>
  )
}
