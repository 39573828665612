import React from "react"
import ProductViewModel from "./ProductViewModel"
import { useViewModel } from "../../../../../../sqadmin/lib/view-model/ViewModel"
import { useNumberObjectId } from "../../../../../../sqadmin/features/objects/presentation/hooks/useObjectId"
import ObjectComponent from "../../../../../../sqadmin/features/objects/presentation/components/object/ObjectComponent"
import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import { useCoreTextProvider } from "../../../../../core/presentation/contexts/CoreTextProviderContext"

export interface ProductPageProps {
  readonly provideViewModel: (parameters: { readonly productId?: number }) => ProductViewModel
}

export default function ProductPage({
  provideViewModel
}: ProductPageProps) {
  const coreTextProvider: CoreTextProvider = useCoreTextProvider()
  const productId: number | undefined = useNumberObjectId()

  const viewModel: ProductViewModel = useViewModel(() => provideViewModel({
    productId
  }))

  return (
    <ObjectComponent
      newObjectTitle={coreTextProvider.newProduct()}
      existedObjectTitle={coreTextProvider.product()}
      observableObjectViewState={viewModel.observableObjectViewState}
      onObjectViewEvent={viewModel.onObjectViewEvent}
    />
  )
}
