import { Expose } from "class-transformer"

export default class NetworkPaymentStatus {
  @Expose()
  readonly id: number | null | undefined

  @Expose()
  readonly name: string | null | undefined

  @Expose()
  readonly externalCode: string | null | undefined

  @Expose()
  readonly displayName: string | null | undefined

  constructor(parameters?: {
    readonly id: number | null | undefined
    readonly name: string | null | undefined
    readonly externalCode: string | null | undefined
    readonly displayName: string | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.id = parameters.id
    this.name = parameters.name
    this.externalCode = parameters.externalCode
    this.displayName = parameters.displayName
  }
}
