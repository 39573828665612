import { GetObjectsPageResult } from "../../../../sqadmin/features/objects/domain/results/GetObjectsPageResult"
import LastItemPaginationsMapper from "../../../core/data/mappers/LastItemPaginationsMapper"
import SortMapper from "../../../core/data/mappers/SortMapper"
import PagesMapper from "../../../core/data/mappers/PagesMapper"
import { LoadObjectResult } from "../../../../sqadmin/features/objects/domain/results/LoadObjectResult"
import GetObjectNetworkResult from "../../../core/data/results/GetObjectNetworkResult"
import { CreateObjectResult } from "../../../../sqadmin/features/objects/domain/results/CreateObjectResult"
import CreateObjectNetworkResult from "../../../core/data/results/CreateObjectNetworkResult"
import { UpdateObjectResult } from "../../../../sqadmin/features/objects/domain/results/UpdateObjectResult"
import UpdateObjectNetworkResult from "../../../core/data/results/UpdateObjectNetworkResult"
import { DestroyObjectResult } from "../../../../sqadmin/features/objects/domain/results/DestroyObjectResult"
import DestroyObjectNetworkResult from "../../../core/data/results/DestroyObjectNetworkResult"
import PlacesRepository from "../domian/repositories/PlacesRepository"
import Place from "../../../core/domain/entities/places/Place"
import { GetPlacesParameters } from "../domian/use-cases/GetPlacesUseCase"
import PlaceError from "../../../core/domain/entities/places/PlaceError"
import NetworkPlace from "../../../core/data/entities/places/NetworkPlace"
import PlacesMapper from "../../../core/data/mappers/PlacesMapper"
import NetworkPlaceError from "../../../core/data/entities/places/NetworkPlaceError"
import PlacesNetworkSource, { GetPlacesNetworkResult } from "../../../core/data/sources/network/PlacesNetworkSource"
import PlacesFiltersMapper from "../../../core/data/mappers/PlacesFiltersMapper"
import { UpdatePlaceParameters } from "../domian/use-cases/UpdatePlaceUseCase"

export default class DefaultPlacesRepository implements PlacesRepository {
  private readonly placesNetworkSource: PlacesNetworkSource

  constructor(parameters: {
    readonly placesNetworkSource: PlacesNetworkSource
  }) {
    this.placesNetworkSource = parameters.placesNetworkSource
  }

  async getPlaces({
    filter,
    pagination,
    sort
  }: GetPlacesParameters): Promise<GetObjectsPageResult<Place>> {
    const result: GetPlacesNetworkResult = await this.placesNetworkSource.getPlaces({
      filter: filter && new PlacesFiltersMapper().mapDomainToNetwork({ filter }),
      pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({ pagination }),
      sort: sort && new SortMapper().mapDomainToNetwork({ sort })
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: {
            objects: result.data.places!.map((place: Place) => {
              return new PlacesMapper().mapNetworkToDomain({
                place: place
              })
            }),
            page: new PagesMapper().mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      default:
        return result
    }
  }

  async getPlace({
    placeId
  }: {
    readonly placeId: number
  }): Promise<LoadObjectResult<Place>> {
    const result: GetObjectNetworkResult<NetworkPlace> =
      await this.placesNetworkSource.getPlace({
        placeId
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new PlacesMapper().mapNetworkToDomain({
            place: result.data
          })
        }
      default:
        return result
    }
  }

  async createPlace({
    place
  }: {
    readonly place: Place
  }): Promise<CreateObjectResult<Place, PlaceError>> {
    const result: CreateObjectNetworkResult<NetworkPlace, NetworkPlaceError> =
      await this.placesNetworkSource.createPlace({
        place: new PlacesMapper().mapDomainToNetwork({
          place: place
        })
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new PlacesMapper().mapNetworkToDomain({
            place: result.data
          })
        }
      default:
        return result
    }
  }

  async updatePlace({
    placeId,
    place
  }: UpdatePlaceParameters): Promise<UpdateObjectResult<Place, PlaceError>> {
    const result: UpdateObjectNetworkResult<NetworkPlace, NetworkPlaceError> =
      await this.placesNetworkSource.updatePlace({
        placeId,
        place: new PlacesMapper().mapDomainToNetwork({
          place: place
        })
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new PlacesMapper().mapNetworkToDomain({
            place: result.data
          })
        }
      default:
        return result
    }
  }

  async destroyPlace({
    placeId
  }: {
    readonly placeId: number
  }): Promise<DestroyObjectResult<PlaceError>> {
    const result: DestroyObjectNetworkResult<NetworkPlaceError> =
      await this.placesNetworkSource.destroyPlace({
        placeId
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: undefined
        }
      default:
        return result
    }
  }
}
