import React, { useState } from "react"
import CalendarComponent from "../calendar/CalendarComponent"
import styles from "./DateRangeCalendarComponent.module.scss"
import { correctDateRange, DateRange } from "../date-range/DateRange"
import ExtendedDate from "../extended-date/ExtendedDate"
import isPresent from "../isPresent"
import isBlank from "../isBlank"
import { flushSync } from "react-dom"

export function DateRangeCalendarComponent({
  dateRange: defaultDateRange,
  onDateRangeChange,
  weekDayNameByNumber,
  monthNameByNumber,
  onMonthChanged,
  renderDateDescription,
  month,
  nextMonth
}: {
  readonly dateRange: Partial<DateRange>
  readonly month: Date
  readonly nextMonth: Date
  readonly onDateRangeChange: (dateRange: Partial<DateRange>) => void
  readonly weekDayNameByNumber: { [_: number]: string }
  readonly monthNameByNumber: { [_: number]: string }
  readonly onMonthChanged?: ({ month }: { readonly month: Date }) => void
  readonly renderDateDescription?: (parameters: { readonly date: Date }) => React.ReactNode
}) {
  const dateRange: Partial<DateRange> = correctDateRange({
    dateFrom: defaultDateRange.dateFrom,
    dateTo: defaultDateRange.dateTo
  })

  const { dateFrom, dateTo } = dateRange
  const [focusedDate, setFocusedDate] = useState<Date | undefined>(undefined)

  function handleDateClick(date: Date) {
    if (isPresent(dateFrom) && isPresent(dateTo) || isBlank(dateFrom) && isBlank(dateTo)) {
      onDateRangeChange({
        dateFrom: date
      })
    } else if (isPresent(dateFrom) && isBlank(dateTo)) {
      onDateRangeChange(correctDateRange({
        dateFrom: dateFrom,
        dateTo: date
      }))
    } else if (isBlank(dateFrom) && isPresent(dateTo)) {
      onDateRangeChange(correctDateRange({
        dateFrom: date,
        dateTo: dateTo
      }))
    }
  }

  function handleNextMonthChange({ month }: { readonly month: Date }) {
    const newShownMonth: Date = new ExtendedDate(month).addMonths(-1)
    onMonthChanged && onMonthChanged({ month: newShownMonth })
  }

  function handleOnDateFocused(date: Date) {
    flushSync(() => setFocusedDate(date))
  }

  function handleOnMouseLeave() {
    setFocusedDate(undefined)
  }

  return (
    <div onMouseLeave={handleOnMouseLeave}>
      <div className={styles.calendars}>
        <CalendarComponent
          weekDayNameByNumber={weekDayNameByNumber}
          monthNameByNumber={monthNameByNumber}
          dateRange={dateRange}
          shownMonth={month}
          focusedDate={focusedDate}
          isRangeDisplayEnabled={true}
          needShowNextMonthPart={false}
          renderDateDescription={renderDateDescription}
          onDateClick={handleDateClick}
          onMonthChanged={onMonthChanged}
          onDateFocused={handleOnDateFocused}
        />
        <CalendarComponent
          weekDayNameByNumber={weekDayNameByNumber}
          monthNameByNumber={monthNameByNumber}
          dateRange={dateRange}
          shownMonth={nextMonth}
          focusedDate={focusedDate}
          isRangeDisplayEnabled={true}
          needShowPreviousMonthPart={false}
          renderDateDescription={renderDateDescription}
          onDateClick={handleDateClick}
          onMonthChanged={handleNextMonthChange}
          onDateFocused={handleOnDateFocused}
        />
      </div>
    </div>
  )
}
