import Product from "../../../../../core/domain/entities/products/Product"
import ProductsRepository from "../../repositories/ProductsRepository"
import { LoadObjectResult } from "../../../../../../sqadmin/features/objects/domain/results/LoadObjectResult"

export default class GetProductUseCase {
  private readonly productsRepository: ProductsRepository

  constructor(parameters: {
    readonly productsRepository: ProductsRepository
  }) {
    this.productsRepository = parameters.productsRepository
  }

  async call(parameters: { readonly productId: number }): Promise<LoadObjectResult<Product>> {
    return await this.productsRepository.getProduct(parameters)
  }
}
