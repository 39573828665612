export default class TableValue {
  private readonly url?: string | null

  constructor(parameters: {
    readonly url?: string | null
  }) {
    this.url = parameters.url
  }

  getUrl() {
    return this.url
  }
}
