import NetworkProduct from "../../../../core/data/entities/products/NetworkProduct"
import { Expose } from "class-transformer"

export default class NetworkProductRequestBody {
  @Expose()
  readonly product: NetworkProduct

  constructor(parameters: {
    readonly product: NetworkProduct
  }) {
    this.product = parameters.product
  }
}
