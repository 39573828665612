import ObjectsPresentationLogicParameters
  from "../../../../../../sqadmin/features/objects/presentation/entities/objects/ObjectsPresentationLogicParameters"
import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import { useCoreTextProvider } from "../../../../../core/presentation/contexts/CoreTextProviderContext"
import CoreUrlProvider from "../../../../../core/presentation/services/CoreUrlProvider"
import useObjectsPresentationLogicParameters
  from "../../../../../../sqadmin/features/objects/presentation/hooks/useObjectsPresentationLogicParameters"
import { useViewModel } from "../../../../../../sqadmin/lib/view-model/ViewModel"
import ObjectsComponent
  from "../../../../../../sqadmin/features/objects/presentation/components/objects/ObjectsComponent"
import React from "react"
import AdminsViewModel from "./AdminsViewModel"
import User from "../../../../../core/domain/entities/users/User"

export interface AdminsPageParameters {
  readonly provideViewModel: (parameters: {
    readonly objectsPresentationLogicParameters: ObjectsPresentationLogicParameters
  }) => AdminsViewModel
}

export default function AdminsPage({
  provideViewModel
}: AdminsPageParameters) {
  const coreTextProvider: CoreTextProvider = useCoreTextProvider()
  const coreUrlProvider: CoreUrlProvider = new CoreUrlProvider()
  const objectsPresentationLogicParameters: ObjectsPresentationLogicParameters = useObjectsPresentationLogicParameters()

  const viewModel: AdminsViewModel = useViewModel(() => provideViewModel({
    objectsPresentationLogicParameters
  }))

  return (
    <ObjectsComponent
      title={coreTextProvider.admins()}
      getObjectUrl={(user: User) => coreUrlProvider.buildAdminUrl({ id: user.id! })}
      newObjectUrl={coreUrlProvider.buildNewAdminUrl()}
      observableObjectsPageViewState={viewModel.observableObjectsPageViewState}
      observableObjectsViewAction={viewModel.observableObjectsViewAction}
      onObjectsViewEvent={viewModel.onObjectsViewEvent}
    />
  )
}
