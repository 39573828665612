import { LoadObjectResult } from "../../../../../sqadmin/features/objects/domain/results/LoadObjectResult"
import ConfigurationsRepository from "../repositories/ConfigurationsRepository"
import Configuration from "../../../../core/domain/entities/configurations/Configuration"

export default class GetConfigurationUseCase {
  private readonly configurationsRepository: ConfigurationsRepository

  constructor(parameters: {
    readonly configurationsRepository: ConfigurationsRepository
  }) {
    this.configurationsRepository = parameters.configurationsRepository
  }

  async call(): Promise<LoadObjectResult<Configuration>> {
    return await this.configurationsRepository.getConfiguration()
  }
}
