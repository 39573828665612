import { Expose } from "class-transformer"
import NetworkOrderProcessingStatus from "./NetworkOrderProcessingStatus"

export default class NetworkOrderProcessingStatusRequestBody {
  @Expose()
  readonly orderProcessingStatus: NetworkOrderProcessingStatus

  constructor(parameters: {
    readonly orderProcessingStatus: NetworkOrderProcessingStatus
  }) {
    this.orderProcessingStatus = parameters.orderProcessingStatus
  }
}
