import ViewModel from "../../../../../../sqadmin/lib/view-model/ViewModel"
import CoreI18n from "../../../../../core/i18n/CoreI18n"
import CoreUrlProvider from "../../../../../core/presentation/services/CoreUrlProvider"
import BroadcastObjectsEventUseCase
  from "../../../../../../sqadmin/features/objects/domain/use-cases/objects/BroadcastObjectsEventUseCase"
import ObjectPresentationLogic
  from "../../../../../../sqadmin/features/objects/presentation/presentation-logics/ObjectPresentationLogic"
import { StateObservable } from "../../../../../../sqadmin/lib/view-model/StateObservable"
import { ObjectViewState } from "../../../../../../sqadmin/features/objects/presentation/view-states/ObjectViewState"
import autoBind from "auto-bind"
import ObjectViewEvent from "../../../../../../sqadmin/features/objects/presentation/view-events/ObjectViewEvent"
import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import isBlank from "../../../../../../sqadmin/lib/isBlank"
import StringFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/StringFormField"
import DestroyBonusProgramLevelUseCase
  from "../../../../bonus-program-levels-core/domain/use-cases/DestroyBonusProgramLevelUseCase"
import UpdateBonusProgramLevelUseCase
  from "../../../../bonus-program-levels-core/domain/use-cases/UpdateBonusProgramLevelUseCase"
import CreateBonusProgramLevelUseCase
  from "../../../../bonus-program-levels-core/domain/use-cases/CreateBonusProgramLevelUseCase"
import GetBonusProgramLevelUseCase
  from "../../../../bonus-program-levels-core/domain/use-cases/GetBonusProgramLevelUseCase"
import BonusProgramLevel from "../../../../../core/domain/entities/bonus-program-levels/BonusProgramLevel"
import BonusProgramLevelError from "../../../../../core/domain/entities/bonus-program-levels/BonusProgramLevelError"
import BonusProgramLevelErrorsObject
  from "../../../../../core/domain/entities/bonus-program-levels/BonusProgramLevelErrorsObject"
import NumberFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/NumberFormField"
import SingleSelectFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/SingleSelectFormField"
import BonusProgramLevelTransitionRulePeriodType
  from "../../../../../core/domain/entities/bonus-program-level-transition-rules/BonusProgramLevelTransitionRulePeriodType"
import BonusProgramLevelTransitionRule
  from "../../../../../core/domain/entities/bonus-program-level-transition-rules/BonusProgramLevelTransitionRule"
import BonusProgramLevelTransitionRuleType
  from "../../../../../core/domain/entities/bonus-program-level-transition-rules/BonusProgramLevelTransitionRuleType"
import ListFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/ListFormField"
import ProductsSet from "../../../../../core/domain/entities/products-sets/ProductsSet"
import ProductsSetErrorsObject from "../../../../../core/domain/entities/products-sets/ProductsSetErrorsObject"
import isPresent from "../../../../../../sqadmin/lib/isPresent"
import ProductsSetCondition from "../../../../../core/domain/entities/products-set-condition/ProductsSetCondition"
import { v4 as uuidv4 } from "uuid"
import GetPropertyValuesUseCase from "../../../../property-values-core/domain/use-cases/GetPropertyValuesUseCase"
import GetPropertiesUseCase from "../../../../properties-core/domain/use-cases/GetPropertiesUseCase"
import GetProductCategoriesUseCase
  from "../../../../product-categories-core/domain/use-cases/product-categories/GetProductCategoriesUseCase"
import GetProductsUseCase from "../../../../products-core/domain/use-cases/products/GetProductsUseCase"
import { ProductsSetConditionsFields } from "../../../../../core/presentation/fields/ProductsSetConditionsFields"
import BonusProgramLevelTransitionRuleCheckingValueType
  from "../../../../../core/domain/entities/bonus-program-level-transition-rules/BonusProgramLevelTransitionRuleCheckingValueType"
import { Decimal } from "decimal.js"
import DecimalFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/DecimalFormField"

export default class BonusProgramLevelViewModel extends ViewModel {
  private readonly coreI18n: CoreI18n
  private readonly coreUrlProvider: CoreUrlProvider
  private readonly broadcastObjectsEventUseCase: BroadcastObjectsEventUseCase
  private readonly getBonusProgramLevelUseCase: GetBonusProgramLevelUseCase
  private readonly createBonusProgramLevelUseCase: CreateBonusProgramLevelUseCase
  private readonly updateBonusProgramLevelUseCase: UpdateBonusProgramLevelUseCase
  private readonly destroyBonusProgramLevelUseCase: DestroyBonusProgramLevelUseCase
  private readonly getProductCategoriesUseCase: GetProductCategoriesUseCase
  private readonly getPropertiesUseCase: GetPropertiesUseCase
  private readonly getPropertyValuesUseCase: GetPropertyValuesUseCase
  private readonly getProductsUseCase: GetProductsUseCase
  private readonly bonusProgramLevelId?: number

  private readonly objectPresentationLogic: ObjectPresentationLogic<
    BonusProgramLevel,
    BonusProgramLevelError,
    BonusProgramLevelErrorsObject
  >

  readonly observableObjectViewState: StateObservable<ObjectViewState>

  constructor(parameters: {
    readonly coreI18n: CoreI18n
    readonly broadcastObjectsEventUseCase: BroadcastObjectsEventUseCase
    readonly getBonusProgramLevelUseCase: GetBonusProgramLevelUseCase
    readonly createBonusProgramLevelUseCase: CreateBonusProgramLevelUseCase
    readonly updateBonusProgramLevelUseCase: UpdateBonusProgramLevelUseCase
    readonly destroyBonusProgramLevelUseCase: DestroyBonusProgramLevelUseCase
    readonly getProductCategoriesUseCase: GetProductCategoriesUseCase
    readonly getPropertiesUseCase: GetPropertiesUseCase
    readonly getPropertyValuesUseCase: GetPropertyValuesUseCase
    readonly getProductsUseCase: GetProductsUseCase
    readonly bonusProgramLevelId?: number
  }) {
    super()
    this.coreI18n = parameters.coreI18n
    this.broadcastObjectsEventUseCase = parameters.broadcastObjectsEventUseCase
    this.getBonusProgramLevelUseCase = parameters.getBonusProgramLevelUseCase
    this.createBonusProgramLevelUseCase = parameters.createBonusProgramLevelUseCase
    this.updateBonusProgramLevelUseCase = parameters.updateBonusProgramLevelUseCase
    this.destroyBonusProgramLevelUseCase = parameters.destroyBonusProgramLevelUseCase
    this.getProductCategoriesUseCase = parameters.getProductCategoriesUseCase
    this.getPropertiesUseCase = parameters.getPropertiesUseCase
    this.getPropertyValuesUseCase = parameters.getPropertyValuesUseCase
    this.getProductsUseCase = parameters.getProductsUseCase
    this.bonusProgramLevelId = parameters.bonusProgramLevelId
    this.objectPresentationLogic = this.createObjectPresentationLogic()
    this.observableObjectViewState = this.objectPresentationLogic.observableObjectViewState
    this.coreUrlProvider = new CoreUrlProvider()
    autoBind(this)
  }

  onViewObjectEvent(objectViewEvent: ObjectViewEvent) {
    this.objectPresentationLogic.onObjectViewEvent(objectViewEvent)
  }

  private createObjectPresentationLogic(): ObjectPresentationLogic<
    BonusProgramLevel,
    BonusProgramLevelError,
    BonusProgramLevelErrorsObject
  > {
    const coreTextProvider: CoreTextProvider = this.coreI18n.getTextProvider()

    return new ObjectPresentationLogic({
      // TODO: how to not pass?
      broadcastObjectsEventUseCase: this.broadcastObjectsEventUseCase,
      isNewObject: isBlank(this.bonusProgramLevelId),
      buildObject: async() => ({
        id: undefined,
        name: undefined,
        position: undefined,
        bonusPointsPercent: undefined,
        transitionRule: undefined
      }),
      getObjectUrl: (bonusProgramLevel) => {
        return this.coreUrlProvider.buildBonusProgramLevelUrl({
          id: bonusProgramLevel.id!
        })
      },
      loadObject: async() => {
        return await this.getBonusProgramLevelUseCase.call({ bonusProgramLevelId: this.bonusProgramLevelId! })
      },
      createObject: async({ object: bonusProgramLevel }) => {
        return await this.createBonusProgramLevelUseCase.call({ bonusProgramLevel })
      },
      updateObject: async({ object: bonusProgramLevel }) => {
        return await this.updateBonusProgramLevelUseCase.call({
          bonusProgramLevelId: this.bonusProgramLevelId!,
          bonusProgramLevel
        })
      },
      destroyObject: async() => {
        return await this.destroyBonusProgramLevelUseCase.call({ bonusProgramLevelId: this.bonusProgramLevelId! })
      },
      getErrorsObject: ({ error: bonusProgramLevelError }) => bonusProgramLevelError
        ?.errorsObject,
      formFields: [
        new StringFormField<BonusProgramLevel, BonusProgramLevelErrorsObject>({
          getTitle: () => coreTextProvider.name(),
          getValue: (bonusProgramLevel: BonusProgramLevel) => bonusProgramLevel.name,
          setValue: (bonusProgramLevel: BonusProgramLevel, name: string): BonusProgramLevel => ({
            ...bonusProgramLevel,
            name
          }),
          getErrors: (bonusProgramLevelErrorsObject?: BonusProgramLevelErrorsObject) => bonusProgramLevelErrorsObject
            ?.attributes
            ?.name
        }),
        new NumberFormField<BonusProgramLevel, BonusProgramLevelErrorsObject>({
          getTitle: () => coreTextProvider.bonusPointsPercent(),
          getValue: (bonusProgramLevel: BonusProgramLevel) => bonusProgramLevel.bonusPointsPercent,
          setValue: (
            bonusProgramLevel: BonusProgramLevel,
            bonusPointsPercent: number | undefined | null
          ): BonusProgramLevel => ({
            ...bonusProgramLevel,
            bonusPointsPercent
          }),
          getErrors: (bonusProgramLevelErrorsObject?: BonusProgramLevelErrorsObject) => bonusProgramLevelErrorsObject
            ?.attributes
            ?.bonusPointsPercent
        }),
        new NumberFormField<BonusProgramLevel, BonusProgramLevelErrorsObject>({
          getTitle: () => coreTextProvider.position(),
          getValue: (bonusProgramLevel: BonusProgramLevel) => bonusProgramLevel.position,
          setValue: (
            bonusProgramLevel: BonusProgramLevel,
            position: number | undefined | null
          ): BonusProgramLevel => ({
            ...bonusProgramLevel,
            position
          }),
          getErrors: (bonusProgramLevelErrorsObject?: BonusProgramLevelErrorsObject) => bonusProgramLevelErrorsObject
            ?.attributes
            ?.position
        }),
        new SingleSelectFormField<
          BonusProgramLevel,
          BonusProgramLevelErrorsObject,
          BonusProgramLevelTransitionRuleType
        >({
          isSearchBarVisible: false,
          getObjects: async() => {
            return {
              type: "success",
              data: {
                objects: Object.values(BonusProgramLevelTransitionRuleType),
                page: { hasMore: false }
              }
            }
          },
          getTitle: () => coreTextProvider.bonusProgramLevelTransitionRule(),
          getValue: (bonusProgramLevel: BonusProgramLevel) => bonusProgramLevel.transitionRule?.type,
          setValue: (
            bonusProgramLevel: BonusProgramLevel,
            type: BonusProgramLevelTransitionRuleType | null
          ) => ({
            ...bonusProgramLevel,
            transitionRule: {
              ...bonusProgramLevel.transitionRule ?? this.createTransitionRute(),
              type: type
            }
          }),
          getErrors: (errorsObject?: BonusProgramLevelErrorsObject) => errorsObject
            ?.transitionRule
            ?.attributes
            ?.type,
          getOptionId: (type: BonusProgramLevelTransitionRuleType) => type.valueOf(),
          getOptionText: (
            type: BonusProgramLevelTransitionRuleType
          ) => this.detectBonusProgramLevelTransitionRuleTypeDisplayName(type)
        }),
        new SingleSelectFormField<
          BonusProgramLevel,
          BonusProgramLevelErrorsObject,
          BonusProgramLevelTransitionRulePeriodType
        >({
          isSearchBarVisible: false,
          getObjects: async() => {
            return {
              type: "success",
              data: {
                objects: Object.values(BonusProgramLevelTransitionRulePeriodType),
                page: { hasMore: false }
              }
            }
          },
          getTitle: () => coreTextProvider.period(),
          getValue: (bonusProgramLevel: BonusProgramLevel) => bonusProgramLevel.transitionRule?.periodType,
          setValue: (
            bonusProgramLevel: BonusProgramLevel,
            type: BonusProgramLevelTransitionRulePeriodType | null
          ) => ({
            ...bonusProgramLevel,
            transitionRule: {
              ...bonusProgramLevel.transitionRule ?? this.createTransitionRute(),
              periodType: type
            }
          }),
          getErrors: (errorsObject?: BonusProgramLevelErrorsObject) => errorsObject
            ?.transitionRule
            ?.attributes
            ?.periodType,
          getOptionId: (type: BonusProgramLevelTransitionRulePeriodType) => type.valueOf(),
          getOptionText: (
            type: BonusProgramLevelTransitionRulePeriodType
          ) => this.detectBonusProgramLevelTransitionRulePeriodTypeDisplayName(type)
        }),
        new NumberFormField<BonusProgramLevel, BonusProgramLevelErrorsObject>({
          getTitle: () => coreTextProvider.bonusProgramLevelTransitionRulePeriodsCount(),
          getValue: (bonusProgramLevel: BonusProgramLevel) => bonusProgramLevel.transitionRule?.periodsCount,
          setValue: (
            bonusProgramLevel: BonusProgramLevel,
            periodsCount: number | undefined | null
          ): BonusProgramLevel => ({
            ...bonusProgramLevel,
            transitionRule: {
              ...bonusProgramLevel.transitionRule ?? this.createTransitionRute(),
              periodsCount: periodsCount
            }
          }),
          getErrors: (bonusProgramLevelErrorsObject?: BonusProgramLevelErrorsObject) => bonusProgramLevelErrorsObject
            ?.transitionRule
            ?.attributes
            ?.periodsCount
        }),
        new SingleSelectFormField<
          BonusProgramLevel,
          BonusProgramLevelErrorsObject,
          BonusProgramLevelTransitionRuleCheckingValueType
        >({
          isSearchBarVisible: false,
          getObjects: async() => {
            return {
              type: "success",
              data: {
                objects: Object.values(BonusProgramLevelTransitionRuleCheckingValueType),
                page: { hasMore: false }
              }
            }
          },
          getTitle: () => coreTextProvider.bonusProgramLevelTransitionRuleCheckingValueType(),
          getValue: (bonusProgramLevel: BonusProgramLevel) => bonusProgramLevel.transitionRule?.checkingValueType,
          setValue: (
            bonusProgramLevel: BonusProgramLevel,
            type: BonusProgramLevelTransitionRuleCheckingValueType | null
          ) => ({
            ...bonusProgramLevel,
            transitionRule: {
              ...bonusProgramLevel.transitionRule ?? this.createTransitionRute(),
              checkingValueType: type
            }
          }),
          getErrors: (errorsObject?: BonusProgramLevelErrorsObject) => errorsObject
            ?.transitionRule
            ?.attributes
            ?.checkingValueType,
          getOptionId: (type: BonusProgramLevelTransitionRuleCheckingValueType) => type.valueOf(),
          getOptionText: (
            type: BonusProgramLevelTransitionRuleCheckingValueType
          ) => this.detectBonusProgramLevelTransitionRuleCheckingValueTypeDisplayName(type)
        }),
        new DecimalFormField<BonusProgramLevel, BonusProgramLevelErrorsObject>({
          getTitle: () => coreTextProvider.value(),
          getValue: (bonusProgramLevel: BonusProgramLevel) => bonusProgramLevel.transitionRule?.checkingValue,
          setValue: (
            bonusProgramLevel: BonusProgramLevel,
            checkingValue: Decimal | undefined | null
          ): BonusProgramLevel => ({
            ...bonusProgramLevel,
            transitionRule: {
              ...bonusProgramLevel.transitionRule ?? this.createTransitionRute(),
              checkingValue: checkingValue
            }
          }),
          getErrors: (bonusProgramLevelErrorsObject?: BonusProgramLevelErrorsObject) => bonusProgramLevelErrorsObject
            ?.transitionRule
            ?.attributes
            ?.checkingValue
        }),
        this.createBonusProgramLevelsTransitionRuleProductsSetsFormField()
      ]
    })
  }

  private createTransitionRute(): BonusProgramLevelTransitionRule {
    return {
      type: undefined,
      periodType: undefined,
      checkingValueType: undefined,
      checkingValue: undefined,
      periodsCount: undefined,
      productsSets: undefined
    }
  }

  private detectBonusProgramLevelTransitionRuleTypeDisplayName(type: BonusProgramLevelTransitionRuleType) {
    const coreTextProvider: CoreTextProvider = this.coreI18n.getTextProvider()
    switch (type) {
      case BonusProgramLevelTransitionRuleType.PERIODIC_BUY:
        return coreTextProvider.bonusProgramLevelTransitionRulePeriodicBuyType()
    }
  }

  private detectBonusProgramLevelTransitionRulePeriodTypeDisplayName(type: BonusProgramLevelTransitionRulePeriodType) {
    const coreTextProvider: CoreTextProvider = this.coreI18n.getTextProvider()
    switch (type) {
      case BonusProgramLevelTransitionRulePeriodType.MONTHLY:
        return coreTextProvider.bonusProgramLevelTransitionRuleMonthlyPeriodType()
    }
  }

  private detectBonusProgramLevelTransitionRuleCheckingValueTypeDisplayName(
    type: BonusProgramLevelTransitionRuleCheckingValueType
  ) {
    const coreTextProvider: CoreTextProvider = this.coreI18n.getTextProvider()
    switch (type) {
      case BonusProgramLevelTransitionRuleCheckingValueType.ORDER_ITEMS_QUANTITY:
        return coreTextProvider.orderItemsQuantity()
    }
  }

  private createBonusProgramLevelsTransitionRuleProductsSetsFormField() {
    return new ListFormField<
      BonusProgramLevel,
      BonusProgramLevelErrorsObject,
      ProductsSet,
      ProductsSetErrorsObject
    >({
      addObjectButtonName: this.coreI18n.getTextProvider().add(),
      getTitle: () => this.coreI18n.getTextProvider().productsSetConditions(),
      getValue: (bonusProgramLevel: BonusProgramLevel): ProductsSet[] | null | undefined => {
        return bonusProgramLevel.transitionRule?.productsSets
      },
      getErrors: (
        errorsObject?: BonusProgramLevelErrorsObject
      ) => errorsObject?.transitionRule?.attributes?.productsSets,
      setValue: (
        bonusProgramLevel: BonusProgramLevel,
        productsSets: ProductsSet[] | null
      ): BonusProgramLevel => ({
        ...bonusProgramLevel,
        transitionRule: {
          ...bonusProgramLevel.transitionRule ?? this.createTransitionRute(),
          productsSets: productsSets
        }
      }),
      getNestedObjectId: (productsSet: ProductsSet) => productsSet.clientId!,
      getNestedObjectTitle: (_: ProductsSet, index: number) => {
        return this.coreI18n.getTextProvider().productsSetWithNumber({ number: index + 1 })
      },
      getNestedErrorsObject: (
        productsSet: ProductsSet,
        bonusProgramLevelErrorsObject?: BonusProgramLevelErrorsObject
      ) => {
        return bonusProgramLevelErrorsObject?.transitionRule?.productsSets?.find(
          (productsSetErrorsObject: ProductsSetErrorsObject) => {
            return productsSetErrorsObject.clientId === productsSet.clientId
          })
      },
      hasNestedErrorsObjects: (
        bonusProgramLevelErrorsObject?: BonusProgramLevelErrorsObject
      ) => {
        return isPresent(bonusProgramLevelErrorsObject?.transitionRule?.productsSets)
      },
      buildNewValue: (): ProductsSet => this.createProductsSet(),
      fields: [
        this.createBonusProgramLevelTransitionRuleProductsSetConditionsFormField()
      ]
    })
  }

  private createBonusProgramLevelTransitionRuleProductsSetConditionsFormField() {
    return new ListFormField<
      ProductsSet,
      ProductsSetErrorsObject,
      ProductsSetCondition,
      ProductsSetErrorsObject
    >({
      addObjectButtonName: this.coreI18n.getTextProvider().add(),
      getTitle: () => this.coreI18n.getTextProvider().productsSetConditions(),
      getValue: (productsSet: ProductsSet): ProductsSetCondition[] | null | undefined => {
        return productsSet.conditions
      },
      getErrors: (productsSetErrorsObject?: ProductsSetErrorsObject) => {
        const productsSetProducts = productsSetErrorsObject?.attributes?.productsSetProducts
        const productsSetProperties = productsSetErrorsObject?.attributes?.productsSetProperties
        const productCategoryId = productsSetErrorsObject?.attributes?.productCategoryId

        if (isPresent(productsSetProducts)) return productsSetProducts
        if (isPresent(productsSetProperties)) return productsSetProperties
        if (isPresent(productCategoryId)) return productCategoryId

        return []
      },
      setValue: (productsSet: ProductsSet, productsSetConditions: ProductsSetCondition[] | null): ProductsSet => {
        return {
          ...productsSet ?? this.createProductsSet(),
          conditions: productsSetConditions
        }
      },
      getNestedObjectId: (productsSetCondition: ProductsSetCondition) => productsSetCondition.clientId!,
      getNestedObjectTitle: (_: ProductsSetCondition, index: number) => {
        return this.coreI18n.getTextProvider().productsSetConditionWithNumber({ number: index + 1 })
      },
      getNestedErrorsObject: (_: ProductsSetCondition, productsSetErrorsObject?: ProductsSetErrorsObject) => {
        return productsSetErrorsObject
      },
      hasNestedErrorsObjects: (productsSetErrorsObject?: ProductsSetErrorsObject) => {
        return isPresent(productsSetErrorsObject)
      },
      buildNewValue: (): ProductsSetCondition => this.createProductsSetCondition(),
      fields: this.createProductsSetConditionsFields().createFields()
    })
  }

  private createProductsSetCondition(): ProductsSetCondition {
    return {
      clientId: uuidv4()
    }
  }

  private createProductsSet(): ProductsSet {
    return {
      id: undefined,
      conditions: undefined,
      clientId: uuidv4()
    }
  }

  private createProductsSetConditionsFields() {
    return new ProductsSetConditionsFields({
      coreI18n: this.coreI18n,
      getProductCategories: async({ query, lastObjectId }) => await this.getProductCategoriesUseCase.call({
        filter: { query },
        pagination: { id: lastObjectId }
      }),
      getProperties: async({ query, lastObjectId }) => await this.getPropertiesUseCase.call({
        filter: { query },
        pagination: { id: lastObjectId }
      }),
      getPropertyValues: async({ query, propertyId }) => await this.getPropertyValuesUseCase.call({
        query,
        propertyId
      }),
      getProducts: async({ filter, query, lastObjectId }) => await this.getProductsUseCase.call({
        filter,
        query,
        pagination: { id: lastObjectId }
      }),
      findProductsSetForCondition: ({
        productsSetCondition
      }) => this.findProductsSetForCondition({ productsSetCondition })
    })
  }

  private findProductsSetForCondition({
    productsSetCondition
  }: {
    readonly productsSetCondition: ProductsSetCondition
  }): ProductsSet | undefined {
    const bonusProgramLevelTransitionRule: BonusProgramLevelTransitionRule | null | undefined =
      this.objectPresentationLogic.getObject()?.transitionRule

    if (isBlank(bonusProgramLevelTransitionRule)) {
      return undefined
    }

    for (const productsSet of bonusProgramLevelTransitionRule.productsSets ?? []) {
      for (const searchingProductsSetCondition of productsSet.conditions ?? []) {
        if (searchingProductsSetCondition.clientId === productsSetCondition.clientId) {
          return productsSet
        }
      }
    }

    return undefined
  }
}
