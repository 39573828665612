import { Expose, Type } from "class-transformer"
import BannerErrorsObject from "../../../domain/entities/banners/BannerErrorsObject"
import NetworkBannerErrorsObjectAttributes from "./NetworkBannerErrorsObjectAttributes"
import NetworkImageErrorsObject from "../images/NetworkImageErrorsObject"
import NetworkLinkErrorsObject from "../links/NetworkLinkErrorsObject"
import NetworkProductsSetErrorsObject from "../products-sets/NetworkProductsSetErrorsObject"

export default class NetworkBannerErrorsObject implements BannerErrorsObject {
  @Expose()
  readonly clientId: string | null | undefined

  @Expose()
  @Type(() => NetworkBannerErrorsObjectAttributes)
  readonly attributes: NetworkBannerErrorsObjectAttributes | null | undefined

  @Expose()
  @Type(() => NetworkImageErrorsObject)
  readonly image: NetworkImageErrorsObject | null | undefined

  @Expose()
  @Type(() => NetworkLinkErrorsObject)
  readonly link: NetworkLinkErrorsObject | null | undefined

  @Expose()
  @Type(() => NetworkProductsSetErrorsObject)
  readonly productsSet: NetworkProductsSetErrorsObject | null | undefined
}
