import NetworkOrderPaymentMethodsRequestFilter from "../entities/NetworkOrderPaymentMethodsRequestFilter"

export default class OrderPaymentMethodsFilterMapper {
  mapDomainToNetwork({
    query
  }: {
    readonly query: string | null | undefined
  }): NetworkOrderPaymentMethodsRequestFilter {
    return {
      query: query
    }
  }
}
