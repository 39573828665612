import NetworkConfiguration from "../entities/configurations/NetworkConfiguration"
import Configuration from "../../domain/entities/configurations/Configuration"
import ConfigurationEntitiesMapper from "./ConfigurationEntitiesMapper"

export default class ConfigurationsMapper {
  mapNetworkToDomain({
    configuration
  }: {
    readonly configuration: NetworkConfiguration
  }): Configuration {
    return {
      entities: configuration.entities && configuration.entities.map(entity => {
        return new ConfigurationEntitiesMapper().mapNetworkToDomain({ configurationEntity: entity })
      })
    }
  }

  mapDomainToNetwork({
    configuration
  }: {
    readonly configuration: Configuration
  }): NetworkConfiguration {
    return new NetworkConfiguration({
      entities: configuration.entities && configuration.entities.map(entity => {
        return new ConfigurationEntitiesMapper().mapDomainToNetwork({ configurationEntity: entity })
      })
    })
  }
}
