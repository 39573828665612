import { UpdateObjectResult } from "../../../../../sqadmin/features/objects/domain/results/UpdateObjectResult"
import OrderReceivingMethod from "../../../../core/domain/entities/order-receiving-methods/OrderReceivingMethod"
import OrderReceivingMethodsRepository from "../repositories/OrderReceivingMethodsRepository"
import OrderReceivingMethodError
  from "../../../../core/domain/entities/order-receiving-methods/OrderReceivingMethodError"

export default class UpdateOrderReceivingMethodUseCase {
  private readonly orderReceivingMethodsRepository: OrderReceivingMethodsRepository

  constructor(parameters: {
    readonly orderReceivingMethodsRepository: OrderReceivingMethodsRepository
  }) {
    this.orderReceivingMethodsRepository = parameters.orderReceivingMethodsRepository
  }

  async call(
    parameters: UpdateOrderReceivingMethodParameters
  ): Promise<UpdateObjectResult<OrderReceivingMethod, OrderReceivingMethodError>> {
    return await this.orderReceivingMethodsRepository.updateOrderReceivingMethod(parameters)
  }
}

export interface UpdateOrderReceivingMethodParameters {
  readonly orderReceivingMethodId: number
  readonly orderReceivingMethod: OrderReceivingMethod
}
