import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../errors/NetworkAttributeError"
import AttributeError from "../../../../../sqadmin/core/domain/entities/AttributeError"
import OrderShippingMethodErrorsObjectAttributes
  from "../../../domain/entities/order-shipping-methods/OrderShippingMethodErrorsObjectAttributes"

export default class NetworkOrderShippingMethodErrorsObjectAttributes
  implements OrderShippingMethodErrorsObjectAttributes {
  @Expose()
  @Type(() => NetworkAttributeError)
  readonly type: AttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly settlementId: AttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly settlement: AttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly id: AttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly orderShippingTariff: AttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly orderShippingTariffId: AttributeError[] | null | undefined
}
