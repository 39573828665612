import DiModule from "../../../../sqadmin/lib/di/DiModule"
import ConfigurationsCoreDataDiModule from "./ConfigurationsCoreDataDiModule"
import GetConfigurationUseCase from "../domain/use-cases/GetConfigurationUseCase"

export default interface ConfigurationsCoreDomainDiModule {
  provideGetConfigurationUseCase(): GetConfigurationUseCase
}

export class DefaultConfigurationsCoreDomainDiModule extends DiModule
  implements ConfigurationsCoreDomainDiModule {

  private readonly configurationsCoreDataDiModule: ConfigurationsCoreDataDiModule

  constructor(parameters: {
    readonly configurationsCoreDataDiModule: ConfigurationsCoreDataDiModule
  }) {
    super()
    this.configurationsCoreDataDiModule = parameters.configurationsCoreDataDiModule
  }

  provideGetConfigurationUseCase(): GetConfigurationUseCase {
    return this.single(
      GetConfigurationUseCase.name,
      () => new GetConfigurationUseCase({
        configurationsRepository: this.configurationsCoreDataDiModule.provideConfigurationsRepository()
      })
    )
  }
}
