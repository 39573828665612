import { v4 as uuidv4 } from "uuid"
import ProductsSetProperty from "../../domain/entities/products-set-properties/ProductsSetProperty"
import PropertiesMapper from "./PropertiesMapper"
import PropertyValuesMapper from "./PropertyValuesMapper"
import NetworkProductsSetProperty from "../entities/products-set-properties/NetworkProductsSetProperty"
import { filterNotEmpty } from "../../../../sqadmin/lib/filterNotEmpty"

export default class ProductsSetPropertiesMapper {
  mapNetworkToDomain({
    productsSetProperty
  }: {
    readonly productsSetProperty: NetworkProductsSetProperty
  }): ProductsSetProperty {
    const propertyValuesMapper = new PropertyValuesMapper()

    return {
      clientId: uuidv4(),
      propertyId: productsSetProperty.propertyId,
      property: productsSetProperty.property && new PropertiesMapper().mapNetworkToDomain({
        property: productsSetProperty.property
      }),
      propertyValueIds: productsSetProperty.propertyValues && filterNotEmpty(
        productsSetProperty.propertyValues.map((propertyValue) => propertyValue.id)
      ),
      propertyValues: productsSetProperty.propertyValues && productsSetProperty.propertyValues
        .map((propertyValue) => {
          return propertyValuesMapper.mapNetworkToDomain({
            value: propertyValue
          })
        })
    }
  }

  mapDomainToNetwork({
    productsSetProperty
  }: {
    readonly productsSetProperty: ProductsSetProperty
  }): NetworkProductsSetProperty {
    return new NetworkProductsSetProperty({
      propertyId: productsSetProperty.propertyId,
      propertyValueIds: productsSetProperty.propertyValueIds,
      clientId: productsSetProperty.clientId
    })
  }
}
