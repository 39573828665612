import IsSessionExistUseCase from "../../domain/use-cases/sessions/IsSessionExistUseCase"
import DiModule from "../../../lib/di/DiModule"
import CoreDataDiModule from "./CoreDataDiModule"
import CreateSessionUseCase from "../../domain/use-cases/sessions/CreateSessionUseCase"
import DestroySessionUseCase from "../../domain/use-cases/sessions/DestroySessionUseCase"

export default interface CoreDomainDiModule {
  provideIsSessionExistUseCase(): IsSessionExistUseCase

  provideCreateSessionUseCase(): CreateSessionUseCase

  provideDestroySessionUseCase(): DestroySessionUseCase
}

export class DefaultCoreDomainDiModule extends DiModule implements CoreDomainDiModule {
  private readonly coreDataDiModule: CoreDataDiModule

  constructor(parameters: {
    readonly coreDataDiModule: CoreDataDiModule
  }) {
    super()
    this.coreDataDiModule = parameters.coreDataDiModule
  }

  provideIsSessionExistUseCase(): IsSessionExistUseCase {
    return this.single(
      IsSessionExistUseCase.name,
      () => new IsSessionExistUseCase({
        sessionsRepository: this.coreDataDiModule.provideSessionsRepository()
      })
    )
  }

  provideCreateSessionUseCase(): CreateSessionUseCase {
    return this.single(
      CreateSessionUseCase.name,
      () => new CreateSessionUseCase({
        sessionsRepository: this.coreDataDiModule.provideSessionsRepository()
      })
    )
  }

  provideDestroySessionUseCase(): DestroySessionUseCase {
    return this.single(
      DestroySessionUseCase.name,
      () => new DestroySessionUseCase({
        sessionsRepository: this.coreDataDiModule.provideSessionsRepository()
      })
    )
  }
}
