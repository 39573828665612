import { Expose, Transform, Type } from "class-transformer"
import NetworkOrderComment from "../order-comments/NetworkOrderComment"
import NetworkOrderProcessingStatus from "../order-processing-statuses/NetworkOrderProcessingStatus"
import NetworkOrderReceiving from "../order-receivings/NetworkOrderReceiving"
import NetworkOrderPayment from "../order-payments/NetworkOrderPayment"
import NetworkUser from "../users/NetworkUser"
import NetworkOrderItem from "../order-items/NetworkOrderItem"
import transformDate from "../../../../../sqadmin/lib/transformDate"
import OrderSourceType from "../../../domain/entities/orders/OrderSourceType"

export default class NetworkOrder {
  @Expose()
  readonly id: number | null | undefined

  @Expose()
  readonly number: string | null | undefined

  @Expose()
  readonly externalCode: string | null | undefined

  @Expose()
  @Type(() => NetworkOrderComment)
  readonly orderComment: NetworkOrderComment | null | undefined

  @Expose()
  @Type(() => NetworkOrderProcessingStatus)
  readonly processingStatus: NetworkOrderProcessingStatus | null | undefined

  @Expose()
  @Transform(transformDate)
  readonly orderedAt: Date | null | undefined

  @Expose()
  @Type(() => NetworkOrderReceiving)
  readonly orderReceiving: NetworkOrderReceiving | null | undefined

  @Expose()
  @Type(() => NetworkOrderPayment)
  readonly orderPayment: NetworkOrderPayment | null | undefined

  @Expose()
  @Type(() => NetworkUser)
  readonly user: NetworkUser | null | undefined

  @Expose()
  @Type(() => NetworkOrderItem)
  readonly items: NetworkOrderItem[] | null | undefined

  @Expose()
  readonly processingStatusId: number | null | undefined

  @Expose()
  readonly sourceType: OrderSourceType | null | undefined

  constructor(parameters?: {
    readonly id: number | null | undefined
    readonly externalCode: string | null | undefined
    readonly number: string | null | undefined,
    readonly orderComment: NetworkOrderComment | null | undefined,
    readonly processingStatus: NetworkOrderProcessingStatus | null | undefined
    readonly orderedAt: Date | null | undefined
    readonly orderReceiving: NetworkOrderReceiving | null | undefined
    readonly orderPayment: NetworkOrderPayment | null | undefined
    readonly user: NetworkUser | null | undefined
    readonly items: NetworkOrderItem[] | null | undefined
    readonly processingStatusId: number | null | undefined
    readonly sourceType: OrderSourceType | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.id = parameters.id
    this.externalCode = parameters.externalCode
    this.number = parameters.number
    this.orderComment = parameters.orderComment
    this.processingStatus = parameters.processingStatus
    this.orderedAt = parameters.orderedAt
    this.orderReceiving = parameters.orderReceiving
    this.orderPayment = parameters.orderPayment
    this.user = parameters.user
    this.items = parameters.items
    this.processingStatusId = parameters.processingStatusId
    this.sourceType = parameters.sourceType
  }
}
