import { Expose, Type } from "class-transformer"
import NetworkImage from "../images/NetworkImage"
import NetworkLink from "../links/NetworkLink"
import NetworkProductsSet from "../products-sets/NetworkProductsSet"
import BannerDataType from "../../../domain/entities/banners/BannerDataType"
import NetworkDashboard from "../dashboards/NetworkDashboard"

export default class NetworkBanner {
  @Expose()
  readonly id: number | null | undefined

  @Expose()
  readonly imageId: number | null | undefined

  @Expose()
  readonly position: number | null | undefined

  @Expose()
  readonly clientId: string | null | undefined

  @Expose()
  @Type(() => NetworkImage)
  readonly image: NetworkImage | null | undefined

  @Expose()
  @Type(() => NetworkLink)
  readonly link: NetworkLink | null | undefined

  @Expose()
  @Type(() => NetworkProductsSet)
  readonly productsSet: NetworkProductsSet | null | undefined

  @Expose()
  readonly dataType: BannerDataType | null | undefined

  @Expose()
  @Type(() => NetworkDashboard)
  readonly dashboard: NetworkDashboard | null | undefined

  @Expose()
  readonly dashboardId: number | null | undefined

  constructor(parameters?: {
    readonly id: number | null | undefined
    readonly imageId: number | null | undefined
    readonly position: number | null | undefined
    readonly clientId: string | null | undefined
    readonly image: NetworkImage | null | undefined
    readonly link: NetworkLink | null | undefined
    readonly productsSet: NetworkProductsSet | null | undefined
    readonly dataType: BannerDataType | null | undefined
    readonly dashboard: NetworkDashboard | null | undefined
    readonly dashboardId: number | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.id = parameters.id
    this.imageId = parameters.imageId
    this.position = parameters.position
    this.clientId = parameters.clientId
    this.image = parameters.image
    this.link = parameters.link
    this.productsSet = parameters.productsSet
    this.dataType = parameters.dataType
    this.dashboard = parameters.dashboard
    this.dashboardId = parameters.dashboardId
  }
}
