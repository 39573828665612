import { Expose, Type } from "class-transformer"
import OrderShippingMethodErrorsObject
  from "../../../domain/entities/order-shipping-methods/OrderShippingMethodErrorsObject"
import NetworkOrderShippingMethodErrorsObjectAttributes from "./NetworkOrderShippingMethodErrorsObjectAttributes"

export default class NetworkOrderShippingMethodErrorsObject implements OrderShippingMethodErrorsObject {
  @Expose()
  @Type(() => NetworkOrderShippingMethodErrorsObjectAttributes)
  readonly attributes: NetworkOrderShippingMethodErrorsObjectAttributes | null | undefined
}
