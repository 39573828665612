import { Expose } from "class-transformer"
import BonusPointBurningRulePeriodType
  from "../../../domain/entities/bonus-points-burning-rules/BonusPointBurningRulePeriodType"

export default class NetworkBonusPointsBurningRule {
  @Expose()
  readonly minEarnedSum: number | null | undefined

  @Expose()
  readonly periodType: BonusPointBurningRulePeriodType | null | undefined

  constructor(parameters?: {
    readonly minEarnedSum: number | null | undefined
    readonly periodType: BonusPointBurningRulePeriodType | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.minEarnedSum = parameters.minEarnedSum
    this.periodType = parameters.periodType
  }
}
