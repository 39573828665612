import { Expose, Transform } from "class-transformer"
import ApiLogItemServiceType from "../../../../core/domain/entities/api-log-items/ApiLogItemServiceType"
import ApiLogItemDirection from "../../../../core/domain/entities/api-log-items/ApiLogItemDirection"
import transformDate from "../../../../../sqadmin/lib/transformDate"
import ApiLogItemRequestType from "../../../../core/domain/entities/api-log-items/ApiLogItemRequestType"
import ApiLogItemResultType from "../../../../core/domain/entities/api-log-items/ApiLogItemResultType"

export default class NetworkApiLogItemsRequestFilter {
  @Expose()
  readonly query: string | null | undefined

  @Expose()
  readonly serviceType: ApiLogItemServiceType | null | undefined

  @Expose()
  readonly direction: ApiLogItemDirection | null | undefined

  @Expose()
  @Transform(transformDate)
  readonly startedAtFrom: Date | null | undefined

  @Expose()
  @Transform(transformDate)
  readonly startedAtTo: Date | null | undefined

  @Expose()
  readonly requestType: ApiLogItemRequestType | null | undefined

  @Expose()
  readonly httpStatus: number | null | undefined

  @Expose()
  readonly resultType: ApiLogItemResultType | null | undefined

  constructor(parameters: {
    readonly query: string | null | undefined
    readonly serviceType: ApiLogItemServiceType | null | undefined
    readonly direction: ApiLogItemDirection | null | undefined
    readonly startedAtFrom: Date | null | undefined
    readonly startedAtTo: Date | null | undefined
    readonly requestType: ApiLogItemRequestType | null | undefined
    readonly httpStatus: number | null | undefined
    readonly resultType: ApiLogItemResultType | null | undefined
  }) {
    this.query = parameters.query
    this.serviceType = parameters.serviceType
    this.direction = parameters.direction
    this.startedAtFrom = parameters.startedAtFrom
    this.startedAtTo = parameters.startedAtTo
    this.requestType = parameters.requestType
    this.httpStatus = parameters.httpStatus
    this.resultType = parameters.resultType
  }
}
