import DiModule from "../../../../../sqadmin/lib/di/DiModule"
import CoreDataDiModule from "../../../../core/di/modules/CoreDataDiModule"
import DashboardsRepository from "../../domain/repositories/DashboardsRepository"
import DefaultDashboardsRepository from "../../data/DefaultDashboardsRepository"
import BannerImagesRepository from "../../domain/repositories/BannerImagesRepository"
import DefaultBannerImagesRepository from "../../data/DefaultBannerImagesRepository"

export default interface DashboardsDataDiModule {
  provideDashboardsRepository(): DashboardsRepository

  provideBannerImagesRepository(): BannerImagesRepository
}

export class DefaultDashboardsDataDiModule
  extends DiModule
  implements DashboardsDataDiModule {

  private readonly coreDataDiModule: CoreDataDiModule

  constructor(parameters: {
    readonly coreDataDiModule: CoreDataDiModule
  }) {
    super()
    this.coreDataDiModule = parameters.coreDataDiModule
  }

  provideBannerImagesRepository(): BannerImagesRepository {
    return this.single(
      DefaultBannerImagesRepository.name,
      () => new DefaultBannerImagesRepository({
        bannerImagesNetworkSource: this.coreDataDiModule.provideBannerImagesNetworkSource()
      })
    )
  }

  provideDashboardsRepository(): DashboardsRepository {
    return this.single(
      DefaultDashboardsRepository.name,
      () => new DefaultDashboardsRepository({
        dashboardsNetworkSource: this.coreDataDiModule.provideDashboardsNetworkSource()
      })
    )
  }
}
