import BackendHttpClient from "../../data/network/BackendHttpClient"
import DiModule from "../../../../sqadmin/lib/di/DiModule"
import HttpClient from "../../../lib/http-client/HttpClient"
import CoreLocalDiModule from "../../../../sqadmin/core/di/modules/CoreLocalDiModule"

export default interface CoreNetworkDiModule {
  provideBackendHttpClient(): BackendHttpClient
}

export class DefaultCoreNetworkDiModule
  extends DiModule
  implements CoreNetworkDiModule {

  private readonly backendBaseUrl: string
  private readonly coreLocalDiModule: CoreLocalDiModule

  constructor(parameters: {
    readonly backendBaseUrl: string
    readonly coreLocalDiModule: CoreLocalDiModule
  }) {
    super()
    this.backendBaseUrl = parameters.backendBaseUrl
    this.coreLocalDiModule = parameters.coreLocalDiModule
  }

  provideBackendHttpClient(): BackendHttpClient {
    return this.single(
      BackendHttpClient.name,
      () => new BackendHttpClient({
        sessionLocalSource: this.coreLocalDiModule.provideSessionLocalSource(),
        httpClient: new HttpClient({
          baseUrl: this.backendBaseUrl
        })
      })
    )
  }
}
