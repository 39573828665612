import { RouteObject } from "react-router-dom"
import { objectIdUrlPart } from "../../../../sqadmin/features/objects/presentation/hooks/useObjectId"
import CoreUrlProvider from "../../../core/presentation/services/CoreUrlProvider"
import PlacesDi from "../di/PlacesDi"

export default function createPlacesRoutes({
  di
}: {
  readonly di: PlacesDi
}): RouteObject[] {
  const urlProvider = new CoreUrlProvider()
  return [
    {
      path: urlProvider.buildPlacesPath(),
      element: di
        .sqCorePlacesDiComponent
        .placesPresentationDiModule
        .providePlacesPage()
    },
    {
      path: urlProvider.buildNewPlacePath(),
      element: di
        .sqCorePlacesDiComponent
        .placesPresentationDiModule
        .providePlacePage()
    },
    {
      path: urlProvider.buildPlacePath({ id: `:${objectIdUrlPart}` }),
      element: di
        .sqCorePlacesDiComponent
        .placesPresentationDiModule
        .providePlacePage()
    }
  ]
}
