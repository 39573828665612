import BonusProgramRuleErrorsObjectAttributes
  from "../../../domain/entities/bonus-program-rules/BonusProgramRuleErrorsObjectAttributes"
import AttributeError from "../../../../../sqadmin/core/domain/entities/AttributeError"
import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../errors/NetworkAttributeError"

export default class NetworkBonusProgramRuleErrorsObjectAttributes implements BonusProgramRuleErrorsObjectAttributes {
  @Expose()
  @Type(() => NetworkAttributeError)
  readonly bonusPointsRules: AttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly id: AttributeError[] | null | undefined
}
