import { Expose } from "class-transformer"

export default class NetworkPaymentProvider {

  @Expose()
  readonly type: string | null | undefined

  @Expose()
  readonly name: string | null | undefined

  constructor(parameters?: {
    readonly type: string | null | undefined
    readonly name: string | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.type = parameters.type
    this.name = parameters.name
  }
}
