import { RouteObject } from "react-router-dom"
import { objectIdUrlPart } from "../../../../sqadmin/features/objects/presentation/hooks/useObjectId"
import CoreUrlProvider from "../../../core/presentation/services/CoreUrlProvider"
import OptionsDi from "../di/OptionsDi"

export default function createOptionsRoutes({
  di
}: {
  readonly di: OptionsDi
}): RouteObject[] {
  const urlProvider = new CoreUrlProvider()
  return [
    {
      path: urlProvider.buildOptionsPath(),
      element: di
        .sqCoreOptionsDiComponent
        .optionsPresentationDiModule
        .provideOptionsPage()
    },
    {
      path: urlProvider.buildNewOptionPath(),
      element: di
        .sqCoreOptionsDiComponent
        .optionsPresentationDiModule
        .provideOptionPage()
    },
    {
      path: urlProvider.buildOptionPath({ id: `:${objectIdUrlPart}` }),
      element: di
        .sqCoreOptionsDiComponent
        .optionsPresentationDiModule
        .provideOptionPage()
    }
  ]
}
