import DiModule from "../../../../sqadmin/lib/di/DiModule"
import OrderReceivingMethodsCoreDataDiModule from "./OrderReceivingMethodsCoreDataDiModule"
import UpdateOrderReceivingMethodUseCase from "../domain/use-cases/UpdateOrderReceivingMethodUseCase"
import GetOrderReceivingMethodUseCase from "../domain/use-cases/GetOrderReceivingMethodUseCase"
import GetOrderReceivingMethodsUseCase from "../domain/use-cases/GetOrderReceivingMethodsUseCase"
import DestroyOrderReceivingMethodUseCase from "../domain/use-cases/DestroyOrderReceivingMethodUseCase"
import CreateOrderReceivingMethodUseCase from "../domain/use-cases/CreateOrderReceivingMethodUseCase"
import GetOrderShippingTariffsUseCase from "../domain/use-cases/GetOrderShippingTariffsUseCase"

export default interface OrderReceivingMethodsCoreDomainDiModule {
  provideCreateOrderReceivingMethodUseCase(): CreateOrderReceivingMethodUseCase

  provideDestroyOrderReceivingMethodUseCase(): DestroyOrderReceivingMethodUseCase

  provideGetOrderReceivingMethodsUseCase(): GetOrderReceivingMethodsUseCase

  provideGetOrderReceivingMethodUseCase(): GetOrderReceivingMethodUseCase

  provideUpdateOrderReceivingMethodUseCase(): UpdateOrderReceivingMethodUseCase

  provideGetOrderShippingTariffsUseCase(): GetOrderShippingTariffsUseCase
}

export class DefaultOrderReceivingMethodsCoreDomainDiModule extends DiModule
  implements OrderReceivingMethodsCoreDomainDiModule {
  private readonly orderReceivingMethodsCoreDataDiModule: OrderReceivingMethodsCoreDataDiModule

  constructor(parameters: {
    readonly orderReceivingMethodsCoreDataDiModule: OrderReceivingMethodsCoreDataDiModule
  }) {
    super()
    this.orderReceivingMethodsCoreDataDiModule = parameters.orderReceivingMethodsCoreDataDiModule
  }

  provideCreateOrderReceivingMethodUseCase(): CreateOrderReceivingMethodUseCase {
    return this.single(
      CreateOrderReceivingMethodUseCase.name,
      () => new CreateOrderReceivingMethodUseCase({
        orderReceivingMethodsRepository: this.orderReceivingMethodsCoreDataDiModule
          .provideOrderReceivingMethodsRepository()
      })
    )
  }

  provideDestroyOrderReceivingMethodUseCase(): DestroyOrderReceivingMethodUseCase {
    return this.single(
      DestroyOrderReceivingMethodUseCase.name,
      () => new DestroyOrderReceivingMethodUseCase({
        orderReceivingMethodsRepository: this.orderReceivingMethodsCoreDataDiModule
          .provideOrderReceivingMethodsRepository()
      })
    )
  }

  provideGetOrderReceivingMethodsUseCase(): GetOrderReceivingMethodsUseCase {
    return this.single(
      GetOrderReceivingMethodsUseCase.name,
      () => new GetOrderReceivingMethodsUseCase({
        orderReceivingMethodsRepository: this.orderReceivingMethodsCoreDataDiModule
          .provideOrderReceivingMethodsRepository()
      })
    )
  }

  provideGetOrderReceivingMethodUseCase(): GetOrderReceivingMethodUseCase {
    return this.single(
      GetOrderReceivingMethodUseCase.name,
      () => new GetOrderReceivingMethodUseCase({
        orderReceivingMethodsRepository: this.orderReceivingMethodsCoreDataDiModule
          .provideOrderReceivingMethodsRepository()
      })
    )
  }

  provideUpdateOrderReceivingMethodUseCase(): UpdateOrderReceivingMethodUseCase {
    return this.single(
      UpdateOrderReceivingMethodUseCase.name,
      () => new UpdateOrderReceivingMethodUseCase({
        orderReceivingMethodsRepository: this.orderReceivingMethodsCoreDataDiModule
          .provideOrderReceivingMethodsRepository()
      })
    )
  }

  provideGetOrderShippingTariffsUseCase(): GetOrderShippingTariffsUseCase {
    return this.single(
      GetOrderShippingTariffsUseCase.name,
      () => new GetOrderShippingTariffsUseCase({
        orderReceivingMethodsRepository: this.orderReceivingMethodsCoreDataDiModule
          .provideOrderReceivingMethodsRepository()
      })
    )
  }
}
