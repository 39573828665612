import ConfigurationEntityProperty
  from "../../../../../../core/domain/entities/configurations/ConfigurationEntityProperty"
import ConfigurationEntity from "../../../../../../core/domain/entities/configurations/ConfigurationEntity"
import Configuration from "../../../../../../core/domain/entities/configurations/Configuration"
import isBlank from "../../../../../../../sqadmin/lib/isBlank"

export class ObjectEditor {
  private properties: ConfigurationEntityProperty[] | null | undefined
  private entity: ConfigurationEntity | null | undefined

  init(configuration: Configuration | null | undefined, entityName: string) {
    this.entity = configuration?.entities
      ?.find((entity: ConfigurationEntity) => entity.name === entityName)
    this.properties = this.entity?.properties ?? []
  }

  isDisabled(id: number | null | undefined, propertyName: string) {
    if (this.isNewObject(id)) return false

    return !(this.canUpdate() && this.canSaveOnUpdateProperty(propertyName))
  }

  canCreate() {
    return this.entity?.policy?.canCreate ?? true
  }

  canDestroy(id: number | null | undefined) {
    return this.isNewObject(id) ? true : this.entity?.policy?.canDestroy ?? true
  }

  private isNewObject(id: number | null | undefined) {
    return isBlank(id)
  }

  private canUpdate() {
    return this.entity?.policy?.canUpdate ?? true
  }

  private canSaveOnUpdateProperty(propertyName: string) {
    return this.properties
      ?.find((property: ConfigurationEntityProperty) => property.name === propertyName)
      ?.policy?.canSaveOnUpdate ?? true
  }
}
