import { LoadObjectResult } from "../../../../../../sqadmin/features/objects/domain/results/LoadObjectResult"
import OrdersRepository from "../../repositories/OrdersRepository"
import Order from "../../../../../core/domain/entities/orders/Order"

export default class GetOrderUseCase {
  private readonly ordersRepository: OrdersRepository

  constructor(parameters: {
    readonly ordersRepository: OrdersRepository
  }) {
    this.ordersRepository = parameters.ordersRepository
  }

  async call(parameters: {
    readonly orderId: number
  }): Promise<LoadObjectResult<Order>> {
    return await this.ordersRepository.getOrder(parameters)
  }
}
