import NetworkCreateSessionErrorsObjectAttributes
  from "../../entities/network/sessions/NetworkCreateSessionErrorsObjectAttributes"
import CreateSessionErrorsObjectAttributes from "../../../domain/entities/CreateSessionErrorsObjectAttributes"
import AttributeError from "../../../domain/entities/AttributeError"
import AttributeErrorsMapper from "../errors/AttributeErrorsMapper"

export default class CreateSessionErrorsObjectAttributesMapper {
  mapNetworkToDomain({
    object
  }: {
    readonly object: NetworkCreateSessionErrorsObjectAttributes
  }): CreateSessionErrorsObjectAttributes {
    return {
      password: object.password && object.password.map((attributeError: AttributeError) => {
        return new AttributeErrorsMapper().mapNetworkToDomain({
          object: attributeError
        })
      }),
      emailAddress: object.emailAddress && object.emailAddress.map((attributeError: AttributeError) => {
        return new AttributeErrorsMapper().mapNetworkToDomain({
          object: attributeError
        })
      })
    }
  }
}
