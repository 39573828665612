import NetworkCountry from "../entities/countries/NetworkCountry"
import Country from "../../domain/entities/countries/Country"

export default class CountriesMapper {
  mapNetworkToDomain({
    country
  }: {
    readonly country: NetworkCountry
  }): Country {
    return {
      id: country.id,
      name: country.name,
      callingCode: country.callingCode,
      phoneNumberFormattingMask: country.phoneNumberFormattingMask
    }
  }

  mapDomainToNetwork({
    country
  }: {
    readonly country: Country
  }): NetworkCountry {
    return new NetworkCountry({
      id: country.id,
      name: country.name,
      callingCode: country.callingCode,
      phoneNumberFormattingMask: country.phoneNumberFormattingMask
    })
  }
}
