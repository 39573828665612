import ProductCategoryImagesRepository from "../../repositories/ProductCategoryImagesRepository"
import { CreateObjectResult } from "../../../../../../sqadmin/features/objects/domain/results/CreateObjectResult"
import Image from "../../../../../core/domain/entities/images/Image"
import ImageError from "../../../../../core/domain/entities/images/ImageError"

export default class CreateProductCategoryImageUseCase {
  private readonly productCategoryImagesRepository: ProductCategoryImagesRepository

  constructor(parameters: {
    readonly productCategoryImagesRepository: ProductCategoryImagesRepository
  }) {
    this.productCategoryImagesRepository = parameters.productCategoryImagesRepository
  }

  async call({ file }: { readonly file: File }): Promise<CreateObjectResult<Image, ImageError>> {
    return await this.productCategoryImagesRepository.createImage({ file })
  }
}
