import NetworkBonusProgramRule from "../entities/bonus-program-rules/NetworkBonusProgramRule"
import BonusProgramRule from "../../domain/entities/bonus-program-rules/BonusProgramRule"
import BonusPointsRulesMapper from "./BonusPointsRulesMapper"

export default class BonusProgramRulesMapper {
  mapNetworkToDomain({
    bonusProgramRule
  }: {
    readonly bonusProgramRule: NetworkBonusProgramRule
  }): BonusProgramRule {
    return {
      id: bonusProgramRule.id,
      bonusPointsRules: bonusProgramRule.bonusPointsRules && bonusProgramRule.bonusPointsRules
        .map((bonusPointsRule) => {
          return new BonusPointsRulesMapper().mapNetworkToDomain({ bonusPointsRule })
        })
    }
  }

  mapDomainToNetwork({
    bonusProgramRule
  }: {
    readonly bonusProgramRule: BonusProgramRule
  }): NetworkBonusProgramRule {
    return new NetworkBonusProgramRule({
      id: bonusProgramRule.id,
      bonusPointsRules: bonusProgramRule.bonusPointsRules && bonusProgramRule.bonusPointsRules
        .map((bonusPointsRule) => {
          return new BonusPointsRulesMapper().mapDomainToNetwork({ bonusPointsRule })
        })
    })
  }
}
