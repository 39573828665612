import { Expose, Type } from "class-transformer"
import NetworkPage from "../../../../core/data/entities/pagination/NetworkPage"
import NetworkUser from "../../../../core/data/entities/users/NetworkUser"

export default class NetworkUsersResponseBody {
  @Expose()
  @Type(() => NetworkUser)
  readonly users: NetworkUser[] | null | undefined

  @Expose()
  @Type(() => NetworkPage)
  readonly page: NetworkPage | null | undefined
}
