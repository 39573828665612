import { Expose, Type } from "class-transformer"
import OrderReceivingPriceRuleConditionErrorsObject
  from "../../../domain/entities/order-receiving-price-rule-conditions/OrderReceivingPriceRuleConditionErrorsObject"
import NetworkOrderReceivingPriceRuleConditionErrorsObjectAttributes
  from "./NetworkOrderReceivingPriceRuleConditionErrorsObjectAttributes"

export default class NetworkOrderReceivingPriceRuleConditionErrorsObject
  implements OrderReceivingPriceRuleConditionErrorsObject {
  @Expose()
  @Type(() => NetworkOrderReceivingPriceRuleConditionErrorsObjectAttributes)
  readonly attributes: NetworkOrderReceivingPriceRuleConditionErrorsObjectAttributes | null | undefined

  @Expose()
  readonly clientId: string | null | undefined
}
