import NetworkPropertyValuesRequestFilter from "../entities/NetworkPropertyValuesRequestFilter"

export default class PropertyValuesFiltersMapper {
  mapDomainToNetwork({
    query
  }: {
    readonly query: string | undefined | null
  }): NetworkPropertyValuesRequestFilter {
    return {
      query: query
    }
  }
}
