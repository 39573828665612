import React, { useRef } from "react"
import SelectPickerComponent, { SelectPickerComponentProps } from "../select-picker/SelectPickerComponent"
import CoreTextProvider from "../../../i18n/CoreTextProvider"
import { useCoreTextProvider } from "../../contexts/CoreTextProviderContext"
import SearchBarComponent, { SearchBarComponentRef } from "../search-bar/SearchBarComponent"
import styles from "./AppSelectPickerComponent.module.scss"
import Page from "../../../domain/entities/Page"
import isPresent from "../../../../lib/isPresent"
import PaginationComponent from "../../../../features/objects/presentation/components/pagination/PaginationComponent"
import PickerNoDataComponent from "../picker-no-data/PickerNoDataComponent"
import isBlank from "../../../../lib/isBlank"
import PickerOptionsErrorComponent from "../picker-options-error/PickerOptionsErrorComponent"

export default function AppSelectPickerComponent(props: SelectPickerComponentProps & {
  readonly query?: string | null
  readonly page?: Page
  readonly onQueryChanged: (query: string) => void
  readonly onSearchRequested: () => void
  readonly onNextPageRequested: () => void
  readonly isSearchBarVisible: boolean
  readonly isClearButtonVisible: boolean
  readonly optionsErrorMessage: string | undefined | null
}) {
  const searchBarRef: React.RefObject<SearchBarComponentRef> = useRef<SearchBarComponentRef>(null)
  const coreTextProvider: CoreTextProvider = useCoreTextProvider()

  function focusOnSearchBarInput() {
    searchBarRef.current?.focus()
  }

  function handleOnOpened() {
    focusOnSearchBarInput()
    props.onOpened && props.onOpened()
  }

  function requestNextPage() {
    if (!props.isLoading && isPresent(props.page) && props.page.hasMore) {
      props.onNextPageRequested()
    }
  }

  return (
    <SelectPickerComponent
      {...props}
      clearable={props.isClearButtonVisible}
      selectButtonText={props.selectButtonText ?? coreTextProvider.select()}
      onOpened={handleOnOpened}
      hasError={props.hasError}
      aboveOptionsElement={
        <>
          {props.isSearchBarVisible && (
            <div className={styles.searchBar}>
              <SearchBarComponent
                ref={searchBarRef}
                query={props.query ?? ""}
                onQueryChanged={props.onQueryChanged}
                onSearchRequested={props.onSearchRequested}
              />
            </div>
          )}

          {!props.isLoading && props.options.length === 0 && isBlank(props.optionsErrorMessage) && (
            <PickerNoDataComponent />
          )}

          {!props.isLoading && isPresent(props.optionsErrorMessage) && (
            <PickerOptionsErrorComponent
              errorMessage={props.optionsErrorMessage}
            />
          )}
        </>
      }
      belowOptionsElement={
        <>
          <PaginationComponent onComponentAppeared={requestNextPage} />
        </>
      }
    />
  )
}
