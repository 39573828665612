import { RootViewState } from "./RootViewState"
import UiMenuItem from "../../../../../core/presentation/entities/UiMenuItem"
import IsSessionExistUseCase from "../../../../../core/domain/use-cases/sessions/IsSessionExistUseCase"
import DestroySessionUseCase from "../../../../../core/domain/use-cases/sessions/DestroySessionUseCase"
import ViewModel from "../../../../../lib/view-model/ViewModel"
import autoBind from "auto-bind"
import { StateObservable } from "../../../../../lib/view-model/StateObservable"

export default class RootViewModel extends ViewModel {
  private readonly isSessionExistUseCase: IsSessionExistUseCase
  private readonly destroySessionUseCase: DestroySessionUseCase
  private readonly menuItems: UiMenuItem[]
  rootStateObservable = new StateObservable<RootViewState>({ type: "initial" })

  constructor(parameters: {
    readonly isSessionExistUseCase: IsSessionExistUseCase
    readonly destroySessionUseCase: DestroySessionUseCase
    readonly menuItems: UiMenuItem[]
  }) {
    super()

    autoBind(this)

    this.isSessionExistUseCase = parameters.isSessionExistUseCase
    this.destroySessionUseCase = parameters.destroySessionUseCase

    this.menuItems = parameters.menuItems

    this.checkIsSessionExistAndSetRootViewState()
  }

  onLogoutClicked() {
    this.destroySessionAndSetNotAuthenticatedRootViewState()
  }

  private checkIsSessionExistAndSetRootViewState() {
    const isSessionExist: boolean = this.isSessionExistUseCase.call()

    if (isSessionExist) {
      this.setAuthenticatedRootViewState()
    } else {
      this.setNotAuthenticatedRootViewState()
    }
  }

  private destroySessionAndSetNotAuthenticatedRootViewState() {
    this.destroySessionUseCase.call()
    this.setNotAuthenticatedRootViewState()
  }

  private setAuthenticatedRootViewState() {
    this.rootStateObservable.setValue({
      type: "authenticated",
      menuItems: this.menuItems
    })
  }

  private setNotAuthenticatedRootViewState() {
    this.rootStateObservable.setValue({ type: "not_authenticated" })
  }
}
