enum ConfigurationEntityPropertyName {
  NAME = "name",
  EXTERNAL_CODE = "externalCode",
  DESCRIPTION = "description",
  HAS_BATCHES = "hasBatches",
  CAN_BE_ORDERED_WITH_BONUS_POINTS = "canBeOrderedWithBonusPoints",
  IS_ARCHIVED = "isArchived",
  INPUT_MULTIPLIER = "inputMultiplier",
  CATEGORY_ID = "categoryId",
  OPTION_IDS = "optionIds",
  PROPERTY_ID = "propertyId",
  PROPERTY_VALUE_ID = "propertyValueId",
  IMAGE_ID = "imageId",
  IMAGE_EXTERNAL_CODE = "imageExternalCode",
  IS_MAIN = "isMain",
  OPTION_VALUE_ID = "optionValueId",
  PRICE = "price",
  ORIGINAL_PRICE = "originalPrice",
  BONUS_POINTS_PRICE = "bonusPointsPrice",
  PLACE_ID = "placeId",
  IS_AVAILABLE = "isAvailable"
}

export default ConfigurationEntityPropertyName
