import React from "react"
import styles from "./TableRowLoadingComponent.module.scss"
import LoadingIndicatorComponent from "../../../../design/loading-indicator/LoadingIndicatorComponent"

export default function TableRowLoadingComponent({
  columnsCount
}: {
  readonly columnsCount: number
}) {
  return (
    <tr className={styles.loadingIndicatorRow}>
      <td colSpan={columnsCount}>
        <LoadingIndicatorComponent />
      </td>
    </tr>
  )
}
