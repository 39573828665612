import isPresent from "../isPresent"
import isBlank from "../isBlank"
import ExtendedDate from "../extended-date/ExtendedDate"

export interface DateRange {
  readonly dateFrom: Date
  readonly dateTo: Date
}

export function isDateRangesEquals(dateRangeOne: DateRange | null, dateRangeTwo: DateRange | null): boolean {
  return isBlank(dateRangeOne) && isBlank(dateRangeTwo) ||
    isPresent(dateRangeOne) && isPresent(dateRangeTwo) &&
    dateRangeOne.dateFrom.getTime() === dateRangeTwo.dateFrom.getTime() &&
    dateRangeOne.dateTo.getTime() === dateRangeTwo.dateTo.getTime()
}

export function getHoursFromDateRange(dateRange: DateRange): Date[] {
  const hours: Date[] = []

  for (
    let hour: Date = dateRange.dateFrom;
    hour.getTime() <= dateRange.dateTo.getTime();
    hour = new ExtendedDate(hour).getNextHour()
  ) {
    hours.push(hour)
  }

  return hours
}

export function correctDateRange({
  dateFrom,
  dateTo
}: Partial<DateRange>): Partial<DateRange> {
  if (isBlank(dateFrom) && isBlank(dateTo)) {
    return {
      dateFrom,
      dateTo
    }
  }

  if (isPresent(dateFrom) && isBlank(dateTo)) {
    return {
      dateFrom,
      dateTo
    }
  }

  if (isBlank(dateFrom) && isPresent(dateTo)) {
    return {
      dateFrom: dateTo,
      dateTo: dateFrom
    }
  }

  if (isPresent(dateFrom) && isPresent(dateTo)) {
    if (dateFrom.getTime() < dateTo.getTime()) {
      return {
        dateFrom,
        dateTo
      }
    } else {
      return {
        dateFrom: dateTo,
        dateTo: dateFrom
      }
    }
  }

  return {}
}
