import CreateObjectNetworkResult from "../../../../core/data/results/CreateObjectNetworkResult"
import Image from "../../../../core/domain/entities/images/Image"
import ExecutionError from "../../../../../sqadmin/core/domain/entities/ExecutionError"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import { plainToInstance } from "class-transformer"
import NetworkImageErrorObject from "../../../../core/data/entities/images/NetworkImageErrorObject"
import BackendHttpClient, { BackendHttpClientResult } from "../../../../core/data/network/BackendHttpClient"
import NetworkProductCategoryImageResponseBody
  from "../entities/NetworkProductCategoryImageResponseBody"

const basePath = "/admin/product_category_images"

export default class ProductCategoryImagesNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async createImage({
    file
  }: {
    readonly file: File
  }): Promise<CreateObjectNetworkResult<Image, ExecutionError>> {
    const formData = new FormData()
    formData.append("productCategoryImage[file]", file, file.name)

    const result: BackendHttpClientResult = await this.backendHttpClient.executeFormDataRequest({
      type: HttpRequestType.POST,
      path: basePath,
      formData: formData
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkProductCategoryImageResponseBody, result.body).productCategoryImage!
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkImageErrorObject, result.body)
        }
      case "failure":
        return result
    }
  }
}
