import { Expose, Type } from "class-transformer"
import BonusPointsBurningRuleErrorsObject
  from "../../../domain/entities/bonus-points-burning-rules/BonusPointsBurningRuleErrorsObject"
import NetworkBonusPointsBurningRuleErrorsObjectAttributes from "./NetworkBonusPointsBurningRuleErrorsObjectAttributes"

export default class NetworkBonusPointsBurningRuleErrorsObject implements BonusPointsBurningRuleErrorsObject {

  @Expose()
  @Type(() => NetworkBonusPointsBurningRuleErrorsObjectAttributes)
  readonly attributes: NetworkBonusPointsBurningRuleErrorsObjectAttributes | null | undefined
}
