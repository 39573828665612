import OptionsRepository from "../../repositories/OptionsRepository"
import { GetObjectsPageResult } from "../../../../../../sqadmin/features/objects/domain/results/GetObjectsPageResult"
import Option from "../../../../../core/domain/entities/options/Option"
import OptionsFilter from "../../../../../core/domain/entities/options/OptionsFilter"
import LastItemPagination from "../../../../../core/domain/entities/paginations/LastItemPagination"

export default class GetOptionsUseCase {
  private readonly optionsRepository: OptionsRepository

  constructor(parameters: {
    readonly optionsRepository: OptionsRepository
  }) {
    this.optionsRepository = parameters.optionsRepository
  }

  async call(parameters: GetOptionsParameters): Promise<GetObjectsPageResult<Option>> {
    return this.optionsRepository.getOptions(parameters)
  }
}

export interface GetOptionsParameters {
  readonly filter?: OptionsFilter
  readonly pagination?: LastItemPagination
}
