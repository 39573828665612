import { LoadObjectResult } from "../../../../../sqadmin/features/objects/domain/results/LoadObjectResult"
import GetObjectNetworkResult from "../../../../core/data/results/GetObjectNetworkResult"
import { CreateObjectResult } from "../../../../../sqadmin/features/objects/domain/results/CreateObjectResult"
import CreateObjectNetworkResult from "../../../../core/data/results/CreateObjectNetworkResult"
import BonusPointsBurningRulesRepository from "../../domain/repositories/BonusPointsBurningRulesRepository"
import BonusPointsBurningRuleNetworkSource from "../sources/BonusPointsBurningRulesNetworkSource"
import NetworkBonusPointsBurningRule
  from "../../../../core/data/entities/bonus-points-burning-rules/NetworkBonusPointsBurningRule"
import BonusPointsBurningRule from "../../../../core/domain/entities/bonus-points-burning-rules/BonusPointsBurningRule"
import NetworkBonusPointsBurningRuleError
  from "../../../../core/data/entities/bonus-points-burning-rules/NetworkBonusPointsBurningRuleError"
import BonusPointsBurningRuleError
  from "../../../../core/domain/entities/bonus-points-burning-rules/BonusPointsBurningRuleError"
import BonusPointsBurningRulesMapper from "../../../../core/data/mappers/BonusPointsBurningRulesMapper"

export default class DefaultBonusPointsBurningRulesRepository implements BonusPointsBurningRulesRepository {
  private readonly bonusPointsBurningRulesNetworkSource: BonusPointsBurningRuleNetworkSource

  constructor(parameters: {
    readonly bonusPointsBurningRulesNetworkSource: BonusPointsBurningRuleNetworkSource
  }) {
    this.bonusPointsBurningRulesNetworkSource = parameters.bonusPointsBurningRulesNetworkSource
  }

  async getBonusPointsBurningRule(): Promise<LoadObjectResult<BonusPointsBurningRule>> {
    const result: GetObjectNetworkResult<NetworkBonusPointsBurningRule> =
      await this.bonusPointsBurningRulesNetworkSource.getBonusPointsBurningRule()

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new BonusPointsBurningRulesMapper().mapNetworkToDomain({
            bonusPointsBurningRule: result.data
          })
        }
      default:
        return result
    }
  }

  async createOrUpdateBonusPointsBurningRule({
    bonusPointsBurningRule
  }: {
    readonly bonusPointsBurningRule: BonusPointsBurningRule
  }): Promise<CreateObjectResult<BonusPointsBurningRule, BonusPointsBurningRuleError>> {
    const result: CreateObjectNetworkResult<NetworkBonusPointsBurningRule, NetworkBonusPointsBurningRuleError> =
      await this.bonusPointsBurningRulesNetworkSource.createOrUpdateBonusPointsBurningRule({
        bonusPointsBurningRule: new BonusPointsBurningRulesMapper().mapDomainToNetwork({
          bonusPointsBurningRule
        })
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new BonusPointsBurningRulesMapper().mapNetworkToDomain({
            bonusPointsBurningRule: result.data
          })
        }
      default:
        return result
    }
  }
}
