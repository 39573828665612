import NetworkOrderReceiving from "../entities/order-receivings/NetworkOrderReceiving"
import OrderReceiving from "../../domain/entities/order-receivings/OrderReceiving"
import PlacesMapper from "./PlacesMapper"
import OrderReceivingMethodsMapper from "./OrderReceivingMethodsMapper"
import SettlementsMapper from "./SettlementsMapper"
import OrderReceivingAddressesMapper from "./OrderReceivingAddressesMapper"
import OrderReceivingRecipientsMappers from "./OrderReceivingRecipientsMappers"

export default class OrderReceivingsMapper {
  mapNetworkToDomain({
    orderReceiving
  }: {
    readonly orderReceiving: NetworkOrderReceiving
  }): OrderReceiving {
    return {
      id: orderReceiving.id,
      method: orderReceiving.method && new OrderReceivingMethodsMapper()
        .mapNetworkToDomain({ orderReceivingMethod: orderReceiving.method }),
      place: orderReceiving.place && new PlacesMapper()
        .mapNetworkToDomain({ place: orderReceiving.place }),
      settlement: orderReceiving.settlement && new SettlementsMapper()
        .mapNetworkToDomain({ settlement: orderReceiving.settlement }),
      address: orderReceiving.address && new OrderReceivingAddressesMapper()
        .mapNetworkToDomain({ orderReceivingAddress: orderReceiving.address }),
      recipient: orderReceiving.recipient && new OrderReceivingRecipientsMappers()
        .mapNetworkToDomain({ recipient: orderReceiving.recipient })
    }
  }

  mapDomainToNetwork({
    orderReceiving
  }: {
    readonly orderReceiving: OrderReceiving
  }): NetworkOrderReceiving {
    return new NetworkOrderReceiving({
      id: orderReceiving.id,
      method: orderReceiving.method && new OrderReceivingMethodsMapper()
        .mapDomainToNetwork({ orderReceivingMethod: orderReceiving.method }),
      place: orderReceiving.place && new PlacesMapper()
        .mapDomainToNetwork({ place: orderReceiving.place }),
      settlement: orderReceiving.settlement && new SettlementsMapper()
        .mapDomainToNetwork({ settlement: orderReceiving.settlement }),
      address: orderReceiving.address && new OrderReceivingAddressesMapper()
        .mapDomainToNetwork({ orderReceivingAddress: orderReceiving.address }),
      recipient: orderReceiving.recipient && new OrderReceivingRecipientsMappers()
        .mapDomainToNetwork({ recipient: orderReceiving.recipient })
    })
  }
}
