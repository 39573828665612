import ProductCategoriesRepository from "../../repositories/ProductCategoriesRepository"
import ProductCategory from "../../../../../core/domain/entities/product-categories/ProductCategory"
import { GetObjectsPageResult } from "../../../../../../sqadmin/features/objects/domain/results/GetObjectsPageResult"
import ProductCategoriesFilter from "../../../../../core/domain/entities/product-categories/ProductCategoriesFilter"
import Sort from "../../../../../../sqadmin/features/objects/presentation/entities/tables/Sort"
import LastItemPagination from "../../../../../core/domain/entities/paginations/LastItemPagination"

export default class GetProductCategoriesUseCase {
  private readonly productCategoriesRepository: ProductCategoriesRepository

  constructor(parameters: {
    readonly productCategoriesRepository: ProductCategoriesRepository
  }) {
    this.productCategoriesRepository = parameters.productCategoriesRepository
  }

  async call(parameters: GetProductCategoriesParameters): Promise<GetObjectsPageResult<ProductCategory>> {
    return this.productCategoriesRepository.getProductCategories(parameters)
  }
}

export interface GetProductCategoriesParameters {
  readonly filter?: ProductCategoriesFilter
  readonly sort?: Sort
  readonly pagination?: LastItemPagination
}
