import DiModule from "../../../../sqadmin/lib/di/DiModule"
import CoreNetworkDiModule from "../../../core/di/modules/CoreNetworkDiModule"
import DiscountRulesRepository from "../domain/repositories/DiscountRulesRepository"
import DefaultDiscountRulesRepository from "../data/repositories/DefaultDiscountRulesRepository"
import DiscountRulesNetworkSource from "../data/sources/DiscountRulesNetworkSource"

export default interface DiscountRulesCoreDataDiModule {
  provideDiscountRulesRepository(): DiscountRulesRepository
}

export class DefaultDiscountRulesCoreDataDiModule extends DiModule implements DiscountRulesCoreDataDiModule {
  private readonly sqCoreCoreNetworkDiModule: CoreNetworkDiModule

  constructor(parameters: {
    readonly sqCoreCoreNetworkDiModule: CoreNetworkDiModule
  }) {
    super()
    this.sqCoreCoreNetworkDiModule = parameters.sqCoreCoreNetworkDiModule
  }

  provideDiscountRulesRepository(): DiscountRulesRepository {
    return this.single(
      DefaultDiscountRulesRepository.name,
      () => new DefaultDiscountRulesRepository({
        discountRulesNetworkSource: this.provideDiscountRulesNetworkSource()
      })
    )
  }

  private provideDiscountRulesNetworkSource(): DiscountRulesNetworkSource {
    return this.single(
      DiscountRulesNetworkSource.name,
      () => new DiscountRulesNetworkSource({
        backendHttpClient: this.sqCoreCoreNetworkDiModule.provideBackendHttpClient()
      })
    )
  }
}
