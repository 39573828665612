import { LoadObjectResult } from "../../../../../sqadmin/features/objects/domain/results/LoadObjectResult"
import PaymentStatusesRepository from "../repositories/PaymentStatusesRepository"
import PaymentStatus from "../../../../core/domain/entities/payment-statuses/PaymentStatus"

export default class GetPaymentStatusUseCase {
  private readonly paymentStatusesRepository: PaymentStatusesRepository

  constructor(parameters: {
    readonly paymentStatusesRepository: PaymentStatusesRepository
  }) {
    this.paymentStatusesRepository = parameters.paymentStatusesRepository
  }

  async call(parameters: {
    readonly paymentStatusId: number
  }): Promise<LoadObjectResult<PaymentStatus>> {
    return await this.paymentStatusesRepository.getPaymentStatus(parameters)
  }
}
