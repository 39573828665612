import NetworkProduct from "../../../../core/data/entities/products/NetworkProduct"
import { Expose, Type } from "class-transformer"
import NetworkPage from "../../../../core/data/entities/pagination/NetworkPage"

export default class NetworkProductsResponseBody {
  @Expose()
  @Type(() => NetworkProduct)
  readonly products?: NetworkProduct[] | null

  @Expose()
  @Type(() => NetworkPage)
  readonly page?: NetworkPage | null
}
