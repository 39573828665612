import DiModule from "../../../../sqadmin/lib/di/DiModule"
import CoreNetworkDiModule from "../../../core/di/modules/CoreNetworkDiModule"
import UsersRepository from "../domain/repositories/UsersRepository"
import DefaultUsersRepository from "../data/repositories/DefaultUsersRepository"
import UsersNetworkSource from "../data/sources/UsersNetworkSource"

export default interface UsersCoreDataDiModule {
  provideUsersRepository(): UsersRepository
}

export class DefaultUsersCoreDataDiModule extends DiModule implements UsersCoreDataDiModule {
  private readonly sqCoreCoreNetworkDiModule: CoreNetworkDiModule

  constructor(parameters: {
    readonly sqCoreCoreNetworkDiModule: CoreNetworkDiModule
  }) {
    super()
    this.sqCoreCoreNetworkDiModule = parameters.sqCoreCoreNetworkDiModule
  }

  provideUsersRepository(): UsersRepository {
    return this.single(
      DefaultUsersRepository.name,
      () => new DefaultUsersRepository({
        usersNetworkSource: this.provideUsersNetworkSource()
      })
    )
  }

  private provideUsersNetworkSource(): UsersNetworkSource {
    return this.single(
      UsersNetworkSource.name,
      () => new UsersNetworkSource({
        backendHttpClient: this.sqCoreCoreNetworkDiModule.provideBackendHttpClient()
      })
    )
  }
}
