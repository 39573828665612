import { Expose, Type } from "class-transformer"
import NetworkProductsSetErrorsObject from "../products-sets/NetworkProductsSetErrorsObject"
import DiscountRuleErrorsObject from "../../../domain/entities/discount-rules/DiscountRuleErrorsObject"
import NetworkDiscountRuleErrorsObjectAttributes from "./NetworkDiscountRuleErrorsObjectAttributes"
import NetworkCalculatorErrorsObject from "../calculators/NetworkCalculatorErrorsObject"

export default class NetworkDiscountRuleErrorsObject implements DiscountRuleErrorsObject {
  @Expose()
  @Type(() => NetworkDiscountRuleErrorsObjectAttributes)
  readonly attributes: NetworkDiscountRuleErrorsObjectAttributes | null | undefined

  @Expose()
  @Type(() => NetworkCalculatorErrorsObject)
  readonly calculator: NetworkCalculatorErrorsObject | null | undefined

  @Expose()
  @Type(() => NetworkProductsSetErrorsObject)
  readonly productsSet: NetworkProductsSetErrorsObject | null | undefined
}
