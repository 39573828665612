import { useCoreTextProvider } from "../../contexts/CoreTextProviderContext"
import React from "react"
import { TextStyle } from "../../../../design/text/TextComponent"
import ErrorMessageComponent from "../../../../design/error-message/ErrorMessageComponent"

export default function AppErrorMessageComponent(parameters: {
  readonly text?: string | null | undefined
  readonly retryText?: string | null | undefined
  readonly messageTextStyle: TextStyle
  readonly buttonTextStyle: TextStyle
  readonly onClick?: () => void
}) {
  const coreTextProvider = useCoreTextProvider()

  return (
    <ErrorMessageComponent
      {...parameters}
      retryText={parameters.retryText ?? coreTextProvider.retryLoading()}
    />
  )
}
