import OptionsRepository from "../../domain/repositories/OptionsRepository"
import PlacesRepository from "../../domain/repositories/PlacesRepository"
import DiModule from "../../../../../sqadmin/lib/di/DiModule"
import CoreDataDiModule from "../../../../core/di/modules/CoreDataDiModule"
import DefaultPlacesRepository from "../../../places/data/DefaultPlacesRepository"
import DefaultOptionsRepository from "../../data/repositories/DefualtOptionsRepository"

export default interface ProductsDataDiModule {

  provideOptionsRepository(): OptionsRepository

  providePlacesRepository(): PlacesRepository
}

export class DefaultProductsDataDiModule extends DiModule implements ProductsDataDiModule {
  private readonly coreDataDiModule: CoreDataDiModule

  constructor(parameters: {
    readonly coreDataDiModule: CoreDataDiModule
  }) {
    super()
    this.coreDataDiModule = parameters.coreDataDiModule
  }

  providePlacesRepository(): PlacesRepository {
    return this.single(
      DefaultPlacesRepository.name,
      () => new DefaultPlacesRepository({
        placesNetworkSource: this.coreDataDiModule.providePlacesNetworkSource()
      })
    )
  }

  provideOptionsRepository(): OptionsRepository {
    return this.single(
      DefaultOptionsRepository.name,
      () => new DefaultOptionsRepository({
        optionsNetworkSource: this.coreDataDiModule.provideOptionsNetworkSource()
      })
    )
  }
}
