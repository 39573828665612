import { Expose } from "class-transformer"
import NetworkUser from "../../../../core/data/entities/users/NetworkUser"

export default class NetworkUserRequestBody {
  @Expose()
  readonly user: NetworkUser

  constructor(parameters: {
    readonly user: NetworkUser
  }) {
    this.user = parameters.user
  }
}
