import Block from "../../domain/entities/blocks/Block"
import NetworkBlock from "../entities/blocks/NetworkBlock"
import BannersMapper from "./BannersMapper"
import { v4 as uuidv4 } from "uuid"
import BlockProductCategoriesMapper from "./BlockProductCategoriesMapper"
import HeadersMapper from "./HeadersMapper"
import ProductsSetsMapper from "./ProductsSetsMapper"
import BlockColumnsCount from "../../domain/entities/blocks/BlockColumnsCount"
import NetworkBlockType from "../entities/blocks/NetworkBlockType"
import BlockType from "../../domain/entities/blocks/BlockType"
import BannersBlockViewType from "../../domain/entities/blocks/BannersBlockViewType"
import ProductsBlockViewType from "../../domain/entities/blocks/ProductsBlockViewType"
import ProductCategoriesBlockViewType from "../../domain/entities/blocks/ProductCategoriesBlockViewType"
import MenuItemsMapper from "./MenuItemsMapper"

export default class BlocksMapper {
  mapNetworkToDomain({
    block
  }: {
    readonly block: NetworkBlock
  }): Block {
    return {
      id: block.id,
      name: block.name,
      clientId: uuidv4(),
      position: block.position,
      type: block.type && this.convertNetworkTypeToType(block.type),
      bannersViewType: block.type && this.convertNetworkTypeToBannersBlockViewType(block.type),
      productsViewType: block.type && this.convertNetworkTypeToProductsBlockViewType(block.type),
      productCategoriesViewType: block.type && this.convertNetworkTypeToProductCategoriesBlockViewType(block.type),
      columnsCount: (block.columnsCount && block.columnsCount.toString()) as BlockColumnsCount,
      banners: block.banners && block.banners.map(banner => {
        return new BannersMapper().mapNetworkToDomain({ banner })
      }),
      blockProductCategories: block.blockProductCategories && block.blockProductCategories.map(blockProductCategory => {
        return new BlockProductCategoriesMapper().mapNetworkToDomain({ blockProductCategory: blockProductCategory })
      }),
      productsSet: block.productsSet && new ProductsSetsMapper().mapNetworkToDomain({ productsSet: block.productsSet }),
      header: block.header && new HeadersMapper().mapNetworkToDomain({ header: block.header }),
      menuItems: block.menuItems && block.menuItems.map(menuItem => {
        return new MenuItemsMapper().mapNetworkToDomain({ menuItem })
      })
    }
  }

  mapDomainToNetwork({
    block
  }: {
    readonly block: Block
  }): NetworkBlock {
    return new NetworkBlock({
      id: block.id,
      name: block.name,
      clientId: block.clientId,
      position: block.position,
      type: block.type && this.convertTypeToNetworkType(block),
      columnsCount: block.columnsCount && parseInt(block.columnsCount),
      banners: block.banners && block.banners.map(banner => {
        return new BannersMapper().mapDomainToNetwork({ banner })
      }),
      blockProductCategories: block.blockProductCategories && block.blockProductCategories.map(blockProductCategory => {
        return new BlockProductCategoriesMapper().mapDomainToNetwork({ blockProductCategory: blockProductCategory })
      }),
      productsSet: block.productsSet && new ProductsSetsMapper().mapDomainToNetwork({ productsSet: block.productsSet }),
      header: block.header && new HeadersMapper().mapDomainToNetwork({ header: block.header }),
      menuItems: block.menuItems && block.menuItems.map(menuItem => {
        return new MenuItemsMapper().mapDomainToNetwork({ menuItem })
      })
    })
  }

  private convertNetworkTypeToType(type: NetworkBlockType): BlockType | null | undefined {
    switch (type) {
      case NetworkBlockType.BANNERS_PAGER:
      case NetworkBlockType.BANNERS_SLIDER:
      case NetworkBlockType.BANNERS:
        return BlockType.BANNERS
      case NetworkBlockType.SEARCH:
        return BlockType.SEARCH
      case NetworkBlockType.PRODUCT_CATEGORIES:
      case NetworkBlockType.PRODUCT_CATEGORIES_SLIDER:
        return BlockType.PRODUCT_CATEGORIES
      case NetworkBlockType.PRODUCTS:
      case NetworkBlockType.PRODUCTS_SLIDER:
        return BlockType.PRODUCTS
      case NetworkBlockType.LOYALTY_PROGRAM:
        return BlockType.LOYALTY_PROGRAM
      case NetworkBlockType.HEADER:
        return BlockType.HEADER
      case NetworkBlockType.CURRENT_ORDERS:
        return BlockType.CURRENT_ORDERS
      case NetworkBlockType.MENU:
        return BlockType.MENU
      default:
        return null
    }
  }

  private convertNetworkTypeToBannersBlockViewType(type: NetworkBlockType): BannersBlockViewType | null | undefined {
    switch (type) {
      case NetworkBlockType.BANNERS_PAGER:
        return BannersBlockViewType.BANNERS_PAGER
      case NetworkBlockType.BANNERS_SLIDER:
        return BannersBlockViewType.BANNERS_SLIDER
      case NetworkBlockType.BANNERS:
        return BannersBlockViewType.BANNERS
      default:
        return null
    }
  }

  private convertNetworkTypeToProductsBlockViewType(type: NetworkBlockType): ProductsBlockViewType | null | undefined {
    switch (type) {
      case NetworkBlockType.PRODUCTS_SLIDER:
        return ProductsBlockViewType.PRODUCTS_SLIDER
      case NetworkBlockType.PRODUCTS:
        return ProductsBlockViewType.PRODUCTS
      default:
        return null
    }
  }

  private convertNetworkTypeToProductCategoriesBlockViewType(
    type: NetworkBlockType
  ): ProductCategoriesBlockViewType | null | undefined {
    switch (type) {
      case NetworkBlockType.PRODUCT_CATEGORIES_SLIDER:
        return ProductCategoriesBlockViewType.PRODUCT_CATEGORIES_SLIDER
      case NetworkBlockType.PRODUCT_CATEGORIES:
        return ProductCategoriesBlockViewType.PRODUCT_CATEGORIES
      default:
        return null
    }
  }

  private convertTypeToNetworkType(block: Block): NetworkBlockType | null | undefined {
    switch (block.type) {
      case BlockType.BANNERS:
        return this.convertBannersBlockViewTypeToNetworkType(block.bannersViewType)
      case BlockType.SEARCH:
        return NetworkBlockType.SEARCH
      case BlockType.PRODUCT_CATEGORIES:
        return this.convertProductCategoriesBlockViewTypeToNetworkType(block.productCategoriesViewType)
      case BlockType.PRODUCTS:
        return this.convertProductsBlockViewTypeToNetworkType(block.productsViewType)
      case BlockType.LOYALTY_PROGRAM:
        return NetworkBlockType.LOYALTY_PROGRAM
      case BlockType.HEADER:
        return NetworkBlockType.HEADER
      case BlockType.CURRENT_ORDERS:
        return NetworkBlockType.CURRENT_ORDERS
      case BlockType.MENU:
        return NetworkBlockType.MENU
      default:
        return null
    }
  }

  private convertBannersBlockViewTypeToNetworkType(
    viewType: BannersBlockViewType | null | undefined
  ): NetworkBlockType | null | undefined {
    switch (viewType) {
      case BannersBlockViewType.BANNERS_PAGER:
        return NetworkBlockType.BANNERS_PAGER
      case BannersBlockViewType.BANNERS_SLIDER:
        return NetworkBlockType.BANNERS_SLIDER
      case BannersBlockViewType.BANNERS:
        return NetworkBlockType.BANNERS
      default:
        return null
    }
  }

  private convertProductsBlockViewTypeToNetworkType(
    viewType: ProductsBlockViewType | null | undefined
  ): NetworkBlockType | null | undefined {
    switch (viewType) {
      case ProductsBlockViewType.PRODUCTS:
        return NetworkBlockType.PRODUCTS
      case ProductsBlockViewType.PRODUCTS_SLIDER:
        return NetworkBlockType.PRODUCTS_SLIDER
      default:
        return null
    }
  }

  private convertProductCategoriesBlockViewTypeToNetworkType(
    viewType: ProductCategoriesBlockViewType | null | undefined
  ): NetworkBlockType | null | undefined {
    switch (viewType) {
      case ProductCategoriesBlockViewType.PRODUCT_CATEGORIES:
        return NetworkBlockType.PRODUCT_CATEGORIES
      case ProductCategoriesBlockViewType.PRODUCT_CATEGORIES_SLIDER:
        return NetworkBlockType.PRODUCT_CATEGORIES_SLIDER
      default:
        return null
    }
  }
}
