import { I18nParameters, TextProvidersMap } from "../../i18n/I18n"
import DiModule from "../../../lib/di/DiModule"

export interface I18nDiModuleParameters<TextProvider> {
  readonly defaultLanguage: string
  readonly textProviderByLanguage: TextProvidersMap<TextProvider>
}

export class I18nDiModule<TextProvider> extends DiModule {
  private readonly defaultLanguage: string
  private readonly textProviderByLanguage: TextProvidersMap<TextProvider>

  constructor(parameters: I18nDiModuleParameters<TextProvider>) {
    super()
    this.defaultLanguage = parameters.defaultLanguage
    this.textProviderByLanguage = parameters.textProviderByLanguage
  }

  protected getI18nParameters(): I18nParameters<TextProvider> {
    return {
      language: this.defaultLanguage,
      textProviderByLanguage: this.textProviderByLanguage
    }
  }
}
