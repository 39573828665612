import React from "react"
import DatePickerComponent from "../../../../lib/date-picker/DatePickerComponent"
import useWeekDayNameByNumber from "../../hooks/useWeekDayNameByNumber"
import useMonthNameByNumber from "../../hooks/useMonthNameByNumber"
import { useCoreTextProvider } from "../../contexts/CoreTextProviderContext"

export default function AppDatePickerComponent(parameters: {
  readonly value: Date | null
  readonly onChange: (date: Date | null) => void
  readonly disabled?: boolean
  readonly selectButtonText?: string
  readonly clearButtonText?: string
}) {
  const coreTextProvider = useCoreTextProvider()
  const weekDayNameByNumber: { [key: number]: string } = useWeekDayNameByNumber()
  const monthNameByNumber: { [key: number]: string } = useMonthNameByNumber()

  return (
    <DatePickerComponent
      {...parameters}
      weekDayNameByNumber={weekDayNameByNumber}
      monthNameByNumber={monthNameByNumber}
      selectButtonText={parameters.selectButtonText ?? coreTextProvider.select()}
      clearButtonText={parameters.clearButtonText ?? coreTextProvider.clear()}
    />
  )
}
