import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../errors/NetworkAttributeError"
import CalculatorErrorsObjectAttributes from "../../../domain/entities/calculators/CalculatorErrorsObjectAttributes"

export default class NetworkCalculatorErrorsObjectAttributes implements CalculatorErrorsObjectAttributes {
  @Expose()
  @Type(() => NetworkAttributeError)
  readonly id: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly value: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly calculationType: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly roundingDecimalsCount: NetworkAttributeError[] | null | undefined
}
