import { Expose, Type } from "class-transformer"
import NetworkDashboard from "./NetworkDashboard"
import NetworkPage from "../pagination/NetworkPage"

export default class NetworkDashboardsResponseBody {
  @Expose()
  @Type(() => NetworkDashboard)
  readonly dashboards: NetworkDashboard[] | null | undefined

  @Expose()
  @Type(() => NetworkPage)
  readonly page: NetworkPage | null | undefined
}
