import { GetObjectsPageResult } from "../../../../sqadmin/features/objects/domain/results/GetObjectsPageResult"
import LastItemPaginationsMapper from "../../../core/data/mappers/LastItemPaginationsMapper"
import SortMapper from "../../../core/data/mappers/SortMapper"
import PagesMapper from "../../../core/data/mappers/PagesMapper"
import OptionsRepository from "../domain/repositories/OptionsRepository"
import { GetOptionsParameters } from "../domain/use-cases/GetOptionsUseCase"
import OptionsNetworkSource, { GetOptionsNetworkResult } from "../../../core/data/sources/network/OptionsNetworkSource"
import OptionsFiltersMapper from "../../../core/data/mappers/OptionsFiltersMapper"
import OptionsMapper from "../../../core/data/mappers/OptionsMapper"
import { CreateObjectResult } from "../../../../sqadmin/features/objects/domain/results/CreateObjectResult"
import { DestroyObjectResult } from "../../../../sqadmin/features/objects/domain/results/DestroyObjectResult"
import { LoadObjectResult } from "../../../../sqadmin/features/objects/domain/results/LoadObjectResult"
import { UpdateObjectResult } from "../../../../sqadmin/features/objects/domain/results/UpdateObjectResult"
import OptionError from "../../../core/domain/entities/options/OptionError"
import GetObjectNetworkResult from "../../../core/data/results/GetObjectNetworkResult"
import CreateObjectNetworkResult from "../../../core/data/results/CreateObjectNetworkResult"
import UpdateObjectNetworkResult from "../../../core/data/results/UpdateObjectNetworkResult"
import DestroyObjectNetworkResult from "../../../core/data/results/DestroyObjectNetworkResult"
import Option from "../../../core/domain/entities/options/Option"
import NetworkOption from "../../../core/data/entities/options/NetworkOption"
import NetworkOptionError from "../../../core/data/entities/options/NetworkOptionError"

export default class DefaultOptionsRepository implements OptionsRepository {
  private readonly optionsNetworkSource: OptionsNetworkSource

  constructor(parameters: {
    readonly optionsNetworkSource: OptionsNetworkSource
  }) {
    this.optionsNetworkSource = parameters.optionsNetworkSource
  }

  async getOptions({
    filter,
    pagination,
    sort
  }: GetOptionsParameters): Promise<GetObjectsPageResult<Option>> {
    const result: GetOptionsNetworkResult = await this.optionsNetworkSource.getOptions({
      filter: filter && new OptionsFiltersMapper().mapDomainToNetwork({ filter }),
      pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({ pagination }),
      sort: sort && new SortMapper().mapDomainToNetwork({ sort })
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: {
            objects: result.data.options!.map((option: Option) => {
              return new OptionsMapper().mapNetworkToDomain({
                option: option
              })
            }),
            page: new PagesMapper().mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      default:
        return result
    }
  }

  async getOption({
    optionId
  }: {
    readonly optionId: number
  }): Promise<LoadObjectResult<Option>> {
    const result: GetObjectNetworkResult<NetworkOption> =
      await this.optionsNetworkSource.getOption({
        optionId
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new OptionsMapper().mapNetworkToDomain({
            option: result.data
          })
        }
      default:
        return result
    }
  }

  async createOption({
    option
  }: {
    readonly option: Option
  }): Promise<CreateObjectResult<Option, OptionError>> {
    const result: CreateObjectNetworkResult<NetworkOption, NetworkOptionError> =
      await this.optionsNetworkSource.createOption({
        option: new OptionsMapper().mapDomainToNetwork({
          option: option
        })
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new OptionsMapper().mapNetworkToDomain({
            option: result.data
          })
        }
      default:
        return result
    }
  }

  async updateOption({
    optionId,
    option
  }: {
    readonly optionId: number
    readonly option: Option
  }): Promise<UpdateObjectResult<Option, OptionError>> {
    const result: UpdateObjectNetworkResult<NetworkOption, NetworkOptionError> =
      await this.optionsNetworkSource.updateOption({
        optionId,
        option: new OptionsMapper().mapDomainToNetwork({
          option: option
        })
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new OptionsMapper().mapNetworkToDomain({
            option: result.data
          })
        }
      default:
        return result
    }
  }

  async destroyOption({
    optionId
  }: {
    readonly optionId: number
  }): Promise<DestroyObjectResult<OptionError>> {
    const result: DestroyObjectNetworkResult<NetworkOptionError> =
      await this.optionsNetworkSource.destroyOption({
        optionId
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: undefined
        }
      default:
        return result
    }
  }
}
