import { Expose, Type } from "class-transformer"
import NetworkPaymentStatus from "../payment-statuses/NetworkPaymentStatus"

export default class NetworkPayment {
  @Expose()
  readonly statusId: number | null | undefined

  @Expose()
  @Type(() => NetworkPaymentStatus)
  readonly status: NetworkPaymentStatus | null | undefined

  constructor(parameters?: {
    readonly statusId: number | null | undefined
    readonly status: NetworkPaymentStatus | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.statusId = parameters.statusId
    this.status = parameters.status
  }
}
