import { GetObjectsPageResult } from "../../../../../sqadmin/features/objects/domain/results/GetObjectsPageResult"
import LastItemPaginationsMapper from "../../../../core/data/mappers/LastItemPaginationsMapper"
import SortMapper from "../../../../core/data/mappers/SortMapper"
import PagesMapper from "../../../../core/data/mappers/PagesMapper"
import { LoadObjectResult } from "../../../../../sqadmin/features/objects/domain/results/LoadObjectResult"
import GetObjectNetworkResult from "../../../../core/data/results/GetObjectNetworkResult"
import { CreateObjectResult } from "../../../../../sqadmin/features/objects/domain/results/CreateObjectResult"
import CreateObjectNetworkResult from "../../../../core/data/results/CreateObjectNetworkResult"
import { UpdateObjectResult } from "../../../../../sqadmin/features/objects/domain/results/UpdateObjectResult"
import UpdateObjectNetworkResult from "../../../../core/data/results/UpdateObjectNetworkResult"
import { DestroyObjectResult } from "../../../../../sqadmin/features/objects/domain/results/DestroyObjectResult"
import DestroyObjectNetworkResult from "../../../../core/data/results/DestroyObjectNetworkResult"
import DiscountRulesRepository from "../../domain/repositories/DiscountRulesRepository"
import DiscountRulesNetworkSource, { GetDiscountRulesNetworkResult } from "../sources/DiscountRulesNetworkSource"
import DiscountRule from "../../../../core/domain/entities/discount-rules/DiscountRule"
import NetworkDiscountRule from "../../../../core/data/entities/discount-rules/NetworkDiscountRule"
import DiscountRuleError from "../../../../core/domain/entities/discount-rules/DiscountRuleError"
import NetworkDiscountRuleError from "../../../../core/data/entities/discount-rules/NetworkDiscountRuleError"
import DiscountRulesMapper from "../../../../core/data/mappers/DiscountRulesMapper"
import DiscountRulesFilterMapper from "../mappers/DiscountRulesFilterMapper"
import { GetDiscountRulesParameters } from "../../domain/use-cases/GetDiscountRulesUseCase"
import { UpdateDiscountRuleParameters } from "../../domain/use-cases/UpdateDiscountRuleUseCase"

export default class DefaultDiscountRulesRepository implements DiscountRulesRepository {
  private readonly discountRulesNetworkSource: DiscountRulesNetworkSource

  constructor(parameters: {
    readonly discountRulesNetworkSource: DiscountRulesNetworkSource
  }) {
    this.discountRulesNetworkSource = parameters.discountRulesNetworkSource
  }

  async getDiscountRules({
    filter,
    pagination,
    sort
  }: GetDiscountRulesParameters): Promise<GetObjectsPageResult<DiscountRule>> {
    const result: GetDiscountRulesNetworkResult = await this.discountRulesNetworkSource
      .getDiscountRules({
        filter: filter && new DiscountRulesFilterMapper().mapDomainToNetwork({ filter }),
        pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({ pagination }),
        sort: sort && new SortMapper().mapDomainToNetwork({ sort })
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: {
            objects: result.data.discountRules!.map((discountRule: NetworkDiscountRule) => {
              return new DiscountRulesMapper().mapNetworkToDomain({
                discountRule: discountRule
              })
            }),
            page: new PagesMapper().mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      default:
        return result
    }
  }

  async getDiscountRule({
    discountRuleId
  }: {
    readonly discountRuleId: number
  }): Promise<LoadObjectResult<DiscountRule>> {
    const result: GetObjectNetworkResult<NetworkDiscountRule> =
      await this.discountRulesNetworkSource.getDiscountRule({
        discountRuleId: discountRuleId
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new DiscountRulesMapper().mapNetworkToDomain({
            discountRule: result.data
          })
        }
      default:
        return result
    }
  }

  async createDiscountRule({
    discountRule
  }: {
    readonly discountRule: DiscountRule
  }): Promise<CreateObjectResult<DiscountRule, DiscountRuleError>> {
    const result: CreateObjectNetworkResult<NetworkDiscountRule, NetworkDiscountRuleError> =
      await this.discountRulesNetworkSource.createDiscountRule({
        discountRule: new DiscountRulesMapper().mapDomainToNetwork({
          discountRule: discountRule
        })
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new DiscountRulesMapper().mapNetworkToDomain({
            discountRule: result.data
          })
        }
      default:
        return result
    }
  }

  async updateDiscountRule({
      discountRuleId,
      discountRule
    }: UpdateDiscountRuleParameters
  ): Promise<UpdateObjectResult<DiscountRule, DiscountRuleError>> {
    const result: UpdateObjectNetworkResult<NetworkDiscountRule, NetworkDiscountRuleError> =
      await this.discountRulesNetworkSource.updateDiscountRule({
        discountRuleId,
        discountRule: new DiscountRulesMapper().mapDomainToNetwork({
          discountRule: discountRule
        })
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new DiscountRulesMapper().mapNetworkToDomain({
            discountRule: result.data
          })
        }
      default:
        return result
    }
  }

  async destroyDiscountRule({
    discountRuleId
  }: {
    readonly discountRuleId: number
  }): Promise<DestroyObjectResult<DiscountRuleError>> {
    const result: DestroyObjectNetworkResult<NetworkDiscountRuleError> =
      await this.discountRulesNetworkSource.destroyDiscountRule({
        discountRuleId: discountRuleId
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: undefined
        }
      default:
        return result
    }
  }
}
