import { GetObjectsPageResult } from "../../../../../sqadmin/features/objects/domain/results/GetObjectsPageResult"
import Sort from "../../../../../sqadmin/features/objects/presentation/entities/tables/Sort"
import LastItemPagination from "../../../../core/domain/entities/paginations/LastItemPagination"
import OrderPaymentMethodsRepository from "../repositories/OrderPaymentMethodsRepository"
import OrderPaymentMethod from "../../../../core/domain/entities/order-payment-methods/OrderPaymentMethod"

export default class GetOrderPaymentMethodsUseCase {
  private readonly orderPaymentMethodsRepository: OrderPaymentMethodsRepository

  constructor(parameters: {
    readonly orderPaymentMethodsRepository: OrderPaymentMethodsRepository
  }) {
    this.orderPaymentMethodsRepository = parameters.orderPaymentMethodsRepository
  }

  async call(parameters: GetOrderPaymentMethodsParameters): Promise<GetObjectsPageResult<OrderPaymentMethod>> {
    return this.orderPaymentMethodsRepository.getOrderPaymentMethods(parameters)
  }
}

export interface GetOrderPaymentMethodsParameters {
  readonly query: string | null | undefined
  readonly sort?: Sort
  readonly pagination?: LastItemPagination
}
