import NetworkOrderShippingTariff from "../entities/order-shipping-method-tariffs/NetworkOrderShippingTariff"
import OrderShippingTariff from "../../domain/entities/order-shipping-method-tariffs/OrderShippingTariff"

export default class OrderShippingTariffsMapper {
  mapNetworkToDomain({
    orderShippingTariff
  }: {
    readonly orderShippingTariff: NetworkOrderShippingTariff
  }): OrderShippingTariff {
    return {
      id: orderShippingTariff.id,
      name: orderShippingTariff.name
    }
  }

  mapDomainToNetwork({
    orderShippingTariff
  }: {
    readonly orderShippingTariff: OrderShippingTariff
  }): NetworkOrderShippingTariff {
    return new NetworkOrderShippingTariff({
      id: orderShippingTariff.id,
      name: orderShippingTariff.name
    })
  }
}
