import { RouteObject } from "react-router-dom"
import { objectIdUrlPart } from "../../../../sqadmin/features/objects/presentation/hooks/useObjectId"
import CoreUrlProvider from "../../../core/presentation/services/CoreUrlProvider"
import ProductCategoriesDi from "../di/ProductCategoriesDi"

export default function createProductCategoriesRoutes({
  di
}: {
  readonly di: ProductCategoriesDi
}): RouteObject[] {
  const urlProvider = new CoreUrlProvider()
  return [
    {
      path: urlProvider.buildProductCategoriesPath(),
      element: di
        .sqCoreProductCategoriesDiComponent
        .productCategoriesPresentationDiModule
        .provideProductCategoriesPage()
    },
    {
      path: urlProvider.buildNewProductCategoryPath(),
      element: di
        .sqCoreProductCategoriesDiComponent
        .productCategoriesPresentationDiModule
        .provideProductCategoryPage()
    },
    {
      path: urlProvider.buildProductCategoryPath({ id: `:${objectIdUrlPart}` }),
      element: di
        .sqCoreProductCategoriesDiComponent
        .productCategoriesPresentationDiModule
        .provideProductCategoryPage()
    }
  ]
}
