import PlacesFilter from "../../domain/entities/places/PlacesFilter"
import NetworkPlacesRequestFilter from "../entities/places/NetworkPlacesRequestFilter"

export default class PlacesFiltersMapper {
  mapDomainToNetwork({
    filter
  }: {
    readonly filter: PlacesFilter
  }): NetworkPlacesRequestFilter {
    return new NetworkPlacesRequestFilter({
      query: filter.query
    })
  }
}
