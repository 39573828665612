import BackendHttpClient, { BackendHttpClientResult } from "../../network/BackendHttpClient"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import CreateSessionUserPartial from "../../../../../sqadmin/core/domain/entities/CreateSessionUserPartial"
import NetworkCreateSessionRequestBody from "../../entities/sessions/NetworkCreateSessionRequestBody"
import { instanceToPlain, plainToInstance } from "class-transformer"
import NetworkUserSession from "../../entities/sessions/NetworkUserSession"
import NetworkCreateSessionError from "../../entities/sessions/NetworkCreateSessionError"
import SessionsNetworkSource, {
  CreateSessionNetworkResult
} from "../../../../../sqadmin/core/data/sources/sessions/SessionsNetworkSource"

export default class DefaultSessionNetworkSource implements SessionsNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async createSession({
    user
  }: {
    readonly user: CreateSessionUserPartial
  }): Promise<CreateSessionNetworkResult> {
    const requestBody = new NetworkCreateSessionRequestBody({
      user: {
        emailAddress: user.emailAddress,
        password: user.password
      }
    })

    const backendHttpClientResult: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.POST,
      path: "/admin/sessions/create_by_email_address_and_password",
      body: instanceToPlain(requestBody, { strategy: "excludeAll" })
    })

    switch (backendHttpClientResult.type) {
      case "success": {
        const networkUserSession: NetworkUserSession = plainToInstance(
          NetworkUserSession,
          backendHttpClientResult.body
        )

        return {
          type: "success",
          data: networkUserSession
        }
      }
      case "error": {
        const error: NetworkCreateSessionError = plainToInstance(
          NetworkCreateSessionError,
          backendHttpClientResult.body
        )

        return {
          type: "error",
          error
        }
      }
      case "failure":
        return backendHttpClientResult
    }
  }
}
