import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../errors/NetworkAttributeError"
import DashboardErrorsObjectAttributes from "../../../domain/entities/dashboards/DashboardErrorsObjectAttributes"

export default class NetworkDashboardErrorsObjectAttributes implements DashboardErrorsObjectAttributes {
  @Expose()
  @Type(() => NetworkAttributeError)
  readonly id: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly code: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly blocks: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly name: NetworkAttributeError[] | null | undefined
}
