import { RouteObject } from "react-router-dom"
import { objectIdUrlPart } from "../../../../sqadmin/features/objects/presentation/hooks/useObjectId"
import CoreUrlProvider from "../../../core/presentation/services/CoreUrlProvider"
import BonusProgramLevelsDi from "../di/BonusProgramLevelsDi"

export default function createBonusProgramLevelsRoutes({
  di
}: {
  readonly di: BonusProgramLevelsDi
}): RouteObject[] {
  const urlProvider = new CoreUrlProvider()
  return [
    {
      path: urlProvider.buildBonusProgramLevelsPath(),
      element: di
        .sqCoreBonusProgramLevelsDiComponent
        .bonusProgramLevelsPresentationDiModule
        .provideBonusProgramLevelsPage()
    },
    {
      path: urlProvider.buildNewBonusProgramLevelPath(),
      element: di
        .sqCoreBonusProgramLevelsDiComponent
        .bonusProgramLevelsPresentationDiModule
        .provideBonusProgramLevelPage()
    },
    {
      path: urlProvider.buildBonusProgramLevelPath({ id: `:${objectIdUrlPart}` }),
      element: di
        .sqCoreBonusProgramLevelsDiComponent
        .bonusProgramLevelsPresentationDiModule
        .provideBonusProgramLevelPage()
    }
  ]
}
