import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../errors/NetworkAttributeError"
import AttributeError from "../../../../../sqadmin/core/domain/entities/AttributeError"
import BonusPointsBurningRuleErrorsObjectAttributes
  from "../../../domain/entities/bonus-points-burning-rules/BonusPointsBurningRuleErrorsObjectAttributes"

export default class NetworkBonusPointsBurningRuleErrorsObjectAttributes
  implements BonusPointsBurningRuleErrorsObjectAttributes {
  @Expose()
  @Type(() => NetworkAttributeError)
  readonly minEarnedSum: AttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly periodType: AttributeError[] | null | undefined
}
