import { Expose } from "class-transformer"

export default class NetworkSettlement {
  @Expose()
  readonly id: number | null | undefined

  @Expose()
  readonly fullName: string | null | undefined

  constructor(parameters?: {
    readonly id: number | null | undefined
    readonly fullName: string | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.id = parameters.id
    this.fullName = parameters.fullName
  }
}
