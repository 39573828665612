import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../errors/NetworkAttributeError"
import DiscountRuleErrorsObjectAttributes
  from "../../../domain/entities/discount-rules/DiscountRuleErrorsObjectAttributes"

export default class NetworkDiscountRuleErrorsObjectAttributes implements DiscountRuleErrorsObjectAttributes {
  @Expose()
  @Type(() => NetworkAttributeError)
  readonly id: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly priority: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly calculator: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly productsSet: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly name: NetworkAttributeError[] | null | undefined
}
