import { Expose } from "class-transformer"

export default class NetworkConfigurationEntityPolicy {
  @Expose()
  readonly canCreate: boolean | null | undefined

  @Expose()
  readonly canUpdate: boolean | null | undefined

  @Expose()
  readonly canDestroy: boolean | null | undefined

  constructor(parameters?: {
    readonly canCreate: boolean | null | undefined
    readonly canUpdate: boolean | null | undefined
    readonly canDestroy: boolean | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.canCreate = parameters.canCreate
    this.canUpdate = parameters.canUpdate
    this.canDestroy = parameters.canDestroy
  }
}
