import React from "react"
import CoreTextProvider from "../../../i18n/CoreTextProvider"
import { useCoreTextProvider } from "../../contexts/CoreTextProviderContext"
import TextComponent, { TextStyle } from "../../../../design/text/TextComponent"

export default function TableRowEmptyDataComponent({
  columnsCount
}: {
  readonly columnsCount: number
}) {
  const coreTextProvider: CoreTextProvider = useCoreTextProvider()
  return (
    <tr>
      <td colSpan={columnsCount}>
        <TextComponent textStyle={TextStyle.TABLE3_PRIMARY}>{coreTextProvider.noObjects()}</TextComponent>
      </td>
    </tr>
  )
}
