import { v4 as uuidv4 } from "uuid"
import ProductsMapper from "./ProductsMapper"
import ProductsSetProduct from "../../domain/entities/products-set-products/ProductsSetProduct"
import NetworkProductsSetProduct from "../entities/products-set-products/NetworkProductsSetProduct"

export default class ProductsSetProductsMapper {
  mapNetworkToDomain({
    productsSetProduct
  }: {
    readonly productsSetProduct: NetworkProductsSetProduct
  }): ProductsSetProduct {
    return {
      id: productsSetProduct.id,
      position: productsSetProduct.position,
      product: productsSetProduct.product && new ProductsMapper().mapNetworkToDomain({
        product: productsSetProduct.product
      }),
      productId: productsSetProduct.productId,
      clientId: uuidv4()
    }
  }

  mapDomainToNetwork({
    productsSetProduct
  }: {
    readonly productsSetProduct: ProductsSetProduct
  }): NetworkProductsSetProduct {
    return new NetworkProductsSetProduct({
      id: productsSetProduct.id,
      position: productsSetProduct.position,
      product: productsSetProduct.product && new ProductsMapper().mapDomainToNetwork({
        product: productsSetProduct.product
      }),
      productId: productsSetProduct.productId,
      clientId: productsSetProduct.clientId
    })
  }
}
