import AuthenticationTextProvider from "../AuthenticationTextProvider"

export default class DefaultRuAuthenticationTextProvider implements AuthenticationTextProvider {
  authentication(): string {
    return "Логин"
  }

  emailAddress(): string {
    return "Адрес электронной почты"
  }

  password(): string {
    return "Пароль"
  }

  authenticate(): string {
    return "Войти"
  }
}
