import NetworkOrderReceivingMethodsRequestFilter from "../entities/NetworkOrderReceivingMethodsRequestFilter"

export default class OrderReceivingMethodsFiltersMapper {
  mapDomainToNetwork({
    query
  }: {
    readonly query: string | null | undefined
  }): NetworkOrderReceivingMethodsRequestFilter {
    return new NetworkOrderReceivingMethodsRequestFilter({
      query: query
    })
  }
}
