import isBlank from "./isBlank"

export default function addSearchParam({
  searchParams,
  key,
  value
}: {
  readonly searchParams: URLSearchParams
  readonly key: string
  readonly value: string | undefined | null
}): URLSearchParams {
  const newSearchParams = new URLSearchParams(searchParams)

  if (isBlank(value)) {
    newSearchParams.delete(key)
  } else {
    newSearchParams.set(key, value)
  }

  return newSearchParams
}
