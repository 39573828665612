import NetworkLastItemPagination from "../entities/pagination/NetworkLastItemPagination"
import LastItemPagination from "../../domain/entities/paginations/LastItemPagination"
import isBlank from "../../../../sqadmin/lib/isBlank"

export default class LastItemPaginationsMapper {
  mapDomainToNetwork({
    pagination
  }: {
    readonly pagination: LastItemPagination
  }): NetworkLastItemPagination {
    return {
      id: isBlank(pagination.id) ? undefined : pagination.id,
      size: pagination.size
    }
  }
}
