import { Expose, Type } from "class-transformer"
import NetworkPage from "../pagination/NetworkPage"
import NetworkOrder from "./NetworkOrder"

export default class NetworkOrdersResponseBody {
  @Expose()
  @Type(() => NetworkOrder)
  readonly orders: NetworkOrder[] | null | undefined

  @Expose()
  @Type(() => NetworkPage)
  readonly page: NetworkPage | null | undefined
}
