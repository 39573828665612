import ApplicationException from "../../domain/exceptions/ApplicationException"
import CoreTextProvider from "../../i18n/CoreTextProvider"

export default class ExceptionLocalizer {
  private readonly coreTextProvider: CoreTextProvider

  constructor(parameters: {
    readonly coreTextProvider: CoreTextProvider
  }) {
    this.coreTextProvider = parameters.coreTextProvider
  }

  localizeException(_exception: ApplicationException): string {
    return this.coreTextProvider.somethingWentWrong()
  }
}
