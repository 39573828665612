import { Expose } from "class-transformer"
import NetworkBonusPointsBurningRule
  from "../../../../core/data/entities/bonus-points-burning-rules/NetworkBonusPointsBurningRule"

export default class NetworkBonusPointsBurningRuleRequestBody {
  @Expose()
  readonly bonusPointsBurningRule: NetworkBonusPointsBurningRule

  constructor(parameters: {
    readonly bonusPointsBurningRule: NetworkBonusPointsBurningRule
  }) {
    this.bonusPointsBurningRule = parameters.bonusPointsBurningRule
  }
}
