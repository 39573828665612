import NetworkOrderReceivingAddress from "../entities/order-receiving-addresses/NetworkOrderReceivingAddress"
import OrderReceivingAddress from "../../domain/entities/order-receiving-addresses/OrderReceivingAddress"

export default class OrderReceivingAddressesMapper {
  mapNetworkToDomain({
    orderReceivingAddress
  }: {
    readonly orderReceivingAddress: NetworkOrderReceivingAddress
  }): OrderReceivingAddress {
    return {
      id: orderReceivingAddress.id,
      value: orderReceivingAddress.value
    }
  }

  mapDomainToNetwork({
    orderReceivingAddress
  }: {
    readonly orderReceivingAddress: OrderReceivingAddress
  }): NetworkOrderReceivingAddress {
    return new NetworkOrderReceivingAddress({
      id: orderReceivingAddress.id,
      value: orderReceivingAddress.value
    })
  }
}
