import React from "react"
import DropdownComponent, { DropdownProps } from "../dropdown/DropdownComponent"
import styles from "./AppDropdownComponent.module.scss"

export default function AppDropdownComponent(props: DropdownProps) {
  return (
    <DropdownComponent {...props}>
      <div className={styles.content}>
        {props.children}
      </div>
    </DropdownComponent>
  )
}
