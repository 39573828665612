import React, { useEffect, useRef, useState } from "react"
import DropdownComponent from "../../core/presentation/components/dropdown/DropdownComponent"
import styles from "./DateRangePickerComponent.module.scss"
import CalendarBorderComponent from "../calendar-border/CalendarBorderComponent"
import CalendarDropdownComponent from "../calendar-dropdown/CalendarDropdownComponent"
import { correctDateRange, DateRange } from "../date-range/DateRange"
import ExtendedDate from "../extended-date/ExtendedDate"
import isPresent from "../isPresent"
import isBlank from "../isBlank"
import { DateRangeCalendarComponent } from "../date-range-calendar/DateRangeCalendarComponent"
import IMask from "imask"

export interface DateRangePickerComponentProps {
  readonly dateRange: DateRange | null
  readonly onDateRangeChanged: (dateRange: DateRange | null) => void
  readonly weekDayNameByNumber: { [_: number]: string }
  readonly monthNameByNumber: { [_: number]: string }
  readonly clearButtonText: string
  readonly selectButtonText: string
  readonly onMonthChanged?: (parameters: { readonly visibleDateRange: DateRange }) => void
  readonly onDateRangeSelected?: (dateRange: DateRange | null) => void
  readonly renderDateDescription?: (parameters: { readonly date: Date }) => React.ReactNode
  readonly disabled?: boolean
}

export default function DateRangePickerComponent({
  dateRange: defaultDateRange,
  onDateRangeChanged,
  weekDayNameByNumber,
  clearButtonText,
  selectButtonText,
  onMonthChanged,
  onDateRangeSelected,
  monthNameByNumber,
  renderDateDescription,
  disabled
}: DateRangePickerComponentProps) {
  const [isCalendarsVisible, setIsCalendarsVisible] = useState(false)
  const [dateRange, setDateRange] = useState<Partial<DateRange>>(defaultDateRange ?? {})
  const { dateFrom, dateTo } = dateRange

  const formattedDateFrom: string | null | undefined = dateFrom && new ExtendedDate(dateFrom).formatDate()
  const formattedDateTo: string | null | undefined = dateTo && new ExtendedDate(dateTo).formatDate()

  const dateFromInputRef = useRef<HTMLInputElement>(null)
  const dateFromInputMaskRef = useRef<IMask.InputMask<{ mask: DateConstructor }> | null>(null)

  const dateToInputRef = useRef<HTMLInputElement>(null)
  const dateToInputMaskRef = useRef<IMask.InputMask<{ mask: DateConstructor }> | null>(null)
  const [shownMonth, setShownMonth] = useState<Date>(new ExtendedDate(dateFrom ?? new Date()).getFirstMonthDate())
  const nextShownMonth: Date = new ExtendedDate(shownMonth).addMonths(1)

  useEffect(() => {
    if (isPresent(dateRange.dateFrom) && isPresent(dateRange.dateTo)) {
      correctDateRangeAndHideCalendar()
    }
  }, [dateRange])

  useEffect(() => {
    callOnMonthChanged(shownMonth)
  }, [])

  useEffect(() => {
    dateFromInputMaskRef.current = IMask(dateFromInputRef.current!, {
      mask: Date,
      lazy: true
    })
  }, [])

  useEffect(() => {
    dateToInputMaskRef.current = IMask(dateToInputRef.current!, {
      mask: Date,
      lazy: true
    })
  }, [])

  useEffect(() => {
    const dateFromInputMask = dateFromInputMaskRef.current!

    dateFromInputMask.value = formattedDateFrom ?? ""

    const onComplete = () => {
      const dateFrom: Date = dateFromInputMask.typedValue
      const dateRange: Partial<DateRange> = correctDateRange({ dateFrom, dateTo })

      if (isPresent(dateRange.dateFrom)) {
        const newShownMonth: Date = new ExtendedDate(dateRange.dateFrom).getFirstMonthDate()
        setShownMonthIfChanged(newShownMonth)
      }

      handleOnDateRangeChanged(dateRange)
    }

    dateFromInputMask.on("complete", onComplete)

    return () => {
      dateFromInputMask.off("complete", onComplete)
    }
  }, [dateFrom, dateTo])

  useEffect(() => {
    const dateToInputMask = dateToInputMaskRef.current!

    dateToInputMask.value = formattedDateTo ?? ""

    const onComplete = () => {
      const dateTo: Date = dateToInputMask.typedValue
      const dateRange: Partial<DateRange> = correctDateRange({ dateFrom, dateTo })

      if (isPresent(dateRange.dateTo)) {
        let newShownMonth: Date = new ExtendedDate(dateRange.dateTo).getFirstMonthDate()
        newShownMonth = new ExtendedDate(newShownMonth).addMonths(-1)
        setShownMonthIfChanged(newShownMonth)
      }

      handleOnDateRangeChanged(dateRange)
    }

    dateToInputMask.on("complete", onComplete)

    return () => {
      dateToInputMask.off("complete", onComplete)
    }
  }, [dateTo, dateFrom])

  function callOnMonthChanged(month: Date) {
    const extendedMonth = new ExtendedDate(month)
    const nextMonth = extendedMonth.addMonths(1)
    const endOfNextMonth: Date = new ExtendedDate(nextMonth).getLastMonthDate()

    const visibleDateRange: DateRange = {
      dateFrom: extendedMonth.getFirstMonthDate(),
      dateTo: endOfNextMonth
    }

    onMonthChanged && onMonthChanged({ visibleDateRange })
  }

  function setShownMonthIfChanged(newShownMonth: Date) {
    if (newShownMonth.getTime() !== shownMonth.getTime()) {
      setShownMonth(newShownMonth)
      callOnMonthChanged(newShownMonth)
    }
  }

  function handleOnInputsClick() {
    if (disabled) {
      return
    }

    if (!isCalendarsVisible) {
      setIsCalendarsVisible(true)

      if (isPresent(dateRange.dateFrom) && isPresent(dateRange.dateTo)) {
        onDateRangeSelected && onDateRangeSelected({
          dateFrom: dateRange.dateFrom,
          dateTo: dateRange.dateTo
        })
      } else if (isBlank(dateRange.dateFrom) && isBlank(dateRange.dateTo)) {
        onDateRangeSelected && onDateRangeSelected(null)
      }
    } else {
      correctDateRangeAndHideCalendar()
    }
  }

  function handleDropdownRequestHide() {
    dateToInputMaskRef.current!.value = formattedDateTo ?? ""
    dateFromInputMaskRef.current!.value = formattedDateFrom ?? ""

    correctDateRangeAndHideCalendar()
  }

  function handleOnDateRangeChanged(dateRange: Partial<DateRange>) {
    setDateRange(dateRange)

    if (isPresent(dateRange.dateFrom) && isPresent(dateRange.dateTo)) {
      onDateRangeSelected && onDateRangeSelected({
        dateFrom: dateRange.dateFrom,
        dateTo: dateRange.dateTo
      })
    }
  }

  function handleClear() {
    setIsCalendarsVisible(false)
    setDateRange({})
    checkAndChangeDateRange(null)
  }

  function handleMonthChange({ month: newShownMonth }: { readonly month: Date }) {
    setShownMonthIfChanged(newShownMonth)
  }

  function correctDateRangeAndHideCalendar() {
    setIsCalendarsVisible(false)

    if (isPresent(dateFrom) && isPresent(dateTo)) {
      checkAndChangeDateRange({
        dateFrom,
        dateTo
      })
    } else if (isPresent(dateFrom) && isBlank(dateTo)) {
      const newDateRange: DateRange = {
        dateFrom,
        dateTo: dateFrom
      }

      setDateRange(newDateRange)
      checkAndChangeDateRange(newDateRange)
    } else if (isBlank(dateFrom) && isPresent(dateTo)) {
      const newDateRange: DateRange = {
        dateFrom: dateTo,
        dateTo
      }

      setDateRange(newDateRange)
      checkAndChangeDateRange(newDateRange)
    } else {
      checkAndChangeDateRange(null)
    }
  }

  function checkAndChangeDateRange(newDateRange: DateRange | null) {
    const isDateFromNotChanged = defaultDateRange?.dateFrom.getTime() === newDateRange?.dateFrom.getTime()
    const isDateToNotChanged = defaultDateRange?.dateTo.getTime() === newDateRange?.dateTo.getTime()
    if (isDateFromNotChanged && isDateToNotChanged) return

    onDateRangeChanged(newDateRange)
  }

  function handleOnInputChange() {
    if (dateFromInputMaskRef.current!.masked.isComplete && dateToInputMaskRef.current!.masked.isComplete) return

    if (!isCalendarsVisible) {
      setIsCalendarsVisible(true)
    }
  }

  return (
    <div className={styles.root}>
      <div className={styles.inputsContainer}>
        <div className={styles.inputContainer}>
          <input
            className={styles.input}
            placeholder={selectButtonText}
            ref={dateFromInputRef}
            disabled={disabled}
            onChange={handleOnInputChange}
            onClick={handleOnInputsClick}
          />
          <div className={`${styles.calendarIcon} ${isCalendarsVisible ? styles.active : styles.inactive}`} />
        </div>
        <div className={styles.inputContainer}>
          <input
            className={styles.input}
            placeholder={selectButtonText}
            ref={dateToInputRef}
            disabled={disabled}
            onChange={handleOnInputChange}
            onClick={handleOnInputsClick}
          />
          <div className={`${styles.calendarIcon} ${isCalendarsVisible ? styles.active : styles.inactive}`} />
        </div>
        <button className={styles.clearButton} onClick={handleClear}>
          {clearButtonText}
        </button>
      </div>
      <DropdownComponent
        isVisible={isCalendarsVisible}
        hideOnClickOutside={true}
        hasScroll={false}
        requestHide={handleDropdownRequestHide}
        needSetWidthByParent={false}
      >
        <CalendarDropdownComponent>
          <CalendarBorderComponent>
            <DateRangeCalendarComponent
              month={shownMonth}
              nextMonth={nextShownMonth}
              dateRange={dateRange ?? {}}
              onDateRangeChange={handleOnDateRangeChanged}
              weekDayNameByNumber={weekDayNameByNumber}
              monthNameByNumber={monthNameByNumber}
              onMonthChanged={handleMonthChange}
              renderDateDescription={renderDateDescription}
            />
          </CalendarBorderComponent>
        </CalendarDropdownComponent>
      </DropdownComponent>
    </div>
  )
}
