import { GetObjectsPageResult } from "../../../../sqadmin/features/objects/domain/results/GetObjectsPageResult"
import LastItemPaginationsMapper from "../../../core/data/mappers/LastItemPaginationsMapper"
import SortMapper from "../../../core/data/mappers/SortMapper"
import PagesMapper from "../../../core/data/mappers/PagesMapper"
import { LoadObjectResult } from "../../../../sqadmin/features/objects/domain/results/LoadObjectResult"
import GetObjectNetworkResult from "../../../core/data/results/GetObjectNetworkResult"
import { CreateObjectResult } from "../../../../sqadmin/features/objects/domain/results/CreateObjectResult"
import CreateObjectNetworkResult from "../../../core/data/results/CreateObjectNetworkResult"
import { UpdateObjectResult } from "../../../../sqadmin/features/objects/domain/results/UpdateObjectResult"
import UpdateObjectNetworkResult from "../../../core/data/results/UpdateObjectNetworkResult"
import { DestroyObjectResult } from "../../../../sqadmin/features/objects/domain/results/DestroyObjectResult"
import DestroyObjectNetworkResult from "../../../core/data/results/DestroyObjectNetworkResult"
import OrderProcessingStatusesRepository from "../domain/repositories/OrderProcessingStatusesRepository"
import { GetOrderProcessingStatusesParameters } from "../domain/use-cases/GetOrderProcessingStatusesUseCase"
import OrderProcessingStatus from "../../../core/domain/entities/order-processing-statuses/OrderProcessingStatus"
import OrderProcessingStatusError from "../../../core/domain/entities/order-processing-statuses/OrderProcessingStatusError"
import OrderProcessingStatusesNetworkSource, {
  GetOrderProcessingStatusesNetworkResult
} from "../../../core/data/sources/network/OrderProcessingStatusesNetworkSource"
import OrderProcessingStatusesMapper from "../../../core/data/mappers/OrderProcessingStatusesMapper"
import NetworkOrderProcessingStatus from "../../../core/data/entities/order-processing-statuses/NetworkOrderProcessingStatus"
import NetworkOrderProcessingStatusError
  from "../../../core/data/entities/order-processing-statuses/NetworkOrderProcessingStatusError"
import OrderProcessingStatusesFiltersMapper from "../../../core/data/mappers/OrderProcessingStatusesFiltersMapper"
import { UpdateOrderProcessingStatusParameters } from "../domain/use-cases/UpdateOrderProcessingStatusUseCase"

export default class DefaultOrderProcessingStatusesRepository implements OrderProcessingStatusesRepository {
  private readonly orderProcessingStatusesNetworkSource: OrderProcessingStatusesNetworkSource

  constructor(parameters: {
    readonly orderProcessingStatusesNetworkSource: OrderProcessingStatusesNetworkSource
  }) {
    this.orderProcessingStatusesNetworkSource = parameters.orderProcessingStatusesNetworkSource
  }

  async getOrderProcessingStatuses({
    filter,
    pagination,
    sort
  }: GetOrderProcessingStatusesParameters): Promise<GetObjectsPageResult<OrderProcessingStatus>> {
    const result: GetOrderProcessingStatusesNetworkResult = await this.orderProcessingStatusesNetworkSource
      .getOrderProcessingStatuses({
        filter: filter && new OrderProcessingStatusesFiltersMapper().mapDomainToNetwork({ filter }),
        pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({ pagination }),
        sort: sort && new SortMapper().mapDomainToNetwork({ sort })
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: {
            objects: result.data.orderProcessingStatuses!.map((orderProcessingStatus: OrderProcessingStatus) => {
              return new OrderProcessingStatusesMapper().mapNetworkToDomain({
                orderProcessingStatus: orderProcessingStatus
              })
            }),
            page: new PagesMapper().mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      default:
        return result
    }
  }

  async getOrderProcessingStatus({
    orderProcessingStatusId
  }: {
    readonly orderProcessingStatusId: number
  }): Promise<LoadObjectResult<OrderProcessingStatus>> {
    const result: GetObjectNetworkResult<NetworkOrderProcessingStatus> =
      await this.orderProcessingStatusesNetworkSource.getOrderProcessingStatus({
        orderProcessingStatusId: orderProcessingStatusId
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new OrderProcessingStatusesMapper().mapNetworkToDomain({
            orderProcessingStatus: result.data
          })
        }
      default:
        return result
    }
  }

  async createOrderProcessingStatus({
    orderProcessingStatus
  }: {
    readonly orderProcessingStatus: OrderProcessingStatus
  }): Promise<CreateObjectResult<OrderProcessingStatus, OrderProcessingStatusError>> {
    const result: CreateObjectNetworkResult<NetworkOrderProcessingStatus, NetworkOrderProcessingStatusError> =
      await this.orderProcessingStatusesNetworkSource.createOrderProcessingStatus({
        orderProcessingStatus: new OrderProcessingStatusesMapper().mapDomainToNetwork({
          orderProcessingStatus: orderProcessingStatus
        })
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new OrderProcessingStatusesMapper().mapNetworkToDomain({
            orderProcessingStatus: result.data
          })
        }
      default:
        return result
    }
  }

  async updateOrderProcessingStatus({
      orderProcessingStatusId,
      orderProcessingStatus
    }:
      UpdateOrderProcessingStatusParameters
  ): Promise<UpdateObjectResult<OrderProcessingStatus, OrderProcessingStatusError>> {
    const result: UpdateObjectNetworkResult<NetworkOrderProcessingStatus, NetworkOrderProcessingStatusError> =
      await this.orderProcessingStatusesNetworkSource.updateOrderProcessingStatus({
        orderProcessingStatusId,
        orderProcessingStatus: new OrderProcessingStatusesMapper().mapDomainToNetwork({
          orderProcessingStatus: orderProcessingStatus
        })
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new OrderProcessingStatusesMapper().mapNetworkToDomain({
            orderProcessingStatus: result.data
          })
        }
      default:
        return result
    }
  }

  async destroyOrderProcessingStatus({
    orderProcessingStatusId
  }: {
    readonly orderProcessingStatusId: number
  }): Promise<DestroyObjectResult<OrderProcessingStatusError>> {
    const result: DestroyObjectNetworkResult<NetworkOrderProcessingStatusError> =
      await this.orderProcessingStatusesNetworkSource.destroyOrderProcessingStatus({
        orderProcessingStatusId: orderProcessingStatusId
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: undefined
        }
      default:
        return result
    }
  }
}
