import { Expose } from "class-transformer"
import NetworkProductsRequestFilter from "./NetworkProductsRequestFilter"
import NetworkLastItemPagination from "../../../../core/data/entities/pagination/NetworkLastItemPagination"

export default class NetworkProductsRequestQuery {
  @Expose()
  readonly filter?: NetworkProductsRequestFilter | null

  @Expose()
  readonly pagination?: NetworkLastItemPagination | null

  @Expose()
  readonly sort?: string | null

  constructor(parameters: {
    readonly filter?: NetworkProductsRequestFilter | null
    readonly pagination?: NetworkLastItemPagination | null
    readonly sort?: string | null
  }) {
    this.filter = parameters.filter
    this.pagination = parameters.pagination
    this.sort = parameters.sort
  }
}
