import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import { useCoreTextProvider } from "../../../../../core/presentation/contexts/CoreTextProviderContext"
import { useNumberObjectId } from "../../../../../../sqadmin/features/objects/presentation/hooks/useObjectId"
import { useViewModel } from "../../../../../../sqadmin/lib/view-model/ViewModel"
import ObjectComponent from "../../../../../../sqadmin/features/objects/presentation/components/object/ObjectComponent"
import React from "react"
import OrderPaymentMethodViewModel from "./OrderPaymentMethodViewModel"

export interface OrderPaymentMethodPageProps {
  readonly provideViewModel: (parameters: { readonly orderPaymentMethodId?: number }) => OrderPaymentMethodViewModel
}

export default function OrderPaymentMethodPage({
  provideViewModel
}: OrderPaymentMethodPageProps) {
  const coreTextProvider: CoreTextProvider = useCoreTextProvider()
  const orderPaymentMethodId: number | undefined = useNumberObjectId()

  const viewModel: OrderPaymentMethodViewModel = useViewModel(() => provideViewModel({
    orderPaymentMethodId: orderPaymentMethodId
  }))

  return (
    <ObjectComponent
      newObjectTitle={coreTextProvider.newOrderPaymentMethod()}
      existedObjectTitle={coreTextProvider.orderPaymentMethod()}
      observableObjectViewState={viewModel.observableObjectViewState}
      onObjectViewEvent={viewModel.onViewObjectEvent}
    />
  )
}
