import { Expose } from "class-transformer"
import NetworkOrderPaymentMethod from "../../../../core/data/entities/order-payment-methods/NetworkOrderPaymentMethod"

export default class NetworkOrderPaymentMethodRequestBody {
  @Expose()
  readonly orderPaymentMethod: NetworkOrderPaymentMethod

  constructor(parameters: {
    readonly orderPaymentMethod: NetworkOrderPaymentMethod
  }) {
    this.orderPaymentMethod = parameters.orderPaymentMethod
  }
}
