import ProductCategoryImagesRepository from "../../domain/repositories/ProductCategoryImagesRepository"
import { CreateObjectResult } from "../../../../../sqadmin/features/objects/domain/results/CreateObjectResult"
import Image from "../../../../core/domain/entities/images/Image"
import ImageError from "../../../../core/domain/entities/images/ImageError"
import CreateObjectNetworkResult from "../../../../core/data/results/CreateObjectNetworkResult"
import NetworkImage from "../../../../core/data/entities/images/NetworkImage"
import NetworkImageError from "../../../../core/data/entities/images/NetworkImageError"
import ImagesMapper from "../../../../core/data/mappers/ImagesMapper"
import ProductCategoryImagesNetworkSource
  from "../sources/ProductCategoryImagesNetworkSource"

export default class DefaultProductCategoryImagesRepository implements ProductCategoryImagesRepository {
  private readonly productCategoryImagesNetworkSource: ProductCategoryImagesNetworkSource

  constructor(parameters: {
    readonly productCategoryImagesNetworkSource: ProductCategoryImagesNetworkSource
  }) {
    this.productCategoryImagesNetworkSource = parameters.productCategoryImagesNetworkSource
  }

  async createImage({
    file
  }: {
    readonly file: File
  }): Promise<CreateObjectResult<Image, ImageError>> {
    const result: CreateObjectNetworkResult<NetworkImage, NetworkImageError> =
      await this.productCategoryImagesNetworkSource.createImage({ file })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new ImagesMapper().mapNetworkToDomain({
            image: result.data
          })
        }
      default:
        return result
    }
  }

}
