import NetworkUser from "../entities/users/NetworkUser"
import User from "../../domain/entities/users/User"
import UserProfilesMapper from "./UserProfilesMapper"
import PhoneNumbersMapper from "./PhoneNumbersMapper"
import BonusProgramMembersMapper from "./BonusProgramMembersMapper"

export default class UsersMapper {
  mapNetworkToDomain({
    user
  }: {
    readonly user: NetworkUser
  }): User {
    return {
      id: user.id,
      profile: user.profile && new UserProfilesMapper()
        .mapNetworkToDomain({ userProfile: user.profile }),
      phoneNumber: user.phoneNumber && new PhoneNumbersMapper()
        .mapNetworkToDomain({ phoneNumber: user.phoneNumber }),
      bonusProgramMember: user.bonusProgramMember && new BonusProgramMembersMapper()
        .mapNetworkToDomain({ bonusProgramMember: user.bonusProgramMember }),
      emailAddress: user.emailAddress,
      roleType: user.roleType,
      passwordConfirmation: user.passwordConfirmation,
      password: user.password,
      isAdminEmailNotificationsEnabled: user.isAdminEmailNotificationsEnabled
    }
  }

  mapDomainToNetwork({
    user
  }: {
    readonly user: User
  }): NetworkUser {
    return new NetworkUser({
      id: user.id,
      profile: user.profile && new UserProfilesMapper()
        .mapDomainToNetwork({ userProfile: user.profile }),
      phoneNumber: user.phoneNumber && new PhoneNumbersMapper()
        .mapDomainToNetwork({ phoneNumber: user.phoneNumber }),
      bonusProgramMember: user.bonusProgramMember && new BonusProgramMembersMapper()
        .mapDomainToNetwork({ bonusProgramMember: user.bonusProgramMember }),
      emailAddress: user.emailAddress,
      roleType: user.roleType,
      passwordConfirmation: user.passwordConfirmation,
      password: user.password,
      isAdminEmailNotificationsEnabled: user.isAdminEmailNotificationsEnabled
    })
  }
}
