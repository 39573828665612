import { Expose, Type } from "class-transformer"
import VariantOptionErrorsObject from "../../../domain/entities/variant-options/VariantOptionErrorsObject"
import NetworkVariantOptionErrorsObjectAttributes from "./NetworkVariantOptionErrorsObjectAttributes"

export default class NetworkVariantOptionErrorsObject implements VariantOptionErrorsObject {
  @Expose()
  readonly clientId: string | null | undefined

  @Expose()
  @Type(() => NetworkVariantOptionErrorsObjectAttributes)
  readonly attributes: NetworkVariantOptionErrorsObjectAttributes | null | undefined
}
