import React from "react"
import styles from "./HeadingComponent.module.scss"

export default function HeadingComponent({
  children
}: {
  readonly children?: React.ReactNode
}) {
  return <h1 className={styles.root}>{children}</h1>
}
