import { CreateObjectResult } from "../../../../../sqadmin/features/objects/domain/results/CreateObjectResult"
import BonusPointsBurningRulesRepository from "../repositories/BonusPointsBurningRulesRepository"
import BonusPointsBurningRule from "../../../../core/domain/entities/bonus-points-burning-rules/BonusPointsBurningRule"
import BonusPointsBurningRuleError
  from "../../../../core/domain/entities/bonus-points-burning-rules/BonusPointsBurningRuleError"

export default class CreateOrUpdateBonusPointsBurningRuleUseCase {
  private readonly bonusPointsBurningRulesRepository: BonusPointsBurningRulesRepository

  constructor(parameters: {
    readonly bonusPointsBurningRulesRepository: BonusPointsBurningRulesRepository
  }) {
    this.bonusPointsBurningRulesRepository = parameters.bonusPointsBurningRulesRepository
  }

  async call({
    bonusPointsBurningRule
  }: {
    readonly bonusPointsBurningRule: BonusPointsBurningRule
  }): Promise<CreateObjectResult<BonusPointsBurningRule, BonusPointsBurningRuleError>> {
    return await this.bonusPointsBurningRulesRepository.createOrUpdateBonusPointsBurningRule({ bonusPointsBurningRule })
  }
}
