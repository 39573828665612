import { v4 as uuidv4 } from "uuid"
import NetworkPropertyValue from "../entities/property-values/NetworkPropertyValue"
import PropertyValue from "../../domain/entities/property-values/PropertyValue"

export default class PropertyValuesMapper {
  mapNetworkToDomain({
    value
  }: {
    readonly value: NetworkPropertyValue
  }): PropertyValue {
    return {
      id: value.id,
      clientId: uuidv4(),
      name: value.name,
      position: value.position,
      externalCode: value.externalCode
    }
  }

  mapDomainToNetwork({
    value
  }: {
    readonly value: PropertyValue
  }): NetworkPropertyValue {
    return new NetworkPropertyValue({
      id: value.id,
      clientId: value.clientId,
      name: value.name,
      position: value.position,
      externalCode: value.externalCode
    })
  }
}
