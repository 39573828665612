import TableValue from "../TableValue"

export default class TextTableValue extends TableValue {
  private readonly value: string | null | undefined

  constructor(parameters: {
    readonly url?: string | null
    readonly value: string | null | undefined
  }) {
    super(parameters)
    this.value = parameters.value
  }

  getValue() {
    return this.value
  }
}
