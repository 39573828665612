import ObjectsRepository from "../../repositories/ObjectsRepository"
import { ObjectsEventCallback } from "../../entities/ObjectsEvent"

export default class SubscribeToObjectsEventsUseCase {
  private readonly objectsRepository: ObjectsRepository

  constructor(parameters: {
    readonly objectsRepository: ObjectsRepository
  }) {
    this.objectsRepository = parameters.objectsRepository
  }

  call(callback: ObjectsEventCallback): ObjectsEventCallback {
    return this.objectsRepository.subscribeToObjectsEvents(callback)
  }
}
