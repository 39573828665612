import React from "react"
import { NumberFormFieldViewState } from "../../../entities/form-fields/form-field-by-type/NumberFormField"
import {
  TextInputComponent,
  TextInputStyle,
  TextInputType
} from "../../../../../../design/text-input/TextInputComponent"
import isPresent from "../../../../../../lib/isPresent"
import styles from "./NumberFormFieldComponent.module.scss"
import TextComponent, { TextStyle } from "../../../../../../design/text/TextComponent"

export default function NumberFormFieldComponent({
  fieldViewState,
  isDisabled
}: {
  readonly fieldViewState: NumberFormFieldViewState
  readonly isDisabled: boolean
}) {
  function handleOnChange(value: string) {
    const valueToNumber: number | null = (() => {
      try {
        const convertedValue = parseInt(value)

        if (Number.isNaN(convertedValue)) return null

        return convertedValue < 0 ? 0 : convertedValue
      } catch {
        return null
      }
    })()

    fieldViewState.onChange(valueToNumber)
  }

  return (
    <div className={styles.root}>
      {
        fieldViewState.getTitle() && (
          <div className={styles.title}>
            <TextComponent textStyle={TextStyle.LABEL1_PRIMARY}>{fieldViewState.getTitle()}</TextComponent>
          </div>
        )
      }
      <TextInputComponent
        type={TextInputType.NUMBER}
        value={fieldViewState.value?.toFixed() ?? ""}
        isDisabled={isDisabled}
        onChange={handleOnChange}
        textInputStyle={TextInputStyle.TEXT_INPUT1_PRIMARY}
        hasError={isPresent(fieldViewState.getErrors())}
        placeholder={null}
      />
    </div>
  )
}
