import { v4 as uuidv4 } from "uuid"
import OrderReceivingPriceRule from "../../domain/entities/order-receiving-price-rules/OrderReceivingPriceRule"
import NetworkOrderReceivingPriceRule from "../entities/order-receiving-price-rules/NetworkOrderReceivingPriceRule"
import OrderReceivingPriceRuleConditionsMapper from "./OrderReceivingPriceRuleConditionsMapper"
import CalculatorsMapper from "./CalculatorsMapper"
import Calculator from "../../domain/entities/calculators/Calculator"
import CalculationType from "../../domain/entities/calculators/CalculationType"
import OrderReceivingPriceRuleCalculationType
  from "../../domain/entities/order-receiving-price-rules/OrderReceivingPriceRuleCalculationType"

export default class OrderReceivingPriceRulesMapper {
  mapNetworkToDomain({
    orderReceivingPriceRule
  }: {
    readonly orderReceivingPriceRule: NetworkOrderReceivingPriceRule
  }): OrderReceivingPriceRule {
    return {
      id: orderReceivingPriceRule.id,
      clientId: uuidv4(),
      conditions: orderReceivingPriceRule.conditions && orderReceivingPriceRule.conditions.map(condition => {
        return new OrderReceivingPriceRuleConditionsMapper().mapNetworkToDomain({ condition })
      }),
      position: orderReceivingPriceRule.position,
      calculationType: orderReceivingPriceRule.calculationType,
      calculator: orderReceivingPriceRule.calculator && new CalculatorsMapper().mapNetworkToDomain({
        calculator: orderReceivingPriceRule.calculator
      })
    }
  }

  mapDomainToNetwork({
    orderReceivingPriceRule
  }: {
    readonly orderReceivingPriceRule: OrderReceivingPriceRule
  }): NetworkOrderReceivingPriceRule {
    const isCalculator = orderReceivingPriceRule.calculationType === OrderReceivingPriceRuleCalculationType.CALCULATOR
    const calculator = isCalculator ? (orderReceivingPriceRule.calculator ?? this.createCalculator()) : undefined

    return new NetworkOrderReceivingPriceRule({
      id: orderReceivingPriceRule.id,
      clientId: orderReceivingPriceRule.clientId,
      conditions: orderReceivingPriceRule.conditions && orderReceivingPriceRule.conditions.map(condition => {
        return new OrderReceivingPriceRuleConditionsMapper().mapDomainToNetwork({ condition })
      }),
      position: orderReceivingPriceRule.position,
      calculationType: orderReceivingPriceRule.calculationType,
      calculator: calculator && new CalculatorsMapper().mapDomainToNetwork({ calculator })
    })
  }

  private createCalculator(): Calculator {
    return {
      calculationType: CalculationType.FIXED,
      id: undefined,
      value: undefined,
      roundingDecimalsCount: 2
    }
  }
}
