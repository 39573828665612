import styles from "./PositionControllerComponent.module.scss"
import React from "react"
import { ObjectFieldViewState } from "../../../entities/form-fields/form-field-by-type/ListFormField"

export default function PositionControllerComponent({
  objectState,
  objectStates,
  index,
  onPositionSwitched
}: {
  readonly objectState: ObjectFieldViewState<unknown>
  readonly objectStates: ObjectFieldViewState<unknown>[]
  readonly index: number
  readonly onPositionSwitched: (firstId: string, secondId: string) => void
}) {
  const nextObjectState: ObjectFieldViewState<unknown> | null | undefined = objectStates[index + 1]
  const previousObjectState: ObjectFieldViewState<unknown> | null | undefined = objectStates[index - 1]
  const lastIndex = objectStates.length - 1

  return (
    <div className={styles.root}>
      <div
        className={[
          styles.positionController,
          index === 0 ? styles.disabled : ""
        ].join(" ")}
        onClick={() => onPositionSwitched(objectState.id, previousObjectState?.id)}
      >&#8593;</div>
      <div
        className={[
          styles.positionController,
          index === lastIndex ? styles.disabled : ""
        ].join(" ")}
        onClick={() => onPositionSwitched(objectState.id, nextObjectState?.id)}
      >&#8595;</div>
    </div>
  )
}
