import DashboardsRepository from "../../../../dashboards/domain/repositories/DashboardsRepository"
import { DestroyObjectResult } from "../../../../../../sqadmin/features/objects/domain/results/DestroyObjectResult"
import DashboardError from "../../../../../core/domain/entities/dashboards/DashboardError"

export default class DestroyDashboardUseCase {
  private readonly dashboardsRepository: DashboardsRepository

  constructor(parameters: {
    readonly dashboardsRepository: DashboardsRepository
  }) {
    this.dashboardsRepository = parameters.dashboardsRepository
  }

  async call(parameters: {
    readonly dashboardId: number
  }): Promise<DestroyObjectResult<DashboardError>> {
    return await this.dashboardsRepository.destroyDashboard(parameters)
  }
}
