import OrderProcessingStatusesRepository from "../domain/repositories/OrderProcessingStatusesRepository"
import OrderProcessingStatusesNetworkSource, {
  GetOrderProcessingStatusesNetworkResult
} from "../../../core/data/sources/network/OrderProcessingStatusesNetworkSource"
import OrderProcessingStatus from "../../../core/domain/entities/order-processing-statuses/OrderProcessingStatus"
import { GetObjectsPageResult } from "../../../../sqadmin/features/objects/domain/results/GetObjectsPageResult"
import PagesMapper from "../../../core/data/mappers/PagesMapper"
import OrderProcessingStatusesMapper from "../../../core/data/mappers/OrderProcessingStatusesMapper"
import SortMapper from "../../../core/data/mappers/SortMapper"
import LastItemPaginationsMapper from "../../../core/data/mappers/LastItemPaginationsMapper"
import OrderProcessingStatusesFiltersMapper from "../../../core/data/mappers/OrderProcessingStatusesFiltersMapper"
import { GetOrderProcessingStatusesParameters } from "../domain/use-cases/order-processing-statuses/GetOrderProcessingStatusesUseCase"

export default class DefaultOrderProcessingStatusesRepository implements OrderProcessingStatusesRepository {
  private readonly orderProcessingStatusesNetworkSource: OrderProcessingStatusesNetworkSource

  constructor(parameters: {
    readonly orderProcessingStatusesNetworkSource: OrderProcessingStatusesNetworkSource
  }) {
    this.orderProcessingStatusesNetworkSource = parameters.orderProcessingStatusesNetworkSource
  }

  async getOrderProcessingStatuses({
    filter,
    pagination,
    sort
  }: GetOrderProcessingStatusesParameters): Promise<GetObjectsPageResult<OrderProcessingStatus>> {
    const result: GetOrderProcessingStatusesNetworkResult = await this.orderProcessingStatusesNetworkSource
      .getOrderProcessingStatuses({
        filter: filter && new OrderProcessingStatusesFiltersMapper().mapDomainToNetwork({ filter }),
        pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({ pagination }),
        sort: sort && new SortMapper().mapDomainToNetwork({ sort })
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: {
            objects: result.data.orderProcessingStatuses!.map((orderProcessingStatus: OrderProcessingStatus) => {
              return new OrderProcessingStatusesMapper().mapNetworkToDomain({
                orderProcessingStatus: orderProcessingStatus
              })
            }),
            page: new PagesMapper().mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      default:
        return result
    }
  }
}
