import ProductVariantErrorsObject from "../../../domain/entities/product-variants/ProductVariantErrorsObject"
import NetworkProductVariantErrorsObjectAttributes from "./NetworkProductVariantErrorsObjectAttributes"
import { Expose, Type } from "class-transformer"
import NetworkVariantOptionErrorsObject from "../variant-options/NetworkVariantOptionErrorsObject"
import NetworkVariantImageErrorsObject from "../variant-images/NetworkVariantImageErrorsObject"
import NetworkProductErrorsObject from "../products/NetworkProductErrorsObject"
import NetworkBatchErrorsObject from "../batches/NetworkBatchErrorsObject"

export default class NetworkProductVariantErrorsObject implements ProductVariantErrorsObject {
  @Expose()
  @Type(() => NetworkProductVariantErrorsObjectAttributes)
  readonly attributes?: NetworkProductVariantErrorsObjectAttributes | null

  @Expose({ name: "variantOptionValues" })
  @Type(() => NetworkVariantOptionErrorsObject)
  readonly variantOptions?: NetworkVariantOptionErrorsObject[] | null

  @Expose()
  @Type(() => NetworkVariantImageErrorsObject)
  readonly variantImages?: NetworkVariantImageErrorsObject[] | null

  @Expose()
  @Type(() => NetworkBatchErrorsObject)
  readonly mainBatch: NetworkBatchErrorsObject | null | undefined

  @Expose()
  @Type(() => NetworkBatchErrorsObject)
  readonly batches: NetworkBatchErrorsObject[] | null | undefined

  @Expose()
  readonly clientId: string | null | undefined

  @Expose()
  @Type(() => NetworkProductErrorsObject)
  readonly product: NetworkProductErrorsObject | null | undefined
}
