import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import { useCoreTextProvider } from "../../../../../core/presentation/contexts/CoreTextProviderContext"
import { useNumberObjectId } from "../../../../../../sqadmin/features/objects/presentation/hooks/useObjectId"
import { useViewModel } from "../../../../../../sqadmin/lib/view-model/ViewModel"
import ObjectComponent from "../../../../../../sqadmin/features/objects/presentation/components/object/ObjectComponent"
import React from "react"
import DiscountRuleViewModel from "./DiscountRuleViewModel"

export interface DiscountRulePageProps {
  readonly provideViewModel: (parameters: { readonly discountRuleId?: number }) => DiscountRuleViewModel
}

export default function DiscountRulePage({
  provideViewModel
}: DiscountRulePageProps) {
  const coreTextProvider: CoreTextProvider = useCoreTextProvider()
  const discountRuleId: number | undefined = useNumberObjectId()

  const viewModel: DiscountRuleViewModel = useViewModel(() => provideViewModel({
    discountRuleId
  }))

  return (
    <ObjectComponent
      newObjectTitle={coreTextProvider.newDiscountRule()}
      existedObjectTitle={coreTextProvider.discountRule()}
      observableObjectViewState={viewModel.observableObjectViewState}
      onObjectViewEvent={viewModel.onViewObjectEvent}
    />
  )
}
