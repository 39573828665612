import { DestroyObjectResult } from "../../../../../sqadmin/features/objects/domain/results/DestroyObjectResult"
import OrderPaymentMethodsRepository from "../repositories/OrderPaymentMethodsRepository"
import OrderPaymentMethodError from "../../../../core/domain/entities/order-payment-methods/OrderPaymentMethodError"

export default class DestroyOrderPaymentMethodUseCase {
  private readonly orderPaymentMethodsRepository: OrderPaymentMethodsRepository

  constructor(parameters: {
    readonly orderPaymentMethodsRepository: OrderPaymentMethodsRepository
  }) {
    this.orderPaymentMethodsRepository = parameters.orderPaymentMethodsRepository
  }

  async call(parameters: {
    readonly orderPaymentMethodId: number
  }): Promise<DestroyObjectResult<OrderPaymentMethodError>> {
    return await this.orderPaymentMethodsRepository.destroyOrderPaymentMethod(parameters)
  }
}
