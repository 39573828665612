import React from "react"
import { MultiSelectFormFieldViewState } from "../../../entities/form-fields/form-field-by-type/MultiSelectFormField"
import AppMultiSelectPickerComponent
  from "../../../../../../core/presentation/components/app-multi-select-picker/AppMultiSelectPickerComponent"
import styles from "./MultiSelectFormFieldComponent.module.scss"
import TextComponent, { TextStyle } from "../../../../../../design/text/TextComponent"

export default function MultiSelectFormFieldComponent({
  fieldViewState,
  isDisabled
}: {
  readonly fieldViewState: MultiSelectFormFieldViewState<unknown>
  readonly isDisabled: boolean
}) {
  const {
    selectedOptions,
    options,
    isLoading,
    query,
    page,
    onOpened,
    onSelect,
    onQueryChanged,
    onSearchRequested,
    onNextPageRequested,
    onSelectedItemClicked
  } = fieldViewState

  return (
    <div className={styles.root}>
      {
        fieldViewState.getTitle() && (
          <div className={styles.title}>
            <TextComponent textStyle={TextStyle.LABEL1_PRIMARY}>{fieldViewState.getTitle()}</TextComponent>
          </div>
        )
      }
      <AppMultiSelectPickerComponent
        selectedOptions={selectedOptions}
        options={options}
        disabled={isDisabled}
        isLoading={isLoading}
        query={query}
        page={page}
        onSelect={onSelect}
        onSelectedItemClicked={onSelectedItemClicked}
        onOpened={onOpened}
        onQueryChanged={onQueryChanged}
        onSearchRequested={onSearchRequested}
        onNextPageRequested={onNextPageRequested}
      />
    </div>
  )
}
