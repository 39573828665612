import ProductCategoryError from "../../../../../core/domain/entities/product-categories/ProductCategoryError"
import ProductCategoriesRepository from "../../repositories/ProductCategoriesRepository"
import ProductCategory from "../../../../../core/domain/entities/product-categories/ProductCategory"
import { CreateObjectResult } from "../../../../../../sqadmin/features/objects/domain/results/CreateObjectResult"

export default class CreateProductCategoryUseCase {
  private readonly productCategoriesRepository: ProductCategoriesRepository

  constructor(parameters: {
    readonly productCategoriesRepository: ProductCategoriesRepository
  }) {
    this.productCategoriesRepository = parameters.productCategoriesRepository
  }

  async call(parameters: {
    readonly productCategory: ProductCategory
  }): Promise<CreateObjectResult<ProductCategory, ProductCategoryError>> {
    return await this.productCategoriesRepository.createProductCategory(parameters)
  }
}
