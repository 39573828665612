import { RouteObject } from "react-router-dom"
import { objectIdUrlPart } from "../../../../sqadmin/features/objects/presentation/hooks/useObjectId"
import CoreUrlProvider from "../../../core/presentation/services/CoreUrlProvider"
import OrderProcessingStatusesDi from "../di/OrderProcessingStatusesDi"

export default function createOrderProcessingStatusesRoutes({
  di
}: {
  readonly di: OrderProcessingStatusesDi
}): RouteObject[] {
  const urlProvider = new CoreUrlProvider()
  return [
    {
      path: urlProvider.buildOrderProcessingStatusesPath(),
      element: di
        .sqCoreOrderProcessingStatusesDiComponent
        .orderProcessingStatusesPresentationDiModule
        .provideOrderProcessingStatusesPage()
    },
    {
      path: urlProvider.buildNewOrderProcessingStatusPath(),
      element: di
        .sqCoreOrderProcessingStatusesDiComponent
        .orderProcessingStatusesPresentationDiModule
        .provideOrderProcessingStatusPage()
    },
    {
      path: urlProvider.buildOrderProcessingStatusPath({ id: `:${objectIdUrlPart}` }),
      element: di
        .sqCoreOrderProcessingStatusesDiComponent
        .orderProcessingStatusesPresentationDiModule
        .provideOrderProcessingStatusPage()
    }
  ]
}
