import { DestroyObjectResult } from "../../../../../sqadmin/features/objects/domain/results/DestroyObjectResult"
import PlaceError from "../../../../core/domain/entities/places/PlaceError"
import PlacesRepository from "../repositories/PlacesRepository"

export default class DestroyPlaceUseCase {
  private readonly placesRepository: PlacesRepository

  constructor(parameters: {
    readonly placesRepository: PlacesRepository
  }) {
    this.placesRepository = parameters.placesRepository
  }

  async call(parameters: {
    readonly placeId: number
  }): Promise<DestroyObjectResult<PlaceError>> {
    return await this.placesRepository.destroyPlace(parameters)
  }
}
