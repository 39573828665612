import NetworkBanner from "../entities/banners/NetworkBanner"
import Banner from "../../domain/entities/banners/Banner"
import LinksMapper from "./LinksMapper"
import { v4 as uuidv4 } from "uuid"
import ProductsSetsMapper from "./ProductsSetsMapper"
import ImagesMapper from "./ImagesMapper"
import DashboardsMapper from "./DashboardsMapper"

export default class BannersMapper {
  mapNetworkToDomain({
    banner
  }: {
    readonly banner: NetworkBanner
  }): Banner {
    return {
      id: banner.id,
      clientId: uuidv4(),
      position: banner.position,
      imageId: banner.imageId,
      image: banner.image && new ImagesMapper().mapNetworkToDomain({ image: banner.image }),
      link: banner.link && new LinksMapper().mapNetworkToDomain({ link: banner.link }),
      productsSet: banner.productsSet && new ProductsSetsMapper().mapNetworkToDomain({
        productsSet: banner.productsSet
      }),
      dataType: banner.dataType,
      dashboard: banner.dashboard && new DashboardsMapper().mapNetworkToDomain({ dashboard: banner.dashboard }),
      dashboardId: banner.dashboardId
    }
  }

  mapDomainToNetwork({
    banner
  }: {
    readonly banner: Banner
  }): NetworkBanner {
    return new NetworkBanner({
      id: banner.id,
      clientId: banner.clientId,
      position: banner.position,
      imageId: banner.imageId,
      image: banner.image && new ImagesMapper().mapDomainToNetwork({ image: banner.image }),
      link: banner.link && new LinksMapper().mapDomainToNetwork({ link: banner.link }),
      productsSet: banner.productsSet && new ProductsSetsMapper().mapDomainToNetwork({
        productsSet: banner.productsSet
      }),
      dataType: banner.dataType,
      dashboard: banner.dashboard && new DashboardsMapper().mapDomainToNetwork({ dashboard: banner.dashboard }),
      dashboardId: banner.dashboardId
    })
  }
}
