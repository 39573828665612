import BackendHttpClient, { BackendHttpClientResult } from "../../network/BackendHttpClient"
import NetworkLastItemPagination from "../../entities/pagination/NetworkLastItemPagination"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import { instanceToPlain, plainToInstance } from "class-transformer"
import NetworkExecutionError from "../../entities/errors/NetworkExecutionError"
import GetObjectNetworkResult from "../../results/GetObjectNetworkResult"
import UpdateObjectNetworkResult from "../../results/UpdateObjectNetworkResult"
import DestroyObjectNetworkResult from "../../results/DestroyObjectNetworkResult"
import SuccessExecutionResult from "../../../../../sqadmin/core/domain/results/SuccessExecutionResult"
import ErrorExecutionResult from "../../../../../sqadmin/core/domain/results/ErrorExecutionResult"
import FailureExecutionResult from "../../../../../sqadmin/core/domain/results/FailureExecutionResult"
import NetworkOrdersRequestFilter from "../../entities/orders/NetworkOrdersRequestFilter"
import NetworkOrdersRequestQuery from "../../entities/orders/NetworkOrdersRequestQuery"
import NetworkOrdersResponseBody from "../../entities/orders/NetworkOrdersResponseBody"
import NetworkOrderResponseBody from "../../entities/orders/NetworkOrderResponseBody"
import NetworkOrderRequestBody from "../../entities/orders/NetworkOrderRequestBody"
import NetworkOrder from "../../entities/orders/NetworkOrder"
import NetworkOrderError from "../../entities/orders/NetworkOrderError"

const basePath = "/admin/orders"

export default class OrdersNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async getOrders({
    filter,
    pagination,
    sort
  }: {
    readonly filter?: NetworkOrdersRequestFilter | null
    readonly pagination?: NetworkLastItemPagination | null
    readonly sort?: string | null
  }): Promise<GetOrdersNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: basePath,
      parameters: instanceToPlain(new NetworkOrdersRequestQuery({
        filter,
        pagination,
        sort
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkOrdersResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async getOrder({
    orderId
  }: {
    readonly orderId: number
  }): Promise<GetObjectNetworkResult<NetworkOrder>> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}/${orderId}`
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkOrderResponseBody, result.body).order!
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async updateOrder({
    orderId,
    order
  }: {
    readonly orderId: number
    readonly order: NetworkOrder
  }): Promise<UpdateObjectNetworkResult<NetworkOrder, NetworkOrderError>> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.PUT,
      path: `${basePath}/${orderId}`,
      body: instanceToPlain(new NetworkOrderRequestBody({
        order
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkOrderResponseBody, result.body).order!
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkOrderError, result.body)
        }
      case "failure":
        return result
    }
  }

  async destroyOrder({
    orderId
  }: {
    readonly orderId: number
  }): Promise<DestroyObjectNetworkResult<NetworkOrderError>> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.DELETE,
      path: `${basePath}/${orderId}`
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: undefined
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkOrderError, result.body)
        }
      case "failure":
        return result
    }
  }
}

export type GetOrdersNetworkResult =
  SuccessExecutionResult<NetworkOrdersResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult
