import CreateSessionUserPartial from "../../entities/CreateSessionUserPartial"
import SessionsRepository from "../../repositories/SessionsRepository"
import SuccessExecutionResult from "../../results/SuccessExecutionResult"
import ErrorExecutionResult from "../../results/ErrorExecutionResult"
import ExecutionError from "../../entities/ExecutionError"
import FailureExecutionResult from "../../results/FailureExecutionResult"
import CreateSessionErrorsObject from "../../entities/CreateSessionErrorsObject"

export default class CreateSessionUseCase {
  private readonly sessionsRepository: SessionsRepository

  constructor(parameters: {
    readonly sessionsRepository: SessionsRepository
  }) {
    this.sessionsRepository = parameters.sessionsRepository
  }

  async call(parameters: {
    readonly user: CreateSessionUserPartial
  }): Promise<CreateSessionResult> {
    return await this.sessionsRepository.createSession(parameters)
  }
}

export interface CreateSessionError extends ExecutionError {
  readonly errorsObject?: CreateSessionErrorsObject | null
}

export type CreateSessionResult =
  SuccessExecutionResult |
  ErrorExecutionResult<CreateSessionError> |
  FailureExecutionResult
