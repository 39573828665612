import PaymentStatusesRepository from "../../domain/repositories/PaymentStatusesRepository"
import { GetObjectsPageResult } from "../../../../../sqadmin/features/objects/domain/results/GetObjectsPageResult"
import { GetPaymentStatusesParameters } from "../../domain/use-cases/GetPaymentStatusesUseCase"
import PaymentStatus from "../../../../core/domain/entities/payment-statuses/PaymentStatus"
import LastItemPaginationsMapper from "../../../../core/data/mappers/LastItemPaginationsMapper"
import SortMapper from "../../../../core/data/mappers/SortMapper"
import PagesMapper from "../../../../core/data/mappers/PagesMapper"
import GetObjectNetworkResult from "../../../../core/data/results/GetObjectNetworkResult"
import { LoadObjectResult } from "../../../../../sqadmin/features/objects/domain/results/LoadObjectResult"
import NetworkPaymentStatus from "../../../../core/data/entities/payment-statuses/NetworkPaymentStatus"
import PaymentStatusError from "../../../../core/domain/entities/payment-statuses/PaymentStatusError"
import CreateObjectNetworkResult from "../../../../core/data/results/CreateObjectNetworkResult"
import { CreateObjectResult } from "../../../../../sqadmin/features/objects/domain/results/CreateObjectResult"
import NetworkPaymentStatusError from "../../../../core/data/entities/payment-statuses/NetworkPaymentStatusError"
import { UpdatePaymentStatusParameters } from "../../domain/use-cases/UpdatePaymentStatusUseCase"
import { UpdateObjectResult } from "../../../../../sqadmin/features/objects/domain/results/UpdateObjectResult"
import UpdateObjectNetworkResult from "../../../../core/data/results/UpdateObjectNetworkResult"
import { DestroyObjectResult } from "../../../../../sqadmin/features/objects/domain/results/DestroyObjectResult"
import DestroyObjectNetworkResult from "../../../../core/data/results/DestroyObjectNetworkResult"
import PaymentStatusesNetworkSource, { GetPaymentStatusesNetworkResult } from "../sources/PaymentStatusesNetworkSource"
import PaymentStatusesMapper from "../../../../core/data/mappers/PaymentStatusesMapper"
import PaymentStatusesFiltersMapper from "../mappers/PaymentStatusesFiltersMapper"

export default class DefaultPaymentStatusesRepository implements PaymentStatusesRepository {
  private readonly paymentStatusesNetworkSource: PaymentStatusesNetworkSource

  constructor(parameters: {
    readonly paymentStatusesNetworkSource: PaymentStatusesNetworkSource
  }) {
    this.paymentStatusesNetworkSource = parameters.paymentStatusesNetworkSource
  }

  async getPaymentStatuses({
    filter,
    pagination,
    sort
  }: GetPaymentStatusesParameters): Promise<GetObjectsPageResult<PaymentStatus>> {
    const result: GetPaymentStatusesNetworkResult = await this.paymentStatusesNetworkSource
      .getPaymentStatuses({
        filter: filter && new PaymentStatusesFiltersMapper().mapDomainToNetwork({ filter }),
        pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({ pagination }),
        sort: sort && new SortMapper().mapDomainToNetwork({ sort })
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: {
            objects: result.data.paymentStatuses!.map((paymentStatus: NetworkPaymentStatus) => {
              return new PaymentStatusesMapper().mapNetworkToDomain({
                paymentStatus: paymentStatus
              })
            }),
            page: new PagesMapper().mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      default:
        return result
    }
  }

  async getPaymentStatus({
    paymentStatusId
  }: {
    readonly paymentStatusId: number
  }): Promise<LoadObjectResult<PaymentStatus>> {
    const result: GetObjectNetworkResult<NetworkPaymentStatus> =
      await this.paymentStatusesNetworkSource.getPaymentStatus({
        paymentStatusId: paymentStatusId
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new PaymentStatusesMapper().mapNetworkToDomain({
            paymentStatus: result.data
          })
        }
      default:
        return result
    }
  }

  async createPaymentStatus({
    paymentStatus
  }: {
    readonly paymentStatus: PaymentStatus
  }): Promise<CreateObjectResult<PaymentStatus, PaymentStatusError>> {
    const result: CreateObjectNetworkResult<NetworkPaymentStatus, NetworkPaymentStatusError> =
      await this.paymentStatusesNetworkSource.createPaymentStatus({
        paymentStatus: new PaymentStatusesMapper().mapDomainToNetwork({
          paymentStatus: paymentStatus
        })
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new PaymentStatusesMapper().mapNetworkToDomain({
            paymentStatus: result.data
          })
        }
      default:
        return result
    }
  }

  async updatePaymentStatus({
      paymentStatusId,
      paymentStatus
    }:
      UpdatePaymentStatusParameters
  ): Promise<UpdateObjectResult<PaymentStatus, PaymentStatusError>> {
    const result: UpdateObjectNetworkResult<NetworkPaymentStatus, NetworkPaymentStatusError> =
      await this.paymentStatusesNetworkSource.updatePaymentStatus({
        paymentStatusId,
        paymentStatus: new PaymentStatusesMapper().mapDomainToNetwork({
          paymentStatus: paymentStatus
        })
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new PaymentStatusesMapper().mapNetworkToDomain({
            paymentStatus: result.data
          })
        }
      default:
        return result
    }
  }

  async destroyPaymentStatus({
    paymentStatusId
  }: {
    readonly paymentStatusId: number
  }): Promise<DestroyObjectResult<PaymentStatusError>> {
    const result: DestroyObjectNetworkResult<NetworkPaymentStatusError> =
      await this.paymentStatusesNetworkSource.destroyPaymentStatus({
        paymentStatusId: paymentStatusId
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: undefined
        }
      default:
        return result
    }
  }
}
