import React from "react"
import styles from "./MultiLineTextInputComponent.module.scss"
import assertNever from "../../lib/assertNever"

export default function MultiLineTextInputComponent({
  value,
  textStyle,
  isDisabled = false,
  hasError,
  onChange
}: {
  readonly value: string | null | undefined
  readonly isDisabled?: boolean
  readonly hasError: boolean
  readonly textStyle: MultiLineTextInputStyle
  readonly onChange: (value: string) => void
}) {
  function detectStyle(): string {
    switch (textStyle) {
      case MultiLineTextInputStyle.MULTI_LINE_TEXT1_PRIMARY:
        return `${styles.textInput1} ${styles.primary} ${hasError ? ` ${styles.error}` : ""}`
      default:
        assertNever(textStyle)
    }
  }

  function handleOnChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    onChange(event.target.value)
  }

  return (
    <div className={detectStyle()}>
      <textarea
        value={value ?? ""}
        disabled={isDisabled}
        onChange={handleOnChange}
      />
    </div>
  )
}

export enum MultiLineTextInputStyle {
  MULTI_LINE_TEXT1_PRIMARY
}
