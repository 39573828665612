import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../errors/NetworkAttributeError"
import PropertyErrorsObjectAttributes from "../../../domain/entities/properties/PropertyErrorsObjectAttributes"
import AttributeError from "../../../../../sqadmin/core/domain/entities/AttributeError"

export default class NetworkPropertyErrorsObjectAttributes implements PropertyErrorsObjectAttributes {
  @Expose()
  @Type(() => NetworkAttributeError)
  readonly shouldDisplayInProduct: AttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly shouldDisplayInFilters: AttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly name?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly externalCode?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly position?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly uom?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly values: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly dataType: NetworkAttributeError[] | null | undefined
}
