import DashboardsRepository from "../../repositories/DashboardsRepository"
import Dashboard from "../../../../../core/domain/entities/dashboards/Dashboard"
import { CreateObjectResult } from "../../../../../../sqadmin/features/objects/domain/results/CreateObjectResult"
import DashboardError from "../../../../../core/domain/entities/dashboards/DashboardError"

export default class CreateDashboardUseCase {
  private readonly dashboardsRepository: DashboardsRepository

  constructor(parameters: {
    readonly dashboardsRepository: DashboardsRepository
  }) {
    this.dashboardsRepository = parameters.dashboardsRepository
  }

  async call(parameters: {
    readonly dashboard: Dashboard
  }): Promise<CreateObjectResult<Dashboard, DashboardError>> {
    return await this.dashboardsRepository.createDashboard(parameters)
  }
}
