import UsersRepository from "../repositories/UsersRepository"
import { LoadObjectResult } from "../../../../../sqadmin/features/objects/domain/results/LoadObjectResult"
import User from "../../../../core/domain/entities/users/User"

export default class GetUserUseCase {
  private readonly usersRepository: UsersRepository

  constructor(parameters: {
    readonly usersRepository: UsersRepository
  }) {
    this.usersRepository = parameters.usersRepository
  }

  async call(parameters: { readonly userId: number }): Promise<LoadObjectResult<User>> {
    return await this.usersRepository.getUser(parameters)
  }
}
