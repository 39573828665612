import DiModule from "../../../../sqadmin/lib/di/DiModule"
import PaymentStatusesRepository from "../domain/repositories/PaymentStatusesRepository"
import DefaultPaymentStatusesRepository from "../data/repositories/DefaultPaymentStatusesRepository"
import CoreNetworkDiModule from "../../../core/di/modules/CoreNetworkDiModule"
import PaymentStatusesNetworkSource from "../data/sources/PaymentStatusesNetworkSource"

export default interface PaymentStatusesCoreDataDiModule {
  providePaymentStatusesRepository(): PaymentStatusesRepository
}

export class DefaultPaymentStatusesCoreDataDiModule extends DiModule implements PaymentStatusesCoreDataDiModule {
  private readonly sqCoreCoreNetworkDiModule: CoreNetworkDiModule

  constructor(parameters: {
    readonly sqCoreCoreNetworkDiModule: CoreNetworkDiModule
  }) {
    super()
    this.sqCoreCoreNetworkDiModule = parameters.sqCoreCoreNetworkDiModule
  }

  providePaymentStatusesRepository(): PaymentStatusesRepository {
    return this.single(
      DefaultPaymentStatusesRepository.name,
      () => new DefaultPaymentStatusesRepository({
        paymentStatusesNetworkSource: this.providePaymentStatusesNetworkSource()
      })
    )
  }

  private providePaymentStatusesNetworkSource(): PaymentStatusesNetworkSource {
    return this.single(
      PaymentStatusesNetworkSource.name,
      () => new PaymentStatusesNetworkSource({
        backendHttpClient: this.sqCoreCoreNetworkDiModule.provideBackendHttpClient()
      })
    )
  }
}
