import { Expose, Type } from "class-transformer"
import BatchErrorsObject from "../../../domain/entities/batch/BatchErrorsObject"
import NetworkBatchErrorsObjectAttributes from "./NetworkBatchErrorsObjectAttributes"
import NetworkBatchPlaceErrorsObject from "../batch-places/NetworkBatchPlaceErrorsObject"
import NetworkProductVariantErrorsObject from "../product-variants/NetworkProductVariantErrorsObject"

export default class NetworkBatchErrorsObject implements BatchErrorsObject {
  @Expose()
  @Type(() => NetworkBatchErrorsObjectAttributes)
  readonly attributes: NetworkBatchErrorsObjectAttributes | null | undefined

  @Expose()
  @Type(() => NetworkBatchPlaceErrorsObject)
  readonly batchPlaces: NetworkBatchPlaceErrorsObject[] | null | undefined

  @Expose({ name: "productVariant" })
  @Type(() => NetworkProductVariantErrorsObject)
  readonly variant: NetworkProductVariantErrorsObject | null | undefined

  @Expose()
  readonly clientId: string | null | undefined
}
