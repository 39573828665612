import { Expose, Type } from "class-transformer"
import NetworkSettlement from "../settlements/NetworkSettlement"
import NetworkGeoLocation from "../geo-locations/NetworkGeoLocation"

export default class NetworkPlace {
  @Expose()
  readonly id: number | null | undefined

  @Expose()
  readonly externalCode: string | null | undefined

  @Expose()
  readonly name: string | null | undefined

  @Expose()
  readonly description: string | null | undefined

  @Expose()
  readonly isPickupPoint: boolean | null | undefined

  @Expose()
  readonly settlementId: number | null | undefined

  @Expose()
  @Type(() => NetworkSettlement)
  readonly settlement: NetworkSettlement | null | undefined

  @Expose()
  @Type(() => NetworkGeoLocation)
  readonly geoLocation: NetworkGeoLocation | null | undefined

  @Expose()
  readonly code: string | null | undefined

  constructor(parameters?: {
    readonly id: number | null | undefined
    readonly name: string | null | undefined
    readonly externalCode: string | null | undefined
    readonly description: string | null | undefined
    readonly isPickupPoint: boolean | null | undefined
    readonly settlementId: number | null | undefined
    readonly settlement: NetworkSettlement | null | undefined
    readonly geoLocation: NetworkGeoLocation | null | undefined
    readonly code: string | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.id = parameters.id
    this.name = parameters.name
    this.externalCode = parameters.externalCode
    this.description = parameters.description
    this.isPickupPoint = parameters.isPickupPoint
    this.settlementId = parameters.settlementId
    this.settlement = parameters.settlement
    this.code = parameters.code
    this.geoLocation = parameters.geoLocation
  }
}
