import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import { useCoreTextProvider } from "../../../../../core/presentation/contexts/CoreTextProviderContext"
import { useNumberObjectId } from "../../../../../../sqadmin/features/objects/presentation/hooks/useObjectId"
import { useViewModel } from "../../../../../../sqadmin/lib/view-model/ViewModel"
import ObjectComponent from "../../../../../../sqadmin/features/objects/presentation/components/object/ObjectComponent"
import React from "react"
import PropertyViewModel from "./PropertyViewModel"

export interface PropertyPageProps {
  readonly provideViewModel: (parameters: { readonly propertyId?: number }) => PropertyViewModel
}

export default function PropertyPage({
  provideViewModel
}: PropertyPageProps) {
  const coreTextProvider: CoreTextProvider = useCoreTextProvider()
  const propertyId: number | undefined = useNumberObjectId()

  const viewModel: PropertyViewModel = useViewModel(() => provideViewModel({
    propertyId
  }))

  return (
    <ObjectComponent
      newObjectTitle={coreTextProvider.newProperty()}
      existedObjectTitle={coreTextProvider.property()}
      observableObjectViewState={viewModel.observableObjectViewState}
      onObjectViewEvent={viewModel.onViewObjectEvent}
    />
  )
}
