import { Expose, Type } from "class-transformer"
import BonusProgramLevelErrorsObject from "../../../domain/entities/bonus-program-levels/BonusProgramLevelErrorsObject"
import NetworkBonusProgramLevelErrorsObjectAttributes from "./NetworkBonusProgramLevelErrorsObjectAttributes"
import NetworkBonusProgramLevelTransitionRuleErrorsObject
  from "../bonus-program-level-transition-rules/NetworkBonusProgramLevelTransitionRuleErrorsObject"

export default class NetworkBonusProgramLevelErrorsObject implements BonusProgramLevelErrorsObject {

  @Expose()
  @Type(() => NetworkBonusProgramLevelErrorsObjectAttributes)
  readonly attributes: NetworkBonusProgramLevelErrorsObjectAttributes | null | undefined

  @Expose()
  @Type(() => NetworkBonusProgramLevelTransitionRuleErrorsObject)
  readonly transitionRule: NetworkBonusProgramLevelTransitionRuleErrorsObject | null | undefined
}
