import DiModule from "../../../../sqadmin/lib/di/DiModule"
import CoreNetworkDiModule from "./CoreNetworkDiModule"
import OptionsNetworkSource from "../../data/sources/network/OptionsNetworkSource"
import DashboardsNetworkSource from "../../data/sources/network/DashboardsNetworkSource"
import BannerImagesNetworkSource from "../../data/sources/network/BannerImagesNetworkSource"
import PlacesNetworkSource from "../../data/sources/network/PlacesNetworkSource"
import OrderProcessingStatusesNetworkSource from "../../data/sources/network/OrderProcessingStatusesNetworkSource"
import OrdersNetworkSource from "../../data/sources/network/OrdersNetworkSource"

export default interface CoreDataDiModule {

  provideOptionsNetworkSource(): OptionsNetworkSource

  provideDashboardsNetworkSource(): DashboardsNetworkSource

  provideBannerImagesNetworkSource(): BannerImagesNetworkSource

  providePlacesNetworkSource(): PlacesNetworkSource

  provideOrderProcessingStatusesNetworkSource(): OrderProcessingStatusesNetworkSource

  provideOrdersNetworkSource(): OrdersNetworkSource
}

export class DefaultCoreDataDiModule extends DiModule implements CoreDataDiModule {
  private readonly sqCoreCoreNetworkDiModule: CoreNetworkDiModule

  constructor(parameters: {
    readonly sqCoreCoreNetworkDiModule: CoreNetworkDiModule
  }) {
    super()
    this.sqCoreCoreNetworkDiModule = parameters.sqCoreCoreNetworkDiModule
  }

  providePlacesNetworkSource(): PlacesNetworkSource {
    return this.single(
      PlacesNetworkSource.name,
      () => new PlacesNetworkSource({
        backendHttpClient: this.sqCoreCoreNetworkDiModule.provideBackendHttpClient()
      })
    )
  }

  provideBannerImagesNetworkSource(): BannerImagesNetworkSource {
    return this.single(
      BannerImagesNetworkSource.name,
      () => new BannerImagesNetworkSource({
        backendHttpClient: this.sqCoreCoreNetworkDiModule.provideBackendHttpClient()
      })
    )
  }

  provideDashboardsNetworkSource(): DashboardsNetworkSource {
    return this.single(
      DashboardsNetworkSource.name,
      () => new DashboardsNetworkSource({
        backendHttpClient: this.sqCoreCoreNetworkDiModule.provideBackendHttpClient()
      })
    )
  }

  provideOptionsNetworkSource(): OptionsNetworkSource {
    return this.single(
      OptionsNetworkSource.name,
      () => new OptionsNetworkSource({
        backendHttpClient: this.sqCoreCoreNetworkDiModule.provideBackendHttpClient()
      })
    )
  }

  provideOrderProcessingStatusesNetworkSource(): OrderProcessingStatusesNetworkSource {
    return this.single(
      OrderProcessingStatusesNetworkSource.name,
      () => new OrderProcessingStatusesNetworkSource({
        backendHttpClient: this.sqCoreCoreNetworkDiModule.provideBackendHttpClient()
      })
    )
  }

  provideOrdersNetworkSource(): OrdersNetworkSource {
    return this.single(
      OrdersNetworkSource.name,
      () => new OrdersNetworkSource({
        backendHttpClient: this.sqCoreCoreNetworkDiModule.provideBackendHttpClient()
      })
    )
  }
}
