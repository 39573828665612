import AttributeError from "../../../../../core/domain/entities/AttributeError"
import FormFieldPlaceType from "./FormFieldPlaceType"
import isPresent from "../../../../../lib/isPresent"
import { v4 as uuidv4 } from "uuid"

export interface FormFieldParameters<DomainObject, ErrorsObject> {
  readonly getTitle?: (object: DomainObject) => string | null | undefined
  readonly getErrors: (errorsObject?: ErrorsObject, object?: DomainObject) => AttributeError[] | null | undefined
  readonly getVisible?: (object: DomainObject) => boolean
  readonly getDisabled?: (object: DomainObject) => boolean
  readonly getPlaceType?: () => FormFieldPlaceType | null | undefined
}

export default abstract class FormField<DomainObject, ErrorsObject> {
  protected setObject!: (object: DomainObject) => void
  protected setAndShowLoadedObjectViewState!: () => void
  protected id: string
  protected getTitle?: (object: DomainObject) => string | null | undefined
  protected getErrors: (errorsObject?: ErrorsObject, object?: DomainObject) => AttributeError[] | null | undefined
  protected getObjectId?: (object: DomainObject) => string
  protected getVisible: (object: DomainObject) => boolean
  protected getDisabled: (object: DomainObject) => boolean
  protected getPlaceType?: () => FormFieldPlaceType | null | undefined

  protected constructor(parameters: FormFieldParameters<DomainObject, ErrorsObject>) {
    this.id = uuidv4()
    this.getTitle = parameters.getTitle
    this.getErrors = parameters.getErrors
    this.getVisible = parameters.getVisible ?? (() => true)
    this.getDisabled = parameters.getDisabled ?? (() => false)
    this.getPlaceType = parameters.getPlaceType
  }

  abstract getViewState(object: DomainObject, errorsObject?: ErrorsObject): FormFieldViewState

  setSetObject(setObject: (object: DomainObject) => void) {
    this.setObject = setObject
  }

  setGetObjectId(getObjectId: (object: DomainObject) => string) {
    this.getObjectId = getObjectId
  }

  setSetAndShowLoadedObjectViewState(setAndShowLoadedObjectViewState: () => void) {
    this.setAndShowLoadedObjectViewState = setAndShowLoadedObjectViewState
  }

  getFormFieldViewStateParameters(object: DomainObject, errorsObject?: ErrorsObject): FormFieldViewStateParameters {
    return {
      id: this.id,
      title: this.getTitle && this.getTitle(object),
      errors: this.getErrors(errorsObject, object),
      visible: this.getVisible(object),
      disabled: this.getDisabled(object),
      placeType: this.getPlaceType?.()
    }
  }

  prepareObject(object: DomainObject): DomainObject {
    return object
  }

  getParentErrorsVisible(object: DomainObject, errorsObject?: ErrorsObject): boolean {
    return isPresent(this.getErrors(errorsObject, object)) && this.getPlaceType?.() === FormFieldPlaceType.ADDITIONAL
  }
}

export interface FormFieldViewStateParameters {
  readonly id: string
  readonly title: string | null | undefined
  readonly errors: AttributeError[] | null | undefined
  readonly visible: boolean
  readonly disabled: boolean
  readonly placeType: FormFieldPlaceType | null | undefined
}

export abstract class FormFieldViewState {
  private readonly id: string
  private readonly title: string | null | undefined
  private readonly errors: AttributeError[] | null | undefined
  private readonly visible: boolean
  private readonly disabled: boolean
  private readonly placeType: FormFieldPlaceType | null | undefined

  protected constructor(parameters: FormFieldViewStateParameters) {
    this.id = parameters.id
    this.title = parameters.title
    this.errors = parameters.errors
    this.visible = parameters.visible
    this.disabled = parameters.disabled
    this.placeType = parameters.placeType
  }

  getId(): string {
    return this.id
  }

  getTitle(): string | null | undefined {
    return this.title
  }

  getErrors(): AttributeError[] | null | undefined {
    return this.errors
  }

  isVisible() {
    return this.visible
  }

  isDisabled() {
    return this.disabled
  }

  getPlaceType() {
    return this.placeType
  }
}
