const minutesInHour = 60
const hoursCharactersCount = 2
const minutesCharactersCount = 2

export default function getTimeZone(date?: Date) {
  const offset = (date ?? new Date()).getTimezoneOffset()
  const absoluteOffset = Math.abs(offset)

  const sign = offset < 0 ? "+" : "-"
  const hoursComponent = Math.floor(absoluteOffset / minutesInHour).toString()
    .padStart(hoursCharactersCount, "0")

  const minutesComponent = (absoluteOffset % minutesInHour).toString()
    .padStart(minutesCharactersCount, "0")

  return `${sign}${hoursComponent}:${minutesComponent}`
}
