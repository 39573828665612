import NetworkPaymentStatusesRequestFilter from "../entities/NetworkPaymentStatusesRequestFilter"
import PaymentStatusesFilter from "../../../../core/domain/entities/payment-statuses/PaymentStatusesFilter"

export default class PaymentStatusesFiltersMapper {
  mapDomainToNetwork({
    filter
  }: {
    readonly filter: PaymentStatusesFilter
  }): NetworkPaymentStatusesRequestFilter {
    return {
      query: filter.query
    }
  }
}
