import { Expose } from "class-transformer"
import NetworkOrder from "./NetworkOrder"

export default class NetworkOrderRequestBody {
  @Expose()
  readonly order: NetworkOrder

  constructor(parameters: {
    readonly order: NetworkOrder
  }) {
    this.order = parameters.order
  }
}
