import { UpdateObjectResult } from "../../../../../sqadmin/features/objects/domain/results/UpdateObjectResult"
import DiscountRulesRepository from "../repositories/DiscountRulesRepository"
import DiscountRule from "../../../../core/domain/entities/discount-rules/DiscountRule"
import DiscountRuleError from "../../../../core/domain/entities/discount-rules/DiscountRuleError"

export default class UpdateDiscountRuleUseCase {
  private readonly discountRulesRepository: DiscountRulesRepository

  constructor(parameters: {
    readonly discountRulesRepository: DiscountRulesRepository
  }) {
    this.discountRulesRepository = parameters.discountRulesRepository
  }

  async call(
    parameters: UpdateDiscountRuleParameters
  ): Promise<UpdateObjectResult<DiscountRule, DiscountRuleError>> {
    return await this.discountRulesRepository.updateDiscountRule(parameters)
  }
}

export interface UpdateDiscountRuleParameters {
  readonly discountRuleId: number
  readonly discountRule: DiscountRule
}
