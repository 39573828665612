import React, { ReactElement } from "react"
import DiModule from "../../../../lib/di/DiModule"
import UiMenuItem from "../../../../core/presentation/entities/UiMenuItem"
import RootViewModel from "../../presentation/pages/root/RootViewModel"
import SqAdminCoreDomainDiModule from "../../../../core/di/modules/CoreDomainDiModule"
import RootPage from "../../presentation/pages/root/RootPage"

export default interface RootPresentationDiModule {
  provideRootPage(parameters: {
    readonly menuItems: UiMenuItem[]
  }): ReactElement
}

export class DefaultRootPresentationDiModule extends DiModule implements RootPresentationDiModule {
  private readonly coreDomainDiModule: SqAdminCoreDomainDiModule

  constructor(parameters: {
    readonly coreDomainDiModule: SqAdminCoreDomainDiModule
  }) {
    super()
    this.coreDomainDiModule = parameters.coreDomainDiModule
  }

  provideRootPage({
    menuItems
  }: {
    readonly menuItems: UiMenuItem[]
  }): ReactElement {
    return (
      <RootPage provideViewModel={() => this.provideRootViewModel({ menuItems })} />
    )
  }

  private provideRootViewModel({
    menuItems
  }: {
    readonly menuItems: UiMenuItem[]
  }): RootViewModel {
    return new RootViewModel({
      isSessionExistUseCase: this.coreDomainDiModule.provideIsSessionExistUseCase(),
      destroySessionUseCase: this.coreDomainDiModule.provideDestroySessionUseCase(),
      menuItems
    })
  }
}
