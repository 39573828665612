import BackendHttpClient, { BackendHttpClientResult } from "../../../../core/data/network/BackendHttpClient"
import NetworkLastItemPagination from "../../../../core/data/entities/pagination/NetworkLastItemPagination"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import { instanceToPlain, plainToInstance } from "class-transformer"
import NetworkExecutionError from "../../../../core/data/entities/errors/NetworkExecutionError"
import GetObjectNetworkResult from "../../../../core/data/results/GetObjectNetworkResult"
import CreateObjectNetworkResult from "../../../../core/data/results/CreateObjectNetworkResult"
import UpdateObjectNetworkResult from "../../../../core/data/results/UpdateObjectNetworkResult"
import DestroyObjectNetworkResult from "../../../../core/data/results/DestroyObjectNetworkResult"
import SuccessExecutionResult from "../../../../../sqadmin/core/domain/results/SuccessExecutionResult"
import ErrorExecutionResult from "../../../../../sqadmin/core/domain/results/ErrorExecutionResult"
import FailureExecutionResult from "../../../../../sqadmin/core/domain/results/FailureExecutionResult"
import NetworkOrderPaymentMethodsRequestFilter from "../entities/NetworkOrderPaymentMethodsRequestFilter"
import NetworkOrderPaymentMethodsRequestQuery from "../entities/NetworkOrderPaymentMethodsRequestQuery"
import NetworkOrderPaymentMethodsResponseBody from "../entities/NetworkOrderPaymentMethodsResponseBody"
import NetworkOrderPaymentMethodResponseBody from "../entities/NetworkOrderPaymentMethodResponseBody"
import NetworkOrderPaymentMethodRequestBody from "../entities/NetworkOrderPaymentMethodRequestBody"
import NetworkOrderPaymentMethod from "../../../../core/data/entities/order-payment-methods/NetworkOrderPaymentMethod"
import NetworkOrderPaymentMethodError
  from "../../../../core/data/entities/order-payment-methods/NetworkOrderPaymentMethodError"
import NetworkPaymentProvidersResponseBody from "../entities/NetworkPaymentProvidersResponseBody"

const basePath = "/admin/order_payment_methods"

export default class OrderPaymentMethodsNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async getOrderPaymentMethods({
    filter,
    pagination,
    sort
  }: {
    readonly filter?: NetworkOrderPaymentMethodsRequestFilter | null
    readonly pagination?: NetworkLastItemPagination | null
    readonly sort?: string | null
  }): Promise<GetOrderPaymentMethodsNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: basePath,
      parameters: instanceToPlain(new NetworkOrderPaymentMethodsRequestQuery({
        filter,
        pagination,
        sort
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkOrderPaymentMethodsResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async getOrderPaymentMethod({
    orderPaymentMethodId
  }: {
    readonly orderPaymentMethodId: number
  }): Promise<GetObjectNetworkResult<NetworkOrderPaymentMethod>> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}/${orderPaymentMethodId}`
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkOrderPaymentMethodResponseBody, result.body).orderPaymentMethod!
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async createOrderPaymentMethod({
    orderPaymentMethod
  }: {
    readonly orderPaymentMethod: NetworkOrderPaymentMethod
  }): Promise<CreateObjectNetworkResult<NetworkOrderPaymentMethod, NetworkOrderPaymentMethodError>> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.POST,
      path: basePath,
      body: instanceToPlain(new NetworkOrderPaymentMethodRequestBody({
        orderPaymentMethod: orderPaymentMethod
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkOrderPaymentMethodResponseBody, result.body).orderPaymentMethod!
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkOrderPaymentMethodError, result.body)
        }
      case "failure":
        return result
    }
  }

  async updateOrderPaymentMethod({
    orderPaymentMethodId,
    orderPaymentMethod
  }: {
    readonly orderPaymentMethodId: number
    readonly orderPaymentMethod: NetworkOrderPaymentMethod
  }): Promise<UpdateObjectNetworkResult<NetworkOrderPaymentMethod, NetworkOrderPaymentMethodError>> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.PUT,
      path: `${basePath}/${orderPaymentMethodId}`,
      body: instanceToPlain(new NetworkOrderPaymentMethodRequestBody({
        orderPaymentMethod: orderPaymentMethod
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkOrderPaymentMethodResponseBody, result.body).orderPaymentMethod!
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkOrderPaymentMethodError, result.body)
        }
      case "failure":
        return result
    }
  }

  async destroyOrderPaymentMethod({
    orderPaymentMethodId
  }: {
    readonly orderPaymentMethodId: number
  }): Promise<DestroyObjectNetworkResult<NetworkOrderPaymentMethodError>> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.DELETE,
      path: `${basePath}/${orderPaymentMethodId}`
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: undefined
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkOrderPaymentMethodError, result.body)
        }
      case "failure":
        return result
    }
  }

  async getPaymentProviders(): Promise<GetPaymentProvidersNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: "/admin/payment_providers"
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkPaymentProvidersResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }
}

export type GetOrderPaymentMethodsNetworkResult =
  SuccessExecutionResult<NetworkOrderPaymentMethodsResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult

export type GetPaymentProvidersNetworkResult =
  SuccessExecutionResult<NetworkPaymentProvidersResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult
