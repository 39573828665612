import { GetObjectsPageResult } from "../../../../../sqadmin/features/objects/domain/results/GetObjectsPageResult"
import LastItemPaginationsMapper from "../../../../core/data/mappers/LastItemPaginationsMapper"
import SortMapper from "../../../../core/data/mappers/SortMapper"
import UsersMapper from "../../../../core/data/mappers/UsersMapper"
import PagesMapper from "../../../../core/data/mappers/PagesMapper"
import { LoadObjectResult } from "../../../../../sqadmin/features/objects/domain/results/LoadObjectResult"
import GetObjectNetworkResult from "../../../../core/data/results/GetObjectNetworkResult"
import { UpdateObjectResult } from "../../../../../sqadmin/features/objects/domain/results/UpdateObjectResult"
import UpdateObjectNetworkResult from "../../../../core/data/results/UpdateObjectNetworkResult"
import UsersRepository from "../../domain/repositories/UsersRepository"
import UsersNetworkSource, { GetUsersNetworkResult } from "../sources/UsersNetworkSource"
import User from "../../../../core/domain/entities/users/User"
import UserFiltersMapper from "../mappers/UserFiltersMapper"
import NetworkUser from "../../../../core/data/entities/users/NetworkUser"
import UserError from "../../../../core/domain/entities/users/UserError"
import NetworkUserError from "../../../../core/data/entities/users/NetworkUserError"
import { GetUsersParameters } from "../../domain/use-cases/GetUsersUseCase"
import { CreateObjectResult } from "../../../../../sqadmin/features/objects/domain/results/CreateObjectResult"
import { DestroyObjectResult } from "../../../../../sqadmin/features/objects/domain/results/DestroyObjectResult"
import CreateObjectNetworkResult from "../../../../core/data/results/CreateObjectNetworkResult"
import DestroyObjectNetworkResult from "../../../../core/data/results/DestroyObjectNetworkResult"

export default class DefaultUsersRepository implements UsersRepository {
  private readonly usersNetworkSource: UsersNetworkSource

  constructor(parameters: {
    readonly usersNetworkSource: UsersNetworkSource
  }) {
    this.usersNetworkSource = parameters.usersNetworkSource
  }

  async getUsers({
    filter,
    sort,
    pagination
  }: GetUsersParameters): Promise<GetObjectsPageResult<User>> {
    const result: GetUsersNetworkResult = await this.usersNetworkSource
      .getUsers({
        filter: filter && new UserFiltersMapper().mapDomainToNetwork({ filter }),
        pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({ pagination }),
        sort: sort && new SortMapper().mapDomainToNetwork({ sort })
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: {
            objects: result.data.users!.map((networkUser: NetworkUser) => {
              return new UsersMapper().mapNetworkToDomain({
                user: networkUser
              })
            }),
            page: new PagesMapper().mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      default:
        return result
    }
  }

  async getUser(parameters: {
    readonly userId: number
  }): Promise<LoadObjectResult<User>> {
    const result: GetObjectNetworkResult<NetworkUser> = await this.usersNetworkSource
      .getUser(parameters)

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new UsersMapper().mapNetworkToDomain({
            user: result.data
          })
        }
      default:
        return result
    }
  }

  async updateUser({
    userId,
    user
  }: {
    readonly userId: number
    readonly user: User
  }): Promise<UpdateObjectResult<User, UserError>> {
    const result: UpdateObjectNetworkResult<NetworkUser, NetworkUserError> =
      await this.usersNetworkSource.updateUser({
        userId,
        user: new UsersMapper().mapDomainToNetwork({
          user
        })
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new UsersMapper().mapNetworkToDomain({
            user: result.data
          })
        }
      default:
        return result
    }
  }

  async createUser(parameters: { readonly user: User }): Promise<CreateObjectResult<User, UserError>> {
    const result: CreateObjectNetworkResult<NetworkUser, NetworkUserError> =
      await this.usersNetworkSource.createUser({
        user: new UsersMapper().mapDomainToNetwork({
          user: parameters.user
        })
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new UsersMapper().mapNetworkToDomain({
            user: result.data
          })
        }
      default:
        return result
    }
  }

  async destroyUser(parameters: { readonly userId: number }): Promise<DestroyObjectResult<UserError>> {
    const result: DestroyObjectNetworkResult<NetworkUserError> =
      await this.usersNetworkSource.destroyUser(parameters)

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: undefined
        }
      default:
        return result
    }
  }
}
