import React from "react"
import AppSelectPickerComponent
  from "../../../../../../core/presentation/components/app-select-picker/AppSelectPickerComponent"
import { SingleSelectFormFieldViewState } from "../../../entities/form-fields/form-field-by-type/SingleSelectFormField"
import isPresent from "../../../../../../lib/isPresent"
import styles from "./SingleSelectFormFieldComponent.module.scss"
import TextComponent, { TextStyle } from "../../../../../../design/text/TextComponent"

export default function SingleSelectFormFieldComponent({
  fieldViewState,
  isDisabled
}: {
  readonly fieldViewState: SingleSelectFormFieldViewState<unknown>
  readonly isDisabled: boolean
}) {
  const {
    selectedOption,
    options,
    isLoading,
    query,
    page,
    onOpened,
    onSelect,
    onQueryChanged,
    onSearchRequested,
    onNextPageRequested,
    isSearchBarVisible,
    isClearButtonVisible,
    placeholder,
    optionErrorMessage
  } = fieldViewState

  return (
    <div className={styles.root}>
      {
        fieldViewState.getTitle() && (
          <div className={styles.title}>
            <TextComponent textStyle={TextStyle.LABEL1_PRIMARY}>{fieldViewState.getTitle()}</TextComponent>
          </div>
        )
      }
      <AppSelectPickerComponent
        selectButtonText={placeholder}
        isClearButtonVisible={isClearButtonVisible}
        selectedOption={selectedOption}
        options={options}
        disabled={isDisabled}
        isLoading={isLoading}
        query={query}
        page={page}
        onChange={onSelect}
        onOpened={onOpened}
        onQueryChanged={onQueryChanged}
        onSearchRequested={onSearchRequested}
        onNextPageRequested={onNextPageRequested}
        isSearchBarVisible={isSearchBarVisible}
        hasError={isPresent(fieldViewState.getErrors())}
        optionsErrorMessage={optionErrorMessage}
      />
    </div>
  )
}
