import { Expose, Type } from "class-transformer"
import NetworkOrderReceivingPriceRuleCondition
  from "../order-receiving-price-rule-conditions/NetworkOrderReceivingPriceRuleCondition"
import OrderReceivingPriceRuleCalculationType
  from "../../../domain/entities/order-receiving-price-rules/OrderReceivingPriceRuleCalculationType"
import NetworkCalculator from "../calculators/NetworkCalculator"

export default class NetworkOrderReceivingPriceRule {
  @Expose()
  readonly id: number | null | undefined

  @Expose()
  readonly clientId: string | null | undefined

  @Expose()
  @Type(() => NetworkOrderReceivingPriceRuleCondition)
  readonly conditions: NetworkOrderReceivingPriceRuleCondition[] | null | undefined

  @Expose()
  @Type(() => NetworkCalculator)
  readonly calculator: NetworkCalculator | null | undefined

  @Expose()
  readonly position: number | null | undefined

  @Expose()
  readonly calculationType: OrderReceivingPriceRuleCalculationType | null | undefined

  constructor(parameters?: {
    readonly id: number | null | undefined
    readonly conditions: NetworkOrderReceivingPriceRuleCondition[] | null | undefined
    readonly clientId: string | null | undefined
    readonly position: number | null | undefined
    readonly calculationType: OrderReceivingPriceRuleCalculationType | null | undefined
    readonly calculator: NetworkCalculator | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.id = parameters.id
    this.clientId = parameters.clientId
    this.conditions = parameters.conditions
    this.position = parameters.position
    this.calculationType = parameters.calculationType
    this.calculator = parameters.calculator
  }
}
