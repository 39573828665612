import React, { useEffect, useRef } from "react"
import styles from "./DropdownComponent.module.scss"
import getScrollParent from "../../../../lib/getScrollParent"

const dropdownInverseThreshold = 1.6

export interface DropdownProps {
  readonly isVisible: boolean
  readonly children: React.ReactNode
  readonly hasScroll?: boolean
  readonly hideOnClickOutside?: boolean
  readonly requestHide?: () => void
  readonly needSetWidthByParent?: boolean
}

export default function DropdownComponent({
  isVisible,
  children,
  hideOnClickOutside = false,
  hasScroll = true,
  requestHide,
  needSetWidthByParent
}: DropdownProps) {
  const dropdownElementRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (isVisible) {
      return initMetricsSettingOnParentScroll()
    }
  }, [isVisible])

  useEffect(() => {
    if (isVisible) {
      return initMetricsSettingOnWindowResize()
    }
  }, [isVisible])

  useEffect(() => {
    if (isVisible) {
      return initMetricsSettingOnWindowScroll()
    }
  }, [isVisible])

  useEffect(() => {
    if (!isVisible) {
      return
    }

    if (!hideOnClickOutside) {
      return
    }

    const listener = () => {
      requestHide && requestHide()
    }

    window.addEventListener("mousedown", listener)

    return () => {
      window.removeEventListener("mousedown", listener)
    }
  }, [isVisible, requestHide])

  if (!isVisible) {
    return <></>
  }

  function initMetricsSettingOnParentScroll() {
    const dropdownElement: HTMLElement | null = dropdownElementRef.current

    if (dropdownElement === null) {
      return
    }

    const dropdownElementScrollParent: HTMLElement | null = getScrollParent(dropdownElement)

    if (dropdownElementScrollParent === null) {
      return
    }

    const onScrollListener = () => {
      setPositionMetricsIfNeeds()
      setWidthMetricsIfNeeds()
    }

    dropdownElementScrollParent.addEventListener("scroll", onScrollListener)

    return () => {
      dropdownElementScrollParent.removeEventListener("scroll", onScrollListener)
    }
  }

  function initMetricsSettingOnWindowResize() {
    const onResize = () => {
      setPositionMetricsIfNeeds()
      setWidthMetricsIfNeeds()
    }

    window.addEventListener("resize", onResize)

    return () => {
      window.removeEventListener("resize", onResize)
    }
  }

  function initMetricsSettingOnWindowScroll() {
    const onScrollListener = () => {
      setPositionMetricsIfNeeds()
      setWidthMetricsIfNeeds()
    }

    window.addEventListener("scroll", onScrollListener)

    return () => {
      window.removeEventListener("scroll", onScrollListener)
    }
  }

  function setPositionMetricsIfNeeds() {
    if (!hasScroll) {
      return
    }

    const dropdownElement: HTMLElement | null = dropdownElementRef.current

    if (dropdownElement === null) {
      return
    }

    const anchor: HTMLElement | null = dropdownElement.parentElement

    if (anchor === null) {
      return
    }

    const anchorBoundingClientRect: DOMRect = anchor.getBoundingClientRect()

    const anchorTopOffsetOnWindow: number = anchorBoundingClientRect.top
    const anchorHeight: number = anchor.offsetHeight
    const dropdownTopOffset: number = anchorTopOffsetOnWindow + anchorHeight
    const windowHeight: number = window.innerHeight
    const dropdownBottomOffset: number = windowHeight - anchorTopOffsetOnWindow
    const anchorLeftOffsetOnWindow: number = anchorBoundingClientRect.left
    const dropdownLeftOffset: number = anchorLeftOffsetOnWindow
    const dropdownInverse: boolean = anchorTopOffsetOnWindow > windowHeight / dropdownInverseThreshold

    const dropdownMaxHeight: number =
      windowHeight -
      (dropdownInverse ? dropdownBottomOffset : dropdownTopOffset)

    dropdownElement.style.left = `${dropdownLeftOffset}px`
    dropdownElement.style.maxHeight = `${dropdownMaxHeight}px`

    if (dropdownInverse) {
      dropdownElement.style.top = ""
      dropdownElement.style.bottom = `${dropdownBottomOffset}px`
    } else {
      dropdownElement.style.top = `${dropdownTopOffset}px`
      dropdownElement.style.bottom = ""
    }
  }

  function setWidthMetricsIfNeeds() {
    if (!needSetWidthByParent) return

    const dropdownElement: HTMLElement | null = dropdownElementRef.current

    if (dropdownElement === null) {
      return
    }

    const parentElement: HTMLElement | null = dropdownElement.parentElement

    if (parentElement === null) {
      return
    }

    dropdownElement.style.width = `${parentElement.offsetWidth}px`
  }

  function onDropdownElementRendered(element: HTMLDivElement | null) {
    dropdownElementRef.current = element

    if (element !== null) {
      setPositionMetricsIfNeeds()
      setWidthMetricsIfNeeds()
    }
  }

  return (
    <div
      ref={(element: HTMLDivElement | null) => onDropdownElementRendered(element)}
      className={`${styles.root} ${hasScroll ? styles.hasScroll : styles.noScroll}`}
      onMouseDown={(event) => event.stopPropagation()}
    >
      {children}
    </div>
  )
}
