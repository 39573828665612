import { GetObjectsPageResult } from "../../../../../sqadmin/features/objects/domain/results/GetObjectsPageResult"
import PropertyValue from "../../../../core/domain/entities/property-values/PropertyValue"
import PropertyValuesRepository from "../repositories/PropertyValuesRepository"

export default class GetPropertyValuesUseCase {
  private readonly propertyValuesRepository: PropertyValuesRepository

  constructor(parameters: {
    readonly propertyValuesRepository: PropertyValuesRepository
  }) {
    this.propertyValuesRepository = parameters.propertyValuesRepository
  }

  async call(parameters: GetPropertyValuesParameters): Promise<GetPropertyValuesResult> {
    return await this.propertyValuesRepository.getPropertyValues(parameters)
  }
}

export interface GetPropertyValuesParameters {
  readonly query: string | null | undefined
  readonly propertyId: number
}

export type GetPropertyValuesResult = GetObjectsPageResult<PropertyValue>
