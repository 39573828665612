import OrderShippingTariffsFilter from "../../domain/entities/order-shipping-method-tariffs/OrderShippingTariffsFilter"
import NetworkOrderShippingTariffsRequestFilter
  from "../../../features/order-receiving-methods-core/data/entities/NetworkOrderShippingTariffsRequestFilter"

export default class OrderShippingTariffsFiltersMapper {
  mapDomainToNetwork({
    filter
  }: {
    readonly filter: OrderShippingTariffsFilter | null | undefined
  }): NetworkOrderShippingTariffsRequestFilter {
    return new NetworkOrderShippingTariffsRequestFilter({
      orderShippingMethodType: filter?.orderShippingMethodType,
      orderReceivingMethodType: filter?.orderReceivingMethodType,
      fulfillmentProviderType: filter?.fulfillmentProviderType
    })
  }
}
