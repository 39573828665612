import ObjectsPresentationLogicParameters
  from "../../../../../../sqadmin/features/objects/presentation/entities/objects/ObjectsPresentationLogicParameters"
import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import { useCoreTextProvider } from "../../../../../core/presentation/contexts/CoreTextProviderContext"
import CoreUrlProvider from "../../../../../core/presentation/services/CoreUrlProvider"
import useObjectsPresentationLogicParameters
  from "../../../../../../sqadmin/features/objects/presentation/hooks/useObjectsPresentationLogicParameters"
import { useViewModel } from "../../../../../../sqadmin/lib/view-model/ViewModel"
import ObjectsComponent
  from "../../../../../../sqadmin/features/objects/presentation/components/objects/ObjectsComponent"
import React from "react"
import Order from "../../../../../core/domain/entities/orders/Order"
import OrdersViewModel from "./OrdersViewModel"

export interface OrdersPageParameters {
  readonly provideViewModel: (parameters: {
    readonly objectsPresentationLogicParameters: ObjectsPresentationLogicParameters
  }) => OrdersViewModel
}

export default function OrdersPage({
  provideViewModel
}: OrdersPageParameters) {
  const coreTextProvider: CoreTextProvider = useCoreTextProvider()
  const coreUrlProvider: CoreUrlProvider = new CoreUrlProvider()
  const objectsPresentationLogicParameters: ObjectsPresentationLogicParameters = useObjectsPresentationLogicParameters()

  const viewModel: OrdersViewModel = useViewModel(() => provideViewModel({
    objectsPresentationLogicParameters
  }))

  function getRowUrl(order: Order): string {
    return coreUrlProvider.buildOrderUrl({ id: order.id! })
  }

  return (
    <ObjectsComponent
      title={coreTextProvider.orders()}
      getObjectUrl={getRowUrl}
      observableObjectsPageViewState={viewModel.observableObjectsPageViewState}
      observableObjectsViewAction={viewModel.observableObjectsViewAction}
      onObjectsViewEvent={viewModel.onObjectsViewEvent}
    />
  )
}
