import IsSessionExistUseCase from "../../../../../core/domain/use-cases/sessions/IsSessionExistUseCase"
import CreateSessionUserPartial from "../../../../../core/domain/entities/CreateSessionUserPartial"
import CreateSessionUseCase, {
  CreateSessionError,
  CreateSessionResult
} from "../../../../../core/domain/use-cases/sessions/CreateSessionUseCase"
import assertNever from "../../../../../lib/assertNever"
import ApplicationException from "../../../../../core/domain/exceptions/ApplicationException"
import ViewModel from "../../../../../lib/view-model/ViewModel"
import { StateObservable } from "../../../../../lib/view-model/StateObservable"
import { AuthenticationViewState } from "./AuthenticationViewState"
import autoBind from "auto-bind"

export default class AuthenticationViewModel extends ViewModel {
  private readonly isSessionExistUseCase: IsSessionExistUseCase
  private readonly createSessionUseCase: CreateSessionUseCase
  private user: CreateSessionUserPartial
  private isAuthenticating: boolean
  private authenticationError?: CreateSessionError
  private authenticationFailureException?: ApplicationException
  authenticationStateObservable = new StateObservable<AuthenticationViewState>({ type: "initial" })

  constructor(parameters: {
    readonly isSessionExistUseCase: IsSessionExistUseCase
    readonly createSessionUseCase: CreateSessionUseCase
  }) {
    super()

    autoBind(this)

    this.isSessionExistUseCase = parameters.isSessionExistUseCase
    this.createSessionUseCase = parameters.createSessionUseCase

    this.user = { emailAddress: "", password: "" }
    this.isAuthenticating = false

    this.checkIsCurrentUserAuthenticatedAndSetViewState()
  }

  onEmailAddressChange({ emailAddress }: { readonly emailAddress: string }) {
    this.setEmailAddress(emailAddress)
    this.setNotAuthenticatedAuthenticationViewState()
  }

  onPasswordChange({ password }: { readonly password: string }) {
    this.setPassword(password)
    this.setNotAuthenticatedAuthenticationViewState()
  }

  onAuthenticateClicked() {
    this.authenticateAndSetAuthenticatedViewState().then()
  }

  private checkIsCurrentUserAuthenticatedAndSetViewState() {
    const isSessionExist: boolean = this.isSessionExistUseCase.call()

    if (isSessionExist) {
      this.setAlreadyAuthenticatedAuthenticationViewState()
    } else {
      this.setNotAuthenticatedAuthenticationViewState()
    }
  }

  private async authenticateAndSetAuthenticatedViewState(): Promise<void> {
    this.setIsAuthenticating(true)
    this.setAuthenticationError(undefined)
    this.setAuthenticationFailureException(undefined)
    this.setNotAuthenticatedAuthenticationViewState()

    const authenticationResult: CreateSessionResult =
      await this.createSessionUseCase.call({ user: this.user })

    this.setIsAuthenticating(false)

    switch (authenticationResult.type) {
      case "success":
        this.setNowAuthenticatedAuthenticationViewState()
        break
      case "error":
        this.setAuthenticationError(authenticationResult.error)
        this.setNotAuthenticatedAuthenticationViewState()
        break
      case "failure":
        this.setAuthenticationFailureException(authenticationResult.exception)
        this.setNotAuthenticatedAuthenticationViewState()
        break
      default:
        assertNever(authenticationResult)
    }
  }

  private setEmailAddress(emailAddress: string) {
    this.user = {
      ...this.user,
      emailAddress
    }
  }

  private setPassword(password: string) {
    this.user = {
      ...this.user,
      password
    }
  }

  private setIsAuthenticating(isAuthenticating: boolean) {
    this.isAuthenticating = isAuthenticating
  }

  private setAuthenticationError(authenticationError: CreateSessionError | undefined) {
    this.authenticationError = authenticationError
  }

  private setAuthenticationFailureException(authenticationFailureException?: ApplicationException | undefined) {
    this.authenticationFailureException = authenticationFailureException
  }

  private setAlreadyAuthenticatedAuthenticationViewState() {
    this.authenticationStateObservable.setValue({ type: "already_authenticated" })
  }

  private setNotAuthenticatedAuthenticationViewState() {
    this.authenticationStateObservable.setValue({
      type: "not_authenticated",
      user: this.user,
      isAuthenticating: this.isAuthenticating,
      authenticationError: this.authenticationError,
      authenticationFailureException: this.authenticationFailureException
    })
  }

  private setNowAuthenticatedAuthenticationViewState() {
    this.authenticationStateObservable.setValue({ type: "now_authenticated" })
  }
}
