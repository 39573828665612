import { Expose, Type } from "class-transformer"
import NetworkOrderErrorsObjectAttributes from "./NetworkOrderErrorsObjectAttributes"
import OrderErrorsObject from "../../../domain/entities/orders/OrderErrorsObject"
import NetworkOrderReceivingErrorsObject from "../order-receivings/NetworkOrderReceivingErrorsObject"
import NetworkOrderPaymentErrorsObject from "../order-payments/NetworkOrderPaymentErrorsObject"
import NetworkUserErrorsObject from "../users/NetworkUserErrorsObject"
import NetworkOrderItemErrorsObject from "../order-items/NetworkOrderItemErrorsObject"

export default class NetworkOrderErrorsObject implements OrderErrorsObject {
  @Expose()
  @Type(() => NetworkOrderErrorsObjectAttributes)
  readonly attributes: NetworkOrderErrorsObjectAttributes | null | undefined

  @Expose()
  @Type(() => NetworkOrderReceivingErrorsObject)
  readonly orderReceiving: NetworkOrderReceivingErrorsObject | null | undefined

  @Expose()
  @Type(() => NetworkOrderPaymentErrorsObject)
  readonly orderPayment: NetworkOrderPaymentErrorsObject | null | undefined

  @Expose()
  @Type(() => NetworkUserErrorsObject)
  readonly user: NetworkUserErrorsObject | null | undefined

  @Expose()
  @Type(() => NetworkOrderItemErrorsObject)
  readonly items: NetworkOrderItemErrorsObject[] | null | undefined
}
