import { Expose, Type } from "class-transformer"
import NetworkBonusProgramLevel from "../bonus-program-levels/NetworkBonusProgramLevel"
import NetworkCalculator from "../calculators/NetworkCalculator"

export default class NetworkBonusPointsRule {
  @Expose()
  readonly id: number | null | undefined

  @Expose()
  @Type(() => NetworkBonusProgramLevel)
  readonly bonusProgramLevel: NetworkBonusProgramLevel | null | undefined

  @Expose()
  @Type(() => NetworkCalculator)
  readonly calculator: NetworkCalculator | null | undefined

  @Expose()
  readonly bonusProgramLevelId: number | null | undefined

  @Expose()
  readonly clientId: string | null | undefined

  constructor(parameters?: {
    readonly id: number | null | undefined
    readonly bonusProgramLevel: NetworkBonusProgramLevel | null | undefined
    readonly bonusProgramLevelId: number | null | undefined
    readonly calculator: NetworkCalculator | null | undefined
    readonly clientId: string | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.id = parameters.id
    this.bonusProgramLevel = parameters.bonusProgramLevel
    this.bonusProgramLevelId = parameters.bonusProgramLevelId
    this.calculator = parameters.calculator
    this.clientId = parameters.clientId
  }
}
