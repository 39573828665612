export default class DiModule {
  private singleByKey: Map<string, unknown> = new Map<string, unknown>()

  protected replaceSingleKey({ from, to }: { from: string, to: string }) {
    this.singleByKey.set(to, this.singleByKey.get(from))
    this.singleByKey.delete(from)
  }

  protected single<Type>(key: string, getter: () => Type): Type {
    let value = this.singleByKey.get(key) as Type

    if (value === undefined) {
      value = getter()
      this.singleByKey.set(key, value)
    }

    return value
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  protected singlePresenter<Type>(constructor: Function, locationId: string, getter: () => Type): Type {
    return this.single(`${constructor.name}_${locationId}`, getter)
  }
}
