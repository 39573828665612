import { Expose, Type } from "class-transformer"
import NetworkImageVariant from "../image-variants/NetworkImageVariant"

export default class NetworkImage {
  @Expose()
  readonly id: number | null | undefined

  @Expose()
  readonly clientId: string | null | undefined

  @Expose()
  @Type(() => NetworkImageVariant)
  readonly listImageVariant: NetworkImageVariant | null | undefined

  @Expose()
  @Type(() => NetworkImageVariant)
  readonly detailsImageVariant: NetworkImageVariant | null | undefined

  @Expose()
  @Type(() => NetworkImageVariant)
  readonly fullScreenImageVariant: NetworkImageVariant | null | undefined

  @Expose()
  @Type(() => NetworkImageVariant)
  readonly sliderImageVariant: NetworkImageVariant | null | undefined

  @Expose()
  @Type(() => NetworkImageVariant)
  readonly pagerImageVariant: NetworkImageVariant | null | undefined

  @Expose()
  readonly attachmentExists: boolean | null | undefined

  @Expose()
  readonly externalCode: string | null | undefined

  constructor(parameters?: {
    readonly id: number | null | undefined
    readonly clientId: string | null | undefined
    readonly listImageVariant: NetworkImageVariant | null | undefined
    readonly detailsImageVariant: NetworkImageVariant | null | undefined
    readonly fullScreenImageVariant: NetworkImageVariant | null | undefined
    readonly sliderImageVariant: NetworkImageVariant | null | undefined
    readonly pagerImageVariant: NetworkImageVariant | null | undefined
    readonly attachmentExists: boolean | null | undefined
    readonly externalCode: string | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.id = parameters.id
    this.clientId = parameters.clientId
    this.listImageVariant = parameters.listImageVariant
    this.detailsImageVariant = parameters.detailsImageVariant
    this.fullScreenImageVariant = parameters.fullScreenImageVariant
    this.sliderImageVariant = parameters.sliderImageVariant
    this.pagerImageVariant = parameters.pagerImageVariant
    this.attachmentExists = parameters.attachmentExists
    this.externalCode = parameters.externalCode
  }
}
