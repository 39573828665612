import DiModule from "../../../../sqadmin/lib/di/DiModule"
import BonusProgramLevelsRepository from "../domain/repositories/BonusProgramLevelsRepository"
import DefaultBonusProgramLevelsRepository from "../data/repositories/DefaultBonusProgramLevelsRepository"
import CoreNetworkDiModule from "../../../core/di/modules/CoreNetworkDiModule"
import BonusProgramLevelsNetworkSource from "../data/sources/BonusProgramLevelsNetworkSource"

export default interface BonusProgramLevelsCoreDataDiModule {
  provideBonusProgramLevelsRepository(): BonusProgramLevelsRepository
}

export class DefaultBonusProgramLevelsCoreDataDiModule extends DiModule implements BonusProgramLevelsCoreDataDiModule {
  private readonly sqCoreCoreNetworkDiModule: CoreNetworkDiModule

  constructor(parameters: {
    readonly sqCoreCoreNetworkDiModule: CoreNetworkDiModule
  }) {
    super()
    this.sqCoreCoreNetworkDiModule = parameters.sqCoreCoreNetworkDiModule
  }

  provideBonusProgramLevelsRepository(): BonusProgramLevelsRepository {
    return this.single(
      DefaultBonusProgramLevelsRepository.name,
      () => new DefaultBonusProgramLevelsRepository({
        bonusProgramLevelsNetworkSource: this.provideBonusProgramLevelsNetworkSource()
      })
    )
  }

  private provideBonusProgramLevelsNetworkSource(): BonusProgramLevelsNetworkSource {
    return this.single(
      BonusProgramLevelsNetworkSource.name,
      () => new BonusProgramLevelsNetworkSource({
        backendHttpClient: this.sqCoreCoreNetworkDiModule.provideBackendHttpClient()
      })
    )
  }
}
