import NetworkBlockProductCategory from "../entities/block-product-categories/NetworkBlockProductCategory"
import BlockProductCategory from "../../domain/entities/block-product-categories/BlockProductCategory"
import ProductCategoriesMapper from "./ProductCategoriesMapper"
import { v4 as uuidv4 } from "uuid"

export default class BlockProductCategoriesMapper {
  mapNetworkToDomain({
    blockProductCategory
  }: {
    readonly blockProductCategory: NetworkBlockProductCategory
  }): BlockProductCategory {
    return {
      position: blockProductCategory.position,
      productCategory: blockProductCategory.productCategory && new ProductCategoriesMapper().mapNetworkToDomain({
        productCategory: blockProductCategory.productCategory
      }),
      productCategoryId: blockProductCategory.productCategoryId,
      clientId: uuidv4()
    }
  }

  mapDomainToNetwork({
    blockProductCategory
  }: {
    readonly blockProductCategory: BlockProductCategory
  }): NetworkBlockProductCategory {
    return new NetworkBlockProductCategory({
      position: blockProductCategory.position,
      productCategory: blockProductCategory.productCategory && new ProductCategoriesMapper().mapDomainToNetwork({
        productCategory: blockProductCategory.productCategory
      }),
      productCategoryId: blockProductCategory.productCategoryId,
      clientId: blockProductCategory.clientId
    })
  }
}
