import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../errors/NetworkAttributeError"
import ProductsSetPropertyErrorsObjectAttributes
  from "../../../domain/entities/products-set-properties/ProductsSetPropertyErrorsObjectAttributes"

export default class NetworkProductsSetPropertyErrorsObjectAttributes
  implements ProductsSetPropertyErrorsObjectAttributes {
  @Expose()
  @Type(() => NetworkAttributeError)
  readonly propertyId?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly propertyValueIds?: NetworkAttributeError[] | null
}
