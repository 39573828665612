import TextComponent, { TextStyle } from "../../../../design/text/TextComponent"
import styles from "./PickerOptionsErrorComponent.module.scss"
import React from "react"

export default function PickerOptionsErrorComponent({
  errorMessage
}: {
  readonly errorMessage: string
}) {
  return (
    <div className={styles.root}>
      <TextComponent textStyle={TextStyle.BODY1_SECONDARY}>{errorMessage}</TextComponent>
    </div>
  )
}
