import { Expose, Type } from "class-transformer"
import NetworkPlace from "../places/NetworkPlace"

export default class NetworkBatchPlace {
  @Expose()
  readonly id: number | null | undefined

  @Expose()
  readonly placeId: number | null | undefined

  @Expose()
  readonly clientId: string | null | undefined

  @Expose()
  readonly isAvailable: boolean | null | undefined

  @Expose()
  @Type(() => NetworkPlace)
  readonly place: NetworkPlace | null | undefined

  constructor(parameters?: {
    readonly id: number | null | undefined
    readonly placeId: number | null | undefined
    readonly clientId: string | null | undefined
    readonly place: NetworkPlace | null | undefined
    readonly isAvailable: boolean | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.id = parameters.id
    this.placeId = parameters.placeId
    this.clientId = parameters.clientId
    this.place = parameters.place
    this.isAvailable = parameters.isAvailable
  }
}
