import DiModule from "../../../../../sqadmin/lib/di/DiModule"
import DashboardsDataDiModule from "./DashboardsDataDiModule"
import GetDashboardsUseCase from "../../domain/use-cases/dashboards/GetDashboardsUseCase"
import GetDashboardUseCase from "../../domain/use-cases/dashboards/GetDashboardUseCase"
import UpdateDashboardUseCase from "../../domain/use-cases/dashboards/UpdateDashboardUseCase"
import CreateBannerImageUseCase from "../../domain/use-cases/banner-images/CreateBannerImageUseCase"
import CreateDashboardUseCase from "../../domain/use-cases/dashboards/CreateDashboardUseCase"
import DestroyDashboardUseCase from "../../domain/use-cases/dashboards/DestroyDashboardUseCase"

export default interface DashboardsDomainDiModule {
  provideGetDashboardsUseCase(): GetDashboardsUseCase

  provideGetDashboardUseCase(): GetDashboardUseCase

  provideUpdateDashboardUseCase(): UpdateDashboardUseCase

  provideCreateDashboardUseCase(): CreateDashboardUseCase

  provideCreateBannerImageUseCase(): CreateBannerImageUseCase

  provideDestroyDashboardUseCase(): DestroyDashboardUseCase
}

export class DefaultDashboardsDomainDiModule
  extends DiModule
  implements DashboardsDomainDiModule {

  private readonly sqCoreDashboardsDataDiModule: DashboardsDataDiModule

  constructor(parameters: {
    readonly sqCoreDashboardsDataDiModule: DashboardsDataDiModule
  }) {
    super()
    this.sqCoreDashboardsDataDiModule = parameters.sqCoreDashboardsDataDiModule
  }

  provideCreateBannerImageUseCase(): CreateBannerImageUseCase {
    return this.single(
      CreateBannerImageUseCase.name,
      () => new CreateBannerImageUseCase({
        bannerImagesRepository: this.sqCoreDashboardsDataDiModule.provideBannerImagesRepository()
      })
    )
  }

  provideGetDashboardsUseCase(): GetDashboardsUseCase {
    return this.single(
      GetDashboardsUseCase.name,
      () => new GetDashboardsUseCase({
        dashboardsRepository: this.sqCoreDashboardsDataDiModule.provideDashboardsRepository()
      })
    )
  }

  provideGetDashboardUseCase(): GetDashboardUseCase {
    return this.single(
      GetDashboardUseCase.name,
      () => new GetDashboardUseCase({
        dashboardsRepository: this.sqCoreDashboardsDataDiModule.provideDashboardsRepository()
      })
    )
  }

  provideUpdateDashboardUseCase(): UpdateDashboardUseCase {
    return this.single(
      UpdateDashboardUseCase.name,
      () => new UpdateDashboardUseCase({
        dashboardsRepository: this.sqCoreDashboardsDataDiModule.provideDashboardsRepository()
      })
    )
  }

  provideCreateDashboardUseCase(): CreateDashboardUseCase {
    return this.single(
      CreateDashboardUseCase.name,
      () => new CreateDashboardUseCase({
        dashboardsRepository: this.sqCoreDashboardsDataDiModule.provideDashboardsRepository()
      })
    )
  }

  provideDestroyDashboardUseCase(): DestroyDashboardUseCase {
    return this.single(
      DestroyDashboardUseCase.name,
      () => new DestroyDashboardUseCase({
        dashboardsRepository: this.sqCoreDashboardsDataDiModule.provideDashboardsRepository()
      })
    )
  }
}
