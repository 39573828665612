import { Expose } from "class-transformer"
import NetworkProperty from "../properties/NetworkProperty"
import NetworkPropertyValue from "../property-values/NetworkPropertyValue"

export default class NetworkProductsSetProperty {
  @Expose()
  readonly clientId?: string | null

  @Expose()
  readonly propertyId?: number | null

  @Expose()
  readonly property?: NetworkProperty | null

  @Expose()
  readonly propertyValueIds?: number[] | null

  @Expose()
  readonly propertyValues?: NetworkPropertyValue[] | null

  constructor(parameters?: {
    readonly clientId?: string | null
    readonly propertyId?: number | null
    readonly property?: NetworkProperty | null
    readonly propertyValueIds?: number[] | null
    readonly propertyValues?: NetworkPropertyValue[] | null
  }) {
    if (parameters === undefined) {
      return
    }

    this.propertyId = parameters.propertyId
    this.property = parameters.property
    this.propertyValueIds = parameters.propertyValueIds
    this.clientId = parameters.clientId
    this.propertyValues = parameters.propertyValues
  }
}
