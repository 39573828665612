import React from "react"
import CalendarComponent from "../calendar/CalendarComponent"

export function SingleDateCalendarComponent({
  date,
  onChange,
  weekDayNameByNumber,
  monthNameByNumber,
  onMonthChanged,
  renderDateDescription
}: {
  readonly date?: Date | null
  readonly onChange: (date: Date) => void
  readonly weekDayNameByNumber: { [_: number]: string }
  readonly monthNameByNumber: { [_: number]: string }
  readonly onMonthChanged?: (parameters: { readonly month: Date }) => void
  readonly renderDateDescription?: (parameters: { readonly date: Date }) => React.ReactNode
}) {
  return (
    <CalendarComponent
      dateRange={{ dateFrom: date ?? undefined }}
      onDateClick={onChange}
      weekDayNameByNumber={weekDayNameByNumber}
      monthNameByNumber={monthNameByNumber}
      isRangeDisplayEnabled={false}
      onMonthChanged={onMonthChanged}
      renderDateDescription={renderDateDescription}
    />
  )
}
