import BackendHttpClient, { BackendHttpClientResult } from "../../../../core/data/network/BackendHttpClient"
import NetworkLastItemPagination from "../../../../core/data/entities/pagination/NetworkLastItemPagination"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import { instanceToPlain, plainToInstance } from "class-transformer"
import NetworkExecutionError from "../../../../core/data/entities/errors/NetworkExecutionError"
import GetObjectNetworkResult from "../../../../core/data/results/GetObjectNetworkResult"
import SuccessExecutionResult from "../../../../../sqadmin/core/domain/results/SuccessExecutionResult"
import ErrorExecutionResult from "../../../../../sqadmin/core/domain/results/ErrorExecutionResult"
import FailureExecutionResult from "../../../../../sqadmin/core/domain/results/FailureExecutionResult"
import NetworkApiLogItemResponseBody from "../entities/NetworkApiLogItemResponseBody"
import NetworkApiLogItem from "../../../../core/data/entities/api-log-items/NetworkApiLogItem"
import NetworkApiLogItemsResponseBody from "../entities/NetworkApiLogItemsResponseBody"
import NetworkApiLogItemsRequestFilter from "../entities/NetworkApiLogItemsRequestFilter"
import NetworkApiLogItemsRequestQuery from "../entities/NetworkApiLogItemsRequestQuery"

const basePath = "/admin/api_log_items"

export default class ApiLogItemsNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async getApiLogItems({
    filter,
    pagination,
    sort
  }: {
    readonly filter?: NetworkApiLogItemsRequestFilter | null
    readonly pagination?: NetworkLastItemPagination | null
    readonly sort?: string | null
  }): Promise<GetApiLogItemsNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: basePath,
      parameters: instanceToPlain(new NetworkApiLogItemsRequestQuery({
        filter,
        pagination,
        sort
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkApiLogItemsResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async getApiLogItem({
    apiLogItemId
  }: {
    readonly apiLogItemId: number
  }): Promise<GetObjectNetworkResult<NetworkApiLogItem>> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}/${apiLogItemId}`
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkApiLogItemResponseBody, result.body).apiLogItem!
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }
}

export type GetApiLogItemsNetworkResult =
  SuccessExecutionResult<NetworkApiLogItemsResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult
