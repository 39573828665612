import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../errors/NetworkAttributeError"
import {
  OrderPaymentErrorsObjectAttributes
} from "../../../domain/entities/order-payments/OrderPaymentErrorsObjectAttributes"

export default class NetworkOrderPaymentErrorsObjectAttributes implements OrderPaymentErrorsObjectAttributes {
  @Expose()
  @Type(() => NetworkAttributeError)
  readonly id: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly method: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly payment: NetworkAttributeError[] | null | undefined
}
