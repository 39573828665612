import DiModule from "../../../../sqadmin/lib/di/DiModule"
import ProductCategoriesCoreDataDiModule from "./ProductCategoriesCoreDataDiModule"
import CreateProductCategoryImageUseCase from "../domain/use-cases/images/CreateProductCategoryImageUseCase"
import DestroyProductCategoryUseCase from "../domain/use-cases/product-categories/DestroyProductCategoryUseCase"
import UpdateProductCategoryUseCase from "../domain/use-cases/product-categories/UpdateProductCategoryUseCase"
import CreateProductCategoryUseCase from "../domain/use-cases/product-categories/CreateProductCategoryUseCase"
import GetProductCategoryUseCase from "../domain/use-cases/product-categories/GetProductCategoryUseCase"
import GetProductCategoriesUseCase from "../domain/use-cases/product-categories/GetProductCategoriesUseCase"

export default interface ProductCategoriesCoreDomainDiModule {
  provideGetProductCategoriesUseCase(): GetProductCategoriesUseCase

  provideGetProductCategoryUseCase(): GetProductCategoryUseCase

  provideCreateProductCategoryUseCase(): CreateProductCategoryUseCase

  provideUpdateProductCategoryUseCase(): UpdateProductCategoryUseCase

  provideDestroyProductCategoryUseCase(): DestroyProductCategoryUseCase

  provideCreateProductCategoryImageUseCase(): CreateProductCategoryImageUseCase

  provideCreateProductCategoryImageUseCase(): CreateProductCategoryImageUseCase
}

export class DefaultProductCategoriesCoreDomainDiModule
  extends DiModule
  implements ProductCategoriesCoreDomainDiModule {

  private readonly productCategoriesCoreDataDiModule: ProductCategoriesCoreDataDiModule

  constructor(parameters: {
    readonly productCategoriesCoreDataDiModule: ProductCategoriesCoreDataDiModule
  }) {
    super()
    this.productCategoriesCoreDataDiModule = parameters.productCategoriesCoreDataDiModule
  }

  provideCreateProductCategoryImageUseCase(): CreateProductCategoryImageUseCase {
    return this.single(
      CreateProductCategoryImageUseCase.name,
      () => new CreateProductCategoryImageUseCase({
        productCategoryImagesRepository: this.productCategoriesCoreDataDiModule
          .provideProductCategoryImagesRepository()
      })
    )
  }

  provideGetProductCategoriesUseCase(): GetProductCategoriesUseCase {
    return this.single(
      GetProductCategoriesUseCase.name,
      () => new GetProductCategoriesUseCase({
        productCategoriesRepository: this.productCategoriesCoreDataDiModule.provideProductCategoriesRepository()
      })
    )
  }

  provideGetProductCategoryUseCase(): GetProductCategoryUseCase {
    return this.single(
      GetProductCategoryUseCase.name,
      () => new GetProductCategoryUseCase({
        productCategoriesRepository: this.productCategoriesCoreDataDiModule.provideProductCategoriesRepository()
      })
    )
  }

  provideCreateProductCategoryUseCase(): CreateProductCategoryUseCase {
    return this.single(
      CreateProductCategoryUseCase.name,
      () => new CreateProductCategoryUseCase({
        productCategoriesRepository: this.productCategoriesCoreDataDiModule.provideProductCategoriesRepository()
      })
    )
  }

  provideUpdateProductCategoryUseCase(): UpdateProductCategoryUseCase {
    return this.single(
      UpdateProductCategoryUseCase.name,
      () => new UpdateProductCategoryUseCase({
        productCategoriesRepository: this.productCategoriesCoreDataDiModule.provideProductCategoriesRepository()
      })
    )
  }

  provideDestroyProductCategoryUseCase(): DestroyProductCategoryUseCase {
    return this.single(
      DestroyProductCategoryUseCase.name,
      () => new DestroyProductCategoryUseCase({
        productCategoriesRepository: this.productCategoriesCoreDataDiModule.provideProductCategoriesRepository()
      })
    )
  }
}
