import DiModule from "../../../../sqadmin/lib/di/DiModule"
import PropertiesRepository from "../domain/repositories/PropertiesRepository"
import DefaultPropertiesRepository from "../data/repositories/DefaultPropertiesRepository"
import CoreNetworkDiModule from "../../../core/di/modules/CoreNetworkDiModule"
import PropertiesNetworkSource from "../data/sources/PropertiesNetworkSource"

export default interface PropertiesCoreDataDiModule {
  providePropertiesRepository(): PropertiesRepository
}

export class DefaultPropertiesCoreDataDiModule
  extends DiModule
  implements PropertiesCoreDataDiModule {

  private readonly sqCoreCoreNetworkDiModule: CoreNetworkDiModule

  constructor(parameters: {
    readonly sqCoreCoreNetworkDiModule: CoreNetworkDiModule
  }) {
    super()
    this.sqCoreCoreNetworkDiModule = parameters.sqCoreCoreNetworkDiModule
  }

  providePropertiesRepository(): PropertiesRepository {
    return this.single(
      DefaultPropertiesRepository.name,
      () => new DefaultPropertiesRepository({
        propertiesNetworkSource: this.providePropertiesNetworkSource()
      })
    )
  }

  private providePropertiesNetworkSource(): PropertiesNetworkSource {
    return this.single(
      PropertiesNetworkSource.name,
      () => new PropertiesNetworkSource({
        backendHttpClient: this.sqCoreCoreNetworkDiModule.provideBackendHttpClient()
      })
    )
  }
}
