import React, { forwardRef, useImperativeHandle, useRef } from "react"
import styles from "./TextInputComponent.module.scss"
import assertNever from "../../lib/assertNever"

export interface TextInputComponentRef {
  readonly focus: () => void
}

export function TextInputComponent({
  type,
  value,
  isDisabled,
  onChange,
  onKeyDown,
  placeholder,
  leftIcon,
  rightIcon,
  hasError,
  textInputStyle,
  onClick,
  readOnly = false,
  needShowCursorPointer = false,
  cancelFocusByClick = false
}: {
  readonly value?: string | null | undefined
  readonly readOnly?: boolean
  readonly type?: TextInputType
  readonly placeholder: string | null | undefined
  readonly isDisabled?: boolean,
  readonly hasError?: boolean,
  readonly needShowCursorPointer?: boolean,
  readonly cancelFocusByClick?: boolean,
  readonly onChange?: (value: string) => void
  readonly onClick?: (event: React.MouseEvent) => void
  readonly onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void
  readonly leftIcon?: React.ReactNode
  readonly rightIcon?: React.ReactNode
  readonly textInputStyle: TextInputStyle
}, forwardedRef: React.ForwardedRef<TextInputComponentRef>) {
  const inputRef: React.RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null)

  function handleOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    onChange?.(event.target.value)
  }

  function handleInputOnKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    onKeyDown?.(event)
  }

  function focusOnInput() {
    inputRef.current?.focus()
  }

  useImperativeHandle(forwardedRef !== null && Object.keys(forwardedRef).length === 0 ? null : forwardedRef, () => {
    return {
      focus: focusOnInput
    }
  })

  function detectStyle(): string {
    switch (textInputStyle) {
      case TextInputStyle.TEXT_INPUT1_PRIMARY:
        return `${styles.textInput1} ${styles.primary}`
      default:
        assertNever(textInputStyle)
    }
  }

  function handleOnMouseDown(event: React.MouseEvent) {
    if (cancelFocusByClick) event.preventDefault()
  }

  return (
    <div
      className={[
        detectStyle(),
        hasError ? styles.error : "",
        needShowCursorPointer && !isDisabled ? styles.cursorPointer : ""
      ].join(" ")}
      onMouseDown={handleOnMouseDown}
      onClick={onClick}
    >
      {leftIcon && (<div className={`${styles.textInputIcon} ${styles.left}`}>{leftIcon}</div>)}
      <input
        readOnly={readOnly}
        type={type ?? TextInputType.TEXT}
        ref={inputRef}
        className={[
          leftIcon ? styles.withLeftIcon : "",
          rightIcon ? styles.withRightIcon : ""
        ].join(" ")}
        value={value ?? ""}
        disabled={isDisabled}
        onChange={handleOnChange}
        onKeyDown={handleInputOnKeyDown}
        placeholder={placeholder ?? ""}
      />
      {rightIcon && (<div className={`${styles.textInputIcon} ${styles.right}`}>{rightIcon}</div>)}
    </div>
  )
}

export default forwardRef(TextInputComponent)

export enum TextInputStyle {
  TEXT_INPUT1_PRIMARY
}

export enum TextInputType {
  TEXT = "text",
  NUMBER = "number",
  EMAIL = "email",
  PASSWORD = "password"
}
