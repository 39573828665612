import { Expose, Type } from "class-transformer"
import NetworkPage from "../../../../core/data/entities/pagination/NetworkPage"
import NetworkOrderReceivingMethod
  from "../../../../core/data/entities/order-receiving-methods/NetworkOrderReceivingMethod"

export default class NetworkOrderReceivingMethodsResponseBody {
  @Expose()
  @Type(() => NetworkOrderReceivingMethod)
  readonly orderReceivingMethods: NetworkOrderReceivingMethod[] | null | undefined

  @Expose()
  @Type(() => NetworkPage)
  readonly page: NetworkPage | null | undefined
}
