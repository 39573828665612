export type TextProvidersMap<TextProvider> = { [key: string]: TextProvider }

export interface I18nParameters<TextProvider> {
  readonly textProviderByLanguage: TextProvidersMap<TextProvider>
  readonly language: string
}

export default class I18n<TextProvider> {
  private readonly textProviderByLanguage: TextProvidersMap<TextProvider>
  private readonly language: string

  constructor(parameters: I18nParameters<TextProvider>) {
    this.textProviderByLanguage = parameters.textProviderByLanguage
    this.language = parameters.language
  }

  getTextProvider(): TextProvider {
    return this.textProviderByLanguage[this.language]
  }
}
