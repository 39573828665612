import DiModule from "../../../../../sqadmin/lib/di/DiModule"
import ObjectsDomainDiModule from "../../../../../sqadmin/features/objects/di/modules/ObjectsDomainDiModule"
import CoreI18nDiModule from "../../../../core/di/modules/CoreI18nDiModule"
import autoBind from "auto-bind"
import React from "react"
import ObjectsPresentationLogicParameters
  from "../../../../../sqadmin/features/objects/presentation/entities/objects/ObjectsPresentationLogicParameters"
import OrdersDomainDiModule from "./OrdersDomainDiModule"
import OrdersViewModel from "../../presentation/pages/orders-page/OrdersViewModel"
import OrdersPage from "../../presentation/pages/orders-page/OrdersPage"
import OrderViewModel from "../../presentation/pages/order-page/OrderViewModel"
import OrderPage from "../../presentation/pages/order-page/OrderPage"
import PaymentStatusesCoreDomainDiModule from "../../../payment-statuses-core/di/PaymentStatusesCoreDomainDiModule"

export default interface OrdersPresentationDiModule {
  provideOrdersPage(): React.ReactNode

  provideOrderPage(): React.ReactNode
}

export class DefaultOrdersPresentationDiModule
  extends DiModule
  implements OrdersPresentationDiModule {

  private readonly objectsDomainDiModule: ObjectsDomainDiModule
  private readonly ordersDomainDiModule: OrdersDomainDiModule
  private readonly paymentStatusesCoreDomainDiModule: PaymentStatusesCoreDomainDiModule
  private readonly coreI18nDiModule: CoreI18nDiModule

  constructor(parameters: {
    readonly objectsDomainDiModule: ObjectsDomainDiModule
    readonly ordersDomainDiModule: OrdersDomainDiModule
    readonly paymentStatusesCoreDomainDiModule: PaymentStatusesCoreDomainDiModule
    readonly coreI18nDiModule: CoreI18nDiModule
  }) {
    super()
    autoBind(this)
    this.objectsDomainDiModule = parameters.objectsDomainDiModule
    this.ordersDomainDiModule = parameters.ordersDomainDiModule
    this.paymentStatusesCoreDomainDiModule = parameters.paymentStatusesCoreDomainDiModule
    this.coreI18nDiModule = parameters.coreI18nDiModule
  }

  provideOrdersPage(): React.ReactNode {
    return <OrdersPage provideViewModel={this.provideOrdersViewModel} />
  }

  provideOrderPage(): React.ReactNode {
    return <OrderPage provideViewModel={this.provideOrderViewModel} />
  }

  private provideOrdersViewModel({
    objectsPresentationLogicParameters
  }: {
    readonly objectsPresentationLogicParameters: ObjectsPresentationLogicParameters
  }): OrdersViewModel {
    return new OrdersViewModel({
      coreI18n: this.coreI18nDiModule.provideCoreI18n(),
      objectsPresentationLogicParameters,
      subscribeToObjectsEventsUseCase: this.objectsDomainDiModule.provideSubscribeToObjectsEventsUseCase({
        key: "orders"
      }),
      unsubscribeFromObjectsEventsUseCase: this.objectsDomainDiModule.provideUnsubscribeFromObjectsEventsUseCase({
        key: "orders"
      }),
      getOrdersUseCase: this.ordersDomainDiModule.provideGetOrdersUseCase()
    })
  }

  private provideOrderViewModel({
    orderId
  }: {
    readonly orderId?: number
  }): OrderViewModel {
    return new OrderViewModel({
      coreI18n: this.coreI18nDiModule.provideCoreI18n(),
      broadcastObjectsEventUseCase: this.objectsDomainDiModule.provideBroadcastObjectsEventUseCase({
        key: "orders"
      }),
      getOrderUseCase: this.ordersDomainDiModule.provideGetOrderUseCase(),
      updateOrderUseCase: this.ordersDomainDiModule.provideUpdateOrderUseCase(),
      destroyOrderUseCase: this.ordersDomainDiModule.provideDestroyOrderUseCase(),
      getOrderProcessingStatusesUseCase: this.ordersDomainDiModule.provideGetOrderProcessingStatusesUseCase(),
      getPaymentStatusesUseCase: this.paymentStatusesCoreDomainDiModule.provideGetPaymentStatusesUseCase(),
      orderId
    })
  }
}
