import { GetObjectsPageResult } from "../../../../../sqadmin/features/objects/domain/results/GetObjectsPageResult"
import Sort from "../../../../../sqadmin/features/objects/presentation/entities/tables/Sort"
import LastItemPagination from "../../../../core/domain/entities/paginations/LastItemPagination"
import BonusProgramLevelsRepository from "../repositories/BonusProgramLevelsRepository"
import BonusProgramLevel from "../../../../core/domain/entities/bonus-program-levels/BonusProgramLevel"
import BonusProgramLevelsFilter from "../../../../core/domain/entities/bonus-program-levels/BonusProgramLevelsFilter"

export default class GetBonusProgramLevelsUseCase {
  private readonly bonusProgramLevelsRepository: BonusProgramLevelsRepository

  constructor(parameters: {
    readonly bonusProgramLevelsRepository: BonusProgramLevelsRepository
  }) {
    this.bonusProgramLevelsRepository = parameters.bonusProgramLevelsRepository
  }

  async call(parameters: GetBonusProgramLevelsParameters): Promise<GetObjectsPageResult<BonusProgramLevel>> {
    return await this.bonusProgramLevelsRepository.getBonusProgramLevels(parameters)
  }
}

export interface GetBonusProgramLevelsParameters {
  readonly filter: BonusProgramLevelsFilter | null | undefined
  readonly sort?: Sort
  readonly pagination?: LastItemPagination
}
