import { Expose } from "class-transformer"

export default class NetworkOrderReceivingRecipient {
  @Expose()
  readonly id: number | null | undefined

  @Expose()
  readonly firstName: string | null | undefined

  @Expose()
  readonly lastName: string | null | undefined

  @Expose()
  readonly middleName: string | null | undefined

  constructor(parameters?: {
    readonly id: number | null | undefined
    readonly firstName: string | null | undefined
    readonly lastName: string | null | undefined
    readonly middleName: string | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.id = parameters.id
    this.firstName = parameters.firstName
    this.lastName = parameters.lastName
    this.middleName = parameters.middleName
  }
}
