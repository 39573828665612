import { Expose } from "class-transformer"

export default class NetworkCountry {
  @Expose()
  readonly id: number | null | undefined

  @Expose()
  readonly name: string | null | undefined

  @Expose()
  readonly callingCode: string | null | undefined

  @Expose()
  readonly phoneNumberFormattingMask: string | null | undefined

  constructor(parameters?: {
    readonly id: number | null | undefined
    readonly name: string | null | undefined
    readonly callingCode: string | null | undefined
    readonly phoneNumberFormattingMask: string | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.id = parameters.id
    this.name = parameters.name
    this.callingCode = parameters.callingCode
    this.phoneNumberFormattingMask = parameters.phoneNumberFormattingMask
  }
}
