import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../errors/NetworkAttributeError"
import AttributeError from "../../../../../sqadmin/core/domain/entities/AttributeError"
import OrderReceivingPriceRuleConditionErrorsObjectAttributes
  from "../../../domain/entities/order-receiving-price-rule-conditions/OrderReceivingPriceRuleConditionErrorsObjectAttributes"

export default class NetworkOrderReceivingPriceRuleConditionErrorsObjectAttributes
  implements OrderReceivingPriceRuleConditionErrorsObjectAttributes {
  @Expose()
  @Type(() => NetworkAttributeError)
  readonly id: AttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly clientId: AttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly type: AttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly value: AttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly position: AttributeError[] | null | undefined
}
