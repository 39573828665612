import DiModule from "../../../../sqadmin/lib/di/DiModule"
import CoreNetworkDiModule from "../../../core/di/modules/CoreNetworkDiModule"
import DefaultOrderReceivingMethodsRepository from "../data/repositories/DefaultOrderReceivingMethodsRepository"
import OrderReceivingMethodsRepository from "../domain/repositories/OrderReceivingMethodsRepository"
import OrderReceivingMethodsNetworkSource from "../data/sources/OrderReceivingMethodsNetworkSource"

export default interface OrderReceivingMethodsCoreDataDiModule {
  provideOrderReceivingMethodsRepository(): OrderReceivingMethodsRepository
}

export class DefaultOrderReceivingMethodsCoreDataDiModule extends DiModule
  implements OrderReceivingMethodsCoreDataDiModule {
  private readonly sqCoreCoreNetworkDiModule: CoreNetworkDiModule

  constructor(parameters: {
    readonly sqCoreCoreNetworkDiModule: CoreNetworkDiModule
  }) {
    super()
    this.sqCoreCoreNetworkDiModule = parameters.sqCoreCoreNetworkDiModule
  }

  provideOrderReceivingMethodsRepository(): OrderReceivingMethodsRepository {
    return this.single(
      DefaultOrderReceivingMethodsRepository.name,
      () => new DefaultOrderReceivingMethodsRepository({
        orderReceivingMethodsNetworkSource: this.provideOrderReceivingMethodsNetworkSource()
      })
    )
  }

  private provideOrderReceivingMethodsNetworkSource(): OrderReceivingMethodsNetworkSource {
    return this.single(
      OrderReceivingMethodsNetworkSource.name,
      () => new OrderReceivingMethodsNetworkSource({
        backendHttpClient: this.sqCoreCoreNetworkDiModule.provideBackendHttpClient()
      })
    )
  }
}
