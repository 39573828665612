import NetworkProductCategoriesRequestFilter from "../entities/NetworkProductCategoriesRequestFilter"
import ProductCategoriesFilter from "../../../../core/domain/entities/product-categories/ProductCategoriesFilter"

export default class ProductCategoriesFiltersMapper {
  mapDomainToNetwork({
    filter
  }: {
    readonly filter: ProductCategoriesFilter
  }): NetworkProductCategoriesRequestFilter {
    return new NetworkProductCategoriesRequestFilter({
      query: filter.query
    })
  }
}
