import { RouteObject } from "react-router-dom"
import { objectIdUrlPart } from "../../../../sqadmin/features/objects/presentation/hooks/useObjectId"
import CoreUrlProvider from "../../../core/presentation/services/CoreUrlProvider"
import ProductsDi from "../di/ProductsDi"

export default function createProductsRoutes({
  di
}: {
  readonly di: ProductsDi
}): RouteObject[] {
  const urlProvider = new CoreUrlProvider()
  return [
    {
      path: urlProvider.buildProductsPath(),
      element: di
        .sqCoreProductsDiComponent
        .productsPresentationDiModule
        .provideProductsPage()
    },
    {
      path: urlProvider.buildNewProductPath(),
      element: di
        .sqCoreProductsDiComponent
        .productsPresentationDiModule
        .provideProductPage()
    },
    {
      path: urlProvider.buildProductPath({ id: `:${objectIdUrlPart}` }),
      element: di
        .sqCoreProductsDiComponent
        .productsPresentationDiModule
        .provideProductPage()
    }
  ]
}
