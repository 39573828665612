import { RouteObject } from "react-router-dom"
import { objectIdUrlPart } from "../../../../sqadmin/features/objects/presentation/hooks/useObjectId"
import CoreUrlProvider from "../../../core/presentation/services/CoreUrlProvider"
import ClientsDi from "../di/ClientsDi"

export default function createClientsRoutes({
  di
}: {
  readonly di: ClientsDi
}): RouteObject[] {
  const urlProvider = new CoreUrlProvider()
  return [
    {
      path: urlProvider.buildClientsPath(),
      element: di
        .sqCoreClientsDiComponent
        .clientsPresentationDiModule
        .provideClientsPage()
    },
    {
      path: urlProvider.buildClientPath({ id: `:${objectIdUrlPart}` }),
      element: di
        .sqCoreClientsDiComponent
        .clientsPresentationDiModule
        .provideClientPage()
    }
  ]
}
