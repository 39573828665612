import React from "react"
import DiModule from "../../../../../sqadmin/lib/di/DiModule"
import ObjectsDomainDiModule from "../../../../../sqadmin/features/objects/di/modules/ObjectsDomainDiModule"
import ProductPage from "../../presentation/pages/product-page/ProductPage"
import ProductViewModel from "../../presentation/pages/product-page/ProductViewModel"
import ProductsPage from "../../presentation/pages/products-page/ProductsPage"
import ProductsViewModel from "../../presentation/pages/products-page/ProductsViewModel"
import ObjectsPresentationLogicParameters
  from "../../../../../sqadmin/features/objects/presentation/entities/objects/ObjectsPresentationLogicParameters"
import autoBind from "auto-bind"
import CoreI18nDiModule from "../../../../core/di/modules/CoreI18nDiModule"
import BonusProgramLevelsCoreDomainDiModule
  from "../../../bonus-program-levels-core/di/BonusProgramLevelsCoreDomainDiModule"
import ConfigurationsCoreDomainDiModule from "../../../configurations-core/di/DefaultConfigurationsCoreDomainDiModule"
import OptionValuesCoreDomainDiModule from "../../../option-values-core/di/OptionValuesCoreDomainDiModule"
import PropertyValuesCoreDomainDiModule from "../../../property-values-core/di/PropertyValuesCoreDomainDiModule"
import PropertiesCoreDomainDiModule from "../../../properties-core/di/PropertiesCoreDomainDiModule"
import ProductCategoriesCoreDomainDiModule
  from "../../../product-categories-core/di/ProductCategoriesCoreDomainDiModule"
import ProductsCoreDomainDiModule from "../../../products-core/di/ProductsCoreDomainDiModule"
import ProductsDomainDiModule from "./ProductsDomainDiModule"

export default interface ProductsPresentationDiModule {
  provideProductsPage(): React.ReactNode

  provideProductPage(): React.ReactNode
}

export class DefaultProductsPresentationDiModule
  extends DiModule
  implements ProductsPresentationDiModule {

  private readonly objectsDomainDiModule: ObjectsDomainDiModule
  private readonly productsCoreDomainDiModule: ProductsCoreDomainDiModule
  private readonly productsDomainDiModule: ProductsDomainDiModule
  private readonly coreI18nDiModule: CoreI18nDiModule
  private readonly bonusProgramLevelsCoreDomainDiModule: BonusProgramLevelsCoreDomainDiModule
  private readonly configurationsCoreDomainDiModule: ConfigurationsCoreDomainDiModule
  private readonly optionValuesCoreDomainDiModule: OptionValuesCoreDomainDiModule
  private readonly propertyValuesCoreDomainDiModule: PropertyValuesCoreDomainDiModule
  private readonly propertiesCoreDomainDiModule: PropertiesCoreDomainDiModule
  private readonly productCategoriesCoreDomainDiModule: ProductCategoriesCoreDomainDiModule

  constructor(parameters: {
    readonly objectsDomainDiModule: ObjectsDomainDiModule
    readonly productsCoreDomainDiModule: ProductsCoreDomainDiModule
    readonly productsDomainDiModule: ProductsDomainDiModule
    readonly coreI18nDiModule: CoreI18nDiModule
    readonly bonusProgramLevelsCoreDomainDiModule: BonusProgramLevelsCoreDomainDiModule
    readonly configurationsCoreDomainDiModule: ConfigurationsCoreDomainDiModule
    readonly optionValuesCoreDomainDiModule: OptionValuesCoreDomainDiModule
    readonly propertyValuesCoreDomainDiModule: PropertyValuesCoreDomainDiModule
    readonly propertiesCoreDomainDiModule: PropertiesCoreDomainDiModule
    readonly productCategoriesCoreDomainDiModule: ProductCategoriesCoreDomainDiModule
  }) {
    super()
    autoBind(this)
    this.objectsDomainDiModule = parameters.objectsDomainDiModule
    this.productsCoreDomainDiModule = parameters.productsCoreDomainDiModule
    this.productsDomainDiModule = parameters.productsDomainDiModule
    this.coreI18nDiModule = parameters.coreI18nDiModule
    this.bonusProgramLevelsCoreDomainDiModule = parameters.bonusProgramLevelsCoreDomainDiModule
    this.configurationsCoreDomainDiModule = parameters.configurationsCoreDomainDiModule
    this.optionValuesCoreDomainDiModule = parameters.optionValuesCoreDomainDiModule
    this.propertyValuesCoreDomainDiModule = parameters.propertyValuesCoreDomainDiModule
    this.propertiesCoreDomainDiModule = parameters.propertiesCoreDomainDiModule
    this.productCategoriesCoreDomainDiModule = parameters.productCategoriesCoreDomainDiModule
  }

  provideProductsPage(): React.ReactNode {
    return <ProductsPage provideViewModel={this.provideProductsViewModel} />
  }

  provideProductPage(): React.ReactNode {
    return <ProductPage provideViewModel={this.provideProductViewModel} />
  }

  private provideProductViewModel({ productId }: { readonly productId?: number }): ProductViewModel {
    return new ProductViewModel({
      coreI18n: this.coreI18nDiModule.provideCoreI18n(),
      broadcastObjectsEventUseCase: this.objectsDomainDiModule.provideBroadcastObjectsEventUseCase({
        key: "products"
      }),
      getProductUseCase: this.productsCoreDomainDiModule.provideGetProductUseCase(),
      createProductUseCase: this.productsCoreDomainDiModule.provideCreateProductUseCase(),
      updateProductUseCase: this.productsCoreDomainDiModule.provideUpdateProductUseCase(),
      destroyProductUseCase: this.productsCoreDomainDiModule.provideDestroyProductUseCase(),
      getProductCategoriesUseCase: this.productCategoriesCoreDomainDiModule.provideGetProductCategoriesUseCase(),
      getOptionsUseCase: this.productsDomainDiModule.provideGetOptionsUseCase(),
      createImageUseCase: this.productsCoreDomainDiModule.provideCreateImageUseCase(),
      getPlacesUseCase: this.productsDomainDiModule.provideGetPlacesUseCase(),
      getPropertiesUseCase: this.propertiesCoreDomainDiModule.provideGetPropertiesUseCase(),
      getBonusProgramLevelsUseCase: this.bonusProgramLevelsCoreDomainDiModule.provideGetBonusProgramLevelsUseCase(),
      getConfigurationUseCase: this.configurationsCoreDomainDiModule.provideGetConfigurationUseCase(),
      getOptionValuesUseCase: this.optionValuesCoreDomainDiModule.provideGetOptionValuesUseCase(),
      getPropertyValuesUseCase: this.propertyValuesCoreDomainDiModule.provideGetPropertyValuesUseCase(),
      productId
    })
  }

  private provideProductsViewModel({
    objectsPresentationLogicParameters
  }: {
    readonly objectsPresentationLogicParameters: ObjectsPresentationLogicParameters
  }): ProductsViewModel {
    return new ProductsViewModel({
      objectsPresentationLogicParameters,
      coreI18n: this.coreI18nDiModule.provideCoreI18n(),
      subscribeToObjectsEventsUseCase: this.objectsDomainDiModule.provideSubscribeToObjectsEventsUseCase({
        key: "products"
      }),
      unsubscribeFromObjectsEventsUseCase: this.objectsDomainDiModule.provideUnsubscribeFromObjectsEventsUseCase({
        key: "products"
      }),
      getProductsUseCase: this.productsCoreDomainDiModule.provideGetProductsUseCase(),
      getProductCategoriesUseCase: this.productCategoriesCoreDomainDiModule.provideGetProductCategoriesUseCase()
    })
  }
}
