import { LoadObjectResult } from "../../../../../sqadmin/features/objects/domain/results/LoadObjectResult"
import BonusProgramLevelsRepository from "../repositories/BonusProgramLevelsRepository"
import BonusProgramLevel from "../../../../core/domain/entities/bonus-program-levels/BonusProgramLevel"

export default class GetBonusProgramLevelUseCase {
  private readonly bonusProgramLevelsRepository: BonusProgramLevelsRepository

  constructor(parameters: {
    readonly bonusProgramLevelsRepository: BonusProgramLevelsRepository
  }) {
    this.bonusProgramLevelsRepository = parameters.bonusProgramLevelsRepository
  }

  async call(parameters: { readonly bonusProgramLevelId: number }): Promise<LoadObjectResult<BonusProgramLevel>> {
    return await this.bonusProgramLevelsRepository.getBonusProgramLevel(parameters)
  }
}
