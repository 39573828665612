import React from "react"
import DiModule from "../../../../../sqadmin/lib/di/DiModule"
import ObjectsPresentationLogicParameters
  from "../../../../../sqadmin/features/objects/presentation/entities/objects/ObjectsPresentationLogicParameters"
import PropertiesViewModel from "../../presentation/pages/properties-page/PropertiesViewModel"
import ObjectsDomainDiModule from "../../../../../sqadmin/features/objects/di/modules/ObjectsDomainDiModule"
import autoBind from "auto-bind"
import PropertiesPage from "../../presentation/pages/properties-page/PropertiesPage"
import PropertyViewModel from "../../presentation/pages/property-page/PropertyViewModel"
import PropertyPage from "../../presentation/pages/property-page/PropertyPage"
import CoreI18nDiModule from "../../../../core/di/modules/CoreI18nDiModule"
import PropertiesCoreDomainDiModule from "../../../properties-core/di/PropertiesCoreDomainDiModule"

export default interface PropertiesPresentationDiModule {
  providePropertiesPage(): React.ReactNode

  providePropertyPage(): React.ReactNode
}

export class DefaultPropertiesPresentationDiModule
  extends DiModule
  implements PropertiesPresentationDiModule {

  private readonly objectsDomainDiModule: ObjectsDomainDiModule
  private readonly propertiesCoreDomainDiModule: PropertiesCoreDomainDiModule
  private readonly coreI18nDiModule: CoreI18nDiModule

  constructor(parameters: {
    readonly objectsDomainDiModule: ObjectsDomainDiModule
    readonly propertiesCoreDomainDiModule: PropertiesCoreDomainDiModule
    readonly coreI18nDiModule: CoreI18nDiModule
  }) {
    super()
    autoBind(this)
    this.objectsDomainDiModule = parameters.objectsDomainDiModule
    this.propertiesCoreDomainDiModule = parameters.propertiesCoreDomainDiModule
    this.coreI18nDiModule = parameters.coreI18nDiModule
  }

  providePropertiesPage(): React.ReactNode {
    return <PropertiesPage provideViewModel={this.providePropertiesViewModel} />
  }

  providePropertyPage(): React.ReactNode {
    return <PropertyPage provideViewModel={this.providePropertyViewModel} />
  }

  private providePropertiesViewModel({
    objectsPresentationLogicParameters
  }: {
    readonly objectsPresentationLogicParameters: ObjectsPresentationLogicParameters
  }): PropertiesViewModel {
    return new PropertiesViewModel({
      objectsPresentationLogicParameters,
      coreI18n: this.coreI18nDiModule.provideCoreI18n(),
      subscribeToObjectsEventsUseCase: this.objectsDomainDiModule.provideSubscribeToObjectsEventsUseCase({
        key: "properties"
      }),
      unsubscribeFromObjectsEventsUseCase: this.objectsDomainDiModule.provideUnsubscribeFromObjectsEventsUseCase({
        key: "properties"
      }),
      getPropertiesUseCase: this.propertiesCoreDomainDiModule.provideGetPropertiesUseCase()
    })
  }

  private providePropertyViewModel({
    propertyId
  }: {
    readonly propertyId?: number
  }): PropertyViewModel {
    return new PropertyViewModel({
      coreI18n: this.coreI18nDiModule.provideCoreI18n(),
      broadcastObjectsEventUseCase: this.objectsDomainDiModule.provideBroadcastObjectsEventUseCase({
        key: "properties"
      }),
      getPropertyUseCase: this.propertiesCoreDomainDiModule.provideGetPropertyUseCase(),
      createPropertyUseCase: this.propertiesCoreDomainDiModule.provideCreatePropertyUseCase(),
      updatePropertyUseCase: this.propertiesCoreDomainDiModule.provideUpdatePropertyUseCase(),
      destroyPropertyUseCase: this.propertiesCoreDomainDiModule.provideDestroyPropertyUseCase(),
      propertyId
    })
  }
}
