import DiModule from "../../../../sqadmin/lib/di/DiModule"
import CoreNetworkDiModule from "../../../core/di/modules/CoreNetworkDiModule"
import OrderPaymentMethodsRepository from "../domain/repositories/OrderPaymentMethodsRepository"
import DefaultOrderPaymentMethodsRepository from "../data/repositories/DefaultOrderPaymentMethodsRepository"
import OrderPaymentMethodsNetworkSource from "../data/sources/OrderPaymentMethodsNetworkSource"

export default interface OrderPaymentMethodsCoreDataDiModule {
  provideOrderPaymentMethodsRepository(): OrderPaymentMethodsRepository
}

export class DefaultOrderPaymentMethodsCoreDataDiModule extends DiModule
  implements OrderPaymentMethodsCoreDataDiModule {
  private readonly sqCoreCoreNetworkDiModule: CoreNetworkDiModule

  constructor(parameters: {
    readonly sqCoreCoreNetworkDiModule: CoreNetworkDiModule
  }) {
    super()
    this.sqCoreCoreNetworkDiModule = parameters.sqCoreCoreNetworkDiModule
  }

  provideOrderPaymentMethodsRepository(): OrderPaymentMethodsRepository {
    return this.single(
      DefaultOrderPaymentMethodsRepository.name,
      () => new DefaultOrderPaymentMethodsRepository({
        orderPaymentMethodsNetworkSource: this.provideOrderPaymentMethodsNetworkSource()
      })
    )
  }

  private provideOrderPaymentMethodsNetworkSource(): OrderPaymentMethodsNetworkSource {
    return this.single(
      OrderPaymentMethodsNetworkSource.name,
      () => new OrderPaymentMethodsNetworkSource({
        backendHttpClient: this.sqCoreCoreNetworkDiModule.provideBackendHttpClient()
      })
    )
  }
}
