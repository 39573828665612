import NetworkCreateSessionErrorsObjectAttributes from "./NetworkCreateSessionErrorsObjectAttributes"
import { Expose, Type } from "class-transformer"
import SqadminNetworkCreateSessionErrorsObject
  from "../../../../../sqadmin/core/data/entities/network/sessions/NetworkCreateSessionErrorsObject"

export default class NetworkCreateSessionErrorsObject implements SqadminNetworkCreateSessionErrorsObject {
  @Expose()
  @Type(() => NetworkCreateSessionErrorsObjectAttributes)
  readonly attributes?: NetworkCreateSessionErrorsObjectAttributes | null
}
