import ImageOption from "./ImageOption"
import React, { useMemo, useRef } from "react"
import styles from "./ImagePickerComponent.module.scss"
import { v4 as uuidv4 } from "uuid"
import LoadingIndicatorComponent from "../../../../design/loading-indicator/LoadingIndicatorComponent"
import ButtonComponent, { ButtonStyle } from "../../../../design/button/ButtonComponent"
import TextComponent, { TextStyle } from "../../../../design/text/TextComponent"
import isPresent from "../../../../lib/isPresent"
import ImageComponent from "../image/ImageComponent"

export interface ImagePickerComponentProps {
  readonly imageOptions: ImageOption[] | null | undefined
  readonly disabled?: boolean
  readonly isUploadingFile?: boolean
  readonly hasError?: boolean
  readonly selectButtonText?: string
  readonly errorMessage?: string | null
  readonly onChange: (file: File | null | undefined) => void
}

export default function ImagePickerComponent({
  imageOptions,
  disabled,
  isUploadingFile,
  hasError,
  selectButtonText,
  errorMessage,
  onChange
}: ImagePickerComponentProps) {
  const uuid = useMemo(() => uuidv4(), [])
  const inputRef: React.RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null)
  const needShowImages = isPresent(imageOptions) && imageOptions.length > 0
  const imagePreviewHeight: number = 120

  function handleOnClick() {
    inputRef.current?.click()
  }

  function handleOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value: File | null | undefined = (() => {
      if (event.target.files) {
        return event.target.files[0]
      } else {
        return null
      }
    })()

    if (value !== null) {
      onChange(value)
    }

    event.target.value = ""
  }

  return (
    <div className={styles.root}>

      {needShowImages && !isUploadingFile && (

        <div className={styles.imagesContainer}>
          {imageOptions?.map((imageOption) => {
              const width = isPresent(imageOption.height) && isPresent(imageOption.width) ?
                imageOption.width * imagePreviewHeight / imageOption.height : undefined

              return (
                <div key={imageOption.url!}>
                  {isPresent(imageOption.name) && (
                    <div className={styles.imageTitle}>
                      <TextComponent textStyle={TextStyle.BODY2_PRIMARY}>{imageOption.name}</TextComponent>
                    </div>
                  )}
                  <div
                    className={styles.imageContainer}
                    style={{ width: width }}
                  >
                    <ImageComponent
                      url={imageOption!.url!}
                      width={imageOption?.width}
                      height={imageOption?.height}
                      originalSizeUrl={imageOption?.originalSizeUrl}
                      originalWidth={imageOption?.originalWidth}
                      originalHeight={imageOption?.originalHeight}
                    />
                  </div>
                </div>
              )
            }
          )}
        </div>
      )}

      <div
        className={styles.imageContainer}
        style={needShowImages && !isUploadingFile ? { display: "none" } : undefined}
      >
        {!isUploadingFile && (
          <label
            htmlFor={uuid}
            className={styles.imageChooseButton}
          >
            <TextComponent textStyle={TextStyle.LABEL1_PRIMARY}>{selectButtonText}</TextComponent>
          </label>
        )}

        <input
          ref={inputRef}
          id={uuid}
          type="file"
          accept="image/*"
          disabled={disabled || isUploadingFile}
          onChange={handleOnChange}
        />

        {isUploadingFile && <div className={styles.imageLoader}><LoadingIndicatorComponent /></div>}

      </div>

      {
        hasError && (
          <div className={styles.error}>
            <TextComponent textStyle={TextStyle.ERROR1_PRIMARY}>{errorMessage}</TextComponent>
          </div>
        )
      }

      <ButtonComponent
        text={selectButtonText}
        buttonStyle={ButtonStyle.TEXT1_PRIMARY}
        isDisabled={disabled || isUploadingFile}
        onClick={handleOnClick}
      />

    </div>
  )
}
