import CreateOrderPaymentMethodUseCase from "../domain/use-cases/CreateOrderPaymentMethodUseCase"
import DestroyOrderPaymentMethodUseCase from "../domain/use-cases/DestroyOrderPaymentMethodUseCase"
import GetOrderPaymentMethodsUseCase from "../domain/use-cases/GetOrderPaymentMethodsUseCase"
import GetOrderPaymentMethodUseCase from "../domain/use-cases/GetOrderPaymentMethodUseCase"
import UpdateOrderPaymentMethodUseCase from "../domain/use-cases/UpdateOrderPaymentMethodUseCase"
import DiModule from "../../../../sqadmin/lib/di/DiModule"
import OrderPaymentMethodsCoreDataDiModule from "./OrderPaymentMethodsCoreDataDiModule"
import GetPaymentProvidersUseCase from "../domain/use-cases/GetPaymentProvidersUseCase"

export default interface OrderPaymentMethodsCoreDomainDiModule {
  provideCreateOrderPaymentMethodUseCase(): CreateOrderPaymentMethodUseCase

  provideDestroyOrderPaymentMethodUseCase(): DestroyOrderPaymentMethodUseCase

  provideGetOrderPaymentMethodsUseCase(): GetOrderPaymentMethodsUseCase

  provideGetOrderPaymentMethodUseCase(): GetOrderPaymentMethodUseCase

  provideUpdateOrderPaymentMethodUseCase(): UpdateOrderPaymentMethodUseCase

  provideGetPaymentProvidersUseCase(): GetPaymentProvidersUseCase
}

export class DefaultOrderPaymentMethodsCoreDomainDiModule extends DiModule
  implements OrderPaymentMethodsCoreDomainDiModule {
  private readonly orderPaymentMethodsCoreDataDiModule: OrderPaymentMethodsCoreDataDiModule

  constructor(parameters: {
    readonly orderPaymentMethodsCoreDataDiModule: OrderPaymentMethodsCoreDataDiModule
  }) {
    super()
    this.orderPaymentMethodsCoreDataDiModule = parameters.orderPaymentMethodsCoreDataDiModule
  }

  provideCreateOrderPaymentMethodUseCase(): CreateOrderPaymentMethodUseCase {
    return this.single(
      CreateOrderPaymentMethodUseCase.name,
      () => new CreateOrderPaymentMethodUseCase({
        orderPaymentMethodsRepository: this.orderPaymentMethodsCoreDataDiModule.provideOrderPaymentMethodsRepository()
      })
    )
  }

  provideDestroyOrderPaymentMethodUseCase(): DestroyOrderPaymentMethodUseCase {
    return this.single(
      DestroyOrderPaymentMethodUseCase.name,
      () => new DestroyOrderPaymentMethodUseCase({
        orderPaymentMethodsRepository: this.orderPaymentMethodsCoreDataDiModule.provideOrderPaymentMethodsRepository()
      })
    )
  }

  provideGetOrderPaymentMethodsUseCase(): GetOrderPaymentMethodsUseCase {
    return this.single(
      GetOrderPaymentMethodsUseCase.name,
      () => new GetOrderPaymentMethodsUseCase({
        orderPaymentMethodsRepository: this.orderPaymentMethodsCoreDataDiModule.provideOrderPaymentMethodsRepository()
      })
    )
  }

  provideGetOrderPaymentMethodUseCase(): GetOrderPaymentMethodUseCase {
    return this.single(
      GetOrderPaymentMethodUseCase.name,
      () => new GetOrderPaymentMethodUseCase({
        orderPaymentMethodsRepository: this.orderPaymentMethodsCoreDataDiModule.provideOrderPaymentMethodsRepository()
      })
    )
  }

  provideUpdateOrderPaymentMethodUseCase(): UpdateOrderPaymentMethodUseCase {
    return this.single(
      UpdateOrderPaymentMethodUseCase.name,
      () => new UpdateOrderPaymentMethodUseCase({
        orderPaymentMethodsRepository: this.orderPaymentMethodsCoreDataDiModule.provideOrderPaymentMethodsRepository()
      })
    )
  }

  provideGetPaymentProvidersUseCase(): GetPaymentProvidersUseCase {
    return this.single(
      GetPaymentProvidersUseCase.name,
      () => new GetPaymentProvidersUseCase({
        orderPaymentMethodsRepository: this.orderPaymentMethodsCoreDataDiModule.provideOrderPaymentMethodsRepository()
      })
    )
  }
}
