import React, { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"

export enum ModalOpenType {
  NEW_PAGE,
  SAME_PAGE
}

let closeByEscapeListener: ((event: KeyboardEvent) => void) | null = null
const onCloseRequestedCallbackIdsStack: Array<number> = []
const onCloseRequestedCallbackByModalId: { [key: number]: ((() => void) | undefined) } = {}
let modalId = 1

function noMoreModals(): boolean {
  return onCloseRequestedCallbackIdsStack.length === 0
}

function onlyOneModal(): boolean {
  return onCloseRequestedCallbackIdsStack.length === 1
}

function addCurrentOnCloseRequested(id: number) {
  onCloseRequestedCallbackIdsStack.push(id)
}

function removeCurrentOnCloseRequested(id: number) {
  const index = onCloseRequestedCallbackIdsStack.indexOf(id)
  onCloseRequestedCallbackIdsStack.splice(index, 1)
}

function setOnCloseRequested(id: number, listener: () => void) {
  onCloseRequestedCallbackByModalId[id] = listener
}

function deleteOnCloseRequested(id: number) {
  delete onCloseRequestedCallbackByModalId[id]
}

function isOpened(id: number) {
  return onCloseRequestedCallbackIdsStack.some((stackId: number) => stackId === id)
}

function enableBodyScroll() {
  setBodyOverflow("auto")
}

function disableBodyScroll() {
  setBodyOverflow("hidden")
}

function setBodyOverflow(overflow: string) {
  const body = document.querySelector("body")

  if (body === null) {
    return
  }

  body.style.overflow = overflow
}

function enableCloseByEscape() {
  const listener = (event: KeyboardEvent) => {
    const keyName = event.key
    const escapeKeyName = "Escape"

    if (keyName === escapeKeyName) {
      const onCloseRequestedIdIndex = onCloseRequestedCallbackIdsStack.length - 1
      const onCloseRequestedId = onCloseRequestedCallbackIdsStack[onCloseRequestedIdIndex]
      const onCloseRequested = onCloseRequestedCallbackByModalId[onCloseRequestedId]!
      onCloseRequested()
    }
  }

  document.addEventListener("keydown", listener)
  closeByEscapeListener = listener
}

function disableCloseByEscape() {
  if (closeByEscapeListener === null) {
    return
  }

  document.removeEventListener("keydown", closeByEscapeListener)
}

export interface ModalWindowProps {
  readonly children?: React.ReactNode
  readonly openType?: ModalOpenType
  readonly parentURL?: string
  readonly isVisible?: boolean
  readonly onCloseRequested?: () => void
}

export default function ModalWindowComponent({
  children,
  openType = ModalOpenType.SAME_PAGE,
  parentURL,
  isVisible: isVisibleDefault = true,
  onCloseRequested: onCloseRequestedDefault
}: ModalWindowProps) {
  const modalIdRef = useRef<number | null>(null)
  const previousIsVisibleRef = useRef<boolean | null>(null)
  const navigate = useNavigate()

  const onCloseRequested = getOnCloseRequested()
  const [isVisible, setIsVisible] = useState(isVisibleDefault)

  useEffect(() => {
    if (isVisibleDefault !== isVisible) {
      setIsVisible(isVisibleDefault)
    }
  }, [isVisibleDefault])

  useEffect(() => {
    setModalVisibility(isVisible)
  }, [isVisible])

  useEffect(() => {
    setOnCloseRequested(getModalId(), onCloseRequested)
  }, [onCloseRequested])

  function getModalId(): number {
    if (modalIdRef.current === null) {
      modalIdRef.current = modalId++
    }

    return modalIdRef.current!
  }

  useEffect(() => {
    return () => {
      if (isOpened(getModalId())) {
        decreaseModalCount()
      }

      deleteOnCloseRequested(getModalId())
    }
  }, [])

  function getOnCloseRequested() {
    switch (openType) {
      case ModalOpenType.NEW_PAGE:
        return onCloseRequestedDefault ?? (() => setIsVisible(false))
      case ModalOpenType.SAME_PAGE:
        return onCloseRequestedDefault!
      default:
        return onCloseRequestedDefault!
    }
  }

  function setModalVisibility(isVisible: boolean) {
    const previousIsVisible = previousIsVisibleRef.current

    if (isVisible) {
      increaseModalCount()
    } else if (!isVisible && previousIsVisible === true) {
      if (openType === ModalOpenType.NEW_PAGE) {
        navigateBack()
      } else {
        decreaseModalCount()
      }
    }

    previousIsVisibleRef.current = isVisible
  }

  function increaseModalCount() {
    addCurrentOnCloseRequested(getModalId())

    if (onlyOneModal()) {
      enableCloseByEscape()
      disableBodyScroll()
    }
  }

  function decreaseModalCount() {
    removeCurrentOnCloseRequested(getModalId())

    if (noMoreModals()) {
      disableCloseByEscape()
      enableBodyScroll()
    }
  }

  function navigateBack() {
    if (parentURL !== undefined) {
      navigate(parentURL, { replace: true })
    } else {
      navigate(-1)
    }
  }

  if (!isVisible) {
    return <></>
  }

  return (
    <>{children}</>
  )
}
