import { Expose, Type } from "class-transformer"
import DashboardCode from "../../../domain/entities/dashboards/DashboardCode"
import NetworkBlock from "../blocks/NetworkBlock"

export default class NetworkDashboard {
  @Expose()
  readonly id: number | null | undefined

  @Expose()
  readonly code: DashboardCode | null | undefined

  @Expose()
  @Type(() => NetworkBlock)
  readonly blocks: NetworkBlock[] | null | undefined

  @Expose()
  readonly name: string | null | undefined

  constructor(parameters?: {
    readonly id: number | null | undefined
    readonly code: DashboardCode | null | undefined
    readonly blocks: NetworkBlock[] | null | undefined
    readonly name: string | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.id = parameters.id
    this.code = parameters.code
    this.blocks = parameters.blocks
    this.name = parameters.name
  }
}
