import NetworkHeader from "../entities/header/NetworkHeader"
import Header from "../../domain/entities/header/Header"

export default class HeadersMapper {
  mapNetworkToDomain({
    header
  }: {
    readonly header: NetworkHeader
  }): Header {
    return {
      leftContentType: header.leftContentType,
      rightContentType: header.rightContentType,
      centerContentType: header.centerContentType
    }
  }

  mapDomainToNetwork({
    header
  }: {
    readonly header: Header
  }): NetworkHeader {
    return new NetworkHeader({
      leftContentType: header.leftContentType,
      rightContentType: header.rightContentType,
      centerContentType: header.centerContentType
    })
  }
}
