import { Link, NavLink, Outlet } from "react-router-dom"
import React from "react"
import styles from "./RootComponent.module.scss"
import UiMenuItem from "../../../../../core/presentation/entities/UiMenuItem"
import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import { useCoreTextProvider } from "../../../../../core/presentation/contexts/CoreTextProviderContext"

import { LocationContext } from "../../../../../lib/view-model/useLocationContext"
import { LocationContextProvider } from "../../../../../lib/view-model/useLocationContextProvider"
import { ReactComponent as LogoutSvg } from "../../../../../core/assets/icons/ic_logout.svg"
import { useCoreTheme } from "../../../../../core/presentation/contexts/CoreThemeProviderContext"
import CoreTheme from "../../../../../core/presentation/entities/CoreTheme"
import SqadminCoreUrlProvider from "../../../../../core/presentation/services/CoreUrlProvider"
import { Helmet } from "react-helmet"

interface Props {
  readonly onLogoutClicked: () => void
  readonly menuItems: UiMenuItem[]
  readonly locationContextProvider: LocationContextProvider
}

export default function RootComponent({
  onLogoutClicked,
  menuItems,
  locationContextProvider
}: Props) {
  const coreTextProvider: CoreTextProvider = useCoreTextProvider()
  const coreTheme: CoreTheme = useCoreTheme()
  const sqadminUrlProvider = new SqadminCoreUrlProvider()

  const calculateNavLinkClassName = ({ isActive }: { readonly isActive: boolean }) => {
    return isActive ? styles.active : ""
  }

  return (
    <>
      <Helmet>
        <title>{coreTextProvider.applicationName()}</title>
      </Helmet>
      <div className={styles.sideMenu}>
        <Link to={sqadminUrlProvider.buildRootUrl()}>
          <div className={styles.header}>
            <img
              src={coreTheme.logoUrl}
              alt={coreTheme.logoUrl}
            />
          </div>
        </Link>
        <nav className={styles.menuItems}>
          <ul>
            {menuItems.map((menuItem: UiMenuItem) => (
              <li key={menuItem.url}>
                <NavLink to={menuItem.url} className={calculateNavLinkClassName}>
                  {menuItem.title}
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
        <div className={styles.footer}>
          <ul>
            <li>
              <a onClick={onLogoutClicked}>
                <LogoutSvg />
                <div>{coreTextProvider.logout()}</div>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.page}>
          {locationContextProvider.locationId && (
            <Outlet
              context={{
                locationId: locationContextProvider.locationId,
                getOrCreateViewModel: locationContextProvider.getOrCreateViewModel
              } as LocationContext}
            />
          )}
        </div>
      </div>
    </>
  )
}
