import ProductCategoriesRepository from "../../domain/repositories/ProductCategoriesRepository"
import ProductCategoriesNetworkSource, {
  GetProductCategoriesNetworkResult
} from "../sources/ProductCategoriesNetworkSource"
import NetworkProductCategory from "../../../../core/data/entities/product-categories/NetworkProductCategory"
import ProductCategoriesMapper from "../../../../core/data/mappers/ProductCategoriesMapper"
import GetObjectNetworkResult from "../../../../core/data/results/GetObjectNetworkResult"
import CreateObjectNetworkResult from "../../../../core/data/results/CreateObjectNetworkResult"
import NetworkProductCategoryError from "../../../../core/data/entities/product-categories/NetworkProductCategoryError"
import ProductCategoryError from "../../../../core/domain/entities/product-categories/ProductCategoryError"
import UpdateObjectNetworkResult from "../../../../core/data/results/UpdateObjectNetworkResult"
import DestroyObjectNetworkResult from "../../../../core/data/results/DestroyObjectNetworkResult"
import ProductCategory from "../../../../core/domain/entities/product-categories/ProductCategory"
import PagesMapper from "../../../../core/data/mappers/PagesMapper"
import LastItemPaginationsMapper from "../../../../core/data/mappers/LastItemPaginationsMapper"
import SortMapper from "../../../../core/data/mappers/SortMapper"
import { LoadObjectResult } from "../../../../../sqadmin/features/objects/domain/results/LoadObjectResult"
import { CreateObjectResult } from "../../../../../sqadmin/features/objects/domain/results/CreateObjectResult"
import { UpdateObjectResult } from "../../../../../sqadmin/features/objects/domain/results/UpdateObjectResult"
import { DestroyObjectResult } from "../../../../../sqadmin/features/objects/domain/results/DestroyObjectResult"
import {
  GetProductCategoriesParameters
} from "../../domain/use-cases/product-categories/GetProductCategoriesUseCase"
import { GetObjectsPageResult } from "../../../../../sqadmin/features/objects/domain/results/GetObjectsPageResult"
import ProductCategoriesFiltersMapper from "../mappers/ProductCategoriesFiltersMapper"

export default class DefaultProductCategoriesRepository implements ProductCategoriesRepository {
  private readonly productCategoriesNetworkSource: ProductCategoriesNetworkSource

  constructor(parameters: {
    readonly productCategoriesNetworkSource: ProductCategoriesNetworkSource
  }) {
    this.productCategoriesNetworkSource = parameters.productCategoriesNetworkSource
  }

  async getProductCategories({
    filter,
    pagination,
    sort
  }: GetProductCategoriesParameters): Promise<GetObjectsPageResult<ProductCategory>> {
    const result: GetProductCategoriesNetworkResult = await this.productCategoriesNetworkSource.getProductCategories({
      filter: filter && new ProductCategoriesFiltersMapper().mapDomainToNetwork({ filter }),
      pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({ pagination }),
      sort: sort && new SortMapper().mapDomainToNetwork({ sort })
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: {
            objects: result.data.productCategories!.map((productCategory: NetworkProductCategory) => {
              return new ProductCategoriesMapper().mapNetworkToDomain({
                productCategory: productCategory
              })
            }),
            page: new PagesMapper().mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      default:
        return result
    }
  }

  async getProductCategory({
    productCategoryId
  }: {
    readonly productCategoryId: number
  }): Promise<LoadObjectResult<ProductCategory>> {
    const result: GetObjectNetworkResult<NetworkProductCategory> =
      await this.productCategoriesNetworkSource.getProductCategory({
        productCategoryId
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new ProductCategoriesMapper().mapNetworkToDomain({
            productCategory: result.data
          })
        }
      default:
        return result
    }
  }

  async createProductCategory({
    productCategory
  }: {
    readonly productCategory: ProductCategory
  }): Promise<CreateObjectResult<ProductCategory, ProductCategoryError>> {
    const result: CreateObjectNetworkResult<NetworkProductCategory, NetworkProductCategoryError> =
      await this.productCategoriesNetworkSource.createProductCategory({
        productCategory: new ProductCategoriesMapper().mapDomainToNetwork({
          productCategory: productCategory
        })
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new ProductCategoriesMapper().mapNetworkToDomain({
            productCategory: result.data
          })
        }
      default:
        return result
    }
  }

  async updateProductCategory({
    productCategoryId,
    productCategory
  }: {
    readonly productCategoryId: number
    readonly productCategory: ProductCategory
  }): Promise<UpdateObjectResult<ProductCategory, ProductCategoryError>> {
    const result: UpdateObjectNetworkResult<NetworkProductCategory, NetworkProductCategoryError> =
      await this.productCategoriesNetworkSource.updateProductCategory({
        productCategoryId,
        productCategory: new ProductCategoriesMapper().mapDomainToNetwork({
          productCategory: productCategory
        })
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new ProductCategoriesMapper().mapNetworkToDomain({
            productCategory: result.data
          })
        }
      default:
        return result
    }
  }

  async destroyProductCategory({
    productCategoryId
  }: {
    readonly productCategoryId: number
  }): Promise<DestroyObjectResult<ProductCategoryError>> {
    const result: DestroyObjectNetworkResult<NetworkProductCategoryError> =
      await this.productCategoriesNetworkSource.destroyProductCategory({
        productCategoryId
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: undefined
        }
      default:
        return result
    }
  }
}
