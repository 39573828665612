import NetworkConfigurationEntityPropertyPolicy
  from "../entities/configurations/NetworkConfigurationEntityPropertyPolicy"
import ConfigurationEntityPropertyPolicy from "../../domain/entities/configurations/ConfigurationEntityPropertyPolicy"

export default class ConfigurationEntityPropertyPoliciesMapper {
  mapNetworkToDomain({
    entityPolicy
  }: {
    readonly entityPolicy: NetworkConfigurationEntityPropertyPolicy
  }): ConfigurationEntityPropertyPolicy {
    return {
      canSaveOnUpdate: entityPolicy.canSaveOnUpdate,
      canSaveOnCreate: entityPolicy.canSaveOnCreate
    }
  }

  mapDomainToNetwork({
    entityPolicy
  }: {
    readonly entityPolicy: ConfigurationEntityPropertyPolicy
  }): NetworkConfigurationEntityPropertyPolicy {
    return new NetworkConfigurationEntityPropertyPolicy({
      canSaveOnUpdate: entityPolicy.canSaveOnUpdate,
      canSaveOnCreate: entityPolicy.canSaveOnCreate
    })
  }
}
