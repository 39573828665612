import { Expose, Type } from "class-transformer"
import NetworkExecutionError from "../errors/NetworkExecutionError"
import NetworkOrderReceivingMethodErrorsObject from "./NetworkOrderReceivingMethodErrorsObject"

export default class NetworkOrderReceivingMethodError extends NetworkExecutionError {

  @Expose()
  @Type(() => NetworkOrderReceivingMethodErrorsObject)
  readonly errorsObject?: NetworkOrderReceivingMethodErrorsObject | null
}
