import OrderPaymentMethodsRepository from "../repositories/OrderPaymentMethodsRepository"
import { GetObjectsPageResult } from "../../../../../sqadmin/features/objects/domain/results/GetObjectsPageResult"
import PaymentProvider from "../../../../core/domain/entities/payment-providers/PaymentProvider"

export default class GetPaymentProvidersUseCase {
  private readonly orderPaymentMethodsRepository: OrderPaymentMethodsRepository

  constructor(parameters: {
    readonly orderPaymentMethodsRepository: OrderPaymentMethodsRepository
  }) {
    this.orderPaymentMethodsRepository = parameters.orderPaymentMethodsRepository
  }

  async call(): Promise<GetObjectsPageResult<PaymentProvider>> {
    return this.orderPaymentMethodsRepository.getPaymentProviders()
  }
}
