import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import { useCoreTextProvider } from "../../../../../core/presentation/contexts/CoreTextProviderContext"
import { useNumberObjectId } from "../../../../../../sqadmin/features/objects/presentation/hooks/useObjectId"
import { useViewModel } from "../../../../../../sqadmin/lib/view-model/ViewModel"
import ObjectComponent from "../../../../../../sqadmin/features/objects/presentation/components/object/ObjectComponent"
import React from "react"
import OrderReceivingMethodViewModel from "./OrderReceivingMethodViewModel"

export interface OrderReceivingMethodProps {
  readonly provideViewModel: (parameters: { readonly orderReceivingMethodId?: number }) => OrderReceivingMethodViewModel
}

export default function OrderReceivingMethodPage({
  provideViewModel
}: OrderReceivingMethodProps) {
  const coreTextProvider: CoreTextProvider = useCoreTextProvider()
  const orderReceivingMethodId: number | undefined = useNumberObjectId()

  const viewModel: OrderReceivingMethodViewModel = useViewModel(() => provideViewModel({
    orderReceivingMethodId
  }))

  return (
    <ObjectComponent
      newObjectTitle={coreTextProvider.newOrderReceivingMethod()}
      existedObjectTitle={coreTextProvider.orderReceivingMethod()}
      observableObjectViewState={viewModel.observableObjectViewState}
      onObjectViewEvent={viewModel.onViewObjectEvent}
    />
  )
}
