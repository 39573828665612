import { Expose } from "class-transformer"
import NetworkProperty from "../properties/NetworkProperty"

export default class NetworkPropertyValue {
  @Expose()
  readonly id?: number | null

  @Expose()
  readonly clientId?: string | null

  @Expose()
  readonly name?: string | null

  @Expose()
  readonly position?: number | null

  @Expose()
  readonly property?: NetworkProperty | null

  @Expose()
  readonly externalCode: string | null | undefined

  constructor(parameters?: {
    readonly id?: number | null
    readonly clientId?: string | null
    readonly name?: string | null
    readonly position?: number | null
    readonly externalCode: string | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.id = parameters.id
    this.clientId = parameters.clientId
    this.name = parameters.name
    this.position = parameters.position
    this.externalCode = parameters.externalCode
  }
}
