import { RouteObject } from "react-router-dom"
import { objectIdUrlPart } from "../../../../sqadmin/features/objects/presentation/hooks/useObjectId"
import CoreUrlProvider from "../../../core/presentation/services/CoreUrlProvider"
import PropertiesDi from "../di/PropertiesDi"

export default function createPropertiesRoutes({
  di
}: {
  readonly di: PropertiesDi
}): RouteObject[] {
  const urlProvider = new CoreUrlProvider()
  return [
    {
      path: urlProvider.buildPropertiesPath(),
      element: di
        .sqCorePropertiesDiComponent
        .propertiesPresentationDiModule
        .providePropertiesPage()
    },
    {
      path: urlProvider.buildNewPropertyPath(),
      element: di
        .sqCorePropertiesDiComponent
        .propertiesPresentationDiModule
        .providePropertyPage()
    },
    {
      path: urlProvider.buildPropertyPath({ id: `:${objectIdUrlPart}` }),
      element: di
        .sqCorePropertiesDiComponent
        .propertiesPresentationDiModule
        .providePropertyPage()
    }
  ]
}
