import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../errors/NetworkAttributeError"
import {
  PaymentStatusErrorsObjectAttributes
} from "../../../domain/entities/payment-statuses/PaymentStatusErrorsObjectAttributes"

export default class NetworkPaymentStatusErrorsObjectAttributes implements PaymentStatusErrorsObjectAttributes {

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly id: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly name: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly externalCode: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly displayName: NetworkAttributeError[] | null | undefined
}
