import { GetObjectsPageResult } from "../../../../../sqadmin/features/objects/domain/results/GetObjectsPageResult"
import Sort from "../../../../../sqadmin/features/objects/presentation/entities/tables/Sort"
import LastItemPagination from "../../../../core/domain/entities/paginations/LastItemPagination"
import Place from "../../../../core/domain/entities/places/Place"
import PlacesRepository from "../repositories/PlacesRepository"
import PlacesFilter from "../../../../core/domain/entities/places/PlacesFilter"

export default class GetPlacesUseCase {
  private readonly placesRepository: PlacesRepository

  constructor(parameters: {
    readonly placesRepository: PlacesRepository
  }) {
    this.placesRepository = parameters.placesRepository
  }

  async call(parameters: GetPlacesParameters): Promise<GetObjectsPageResult<Place>> {
    return this.placesRepository.getPlaces(parameters)
  }
}

export interface GetPlacesParameters {
  readonly filter?: PlacesFilter
  readonly sort?: Sort
  readonly pagination?: LastItemPagination
}
