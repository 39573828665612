import DiModule from "../../../../../sqadmin/lib/di/DiModule"
import ObjectsDomainDiModule from "../../../../../sqadmin/features/objects/di/modules/ObjectsDomainDiModule"
import CoreI18nDiModule from "../../../../core/di/modules/CoreI18nDiModule"
import autoBind from "auto-bind"
import React from "react"
import PaymentStatusesCoreDomainDiModule from "../../../payment-statuses-core/di/PaymentStatusesCoreDomainDiModule"
import PaymentStatusesPage from "../../presentation/pages/payment-statuses-page/PaymentStatusesPage"
import PaymentStatusPage from "../../presentation/pages/payment-status-page/PaymentStatusPage"
import ObjectsPresentationLogicParameters
  from "../../../../../sqadmin/features/objects/presentation/entities/objects/ObjectsPresentationLogicParameters"
import PaymentStatusesViewModel from "../../presentation/pages/payment-statuses-page/PaymentStatusesViewModel"
import PaymentStatusViewModel from "../../presentation/pages/payment-status-page/PaymentStatusViewModel"

export default interface PaymentStatusesPresentationDiModule {
  providePaymentStatusesPage(): React.ReactNode

  providePaymentStatusPage(): React.ReactNode
}

export class DefaultPaymentStatusesPresentationDiModule
  extends DiModule
  implements PaymentStatusesPresentationDiModule {

  private readonly objectsDomainDiModule: ObjectsDomainDiModule
  private readonly paymentStatusesCoreDomainDiModule: PaymentStatusesCoreDomainDiModule
  private readonly coreI18nDiModule: CoreI18nDiModule

  constructor(parameters: {
    readonly objectsDomainDiModule: ObjectsDomainDiModule
    readonly paymentStatusesCoreDomainDiModule: PaymentStatusesCoreDomainDiModule
    readonly coreI18nDiModule: CoreI18nDiModule
  }) {
    super()
    autoBind(this)
    this.objectsDomainDiModule = parameters.objectsDomainDiModule
    this.paymentStatusesCoreDomainDiModule = parameters.paymentStatusesCoreDomainDiModule
    this.coreI18nDiModule = parameters.coreI18nDiModule
  }

  providePaymentStatusesPage(): React.ReactNode {
    return <PaymentStatusesPage provideViewModel={this.providePaymentStatusesViewModel} />
  }

  providePaymentStatusPage(): React.ReactNode {
    return <PaymentStatusPage provideViewModel={this.providePaymentStatusViewModel} />
  }

  private providePaymentStatusesViewModel({
    objectsPresentationLogicParameters
  }: {
    readonly objectsPresentationLogicParameters: ObjectsPresentationLogicParameters
  }): PaymentStatusesViewModel {
    return new PaymentStatusesViewModel({
      coreI18n: this.coreI18nDiModule.provideCoreI18n(),
      objectsPresentationLogicParameters,
      subscribeToObjectsEventsUseCase: this.objectsDomainDiModule.provideSubscribeToObjectsEventsUseCase({
        key: "paymentStatuses"
      }),
      unsubscribeFromObjectsEventsUseCase: this.objectsDomainDiModule.provideUnsubscribeFromObjectsEventsUseCase({
        key: "paymentStatuses"
      }),
      getPaymentStatusesUseCase: this.paymentStatusesCoreDomainDiModule
        .provideGetPaymentStatusesUseCase()
    })
  }

  private providePaymentStatusViewModel({
    paymentStatusId
  }: {
    readonly paymentStatusId?: number
  }): PaymentStatusViewModel {
    return new PaymentStatusViewModel({
      coreI18n: this.coreI18nDiModule.provideCoreI18n(),
      broadcastObjectsEventUseCase: this.objectsDomainDiModule.provideBroadcastObjectsEventUseCase({
        key: "paymentStatuses"
      }),
      getPaymentStatusUseCase: this.paymentStatusesCoreDomainDiModule
        .provideGetPaymentStatusUseCase(),
      createPaymentStatusUseCase: this.paymentStatusesCoreDomainDiModule
        .provideCreatePaymentStatusUseCase(),
      updatePaymentStatusUseCase: this.paymentStatusesCoreDomainDiModule
        .provideUpdatePaymentStatusUseCase(),
      destroyPaymentStatusUseCase: this.paymentStatusesCoreDomainDiModule
        .provideDestroyPaymentStatusUseCase(),
      paymentStatusId: paymentStatusId
    })
  }
}
