import { Expose, Type } from "class-transformer"
import NetworkOptionValuesRequestFilter from "./NetworkOptionValuesRequestFilter"

export default class NetworkOptionValuesRequestQuery {
  @Expose()
  @Type(() => NetworkOptionValuesRequestFilter)
  readonly filter: NetworkOptionValuesRequestFilter | null | undefined

  @Expose()
  readonly sort: string | null | undefined

  constructor(parameters: {
    readonly filter: NetworkOptionValuesRequestFilter | null | undefined
    readonly sort: string | null | undefined
  }) {
    this.filter = parameters.filter
    this.sort = parameters.sort
  }
}
