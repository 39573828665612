import FormField, { FormFieldParameters, FormFieldViewState, FormFieldViewStateParameters } from "../FormField"

export default class BooleanFormField<DomainObject, ErrorsObject> extends FormField<DomainObject, ErrorsObject> {
  private readonly getValue: (object: DomainObject) => boolean | null | undefined
  private readonly setValue: (object: DomainObject, value: boolean) => DomainObject
  private readonly getText: (object: DomainObject) => string
  private readonly afterValueChanged?: (object: DomainObject) => void

  constructor(parameters: FormFieldParameters<DomainObject, ErrorsObject> & {
    readonly getValue: (object: DomainObject) => boolean | null | undefined
    readonly setValue: (object: DomainObject, value: boolean) => DomainObject
    readonly getText: (object: DomainObject) => string
    readonly afterValueChanged?: (object: DomainObject) => void
  }) {
    super(parameters)
    this.getValue = parameters.getValue
    this.setValue = parameters.setValue
    this.getText = parameters.getText
    this.afterValueChanged = parameters.afterValueChanged
  }

  getViewState(object: DomainObject, errorsObject?: ErrorsObject): FormFieldViewState {
    return new BooleanFormFieldViewState({
      ...this.getFormFieldViewStateParameters(object, errorsObject),
      value: this.getValue(object),
      text: this.getText(object),
      onChange: (value: boolean) => {
        this.setObject(this.setValue(object, value))
        this.afterValueChanged?.(object)
        this.setAndShowLoadedObjectViewState()
      }
    })
  }
}

export class BooleanFormFieldViewState extends FormFieldViewState {
  readonly value: boolean | null | undefined
  readonly text: string
  onChange: (value: boolean) => void

  constructor(parameters: FormFieldViewStateParameters & {
    readonly onChange: (value: boolean) => void
    readonly value: boolean | null | undefined
    readonly text: string
  }) {
    super(parameters)
    this.value = parameters.value
    this.onChange = parameters.onChange
    this.text = parameters.text
  }
}
