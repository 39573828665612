import { Expose, Type } from "class-transformer"
import NetworkPage from "../../../../core/data/entities/pagination/NetworkPage"
import NetworkDiscountRule from "../../../../core/data/entities/discount-rules/NetworkDiscountRule"

export default class NetworkDiscountRulesResponseBody {
  @Expose()
  @Type(() => NetworkDiscountRule)
  readonly discountRules: NetworkDiscountRule[] | null | undefined

  @Expose()
  @Type(() => NetworkPage)
  readonly page: NetworkPage | null | undefined
}
