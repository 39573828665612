import NetworkOrderReceivingPriceRuleCondition
  from "../entities/order-receiving-price-rule-conditions/NetworkOrderReceivingPriceRuleCondition"
import OrderReceivingPriceRuleCondition
  from "../../domain/entities/order-receiving-price-rule-conditions/OrderReceivingPriceRuleCondition"
import { v4 as uuidv4 } from "uuid"

export default class OrderReceivingPriceRuleConditionsMapper {
  mapNetworkToDomain({
    condition
  }: {
    readonly condition: NetworkOrderReceivingPriceRuleCondition
  }): OrderReceivingPriceRuleCondition {
    return {
      id: condition.id,
      clientId: uuidv4(),
      type: condition.type,
      value: condition.value,
      position: condition.position
    }
  }

  mapDomainToNetwork({
    condition
  }: {
    readonly condition: OrderReceivingPriceRuleCondition
  }): NetworkOrderReceivingPriceRuleCondition {
    return new NetworkOrderReceivingPriceRuleCondition({
      id: condition.id,
      clientId: condition.clientId,
      type: condition.type,
      value: condition.value,
      position: condition.position
    })
  }
}
