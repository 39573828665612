import React, { createContext, useContext } from "react"
import CoreTextProvider from "../../i18n/CoreTextProvider"
import CoreI18n from "../../i18n/CoreI18n"

export const CoreTextProviderContext: React.Context<CoreTextProvider | null> =
  createContext<CoreTextProvider | null>(null)

export function useCoreTextProvider(): CoreTextProvider {
  return useContext(CoreTextProviderContext)!
}

export interface CoreTextProviderProviderProps {
  readonly children?: React.ReactNode
  readonly coreI18n: CoreI18n
}

export function CoreTextProviderProvider({
  children,
  coreI18n
}: CoreTextProviderProviderProps) {
  return (
    <CoreTextProviderContext.Provider value={coreI18n.getTextProvider()}>
      {children}
    </CoreTextProviderContext.Provider>
  )
}
