import NetworkOrderReceivingMethod from "../entities/order-receiving-methods/NetworkOrderReceivingMethod"
import OrderReceivingMethod from "../../domain/entities/order-receiving-methods/OrderReceivingMethod"
import OrderReceivingPriceRulesMapper from "./OrderReceivingPriceRulesMapper"
import OrderShippingMethodsMapper from "./OrderShippingMethodsMapper"
import OrderShippingMethod from "../../domain/entities/order-shipping-methods/OrderShippingMethod"
import OrderReceivingPriceRule from "../../domain/entities/order-receiving-price-rules/OrderReceivingPriceRule"
import OrderReceivingPriceRuleCalculationType
  from "../../domain/entities/order-receiving-price-rules/OrderReceivingPriceRuleCalculationType"

export default class OrderReceivingMethodsMapper {
  mapNetworkToDomain({
    orderReceivingMethod
  }: {
    readonly orderReceivingMethod: NetworkOrderReceivingMethod
  }): OrderReceivingMethod {
    return {
      id: orderReceivingMethod.id,
      name: orderReceivingMethod.name,
      type: orderReceivingMethod.type,
      position: orderReceivingMethod.position,
      isDefault: orderReceivingMethod.isDefault,
      isAvailable: orderReceivingMethod.isAvailable,
      fulfillmentProviderType: orderReceivingMethod.fulfillmentProviderType,
      orderReceivingPriceRules: orderReceivingMethod.orderReceivingPriceRules &&
        orderReceivingMethod.orderReceivingPriceRules.map(orderReceivingPriceRule => {
          return new OrderReceivingPriceRulesMapper().mapNetworkToDomain({ orderReceivingPriceRule })
        }),
      orderShippingMethod: orderReceivingMethod.orderShippingMethod && new OrderShippingMethodsMapper()
        .mapNetworkToDomain({ orderShippingMethod: orderReceivingMethod.orderShippingMethod }),
      forOfflineSale: orderReceivingMethod.forOfflineSale
    }
  }

  mapDomainToNetwork({
    orderReceivingMethod
  }: {
    readonly orderReceivingMethod: OrderReceivingMethod
  }): NetworkOrderReceivingMethod {
    const hasPriceRulesWithFulfillmentProvider = this.hasPriceRulesWithFulfillmentProvider(orderReceivingMethod)
    const orderShippingMethod = hasPriceRulesWithFulfillmentProvider ?
      orderReceivingMethod.orderShippingMethod ?? this.createOrderShippingMethod() : undefined

    return new NetworkOrderReceivingMethod({
      id: orderReceivingMethod.id,
      name: orderReceivingMethod.name,
      type: orderReceivingMethod.type,
      position: orderReceivingMethod.position,
      isDefault: orderReceivingMethod.isDefault,
      isAvailable: orderReceivingMethod.isAvailable,
      fulfillmentProviderType: orderReceivingMethod.fulfillmentProviderType,
      orderReceivingPriceRules: orderReceivingMethod.orderReceivingPriceRules &&
        orderReceivingMethod.orderReceivingPriceRules.map(orderReceivingPriceRule => {
          return new OrderReceivingPriceRulesMapper().mapDomainToNetwork({ orderReceivingPriceRule })
        }),
      orderShippingMethod: orderShippingMethod && new OrderShippingMethodsMapper()
        .mapDomainToNetwork({ orderShippingMethod }),
      forOfflineSale: orderReceivingMethod.forOfflineSale
    })
  }

  private createOrderShippingMethod(): OrderShippingMethod {
    return {
      type: undefined,
      id: undefined,
      settlementId: undefined,
      settlement: undefined,
      orderShippingTariff: undefined,
      orderShippingTariffId: undefined
    }
  }

  private hasPriceRulesWithFulfillmentProvider(orderReceivingMethod: OrderReceivingMethod) {
    const foundPriceRulesWithFulfillmentProvider = orderReceivingMethod.orderReceivingPriceRules
      ?.filter((priceRule: OrderReceivingPriceRule) => {
        return priceRule.calculationType === OrderReceivingPriceRuleCalculationType.FULFILLMENT_PROVIDER
      })

    return (foundPriceRulesWithFulfillmentProvider?.length ?? 0) > 0
  }
}
