import { Expose, Type } from "class-transformer"
import NetworkPage from "../../../../core/data/entities/pagination/NetworkPage"
import NetworkOrderPaymentMethod from "../../../../core/data/entities/order-payment-methods/NetworkOrderPaymentMethod"

export default class NetworkOrderPaymentMethodsResponseBody {
  @Expose()
  @Type(() => NetworkOrderPaymentMethod)
  readonly orderPaymentMethods: NetworkOrderPaymentMethod[] | null | undefined

  @Expose()
  @Type(() => NetworkPage)
  readonly page: NetworkPage | null | undefined
}
