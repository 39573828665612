import { Expose } from "class-transformer"

export default class NetworkOrdersRequestFilter {
  @Expose()
  readonly query: string | null | undefined

  constructor(parameters: {
    readonly query: string | null | undefined
  }) {
    this.query = parameters.query
  }
}
