import { Expose, Transform } from "class-transformer"
import transformDate from "../../../../../sqadmin/lib/transformDate"
import ApiLogItemServiceType from "../../../domain/entities/api-log-items/ApiLogItemServiceType"
import ApiLogItemDirection from "../../../domain/entities/api-log-items/ApiLogItemDirection"
import ApiLogItemRequestType from "../../../domain/entities/api-log-items/ApiLogItemRequestType"
import ApiLogItemResultType from "../../../domain/entities/api-log-items/ApiLogItemResultType"

export default class NetworkApiLogItem {
  @Expose()
  readonly id: number | null | undefined

  @Expose()
  readonly serviceType: ApiLogItemServiceType | null | undefined

  @Expose()
  readonly direction: ApiLogItemDirection | null | undefined

  @Expose()
  @Transform(transformDate)
  readonly startedAt: Date | null | undefined

  @Expose()
  @Transform(transformDate)
  readonly finishedAt: Date | null | undefined

  @Expose()
  readonly url: string | null | undefined

  @Expose()
  readonly shortUrl: string | null | undefined

  @Expose()
  readonly requestType: ApiLogItemRequestType | null | undefined

  @Expose()
  readonly httpStatus: number | null | undefined

  @Expose()
  readonly resultType: ApiLogItemResultType | null | undefined

  @Expose()
  readonly requestBody: string | null | undefined

  @Expose()
  readonly responseBody: string | null | undefined

  constructor(parameters?: {
    readonly id: number | null | undefined
    readonly serviceType: ApiLogItemServiceType | null | undefined
    readonly direction: ApiLogItemDirection | null | undefined
    readonly startedAt: Date | null | undefined
    readonly finishedAt: Date | null | undefined
    readonly url: string | null | undefined
    readonly shortUrl: string | null | undefined
    readonly requestType: ApiLogItemRequestType | null | undefined
    readonly httpStatus: number | null | undefined
    readonly resultType: ApiLogItemResultType | null | undefined
    readonly requestBody: string | null | undefined
    readonly responseBody: string | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.id = parameters.id
    this.serviceType = parameters.serviceType
    this.direction = parameters.direction
    this.startedAt = parameters.startedAt
    this.finishedAt = parameters.finishedAt
    this.url = parameters.url
    this.shortUrl = parameters.shortUrl
    this.requestType = parameters.requestType
    this.httpStatus = parameters.httpStatus
    this.resultType = parameters.resultType
    this.requestBody = parameters.requestBody
    this.responseBody = parameters.responseBody
  }
}
