import { Expose } from "class-transformer"
import NetworkDiscountRule from "../../../../core/data/entities/discount-rules/NetworkDiscountRule"

export default class NetworkDiscountRuleRequestBody {
  @Expose()
  readonly discountRule: NetworkDiscountRule

  constructor(parameters: {
    readonly discountRule: NetworkDiscountRule
  }) {
    this.discountRule = parameters.discountRule
  }
}
