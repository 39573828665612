import CoreI18n from "../../../../../core/i18n/CoreI18n"
import SingleSelectFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/SingleSelectFormField"
import OrderReceivingPriceRule
  from "../../../../../core/domain/entities/order-receiving-price-rules/OrderReceivingPriceRule"
import OrderReceivingPriceRuleErrorsObject
  from "../../../../../core/domain/entities/order-receiving-price-rules/OrderReceivingPriceRuleErrorsObject"
import OrderReceivingPriceRuleCalculationType
  from "../../../../../core/domain/entities/order-receiving-price-rules/OrderReceivingPriceRuleCalculationType"
import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import assertNever from "../../../../../../sqadmin/lib/assertNever"
import ListFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/ListFormField"
import OrderReceivingPriceRuleCondition
  from "../../../../../core/domain/entities/order-receiving-price-rule-conditions/OrderReceivingPriceRuleCondition"
import OrderReceivingPriceRuleConditionErrorsObject
  from "../../../../../core/domain/entities/order-receiving-price-rule-conditions/OrderReceivingPriceRuleConditionErrorsObject"
import { v4 as uuidv4 } from "uuid"
import OrderReceivingPriceRuleConditionType
  from "../../../../../core/domain/entities/order-receiving-price-rule-conditions/OrderReceivingPriceRuleConditionType"
import DecimalFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/DecimalFormField"
import { Decimal } from "decimal.js"
import Calculator from "../../../../../core/domain/entities/calculators/Calculator"
import CalculationType from "../../../../../core/domain/entities/calculators/CalculationType"
import OrderReceivingMethod from "../../../../../core/domain/entities/order-receiving-methods/OrderReceivingMethod"
import FulfillmentProviderType
  from "../../../../../core/domain/entities/order-receiving-methods/FulfillmentProviderType"

export class OrderReceivingPriceRulesFields {
  private readonly coreI18n: CoreI18n

  constructor(parameters: {
    readonly coreI18n: CoreI18n
  }) {
    this.coreI18n = parameters.coreI18n
  }

  createFields(onCurrentRootObject: () => OrderReceivingMethod) {
    return [
      this.createOrderReceivingMethodPriceRuleConditionsFormField(),
      this.createOrderReceivingMethodPriceRuleCalculationTypeFormField(onCurrentRootObject),
      this.createOrderReceivingMethodPriceRuleCalculatorValue()
    ]
  }

  private createOrderReceivingMethodPriceRuleCalculationTypeFormField(onCurrentRootObject: () => OrderReceivingMethod) {
    return new SingleSelectFormField<
      OrderReceivingPriceRule,
      OrderReceivingPriceRuleErrorsObject,
      OrderReceivingPriceRuleCalculationType
    >({
      isSearchBarVisible: false,
      getObjects: async() => {
        return {
          type: "success",
          data: {
            objects: this.filterOrderReceivingPriceRuleCalculationTypeIfNeed(onCurrentRootObject),
            page: { hasMore: false }
          }
        }
      },
      getTitle: () => this.coreI18n.getTextProvider().calculationType(),
      getValue: (orderReceivingPriceRule: OrderReceivingPriceRule) => orderReceivingPriceRule.calculationType,
      setValue: (
        orderReceivingPriceRule: OrderReceivingPriceRule,
        type: OrderReceivingPriceRuleCalculationType | null | undefined
      ): OrderReceivingPriceRule => {
        return {
          ...orderReceivingPriceRule,
          calculationType: type,
          calculator: undefined
        }
      },
      getErrors: (
        orderReceivingPriceRuleErrorsObject?: OrderReceivingPriceRuleErrorsObject
      ) => orderReceivingPriceRuleErrorsObject
        ?.attributes
        ?.calculationType,
      getOptionId: (type: OrderReceivingPriceRuleCalculationType) => type.valueOf(),
      getOptionText: (type: OrderReceivingPriceRuleCalculationType) => {
        return this.detectOrderReceivingPriceRuleCalculationTypeDisplayName(type)
      }
    })
  }

  private filterOrderReceivingPriceRuleCalculationTypeIfNeed(onCurrentRootObject: () => OrderReceivingMethod) {
    const orderReceivingMethod = onCurrentRootObject()
    const types = Object.values(OrderReceivingPriceRuleCalculationType)

    if (orderReceivingMethod.fulfillmentProviderType === FulfillmentProviderType.INTERNAL) {
      return types.filter((type) => type === OrderReceivingPriceRuleCalculationType.CALCULATOR)
    }

    return types
  }

  private detectOrderReceivingPriceRuleCalculationTypeDisplayName(type: OrderReceivingPriceRuleCalculationType) {
    const coreTextProvider: CoreTextProvider = this.coreI18n.getTextProvider()

    switch (type) {
      case OrderReceivingPriceRuleCalculationType.FULFILLMENT_PROVIDER:
        return coreTextProvider.calculationTypeFulfillmentProvider()
      case OrderReceivingPriceRuleCalculationType.CALCULATOR:
        return coreTextProvider.calculationTypeFixed()
      default:
        assertNever(type)
    }
  }

  private createOrderReceivingMethodPriceRuleConditionsFormField() {
    return new ListFormField<OrderReceivingPriceRule, OrderReceivingPriceRuleErrorsObject,
      OrderReceivingPriceRuleCondition, OrderReceivingPriceRuleConditionErrorsObject>({
      isSwitchPositionVisible: true,
      getTitle: () => this.coreI18n.getTextProvider().orderReceivingPriceRuleConditions(),
      getValue: (orderReceivingPriceRule: OrderReceivingPriceRule) => orderReceivingPriceRule.conditions,
      getErrors: (
        orderReceivingPriceRuleErrorsObject?: OrderReceivingPriceRuleErrorsObject
      ) => orderReceivingPriceRuleErrorsObject?.attributes?.conditions,
      setValue: (
        orderReceivingPriceRule: OrderReceivingPriceRule,
        conditions: OrderReceivingPriceRuleCondition[] | null
      ): OrderReceivingPriceRule => ({
        ...orderReceivingPriceRule,
        conditions
      }),
      getNestedObjectId: (condition: OrderReceivingPriceRuleCondition) => condition.clientId!,
      getNestedObjectTitle: (_, nestedObjectIndex) => this.coreI18n.getTextProvider()
        .orderReceivingPriceRuleCondition({ number: nestedObjectIndex + 1 }),
      getNestedErrorsObject: (
        orderReceivingPriceRuleCondition: OrderReceivingPriceRuleCondition,
        orderReceivingPriceRuleErrorsObject?: OrderReceivingPriceRuleErrorsObject
      ) => {
        return orderReceivingPriceRuleErrorsObject?.conditions?.find(
          (orderReceivingPriceRuleConditionErrorsObject: OrderReceivingPriceRuleConditionErrorsObject) => {
            return orderReceivingPriceRuleConditionErrorsObject.clientId === orderReceivingPriceRuleCondition.clientId
          })
      },
      getPosition: (condition: OrderReceivingPriceRuleCondition) => condition.position!,
      setPosition: (
        condition: OrderReceivingPriceRuleCondition,
        position: number
      ): OrderReceivingPriceRuleCondition => {
        return { ...condition, position }
      },
      buildNewValue: () => ({
        id: undefined,
        clientId: uuidv4(),
        value: undefined,
        type: undefined,
        position: undefined
      }),
      fields: [
        this.createOrderReceivingMethodPriceRuleConditionTypeFormField(),
        this.createOrderReceivingMethodPriceRuleConditionValueFormField()
      ]
    })
  }

  private createOrderReceivingMethodPriceRuleConditionTypeFormField() {
    return new SingleSelectFormField<
      OrderReceivingPriceRuleCondition,
      OrderReceivingPriceRuleConditionErrorsObject,
      OrderReceivingPriceRuleConditionType
    >({
      isSearchBarVisible: false,
      getObjects: async() => {
        return {
          type: "success",
          data: {
            objects: Object.values(OrderReceivingPriceRuleConditionType),
            page: { hasMore: false }
          }
        }
      },
      getTitle: () => this.coreI18n.getTextProvider().type(),
      getValue: (condition: OrderReceivingPriceRuleCondition) => condition.type,
      setValue: (
        condition: OrderReceivingPriceRuleCondition,
        type: OrderReceivingPriceRuleConditionType | null
      ): OrderReceivingPriceRuleCondition => {
        return {
          ...condition,
          type: type
        }
      },
      getErrors: (
        conditionErrorsObject?: OrderReceivingPriceRuleConditionErrorsObject
      ) => conditionErrorsObject?.attributes?.type,
      getOptionId: (type: OrderReceivingPriceRuleConditionType) => type.valueOf(),
      getOptionText: (type: OrderReceivingPriceRuleConditionType) => {
        return this.detectOrderReceivingPriceRuleConditionTypeDisplayName(type)
      }
    })
  }

  private detectOrderReceivingPriceRuleConditionTypeDisplayName(type: OrderReceivingPriceRuleConditionType) {
    const coreTextProvider: CoreTextProvider = this.coreI18n.getTextProvider()

    switch (type) {
      case OrderReceivingPriceRuleConditionType.ORDER_AMOUNT_GREATER_THAN:
        return coreTextProvider.orderReceivingPriceRuleConditionTypeOrderAmountGreaterThan()
      case OrderReceivingPriceRuleConditionType.ORDER_AMOUNT_GREATER_THAN_OR_EQUALS_TO:
        return coreTextProvider.orderReceivingPriceRuleConditionTypeOrderAmountGreaterThanOrEqualsTo()
      case OrderReceivingPriceRuleConditionType.ORDER_AMOUNT_LESS_THAN:
        return coreTextProvider.orderReceivingPriceRuleConditionTypeOrderAmountLessThan()
      case OrderReceivingPriceRuleConditionType.ORDER_AMOUNT_LESS_THAN_OR_EQUALS_TO:
        return coreTextProvider.orderReceivingPriceRuleConditionTypeOrderAmountLessThanOrEqualsTo()
      default:
        assertNever(type)
    }
  }

  private createOrderReceivingMethodPriceRuleConditionValueFormField() {
    return new DecimalFormField<OrderReceivingPriceRuleCondition, OrderReceivingPriceRuleConditionErrorsObject>({
      getTitle: () => this.coreI18n.getTextProvider().value(),
      getValue: (condition: OrderReceivingPriceRuleCondition) => condition.value,
      setValue: (condition: OrderReceivingPriceRuleCondition, value: Decimal | undefined | null) => ({
        ...condition,
        value
      }),
      getErrors: (conditionErrorsObject?: OrderReceivingPriceRuleConditionErrorsObject) => {
        return conditionErrorsObject
          ?.attributes
          ?.value
      }
    })
  }

  private createOrderReceivingMethodPriceRuleCalculatorValue() {
    return new DecimalFormField<OrderReceivingPriceRule, OrderReceivingPriceRuleErrorsObject>({
      getVisible: (orderReceivingPriceRule: OrderReceivingPriceRule) => {
        return orderReceivingPriceRule.calculationType === OrderReceivingPriceRuleCalculationType.CALCULATOR
      },
      getTitle: () => this.coreI18n.getTextProvider().value(),
      getValue: (orderReceivingPriceRule: OrderReceivingPriceRule) => orderReceivingPriceRule.calculator?.value,
      setValue: (orderReceivingPriceRule: OrderReceivingPriceRule, value: Decimal | null | undefined) => {
        return {
          ...orderReceivingPriceRule,
          calculator: {
            ...orderReceivingPriceRule.calculator ?? this.createCalculator(),
            value
          }
        }
      },
      getErrors: (
        orderReceivingPriceRuleErrorsObject?: OrderReceivingPriceRuleErrorsObject
      ) => orderReceivingPriceRuleErrorsObject?.calculator?.attributes?.value
    })
  }

  private createCalculator(): Calculator {
    return {
      calculationType: CalculationType.FIXED,
      id: undefined,
      value: undefined,
      roundingDecimalsCount: 2
    }
  }
}
