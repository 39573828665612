import { Expose } from "class-transformer"

export default class NetworkDimensions {
  @Expose()
  readonly width: number | null | undefined

  @Expose()
  readonly height: number | null | undefined

  constructor(parameters?: {
    readonly width: number | null | undefined
    readonly height: number | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.width = parameters.width
    this.height = parameters.height
  }
}
