import { Expose, Type } from "class-transformer"
import OrderReceivingMethodErrorsObject
  from "../../../domain/entities/order-receiving-methods/OrderReceivingMethodErrorsObject"
import NetworkOrderReceivingMethodErrorsObjectAttributes from "./NetworkOrderReceivingMethodErrorsObjectAttributes"
import NetworkOrderReceivingPriceRuleErrorsObject
  from "../order-receiving-price-rules/NetworkOrderReceivingPriceRuleErrorsObject"
import NetworkOrderShippingMethodErrorsObject from "../order-shipping-methods/NetworkOrderShippingMethodErrorsObject"

export default class NetworkOrderReceivingMethodErrorsObject implements OrderReceivingMethodErrorsObject {
  @Expose()
  @Type(() => NetworkOrderReceivingMethodErrorsObjectAttributes)
  readonly attributes: NetworkOrderReceivingMethodErrorsObjectAttributes | null | undefined

  @Expose()
  @Type(() => NetworkOrderReceivingPriceRuleErrorsObject)
  readonly orderReceivingPriceRules: NetworkOrderReceivingPriceRuleErrorsObject[] | null | undefined

  @Expose()
  @Type(() => NetworkOrderShippingMethodErrorsObject)
  readonly orderShippingMethod: NetworkOrderShippingMethodErrorsObject | null | undefined
}
