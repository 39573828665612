import { Expose, Type } from "class-transformer"
import NetworkBatchPlaceErrorsObjectAttributes from "./NetworkBatchPlaceErrorsObjectAttributes"
import BatchPlaceErrorsObject from "../../../domain/entities/batch-paces/BatchPlaceErrorsObject"

export default class NetworkBatchPlaceErrorsObject implements BatchPlaceErrorsObject {
  @Expose()
  readonly clientId: string | null | undefined

  @Expose()
  readonly placeId: number | null | undefined

  @Expose()
  @Type(() => NetworkBatchPlaceErrorsObjectAttributes)
  readonly attributes: NetworkBatchPlaceErrorsObjectAttributes | null | undefined
}
