import KeyValueStorage from "./KeyValueStorage"

export default class BrowserKeyValueStorage implements KeyValueStorage {
  private readonly keyPrefix: string
  private readonly storage: Storage

  constructor(parameters: { keyPrefix: string }) {
    this.keyPrefix = parameters.keyPrefix
    this.storage = window.localStorage
  }

  put(key: string, value: string) {
    this.storage.setItem(this.buildKey(key), value)
  }

  get(key: string): string | null {
    return this.storage.getItem(this.buildKey(key))
  }

  isSet(key: string): boolean {
    return this.storage.getItem(this.buildKey(key)) !== null
  }

  remove(key: string) {
    this.storage.removeItem(this.buildKey(key))
  }

  private buildKey(key: string): string {
    return `${this.keyPrefix}${key}`
  }
}
