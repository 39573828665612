import { Expose, Type } from "class-transformer"
import NetworkPropertyErrorsObjectAttributes from "./NetworkPropertyErrorsObjectAttributes"
import NetworkPropertyValueErrorsObject from "../property-values/NetworkPropertyValueErrorsObject"
import PropertyErrorsObject from "../../../domain/entities/properties/PropertyErrorsObject"

export default class NetworkPropertyErrorsObject implements PropertyErrorsObject {
  @Expose()
  @Type(() => NetworkPropertyErrorsObjectAttributes)
  readonly attributes: NetworkPropertyErrorsObjectAttributes | null | undefined

  @Expose()
  @Type(() => NetworkPropertyValueErrorsObject)
  readonly values: NetworkPropertyValueErrorsObject[] | null | undefined
}
