import LastItemPaginationsMapper from "../../../../core/data/mappers/LastItemPaginationsMapper"
import SortMapper from "../../../../core/data/mappers/SortMapper"
import PagesMapper from "../../../../core/data/mappers/PagesMapper"
import PropertiesRepository from "../../domain/repositories/PropertiesRepository"
import { GetPropertiesParameters, GetPropertiesResult } from "../../domain/use-cases/GetPropertiesUseCase"
import PropertiesNetworkSource, {
  CreatePropertyNetworkResult,
  DestroyPropertyNetworkResult,
  GetPropertiesNetworkResult,
  GetPropertyNetworkResult,
  UpdatePropertyNetworkResult
} from "../sources/PropertiesNetworkSource"
import PropertiesFiltersMapper from "../mappers/PropertiesFiltersMapper"
import PropertiesMapper from "../../../../core/data/mappers/PropertiesMapper"
import { GetPropertyParameters, GetPropertyResult } from "../../domain/use-cases/GetPropertyUseCase"
import { CreatePropertyParameters, CreatePropertyResult } from "../../domain/use-cases/CreatePropertyUseCase"
import { UpdatePropertyParameters, UpdatePropertyResult } from "../../domain/use-cases/UpdatePropertyUseCase"
import { DestroyPropertyParameters, DestroyPropertyResult } from "../../domain/use-cases/DestroyPropertyUseCase"
import NetworkProperty from "../../../../core/data/entities/properties/NetworkProperty"

export default class DefaultPropertiesRepository implements PropertiesRepository {
  private readonly propertiesNetworkSource: PropertiesNetworkSource

  constructor(parameters: {
    readonly propertiesNetworkSource: PropertiesNetworkSource
  }) {
    this.propertiesNetworkSource = parameters.propertiesNetworkSource
  }

  async getProperties({
    filter,
    pagination,
    sort
  }: GetPropertiesParameters): Promise<GetPropertiesResult> {
    const result: GetPropertiesNetworkResult = await this.propertiesNetworkSource.getProperties({
      filter: filter && new PropertiesFiltersMapper().mapDomainToNetwork({ filter }),
      pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({ pagination }),
      sort: sort && new SortMapper().mapDomainToNetwork({ sort })
    })

    switch (result.type) {
      case "success": {
        const propertiesMapper = new PropertiesMapper()

        return {
          type: "success",
          data: {
            objects: result.data.properties!.map((property: NetworkProperty) => {
              return propertiesMapper.mapNetworkToDomain({ property })
            }),
            page: new PagesMapper().mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      }
      default:
        return result
    }
  }

  async getProperty({
    propertyId
  }: GetPropertyParameters): Promise<GetPropertyResult> {
    const result: GetPropertyNetworkResult =
      await this.propertiesNetworkSource.getProperty({
        propertyId
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new PropertiesMapper().mapNetworkToDomain({
            property: result.data
          })
        }
      default:
        return result
    }
  }

  async createProperty({
    property
  }: CreatePropertyParameters): Promise<CreatePropertyResult> {
    const propertiesMapper = new PropertiesMapper()

    const result: CreatePropertyNetworkResult =
      await this.propertiesNetworkSource.createProperty({
        property: propertiesMapper.mapDomainToNetwork({
          property
        })
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: propertiesMapper.mapNetworkToDomain({
            property: result.data
          })
        }
      default:
        return result
    }
  }

  async updateProperty({
    propertyId,
    property
  }: UpdatePropertyParameters): Promise<UpdatePropertyResult> {
    const propertiesMapper = new PropertiesMapper()

    const result: UpdatePropertyNetworkResult =
      await this.propertiesNetworkSource.updateProperty({
        propertyId,
        property: propertiesMapper.mapDomainToNetwork({
          property
        })
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: propertiesMapper.mapNetworkToDomain({
            property: result.data
          })
        }
      default:
        return result
    }
  }

  async destroyProperty({
    propertyId
  }: DestroyPropertyParameters): Promise<DestroyPropertyResult> {
    const result: DestroyPropertyNetworkResult =
      await this.propertiesNetworkSource.destroyProperty({
        propertyId
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: undefined
        }
      default:
        return result
    }
  }
}
