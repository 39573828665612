import DiModule from "../../../../sqadmin/lib/di/DiModule"
import GetUsersUseCase from "../domain/use-cases/GetUsersUseCase"
import GetUserUseCase from "../domain/use-cases/GetUserUseCase"
import UpdateUserUseCase from "../domain/use-cases/UpdateUserUseCase"
import UsersCoreDataDiModule from "./UsersCoreDataDiModule"
import CreateUserUseCase from "../domain/use-cases/CreateUserUseCase"
import DestroyUserUseCase from "../domain/use-cases/DestroyUserUseCase"

export default interface UsersCoreDomainDiModule {
  provideGetUsersUseCase(): GetUsersUseCase

  provideGetUserUseCase(): GetUserUseCase

  provideUpdateUserUseCase(): UpdateUserUseCase

  provideCreateUserUseCase(): CreateUserUseCase

  provideDestroyUserUseCase(): DestroyUserUseCase
}

export class DefaultUsersCoreDomainDiModule extends DiModule
  implements UsersCoreDomainDiModule {
  private readonly usersCoreDataDiModule: UsersCoreDataDiModule

  constructor(parameters: {
    readonly usersCoreDataDiModule: UsersCoreDataDiModule
  }) {
    super()
    this.usersCoreDataDiModule = parameters.usersCoreDataDiModule
  }

  provideGetUsersUseCase(): GetUsersUseCase {
    return this.single(
      GetUsersUseCase.name,
      () => new GetUsersUseCase({
        usersRepository: this.usersCoreDataDiModule.provideUsersRepository()
      })
    )
  }

  provideGetUserUseCase(): GetUserUseCase {
    return this.single(
      GetUserUseCase.name,
      () => new GetUserUseCase({
        usersRepository: this.usersCoreDataDiModule.provideUsersRepository()
      })
    )
  }

  provideUpdateUserUseCase(): UpdateUserUseCase {
    return this.single(
      UpdateUserUseCase.name,
      () => new UpdateUserUseCase({
        usersRepository: this.usersCoreDataDiModule.provideUsersRepository()
      })
    )
  }

  provideCreateUserUseCase(): CreateUserUseCase {
    return this.single(
      CreateUserUseCase.name,
      () => new CreateUserUseCase({
        usersRepository: this.usersCoreDataDiModule.provideUsersRepository()
      })
    )
  }

  provideDestroyUserUseCase(): DestroyUserUseCase {
    return this.single(
      DestroyUserUseCase.name,
      () => new DestroyUserUseCase({
        usersRepository: this.usersCoreDataDiModule.provideUsersRepository()
      })
    )
  }
}
