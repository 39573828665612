import { Expose, Type } from "class-transformer"
import OrderPaymentErrorsObject from "../../../domain/entities/order-payments/OrderPaymentErrorsObject"
import NetworkOrderPaymentErrorsObjectAttributes from "./NetworkOrderPaymentErrorsObjectAttributes"
import NetworkPaymentErrorsObject from "../payments/NetworkPaymentErrorsObject"

export default class NetworkOrderPaymentErrorsObject implements OrderPaymentErrorsObject {
  @Expose()
  @Type(() => NetworkOrderPaymentErrorsObjectAttributes)
  readonly attributes: NetworkOrderPaymentErrorsObjectAttributes | null | undefined

  @Expose()
  @Type(() => NetworkPaymentErrorsObject)
  readonly payment: NetworkPaymentErrorsObject | null | undefined
}
