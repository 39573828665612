import { useOutletContext } from "react-router-dom"

export interface LocationContext {
  readonly locationId: string
  readonly getOrCreateViewModel: <ViewModel>(creator: () => ViewModel) => ViewModel
}

export default function useLocationContext(): LocationContext {
  return useOutletContext() as LocationContext
}
