import PhoneNumber from "../../domain/entities/phone-numbers/PhoneNumber"
import NetworkPhoneNumber from "../entities/phone-numbers/NetworkPhoneNumber"
import CountriesMapper from "./CountriesMapper"

export default class PhoneNumbersMapper {
  mapNetworkToDomain({
    phoneNumber
  }: {
    readonly phoneNumber: NetworkPhoneNumber
  }): PhoneNumber {
    return {
      id: phoneNumber.id,
      value: phoneNumber.value,
      country: phoneNumber.country && new CountriesMapper()
        .mapNetworkToDomain({ country: phoneNumber.country })
    }
  }

  mapDomainToNetwork({
    phoneNumber
  }: {
    readonly phoneNumber: PhoneNumber
  }): NetworkPhoneNumber {
    return new NetworkPhoneNumber({
      id: phoneNumber.id,
      value: phoneNumber.value,
      country: phoneNumber.country && new CountriesMapper()
        .mapDomainToNetwork({ country: phoneNumber.country })
    })
  }
}
