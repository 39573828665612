export default function wrapElement(
  element: HTMLElement | null | undefined
): ElementWrapper | undefined {
  if (element === null || element === undefined) {
    return undefined
  }

  return new ElementWrapper(element)
}

export class ElementWrapper {
  element: HTMLElement

  constructor(element: HTMLElement) {
    this.element = element
  }

  getMargins(): ElementMetrics {
    const top = getIntStyleProperty(this.element, "margin-top")
    const bottom = getIntStyleProperty(this.element, "margin-bottom")
    const right = getIntStyleProperty(this.element, "margin-right")
    const left = getIntStyleProperty(this.element, "margin-left")
    return new ElementMetrics(top, right, bottom, left)
  }

  getPaddings(): ElementMetrics {
    const top = getIntStyleProperty(this.element, "padding-top")
    const bottom = getIntStyleProperty(this.element, "padding-bottom")
    const right = getIntStyleProperty(this.element, "padding-right")
    const left = getIntStyleProperty(this.element, "padding-left")
    return new ElementMetrics(top, right, bottom, left)
  }

  getHeight(): number {
    return this.element.offsetHeight
  }

  getHeightWithoutAbsoluteAndFixedChildren(): number {
    let top: number | null = null
    let bottom: number | null = null

    Array.from(this.element.children).forEach((childElement: Element) => {
      if (!(childElement instanceof HTMLElement)) {
        return
      }

      const wrappedChildElement: ElementWrapper = wrapElement(childElement)!
      const childElementPosition: string = wrappedChildElement.getPosition()

      if (childElementPosition === "absolute" || childElementPosition === "fixed") {
        return
      }

      const childElementRect: DOMRect = childElement.getBoundingClientRect()

      top = top === null ? childElementRect.top : Math.min(top, childElementRect.top)
      bottom = bottom === null ? childElementRect.bottom : Math.max(bottom, childElementRect.bottom)
    })

    return (top === null || bottom === null) ? 0 : bottom - top
  }

  getWidth(): number {
    return this.element.offsetWidth
  }

  getOuterHeight(): number {
    const innerHeight = this.getHeight()
    const margins = this.getMargins()
    return innerHeight + margins.top + margins.bottom
  }

  getOuterWidth(): number {
    const innerWidth = this.getWidth()
    const margins = this.getMargins()
    return innerWidth + margins.left + margins.right
  }

  getOffsets(): ElementMetrics {
    return new ElementMetrics(this.element.offsetTop, 0, 0, this.element.offsetLeft)
  }

  getScrollHeight(): number {
    return this.element.scrollHeight
  }

  getPosition(): string {
    return getStyleProperty(this.element, "position")
  }

  setHeight(height: number) {
    this.element.style.height = `${height}px`
  }
}

class ElementMetrics {
  top: number
  right: number
  bottom: number
  left: number

  constructor(top: number, right: number, bottom: number, left: number) {
    this.top = top
    this.right = right
    this.bottom = bottom
    this.left = left
  }
}

function getIntStyleProperty(element: HTMLElement, propertyName: string): number {
  return parseInt(getStyleProperty(element, propertyName))
}

function getStyleProperty(element: HTMLElement, propertyName: string): string {
  const style = window.getComputedStyle(element)
  return style.getPropertyValue(propertyName)
}
