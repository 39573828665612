import NetworkProduct from "../entities/products/NetworkProduct"
import Product from "../../domain/entities/products/Product"
import ProductVariantsMapper from "./ProductVariantsMapper"
import ProductCategoriesMapper from "./ProductCategoriesMapper"
import ProductPropertiesMapper from "./ProductPropertiesMapper"
import BonusProgramRulesMapper from "./BonusProgramRulesMapper"
import OptionsMapper from "./OptionsMapper"

export default class ProductsMapper {
  mapNetworkToDomain({
    product
  }: {
    readonly product: NetworkProduct
  }): Product {
    const productVariantsMapper = new ProductVariantsMapper()
    const productPropertiesMapper = new ProductPropertiesMapper()

    return {
      id: product.id,
      externalCode: product.externalCode,
      name: product.name,
      description: product.description,
      categoryId: product.categoryId,
      category: product.category && new ProductCategoriesMapper().mapNetworkToDomain({
        productCategory: product.category
      }),
      mainVariant: product.mainVariant && productVariantsMapper.mapNetworkToDomain({
        productVariant: product.mainVariant
      }),
      options: product.options && product.options.map(option => {
        return new OptionsMapper().mapNetworkToDomain({ option: option })
      }),
      optionIds: product.optionIds,
      variants: product.variants && product.variants.map(variant => {
        return productVariantsMapper.mapNetworkToDomain({ productVariant: variant })
      }),
      productProperties: product.productProperties && product.productProperties.map((productProperty) => {
        return productPropertiesMapper.mapNetworkToDomain({
          productProperty
        })
      }),
      bonusProgramRule: product.bonusProgramRule && new BonusProgramRulesMapper()
        .mapNetworkToDomain({ bonusProgramRule: product.bonusProgramRule }),
      hasBatches: product.hasBatches,
      isArchived: product.isArchived,
      inputMultiplier: product.inputMultiplier,
      canBeOrderedWithBonusPoints: product.canBeOrderedWithBonusPoints
    }
  }

  mapDomainToNetwork({
    product
  }: {
    readonly product: Product
  }): NetworkProduct {
    const productVariantsMapper = new ProductVariantsMapper()
    const productPropertiesMapper = new ProductPropertiesMapper()

    return new NetworkProduct({
      id: product.id,
      name: product.name,
      externalCode: product.externalCode,
      description: product.description,
      categoryId: product.categoryId,
      category: product.category && new ProductCategoriesMapper().mapDomainToNetwork({
        productCategory: product.category
      }),
      mainVariant: product.mainVariant && productVariantsMapper.mapDomainToNetwork({
        productVariant: product.mainVariant
      }),
      options: product.options && product.options.map(option => {
        return new OptionsMapper().mapDomainToNetwork({ option: option })
      }),
      optionIds: product.optionIds,
      variants: product.variants && product.variants.map(variant => {
        return productVariantsMapper.mapDomainToNetwork({ productVariant: variant })
      }),
      productProperties: product.productProperties && product.productProperties.map((productProperty) => {
        return productPropertiesMapper.mapDomainToNetwork({
          productProperty
        })
      }),
      bonusProgramRule: product.bonusProgramRule && new BonusProgramRulesMapper()
        .mapDomainToNetwork({ bonusProgramRule: product.bonusProgramRule }),
      hasBatches: product.hasBatches,
      isArchived: product.isArchived,
      inputMultiplier: product.inputMultiplier,
      canBeOrderedWithBonusPoints: product.canBeOrderedWithBonusPoints
    })
  }
}
