import { Expose, Type } from "class-transformer"
import NetworkPlace from "../places/NetworkPlace"
import NetworkOrderReceivingMethod from "../order-receiving-methods/NetworkOrderReceivingMethod"
import NetworkSettlement from "../settlements/NetworkSettlement"
import NetworkOrderReceivingAddress from "../order-receiving-addresses/NetworkOrderReceivingAddress"
import NetworkOrderReceivingRecipient from "../order-receiving-recipients/NetworkOrderReceivingRecipient"

export default class NetworkOrderReceiving {
  @Expose()
  readonly id: number | null | undefined

  @Expose()
  @Type(() => NetworkOrderReceivingMethod)
  readonly method: NetworkOrderReceivingMethod | null | undefined

  @Expose()
  @Type(() => NetworkPlace)
  readonly place: NetworkPlace | null | undefined

  @Expose()
  @Type(() => NetworkSettlement)
  readonly settlement: NetworkSettlement | null | undefined

  @Expose()
  @Type(() => NetworkOrderReceivingAddress)
  readonly address: NetworkOrderReceivingAddress | null | undefined

  @Expose()
  @Type(() => NetworkOrderReceivingRecipient)
  readonly recipient: NetworkOrderReceivingRecipient | null | undefined

  constructor(parameters?: {
    readonly id: number | null | undefined
    readonly method: NetworkOrderReceivingMethod | null | undefined
    readonly place: NetworkPlace | null | undefined
    readonly settlement: NetworkSettlement | null | undefined
    readonly address: NetworkOrderReceivingAddress | null | undefined
    readonly recipient: NetworkOrderReceivingRecipient | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.id = parameters.id
    this.method = parameters.method
    this.place = parameters.place
    this.settlement = parameters.settlement
    this.address = parameters.address
    this.recipient = parameters.recipient
  }
}
