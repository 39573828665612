export enum ObjectsEventType {
  CREATED = "created",
  UPDATED = "updated",
  DESTROYED = "destroyed"
}

export interface CreatedObjectsEvent {
  readonly type: ObjectsEventType.CREATED
}

export interface UpdatedObjectsEvent {
  readonly type: ObjectsEventType.UPDATED
}

export interface DestroyedObjectsEvent {
  readonly type: ObjectsEventType.DESTROYED
}

type ObjectsEvent =
  CreatedObjectsEvent |
  UpdatedObjectsEvent |
  DestroyedObjectsEvent

export default ObjectsEvent

export type ObjectsEventCallback = (event: ObjectsEvent) => void
