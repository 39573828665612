import Sort from "../../../../../sqadmin/features/objects/presentation/entities/tables/Sort"
import LastItemPagination from "../../../../core/domain/entities/paginations/LastItemPagination"
import { GetObjectsPageResult } from "../../../../../sqadmin/features/objects/domain/results/GetObjectsPageResult"
import OrderReceivingMethod from "../../../../core/domain/entities/order-receiving-methods/OrderReceivingMethod"
import OrderReceivingMethodsRepository from "../repositories/OrderReceivingMethodsRepository"

export default class GetOrderReceivingMethodsUseCase {
  private readonly orderReceivingMethodsRepository: OrderReceivingMethodsRepository

  constructor(parameters: {
    readonly orderReceivingMethodsRepository: OrderReceivingMethodsRepository
  }) {
    this.orderReceivingMethodsRepository = parameters.orderReceivingMethodsRepository
  }

  async call(parameters: GetOrderReceivingMethodsParameters): Promise<GetObjectsPageResult<OrderReceivingMethod>> {
    return this.orderReceivingMethodsRepository.getOrderReceivingMethods(parameters)
  }
}

export interface GetOrderReceivingMethodsParameters {
  readonly query: string | null | undefined
  readonly sort: Sort | null | undefined
  readonly pagination: LastItemPagination | null | undefined
}
