import BonusPointsRuleErrorsObjectAttributes
  from "../../../domain/entities/bonus-points-rules/BonusPointsRuleErrorsObjectAttributes"
import AttributeError from "../../../../../sqadmin/core/domain/entities/AttributeError"
import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../errors/NetworkAttributeError"

export default class NetworkBonusPointsRuleErrorsObjectAttributes implements BonusPointsRuleErrorsObjectAttributes {
  @Expose()
  @Type(() => NetworkAttributeError)
  readonly calculator: AttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly bonusProgramLevel: AttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly bonusProgramLevelId: AttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly clientId: AttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly id: AttributeError[] | null | undefined
}
