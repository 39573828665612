import OrderItem from "../../domain/entities/order-items/OrderItem"
import NetworkOrderItem from "../entities/order-items/NetworkOrderItem"
import { v4 as uuidv4 } from "uuid"
import BatchesMapper from "./BatchesMapper"

export default class OrderItemsMapper {
  mapNetworkToDomain({
    orderItem
  }: {
    readonly orderItem: NetworkOrderItem
  }): OrderItem {
    return {
      id: orderItem.id,
      count: orderItem.count,
      price: orderItem.price,
      clientId: uuidv4(),
      batch: orderItem.batch && new BatchesMapper()
        .mapNetworkToDomain({ batch: orderItem.batch })
    }
  }

  mapDomainToNetwork({
    orderItem
  }: {
    readonly orderItem: OrderItem
  }): NetworkOrderItem {
    return new NetworkOrderItem({
      id: orderItem.id,
      count: orderItem.count,
      price: orderItem.price,
      clientId: orderItem.clientId,
      batch: orderItem.batch && new BatchesMapper()
        .mapDomainToNetwork({ batch: orderItem.batch })
    })
  }
}
