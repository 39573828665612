import TextComponent, { TextStyle } from "../../../../design/text/TextComponent"
import styles from "./PickerItemComponent.module.scss"
import React from "react"

export default function PickerItemComponent({
  text,
  isSelected,
  onClick
}: {
  readonly text?: string | null | undefined
  readonly isSelected?: boolean | null | undefined
  readonly onClick?: () => void
}) {
  return (
    <div
      className={[
        styles.root,
        isSelected ? styles.selected : ""
      ].join(" ")}
      onClick={onClick}
    >
      <TextComponent textStyle={TextStyle.BODY1_PRIMARY}>{text}</TextComponent>
    </div>
  )
}
