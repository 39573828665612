import ViewModel from "../../../../../../sqadmin/lib/view-model/ViewModel"
import CoreI18n from "../../../../../core/i18n/CoreI18n"
import CoreUrlProvider from "../../../../../core/presentation/services/CoreUrlProvider"
import BroadcastObjectsEventUseCase
  from "../../../../../../sqadmin/features/objects/domain/use-cases/objects/BroadcastObjectsEventUseCase"
import ObjectPresentationLogic
  from "../../../../../../sqadmin/features/objects/presentation/presentation-logics/ObjectPresentationLogic"
import { StateObservable } from "../../../../../../sqadmin/lib/view-model/StateObservable"
import { ObjectViewState } from "../../../../../../sqadmin/features/objects/presentation/view-states/ObjectViewState"
import autoBind from "auto-bind"
import ObjectViewEvent from "../../../../../../sqadmin/features/objects/presentation/view-events/ObjectViewEvent"
import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import NumberFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/NumberFormField"
import CreateOrUpdateBonusPointsBurningRuleUseCase
  from "../../../../bonus-points-burning-rules-core/domain/use-cases/CreateOrUpdateBonusPointsBurningRuleUseCase"
import BonusPointsBurningRuleError
  from "../../../../../core/domain/entities/bonus-points-burning-rules/BonusPointsBurningRuleError"
import BonusPointsBurningRule
  from "../../../../../core/domain/entities/bonus-points-burning-rules/BonusPointsBurningRule"
import BonusPointsBurningRuleErrorsObject
  from "../../../../../core/domain/entities/bonus-points-burning-rules/BonusPointsBurningRuleErrorsObject"
import GetBonusPointsBurningRuleUseCase
  from "../../../../bonus-points-burning-rules-core/domain/use-cases/GetBonusPointsBurningRuleUseCase"
import BonusPointBurningRulePeriodType
  from "../../../../../core/domain/entities/bonus-points-burning-rules/BonusPointBurningRulePeriodType"
import SingleSelectFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/SingleSelectFormField"

export default class BonusPointsBurningRuleViewModel extends ViewModel {
  private readonly coreI18n: CoreI18n
  private readonly coreUrlProvider: CoreUrlProvider
  private readonly broadcastObjectsEventUseCase: BroadcastObjectsEventUseCase
  private readonly getBonusPointsBurningRuleUseCase: GetBonusPointsBurningRuleUseCase
  private readonly createOrUpdateBonusPointsBurningRuleUseCase: CreateOrUpdateBonusPointsBurningRuleUseCase

  private readonly objectPresentationLogic: ObjectPresentationLogic<
    BonusPointsBurningRule,
    BonusPointsBurningRuleError,
    BonusPointsBurningRuleErrorsObject
  >

  readonly observableObjectViewState: StateObservable<ObjectViewState>

  constructor(parameters: {
    readonly coreI18n: CoreI18n
    readonly broadcastObjectsEventUseCase: BroadcastObjectsEventUseCase
    readonly getBonusPointsBurningRuleUseCase: GetBonusPointsBurningRuleUseCase
    readonly createOrUpdateBonusPointsBurningRuleUseCase: CreateOrUpdateBonusPointsBurningRuleUseCase
  }) {
    super()
    this.coreI18n = parameters.coreI18n
    this.broadcastObjectsEventUseCase = parameters.broadcastObjectsEventUseCase
    this.getBonusPointsBurningRuleUseCase = parameters.getBonusPointsBurningRuleUseCase
    this.createOrUpdateBonusPointsBurningRuleUseCase = parameters.createOrUpdateBonusPointsBurningRuleUseCase
    this.objectPresentationLogic = this.createObjectPresentationLogic()
    this.observableObjectViewState = this.objectPresentationLogic.observableObjectViewState
    this.coreUrlProvider = new CoreUrlProvider()
    autoBind(this)
  }

  onViewObjectEvent(objectViewEvent: ObjectViewEvent) {
    this.objectPresentationLogic.onObjectViewEvent(objectViewEvent)
  }

  private createObjectPresentationLogic(): ObjectPresentationLogic<
    BonusPointsBurningRule,
    BonusPointsBurningRuleError,
    BonusPointsBurningRuleErrorsObject
  > {
    const coreTextProvider: CoreTextProvider = this.coreI18n.getTextProvider()

    return new ObjectPresentationLogic({
      // TODO: how to not pass?
      broadcastObjectsEventUseCase: this.broadcastObjectsEventUseCase,
      isNewObject: false,
      buildObject: async() => ({
        periodType: BonusPointBurningRulePeriodType.MONTHLY,
        minEarnedSum: undefined
      }),
      getObjectUrl: () => {
        return this.coreUrlProvider.buildBonusPointsBurningRuleUrl()
      },
      loadObject: async() => {
        return await this.getBonusPointsBurningRuleUseCase.call()
      },
      createObject: async({ object: bonusPointsBurningRule }) => {
        return await this.createOrUpdateBonusPointsBurningRuleUseCase.call({ bonusPointsBurningRule })
      },
      updateObject: async({ object: bonusPointsBurningRule }) => {
        return await this.createOrUpdateBonusPointsBurningRuleUseCase.call({ bonusPointsBurningRule })
      },
      canClosePageAfterUpdatedObject: false,
      getErrorsObject: ({ error: bonusPointsBurningRuleError }) => bonusPointsBurningRuleError
        ?.errorsObject,
      formFields: [
        new SingleSelectFormField<
          BonusPointsBurningRule,
          BonusPointsBurningRuleErrorsObject,
          BonusPointBurningRulePeriodType
        >({
          isSearchBarVisible: false,
          getObjects: async() => {
            return {
              type: "success",
              data: {
                objects: Object.values(BonusPointBurningRulePeriodType),
                page: { hasMore: false }
              }
            }
          },
          getTitle: () => coreTextProvider.bonusPointsBurningPeriod(),
          getValue: (bonusPointsBurningRule: BonusPointsBurningRule) => bonusPointsBurningRule.periodType,
          setValue: (
            bonusPointsBurningRule: BonusPointsBurningRule,
            type: BonusPointBurningRulePeriodType | null
          ) => ({
            ...bonusPointsBurningRule,
            periodType: type
          }),
          getErrors: (
            bonusPointsBurningRuleLevelErrorsObject?: BonusPointsBurningRuleErrorsObject
          ) => bonusPointsBurningRuleLevelErrorsObject?.attributes?.periodType,
          getOptionId: (period: BonusPointBurningRulePeriodType) => period.valueOf(),
          getOptionText: (
            period: BonusPointBurningRulePeriodType
          ) => this.detectBonusPointBurningRulePeriodTypeDisplayName(period)
        }),
        new NumberFormField<BonusPointsBurningRule, BonusPointsBurningRuleErrorsObject>({
          getTitle: () => coreTextProvider.minEarnedSum(),
          getValue: (bonusPointsBurningRule: BonusPointsBurningRule) => bonusPointsBurningRule.minEarnedSum,
          setValue: (
            bonusPointsBurningRule: BonusPointsBurningRule,
            minEarnedSum: number | undefined | null
          ): BonusPointsBurningRule => ({
            ...bonusPointsBurningRule,
            minEarnedSum
          }),
          getErrors: (
            bonusPointsBurningRuleLevelErrorsObject?: BonusPointsBurningRuleErrorsObject
          ) => bonusPointsBurningRuleLevelErrorsObject?.attributes?.minEarnedSum
        })
      ]
    })
  }

  private detectBonusPointBurningRulePeriodTypeDisplayName(period: BonusPointBurningRulePeriodType): string {
    const coreTextProvider: CoreTextProvider = this.coreI18n.getTextProvider()
    switch (period) {
      case BonusPointBurningRulePeriodType.MONTHLY:
        return coreTextProvider.monthly()
    }
  }
}
