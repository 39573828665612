import ObjectsPresentationLogicParameters
  from "../../../../../../sqadmin/features/objects/presentation/entities/objects/ObjectsPresentationLogicParameters"
import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import { useCoreTextProvider } from "../../../../../core/presentation/contexts/CoreTextProviderContext"
import CoreUrlProvider from "../../../../../core/presentation/services/CoreUrlProvider"
import useObjectsPresentationLogicParameters
  from "../../../../../../sqadmin/features/objects/presentation/hooks/useObjectsPresentationLogicParameters"
import { useViewModel } from "../../../../../../sqadmin/lib/view-model/ViewModel"
import ObjectsComponent
  from "../../../../../../sqadmin/features/objects/presentation/components/objects/ObjectsComponent"
import React from "react"
import DashboardsViewModel from "./DashboardsViewModel"
import Dashboard from "../../../../../core/domain/entities/dashboards/Dashboard"

export interface DashboardsPageParameters {
  readonly provideViewModel: (parameters: {
    readonly objectsPresentationLogicParameters: ObjectsPresentationLogicParameters
  }) => DashboardsViewModel
}

export default function DashboardsPage({
  provideViewModel
}: DashboardsPageParameters) {
  const coreTextProvider: CoreTextProvider = useCoreTextProvider()
  const coreUrlProvider: CoreUrlProvider = new CoreUrlProvider()
  const objectsPresentationLogicParameters: ObjectsPresentationLogicParameters = useObjectsPresentationLogicParameters()

  const viewModel: DashboardsViewModel = useViewModel(() => provideViewModel({
    objectsPresentationLogicParameters
  }))

  function getRowUrl(dashboard: Dashboard): string {
    return coreUrlProvider.buildDashboardUrl({ id: dashboard.id! })
  }

  return (
    <ObjectsComponent
      title={coreTextProvider.dashboards()}
      getObjectUrl={getRowUrl}
      newObjectUrl={coreUrlProvider.buildNewDashboardUrl()}
      observableObjectsPageViewState={viewModel.observableObjectsPageViewState}
      observableObjectsViewAction={viewModel.observableObjectsViewAction}
      onObjectsViewEvent={viewModel.onObjectsViewEvent}
    />
  )
}
