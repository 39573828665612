import { Expose, Type } from "class-transformer"
import NetworkPage from "../pagination/NetworkPage"
import NetworkOption from "./NetworkOption"

export default class NetworkOptionsResponseBody {
  @Expose()
  @Type(() => NetworkOption)
  readonly options?: NetworkOption[] | null

  @Expose()
  @Type(() => NetworkPage)
  readonly page?: NetworkPage | null
}
