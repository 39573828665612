import { Expose, Transform } from "class-transformer"
import OrderReceivingPriceRuleConditionType
  from "../../../domain/entities/order-receiving-price-rule-conditions/OrderReceivingPriceRuleConditionType"
import transformDecimal from "../../network/transformers/transformDecimal"
import { Decimal } from "decimal.js"

export default class NetworkOrderReceivingPriceRuleCondition {
  @Expose()
  readonly id: number | null | undefined

  @Expose()
  readonly clientId: string | null | undefined

  @Expose()
  @Transform(transformDecimal)
  readonly value: Decimal | null | undefined

  @Expose()
  readonly type: OrderReceivingPriceRuleConditionType | null | undefined

  @Expose()
  readonly position: number | null | undefined

  constructor(parameters?: {
    readonly id: number | null | undefined
    readonly type: OrderReceivingPriceRuleConditionType | null | undefined
    readonly value: Decimal | null | undefined
    readonly clientId: string | null | undefined
    readonly position: number | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.id = parameters.id
    this.type = parameters.type
    this.value = parameters.value
    this.clientId = parameters.clientId
    this.position = parameters.position
  }
}
