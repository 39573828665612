import { Expose } from "class-transformer"
import LinkType from "../../../domain/entities/links/LinkType"

export default class NetworkLink {

  @Expose()
  readonly url: string | null | undefined

  @Expose()
  readonly type: LinkType | null | undefined

  constructor(parameters?: {
    readonly type: LinkType | null | undefined
    readonly url: string | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.type = parameters.type
    this.url = parameters.url
  }
}
