import TextComponent, { TextStyle } from "../../../../design/text/TextComponent"
import styles from "./PickerNoDataComponent.module.scss"
import React from "react"
import CoreTextProvider from "../../../i18n/CoreTextProvider"
import { useCoreTextProvider } from "../../contexts/CoreTextProviderContext"

export default function PickerNoDataComponent() {
  const coreTextProvider: CoreTextProvider = useCoreTextProvider()
  return (
    <div className={styles.root}>
      <TextComponent textStyle={TextStyle.BODY1_SECONDARY}>{coreTextProvider.noObjects()}</TextComponent>
    </div>
  )
}
