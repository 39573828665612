import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import { useCoreTextProvider } from "../../../../../core/presentation/contexts/CoreTextProviderContext"
import { useViewModel } from "../../../../../../sqadmin/lib/view-model/ViewModel"
import ObjectComponent from "../../../../../../sqadmin/features/objects/presentation/components/object/ObjectComponent"
import React from "react"
import BonusPointsBurningRuleViewModel from "./BonusPointsBurningRuleViewModel"

export interface BonusPointsBurningRulePageProps {
  readonly provideViewModel: () => BonusPointsBurningRuleViewModel
}

export default function BonusPointsBurningRulePage({
  provideViewModel
}: BonusPointsBurningRulePageProps) {
  const coreTextProvider: CoreTextProvider = useCoreTextProvider()

  const viewModel: BonusPointsBurningRuleViewModel = useViewModel(() => provideViewModel())

  return (
    <ObjectComponent
      existedObjectTitle={coreTextProvider.bonusPointsBurningRule()}
      observableObjectViewState={viewModel.observableObjectViewState}
      onObjectViewEvent={viewModel.onViewObjectEvent}
    />
  )
}
