import Sort from "../entities/tables/Sort"
import isBlank from "../../../../lib/isBlank"
import SortMapper from "../mappers/SortMapper"
import { useSearchParams } from "react-router-dom"
import ObjectsPresentationLogicParameters from "../entities/objects/ObjectsPresentationLogicParameters"

// TODO: private
export const SORT_KEY = "sort"
export const QUERY_KEY = "query"

export default function useObjectsPresentationLogicParameters(): ObjectsPresentationLogicParameters {
  const [searchParams] = useSearchParams()

  function parseSort(jsonString: string | null): Sort | undefined {
    if (isBlank(jsonString)) {
      return undefined
    }

    return new SortMapper().mapBrowserSortToDomain({
      browserSort: jsonString
    })
  }

  function parseQuery(query: string | null): string | undefined {
    if (isBlank(query)) {
      return undefined
    }

    return query
  }

  return {
    sort: parseSort(searchParams.get(SORT_KEY)),
    query: parseQuery(searchParams.get(QUERY_KEY))
  }
}
