import BonusProgramLevelsFilter from "../../../../core/domain/entities/bonus-program-levels/BonusProgramLevelsFilter"
import NetworkBonusProgramLevelsRequestFilter
  from "../entities/NetworkBonusProgramLevelsRequestFilter"

export default class BonusProgramLevelFiltersMapper {
  mapDomainToNetwork({
    filter
  }: {
    readonly filter: BonusProgramLevelsFilter
  }): NetworkBonusProgramLevelsRequestFilter {
    return {
      query: filter.query
    }
  }
}
