import { LoadObjectResult } from "../../../../../sqadmin/features/objects/domain/results/LoadObjectResult"
import DiscountRulesRepository from "../repositories/DiscountRulesRepository"
import DiscountRule from "../../../../core/domain/entities/discount-rules/DiscountRule"

export default class GetDiscountRuleUseCase {
  private readonly discountRulesRepository: DiscountRulesRepository

  constructor(parameters: {
    readonly discountRulesRepository: DiscountRulesRepository
  }) {
    this.discountRulesRepository = parameters.discountRulesRepository
  }

  async call(parameters: {
    readonly discountRuleId: number
  }): Promise<LoadObjectResult<DiscountRule>> {
    return await this.discountRulesRepository.getDiscountRule(parameters)
  }
}
