import Product from "../../../../../core/domain/entities/products/Product"
import ProductError from "../../../../../core/domain/entities/products/ProductError"
import ProductsRepository from "../../repositories/ProductsRepository"
import { UpdateObjectResult } from "../../../../../../sqadmin/features/objects/domain/results/UpdateObjectResult"

export default class UpdateProductUseCase {
  private readonly productsRepository: ProductsRepository

  constructor(parameters: {
    readonly productsRepository: ProductsRepository
  }) {
    this.productsRepository = parameters.productsRepository
  }

  async call(parameters: {
    readonly productId: number
    readonly product: Product
  }): Promise<UpdateObjectResult<Product, ProductError>> {
    return await this.productsRepository.updateProduct(parameters)
  }
}
