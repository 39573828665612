import { Expose, Type } from "class-transformer"
import NetworkLastItemPagination from "../../../../core/data/entities/pagination/NetworkLastItemPagination"
import NetworkOrderPaymentMethodsRequestFilter from "./NetworkOrderPaymentMethodsRequestFilter"

export default class NetworkOrderPaymentMethodsRequestQuery {
  @Expose()
  @Type(() => NetworkOrderPaymentMethodsRequestFilter)
  readonly filter: NetworkOrderPaymentMethodsRequestFilter | null | undefined

  @Expose()
  @Type(() => NetworkLastItemPagination)
  readonly pagination: NetworkLastItemPagination | null | undefined

  @Expose()
  readonly sort: string | null | undefined

  constructor(parameters: {
    readonly filter: NetworkOrderPaymentMethodsRequestFilter | null | undefined
    readonly pagination: NetworkLastItemPagination | null | undefined
    readonly sort: string | null | undefined
  }) {
    this.filter = parameters.filter
    this.pagination = parameters.pagination
    this.sort = parameters.sort
  }
}
