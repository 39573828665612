import { v4 as uuidv4 } from "uuid"
import ProductProperty from "../../domain/entities/product-properties/ProductProperty"
import NetworkProductProperty from "../entities/product-properties/NetworkProductProperty"
import PropertiesMapper from "./PropertiesMapper"
import PropertyValuesMapper from "./PropertyValuesMapper"

export default class ProductPropertiesMapper {
  mapNetworkToDomain({
    productProperty
  }: {
    readonly productProperty: NetworkProductProperty
  }): ProductProperty {
    return {
      id: productProperty.id,
      propertyId: productProperty.propertyId,
      propertyValueId: productProperty.propertyValueId,
      clientId: uuidv4(),
      property: productProperty.property &&
        new PropertiesMapper().mapNetworkToDomain({
          property: productProperty.property
        }),
      propertyValue: productProperty.propertyValue && new PropertyValuesMapper().mapNetworkToDomain({
        value: productProperty.propertyValue
      }),
      propertyValueName: productProperty.propertyValue && productProperty.propertyValue.name
    }
  }

  mapDomainToNetwork({
    productProperty
  }: {
    readonly productProperty: ProductProperty
  }): NetworkProductProperty {
    return new NetworkProductProperty({
      id: productProperty.id,
      propertyId: productProperty.propertyId,
      propertyValueId: productProperty.propertyValueId,
      clientId: productProperty.clientId,
      propertyValueName: productProperty.propertyValueName
    })
  }
}
