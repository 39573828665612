import { useCoreTextProvider } from "../contexts/CoreTextProviderContext"

export default function useMonthNameByNumber(): { [key: number]: string } {
  const coreTextProvider = useCoreTextProvider()

  return {
    0: coreTextProvider.january(),
    1: coreTextProvider.february(),
    2: coreTextProvider.march(),
    3: coreTextProvider.april(),
    4: coreTextProvider.may(),
    5: coreTextProvider.june(),
    6: coreTextProvider.july(),
    7: coreTextProvider.august(),
    8: coreTextProvider.september(),
    9: coreTextProvider.october(),
    10: coreTextProvider.november(),
    11: coreTextProvider.december()
  }
}
