import NetworkDiscountRule from "../entities/discount-rules/NetworkDiscountRule"
import DiscountRule from "../../domain/entities/discount-rules/DiscountRule"
import ProductsSetsMapper from "./ProductsSetsMapper"
import CalculatorsMapper from "./CalculatorsMapper"

export default class DiscountRulesMapper {
  mapNetworkToDomain({
    discountRule
  }: {
    readonly discountRule: NetworkDiscountRule
  }): DiscountRule {
    return {
      id: discountRule.id,
      name: discountRule.name,
      priority: discountRule.priority,
      productsSet: discountRule.productsSet && new ProductsSetsMapper().mapNetworkToDomain({
        productsSet: discountRule.productsSet
      }),
      calculator: discountRule.calculator && new CalculatorsMapper().mapNetworkToDomain({
        calculator: discountRule.calculator
      })
    }
  }

  mapDomainToNetwork({
    discountRule
  }: {
    readonly discountRule: DiscountRule
  }): NetworkDiscountRule {
    return new NetworkDiscountRule({
      id: discountRule.id,
      name: discountRule.name,
      priority: discountRule.priority,
      productsSet: discountRule.productsSet && new ProductsSetsMapper().mapDomainToNetwork({
        productsSet: discountRule.productsSet
      }),
      calculator: discountRule.calculator && new CalculatorsMapper().mapDomainToNetwork({
        calculator: discountRule.calculator
      })
    })
  }
}
