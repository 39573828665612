import { Expose, Type } from "class-transformer"
import NetworkPage from "../pagination/NetworkPage"
import NetworkPlace from "./NetworkPlace"

export default class NetworkPlacesResponseBody {
  @Expose()
  @Type(() => NetworkPlace)
  readonly places?: NetworkPlace[] | null

  @Expose()
  @Type(() => NetworkPage)
  readonly page?: NetworkPage | null
}
