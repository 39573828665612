import React, { createContext, useContext } from "react"
import AuthenticationTextProvider from "../../i18n/AuthenticationTextProvider"
import AuthenticationI18n from "../../i18n/AuthenticationI18n"

// TODO: remove duplication between the same files
export const AuthenticationTextProviderContext: React.Context<AuthenticationTextProvider | null> =
  createContext<AuthenticationTextProvider | null>(null)

export function useAuthenticationTextProvider(): AuthenticationTextProvider {
  return useContext(AuthenticationTextProviderContext)!
}

export interface AuthenticationTextProviderProviderProps {
  readonly children?: React.ReactNode
  readonly authenticationI18n: AuthenticationI18n
}

export function AuthenticationTextProviderProvider({
  children,
  authenticationI18n
}: AuthenticationTextProviderProviderProps) {
  return (
    <AuthenticationTextProviderContext.Provider value={authenticationI18n.getTextProvider()}>
      {children}
    </AuthenticationTextProviderContext.Provider>
  )
}
