import { Expose, Type } from "class-transformer"
import NetworkPage from "../../../../core/data/entities/pagination/NetworkPage"
import NetworkProperty from "../../../../core/data/entities/properties/NetworkProperty"

export default class NetworkPropertiesResponseBody {
  @Expose()
  @Type(() => NetworkProperty)
  readonly properties?: NetworkProperty[] | null

  @Expose()
  @Type(() => NetworkPage)
  readonly page?: NetworkPage | null
}
