import DiModule from "../../../../../sqadmin/lib/di/DiModule"
import OrdersDataDiModule from "./OrdersDataDiModule"
import GetOrdersUseCase from "../../domain/use-cases/orders/GetOrdersUseCase"
import GetOrderUseCase from "../../domain/use-cases/orders/GetOrderUseCase"
import UpdateOrderUseCase from "../../domain/use-cases/orders/UpdateOrderUseCase"
import DestroyOrderUseCase from "../../domain/use-cases/orders/DestroyOrderUseCase"
import GetOrderProcessingStatusesUseCase from "../../domain/use-cases/order-processing-statuses/GetOrderProcessingStatusesUseCase"

export default interface OrdersDomainDiModule {
  provideGetOrdersUseCase(): GetOrdersUseCase

  provideGetOrderUseCase(): GetOrderUseCase

  provideUpdateOrderUseCase(): UpdateOrderUseCase

  provideDestroyOrderUseCase(): DestroyOrderUseCase

  provideGetOrderProcessingStatusesUseCase(): GetOrderProcessingStatusesUseCase
}

export class DefaultOrdersDomainDiModule
  extends DiModule
  implements OrdersDomainDiModule {

  private readonly sqCoreOrdersDataDiModule: OrdersDataDiModule

  constructor(parameters: {
    readonly sqCoreOrdersDataDiModule: OrdersDataDiModule
  }) {
    super()
    this.sqCoreOrdersDataDiModule = parameters.sqCoreOrdersDataDiModule
  }

  provideGetOrdersUseCase(): GetOrdersUseCase {
    return this.single(
      GetOrdersUseCase.name,
      () => new GetOrdersUseCase({
        ordersRepository: this.sqCoreOrdersDataDiModule.provideOrdersRepository()
      })
    )
  }

  provideGetOrderUseCase(): GetOrderUseCase {
    return this.single(
      GetOrderUseCase.name,
      () => new GetOrderUseCase({
        ordersRepository: this.sqCoreOrdersDataDiModule.provideOrdersRepository()
      })
    )
  }

  provideUpdateOrderUseCase(): UpdateOrderUseCase {
    return this.single(
      UpdateOrderUseCase.name,
      () => new UpdateOrderUseCase({
        ordersRepository: this.sqCoreOrdersDataDiModule.provideOrdersRepository()
      })
    )
  }

  provideDestroyOrderUseCase(): DestroyOrderUseCase {
    return this.single(
      DestroyOrderUseCase.name,
      () => new DestroyOrderUseCase({
        ordersRepository: this.sqCoreOrdersDataDiModule.provideOrdersRepository()
      })
    )
  }

  provideGetOrderProcessingStatusesUseCase(): GetOrderProcessingStatusesUseCase {
    return this.single(
      GetOrderProcessingStatusesUseCase.name,
      () => new GetOrderProcessingStatusesUseCase({
        orderProcessingStatusesRepository: this.sqCoreOrdersDataDiModule.provideOrderProcessingStatusesRepository()
      })
    )
  }
}
