import { TransformationType } from "class-transformer"
import { Decimal } from "decimal.js"
import assertNever from "../../../../../sqadmin/lib/assertNever"
import { TransformFnParams } from "class-transformer/types/interfaces"

export default function transformDecimal({
  value,
  type
}: TransformFnParams) {
  switch (type) {
    case TransformationType.CLASS_TO_CLASS:
      return value
    case TransformationType.CLASS_TO_PLAIN:
      return value === null || value === undefined ? value : value.toString()
    case TransformationType.PLAIN_TO_CLASS:
      return value === null || value === undefined ? value : new Decimal(value)
    default:
      assertNever(type)
  }
}
