import { Expose } from "class-transformer"
import NetworkOrderReceivingMethod
  from "../../../../core/data/entities/order-receiving-methods/NetworkOrderReceivingMethod"

export default class NetworkOrderReceivingMethodRequestBody {
  @Expose()
  readonly orderReceivingMethod: NetworkOrderReceivingMethod

  constructor(parameters: {
    readonly orderReceivingMethod: NetworkOrderReceivingMethod
  }) {
    this.orderReceivingMethod = parameters.orderReceivingMethod
  }
}
