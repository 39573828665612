import ViewModel from "../../../../../../sqadmin/lib/view-model/ViewModel"
import ObjectsPresentationLogic
  from "../../../../../../sqadmin/features/objects/presentation/presentation-logics/ObjectsPresentationLogic"
import Product from "../../../../../core/domain/entities/products/Product"
import autoBind from "auto-bind"
import SubscribeToObjectsEventsUseCase
  from "../../../../../../sqadmin/features/objects/domain/use-cases/objects/SubscribeToObjectsEventsUseCase"
import UnsubscribeFromObjectsEventsUseCase
  from "../../../../../../sqadmin/features/objects/domain/use-cases/objects/UnsubscribeFromObjectsEventsUseCase"
import ObjectsViewEvent from "../../../../../../sqadmin/features/objects/presentation/view-events/ObjectsViewEvent"
import { StateObservable } from "../../../../../../sqadmin/lib/view-model/StateObservable"
import {
  ObjectsPageViewState
} from "../../../../../../sqadmin/features/objects/presentation/view-states/ObjectsViewState"
import { ActionObservable } from "../../../../../../sqadmin/lib/view-model/ActionObservable"
import {
  ObjectsViewAction
} from "../../../../../../sqadmin/features/objects/presentation/view-actions/ObjectsViewAction"
import ObjectsPresentationLogicParameters
  from "../../../../../../sqadmin/features/objects/presentation/entities/objects/ObjectsPresentationLogicParameters"
import TableColumn from "../../../../../../sqadmin/features/objects/presentation/entities/tables/TableColumn"
import NumberTableValue
  from "../../../../../../sqadmin/features/objects/presentation/entities/tables/table-value-by-type/NumberTableValue"
import TextTableValue
  from "../../../../../../sqadmin/features/objects/presentation/entities/tables/table-value-by-type/TextTableValue"
import DecimalTableValue
  from "../../../../../../sqadmin/features/objects/presentation/entities/tables/table-value-by-type/DecimalTableValue"
import isBlank from "../../../../../../sqadmin/lib/isBlank"
import CoreUrlProvider from "../../../../../core/presentation/services/CoreUrlProvider"
import SingleSelectFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/SingleSelectFormField"
import ProductsFilter from "../../../../../core/domain/entities/products/ProductsFilter"
import IsArchivedFilterValue from "../../../../../core/domain/entities/filters/IsArchivedFilterValue"
import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import assertNever from "../../../../../../sqadmin/lib/assertNever"
import CoreI18n from "../../../../../core/i18n/CoreI18n"
import ProductErrorsObject from "../../../../../core/domain/entities/products/ProductErrorsObject"
import ProductCategory from "../../../../../core/domain/entities/product-categories/ProductCategory"
import GetProductCategoriesUseCase
  from "../../../../product-categories-core/domain/use-cases/product-categories/GetProductCategoriesUseCase"
import GetProductsUseCase from "../../../../products-core/domain/use-cases/products/GetProductsUseCase"

const descriptionMaxLength = 40

export default class ProductsViewModel extends ViewModel {
  private readonly coreI18n: CoreI18n
  private readonly subscribeToObjectsEventsUseCase: SubscribeToObjectsEventsUseCase
  private readonly unsubscribeFromObjectsEventsUseCase: UnsubscribeFromObjectsEventsUseCase
  private readonly getProductsUseCase: GetProductsUseCase
  private readonly getProductCategoriesUseCase: GetProductCategoriesUseCase
  private readonly objectsPresentationLogicParameters: ObjectsPresentationLogicParameters
  private readonly objectsPresentationLogic: ObjectsPresentationLogic<Product, ProductsFilter>

  readonly observableObjectsPageViewState: StateObservable<ObjectsPageViewState<Product>>
  readonly observableObjectsViewAction: ActionObservable<ObjectsViewAction>

  constructor(parameters: {
    readonly coreI18n: CoreI18n
    readonly subscribeToObjectsEventsUseCase: SubscribeToObjectsEventsUseCase
    readonly unsubscribeFromObjectsEventsUseCase: UnsubscribeFromObjectsEventsUseCase
    readonly getProductsUseCase: GetProductsUseCase
    readonly objectsPresentationLogicParameters: ObjectsPresentationLogicParameters
    readonly getProductCategoriesUseCase: GetProductCategoriesUseCase
  }) {
    super()
    autoBind(this)
    this.coreI18n = parameters.coreI18n
    this.subscribeToObjectsEventsUseCase = parameters.subscribeToObjectsEventsUseCase
    this.unsubscribeFromObjectsEventsUseCase = parameters.unsubscribeFromObjectsEventsUseCase
    this.getProductsUseCase = parameters.getProductsUseCase
    this.getProductCategoriesUseCase = parameters.getProductCategoriesUseCase
    this.objectsPresentationLogicParameters = parameters.objectsPresentationLogicParameters
    this.objectsPresentationLogic = this.createObjectsPresentationLogic()
    this.observableObjectsPageViewState = this.objectsPresentationLogic.observableObjectsPageViewState
    this.observableObjectsViewAction = this.objectsPresentationLogic.observableObjectsViewAction
  }

  protected onDestroy() {
    super.onDestroy()
    this.objectsPresentationLogic.destroy()
  }

  onObjectsViewEvent(objectsViewEvent: ObjectsViewEvent) {
    this.objectsPresentationLogic.onObjectsViewEvent(objectsViewEvent)
  }

  private createObjectsPresentationLogic(): ObjectsPresentationLogic<Product, ProductsFilter> {
    // TODO: think how to move building of urls in view
    const coreUrlProvider = new CoreUrlProvider()
    const coreTextProvider: CoreTextProvider = this.coreI18n.getTextProvider()
    return new ObjectsPresentationLogic<Product, ProductsFilter>({
      ...this.objectsPresentationLogicParameters,
      subscribeToObjectsEventsUseCase: this.subscribeToObjectsEventsUseCase,
      unsubscribeFromObjectsEventsUseCase: this.unsubscribeFromObjectsEventsUseCase,
      getObjects: async({
        query,
        sort,
        filterObject,
        lastObject: lastProduct
      }) => {
        return await this.getProductsUseCase.call({
          filter: {
            isArchivedFilterValue: filterObject?.isArchivedFilterValue,
            category: filterObject?.category
          },
          query,
          sort,
          pagination: { id: lastProduct?.id ?? undefined }
        })
      },
      getObjectId: (product: Product) => product.id!.toString(),
      columns: [
        new TableColumn<Product>({
          title: coreTextProvider.id(),
          createValue: (product: Product) => new NumberTableValue({
            value: product.id
          })
        }),
        new TableColumn<Product>({
          title: coreTextProvider.externalCode(),
          createValue: (product: Product) => new TextTableValue({
            value: product.externalCode
          })
        }),
        new TableColumn<Product>({
          title: coreTextProvider.name(),
          createValue: (product: Product) => new TextTableValue({
            value: product.name
          })
        }),
        new TableColumn<Product>({
          title: coreTextProvider.description(),
          createValue: (product: Product) => {
            const description = product.description ?? ""

            return new TextTableValue({
              value: description.length <= descriptionMaxLength ?
                description :
                `${description.substring(0, descriptionMaxLength)}...`
            })
          }
        }),
        new TableColumn<Product>({
          title: coreTextProvider.price(),
          createValue: (product: Product) => new DecimalTableValue({
            decimal: product.mainVariant?.mainBatch?.price
          })
        }),
        new TableColumn<Product>({
          title: coreTextProvider.productCategory(),
          createValue: (product: Product) => isBlank(product.category) ? null : new TextTableValue({
            value: product.category.name,
            url: coreUrlProvider.buildProductCategoryUrl({
              id: product.category.id!
            })
          })
        })
      ],
      buildFilterObject: async() => ({
        isArchivedFilterValue: IsArchivedFilterValue.NOT_IN_ARCHIVE,
        category: undefined
      }),
      filterFormFields: [
        new SingleSelectFormField<ProductsFilter, never, IsArchivedFilterValue>({
          isSearchBarVisible: false,
          isClearButtonVisible: false,
          getObjects: async() => {
            return {
              type: "success",
              data: {
                objects: Object.values(IsArchivedFilterValue),
                page: { hasMore: false }
              }
            }
          },
          getValue: (filter: ProductsFilter) => filter?.isArchivedFilterValue,
          setValue: (filter: ProductsFilter, archiveFilterType: IsArchivedFilterValue | null): ProductsFilter => ({
            ...filter,
            isArchivedFilterValue: archiveFilterType
          }),
          getErrors: () => undefined,
          getOptionId: (archiveFilterType: IsArchivedFilterValue) => archiveFilterType.valueOf(),
          getOptionText: (archiveFilterType: IsArchivedFilterValue) => {
            return this.detectArchiveFilterTypeDisplayName(archiveFilterType)
          }
        }),
        new SingleSelectFormField<ProductsFilter, never, ProductCategory>({
          // todo не реализовано на серсере, поэтому отключено
          getVisible: () => false,
          getPlaceholder: () => undefined,
          getObjects: async({
            query,
            lastObject
          }) => {
            return await this.getProductCategoriesUseCase.call({
              filter: { query },
              pagination: { id: lastObject?.id ?? undefined }
            })
          },
          getValue: (filter: ProductsFilter) => filter?.category,
          setValue: (filter: ProductsFilter, category: ProductCategory | null) => ({
            ...filter,
            category
          }),
          getErrors: (productErrorsObject?: ProductErrorsObject) => productErrorsObject?.attributes?.categoryId,
          getOptionId: (category: ProductCategory) => category.id!.toString(),
          getOptionText: (category: ProductCategory) => category.name
        })
      ]
    })
  }

  private detectArchiveFilterTypeDisplayName(archiveFilterType: IsArchivedFilterValue): string {
    const coreTextProvider: CoreTextProvider = this.coreI18n.getTextProvider()

    switch (archiveFilterType) {
      case IsArchivedFilterValue.ALL:
        return coreTextProvider.excludingIsArchivedStatus()
      case IsArchivedFilterValue.IN_ARCHIVE:
        return coreTextProvider.isArchived()
      case IsArchivedFilterValue.NOT_IN_ARCHIVE:
        return coreTextProvider.isNotArchived()
      default:
        assertNever(archiveFilterType)
    }
  }
}
