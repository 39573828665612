import { useCoreTextProvider } from "../contexts/CoreTextProviderContext"

export default function useWeekDayNameByNumber(): { [key: number]: string } {
  const coreTextProvider = useCoreTextProvider()

  return {
    0: coreTextProvider.sundayShort(),
    1: coreTextProvider.mondayShort(),
    2: coreTextProvider.tuesdayShort(),
    3: coreTextProvider.wednesdayShort(),
    4: coreTextProvider.thursdayShort(),
    5: coreTextProvider.fridayShort(),
    6: coreTextProvider.saturdayShort()
  }
}
