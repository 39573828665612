import GeoLocation from "../../domain/entities/geo-locations/GeoLocation"
import NetworkGeoLocation from "../entities/geo-locations/NetworkGeoLocation"

export default class GeoLocationsMapper {
  mapNetworkToDomain({
    geoLocation
  }: {
    readonly geoLocation: NetworkGeoLocation
  }): GeoLocation {
    return {
      latitude: geoLocation.latitude,
      longitude: geoLocation.longitude
    }
  }

  mapDomainToNetwork({
    geoLocation
  }: {
    readonly geoLocation: GeoLocation
  }): NetworkGeoLocation {
    return new NetworkGeoLocation({
      latitude: geoLocation.latitude,
      longitude: geoLocation.longitude
    })
  }
}
