import React from "react"
import { TextFormFieldViewState } from "../../../entities/form-fields/form-field-by-type/TextFormField"
import MultiLineTextInputComponent, {
  MultiLineTextInputStyle
} from "../../../../../../design/multi-line-text-input/MultiLineTextInputComponent"
import isPresent from "../../../../../../lib/isPresent"
import styles from "./TextFormFieldComponent.module.scss"
import TextComponent, { TextStyle } from "../../../../../../design/text/TextComponent"

export default function TextFormFieldComponent({
  fieldViewState,
  isDisabled
}: {
  readonly fieldViewState: TextFormFieldViewState
  readonly isDisabled: boolean
}) {
  function handleOnChange(value: string) {
    fieldViewState.onChange(value)
  }

  return (
    <div className={styles.root}>
      {
        fieldViewState.getTitle() && (
          <div className={styles.title}>
            <TextComponent textStyle={TextStyle.LABEL1_PRIMARY}>{fieldViewState.getTitle()}</TextComponent>
          </div>
        )
      }
      <MultiLineTextInputComponent
        value={fieldViewState.value ?? ""}
        isDisabled={isDisabled}
        onChange={handleOnChange}
        textStyle={MultiLineTextInputStyle.MULTI_LINE_TEXT1_PRIMARY}
        hasError={isPresent(fieldViewState.getErrors())}
      />
    </div>

  )
}
