import { v4 as uuidv4 } from "uuid"
import NetworkBatchPlace from "../entities/batch-places/NetworkBatchPlace"
import BatchPlace from "../../domain/entities/batch-paces/BatchPlace"
import PlacesMapper from "./PlacesMapper"

export default class BatchPlacesMapper {
  mapNetworkToDomain({
    batchPlace
  }: {
    readonly batchPlace: NetworkBatchPlace
  }): BatchPlace {
    return {
      id: batchPlace.id,
      clientId: uuidv4(),
      placeId: batchPlace.placeId,
      place: batchPlace.place && new PlacesMapper().mapNetworkToDomain({ place: batchPlace.place }),
      isAvailable: batchPlace.isAvailable
    }
  }

  mapDomainToNetwork({
    batchPlace
  }: {
    readonly batchPlace: BatchPlace
  }): NetworkBatchPlace {
    return new NetworkBatchPlace({
      id: batchPlace.id,
      clientId: batchPlace.clientId,
      placeId: batchPlace.placeId,
      place: batchPlace.place && new PlacesMapper().mapDomainToNetwork({ place: batchPlace.place }),
      isAvailable: batchPlace.isAvailable
    })
  }
}
