import EcommerceDi from "./di/EcommerceDi"
import CoreTheme from "../sqadmin/core/presentation/entities/CoreTheme"
import { CoreThemeProvider as SqAdminCoreThemeProvider } from "../sqadmin/core/presentation/contexts/CoreThemeProviderContext"
import { CoreTextProviderProvider as SqAdminCoreTextProviderProvider } from "../sqadmin/core/presentation/contexts/CoreTextProviderContext"
import {
  AuthenticationTextProviderProvider as SqAdminAuthenticationTextProviderProvider
} from "../sqadmin/features/authentication/presentation/contexts/AuthenticationTextProviderContext"
import { CoreTextProviderProvider as SqCoreCoreTextProviderProvider } from "./core/presentation/contexts/CoreTextProviderContext"
import { RouterProvider } from "react-router-dom"
import createEcommerceBrowserRouter from "./routing/createEcommerceBrowserRouter"
import React from "react"

interface Props {
  readonly di: EcommerceDi
  readonly coreTheme: CoreTheme
}

export default function EcommerceApp({
  di,
  coreTheme
}: Props) {
  const sqAdminCoreI18n = di.sqAdminCoreDiComponent
    .coreI18nDiModule
    .provideCoreI18n()

  const sqAdminAuthenticationI18n = di.sqAdminAuthenticationDiComponent
    .authenticationI18nDiModule
    .provideAuthenticationI18n()

  const sqCoreCoreI18n = di.sqCoreCoreDiComponent
    .coreI18nDiModule
    .provideCoreI18n()

  const router = createEcommerceBrowserRouter({ di })

  return (
    <SqAdminCoreThemeProvider
      coreTheme={coreTheme}
    >
      <SqAdminCoreTextProviderProvider
        coreI18n={sqAdminCoreI18n}
      >
        <SqAdminAuthenticationTextProviderProvider
          authenticationI18n={sqAdminAuthenticationI18n}
        >
          <SqCoreCoreTextProviderProvider
            coreI18n={sqCoreCoreI18n}
          >
            <RouterProvider router={router} />
          </SqCoreCoreTextProviderProvider>
        </SqAdminAuthenticationTextProviderProvider>
      </SqAdminCoreTextProviderProvider>
    </SqAdminCoreThemeProvider>
  )
}
