import { GetObjectsPageResult } from "../../../../sqadmin/features/objects/domain/results/GetObjectsPageResult"
import LastItemPaginationsMapper from "../../../core/data/mappers/LastItemPaginationsMapper"
import SortMapper from "../../../core/data/mappers/SortMapper"
import PagesMapper from "../../../core/data/mappers/PagesMapper"
import { LoadObjectResult } from "../../../../sqadmin/features/objects/domain/results/LoadObjectResult"
import GetObjectNetworkResult from "../../../core/data/results/GetObjectNetworkResult"
import { UpdateObjectResult } from "../../../../sqadmin/features/objects/domain/results/UpdateObjectResult"
import UpdateObjectNetworkResult from "../../../core/data/results/UpdateObjectNetworkResult"
import { DestroyObjectResult } from "../../../../sqadmin/features/objects/domain/results/DestroyObjectResult"
import DestroyObjectNetworkResult from "../../../core/data/results/DestroyObjectNetworkResult"
import OrdersRepository from "../domain/repositories/OrdersRepository"
import OrdersNetworkSource, { GetOrdersNetworkResult } from "../../../core/data/sources/network/OrdersNetworkSource"
import Order from "../../../core/domain/entities/orders/Order"
import { GetOrdersParameters } from "../domain/use-cases/orders/GetOrdersUseCase"
import NetworkOrder from "../../../core/data/entities/orders/NetworkOrder"
import { UpdateOrderParameters } from "../domain/use-cases/orders/UpdateOrderUseCase"
import OrderError from "../../../core/domain/entities/orders/OrderError"
import NetworkOrderError from "../../../core/data/entities/orders/NetworkOrderError"
import OrdersFiltersMapper from "../../../core/data/mappers/OrdersFiltersMapper"
import OrdersMapper from "../../../core/data/mappers/OrdersMapper"

export default class DefaultOrdersRepository implements OrdersRepository {
  private readonly ordersNetworkSource: OrdersNetworkSource

  constructor(parameters: {
    readonly ordersNetworkSource: OrdersNetworkSource
  }) {
    this.ordersNetworkSource = parameters.ordersNetworkSource
  }

  async getOrders({
    filter,
    pagination,
    sort
  }: GetOrdersParameters): Promise<GetObjectsPageResult<Order>> {
    const result: GetOrdersNetworkResult = await this.ordersNetworkSource.getOrders({
      filter: filter && new OrdersFiltersMapper().mapDomainToNetwork({ filter }),
      pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({ pagination }),
      sort: sort && new SortMapper().mapDomainToNetwork({ sort })
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: {
            objects: result.data.orders!.map((order: NetworkOrder) => {
              return new OrdersMapper().mapNetworkToDomain({
                order: order
              })
            }),
            page: new PagesMapper().mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      default:
        return result
    }
  }

  async getOrder({
    orderId
  }: {
    readonly orderId: number
  }): Promise<LoadObjectResult<Order>> {
    const result: GetObjectNetworkResult<NetworkOrder> =
      await this.ordersNetworkSource.getOrder({
        orderId
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new OrdersMapper().mapNetworkToDomain({
            order: result.data
          })
        }
      default:
        return result
    }
  }

  async updateOrder({
    orderId,
    order
  }: UpdateOrderParameters): Promise<UpdateObjectResult<Order, OrderError>> {
    const result: UpdateObjectNetworkResult<NetworkOrder, NetworkOrderError> =
      await this.ordersNetworkSource.updateOrder({
        orderId,
        order: new OrdersMapper().mapDomainToNetwork({
          order: order
        })
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new OrdersMapper().mapNetworkToDomain({
            order: result.data
          })
        }
      default:
        return result
    }
  }

  async destroyOrder({
    orderId
  }: {
    readonly orderId: number
  }): Promise<DestroyObjectResult<OrderError>> {
    const result: DestroyObjectNetworkResult<NetworkOrderError> =
      await this.ordersNetworkSource.destroyOrder({
        orderId
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: undefined
        }
      default:
        return result
    }
  }
}
