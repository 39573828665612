import { Expose, Type } from "class-transformer"
import NetworkOption from "../options/NetworkOption"
import NetworkOptionValue from "../option-values/NetworkOptionValue"

export default class NetworkVariantOption {
  @Expose()
  readonly id?: number | null

  @Expose()
  readonly optionId?: number | null

  @Expose()
  readonly optionValueId?: number | null

  @Expose()
  readonly clientId?: string | null

  @Expose()
  @Type(() => NetworkOption)
  readonly option?: NetworkOption | null

  @Expose()
  @Type(() => NetworkOptionValue)
  readonly optionValue?: NetworkOptionValue | null

  constructor(parameters?: {
    readonly id?: number | null
    readonly optionId?: number | null
    readonly optionValueId?: number | null
    readonly clientId?: string | null,
    readonly option?: NetworkOption | null,
    readonly optionValue?: NetworkOptionValue | null
  }) {
    if (parameters === undefined) {
      return
    }

    this.id = parameters.id
    this.optionId = parameters.optionId
    this.optionValueId = parameters.optionValueId
    this.clientId = parameters.clientId
    this.optionValue = parameters.optionValue
  }
}
