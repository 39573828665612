import { Expose } from "class-transformer"

export default class NetworkOrderProcessingStatus {
  @Expose()
  readonly id: number | null | undefined

  @Expose()
  readonly name: string | null | undefined

  @Expose()
  readonly externalCode: string | null | undefined

  @Expose()
  readonly displayName: string | null | undefined

  @Expose()
  readonly isInProcessing: boolean | null | undefined

  @Expose()
  readonly isCreated: boolean | null | undefined

  @Expose()
  readonly isCompleted: boolean | null | undefined

  @Expose()
  readonly isCanceled: boolean | null | undefined

  @Expose()
  readonly forOfflineSale: boolean | null | undefined

  constructor(parameters?: {
    readonly id: number | null | undefined
    readonly name: string | null | undefined
    readonly externalCode: string | null | undefined
    readonly displayName: string | null | undefined
    readonly isInProcessing: boolean | null | undefined
    readonly isCreated: boolean | null | undefined
    readonly isCompleted: boolean | null | undefined
    readonly isCanceled: boolean | null | undefined
    readonly forOfflineSale: boolean | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.id = parameters.id
    this.name = parameters.name
    this.externalCode = parameters.externalCode
    this.displayName = parameters.displayName
    this.isInProcessing = parameters.isInProcessing
    this.isCreated = parameters.isCreated
    this.isCompleted = parameters.isCompleted
    this.isCanceled = parameters.isCanceled
    this.forOfflineSale = parameters.forOfflineSale
  }
}
