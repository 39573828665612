import DiModule from "../../../../../sqadmin/lib/di/DiModule"
import CreateOrderProcessingStatusUseCase from "../../domain/use-cases/CreateOrderProcessingStatusUseCase"
import OrderProcessingStatusesDataDiModule from "./OrderProcessingStatusesDataDiModule"
import DestroyOrderProcessingStatusUseCase from "../../domain/use-cases/DestroyOrderProcessingStatusUseCase"
import GetOrderProcessingStatusesUseCase from "../../domain/use-cases/GetOrderProcessingStatusesUseCase"
import UpdateOrderProcessingStatusUseCase from "../../domain/use-cases/UpdateOrderProcessingStatusUseCase"
import GetOrderProcessingStatusUseCase from "../../domain/use-cases/GetOrderProcessingStatusUseCase"

export default interface OrderProcessingStatusesDomainDiModule {
  provideGetOrderProcessingStatusesUseCase(): GetOrderProcessingStatusesUseCase

  provideGetOrderProcessingStatusUseCase(): GetOrderProcessingStatusUseCase

  provideCreateOrderProcessingStatusUseCase(): CreateOrderProcessingStatusUseCase

  provideUpdateOrderProcessingStatusUseCase(): UpdateOrderProcessingStatusUseCase

  provideDestroyOrderProcessingStatusUseCase(): DestroyOrderProcessingStatusUseCase
}

export class DefaultOrderProcessingStatusesDomainDiModule
  extends DiModule
  implements OrderProcessingStatusesDomainDiModule {

  private readonly sqCoreOrderProcessingStatusesDataDiModule: OrderProcessingStatusesDataDiModule

  constructor(parameters: {
    readonly sqCoreOrderProcessingStatusesDataDiModule: OrderProcessingStatusesDataDiModule
  }) {
    super()
    this.sqCoreOrderProcessingStatusesDataDiModule = parameters.sqCoreOrderProcessingStatusesDataDiModule
  }

  provideGetOrderProcessingStatusesUseCase(): GetOrderProcessingStatusesUseCase {
    return this.single(
      GetOrderProcessingStatusesUseCase.name,
      () => new GetOrderProcessingStatusesUseCase({
        orderProcessingStatusesRepository: this.sqCoreOrderProcessingStatusesDataDiModule
          .provideOrderProcessingStatusesRepository()
      })
    )
  }

  provideGetOrderProcessingStatusUseCase(): GetOrderProcessingStatusUseCase {
    return this.single(
      GetOrderProcessingStatusUseCase.name,
      () => new GetOrderProcessingStatusUseCase({
        orderProcessingStatusesRepository: this.sqCoreOrderProcessingStatusesDataDiModule
          .provideOrderProcessingStatusesRepository()
      })
    )
  }

  provideCreateOrderProcessingStatusUseCase(): CreateOrderProcessingStatusUseCase {
    return this.single(
      CreateOrderProcessingStatusUseCase.name,
      () => new CreateOrderProcessingStatusUseCase({
        orderProcessingStatusesRepository:
          this.sqCoreOrderProcessingStatusesDataDiModule.provideOrderProcessingStatusesRepository()
      })
    )
  }

  provideUpdateOrderProcessingStatusUseCase(): UpdateOrderProcessingStatusUseCase {
    return this.single(
      UpdateOrderProcessingStatusUseCase.name,
      () => new UpdateOrderProcessingStatusUseCase({
        orderProcessingStatusesRepository: this.sqCoreOrderProcessingStatusesDataDiModule
          .provideOrderProcessingStatusesRepository()
      })
    )
  }

  provideDestroyOrderProcessingStatusUseCase(): DestroyOrderProcessingStatusUseCase {
    return this.single(
      DestroyOrderProcessingStatusUseCase.name,
      () => new DestroyOrderProcessingStatusUseCase({
        orderProcessingStatusesRepository: this.sqCoreOrderProcessingStatusesDataDiModule
          .provideOrderProcessingStatusesRepository()
      })
    )
  }
}
