import DiModule from "../../../../sqadmin/lib/di/DiModule"
import CreateOrUpdateBonusPointsBurningRuleUseCase
  from "../domain/use-cases/CreateOrUpdateBonusPointsBurningRuleUseCase"
import GetBonusPointsBurningRuleUseCase from "../domain/use-cases/GetBonusPointsBurningRuleUseCase"
import BonusPointsBurningRulesCoreDataDiModule from "./BonusPointsBurningRulesCoreDataDiModule"

export default interface BonusPointsBurningRulesCoreDomainDiModule {
  provideCreateOrUpdateBonusPointsBurningRuleUseCase(): CreateOrUpdateBonusPointsBurningRuleUseCase

  provideGetBonusPointsBurningRuleUseCase(): GetBonusPointsBurningRuleUseCase
}

export class DefaultBonusPointsBurningRulesCoreDomainDiModule extends DiModule
  implements BonusPointsBurningRulesCoreDomainDiModule {
  private readonly bonusPointsBurningRulesCoreDataDiModule: BonusPointsBurningRulesCoreDataDiModule

  constructor(parameters: {
    readonly bonusPointsBurningRulesCoreDataDiModule: BonusPointsBurningRulesCoreDataDiModule
  }) {
    super()
    this.bonusPointsBurningRulesCoreDataDiModule = parameters.bonusPointsBurningRulesCoreDataDiModule
  }

  provideCreateOrUpdateBonusPointsBurningRuleUseCase(): CreateOrUpdateBonusPointsBurningRuleUseCase {
    return this.single(
      CreateOrUpdateBonusPointsBurningRuleUseCase.name,
      () => new CreateOrUpdateBonusPointsBurningRuleUseCase({
        bonusPointsBurningRulesRepository: this.bonusPointsBurningRulesCoreDataDiModule
          .provideBonusPointsBurningRulesRepository()
      })
    )
  }

  provideGetBonusPointsBurningRuleUseCase(): GetBonusPointsBurningRuleUseCase {
    return this.single(
      GetBonusPointsBurningRuleUseCase.name,
      () => new GetBonusPointsBurningRuleUseCase({
        bonusPointsBurningRulesRepository: this.bonusPointsBurningRulesCoreDataDiModule
          .provideBonusPointsBurningRulesRepository()
      })
    )
  }
}
