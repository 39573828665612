import { Expose, Type } from "class-transformer"
import NetworkProduct from "../products/NetworkProduct"

export default class NetworkProductsSetProduct {
  @Expose()
  readonly id: number | null | undefined

  @Expose()
  readonly position: number | null | undefined

  @Expose()
  readonly productId: number | null | undefined

  @Expose()
  @Type(() => NetworkProduct)
  readonly product: NetworkProduct | null | undefined

  @Expose()
  readonly clientId: string | null | undefined

  constructor(parameters?: {
    readonly id: number | null | undefined
    readonly position: number | null | undefined
    readonly productId: number | null | undefined
    readonly product: NetworkProduct | null | undefined
    readonly clientId: string | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.id = parameters.id
    this.position = parameters.position
    this.productId = parameters.productId
    this.product = parameters.product
    this.clientId = parameters.clientId
  }
}
