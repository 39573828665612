import ProductErrorsObject from "../../../domain/entities/products/ProductErrorsObject"
import { Expose, Type } from "class-transformer"
import NetworkProductErrorsObjectAttributes from "./NetworkProductErrorsObjectAttributes"
import NetworkProductVariantErrorsObject from "../product-variants/NetworkProductVariantErrorsObject"
import NetworkProductPropertyErrorsObject from "../product-properties/NetworkProductPropertyErrorsObject"
import NetworkBonusProgramRuleErrorsObject from "../bonus-program-rules/NetworkBonusProgramRuleErrorsObject"

export default class NetworkProductErrorsObject implements ProductErrorsObject {

  @Expose()
  @Type(() => NetworkProductErrorsObjectAttributes)
  readonly attributes?: NetworkProductErrorsObjectAttributes | null

  @Expose()
  @Type(() => NetworkProductVariantErrorsObject)
  readonly mainVariant?: NetworkProductVariantErrorsObject | null

  @Expose()
  @Type(() => NetworkProductVariantErrorsObject)
  readonly variants: NetworkProductVariantErrorsObject[] | null | undefined

  @Expose({ name: "productPropertyValues" })
  @Type(() => NetworkProductPropertyErrorsObject)
  readonly productProperties?: NetworkProductPropertyErrorsObject[] | null

  @Expose()
  @Type(() => NetworkBonusProgramRuleErrorsObject)
  readonly bonusProgramRule: NetworkBonusProgramRuleErrorsObject | null | undefined
}
