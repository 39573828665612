import { Expose } from "class-transformer"

export default class NetworkOptionValue {
  @Expose()
  readonly id?: number | null

  @Expose()
  readonly externalCode?: string | null

  @Expose()
  readonly clientId?: string | null

  @Expose()
  readonly name?: string | null

  @Expose()
  readonly position?: number | null

  constructor(parameters?: {
    readonly id?: number | null
    readonly externalCode?: string | null
    readonly clientId?: string | null
    readonly name?: string | null
    readonly position?: number | null
  }) {
    if (parameters === undefined) {
      return
    }

    this.id = parameters.id
    this.externalCode = parameters.externalCode
    this.clientId = parameters.clientId
    this.name = parameters.name
    this.position = parameters.position
  }
}
