import DiModule from "../../../../../sqadmin/lib/di/DiModule"
import ObjectsDomainDiModule from "../../../../../sqadmin/features/objects/di/modules/ObjectsDomainDiModule"
import CoreI18nDiModule from "../../../../core/di/modules/CoreI18nDiModule"
import autoBind from "auto-bind"
import React from "react"
import ObjectsPresentationLogicParameters
  from "../../../../../sqadmin/features/objects/presentation/entities/objects/ObjectsPresentationLogicParameters"
import OrderProcessingStatusesDomainDiModule from "./OrderProcessingStatusesDomainDiModule"
import OrderProcessingStatusesPage from "../../presentation/pages/order-processing-statuses-page/OrderProcessingStatusesPage"
import OrderProcessingStatusesViewModel
  from "../../presentation/pages/order-processing-statuses-page/OrderProcessingStatusesViewModel"
import OrderProcessingStatusPage from "../../presentation/pages/order-processing-status-page/OrderProcessingStatusPage"
import OrderProcessingStatusViewModel from "../../presentation/pages/order-processing-status-page/OrderProcessingStatusViewModel"

export default interface OrderProcessingStatusesPresentationDiModule {
  provideOrderProcessingStatusesPage(): React.ReactNode

  provideOrderProcessingStatusPage(): React.ReactNode
}

export class DefaultOrderProcessingStatusesPresentationDiModule
  extends DiModule
  implements OrderProcessingStatusesPresentationDiModule {

  private readonly objectsDomainDiModule: ObjectsDomainDiModule
  private readonly orderProcessingStatusesDomainDiModule: OrderProcessingStatusesDomainDiModule
  private readonly coreI18nDiModule: CoreI18nDiModule

  constructor(parameters: {
    readonly objectsDomainDiModule: ObjectsDomainDiModule
    readonly orderProcessingStatusesDomainDiModule: OrderProcessingStatusesDomainDiModule
    readonly coreI18nDiModule: CoreI18nDiModule
  }) {
    super()
    autoBind(this)
    this.objectsDomainDiModule = parameters.objectsDomainDiModule
    this.orderProcessingStatusesDomainDiModule = parameters.orderProcessingStatusesDomainDiModule
    this.coreI18nDiModule = parameters.coreI18nDiModule
  }

  provideOrderProcessingStatusesPage(): React.ReactNode {
    return <OrderProcessingStatusesPage provideViewModel={this.provideOrderProcessingStatusesViewModel} />
  }

  provideOrderProcessingStatusPage(): React.ReactNode {
    return <OrderProcessingStatusPage provideViewModel={this.provideOrderProcessingStatusViewModel} />
  }

  private provideOrderProcessingStatusesViewModel({
    objectsPresentationLogicParameters
  }: {
    readonly objectsPresentationLogicParameters: ObjectsPresentationLogicParameters
  }): OrderProcessingStatusesViewModel {
    return new OrderProcessingStatusesViewModel({
      coreI18n: this.coreI18nDiModule.provideCoreI18n(),
      objectsPresentationLogicParameters,
      subscribeToObjectsEventsUseCase: this.objectsDomainDiModule.provideSubscribeToObjectsEventsUseCase({
        key: "orderProcessingStatuses"
      }),
      unsubscribeFromObjectsEventsUseCase: this.objectsDomainDiModule.provideUnsubscribeFromObjectsEventsUseCase({
        key: "orderProcessingStatuses"
      }),
      getOrderProcessingStatusesUseCase: this.orderProcessingStatusesDomainDiModule
        .provideGetOrderProcessingStatusesUseCase()
    })
  }

  private provideOrderProcessingStatusViewModel({
    orderProcessingStatusId
  }: {
    readonly orderProcessingStatusId?: number
  }): OrderProcessingStatusViewModel {
    return new OrderProcessingStatusViewModel({
      coreI18n: this.coreI18nDiModule.provideCoreI18n(),
      broadcastObjectsEventUseCase: this.objectsDomainDiModule.provideBroadcastObjectsEventUseCase({
        key: "orderProcessingStatuses"
      }),
      getOrderProcessingStatusUseCase: this.orderProcessingStatusesDomainDiModule
        .provideGetOrderProcessingStatusUseCase(),
      createOrderProcessingStatusUseCase: this.orderProcessingStatusesDomainDiModule
        .provideCreateOrderProcessingStatusUseCase(),
      updateOrderProcessingStatusUseCase: this.orderProcessingStatusesDomainDiModule
        .provideUpdateOrderProcessingStatusUseCase(),
      destroyOrderProcessingStatusUseCase: this.orderProcessingStatusesDomainDiModule
        .provideDestroyOrderProcessingStatusUseCase(),
      orderProcessingStatusId: orderProcessingStatusId
    })
  }
}
