import { Expose, Transform } from "class-transformer"
import CalculationType from "../../../domain/entities/calculators/CalculationType"
import transformDecimal from "../../network/transformers/transformDecimal"
import { Decimal } from "decimal.js"

export default class NetworkCalculator {
  @Expose()
  readonly id: number | null | undefined

  @Expose()
  @Transform(transformDecimal)
  readonly value: Decimal | null | undefined

  @Expose()
  readonly calculationType: CalculationType | null | undefined

  @Expose()
  readonly roundingDecimalsCount: number | null | undefined

  constructor(parameters?: {
    readonly id: number | null | undefined
    readonly value: Decimal | null | undefined
    readonly calculationType: CalculationType | null | undefined
    readonly roundingDecimalsCount: number | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.id = parameters.id
    this.value = parameters.value
    this.calculationType = parameters.calculationType
    this.roundingDecimalsCount = parameters.roundingDecimalsCount
  }
}
