import DiModule from "../../../../../sqadmin/lib/di/DiModule"
import CoreDataDiModule from "../../../../core/di/modules/CoreDataDiModule"
import OptionsRepository from "../../domain/repositories/OptionsRepository"
import DefaultOptionsRepository from "../../data/DefaultOptionsRepository"

export default interface OptionsDataDiModule {
  provideOptionsRepository(): OptionsRepository
}

export class DefaultOptionsDataDiModule
  extends DiModule
  implements OptionsDataDiModule {

  private readonly coreDataDiModule: CoreDataDiModule

  constructor(parameters: {
    readonly coreDataDiModule: CoreDataDiModule
  }) {
    super()
    this.coreDataDiModule = parameters.coreDataDiModule
  }

  provideOptionsRepository(): OptionsRepository {
    return this.single(
      DefaultOptionsRepository.name,
      () => new DefaultOptionsRepository({
        optionsNetworkSource: this.coreDataDiModule.provideOptionsNetworkSource()
      })
    )
  }
}
