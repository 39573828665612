import { Expose, Type } from "class-transformer"
import NetworkBonusProgramLevelTransitionRule
  from "../bonus-program-level-transition-rules/NetworkBonusProgramLevelTransitionRule"

export default class NetworkBonusProgramLevel {
  @Expose()
  readonly id: number | null | undefined

  @Expose()
  readonly name: string | null | undefined

  @Expose()
  readonly position: number | null | undefined

  @Expose()
  readonly bonusPointsPercent: number | null | undefined

  @Expose()
  @Type(() => NetworkBonusProgramLevelTransitionRule)
  readonly transitionRule: NetworkBonusProgramLevelTransitionRule | null | undefined

  constructor(parameters?: {
    readonly id: number | null | undefined
    readonly name: string | null | undefined
    readonly position: number | null | undefined
    readonly bonusPointsPercent: number | null | undefined
    readonly transitionRule: NetworkBonusProgramLevelTransitionRule | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.id = parameters.id
    this.name = parameters.name
    this.position = parameters.position
    this.bonusPointsPercent = parameters.bonusPointsPercent
    this.transitionRule = parameters.transitionRule
  }
}
