import CoreI18n from "../../i18n/CoreI18n"
import { I18nDiModule } from "../../../../sqadmin/core/di/modules/I18nDiModule"
import CoreTextProvider from "../../i18n/CoreTextProvider"

export default interface CoreI18nDiModule {
  provideCoreI18n(): CoreI18n
}

export class DefaultCoreI18nDiModule
  extends I18nDiModule<CoreTextProvider>
  implements CoreI18nDiModule {

  provideCoreI18n(): CoreI18n {
    return this.single(
      CoreI18n.name,
      () => new CoreI18n(this.getI18nParameters())
    )
  }
}
