import { UpdateObjectResult } from "../../../../../sqadmin/features/objects/domain/results/UpdateObjectResult"
import PaymentStatusesRepository from "../repositories/PaymentStatusesRepository"
import PaymentStatus from "../../../../core/domain/entities/payment-statuses/PaymentStatus"
import PaymentStatusError from "../../../../core/domain/entities/payment-statuses/PaymentStatusError"

export default class UpdatePaymentStatusUseCase {
  private readonly paymentStatusesRepository: PaymentStatusesRepository

  constructor(parameters: {
    readonly paymentStatusesRepository: PaymentStatusesRepository
  }) {
    this.paymentStatusesRepository = parameters.paymentStatusesRepository
  }

  async call(
    parameters: UpdatePaymentStatusParameters
  ): Promise<UpdateObjectResult<PaymentStatus, PaymentStatusError>> {
    return await this.paymentStatusesRepository.updatePaymentStatus(parameters)
  }
}

export interface UpdatePaymentStatusParameters {
  readonly paymentStatusId: number
  readonly paymentStatus: PaymentStatus
}
