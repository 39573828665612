import ExecutionError from "../../../../core/domain/entities/ExecutionError"
import BroadcastObjectsEventUseCase from "../../domain/use-cases/objects/BroadcastObjectsEventUseCase"
import FormPermissionsSet from "../entities/form-permissions-sets/FormPermissionsSet"
import FormField, { FormFieldViewState } from "../entities/form-fields/FormField"
import ApplicationException from "../../../../core/domain/exceptions/ApplicationException"
import { StateObservable } from "../../../../lib/view-model/StateObservable"
import { AbstractObjectViewState, ObjectViewState } from "../view-states/ObjectViewState"
import autoBind from "auto-bind"
import assertNever from "../../../../lib/assertNever"
import ObjectViewEvent from "../view-events/ObjectViewEvent"
import { LoadObjectResult } from "../../domain/results/LoadObjectResult"
import { CreateObjectResult } from "../../domain/results/CreateObjectResult"
import { UpdateObjectResult } from "../../domain/results/UpdateObjectResult"
import { DestroyObjectResult } from "../../domain/results/DestroyObjectResult"
import { ObjectsEventType } from "../../domain/entities/ObjectsEvent"

// TODO: move ui specific properties to page?
export default class ObjectPresentationLogic<
  DomainObject,
  DomainError extends ExecutionError,
  ErrorsObject
> {
  private readonly broadcastObjectsEventUseCase: BroadcastObjectsEventUseCase
  private readonly isNewObject: boolean
  private readonly buildObject?: () => Promise<DomainObject>
  private readonly getObjectUrl: (object: DomainObject) => string
  private readonly loadObject?: LoadObjectFunction<DomainObject>
  private readonly createObject?: CreateObjectFunction<DomainObject, DomainError>
  private readonly updateObject?: UpdateObjectFunction<DomainObject, DomainError>
  private readonly destroyObject?: DestroyObjectFunction<DomainError>
  private readonly canClosePageAfterUpdatedObject?: boolean
  private readonly onObjectChanged?: (object: DomainObject) => void
  private readonly getErrorsObject?: (parameters: { readonly error?: DomainError }) => ErrorsObject | null | undefined
  private readonly formFields: FormField<DomainObject, ErrorsObject>[]
  private readonly formPermissionsSet: FormPermissionsSet
  private object?: DomainObject
  private changingError?: DomainError
  private changingException?: ApplicationException

  readonly observableObjectViewState: StateObservable<ObjectViewState> =
    new StateObservable<ObjectViewState>({ type: "initial" })

  constructor(parameters: {
    readonly broadcastObjectsEventUseCase: BroadcastObjectsEventUseCase
    readonly isNewObject: boolean
    readonly getObjectUrl: (object: DomainObject) => string
    readonly buildObject?: () => Promise<DomainObject>
    readonly loadObject?: () => Promise<LoadObjectResult<DomainObject>>
    readonly createObject?: CreateObjectFunction<DomainObject, DomainError>
    readonly updateObject?: UpdateObjectFunction<DomainObject, DomainError>
    readonly destroyObject?: DestroyObjectFunction<DomainError>
    readonly canClosePageAfterUpdatedObject?: boolean
    readonly onObjectChanged?: (object: DomainObject) => void
    readonly getErrorsObject?: (parameters: { readonly error?: DomainError }) => ErrorsObject | null | undefined
    readonly formFields: FormField<DomainObject, ErrorsObject>[]
  }) {
    autoBind(this)

    this.broadcastObjectsEventUseCase = parameters.broadcastObjectsEventUseCase
    this.isNewObject = parameters.isNewObject
    this.getObjectUrl = parameters.getObjectUrl
    this.buildObject = parameters.buildObject
    this.loadObject = parameters.loadObject
    this.createObject = parameters.createObject
    this.updateObject = parameters.updateObject
    this.destroyObject = parameters.destroyObject
    this.canClosePageAfterUpdatedObject = parameters.canClosePageAfterUpdatedObject
    this.onObjectChanged = parameters.onObjectChanged
    this.getErrorsObject = parameters.getErrorsObject
    this.formFields = parameters.formFields

    this.formPermissionsSet = this.buildFormPermissionsSet()

    this.configureFormFields()

    if (this.isNewObject) {
      this.buildAndShowObject().then()
    } else {
      this.loadAndShowObject().then()
    }
  }

  onObjectViewEvent(objectViewEvent: ObjectViewEvent) {
    switch (objectViewEvent.type) {
      case "on_create_clicked":
        this.createObjectAndShow().then()
        break
      case "on_create_and_close_clicked":
        this.createObjectAndShowList().then()
        break
      case "on_update_clicked":
        this.updateObjectAndShow().then()
        break
      case "on_update_and_close_clicked":
        this.updateObjectAndShowList().then()
        break
      case "on_delete_clicked":
        this.destroyObjectAndShowList().then()
        break
      case "on_retry_loading_clicked":
        this.loadAndShowObject().then()
        break
      default:
        assertNever(objectViewEvent)
    }
  }

  changeObject(changer: (object: DomainObject) => DomainObject) {
    const updatedObject = changer(this.object!)
    this.setObject(updatedObject)
  }

  getObject(): DomainObject | undefined {
    return this.object
  }

  private buildFormPermissionsSet(): FormPermissionsSet {
    return {
      canCreate: this.createObject !== undefined,
      canUpdate: this.updateObject !== undefined,
      canDestroy: this.destroyObject !== undefined,
      canClosePageAfterUpdated: this.canClosePageAfterUpdatedObject ?? true
    }
  }

  private configureFormFields() {
    this.formFields.forEach((formField: FormField<DomainObject, ErrorsObject>) => {
      formField.setSetObject(this.setObject)
      formField.setSetAndShowLoadedObjectViewState(this.setLoadedObjectViewState)
    })
  }

  private async buildAndShowObject(): Promise<void> {
    const newObject: DomainObject = await this.buildObject!()
    this.setObject(newObject)
    this.setLoadedObjectViewState()
  }

  private async loadAndShowObject(): Promise<void> {
    this.setLoadingObjectViewState()

    const result: LoadObjectResult<DomainObject> = await this.loadObject!()

    switch (result.type) {
      case "error":
        this.setLoadingErrorObjectViewState({ error: result.error })
        break
      case "failure":
        this.setLoadingFailureObjectViewState({ exception: result.exception })
        break
      case "success":
        this.showObject({
          object: result.data
        })
        break
      default:
        assertNever(result)
    }
  }

  private async createObjectAndShow(): Promise<void> {
    const result: CreateObjectResult<DomainObject, DomainError> = await this.executeCreateObject()

    switch (result.type) {
      case "success":
        this.setCreatedObjectViewState({
          object: result.data
        })
        break
      case "error":
        this.showObjectError({
          error: result.error
        })
        break
      case "failure":
        this.showObjectException({
          exception: result.exception
        })
        break
      default:
        assertNever(result)
    }
  }

  private async createObjectAndShowList(): Promise<void> {
    const result: CreateObjectResult<DomainObject, DomainError> = await this.executeCreateObject()

    switch (result.type) {
      case "success":
        this.showObjectsList({
          objectsEventType: ObjectsEventType.CREATED
        })
        break
      case "error":
        this.showObjectError({
          error: result.error
        })
        break
      case "failure":
        this.showObjectException({
          exception: result.exception
        })
        break
      default:
        assertNever(result)
    }
  }

  private async updateObjectAndShowList(): Promise<void> {
    const result: UpdateObjectResult<DomainObject, DomainError> = await this.executeUpdateObject()

    switch (result.type) {
      case "success":
        this.showObjectsList({
          objectsEventType: ObjectsEventType.UPDATED
        })
        break
      case "error":
        this.showObjectError({
          error: result.error
        })
        break
      case "failure":
        this.showObjectException({
          exception: result.exception
        })
        break
      default:
        assertNever(result)
    }
  }

  private async updateObjectAndShow(): Promise<void> {
    const result: UpdateObjectResult<DomainObject, DomainError> = await this.executeUpdateObject()

    switch (result.type) {
      case "success":
        this.showObject({
          object: result.data
        })
        break
      case "error":
        this.showObjectError({
          error: result.error
        })
        break
      case "failure":
        this.showObjectException({
          exception: result.exception
        })
        break
      default:
        assertNever(result)
    }
  }

  private async executeCreateObject(): Promise<CreateObjectResult<DomainObject, DomainError>> {
    this.setCreatingObjectViewState()

    return await this.createObject!({
      object: this.object!
    })
  }

  private async executeUpdateObject(): Promise<UpdateObjectResult<DomainObject, DomainError>> {
    this.setUpdatingObjectViewState()

    return await this.updateObject!({
      object: this.object!
    })
  }

  private async destroyObjectAndShowList(): Promise<void> {
    this.setDestroyingObjectViewState()

    const result: DestroyObjectResult<DomainError> = await this.destroyObject!()

    switch (result.type) {
      case "success":
        this.showObjectsList({
          objectsEventType: ObjectsEventType.DESTROYED
        })
        break
      case "error":
        this.showObjectError({
          error: result.error
        })
        break
      case "failure":
        this.showObjectException({
          exception: result.exception
        })
        break
      default:
        assertNever(result)
    }
  }

  private buildFieldViewStates(): FormFieldViewState[] {
    const errorsObject: ErrorsObject | undefined = this.getErrorsObject?.({
      error: this.changingError
    }) ?? undefined

    return this.formFields.map((formField: FormField<DomainObject, ErrorsObject>): FormFieldViewState => {
      return formField.getViewState(this.object!, errorsObject)
    })
  }

  private setLoadingObjectViewState() {
    this.observableObjectViewState.setValue({
      ...this.buildAbstractObjectViewState(),
      type: "loading"
    })
  }

  private setLoadingErrorObjectViewState({ error }: { readonly error: ExecutionError }) {
    this.observableObjectViewState.setValue({
      ...this.buildAbstractObjectViewState(),
      type: "loading_error",
      error
    })
  }

  private setLoadingFailureObjectViewState({ exception }: { readonly exception: ApplicationException }) {
    this.observableObjectViewState.setValue({
      ...this.buildAbstractObjectViewState(),
      type: "loading_failure",
      exception
    })
  }

  private setLoadedObjectViewState() {
    this.observableObjectViewState.setValue({
      ...this.buildAbstractObjectViewState(),
      type: "loaded",
      fieldViewStates: this.buildFieldViewStates(),
      changingError: this.changingError,
      changingException: this.changingException
    })
  }

  private setCreatingObjectViewState() {
    this.observableObjectViewState.setValue({
      ...this.buildAbstractObjectViewState(),
      type: "creating",
      fieldViewStates: this.buildFieldViewStates()
    })
  }

  private setUpdatingObjectViewState() {
    this.observableObjectViewState.setValue({
      ...this.buildAbstractObjectViewState(),
      type: "updating",
      fieldViewStates: this.buildFieldViewStates()
    })
  }

  private setDestroyingObjectViewState() {
    this.observableObjectViewState.setValue({
      ...this.buildAbstractObjectViewState(),
      type: "destroying",
      fieldViewStates: this.buildFieldViewStates()
    })
  }

  private setListObjectViewState() {
    this.observableObjectViewState.setValue({
      ...this.buildAbstractObjectViewState(),
      type: "list"
    })
  }

  private setCreatedObjectViewState({
    object
  }: {
    readonly object: DomainObject
  }) {
    this.setObject(object)

    this.observableObjectViewState.setValue({
      ...this.buildAbstractObjectViewState(),
      type: "created",
      url: this.getObjectUrl(object)
    })
  }

  private buildAbstractObjectViewState(): AbstractObjectViewState {
    return {
      isNewObject: this.isNewObject,
      formPermissionsSet: this.formPermissionsSet
    }
  }

  private setObject(object: DomainObject) {
    this.object = object
    this.onObjectChanged?.(object)
  }

  private setChangingError(changingError: DomainError | undefined) {
    this.changingError = changingError
  }

  private setChangingException(changingException: ApplicationException | undefined) {
    this.changingException = changingException
  }

  private showObject({
    object
  }: {
    readonly object: DomainObject
  }) {
    const updatedObject = this.formFields.reduce((result, formField) => formField.prepareObject(result), object)
    this.setObject(updatedObject)
    this.setChangingError(undefined)
    this.setChangingException(undefined)
    this.setLoadedObjectViewState()
  }

  private showObjectsList({
    objectsEventType
  }: {
    readonly objectsEventType: ObjectsEventType
  }) {
    this.broadcastObjectsEventUseCase.call({ type: objectsEventType })
    this.setListObjectViewState()
  }

  private showObjectError({
    error
  }: {
    readonly error: DomainError
  }) {
    this.setChangingError(error)
    this.setLoadedObjectViewState()
  }

  private showObjectException({
    exception
  }: {
    readonly exception: ApplicationException
  }) {
    this.setChangingException(exception)
    this.setLoadedObjectViewState()
  }
}

export type LoadObjectFunction<DomainObject> =
  () => Promise<LoadObjectResult<DomainObject>>

export type CreateObjectFunction<DomainObject, DomainError extends ExecutionError> =
  (parameters: { readonly object: DomainObject }) => Promise<CreateObjectResult<DomainObject, DomainError>>

export type UpdateObjectFunction<DomainObject, DomainError extends ExecutionError> =
  (parameters: { readonly object: DomainObject }) => Promise<UpdateObjectResult<DomainObject, DomainError>>

export type DestroyObjectFunction<DomainError extends ExecutionError> =
  () => Promise<DestroyObjectResult<DomainError>>
