import NetworkImage from "../entities/images/NetworkImage"
import Image from "../../domain/entities/images/Image"
import ImageVariantsMappers from "./ImageVariantsMappers"
import { v4 as uuidv4 } from "uuid"

export default class ImagesMapper {
  mapNetworkToDomain({
    image
  }: {
    readonly image: NetworkImage
  }): Image {
    return {
      id: image.id,
      listImageVariant: image.listImageVariant && new ImageVariantsMappers().mapNetworkToDomain({
        imageVariant: image.listImageVariant
      }),
      detailsImageVariant: image.detailsImageVariant && new ImageVariantsMappers().mapNetworkToDomain({
        imageVariant: image.detailsImageVariant
      }),
      fullScreenImageVariant: image.fullScreenImageVariant && new ImageVariantsMappers().mapNetworkToDomain({
        imageVariant: image.fullScreenImageVariant
      }),
      sliderImageVariant: image.sliderImageVariant && new ImageVariantsMappers().mapNetworkToDomain({
        imageVariant: image.sliderImageVariant
      }),
      pagerImageVariant: image.pagerImageVariant && new ImageVariantsMappers().mapNetworkToDomain({
        imageVariant: image.pagerImageVariant
      }),
      clientId: uuidv4(),
      externalCode: image.externalCode,
      attachmentExists: image.attachmentExists
    }
  }

  mapDomainToNetwork({
    image
  }: {
    readonly image: Image
  }): NetworkImage {
    return new NetworkImage({
      id: image.id,
      listImageVariant: image.listImageVariant && new ImageVariantsMappers().mapDomainToNetwork({
        imageVariant: image.listImageVariant
      }),
      detailsImageVariant: image.detailsImageVariant && new ImageVariantsMappers().mapDomainToNetwork({
        imageVariant: image.detailsImageVariant
      }),
      fullScreenImageVariant: image.fullScreenImageVariant && new ImageVariantsMappers().mapDomainToNetwork({
        imageVariant: image.fullScreenImageVariant
      }),
      sliderImageVariant: image.sliderImageVariant && new ImageVariantsMappers().mapDomainToNetwork({
        imageVariant: image.sliderImageVariant
      }),
      pagerImageVariant: image.pagerImageVariant && new ImageVariantsMappers().mapDomainToNetwork({
        imageVariant: image.pagerImageVariant
      }),
      clientId: image.clientId,
      externalCode: image.externalCode,
      attachmentExists: image.attachmentExists
    })
  }
}
