import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../errors/NetworkAttributeError"
import { BatchErrorsObjectAttributes } from "../../../domain/entities/batch/BatchErrorsObjectAttributes"

export default class NetworkBatchErrorsObjectAttributes implements BatchErrorsObjectAttributes {
  @Expose()
  @Type(() => NetworkAttributeError)
  readonly id: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly externalCode: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly price: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly originalPrice: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly bonusPointsPrice: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly clientId: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly batchPlaces: NetworkAttributeError[] | null | undefined

  @Expose({ name: "productVariant" })
  @Type(() => NetworkAttributeError)
  readonly variant: NetworkAttributeError[] | null | undefined
}
