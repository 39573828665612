import DiModule from "../../../../../sqadmin/lib/di/DiModule"
import GetPlacesUseCase from "../../domian/use-cases/GetPlacesUseCase"
import UpdatePlaceUseCase from "../../domian/use-cases/UpdatePlaceUseCase"
import DestroyPlaceUseCase from "../../domian/use-cases/DestroyPlaceUseCase"
import GetPlaceUseCase from "../../domian/use-cases/GetPlaceUseCase"
import CreatePlaceUseCase from "../../domian/use-cases/CreatePlaceUseCase"
import PlacesDataDiModule from "./PlacesDataDiModule"

export default interface PlacesDomainDiModule {
  provideGetPlacesUseCase(): GetPlacesUseCase

  provideGetPlaceUseCase(): GetPlaceUseCase

  provideCreatePlaceUseCase(): CreatePlaceUseCase

  provideUpdatePlaceUseCase(): UpdatePlaceUseCase

  provideDestroyPlaceUseCase(): DestroyPlaceUseCase
}

export class DefaultPlacesDomainDiModule
  extends DiModule
  implements PlacesDomainDiModule {

  private readonly sqCorePlacesDataDiModule: PlacesDataDiModule

  constructor(parameters: {
    readonly sqCorePlacesDataDiModule: PlacesDataDiModule
  }) {
    super()
    this.sqCorePlacesDataDiModule = parameters.sqCorePlacesDataDiModule
  }

  provideGetPlacesUseCase(): GetPlacesUseCase {
    return this.single(
      GetPlacesUseCase.name,
      () => new GetPlacesUseCase({
        placesRepository: this.sqCorePlacesDataDiModule.providePlacesRepository()
      })
    )
  }

  provideGetPlaceUseCase(): GetPlaceUseCase {
    return this.single(
      GetPlaceUseCase.name,
      () => new GetPlaceUseCase({
        placesRepository: this.sqCorePlacesDataDiModule.providePlacesRepository()
      })
    )
  }

  provideCreatePlaceUseCase(): CreatePlaceUseCase {
    return this.single(
      CreatePlaceUseCase.name,
      () => new CreatePlaceUseCase({
        placesRepository: this.sqCorePlacesDataDiModule.providePlacesRepository()
      })
    )
  }

  provideUpdatePlaceUseCase(): UpdatePlaceUseCase {
    return this.single(
      UpdatePlaceUseCase.name,
      () => new UpdatePlaceUseCase({
        placesRepository: this.sqCorePlacesDataDiModule.providePlacesRepository()
      })
    )
  }

  provideDestroyPlaceUseCase(): DestroyPlaceUseCase {
    return this.single(
      DestroyPlaceUseCase.name,
      () => new DestroyPlaceUseCase({
        placesRepository: this.sqCorePlacesDataDiModule.providePlacesRepository()
      })
    )
  }
}
