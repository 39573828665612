import { useEffect } from "react"

type ActionObserver<T> = (val: T) => void;

export class ActionObservable<T> {
  private observers: ActionObserver<T>[] = []
  private unsentAction: T | null = null

  subscribe(observer: ActionObserver<T>) {
    this.observers.push(observer)

    if (this.unsentAction !== null) {
      this.sendAction(this.unsentAction)
      this.unsentAction = null
    }
  }

  unsubscribe(observer: ActionObserver<T>) {
    this.observers = this.observers.filter(actionObserver => actionObserver !== observer)
  }

  sendAction(data: T) {
    if (this.observers.length === 0) {
      this.unsentAction = data
      return
    }

    this.observers.forEach(observer => {
      observer(data)
    })
  }
}

export function useActionObservable<T>(observable: ActionObservable<T>, observer: ActionObserver<T>) {
  useEffect(() => {
    observable.subscribe(observer)
    return () => {
      observable.unsubscribe(observer)
    }
  })
}
