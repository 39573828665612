import React from "react"

const monthsCount = 12

export default function MonthPickerComponent({
  date,
  onChange,
  isDisabled,
  monthNameByNumber
}: {
  readonly date: Date | null
  readonly isDisabled: boolean
  readonly onChange: (parameters: {
    readonly date: Date | null
  }) => void
  readonly monthNameByNumber: { [_: number]: string }
}) {
  const monthNumbers: number[] = Array(monthsCount)
    .fill(null)
    .map((_, monthIndex: number) => monthIndex)

  const yearsRange = 200
  const year: number | undefined = date?.getFullYear()
  const currentYear: number = new Date().getFullYear()
  const years: number[] = Array(yearsRange)
    .fill(null)
    .map((_, index: number) => currentYear - Math.floor(yearsRange / 2) + index)

  const monthNumber: number | undefined = date?.getMonth()

  function handleMonthNumberChange({
    newMonthNumber
  }: {
    readonly newMonthNumber: number
  }) {
    const newDate: Date = createZeroDate()
    newDate.setFullYear((date ?? new Date()).getFullYear())
    newDate.setMonth(newMonthNumber)
    onChange({ date: newDate })
  }

  function handleYearChange({
    newYear
  }: {
    readonly newYear: number
  }) {
    const newDate: Date = createZeroDate()
    newDate.setFullYear(newYear)
    newDate.setMonth((date ?? new Date()).getMonth())
    onChange({ date: newDate })
  }

  function createZeroDate(): Date {
    const newDate: Date = new Date()

    newDate.setDate(1)
    newDate.setHours(0)
    newDate.setMinutes(0)
    newDate.setSeconds(0)

    return newDate
  }

  return (
    <div>
      <select
        value={monthNumber}
        disabled={isDisabled}
        onChange={(event) => handleMonthNumberChange({
          newMonthNumber: parseInt(event.target.value)
        })}
      >
        {monthNumbers.map((monthNumber: number) => (
          <option
            key={monthNumber}
            value={monthNumber}
          >
            {monthNameByNumber[monthNumber]}
          </option>
        ))}
      </select>
      <select
        value={year}
        disabled={isDisabled}
        onChange={(event) => handleYearChange({
          newYear: parseInt(event.target.value)
        })}
      >
        {years.map((year: number) => (
          <option key={year}>{year}</option>
        ))}
      </select>
    </div>
  )
}
