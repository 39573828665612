import { Expose, Type } from "class-transformer"
import NetworkCountry from "../countries/NetworkCountry"

export default class NetworkPhoneNumber {
  @Expose()
  readonly id: number | null | undefined

  @Expose()
  readonly value: string | null | undefined

  @Expose()
  @Type(() => NetworkCountry)
  readonly country: NetworkCountry | null | undefined

  constructor(parameters?: {
    readonly id: number | null | undefined
    readonly value: string | null | undefined
    readonly country: NetworkCountry | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.id = parameters.id
    this.value = parameters.value
    this.country = parameters.country
  }
}
