import CoreTextProvider from "../CoreTextProvider"

export default abstract class AbstractRuCoreTextProvider implements CoreTextProvider {

  abstract applicationName(): string

  retryLoading(): string {
    return "Повторить загрузку"
  }

  somethingWentWrong(): string {
    return "Что-то пошло не так"
  }

  logout(): string {
    return "Выйти"
  }

  loading(): string {
    return "Загрузка..."
  }

  goToNewObject(): string {
    return "Создать"
  }

  newObject(): string {
    return "Создание"
  }

  createObject(): string {
    return "Создать"
  }

  deleteObject(): string {
    return "Удалить"
  }

  editObject(): string {
    return "Редактировать"
  }

  updateObject(): string {
    return "Сохранить"
  }

  clear(): string {
    return "Очистить"
  }

  select(): string {
    return "Выберите"
  }

  enterSearchQuery(): string {
    return "Поиск"
  }

  noObjects(): string {
    return "По вашему запросу ничего не найдено"
  }

  sundayShort(): string {
    return "Вс"
  }

  mondayShort(): string {
    return "Пн"
  }

  tuesdayShort(): string {
    return "Вт"
  }

  wednesdayShort(): string {
    return "Ср"
  }

  thursdayShort(): string {
    return "Чт"
  }

  fridayShort(): string {
    return "Пт"
  }

  saturdayShort(): string {
    return "Сб"
  }

  january(): string {
    return "Январь"
  }

  february(): string {
    return "Февраль"
  }

  march(): string {
    return "Март"
  }

  april(): string {
    return "Апрель"
  }

  may(): string {
    return "Май"
  }

  june(): string {
    return "Июнь"
  }

  july(): string {
    return "Июль"
  }

  august(): string {
    return "Август"
  }

  september(): string {
    return "Сентябрь"
  }

  october(): string {
    return "Октябрь"
  }

  november(): string {
    return "Ноябрь"
  }

  december(): string {
    return "Декабрь"
  }

  selectDate(): string {
    return "Выберите дату"
  }

  createObjectAndClose(): string {
    return "Создать и закрыть"
  }

  updateObjectAndClose(): string {
    return "Сохранить и закрыть"
  }

  close(): string {
    return "Закрыть"
  }

  imageLoading(): string {
    return "Изображение загружается"
  }
}
