import { LoadObjectResult } from "../../../../../sqadmin/features/objects/domain/results/LoadObjectResult"
import PropertiesRepository from "../repositories/PropertiesRepository"
import Property from "../../../../core/domain/entities/properties/Property"

export default class GetPropertyUseCase {
  private readonly propertiesRepository: PropertiesRepository

  constructor(parameters: {
    readonly propertiesRepository: PropertiesRepository
  }) {
    this.propertiesRepository = parameters.propertiesRepository
  }

  async call(parameters: GetPropertyParameters): Promise<LoadObjectResult<Property>> {
    return await this.propertiesRepository.getProperty(parameters)
  }
}

export interface GetPropertyParameters {
  readonly propertyId: number
}

export type GetPropertyResult = LoadObjectResult<Property>
