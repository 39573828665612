import { UpdateObjectResult } from "../../../../../sqadmin/features/objects/domain/results/UpdateObjectResult"
import OrderPaymentMethodsRepository from "../repositories/OrderPaymentMethodsRepository"
import OrderPaymentMethod from "../../../../core/domain/entities/order-payment-methods/OrderPaymentMethod"
import OrderPaymentMethodError from "../../../../core/domain/entities/order-payment-methods/OrderPaymentMethodError"

export default class UpdateOrderPaymentMethodUseCase {
  private readonly orderPaymentMethodsRepository: OrderPaymentMethodsRepository

  constructor(parameters: {
    readonly orderPaymentMethodsRepository: OrderPaymentMethodsRepository
  }) {
    this.orderPaymentMethodsRepository = parameters.orderPaymentMethodsRepository
  }

  async call(
    parameters: UpdateOrderPaymentMethodParameters
  ): Promise<UpdateObjectResult<OrderPaymentMethod, OrderPaymentMethodError>> {
    return await this.orderPaymentMethodsRepository.updateOrderPaymentMethod(parameters)
  }
}

export interface UpdateOrderPaymentMethodParameters {
  readonly orderPaymentMethodId: number
  readonly orderPaymentMethod: OrderPaymentMethod
}
