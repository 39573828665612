import React from "react"
import styles from "./TextComponent.module.scss"
import assertNever from "../../lib/assertNever"

export default function TextComponent({
  children,
  textStyle
}: {
  readonly children?: React.ReactNode
  readonly textStyle: TextStyle
}) {
  function detectStyle(): string {
    switch (textStyle) {
      case TextStyle.BODY2_SECONDARY:
        return `${styles.body2} ${styles.secondary}`
      case TextStyle.BODY2_PRIMARY:
        return `${styles.body2} ${styles.primary}`
      case TextStyle.BODY1_SECONDARY:
        return `${styles.body1} ${styles.secondary}`
      case TextStyle.BODY1_PRIMARY:
        return `${styles.body1} ${styles.primary}`
      case TextStyle.ERROR1_PRIMARY:
        return `${styles.error1} ${styles.primary}`
      case TextStyle.TITLE2_PRIMARY:
        return `${styles.title2} ${styles.primary}`
      case TextStyle.TITLE1_PRIMARY:
        return `${styles.title1} ${styles.primary}`
      case TextStyle.CHECKBOX1_PRIMARY:
        return `${styles.checkbox1} ${styles.primary}`
      case TextStyle.TABLE3_PRIMARY:
        return `${styles.table3} ${styles.primary}`
      case TextStyle.TABLE3_SECONDARY:
        return `${styles.table3} ${styles.secondary}`
      case TextStyle.TABLE1_PRIMARY:
        return `${styles.table1} ${styles.primary}`
      case TextStyle.TABLE2_PRIMARY:
        return `${styles.table2} ${styles.primary}`
      case TextStyle.HEADER1_PRIMARY:
        return `${styles.headline1} ${styles.primary}`
      case TextStyle.LABEL1_SECONDARY:
        return `${styles.label1} ${styles.secondary}`
      case TextStyle.LABEL1_PRIMARY:
        return `${styles.label1} ${styles.primary}`
      default:
        assertNever(textStyle)
    }
  }

  return (
    <div className={detectStyle()}>{children}</div>
  )
}

export enum TextStyle {
  HEADER1_PRIMARY,
  TITLE1_PRIMARY,
  TITLE2_PRIMARY,
  LABEL1_PRIMARY,
  LABEL1_SECONDARY,
  TABLE1_PRIMARY,
  TABLE2_PRIMARY,
  TABLE3_PRIMARY,
  TABLE3_SECONDARY,
  CHECKBOX1_PRIMARY,
  ERROR1_PRIMARY,
  BODY1_PRIMARY,
  BODY2_PRIMARY,
  BODY1_SECONDARY,
  BODY2_SECONDARY
}
