import { CreateObjectResult } from "../../../../../sqadmin/features/objects/domain/results/CreateObjectResult"
import PaymentStatusesRepository from "../repositories/PaymentStatusesRepository"
import PaymentStatus from "../../../../core/domain/entities/payment-statuses/PaymentStatus"
import PaymentStatusError from "../../../../core/domain/entities/payment-statuses/PaymentStatusError"

export default class CreatePaymentStatusUseCase {
  private readonly paymentStatusesRepository: PaymentStatusesRepository

  constructor(parameters: {
    readonly paymentStatusesRepository: PaymentStatusesRepository
  }) {
    this.paymentStatusesRepository = parameters.paymentStatusesRepository
  }

  async call(parameters: {
    readonly paymentStatus: PaymentStatus
  }): Promise<CreateObjectResult<PaymentStatus, PaymentStatusError>> {
    return await this.paymentStatusesRepository.createPaymentStatus(parameters)
  }
}
