import ViewModel from "../../../../../../sqadmin/lib/view-model/ViewModel"
import CoreI18n from "../../../../../core/i18n/CoreI18n"
import CoreUrlProvider from "../../../../../core/presentation/services/CoreUrlProvider"
import BroadcastObjectsEventUseCase
  from "../../../../../../sqadmin/features/objects/domain/use-cases/objects/BroadcastObjectsEventUseCase"
import ObjectPresentationLogic
  from "../../../../../../sqadmin/features/objects/presentation/presentation-logics/ObjectPresentationLogic"
import { StateObservable } from "../../../../../../sqadmin/lib/view-model/StateObservable"
import { ObjectViewState } from "../../../../../../sqadmin/features/objects/presentation/view-states/ObjectViewState"
import autoBind from "auto-bind"
import ObjectViewEvent from "../../../../../../sqadmin/features/objects/presentation/view-events/ObjectViewEvent"
import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import isBlank from "../../../../../../sqadmin/lib/isBlank"
import StringFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/StringFormField"
import GetUserUseCase from "../../../../users-core/domain/use-cases/GetUserUseCase"
import UpdateUserUseCase from "../../../../users-core/domain/use-cases/UpdateUserUseCase"
import UserError from "../../../../../core/domain/entities/users/UserError"
import User from "../../../../../core/domain/entities/users/User"
import UserErrorsObject from "../../../../../core/domain/entities/users/UserErrorsObject"
import PhoneNumber from "../../../../../core/domain/entities/phone-numbers/PhoneNumber"
import isPresent from "../../../../../../sqadmin/lib/isPresent"
import NumberFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/NumberFormField"

export default class ClientViewModel extends ViewModel {
  private readonly coreI18n: CoreI18n
  private readonly coreUrlProvider: CoreUrlProvider
  private readonly broadcastObjectsEventUseCase: BroadcastObjectsEventUseCase
  private readonly getUserUseCase: GetUserUseCase
  private readonly updateUserUseCase: UpdateUserUseCase
  private readonly clientId?: number

  private readonly objectPresentationLogic: ObjectPresentationLogic<
    User,
    UserError,
    UserErrorsObject
  >

  readonly observableObjectViewState: StateObservable<ObjectViewState>

  constructor(parameters: {
    readonly coreI18n: CoreI18n
    readonly broadcastObjectsEventUseCase: BroadcastObjectsEventUseCase
    readonly getUserUseCase: GetUserUseCase
    readonly updateUserUseCase: UpdateUserUseCase
    readonly clientId?: number
  }) {
    super()
    this.coreI18n = parameters.coreI18n
    this.broadcastObjectsEventUseCase = parameters.broadcastObjectsEventUseCase
    this.getUserUseCase = parameters.getUserUseCase
    this.updateUserUseCase = parameters.updateUserUseCase
    this.clientId = parameters.clientId
    this.objectPresentationLogic = this.createObjectPresentationLogic()
    this.observableObjectViewState = this.objectPresentationLogic.observableObjectViewState
    this.coreUrlProvider = new CoreUrlProvider()
    autoBind(this)
  }

  onViewObjectEvent(objectViewEvent: ObjectViewEvent) {
    this.objectPresentationLogic.onObjectViewEvent(objectViewEvent)
  }

  private createObjectPresentationLogic(): ObjectPresentationLogic<
    User,
    UserError,
    UserErrorsObject
  > {
    const coreTextProvider: CoreTextProvider = this.coreI18n.getTextProvider()

    return new ObjectPresentationLogic({
      // TODO: how to not pass?
      broadcastObjectsEventUseCase: this.broadcastObjectsEventUseCase,
      isNewObject: isBlank(this.clientId),
      getObjectUrl: (user) => {
        return this.coreUrlProvider.buildClientUrl({
          id: user.id!
        })
      },
      loadObject: async() => {
        return await this.getUserUseCase.call({ userId: this.clientId! })
      },
      updateObject: async({ object: user }) => {
        return await this.updateUserUseCase.call({
          userId: this.clientId!,
          user
        })
      },
      getErrorsObject: ({ error: userError }) => userError
        ?.errorsObject,
      formFields: [
        new StringFormField<User, UserErrorsObject>({
          getTitle: () => coreTextProvider.firstName(),
          getValue: (user: User) => user.profile?.firstName,
          setValue: (user: User, value: string): User => ({
            ...user,
            profile: {
              ...user.profile ?? this.createProfile(),
              firstName: value
            }
          }),
          getErrors: (userErrorsObject?: UserErrorsObject) => userErrorsObject
            ?.profile
            ?.attributes
            ?.firstName
        }),
        new StringFormField<User, UserErrorsObject>({
          getTitle: () => coreTextProvider.lastName(),
          getValue: (user: User) => user.profile?.lastName,
          setValue: (user: User, value: string): User => ({
            ...user,
            profile: {
              ...user.profile ?? this.createProfile(),
              lastName: value
            }
          }),
          getErrors: (userErrorsObject?: UserErrorsObject) => userErrorsObject
            ?.profile
            ?.attributes
            ?.lastName
        }),
        new StringFormField<User, UserErrorsObject>({
          getDisabled: () => true,
          getTitle: () => coreTextProvider.phoneNumber(),
          getValue: (user: User) => this.formatPhoneNumber(user.phoneNumber),
          setValue: (user: User, _: string): User => ({ ...user }),
          getErrors: () => undefined
        }),
        new StringFormField<User, UserErrorsObject>({
          getDisabled: () => true,
          getTitle: () => coreTextProvider.bonusProgramLevel(),
          getValue: (user: User) => user.bonusProgramMember?.bonusProgramLevel?.name,
          setValue: (user: User): User => ({ ...user }),
          getErrors: () => undefined
        }),
        new NumberFormField<User, UserErrorsObject>({
          getDisabled: () => true,
          getTitle: () => coreTextProvider.bonusPointsPercent(),
          getValue: (user: User) => user.bonusProgramMember?.bonusProgramLevel?.bonusPointsPercent,
          setValue: (user: User): User => ({ ...user }),
          getErrors: () => undefined
        }),
        new NumberFormField<User, UserErrorsObject>({
          getDisabled: () => true,
          getTitle: () => coreTextProvider.bonusPoints(),
          getValue: (user: User) => user.bonusProgramMember?.bonusPointsCount,
          setValue: (user: User): User => ({ ...user }),
          getErrors: () => undefined
        })
      ]
    })
  }

  private formatPhoneNumber(phoneNumber: PhoneNumber | null | undefined): string | null | undefined {
    return [
      phoneNumber?.country?.callingCode,
      phoneNumber?.value
    ]
      .filter((value: string | null | undefined) => isPresent(value))
      .join("")
  }

  private createProfile() {
    return {
      firstName: undefined,
      lastName: undefined,
      middleName: undefined,
      id: undefined
    }
  }
}
