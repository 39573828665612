import NetworkAttributeError from "../../entities/network/errors/NetworkAttributeError"
import AttributeError from "../../../domain/entities/AttributeError"

export default class AttributeErrorsMapper {
  mapNetworkToDomain({ object }: { readonly object: NetworkAttributeError }): AttributeError {
    return {
      type: object.type,
      message: object.message
    }
  }
}
