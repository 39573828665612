import { Expose } from "class-transformer"

export default class NetworkOrderReceivingAddress {
  @Expose()
  readonly id: number | null | undefined

  @Expose()
  readonly value: string | null | undefined

  constructor(parameters?: {
    readonly id: number | null | undefined
    readonly value: string | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.id = parameters.id
    this.value = parameters.value
  }
}
