import { v4 as uuidv4 } from "uuid"
import NetworkVariantOption from "../entities/variant-options/NetworkVariantOption"
import VariantOption from "../../domain/entities/variant-options/VariantOption"
import OptionsMapper from "./OptionsMapper"
import OptionValuesMapper from "./OptionValuesMapper"

export default class VariantOptionsMapper {
  mapNetworkToDomain({
    variantOption
  }: {
    readonly variantOption: NetworkVariantOption
  }): VariantOption {
    return {
      id: variantOption.id,
      optionId: variantOption.optionId,
      optionValueId: variantOption.optionValueId,
      clientId: uuidv4(),
      option: variantOption.option && new OptionsMapper().mapNetworkToDomain({ option: variantOption.option }),
      optionValue: variantOption.optionValue && new OptionValuesMapper().mapNetworkToDomain({
        value: variantOption.optionValue
      })
    }
  }

  mapDomainToNetwork({
    variantOption
  }: {
    readonly variantOption: VariantOption
  }): NetworkVariantOption {
    return new NetworkVariantOption({
      id: variantOption.id,
      optionId: variantOption.optionId,
      optionValueId: variantOption.optionValueId,
      clientId: variantOption.clientId,
      option: variantOption.option && new OptionsMapper().mapDomainToNetwork({ option: variantOption.option }),
      optionValue: variantOption.optionValue && new OptionValuesMapper().mapDomainToNetwork({
        value: variantOption.optionValue
      })
    })
  }
}
