import { CreateObjectResult } from "../../../../../sqadmin/features/objects/domain/results/CreateObjectResult"
import DiscountRulesRepository from "../repositories/DiscountRulesRepository"
import DiscountRule from "../../../../core/domain/entities/discount-rules/DiscountRule"
import DiscountRuleError from "../../../../core/domain/entities/discount-rules/DiscountRuleError"

export default class CreateDiscountRuleUseCase {
  private readonly discountRulesRepository: DiscountRulesRepository

  constructor(parameters: {
    readonly discountRulesRepository: DiscountRulesRepository
  }) {
    this.discountRulesRepository = parameters.discountRulesRepository
  }

  async call(parameters: {
    readonly discountRule: DiscountRule
  }): Promise<CreateObjectResult<DiscountRule, DiscountRuleError>> {
    return await this.discountRulesRepository.createDiscountRule(parameters)
  }
}
