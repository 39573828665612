import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../errors/NetworkAttributeError"
import {
  UserProfileErrorsObjectAttributes
} from "../../../domain/entities/user-profiles/UserProfileErrorsObjectAttributes"
import AttributeError from "../../../../../sqadmin/core/domain/entities/AttributeError"

export default class NetworkUserProfileErrorsObjectAttributes implements UserProfileErrorsObjectAttributes {
  @Expose()
  @Type(() => NetworkAttributeError)
  readonly id: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly firstName: AttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly lastName: AttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly middleName: AttributeError[] | null | undefined
}
