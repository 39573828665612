import NetworkCalculator from "../entities/calculators/NetworkCalculator"
import Calculator from "../../domain/entities/calculators/Calculator"

export default class CalculatorsMapper {
  mapNetworkToDomain({
    calculator
  }: {
    readonly calculator: NetworkCalculator
  }): Calculator {
    return {
      id: calculator.id,
      calculationType: calculator.calculationType,
      value: calculator.value,
      roundingDecimalsCount: calculator.roundingDecimalsCount
    }
  }

  mapDomainToNetwork({
    calculator
  }: {
    readonly calculator: Calculator
  }): NetworkCalculator {
    return new NetworkCalculator({
      id: calculator.id,
      calculationType: calculator.calculationType,
      value: calculator.value,
      roundingDecimalsCount: calculator.roundingDecimalsCount
    })
  }
}
