import React from "react"
import DiModule from "../../../../../sqadmin/lib/di/DiModule"
import ObjectsDomainDiModule from "../../../../../sqadmin/features/objects/di/modules/ObjectsDomainDiModule"
import CoreI18nDiModule from "../../../../core/di/modules/CoreI18nDiModule"
import autoBind from "auto-bind"
import ObjectsPresentationLogicParameters
  from "../../../../../sqadmin/features/objects/presentation/entities/objects/ObjectsPresentationLogicParameters"
import UsersCoreDomainDiModule from "../../../users-core/di/UsersCoreDomainDiModule"
import ClientsPage from "../../presentation/pages/clients-page/ClientsPage"
import ClientsViewModel from "../../presentation/pages/clients-page/ClientsViewModel"
import ClientViewModel from "../../presentation/pages/client-page/ClientViewModel"
import ClientPage from "../../presentation/pages/client-page/ClientPage"

export default interface ClientsPresentationDiModule {
  provideClientsPage(): React.ReactNode

  provideClientPage(): React.ReactNode
}

export class DefaultClientsPresentationDiModule
  extends DiModule
  implements ClientsPresentationDiModule {

  private readonly objectsDomainDiModule: ObjectsDomainDiModule
  private readonly usersCoreDomainDiModule: UsersCoreDomainDiModule
  private readonly coreI18nDiModule: CoreI18nDiModule

  constructor(parameters: {
    readonly objectsDomainDiModule: ObjectsDomainDiModule
    readonly usersCoreDomainDiModule: UsersCoreDomainDiModule
    readonly coreI18nDiModule: CoreI18nDiModule
  }) {
    super()
    autoBind(this)
    this.objectsDomainDiModule = parameters.objectsDomainDiModule
    this.usersCoreDomainDiModule = parameters.usersCoreDomainDiModule
    this.coreI18nDiModule = parameters.coreI18nDiModule
  }

  provideClientsPage(): React.ReactNode {
    return <ClientsPage provideViewModel={this.provideClientsViewModel} />
  }

  provideClientPage(): React.ReactNode {
    return <ClientPage provideViewModel={this.provideClientViewModel} />
  }

  private provideClientViewModel({ clientId }: {
    readonly clientId?: number
  }): ClientViewModel {
    return new ClientViewModel({
      coreI18n: this.coreI18nDiModule.provideCoreI18n(),
      broadcastObjectsEventUseCase: this.objectsDomainDiModule.provideBroadcastObjectsEventUseCase({
        key: "clients"
      }),
      getUserUseCase: this.usersCoreDomainDiModule.provideGetUserUseCase(),
      updateUserUseCase: this.usersCoreDomainDiModule.provideUpdateUserUseCase(),
      clientId: clientId
    })
  }

  private provideClientsViewModel({
    objectsPresentationLogicParameters
  }: {
    readonly objectsPresentationLogicParameters: ObjectsPresentationLogicParameters
  }): ClientsViewModel {
    return new ClientsViewModel({
      objectsPresentationLogicParameters,
      coreI18n: this.coreI18nDiModule.provideCoreI18n(),
      subscribeToObjectsEventsUseCase: this.objectsDomainDiModule.provideSubscribeToObjectsEventsUseCase({
        key: "clients"
      }),
      unsubscribeFromObjectsEventsUseCase: this.objectsDomainDiModule.provideUnsubscribeFromObjectsEventsUseCase({
        key: "clients"
      }),
      getUsersUseCase: this.usersCoreDomainDiModule.provideGetUsersUseCase()
    })
  }
}
