import ConfigurationEntityPolicy from "../../domain/entities/configurations/ConfigurationEntityPolicy"
import NetworkConfigurationEntityPolicy from "../entities/configurations/NetworkConfigurationEntityPolicy"

export default class ConfigurationEntityPoliciesMapper {
  mapNetworkToDomain({
    entityPolicy
  }: {
    readonly entityPolicy: NetworkConfigurationEntityPolicy
  }): ConfigurationEntityPolicy {
    return {
      canCreate: entityPolicy.canCreate,
      canDestroy: entityPolicy.canDestroy,
      canUpdate: entityPolicy.canUpdate
    }
  }

  mapDomainToNetwork({
    entityPolicy
  }: {
    readonly entityPolicy: ConfigurationEntityPolicy
  }): NetworkConfigurationEntityPolicy {
    return new NetworkConfigurationEntityPolicy({
      canCreate: entityPolicy.canCreate,
      canDestroy: entityPolicy.canDestroy,
      canUpdate: entityPolicy.canUpdate
    })
  }
}
