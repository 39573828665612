import { Expose, Type } from "class-transformer"
import NetworkConfigurationEntityPropertyPolicy from "./NetworkConfigurationEntityPropertyPolicy"

export default class NetworkConfigurationEntityProperty {
  @Expose()
  @Type(() => NetworkConfigurationEntityPropertyPolicy)
  readonly policy: NetworkConfigurationEntityPropertyPolicy | null | undefined

  @Expose()
  readonly name: string | null | undefined

  constructor(parameters?: {
    readonly policy: NetworkConfigurationEntityPropertyPolicy | null | undefined
    readonly name: string | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.policy = parameters.policy
    this.name = parameters.name
  }
}
