import ApiLogItem from "../../domain/entities/api-log-items/ApiLogItem"
import NetworkApiLogItem from "../entities/api-log-items/NetworkApiLogItem"

export default class ApiLogItemsMapper {
  mapNetworkToDomain({
    apiLogItem
  }: {
    readonly apiLogItem: NetworkApiLogItem
  }): ApiLogItem {
    return {
      id: apiLogItem.id,
      serviceType: apiLogItem.serviceType,
      direction: apiLogItem.direction,
      startedAt: apiLogItem.startedAt,
      finishedAt: apiLogItem.finishedAt,
      url: apiLogItem.url,
      shortUrl: apiLogItem.shortUrl,
      requestType: apiLogItem.requestType,
      httpStatus: apiLogItem.httpStatus,
      resultType: apiLogItem.resultType,
      requestBody: apiLogItem.requestBody,
      responseBody: apiLogItem.responseBody
    }
  }

  mapDomainToNetwork({
    apiLogItem
  }: {
    readonly apiLogItem: ApiLogItem
  }): NetworkApiLogItem {
    return new NetworkApiLogItem({
      id: apiLogItem.id,
      serviceType: apiLogItem.serviceType,
      direction: apiLogItem.direction,
      startedAt: apiLogItem.startedAt,
      finishedAt: apiLogItem.finishedAt,
      url: apiLogItem.url,
      shortUrl: apiLogItem.shortUrl,
      requestType: apiLogItem.requestType,
      httpStatus: apiLogItem.httpStatus,
      resultType: apiLogItem.resultType,
      requestBody: apiLogItem.requestBody,
      responseBody: apiLogItem.responseBody
    })
  }
}
