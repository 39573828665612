import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../errors/NetworkAttributeError"
import { PaymentErrorsObjectAttributes } from "../../../domain/entities/payments/PaymentErrorsObjectAttributes"

export default class NetworkPaymentErrorsObjectAttributes implements PaymentErrorsObjectAttributes {
  @Expose()
  @Type(() => NetworkAttributeError)
  readonly statusId: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly status: NetworkAttributeError[] | null | undefined
}
