import PropertiesFilter from "../../../../core/domain/entities/properties/PropertiesFilter"
import NetworkPropertiesRequestFilter from "../entities/NetworkPropertiesRequestFilter"

export default class PropertiesFiltersMapper {
  mapDomainToNetwork({
    filter
  }: {
    readonly filter: PropertiesFilter
  }): NetworkPropertiesRequestFilter {
    return new NetworkPropertiesRequestFilter({
      query: filter.query
    })
  }
}
