import Configuration from "../../../../../../core/domain/entities/configurations/Configuration"
import ConfigurationEntityName from "../../../../../../core/domain/entities/configurations/ConfigurationEntityName"
import ConfigurationEntityPropertyName
  from "../../../../../../core/domain/entities/configurations/ConfigurationEntityPropertyName"
import { ObjectEditor } from "./ObjectEditor"

export class ProductVariantBatchPlaceEditor {
  private readonly objectEditor: ObjectEditor

  constructor(parameters: { configuration: Configuration | null | undefined }) {
    this.objectEditor = new ObjectEditor()
    this.objectEditor.init(parameters.configuration, ConfigurationEntityName.PRODUCT_VARIANT_BATCH_PLACE)
  }

  isPlaceDisabled(id: number | null | undefined) {
    return this.objectEditor.isDisabled(id, ConfigurationEntityPropertyName.PLACE_ID)
  }

  isAvailableDisabled(id: number | null | undefined) {
    return this.objectEditor.isDisabled(id, ConfigurationEntityPropertyName.IS_AVAILABLE)
  }

  canCreate() {
    return this.objectEditor.canCreate()
  }

  canDestroy(id: number | null | undefined) {
    return this.objectEditor.canDestroy(id)
  }
}
