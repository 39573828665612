import { Expose, Type } from "class-transformer"
import MenuItemErrorsObject from "../../../domain/entities/menu-items/MenuItemErrorsObject"
import NetworkMenuItemErrorsObjectAttributes from "./NetworkMenuItemErrorsObjectAttributes"
import NetworkLinkErrorsObject from "../links/NetworkLinkErrorsObject"
import NetworkProductsSetErrorsObject from "../products-sets/NetworkProductsSetErrorsObject"

export default class NetworkMenuItemErrorsObject implements MenuItemErrorsObject {
  @Expose()
  readonly clientId: string | null | undefined

  @Expose()
  @Type(() => NetworkMenuItemErrorsObjectAttributes)
  readonly attributes: NetworkMenuItemErrorsObjectAttributes | null | undefined

  @Expose()
  @Type(() => NetworkLinkErrorsObject)
  readonly productsSet: NetworkProductsSetErrorsObject | null | undefined
}
