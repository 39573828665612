import { GetObjectsPageResult } from "../../../../../sqadmin/features/objects/domain/results/GetObjectsPageResult"
import LastItemPaginationsMapper from "../../../../core/data/mappers/LastItemPaginationsMapper"
import SortMapper from "../../../../core/data/mappers/SortMapper"
import OrderReceivingMethodsMapper from "../../../../core/data/mappers/OrderReceivingMethodsMapper"
import PagesMapper from "../../../../core/data/mappers/PagesMapper"
import { LoadObjectResult } from "../../../../../sqadmin/features/objects/domain/results/LoadObjectResult"
import GetObjectNetworkResult from "../../../../core/data/results/GetObjectNetworkResult"
import { CreateObjectResult } from "../../../../../sqadmin/features/objects/domain/results/CreateObjectResult"
import CreateObjectNetworkResult from "../../../../core/data/results/CreateObjectNetworkResult"
import { UpdateObjectResult } from "../../../../../sqadmin/features/objects/domain/results/UpdateObjectResult"
import UpdateObjectNetworkResult from "../../../../core/data/results/UpdateObjectNetworkResult"
import { DestroyObjectResult } from "../../../../../sqadmin/features/objects/domain/results/DestroyObjectResult"
import DestroyObjectNetworkResult from "../../../../core/data/results/DestroyObjectNetworkResult"
import OrderReceivingMethodsRepository from "../../domain/repositories/OrderReceivingMethodsRepository"
import { GetOrderReceivingMethodsParameters } from "../../domain/use-cases/GetOrderReceivingMethodsUseCase"
import OrderReceivingMethod from "../../../../core/domain/entities/order-receiving-methods/OrderReceivingMethod"
import NetworkOrderReceivingMethod
  from "../../../../core/data/entities/order-receiving-methods/NetworkOrderReceivingMethod"
import { UpdateOrderReceivingMethodParameters } from "../../domain/use-cases/UpdateOrderReceivingMethodUseCase"
import OrderReceivingMethodsNetworkSource, {
  GetOrderReceivingMethodsNetworkResult,
  GetOrderShippingTariffsNetworkResult
} from "../sources/OrderReceivingMethodsNetworkSource"
import OrderReceivingMethodsFiltersMapper from "../mappers/OrderReceivingMethodsFiltersMapper"
import NetworkOrderReceivingMethodError
  from "../../../../core/data/entities/order-receiving-methods/NetworkOrderReceivingMethodError"
import isPresent from "../../../../../sqadmin/lib/isPresent"
import OrderReceivingMethodError
  from "../../../../core/domain/entities/order-receiving-methods/OrderReceivingMethodError"
import OrderShippingTariff from "../../../../core/domain/entities/order-shipping-method-tariffs/OrderShippingTariff"
import { GetOrderShippingTariffsParameters } from "../../domain/use-cases/GetOrderShippingTariffsUseCase"
import OrderShippingTariffsMapper from "../../../../core/data/mappers/OrderShippingTariffsMapper"
import NetworkOrderShippingTariff
  from "../../../../core/data/entities/order-shipping-method-tariffs/NetworkOrderShippingTariff"
import OrderShippingTariffsFiltersMapper from "../../../../core/data/mappers/OrderShippingTariffsFiltersMapper"

export default class DefaultOrderReceivingMethodsRepository implements OrderReceivingMethodsRepository {
  private readonly orderReceivingMethodsNetworkSource: OrderReceivingMethodsNetworkSource

  constructor(parameters: {
    readonly orderReceivingMethodsNetworkSource: OrderReceivingMethodsNetworkSource
  }) {
    this.orderReceivingMethodsNetworkSource = parameters.orderReceivingMethodsNetworkSource
  }

  async getOrderReceivingMethods({
    query,
    pagination,
    sort
  }: GetOrderReceivingMethodsParameters): Promise<GetObjectsPageResult<OrderReceivingMethod>> {
    const result: GetOrderReceivingMethodsNetworkResult = await this.orderReceivingMethodsNetworkSource
      .getOrderReceivingMethods({
        filter: isPresent(query) ? new OrderReceivingMethodsFiltersMapper().mapDomainToNetwork({ query }) : undefined,
        pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({ pagination }),
        sort: sort && new SortMapper().mapDomainToNetwork({ sort })
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: {
            objects: result.data.orderReceivingMethods!.map((orderReceivingMethod: NetworkOrderReceivingMethod) => {
              return new OrderReceivingMethodsMapper().mapNetworkToDomain({
                orderReceivingMethod: orderReceivingMethod
              })
            }),
            page: new PagesMapper().mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      default:
        return result
    }
  }

  async getOrderShippingTariffs({
    filter,
    pagination,
    sort
  }: GetOrderShippingTariffsParameters): Promise<GetObjectsPageResult<OrderShippingTariff>> {
    const result: GetOrderShippingTariffsNetworkResult = await this.orderReceivingMethodsNetworkSource
      .getOrderShippingTariffs({
        filter: new OrderShippingTariffsFiltersMapper().mapDomainToNetwork({ filter }),
        pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({ pagination }),
        sort: sort && new SortMapper().mapDomainToNetwork({ sort })
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: {
            objects: result.data.orderShippingTariffs!.map((orderShippingTariff: NetworkOrderShippingTariff) => {
              return new OrderShippingTariffsMapper().mapNetworkToDomain({
                orderShippingTariff: orderShippingTariff
              })
            }),
            page: new PagesMapper().mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      default:
        return result
    }
  }

  async getOrderReceivingMethod({
    orderReceivingMethodId
  }: {
    readonly orderReceivingMethodId: number
  }): Promise<LoadObjectResult<OrderReceivingMethod>> {
    const result: GetObjectNetworkResult<NetworkOrderReceivingMethod> =
      await this.orderReceivingMethodsNetworkSource.getOrderReceivingMethod({
        orderReceivingMethodId: orderReceivingMethodId
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new OrderReceivingMethodsMapper().mapNetworkToDomain({
            orderReceivingMethod: result.data
          })
        }
      default:
        return result
    }
  }

  async createOrderReceivingMethod({
    orderReceivingMethod
  }: {
    readonly orderReceivingMethod: OrderReceivingMethod
  }): Promise<CreateObjectResult<OrderReceivingMethod, OrderReceivingMethodError>> {
    const result: CreateObjectNetworkResult<NetworkOrderReceivingMethod, NetworkOrderReceivingMethodError> =
      await this.orderReceivingMethodsNetworkSource.createOrderReceivingMethod({
        orderReceivingMethod: new OrderReceivingMethodsMapper().mapDomainToNetwork({
          orderReceivingMethod: orderReceivingMethod
        })
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new OrderReceivingMethodsMapper().mapNetworkToDomain({
            orderReceivingMethod: result.data
          })
        }
      default:
        return result
    }
  }

  async updateOrderReceivingMethod({
      orderReceivingMethodId,
      orderReceivingMethod
    }:
      UpdateOrderReceivingMethodParameters
  ): Promise<UpdateObjectResult<OrderReceivingMethod, OrderReceivingMethodError>> {
    const result: UpdateObjectNetworkResult<NetworkOrderReceivingMethod, NetworkOrderReceivingMethodError> =
      await this.orderReceivingMethodsNetworkSource.updateOrderReceivingMethod({
        orderReceivingMethodId,
        orderReceivingMethod: new OrderReceivingMethodsMapper().mapDomainToNetwork({
          orderReceivingMethod: orderReceivingMethod
        })
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new OrderReceivingMethodsMapper().mapNetworkToDomain({
            orderReceivingMethod: result.data
          })
        }
      default:
        return result
    }
  }

  async destroyOrderReceivingMethod({
    orderReceivingMethodId
  }: {
    readonly orderReceivingMethodId: number
  }): Promise<DestroyObjectResult<OrderReceivingMethodError>> {
    const result: DestroyObjectNetworkResult<NetworkOrderReceivingMethodError> =
      await this.orderReceivingMethodsNetworkSource.destroyOrderReceivingMethod({
        orderReceivingMethodId: orderReceivingMethodId
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: undefined
        }
      default:
        return result
    }
  }
}
