import { LoadObjectResult } from "../../../../../sqadmin/features/objects/domain/results/LoadObjectResult"
import GetObjectNetworkResult from "../../../../core/data/results/GetObjectNetworkResult"
import ConfigurationsNetworkSource from "../../../../core/data/sources/network/ConfigurationsNetworkSource"
import ConfigurationsLocalSource from "../../../../core/data/sources/network/ConfigurationsLocalSource"
import ConfigurationsRepository from "../../domain/repositories/ConfigurationsRepository"
import Configuration from "../../../../core/domain/entities/configurations/Configuration"
import NetworkConfiguration from "../../../../core/data/entities/configurations/NetworkConfiguration"
import ConfigurationsMapper from "../../../../core/data/mappers/ConfigurationsMapper"

export default class DefaultConfigurationsRepository implements ConfigurationsRepository {
  private readonly configurationsNetworkSource: ConfigurationsNetworkSource
  private readonly configurationsLocalSource: ConfigurationsLocalSource

  constructor(parameters: {
    readonly configurationsNetworkSource: ConfigurationsNetworkSource
    readonly configurationsLocalSource: ConfigurationsLocalSource
  }) {
    this.configurationsNetworkSource = parameters.configurationsNetworkSource
    this.configurationsLocalSource = parameters.configurationsLocalSource
  }

  async getConfiguration(): Promise<LoadObjectResult<Configuration>> {
    const localResult = this.configurationsLocalSource.getConfiguration()

    if (localResult?.type === "success") {
      return {
        type: "success",
        data: new ConfigurationsMapper().mapNetworkToDomain({ configuration: localResult.data })
      }
    }

    const result: GetObjectNetworkResult<NetworkConfiguration> = await this.configurationsNetworkSource
      .getConfiguration()

    this.configurationsLocalSource.saveConfiguration({ configurationResult: result })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new ConfigurationsMapper().mapNetworkToDomain({
            configuration: result.data
          })
        }
      default:
        return result
    }
  }
}
