import VariantImageErrorsObjectAttributes
  from "../../../domain/entities/variant-images/VariantImageErrorsObjectAttributes"
import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../errors/NetworkAttributeError"

export default class NetworkVariantImageErrorsObjectAttributes implements VariantImageErrorsObjectAttributes {
  @Expose()
  @Type(() => NetworkAttributeError)
  readonly id: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly imageId: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly position: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly clientId: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly image: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly isMain: NetworkAttributeError[] | null | undefined
}
