import { Expose, Type } from "class-transformer"
import NetworkDimensions from "../dimensions/NetworkDimensions"

export default class NetworkImageVariant {
  @Expose()
  readonly url: string | null | undefined

  @Expose()
  @Type(() => NetworkDimensions)
  readonly dimensions: NetworkDimensions | null | undefined

  constructor(parameters?: {
    readonly url: string | null | undefined
    readonly dimensions: NetworkDimensions | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.url = parameters.url
    this.dimensions = parameters.dimensions
  }
}
