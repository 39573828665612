import NetworkOrderProcessingStatusesRequestFilter
  from "../entities/order-processing-statuses/NetworkOrderProcessingStatusesRequestFilter"
import OrderProcessingStatusesFilter from "../../domain/entities/order-processing-statuses/OrderProcessingStatusesFilter"

export default class OrderProcessingStatusesFiltersMapper {
  mapDomainToNetwork({
    filter
  }: {
    readonly filter: OrderProcessingStatusesFilter
  }): NetworkOrderProcessingStatusesRequestFilter {
    return new NetworkOrderProcessingStatusesRequestFilter({
      query: filter.query
    })
  }
}
