import NetworkOptionsRequestFilter from "../entities/options/NetworkOptionsRequestFilter"
import OptionsFilter from "../../domain/entities/options/OptionsFilter"

export default class OptionsFiltersMapper {
  mapDomainToNetwork({
    filter
  }: {
    readonly filter: OptionsFilter
  }): NetworkOptionsRequestFilter {
    return new NetworkOptionsRequestFilter({
      query: filter.query
    })
  }
}
