import DiModule from "../../../../sqadmin/lib/di/DiModule"
import CoreNetworkDiModule from "../../../core/di/modules/CoreNetworkDiModule"
import ApiLogItemsRepository from "../domain/repositories/ApiLogItemsRepository"
import DefaultApiLogItemsRepository from "../data/repositories/DefaultApiLogItemsRepository"
import ApiLogItemsNetworkSource from "../data/sources/ApiLogItemsNetworkSource"

export default interface ApiLogItemsCoreDataDiModule {
  provideApiLogItemsRepository(): ApiLogItemsRepository
}

export class DefaultApiLogItemsCoreDataDiModule extends DiModule implements ApiLogItemsCoreDataDiModule {
  private readonly sqCoreCoreNetworkDiModule: CoreNetworkDiModule

  constructor(parameters: {
    readonly sqCoreCoreNetworkDiModule: CoreNetworkDiModule
  }) {
    super()
    this.sqCoreCoreNetworkDiModule = parameters.sqCoreCoreNetworkDiModule
  }

  provideApiLogItemsRepository(): ApiLogItemsRepository {
    return this.single(
      DefaultApiLogItemsRepository.name,
      () => new DefaultApiLogItemsRepository({
        apiLogItemsNetworkSource: this.provideApiLogItemsNetworkSource()
      })
    )
  }

  private provideApiLogItemsNetworkSource(): ApiLogItemsNetworkSource {
    return this.single(
      ApiLogItemsNetworkSource.name,
      () => new ApiLogItemsNetworkSource({
        backendHttpClient: this.sqCoreCoreNetworkDiModule.provideBackendHttpClient()
      })
    )
  }
}
