import NetworkImageVariant from "../entities/image-variants/NetworkImageVariant"
import ImageVariant from "../../domain/entities/image-variants/ImageVariant"
import DimensionsMapper from "./DimensionsMapper"

export default class ImageVariantsMappers {
  mapNetworkToDomain({
    imageVariant
  }: {
    readonly imageVariant: NetworkImageVariant
  }): ImageVariant {
    return {
      url: imageVariant.url,
      dimensions: imageVariant.dimensions && new DimensionsMapper().mapNetworkToDomain({
        dimensions: imageVariant.dimensions
      })
    }
  }

  mapDomainToNetwork({
    imageVariant
  }: {
    readonly imageVariant: ImageVariant
  }): NetworkImageVariant {
    return new NetworkImageVariant({
      url: imageVariant.url,
      dimensions: imageVariant.dimensions && new DimensionsMapper().mapDomainToNetwork({
        dimensions: imageVariant.dimensions
      })
    })
  }
}
