import React from "react"
import styles from "./ListFormFieldComponent.module.scss"
import {
  ListFormFieldViewState,
  ObjectFieldViewState
} from "../../../entities/form-fields/form-field-by-type/ListFormField"
import ButtonComponent, { ButtonStyle } from "../../../../../../design/button/ButtonComponent"
import CoreTextProvider from "../../../../../../core/i18n/CoreTextProvider"
import { useCoreTextProvider } from "../../../../../../core/presentation/contexts/CoreTextProviderContext"
import TextComponent, { TextStyle } from "../../../../../../design/text/TextComponent"
import FormFieldsComponent from "../../form-fields/FormFieldsComponent"
import PositionControllerComponent from "./PositionControllerComponent"
import FormFieldPlaceType from "../../../entities/form-fields/FormFieldPlaceType"
import isBlank from "../../../../../../lib/isBlank"

export default function ListFormFieldComponent({
  fieldViewState,
  isDisabled
}: {
  readonly fieldViewState: ListFormFieldViewState<unknown>
  readonly isDisabled: boolean
}) {
  const coreTextProvider: CoreTextProvider = useCoreTextProvider()
  const displayTypeStyleName = detectStyle()

  function detectStyle(): string {
    switch (fieldViewState.displayType) {
      case ListFormFieldDisplayType.INLINE:
        return styles.inline
      case ListFormFieldDisplayType.NESTED:
        return styles.nested
      default:
        return styles.nested
    }
  }

  return (
    <div className={[styles.root, displayTypeStyleName].join(" ")}>
      <div className={styles.header}>
        <div className={styles.title}>
          <TextComponent textStyle={TextStyle.TITLE1_PRIMARY}>{fieldViewState.getTitle() ?? ""}</TextComponent>
        </div>
        {fieldViewState.isAddObjectVisible && (
          <ButtonComponent
            buttonStyle={ButtonStyle.TEXT1_PRIMARY}
            text={fieldViewState.addObjectButtonName ?? coreTextProvider.createObject()}
            onClick={fieldViewState.onAddObjectClicked}
            isDisabled={isDisabled}
          />
        )}
      </div>
      {
        fieldViewState.getObjectFieldViewStates().length > 0 && (
          <div className={[styles.list, displayTypeStyleName].join(" ")}>
            {fieldViewState.getObjectFieldViewStates()
              .map((objectState: ObjectFieldViewState<unknown>, index) => {
                  const isRemoveObjectVisible = fieldViewState.getRemoveObjectVisible(objectState.id)
                  return (
                    <div key={objectState.id} className={styles.listItem}>
                      {
                        (objectState.title || isRemoveObjectVisible) && (
                          <div className={styles.listHeader}>
                            {objectState.title && (
                              <div className={styles.title}>
                                <TextComponent textStyle={TextStyle.TITLE2_PRIMARY}>{objectState.title}</TextComponent>
                              </div>
                            )}

                            {fieldViewState.isSwitchPositionVisible && (
                              <PositionControllerComponent
                                index={index}
                                onPositionSwitched={fieldViewState.onPositionSwitched}
                                objectState={objectState}
                                objectStates={fieldViewState.getObjectFieldViewStates()}
                              />
                            )}

                            {fieldViewState.isEditVisible && (
                              <ButtonComponent
                                buttonStyle={objectState.hasErrorsObject ?
                                  ButtonStyle.TEXT1_ERROR : ButtonStyle.TEXT1_PRIMARY}
                                text={coreTextProvider.editObject()}
                                onClick={() => fieldViewState.onEditClicked(objectState.id)}
                                isDisabled={isDisabled}
                              />
                            )}

                            {isRemoveObjectVisible && (
                              <ButtonComponent
                                buttonStyle={ButtonStyle.TEXT1_PRIMARY}
                                isDisabled={isDisabled}
                                text={coreTextProvider.deleteObject()}
                                onClick={() => fieldViewState.onRemoveObjectClicked(objectState.id)}
                              />
                            )}
                          </div>
                        )
                      }

                      <div className={styles.listFields}>
                        <FormFieldsComponent
                          fieldViewStates={objectState.fieldViewStates.filter(value => {
                            return value.getPlaceType() === FormFieldPlaceType.MAIN || isBlank(value.getPlaceType())
                          })}
                          isDisabled={isDisabled}
                        />
                      </div>

                    </div>
                  )
                }
              )}
          </div>
        )
      }
    </div>
  )
}

export enum ListFormFieldDisplayType {
  INLINE,
  NESTED
}
