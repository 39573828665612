import Sort from "../../../../sqadmin/features/objects/presentation/entities/tables/Sort"

export default class SortMapper {
  mapDomainToNetwork({
    sort
  }: {
    readonly sort: Sort
  }): string | undefined {
    return sort?.id && sort?.type ? `${sort?.id}_${sort?.type}` : undefined
  }
}
