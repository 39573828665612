import ViewModel from "../../../../../../sqadmin/lib/view-model/ViewModel"
import BroadcastObjectsEventUseCase
  from "../../../../../../sqadmin/features/objects/domain/use-cases/objects/BroadcastObjectsEventUseCase"
import ObjectPresentationLogic
  from "../../../../../../sqadmin/features/objects/presentation/presentation-logics/ObjectPresentationLogic"
import Place from "../../../../../core/domain/entities/places/Place"
import PlaceError from "../../../../../core/domain/entities/places/PlaceError"
import PlaceErrorsObject from "../../../../../core/domain/entities/places/PlaceErrorsObject"
import { StateObservable } from "../../../../../../sqadmin/lib/view-model/StateObservable"
import { ObjectViewState } from "../../../../../../sqadmin/features/objects/presentation/view-states/ObjectViewState"
import autoBind from "auto-bind"
import ObjectViewEvent from "../../../../../../sqadmin/features/objects/presentation/view-events/ObjectViewEvent"
import isBlank from "../../../../../../sqadmin/lib/isBlank"
import StringFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/StringFormField"
import GetPlaceUseCase from "../../../domian/use-cases/GetPlaceUseCase"
import CreatePlaceUseCase from "../../../domian/use-cases/CreatePlaceUseCase"
import UpdatePlaceUseCase from "../../../domian/use-cases/UpdatePlaceUseCase"
import DestroyPlaceUseCase from "../../../domian/use-cases/DestroyPlaceUseCase"
import BooleanFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/BooleanFormField"
import CoreI18n from "../../../../../core/i18n/CoreI18n"
import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import CoreUrlProvider from "../../../../../core/presentation/services/CoreUrlProvider"
import GetSettlementsUseCase from "../../../../settlements-core/domain/use-cases/GetSettlementsUseCase"
import SingleSelectFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/SingleSelectFormField"
import Settlement from "../../../../../core/domain/entities/settlements/Settlement"
import { Decimal } from "decimal.js"
import DecimalFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/DecimalFormField"
import GeoLocation from "../../../../../core/domain/entities/geo-locations/GeoLocation"

export default class PlaceViewModel extends ViewModel {
  private readonly coreI18n: CoreI18n
  private readonly coreUrlProvider: CoreUrlProvider
  private readonly broadcastObjectsEventUseCase: BroadcastObjectsEventUseCase
  private readonly getPlaceUseCase: GetPlaceUseCase
  private readonly createPlaceUseCase: CreatePlaceUseCase
  private readonly updatePlaceUseCase: UpdatePlaceUseCase
  private readonly destroyPlaceUseCase: DestroyPlaceUseCase
  private readonly getSettlementsUseCase: GetSettlementsUseCase
  private readonly placeId?: number

  private readonly objectPresentationLogic: ObjectPresentationLogic<
    Place,
    PlaceError,
    PlaceErrorsObject
  >

  readonly observableObjectViewState: StateObservable<ObjectViewState>

  constructor(parameters: {
    readonly coreI18n: CoreI18n
    readonly broadcastObjectsEventUseCase: BroadcastObjectsEventUseCase
    readonly getPlaceUseCase: GetPlaceUseCase
    readonly createPlaceUseCase: CreatePlaceUseCase
    readonly updatePlaceUseCase: UpdatePlaceUseCase
    readonly destroyPlaceUseCase: DestroyPlaceUseCase
    readonly getSettlementsUseCase: GetSettlementsUseCase
    readonly placeId?: number
  }) {
    super()
    this.coreI18n = parameters.coreI18n
    this.broadcastObjectsEventUseCase = parameters.broadcastObjectsEventUseCase
    this.getPlaceUseCase = parameters.getPlaceUseCase
    this.createPlaceUseCase = parameters.createPlaceUseCase
    this.updatePlaceUseCase = parameters.updatePlaceUseCase
    this.destroyPlaceUseCase = parameters.destroyPlaceUseCase
    this.getSettlementsUseCase = parameters.getSettlementsUseCase
    this.placeId = parameters.placeId
    this.objectPresentationLogic = this.createObjectPresentationLogic()
    this.observableObjectViewState = this.objectPresentationLogic.observableObjectViewState
    this.coreUrlProvider = new CoreUrlProvider()
    autoBind(this)
  }

  onViewObjectEvent(objectViewEvent: ObjectViewEvent) {
    this.objectPresentationLogic.onObjectViewEvent(objectViewEvent)
  }

  private createObjectPresentationLogic(): ObjectPresentationLogic<
    Place,
    PlaceError,
    PlaceErrorsObject
  > {
    const coreTextProvider: CoreTextProvider = this.coreI18n.getTextProvider()

    return new ObjectPresentationLogic({
      // TODO: how to not pass?
      broadcastObjectsEventUseCase: this.broadcastObjectsEventUseCase,
      isNewObject: isBlank(this.placeId),
      buildObject: async() => ({
        id: undefined,
        externalCode: undefined,
        name: undefined,
        description: undefined,
        isPickupPoint: undefined,
        settlement: undefined,
        settlementId: undefined,
        code: undefined,
        geoLocation: undefined
      }),
      getObjectUrl: (place) => {
        return this.coreUrlProvider.buildPlaceUrl({
          id: place.id!
        })
      },
      loadObject: async() => {
        return await this.getPlaceUseCase.call({ placeId: this.placeId! })
      },
      createObject: async({ object: place }) => {
        return await this.createPlaceUseCase.call({ place })
      },
      updateObject: async({ object: place }) => {
        return await this.updatePlaceUseCase.call({
          placeId: this.placeId!,
          place
        })
      },
      destroyObject: async() => {
        return await this.destroyPlaceUseCase.call({ placeId: this.placeId! })
      },
      getErrorsObject: ({ error: placeError }) => placeError
        ?.errorsObject,
      formFields: [
        new SingleSelectFormField<Place, PlaceErrorsObject, Settlement>({
          getObjects: async({
            query,
            lastObject
          }) => {
            return await this.getSettlementsUseCase.call({
              query: query,
              pagination: { id: lastObject?.id ?? undefined }
            })
          },
          getTitle: () => this.coreI18n.getTextProvider().settlement(),
          getValue: (place: Place) => place.settlement,
          setValue: (
            place: Place,
            settlement: Settlement | null | undefined
          ): Place => {
            return {
              ...place,
              settlement,
              settlementId: settlement && settlement.id
            }
          },
          getErrors: (placeErrorsObject?: PlaceErrorsObject) => placeErrorsObject
            ?.attributes
            ?.settlement,
          getOptionId: (settlement: Settlement) => settlement.id!.toString(),
          getOptionText: (settlement: Settlement) => settlement?.fullName
        }),
        new StringFormField<Place, PlaceErrorsObject>({
          getTitle: () => coreTextProvider.name(),
          getValue: (place: Place) => place.name,
          setValue: (place: Place, name: string): Place => ({
            ...place,
            name
          }),
          getErrors: (placeErrorsObject?: PlaceErrorsObject) => placeErrorsObject
            ?.attributes
            ?.name
        }),
        new StringFormField<Place, PlaceErrorsObject>({
          getDisabled: () => true,
          getTitle: () => coreTextProvider.externalCode(),
          getValue: (place: Place) => place.externalCode,
          setValue: (place: Place, externalCode: string): Place => ({
            ...place,
            externalCode
          }),
          getErrors: (placeErrorsObject?: PlaceErrorsObject) => placeErrorsObject
            ?.attributes
            ?.externalCode
        }),
        new StringFormField<Place, PlaceErrorsObject>({
          getTitle: () => coreTextProvider.description(),
          getValue: (place: Place) => place.description,
          setValue: (place: Place, description: string): Place => ({
            ...place,
            description
          }),
          getErrors: (placeErrorsObject?: PlaceErrorsObject) => placeErrorsObject
            ?.attributes
            ?.description
        }),
        new BooleanFormField<Place, PlaceErrorsObject>({
          getValue: (place: Place) => place.isPickupPoint,
          setValue: (place: Place, isPickupPoint: boolean): Place => ({
            ...place,
            isPickupPoint
          }),
          getText: () => coreTextProvider.pickUpPoint(),
          getErrors: (placeErrorsObject?: PlaceErrorsObject) => placeErrorsObject
            ?.attributes
            ?.isPickupPoint
        }),
        new DecimalFormField<Place, PlaceErrorsObject>({
          getTitle: () => coreTextProvider.longitude(),
          getValue: (place: Place) => place.geoLocation?.longitude,
          setValue: (place: Place, longitude: Decimal | null | undefined): Place => ({
            ...place,
            geoLocation: {
              ...place.geoLocation ?? this.createGeoLocation(),
              longitude
            }
          }),
          getErrors: (placeErrorsObject?: PlaceErrorsObject) => placeErrorsObject
            ?.geoLocation
            ?.attributes
            ?.longitude
        }),
        new DecimalFormField<Place, PlaceErrorsObject>({
          getTitle: () => coreTextProvider.latitude(),
          getValue: (place: Place) => place.geoLocation?.latitude,
          setValue: (place: Place, latitude: Decimal | null | undefined): Place => ({
            ...place,
            geoLocation: {
              ...place.geoLocation ?? this.createGeoLocation(),
              latitude
            }
          }),
          getErrors: (placeErrorsObject?: PlaceErrorsObject) => placeErrorsObject
            ?.geoLocation
            ?.attributes
            ?.latitude
        })
      ]
    })
  }

  private createGeoLocation(): GeoLocation {
    return {
      latitude: undefined,
      longitude: undefined
    }
  }
}
