import { LoadObjectResult } from "../../../../../sqadmin/features/objects/domain/results/LoadObjectResult"
import OrderPaymentMethodsRepository from "../repositories/OrderPaymentMethodsRepository"
import OrderPaymentMethod from "../../../../core/domain/entities/order-payment-methods/OrderPaymentMethod"

export default class GetOrderPaymentMethodUseCase {
  private readonly orderPaymentMethodsRepository: OrderPaymentMethodsRepository

  constructor(parameters: {
    readonly orderPaymentMethodsRepository: OrderPaymentMethodsRepository
  }) {
    this.orderPaymentMethodsRepository = parameters.orderPaymentMethodsRepository
  }

  async call(parameters: {
    readonly orderPaymentMethodId: number
  }): Promise<LoadObjectResult<OrderPaymentMethod>> {
    return await this.orderPaymentMethodsRepository.getOrderPaymentMethod(parameters)
  }
}
