import ProductCategoriesRepository from "../../repositories/ProductCategoriesRepository"
import ProductCategory from "../../../../../core/domain/entities/product-categories/ProductCategory"
import { LoadObjectResult } from "../../../../../../sqadmin/features/objects/domain/results/LoadObjectResult"

export default class GetProductCategoryUseCase {
  private readonly productCategoriesRepository: ProductCategoriesRepository

  constructor(parameters: {
    readonly productCategoriesRepository: ProductCategoriesRepository
  }) {
    this.productCategoriesRepository = parameters.productCategoriesRepository
  }

  async call(parameters: {
    readonly productCategoryId: number
  }): Promise<LoadObjectResult<ProductCategory>> {
    return await this.productCategoriesRepository.getProductCategory(parameters)
  }
}
