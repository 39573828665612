import BackendHttpClient, { BackendHttpClientResult } from "../../../../core/data/network/BackendHttpClient"
import NetworkProduct from "../../../../core/data/entities/products/NetworkProduct"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import { instanceToPlain, plainToInstance } from "class-transformer"
import NetworkExecutionError from "../../../../core/data/entities/errors/NetworkExecutionError"
import NetworkProductsResponseBody from "../entities/NetworkProductsResponseBody"
import NetworkProductResponseBody from "../entities/NetworkProductResponseBody"
import GetObjectNetworkResult from "../../../../core/data/results/GetObjectNetworkResult"
import CreateObjectNetworkResult from "../../../../core/data/results/CreateObjectNetworkResult"
import NetworkProductError from "../../../../core/data/entities/products/NetworkProductError"
import NetworkProductRequestBody from "../entities/NetworkProductRequestBody"
import UpdateObjectNetworkResult from "../../../../core/data/results/UpdateObjectNetworkResult"
import DestroyObjectNetworkResult from "../../../../core/data/results/DestroyObjectNetworkResult"
import NetworkProductsRequestQuery from "../entities/NetworkProductsRequestQuery"
import NetworkProductsRequestFilter from "../entities/NetworkProductsRequestFilter"
import NetworkLastItemPagination from "../../../../core/data/entities/pagination/NetworkLastItemPagination"
import SuccessExecutionResult from "../../../../../sqadmin/core/domain/results/SuccessExecutionResult"
import ErrorExecutionResult from "../../../../../sqadmin/core/domain/results/ErrorExecutionResult"
import FailureExecutionResult from "../../../../../sqadmin/core/domain/results/FailureExecutionResult"

const basePath = "/admin/products"

export default class ProductsNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async getProducts({
    filter,
    pagination,
    sort
  }: {
    readonly filter?: NetworkProductsRequestFilter | null
    readonly pagination?: NetworkLastItemPagination | null
    readonly sort?: string | null
  }): Promise<GetProductsNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: basePath,
      parameters: instanceToPlain(new NetworkProductsRequestQuery({
        filter,
        pagination,
        sort
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkProductsResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async getProduct({
    productId
  }: {
    readonly productId: number
  }): Promise<GetObjectNetworkResult<NetworkProduct>> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}/${productId}`
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkProductResponseBody, result.body).product!
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async createProduct({
    product
  }: {
    readonly product: NetworkProduct
  }): Promise<CreateObjectNetworkResult<NetworkProduct, NetworkProductError>> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.POST,
      path: basePath,
      body: instanceToPlain(new NetworkProductRequestBody({
        product
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkProductResponseBody, result.body).product!
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkProductError, result.body)
        }
      case "failure":
        return result
    }
  }

  async updateProduct({
    productId,
    product
  }: {
    readonly productId: number
    readonly product: NetworkProduct
  }): Promise<UpdateObjectNetworkResult<NetworkProduct, NetworkProductError>> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.PUT,
      path: `${basePath}/${productId}`,
      body: instanceToPlain(new NetworkProductRequestBody({
        product
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkProductResponseBody, result.body).product!
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkProductError, result.body)
        }
      case "failure":
        return result
    }
  }

  async destroyProduct({
    productId
  }: {
    readonly productId: number
  }): Promise<DestroyObjectNetworkResult<NetworkProductError>> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.DELETE,
      path: `${basePath}/${productId}`
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: undefined
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkProductError, result.body)
        }
      case "failure":
        return result
    }
  }
}

export type GetProductsNetworkResult =
  SuccessExecutionResult<NetworkProductsResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult
