import ProductsRepository from "../../domain/repositories/ProductsRepository"
import ProductsNetworkSource, {
  GetProductsNetworkResult
} from "../sources/ProductsNetworkSource"
import Product from "../../../../core/domain/entities/products/Product"
import NetworkProduct from "../../../../core/data/entities/products/NetworkProduct"
import ProductsMapper from "../../../../core/data/mappers/ProductsMapper"
import GetObjectNetworkResult from "../../../../core/data/results/GetObjectNetworkResult"
import ProductError from "../../../../core/domain/entities/products/ProductError"
import CreateObjectNetworkResult from "../../../../core/data/results/CreateObjectNetworkResult"
import NetworkProductError from "../../../../core/data/entities/products/NetworkProductError"
import UpdateObjectNetworkResult from "../../../../core/data/results/UpdateObjectNetworkResult"
import DestroyObjectNetworkResult from "../../../../core/data/results/DestroyObjectNetworkResult"
import PagesMapper from "../../../../core/data/mappers/PagesMapper"
import LastItemPaginationsMapper from "../../../../core/data/mappers/LastItemPaginationsMapper"
import SortMapper from "../../../../core/data/mappers/SortMapper"
import { LoadObjectResult } from "../../../../../sqadmin/features/objects/domain/results/LoadObjectResult"
import { CreateObjectResult } from "../../../../../sqadmin/features/objects/domain/results/CreateObjectResult"
import { UpdateObjectResult } from "../../../../../sqadmin/features/objects/domain/results/UpdateObjectResult"
import { DestroyObjectResult } from "../../../../../sqadmin/features/objects/domain/results/DestroyObjectResult"
import { GetObjectsPageResult } from "../../../../../sqadmin/features/objects/domain/results/GetObjectsPageResult"
import { GetProductsParameters } from "../../domain/use-cases/products/GetProductsUseCase"
import ProductFiltersMapper from "../mappers/ProductFiltersMapper"

export default class DefaultProductsRepository implements ProductsRepository {
  private readonly productsNetworkSource: ProductsNetworkSource

  constructor(parameters: {
    readonly productsNetworkSource: ProductsNetworkSource
  }) {
    this.productsNetworkSource = parameters.productsNetworkSource
  }

  async getProducts({
    filter,
    query,
    sort,
    pagination
  }: GetProductsParameters): Promise<GetObjectsPageResult<Product>> {
    const result: GetProductsNetworkResult = await this.productsNetworkSource.getProducts({
      filter: filter && new ProductFiltersMapper().mapDomainToNetwork({ filter, query }),
      pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({ pagination }),
      sort: sort && new SortMapper().mapDomainToNetwork({ sort })
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: {
            objects: result.data.products!.map((networkProduct: NetworkProduct) => {
              return new ProductsMapper().mapNetworkToDomain({
                product: networkProduct
              })
            }),
            page: new PagesMapper().mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      default:
        return result
    }
  }

  async getProduct(parameters: {
    readonly productId: number
  }): Promise<LoadObjectResult<Product>> {
    const result: GetObjectNetworkResult<NetworkProduct> = await this.productsNetworkSource.getProduct(parameters)

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new ProductsMapper().mapNetworkToDomain({
            product: result.data
          })
        }
      default:
        return result
    }
  }

  async createProduct({
    product
  }: {
    readonly product: Product
  }): Promise<CreateObjectResult<Product, ProductError>> {
    const result: CreateObjectNetworkResult<NetworkProduct, NetworkProductError> =
      await this.productsNetworkSource.createProduct({
        product: new ProductsMapper().mapDomainToNetwork({
          product
        })
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new ProductsMapper().mapNetworkToDomain({
            product: result.data
          })
        }
      default:
        return result
    }
  }

  async updateProduct({
    productId,
    product
  }: {
    readonly productId: number
    readonly product: Product
  }): Promise<UpdateObjectResult<Product, ProductError>> {
    const result: UpdateObjectNetworkResult<NetworkProduct, NetworkProductError> =
      await this.productsNetworkSource.updateProduct({
        productId,
        product: new ProductsMapper().mapDomainToNetwork({
          product
        })
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new ProductsMapper().mapNetworkToDomain({
            product: result.data
          })
        }
      default:
        return result
    }
  }

  async destroyProduct({
    productId
  }: {
    readonly productId: number
  }): Promise<DestroyObjectResult<ProductError>> {
    const result: DestroyObjectNetworkResult<NetworkProductError> =
      await this.productsNetworkSource.destroyProduct({
        productId
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: undefined
        }
      default:
        return result
    }
  }
}
