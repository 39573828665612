import AuthenticationI18n from "../../i18n/AuthenticationI18n"
import { I18nDiModule } from "../../../../core/di/modules/I18nDiModule"
import AuthenticationTextProvider from "../../i18n/AuthenticationTextProvider"

export default interface AuthenticationI18nDiModule {
  provideAuthenticationI18n(): AuthenticationI18n
}

export class DefaultAuthenticationI18nDiModule
  extends I18nDiModule<AuthenticationTextProvider>
  implements AuthenticationI18nDiModule {

  provideAuthenticationI18n(): AuthenticationI18n {
    return this.single(
      AuthenticationI18n.name,
      () => new AuthenticationI18n(this.getI18nParameters())
    )
  }
}
