import { v4 as uuidv4 } from "uuid"
import NetworkMenuItem from "../entities/menu-items/NetworkMenuItem"
import MenuItem from "../../domain/entities/menu-items/MenuItem"
import LinksMapper from "./LinksMapper"
import DashboardsMapper from "./DashboardsMapper"
import ProductsSetsMapper from "./ProductsSetsMapper"

export default class MenuItemsMapper {
  mapNetworkToDomain({
    menuItem
  }: {
    readonly menuItem: NetworkMenuItem
  }): MenuItem {
    return {
      id: menuItem.id,
      clientId: uuidv4(),
      position: menuItem.position,
      title: menuItem.title,
      type: menuItem.type,
      link: menuItem.link && new LinksMapper().mapNetworkToDomain({ link: menuItem.link }),
      dashboard: menuItem.dashboard && new DashboardsMapper().mapNetworkToDomain({ dashboard: menuItem.dashboard }),
      dashboardId: menuItem.dashboardId,
      productsSet: menuItem.productsSet && new ProductsSetsMapper().mapNetworkToDomain({
        productsSet: menuItem.productsSet
      })
    }
  }

  mapDomainToNetwork({
    menuItem
  }: {
    readonly menuItem: MenuItem
  }): NetworkMenuItem {
    return new NetworkMenuItem({
      id: menuItem.id,
      clientId: menuItem.clientId,
      position: menuItem.position,
      title: menuItem.title,
      type: menuItem.type,
      link: menuItem.link && new LinksMapper().mapDomainToNetwork({ link: menuItem.link }),
      dashboard: menuItem.dashboard && new DashboardsMapper().mapDomainToNetwork({ dashboard: menuItem.dashboard }),
      dashboardId: menuItem.dashboardId,
      productsSet: menuItem.productsSet && new ProductsSetsMapper().mapDomainToNetwork({
        productsSet: menuItem.productsSet
      })
    })
  }
}
