import NetworkDiscountRulesRequestFilter from "../entities/NetworkDiscountRulesRequestFilter"
import DiscountRulesFilter from "../../../../core/domain/entities/discount-rules/DiscountRulesFilter"

export default class DiscountRulesFilterMapper {
  mapDomainToNetwork({
    filter
  }: {
    readonly filter: DiscountRulesFilter
  }): NetworkDiscountRulesRequestFilter {
    return {
      query: filter.query
    }
  }
}
