import NetworkSettlementsRequestFilter
  from "../../../features/settlements-core/data/entities/NetworkSettlementsRequestFilter"

export default class SettlementsFiltersMapper {
  mapDomainToNetwork({
    query
  }: {
    readonly query: string | null | undefined
  }): NetworkSettlementsRequestFilter {
    return new NetworkSettlementsRequestFilter({
      query: query
    })
  }
}
