import { Expose, Type } from "class-transformer"
import NetworkImage from "../images/NetworkImage"

export default class NetworkVariantImage {
  @Expose()
  readonly id: number | null | undefined

  @Expose()
  readonly imageId: number | null | undefined

  @Expose()
  readonly position: number | null | undefined

  @Expose()
  readonly clientId: string | null | undefined

  @Expose()
  @Type(() => NetworkImage)
  readonly image: NetworkImage | null | undefined

  @Expose()
  readonly isMain: boolean | null | undefined

  constructor(parameters?: {
    readonly id: number | null | undefined
    readonly imageId: number | null | undefined
    readonly position: number | null | undefined
    readonly clientId: string | null | undefined
    readonly image: NetworkImage | null | undefined
    readonly isMain: boolean | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.id = parameters.id
    this.imageId = parameters.imageId
    this.position = parameters.position
    this.clientId = parameters.clientId
    this.image = parameters.image
    this.isMain = parameters.isMain
  }
}
