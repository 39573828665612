import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../errors/NetworkAttributeError"
import AttributeError from "../../../../../sqadmin/core/domain/entities/AttributeError"
import OrderReceivingPriceRuleErrorsObjectAttributes
  from "../../../domain/entities/order-receiving-price-rules/OrderReceivingPriceRuleErrorsObjectAttributes"

export default class NetworkOrderReceivingPriceRuleErrorsObjectAttributes
  implements OrderReceivingPriceRuleErrorsObjectAttributes {
  @Expose()
  @Type(() => NetworkAttributeError)
  readonly id: AttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly clientId: AttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly conditions: AttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly position: AttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly calculator: AttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly calculationType: AttributeError[] | null | undefined
}
