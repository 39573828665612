import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../errors/NetworkAttributeError"
import ProductPropertyErrorsObjectAttributes
  from "../../../domain/entities/product-properties/ProductPropertyErrorsObjectAttributes"

export default class NetworkProductPropertyErrorsObjectAttributes implements ProductPropertyErrorsObjectAttributes {
  @Expose()
  @Type(() => NetworkAttributeError)
  readonly propertyId?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly propertyValueId?: NetworkAttributeError[] | null
}
