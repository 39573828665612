import NetworkConfigurationEntity from "../entities/configurations/NetworkConfigurationEntity"
import ConfigurationEntity from "../../domain/entities/configurations/ConfigurationEntity"
import ConfigurationEntityPoliciesMapper from "./ConfigurationEntityPoliciesMapper"
import ConfigurationEntityPropertiesMapper from "./ConfigurationEntityPropertiesMapper"

export default class ConfigurationEntitiesMapper {
  mapNetworkToDomain({
    configurationEntity
  }: {
    readonly configurationEntity: NetworkConfigurationEntity
  }): ConfigurationEntity {
    return {
      name: configurationEntity.name,
      properties: configurationEntity.properties && configurationEntity.properties.map(property => {
        return new ConfigurationEntityPropertiesMapper().mapNetworkToDomain({ entityProperty: property })
      }),
      policy: configurationEntity.policy &&
        new ConfigurationEntityPoliciesMapper().mapNetworkToDomain({ entityPolicy: configurationEntity.policy })
    }
  }

  mapDomainToNetwork({
    configurationEntity
  }: {
    readonly configurationEntity: ConfigurationEntity
  }): NetworkConfigurationEntity {
    return new NetworkConfigurationEntity({
      name: configurationEntity.name,
      properties: configurationEntity.properties && configurationEntity.properties.map(property => {
        return new ConfigurationEntityPropertiesMapper().mapDomainToNetwork({ entityProperty: property })
      }),
      policy: configurationEntity.policy &&
        new ConfigurationEntityPoliciesMapper().mapDomainToNetwork({ entityPolicy: configurationEntity.policy })
    })
  }
}
