import SessionsRepository from "../../domain/repositories/SessionsRepository"
import DiModule from "../../../lib/di/DiModule"
import DefaultSessionsRepository from "../../data/repositories/DefaultSessionsRepository"
import CoreSessionsNetworkDiModule from "./CoreSessionsNetworkDiModule"
import CoreLocalDiModule from "./CoreLocalDiModule"

export default interface CoreDataDiModule {
  provideSessionsRepository(): SessionsRepository
}

export class DefaultCoreDataDiModule extends DiModule implements CoreDataDiModule {
  private readonly coreLocalDiModule: CoreLocalDiModule
  private readonly coreSessionsNetworkSourceDiModule: CoreSessionsNetworkDiModule

  constructor(parameters: {
    readonly coreLocalDiModule: CoreLocalDiModule
    readonly coreSessionsNetworkSourceDiModule: CoreSessionsNetworkDiModule
  }) {
    super()
    this.coreLocalDiModule = parameters.coreLocalDiModule
    this.coreSessionsNetworkSourceDiModule = parameters.coreSessionsNetworkSourceDiModule
  }

  provideSessionsRepository(): SessionsRepository {
    return this.single(
      DefaultSessionsRepository.name,
      () => new DefaultSessionsRepository({
        sessionLocalSource: this.coreLocalDiModule.provideSessionLocalSource(),
        sessionNetworkSource: this.coreSessionsNetworkSourceDiModule.provideSessionNetworkSource()
      })
    )
  }
}
