import React from "react"
import { DateRange } from "../../../../lib/date-range/DateRange"
import CoreTextProvider from "../../../i18n/CoreTextProvider"
import { useCoreTextProvider } from "../../contexts/CoreTextProviderContext"
import DateRangePickerComponent from "../../../../lib/date-range-picker/DateRangePickerComponent"
import useWeekDayNameByNumber from "../../hooks/useWeekDayNameByNumber"
import useMonthNameByNumber from "../../hooks/useMonthNameByNumber"

export default function AppDateRangePickerComponent(props: {
  readonly dateRange: DateRange | null
  readonly onDateRangeChanged: (dateRange: DateRange | null) => void
  readonly onMonthChanged?: (parameters: { readonly visibleDateRange: DateRange }) => void
  readonly onDateRangeSelected?: (dateRange: DateRange | null) => void
  readonly renderDateDescription?: (parameters: { readonly date: Date }) => React.ReactNode
  readonly selectButtonText?: string
  readonly disabled?: boolean
}) {
  const coreTextProvider: CoreTextProvider = useCoreTextProvider()
  const weekDayNameByNumber: { [key: number]: string } = useWeekDayNameByNumber()
  const monthNameByNumber: { [key: number]: string } = useMonthNameByNumber()

  return (
    <DateRangePickerComponent
      {...props}
      weekDayNameByNumber={weekDayNameByNumber}
      monthNameByNumber={monthNameByNumber}
      clearButtonText={coreTextProvider.clear()}
      selectButtonText={props.selectButtonText ?? coreTextProvider.select()}
    />
  )
}
