import React, { useCallback, useRef } from "react"
import styles from "./ZoomAreaComponent.module.scss"
import wrapElement from "../wrapElement"
import useScreenSize from "./UseScreenSize"
import isBlank from "../isBlank"

interface PointParameters {
  x: number
  y: number
}

class Point {
  x: number
  y: number

  constructor({ x, y }: PointParameters) {
    this.x = x
    this.y = y
  }

  static fromMouseMovement(event: React.MouseEvent) {
    return new Point({ x: event.movementX, y: event.movementY })
  }

  static fromCursor(event: React.MouseEvent) {
    return new Point({ x: event.clientX, y: event.clientY })
  }

  static fromTouch(touch: React.Touch): Point {
    return new Point({
      x: touch.clientX,
      y: touch.clientY
    })
  }

  plus(point: Point): Point {
    return new Point({
      x: this.x + point.x,
      y: this.y + point.y
    })
  }

  minus(point: Point): Point {
    return new Point({
      x: this.x - point.x,
      y: this.y - point.y
    })
  }

  divide(point: Point): Point {
    return new Point({
      x: this.x / point.x,
      y: this.y / point.y
    })
  }

  scaleBy(scale: Point | number): Point {
    const scaleX = scale instanceof Point ? scale.x : scale
    const scaleY = scale instanceof Point ? scale.y : scale

    return new Point({
      x: this.x * scaleX,
      y: this.y * scaleY
    })
  }
}

interface VectorParameters {
  start: Point
  end: Point
}

class Vector {
  start: Point
  end: Point

  constructor({ start, end }: VectorParameters) {
    this.start = start
    this.end = end
  }

  static fromTwoTouches(touchOne: React.Touch, touchTwo: React.Touch) {
    return new Vector({
      start: Point.fromTouch(touchOne),
      end: Point.fromTouch(touchTwo)
    })
  }

  getPointBetweenBounds(part: number) {
    return new Point({
      x: (this.start.x + this.end.x) * part,
      y: (this.start.y + this.end.y) * part
    })
  }

  getLength(): number {
    const doubleMultiplier = 2

    const xDistance = this.start.x - this.end.x
    const yDistance = this.start.y - this.end.y

    return Math.sqrt(
      Math.pow(xDistance, doubleMultiplier) +
      Math.pow(yDistance, doubleMultiplier)
    )
  }
}

interface ViewMetricsParameters {
  originalSize: Point
  originalPositionOnScreen: Point
  scale: number
  position: Point
  size: Point
  positionOnScreen: Point
}

class ViewMetrics {
  originalSize: Point
  originalPositionOnScreen: Point
  scale: number
  position: Point
  size: Point
  positionOnScreen: Point

  constructor(parameters: ViewMetricsParameters) {
    this.originalSize = parameters.originalSize
    this.originalPositionOnScreen = parameters.originalPositionOnScreen
    this.scale = parameters.scale
    this.position = parameters.position
    this.size = parameters.size
    this.positionOnScreen = parameters.positionOnScreen
  }
}

interface ZoomAreaProps {
  children?: React.ReactElement,
  onClick?: () => void
  originalWidth?: number | null
  originalHeight?: number | null
}

interface ViewParameters {
  position?: Point
  scale?: number
}

export default function ZoomAreaComponent({
  children,
  onClick: onClickListener,
  originalWidth,
  originalHeight
}: ZoomAreaProps) {
  const isMouseDownRef = useRef(false)
  const scaleRef = useRef(1.0)
  const positionRef = useRef(new Point({ x: 0.0, y: 0.0 }))
  const previousTouchEventRef = useRef<React.TouchEvent>()

  const childrenRef = useRef<HTMLElement>()
  const childrenCallbackRef = useCallback((view: HTMLImageElement | null) => {
    saveToChildrenRef(view)
    initView(view)
  }, [])

  const screenSize = useScreenSize()

  function saveToChildrenRef(view: HTMLImageElement | null) {
    childrenRef.current = view ?? undefined
  }

  function initView(view: HTMLImageElement | null) {
    if (view === null) {
      return
    }

    stopClicksPropagation(view)
    setPivotToLeftTopCorner(view)
    fitIntoScreen()
  }

  function fitIntoScreen() {
    if (isBlank(originalWidth) || isBlank(originalHeight)) {
      return
    }

    let scale: number | null | undefined
    const scaleX = screenSize.width / originalWidth
    const scaleY = screenSize.height / originalHeight

    if (scaleX < 1 && scaleX < scaleY) {
      scale = scaleX
    }

    if (scaleY < 1 && scaleY < scaleX) {
      scale = scaleY
    }

    if (isBlank(scale)) {
      return
    }

    const newX = (originalWidth - originalWidth * scale) / 2
    const newY = (originalHeight - originalHeight * scale) / 2
    const newSize = new Point({ x: newX, y: newY })
    transformView({ position: newSize, scale: scale })
  }

  function stopClicksPropagation(view: HTMLElement) {
    view.addEventListener("click", (event) => {
      event.stopPropagation()
    })
  }

  function setPivotToLeftTopCorner(view: HTMLElement) {
    view.style.transformOrigin = "left top"
  }

  function moveViewByMouseEvent(event: React.MouseEvent) {
    const delta = Point.fromMouseMovement(event)
    moveView(delta)
  }

  function scaleViewByWheelEvent(event: React.WheelEvent) {
    const viewMetrics = getViewMetrics()

    const wheelDelta = event.deltaY
    const scaleSpeed = 1.15
    const scaleDelta = wheelDelta < 0 ? scaleSpeed : 1.0 / scaleSpeed
    const newScale = viewMetrics.scale * scaleDelta
    const newSize = viewMetrics.originalSize.scaleBy(newScale)

    const cursorPositionOnScreen = Point.fromCursor(event)
    const cursorPositionOnImage = cursorPositionOnScreen.minus(viewMetrics.positionOnScreen)
    const pivotRelativeToImage = cursorPositionOnImage.divide(viewMetrics.size)

    const newSizeDelta = newSize.minus(viewMetrics.size)
    const shiftBecauseOfZoom = newSizeDelta.scaleBy(pivotRelativeToImage)
    const newPosition = viewMetrics.position.minus(shiftBecauseOfZoom)

    transformView({ position: newPosition, scale: newScale })
  }

  function moveViewBySingleTouch(touch: React.Touch, previousTouch: React.Touch) {
    const touchPoint = Point.fromTouch(touch)
    const previousTouchPoint = Point.fromTouch(previousTouch)
    const delta = touchPoint.minus(previousTouchPoint)
    moveView(delta)
  }

  function moveViewByTwoTouches(
    touchOne: React.Touch,
    touchTwo: React.Touch,
    previousTouchOne: React.Touch,
    previousTouchTwo: React.Touch
  ) {
    const touchesVector = Vector.fromTwoTouches(touchOne, touchTwo)
    const previousTouchesVector = Vector.fromTwoTouches(previousTouchOne, previousTouchTwo)
    const viewMetrics = getViewMetrics()

    const distanceBetweenTouches = touchesVector.getLength()
    const distanceBetweenPreviousTouches = previousTouchesVector.getLength()
    const scaleDelta = distanceBetweenTouches / distanceBetweenPreviousTouches
    const newScale = viewMetrics.scale * scaleDelta
    const newSize = viewMetrics.originalSize.scaleBy(newScale)

    const halfPart = 0.5
    const touchesCenterOnScreen = touchesVector.getPointBetweenBounds(halfPart)
    const touchesCenterOnImage = touchesCenterOnScreen.minus(viewMetrics.positionOnScreen)
    const pivotRelativeToImage = touchesCenterOnImage.divide(viewMetrics.size)

    const newSizeDelta = newSize.minus(viewMetrics.size)
    const shiftBecauseOfZoom = newSizeDelta.scaleBy(pivotRelativeToImage)
    const previousTouchesCenterOnScreen = previousTouchesVector.getPointBetweenBounds(halfPart)
    const touchesCenterDelta = touchesCenterOnScreen.minus(previousTouchesCenterOnScreen)
    const newPosition = viewMetrics.position.minus(shiftBecauseOfZoom).plus(touchesCenterDelta)

    transformView({ position: newPosition, scale: newScale })
  }

  function getViewMetrics(): ViewMetrics {
    const view = wrapElement(childrenRef.current)!
    const offsets = view.getOffsets()

    const originalSize = new Point({ x: view.getOuterWidth(), y: view.getOuterHeight() })
    const originalPositionOnScreen = new Point({ x: offsets.left, y: offsets.top })

    const scale = scaleRef.current
    const position = positionRef.current
    const size = originalSize.scaleBy(scale)
    const positionOnScreen = originalPositionOnScreen.plus(position)

    return new ViewMetrics({
      originalSize,
      originalPositionOnScreen,
      scale,
      position,
      size,
      positionOnScreen
    })
  }

  function transformView(parameters: ViewParameters) {
    const view = childrenRef.current!
    const { position = positionRef.current, scale = scaleRef.current } = parameters

    view.style.transform = `translate(${position.x}px, ${position.y}px) scale(${scale})`

    positionRef.current = position
    scaleRef.current = scale
  }

  function moveView(vector: Point) {
    const position = positionRef.current
    const newPosition = position.plus(vector)
    transformView({ position: newPosition })
  }

  function onClick() {
    onClickListener?.()
  }

  function onWheel(event: React.WheelEvent<HTMLElement>) {
    scaleViewByWheelEvent(event)
  }

  function onMouseDown(event: React.MouseEvent) {
    event.preventDefault()
    isMouseDownRef.current = true
  }

  function onMouseUp() {
    isMouseDownRef.current = false
  }

  function onMouseMove(event: React.MouseEvent) {
    const isMouseDown = isMouseDownRef.current
    if (!isMouseDown) return
    moveViewByMouseEvent(event)
  }

  function onTouchMove(event: React.TouchEvent) {
    const previousTouchEvent = previousTouchEventRef.current
    const touches = event.touches
    const noPreviousTouch = previousTouchEvent === undefined
    const noTouches = touches.length === 0

    previousTouchEventRef.current = event

    if (noPreviousTouch || noTouches) {
      return
    }

    const previousTouches = previousTouchEvent.touches
    const multipleTouchLimit = 2

    if (touches.length === 1) {
      const touch = touches[0]
      const previousTouch = previousTouches[touch.identifier]

      if (previousTouch === undefined) {
        return
      }

      moveViewBySingleTouch(touch, previousTouch)
      return
    }

    if (touches.length === multipleTouchLimit) {
      const touchOne = touches[0]
      const touchTwo = touches[1]
      const previousTouchOne = previousTouches[touchOne.identifier]
      const previousTouchTwo = previousTouches[touchTwo.identifier]

      if (previousTouchOne === undefined || previousTouchTwo === undefined) {
        return
      }

      moveViewByTwoTouches(touchOne, touchTwo, previousTouchOne, previousTouchTwo)
    }
  }

  function onTouchEnd() {
    previousTouchEventRef.current = undefined
  }

  if (children === undefined) {
    return <></>
  }

  const childrenWithRef = React.cloneElement(children, {
    ref: childrenCallbackRef
  })

  return (
    <div
      className={styles.zoomArea}
      onWheel={onWheel}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseMove={onMouseMove}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
      onClick={onClick}
    >
      {childrenWithRef}
    </div>
  )
}
