import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import { useCoreTextProvider } from "../../../../../core/presentation/contexts/CoreTextProviderContext"
import { useNumberObjectId } from "../../../../../../sqadmin/features/objects/presentation/hooks/useObjectId"
import { useViewModel } from "../../../../../../sqadmin/lib/view-model/ViewModel"
import ObjectComponent from "../../../../../../sqadmin/features/objects/presentation/components/object/ObjectComponent"
import React from "react"
import BonusProgramLevelViewModel from "./BonusProgramLevelViewModel"

export interface BonusProgramLevelPageProps {
  readonly provideViewModel: (parameters: { readonly bonusProgramLevelId?: number }) => BonusProgramLevelViewModel
}

export default function BonusProgramLevelPage({
  provideViewModel
}: BonusProgramLevelPageProps) {
  const coreTextProvider: CoreTextProvider = useCoreTextProvider()
  const bonusProgramLevelId: number | undefined = useNumberObjectId()

  console.log(bonusProgramLevelId)
  const viewModel: BonusProgramLevelViewModel = useViewModel(() => provideViewModel({
    bonusProgramLevelId
  }))

  return (
    <ObjectComponent
      newObjectTitle={coreTextProvider.newBonusProgramLevel()}
      existedObjectTitle={coreTextProvider.bonusProgramLevel()}
      observableObjectViewState={viewModel.observableObjectViewState}
      onObjectViewEvent={viewModel.onViewObjectEvent}
    />
  )
}
