import { RouteObject } from "react-router-dom"
import CoreUrlProvider from "../../../core/presentation/services/CoreUrlProvider"
import { objectIdUrlPart } from "../../../../sqadmin/features/objects/presentation/hooks/useObjectId"
import DiscountRulesDi from "../di/DiscountRulesDi"

export default function createDiscountRulesRoutes({
  di
}: {
  readonly di: DiscountRulesDi
}): RouteObject[] {
  const urlProvider = new CoreUrlProvider()
  return [
    {
      path: urlProvider.buildDiscountRulesPath(),
      element: di
        .sqCoreDiscountRulesDiComponent
        .discountRulesPresentationDiModule
        .provideDiscountRulesPage()
    },
    {
      path: urlProvider.buildNewDiscountRulePath(),
      element: di
        .sqCoreDiscountRulesDiComponent
        .discountRulesPresentationDiModule
        .provideDiscountRulePage()
    },
    {
      path: urlProvider.buildDiscountRulePath({ id: `:${objectIdUrlPart}` }),
      element: di
        .sqCoreDiscountRulesDiComponent
        .discountRulesPresentationDiModule
        .provideDiscountRulePage()
    }
  ]
}
