import React from "react"
import DiModule from "../../../../../sqadmin/lib/di/DiModule"
import ObjectsDomainDiModule from "../../../../../sqadmin/features/objects/di/modules/ObjectsDomainDiModule"
import autoBind from "auto-bind"

import ObjectsPresentationLogicParameters
  from "../../../../../sqadmin/features/objects/presentation/entities/objects/ObjectsPresentationLogicParameters"
import PlacesViewModel from "../../presentation/pages/places-page/PlacesViewModel"
import PlacesDomainDiModule from "./PlacesDomainDiModule"
import PlaceViewModel from "../../presentation/pages/place-page/PlaceViewModel"
import PlacePage from "../../presentation/pages/place-page/PlacePage"
import PlacesPage from "../../presentation/pages/places-page/PlacesPage"
import CoreI18nDiModule from "../../../../core/di/modules/CoreI18nDiModule"
import SettlementsCoreDomainDiModule from "../../../settlements-core/di/SettlementsCoreDomainDiModule"

export default interface PlacesPresentationDiModule {
  providePlacesPage(): React.ReactNode

  providePlacePage(): React.ReactNode
}

export class DefaultPlacesPresentationDiModule
  extends DiModule
  implements PlacesPresentationDiModule {

  private readonly objectsDomainDiModule: ObjectsDomainDiModule
  private readonly placesDomainDiModule: PlacesDomainDiModule
  private readonly settlementsCoreDomainDiModule: SettlementsCoreDomainDiModule
  private readonly coreI18nDiModule: CoreI18nDiModule

  constructor(parameters: {
    readonly objectsDomainDiModule: ObjectsDomainDiModule
    readonly placesDomainDiModule: PlacesDomainDiModule
    readonly settlementsCoreDomainDiModule: SettlementsCoreDomainDiModule
    readonly coreI18nDiModule: CoreI18nDiModule
  }) {
    super()
    autoBind(this)
    this.objectsDomainDiModule = parameters.objectsDomainDiModule
    this.placesDomainDiModule = parameters.placesDomainDiModule
    this.settlementsCoreDomainDiModule = parameters.settlementsCoreDomainDiModule
    this.coreI18nDiModule = parameters.coreI18nDiModule
  }

  providePlacesPage(): React.ReactNode {
    return <PlacesPage provideViewModel={this.providePlacesViewModel} />
  }

  providePlacePage(): React.ReactNode {
    return <PlacePage provideViewModel={this.providePlaceViewModel} />
  }

  private providePlacesViewModel({
    objectsPresentationLogicParameters
  }: {
    readonly objectsPresentationLogicParameters: ObjectsPresentationLogicParameters
  }): PlacesViewModel {
    return new PlacesViewModel({
      objectsPresentationLogicParameters,
      coreI18n: this.coreI18nDiModule.provideCoreI18n(),
      subscribeToObjectsEventsUseCase: this.objectsDomainDiModule.provideSubscribeToObjectsEventsUseCase({
        key: "places"
      }),
      unsubscribeFromObjectsEventsUseCase: this.objectsDomainDiModule.provideUnsubscribeFromObjectsEventsUseCase({
        key: "places"
      }),
      getPlacesUseCase: this.placesDomainDiModule.provideGetPlacesUseCase()
    })
  }

  private providePlaceViewModel({
    placeId
  }: {
    readonly placeId?: number
  }): PlaceViewModel {
    return new PlaceViewModel({
      coreI18n: this.coreI18nDiModule.provideCoreI18n(),
      broadcastObjectsEventUseCase: this.objectsDomainDiModule.provideBroadcastObjectsEventUseCase({
        key: "places"
      }),
      getPlaceUseCase: this.placesDomainDiModule.provideGetPlaceUseCase(),
      createPlaceUseCase: this.placesDomainDiModule.provideCreatePlaceUseCase(),
      updatePlaceUseCase: this.placesDomainDiModule.provideUpdatePlaceUseCase(),
      destroyPlaceUseCase: this.placesDomainDiModule.provideDestroyPlaceUseCase(),
      getSettlementsUseCase: this.settlementsCoreDomainDiModule.provideGetSettlementsUseCase(),
      placeId
    })
  }
}
