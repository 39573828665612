import DiModule from "../../../../sqadmin/lib/di/DiModule"
import CoreNetworkDiModule from "../../../core/di/modules/CoreNetworkDiModule"
import SettlementsRepository from "../domain/repositories/SettlementsRepository"
import DefaultSettlementsRepository from "../data/repositories/DefaultSettlementsRepository"
import SettlementsNetworkSource from "../data/sources/SettlementsNetworkSource"

export default interface SettlementsCoreDataDiModule {
  provideSettlementsRepository(): SettlementsRepository
}

export class DefaultSettlementsCoreDataDiModule extends DiModule implements SettlementsCoreDataDiModule {
  private readonly sqCoreCoreNetworkDiModule: CoreNetworkDiModule

  constructor(parameters: {
    readonly sqCoreCoreNetworkDiModule: CoreNetworkDiModule
  }) {
    super()
    this.sqCoreCoreNetworkDiModule = parameters.sqCoreCoreNetworkDiModule
  }

  provideSettlementsRepository(): SettlementsRepository {
    return this.single(
      DefaultSettlementsRepository.name,
      () => new DefaultSettlementsRepository({
        settlementsNetworkSource: this.provideSettlementsNetworkSource()
      })
    )
  }

  private provideSettlementsNetworkSource(): SettlementsNetworkSource {
    return this.single(
      SettlementsNetworkSource.name,
      () => new SettlementsNetworkSource({
        backendHttpClient: this.sqCoreCoreNetworkDiModule.provideBackendHttpClient()
      })
    )
  }
}
