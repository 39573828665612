import BackendHttpClient, { BackendHttpClientResult } from "../../../../core/data/network/BackendHttpClient"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import { instanceToPlain, plainToInstance } from "class-transformer"
import NetworkExecutionError from "../../../../core/data/entities/errors/NetworkExecutionError"
import GetObjectNetworkResult from "../../../../core/data/results/GetObjectNetworkResult"
import CreateObjectNetworkResult from "../../../../core/data/results/CreateObjectNetworkResult"
import NetworkBonusPointsBurningRuleResponseBody from "../entities/NetworkBonusPointsBurningRuleResponseBody"
import NetworkBonusPointsBurningRule
  from "../../../../core/data/entities/bonus-points-burning-rules/NetworkBonusPointsBurningRule"
import NetworkBonusPointsBurningRuleRequestBody from "../entities/NetworkBonusPointsBurningRuleRequestBody"
import NetworkBonusPointsBurningRuleError
  from "../../../../core/data/entities/bonus-points-burning-rules/NetworkBonusPointsBurningRuleError"

const basePath = "/admin/bonus_points_burning_rule"

export default class BonusPointsBurningRulesNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async getBonusPointsBurningRule(): Promise<GetObjectNetworkResult<NetworkBonusPointsBurningRule>> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}`
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkBonusPointsBurningRuleResponseBody, result.body).bonusPointsBurningRule!
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async createOrUpdateBonusPointsBurningRule({
    bonusPointsBurningRule
  }: {
    readonly bonusPointsBurningRule: NetworkBonusPointsBurningRule
  }): Promise<CreateObjectNetworkResult<NetworkBonusPointsBurningRule, NetworkBonusPointsBurningRuleError>> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.POST,
      path: basePath,
      body: instanceToPlain(new NetworkBonusPointsBurningRuleRequestBody({
        bonusPointsBurningRule
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkBonusPointsBurningRuleResponseBody, result.body).bonusPointsBurningRule!
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkBonusPointsBurningRuleError, result.body)
        }
      case "failure":
        return result
    }
  }
}
