import { CreateObjectResult } from "../../../../../sqadmin/features/objects/domain/results/CreateObjectResult"
import OrderPaymentMethodsRepository from "../repositories/OrderPaymentMethodsRepository"
import OrderPaymentMethod from "../../../../core/domain/entities/order-payment-methods/OrderPaymentMethod"
import OrderPaymentMethodError from "../../../../core/domain/entities/order-payment-methods/OrderPaymentMethodError"

export default class CreateOrderPaymentMethodUseCase {
  private readonly orderPaymentMethodsRepository: OrderPaymentMethodsRepository

  constructor(parameters: {
    readonly orderPaymentMethodsRepository: OrderPaymentMethodsRepository
  }) {
    this.orderPaymentMethodsRepository = parameters.orderPaymentMethodsRepository
  }

  async call(parameters: {
    readonly orderPaymentMethod: OrderPaymentMethod
  }): Promise<CreateObjectResult<OrderPaymentMethod, OrderPaymentMethodError>> {
    return await this.orderPaymentMethodsRepository.createOrderPaymentMethod(parameters)
  }
}
