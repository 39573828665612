import { Expose } from "class-transformer"
import NetworkPlace from "./NetworkPlace"

export default class NetworkPlaceRequestBody {
  @Expose()
  readonly place: NetworkPlace

  constructor(parameters: {
    readonly place: NetworkPlace
  }) {
    this.place = parameters.place
  }
}
