import BackendHttpClient, { BackendHttpClientResult } from "../../network/BackendHttpClient"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import { plainToInstance } from "class-transformer"
import NetworkExecutionError from "../../entities/errors/NetworkExecutionError"
import GetObjectNetworkResult from "../../results/GetObjectNetworkResult"
import NetworkConfigurationResponseBody from "../../entities/configurations/NetworkConfigurationResponseBody"
import NetworkConfiguration from "../../entities/configurations/NetworkConfiguration"

const basePath = "/admin/configuration"

export default class ConfigurationsNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async getConfiguration(): Promise<GetObjectNetworkResult<NetworkConfiguration>> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: basePath
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkConfigurationResponseBody, result.body).configuration!
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }
}
