import { Expose, Type } from "class-transformer"
import NetworkPage from "../../../../core/data/entities/pagination/NetworkPage"
import NetworkBonusProgramLevel from "../../../../core/data/entities/bonus-program-levels/NetworkBonusProgramLevel"

export default class NetworkBonusProgramLevelsResponseBody {
  @Expose()
  @Type(() => NetworkBonusProgramLevel)
  readonly bonusProgramLevels: NetworkBonusProgramLevel[] | null | undefined

  @Expose()
  @Type(() => NetworkPage)
  readonly page: NetworkPage | null | undefined
}
