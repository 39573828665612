import React, { forwardRef, useImperativeHandle, useRef } from "react"
import CoreTextProvider from "../../../i18n/CoreTextProvider"
import { useCoreTextProvider } from "../../contexts/CoreTextProviderContext"
import { ReactComponent as SearchSvg } from "../../../../core/assets/icons/ic_search.svg"
import TextInputComponent, {
  TextInputComponentRef,
  TextInputStyle
} from "../../../../design/text-input/TextInputComponent"

export interface SearchBarComponentRef {
  readonly focus: () => void
}

export interface SearchBarComponentProps {
  readonly query: string | null | undefined
  readonly placeholder?: string
  readonly disabled?: boolean
  readonly onQueryChanged: (query: string) => void
  readonly onSearchRequested: () => void
}

function SearchBarComponent(
  {
    query,
    placeholder,
    disabled,
    onQueryChanged,
    onSearchRequested
  }: SearchBarComponentProps, forwardedRef: React.ForwardedRef<SearchBarComponentRef>
) {
  const inputRef: React.RefObject<TextInputComponentRef> = useRef<TextInputComponentRef>(null)
  const coreTextProvider: CoreTextProvider = useCoreTextProvider()

  function handleInputOnChange(value: string) {
    onQueryChanged(value)
  }

  function handleInputOnKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      onSearchRequested()
    }
  }

  function focusOnInput() {
    inputRef.current?.focus()
  }

  useImperativeHandle(forwardedRef, () => {
    return {
      focus: focusOnInput
    }
  })

  return (
    <TextInputComponent
      textInputStyle={TextInputStyle.TEXT_INPUT1_PRIMARY}
      ref={inputRef}
      value={query ?? ""}
      onChange={handleInputOnChange}
      onKeyDown={handleInputOnKeyDown}
      isDisabled={disabled}
      leftIcon={<SearchSvg />}
      placeholder={placeholder ?? coreTextProvider.enterSearchQuery()}
    />
  )
}

export default forwardRef(SearchBarComponent)
