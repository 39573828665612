import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import { useCoreTextProvider } from "../../../../../core/presentation/contexts/CoreTextProviderContext"
import { useNumberObjectId } from "../../../../../../sqadmin/features/objects/presentation/hooks/useObjectId"
import { useViewModel } from "../../../../../../sqadmin/lib/view-model/ViewModel"
import ObjectComponent from "../../../../../../sqadmin/features/objects/presentation/components/object/ObjectComponent"
import React from "react"
import OptionViewModel from "./OptionViewModel"

export interface OptionPageProps {
  readonly provideViewModel: (parameters: { readonly optionId?: number }) => OptionViewModel
}

export default function OptionPage({
  provideViewModel
}: OptionPageProps) {
  const coreTextProvider: CoreTextProvider = useCoreTextProvider()
  const optionId: number | undefined = useNumberObjectId()

  const viewModel: OptionViewModel = useViewModel(() => provideViewModel({
    optionId
  }))

  return (
    <ObjectComponent
      newObjectTitle={coreTextProvider.newOption()}
      existedObjectTitle={coreTextProvider.option()}
      observableObjectViewState={viewModel.observableObjectViewState}
      onObjectViewEvent={viewModel.onViewObjectEvent}
    />
  )
}
