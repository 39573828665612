import GetPropertiesUseCase from "../domain/use-cases/GetPropertiesUseCase"
import DiModule from "../../../../sqadmin/lib/di/DiModule"
import PropertiesCoreDataDiModule from "./PropertiesCoreDataDiModule"
import GetPropertyUseCase from "../domain/use-cases/GetPropertyUseCase"
import CreatePropertyUseCase from "../domain/use-cases/CreatePropertyUseCase"
import UpdatePropertyUseCase from "../domain/use-cases/UpdatePropertyUseCase"
import DestroyPropertyUseCase from "../domain/use-cases/DestroyPropertyUseCase"

export default interface PropertiesCoreDomainDiModule {
  provideGetPropertiesUseCase(): GetPropertiesUseCase

  provideGetPropertyUseCase(): GetPropertyUseCase

  provideCreatePropertyUseCase(): CreatePropertyUseCase

  provideUpdatePropertyUseCase(): UpdatePropertyUseCase

  provideDestroyPropertyUseCase(): DestroyPropertyUseCase
}

export class DefaultPropertiesCoreDomainDiModule
  extends DiModule
  implements PropertiesCoreDomainDiModule {

  private readonly propertiesCoreDataDiModule: PropertiesCoreDataDiModule

  constructor(parameters: {
    readonly propertiesCoreDataDiModule: PropertiesCoreDataDiModule
  }) {
    super()
    this.propertiesCoreDataDiModule = parameters.propertiesCoreDataDiModule
  }

  provideGetPropertiesUseCase(): GetPropertiesUseCase {
    return this.single(
      GetPropertiesUseCase.name,
      () => new GetPropertiesUseCase({
        propertiesRepository: this.propertiesCoreDataDiModule.providePropertiesRepository()
      })
    )
  }

  provideGetPropertyUseCase(): GetPropertyUseCase {
    return this.single(
      GetPropertyUseCase.name,
      () => new GetPropertyUseCase({
        propertiesRepository: this.propertiesCoreDataDiModule.providePropertiesRepository()
      })
    )
  }

  provideCreatePropertyUseCase(): CreatePropertyUseCase {
    return this.single(
      CreatePropertyUseCase.name,
      () => new CreatePropertyUseCase({
        propertiesRepository: this.propertiesCoreDataDiModule.providePropertiesRepository()
      })
    )
  }

  provideUpdatePropertyUseCase(): UpdatePropertyUseCase {
    return this.single(
      UpdatePropertyUseCase.name,
      () => new UpdatePropertyUseCase({
        propertiesRepository: this.propertiesCoreDataDiModule.providePropertiesRepository()
      })
    )
  }

  provideDestroyPropertyUseCase(): DestroyPropertyUseCase {
    return this.single(
      DestroyPropertyUseCase.name,
      () => new DestroyPropertyUseCase({
        propertiesRepository: this.propertiesCoreDataDiModule.providePropertiesRepository()
      })
    )
  }
}
