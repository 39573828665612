import DashboardsFilter from "../../domain/entities/dashboards/DashboardsFilter"
import NetworkDashboardsRequestFilter from "../entities/dashboards/NetworkDashboardsRequestFilter"

export default class DashboardsFiltersMapper {
  mapDomainToNetwork({
    filter
  }: {
    readonly filter: DashboardsFilter
  }): NetworkDashboardsRequestFilter {
    return new NetworkDashboardsRequestFilter({
      query: filter.query
    })
  }
}
