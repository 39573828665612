import DiModule from "../../../../../sqadmin/lib/di/DiModule"
import CoreDataDiModule from "../../../../core/di/modules/CoreDataDiModule"
import OrderProcessingStatusesRepository from "../../domain/repositories/OrderProcessingStatusesRepository"
import DefaultOrderProcessingStatusesRepository from "../../data/DefaultOrderProcessingStatusesRepository"

export default interface OrderProcessingStatusesDataDiModule {
  provideOrderProcessingStatusesRepository(): OrderProcessingStatusesRepository
}

export class DefaultOrderProcessingStatusesDataDiModule
  extends DiModule
  implements OrderProcessingStatusesDataDiModule {

  private readonly coreDataDiModule: CoreDataDiModule

  constructor(parameters: {
    readonly coreDataDiModule: CoreDataDiModule
  }) {
    super()
    this.coreDataDiModule = parameters.coreDataDiModule
  }

  provideOrderProcessingStatusesRepository(): OrderProcessingStatusesRepository {
    return this.single(
      DefaultOrderProcessingStatusesRepository.name,
      () => new DefaultOrderProcessingStatusesRepository({
        orderProcessingStatusesNetworkSource: this.coreDataDiModule.provideOrderProcessingStatusesNetworkSource()
      })
    )
  }
}
