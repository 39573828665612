import React from "react"
import styles from "./CenterModalWindowComponent.module.scss"
import ModalWindowComponent, { ModalWindowProps } from "../../../../../../lib/modal-window/ModalWindowComponent"

export default function CenterModalWindowComponent(props: ModalWindowProps) {
  return (
    <ModalWindowComponent
      {...props}
    >
      <div className={styles.modalWindow}>
        <div
          className={styles.body}
        >
          <div
            className={styles.background}
            onClick={props.onCloseRequested}
          />
          <div className={styles.closeButtonContainer}>
            <div
              className={styles.closeButton}
              onClick={props.onCloseRequested}
            >
              <div className={styles.closeIcon} />
            </div>
          </div>

          <div
            onClick={() => {
              /*чтобы контент не пропускал клики в фон*/
            }}
            className={styles.content}
          >
            {props.children}
          </div>
        </div>
      </div>
    </ModalWindowComponent>
  )
}
