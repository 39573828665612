import BackendHttpClient, { BackendHttpClientResult } from "../../../../core/data/network/BackendHttpClient"
import NetworkLastItemPagination from "../../../../core/data/entities/pagination/NetworkLastItemPagination"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import { instanceToPlain, plainToInstance } from "class-transformer"
import NetworkExecutionError from "../../../../core/data/entities/errors/NetworkExecutionError"
import GetObjectNetworkResult from "../../../../core/data/results/GetObjectNetworkResult"
import CreateObjectNetworkResult from "../../../../core/data/results/CreateObjectNetworkResult"
import UpdateObjectNetworkResult from "../../../../core/data/results/UpdateObjectNetworkResult"
import DestroyObjectNetworkResult from "../../../../core/data/results/DestroyObjectNetworkResult"
import SuccessExecutionResult from "../../../../../sqadmin/core/domain/results/SuccessExecutionResult"
import ErrorExecutionResult from "../../../../../sqadmin/core/domain/results/ErrorExecutionResult"
import FailureExecutionResult from "../../../../../sqadmin/core/domain/results/FailureExecutionResult"
import NetworkOrderReceivingMethod
  from "../../../../core/data/entities/order-receiving-methods/NetworkOrderReceivingMethod"
import NetworkOrderReceivingMethodsRequestFilter from "../entities/NetworkOrderReceivingMethodsRequestFilter"
import NetworkOrderReceivingMethodsRequestQuery from "../entities/NetworkOrderReceivingMethodsRequestQuery"
import NetworkOrderReceivingMethodsResponseBody from "../entities/NetworkOrderReceivingMethodsResponseBody"
import NetworkOrderReceivingMethodResponseBody from "../entities/NetworkOrderReceivingMethodResponseBody"
import NetworkOrderReceivingMethodRequestBody from "../entities/NetworkOrderReceivingMethodRequestBody"
import NetworkOrderReceivingMethodError
  from "../../../../core/data/entities/order-receiving-methods/NetworkOrderReceivingMethodError"
import NetworkOrderShippingTariffsResponseBody from "../entities/NetworkOrderShippingTariffsResponseBody"
import NetworkOrderShippingTariffsRequestFilter from "../entities/NetworkOrderShippingTariffsRequestFilter"
import NetworkOrderShippingTariffsRequestQuery from "../entities/NetworkOrderShippingTariffsRequestQuery"

const basePath = "/admin/order_receiving_methods"

export default class OrderReceivingMethodsNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async getOrderReceivingMethods({
    filter,
    pagination,
    sort
  }: {
    readonly filter?: NetworkOrderReceivingMethodsRequestFilter | null
    readonly pagination?: NetworkLastItemPagination | null
    readonly sort?: string | null
  }): Promise<GetOrderReceivingMethodsNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: basePath,
      parameters: instanceToPlain(new NetworkOrderReceivingMethodsRequestQuery({
        filter,
        pagination,
        sort
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkOrderReceivingMethodsResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async getOrderReceivingMethod({
    orderReceivingMethodId
  }: {
    readonly orderReceivingMethodId: number
  }): Promise<GetObjectNetworkResult<NetworkOrderReceivingMethod>> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}/${orderReceivingMethodId}`
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkOrderReceivingMethodResponseBody, result.body).orderReceivingMethod!
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async createOrderReceivingMethod({
    orderReceivingMethod
  }: {
    readonly orderReceivingMethod: NetworkOrderReceivingMethod
  }): Promise<CreateObjectNetworkResult<NetworkOrderReceivingMethod, NetworkOrderReceivingMethodError>> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.POST,
      path: basePath,
      body: instanceToPlain(new NetworkOrderReceivingMethodRequestBody({
        orderReceivingMethod: orderReceivingMethod
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkOrderReceivingMethodResponseBody, result.body).orderReceivingMethod!
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkOrderReceivingMethodError, result.body)
        }
      case "failure":
        return result
    }
  }

  async updateOrderReceivingMethod({
    orderReceivingMethodId,
    orderReceivingMethod
  }: {
    readonly orderReceivingMethodId: number
    readonly orderReceivingMethod: NetworkOrderReceivingMethod
  }): Promise<UpdateObjectNetworkResult<NetworkOrderReceivingMethod, NetworkOrderReceivingMethodError>> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.PUT,
      path: `${basePath}/${orderReceivingMethodId}`,
      body: instanceToPlain(new NetworkOrderReceivingMethodRequestBody({
        orderReceivingMethod: orderReceivingMethod
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkOrderReceivingMethodResponseBody, result.body).orderReceivingMethod!
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkOrderReceivingMethodError, result.body)
        }
      case "failure":
        return result
    }
  }

  async destroyOrderReceivingMethod({
    orderReceivingMethodId
  }: {
    readonly orderReceivingMethodId: number
  }): Promise<DestroyObjectNetworkResult<NetworkOrderReceivingMethodError>> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.DELETE,
      path: `${basePath}/${orderReceivingMethodId}`
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: undefined
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkOrderReceivingMethodError, result.body)
        }
      case "failure":
        return result
    }
  }

  async getOrderShippingTariffs({
    filter,
    pagination,
    sort
  }: {
    readonly filter?: NetworkOrderShippingTariffsRequestFilter | null
    readonly pagination?: NetworkLastItemPagination | null
    readonly sort?: string | null
  }): Promise<GetOrderShippingTariffsNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: "/admin/order_shipping_tariffs",
      parameters: instanceToPlain(new NetworkOrderShippingTariffsRequestQuery({
        filter,
        pagination,
        sort
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkOrderShippingTariffsResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }
}

export type GetOrderReceivingMethodsNetworkResult =
  SuccessExecutionResult<NetworkOrderReceivingMethodsResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult

export type GetOrderShippingTariffsNetworkResult =
  SuccessExecutionResult<NetworkOrderShippingTariffsResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult
