import FormField, { FormFieldParameters, FormFieldViewState, FormFieldViewStateParameters } from "../FormField"

export default class TextFormField<DomainObject, ErrorsObject> extends FormField<DomainObject, ErrorsObject> {
  private readonly getValue: (object: DomainObject) => string | null | undefined
  private readonly setValue: (object: DomainObject, value: string) => DomainObject

  constructor(parameters: FormFieldParameters<DomainObject, ErrorsObject> & {
    readonly getValue: (object: DomainObject) => string | null | undefined
    readonly setValue: (object: DomainObject, value: string) => DomainObject
  }) {
    super(parameters)
    this.getValue = parameters.getValue
    this.setValue = parameters.setValue
  }

  getViewState(object: DomainObject, errorsObject?: ErrorsObject): FormFieldViewState {
    return new TextFormFieldViewState({
      ...this.getFormFieldViewStateParameters(object, errorsObject),
      value: this.getValue(object),
      onChange: (value: string) => {
        this.setObject(this.setValue(object, value))
        this.setAndShowLoadedObjectViewState()
      }
    })
  }
}

export class TextFormFieldViewState extends FormFieldViewState {
  readonly value: string | null | undefined
  onChange: (value: string) => void

  constructor(parameters: FormFieldViewStateParameters & {
    readonly onChange: (value: string) => void
    readonly value: string | null | undefined
  }) {
    super(parameters)
    this.value = parameters.value
    this.onChange = parameters.onChange
  }
}
