import React from "react"
import NumberTableValue from "../../../entities/tables/table-value-by-type/NumberTableValue"

export default function NumberTableValueComponent({
  numberTableValue
}: {
  readonly numberTableValue: NumberTableValue
}) {
  return <span>{numberTableValue.getValue()}</span>
}
