import ViewModel from "../../../../../../sqadmin/lib/view-model/ViewModel"
import CoreI18n from "../../../../../core/i18n/CoreI18n"
import CoreUrlProvider from "../../../../../core/presentation/services/CoreUrlProvider"
import BroadcastObjectsEventUseCase
  from "../../../../../../sqadmin/features/objects/domain/use-cases/objects/BroadcastObjectsEventUseCase"
import ObjectPresentationLogic
  from "../../../../../../sqadmin/features/objects/presentation/presentation-logics/ObjectPresentationLogic"
import { ObjectViewState } from "../../../../../../sqadmin/features/objects/presentation/view-states/ObjectViewState"
import autoBind from "auto-bind"
import ObjectViewEvent from "../../../../../../sqadmin/features/objects/presentation/view-events/ObjectViewEvent"
import isBlank from "../../../../../../sqadmin/lib/isBlank"
import StringFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/StringFormField"
import OrderReceivingMethod from "../../../../../core/domain/entities/order-receiving-methods/OrderReceivingMethod"
import { StateObservable } from "../../../../../../sqadmin/lib/view-model/StateObservable"
import GetOrderReceivingMethodUseCase
  from "../../../../order-receiving-methods-core/domain/use-cases/GetOrderReceivingMethodUseCase"
import CreateOrderReceivingMethodUseCase
  from "../../../../order-receiving-methods-core/domain/use-cases/CreateOrderReceivingMethodUseCase"
import UpdateOrderReceivingMethodUseCase
  from "../../../../order-receiving-methods-core/domain/use-cases/UpdateOrderReceivingMethodUseCase"
import DestroyOrderReceivingMethodUseCase
  from "../../../../order-receiving-methods-core/domain/use-cases/DestroyOrderReceivingMethodUseCase"
import OrderReceivingMethodError
  from "../../../../../core/domain/entities/order-receiving-methods/OrderReceivingMethodError"
import OrderReceivingMethodErrorsObject
  from "../../../../../core/domain/entities/order-receiving-methods/OrderReceivingMethodErrorsObject"
import SingleSelectFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/SingleSelectFormField"
import OrderReceivingMethodType
  from "../../../../../core/domain/entities/order-receiving-methods/OrderReceivingMethodType"
import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import assertNever from "../../../../../../sqadmin/lib/assertNever"
import NumberFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/NumberFormField"
import BooleanFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/BooleanFormField"
import FulfillmentProviderType
  from "../../../../../core/domain/entities/order-receiving-methods/FulfillmentProviderType"
import ListFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/ListFormField"
import OrderReceivingPriceRule
  from "../../../../../core/domain/entities/order-receiving-price-rules/OrderReceivingPriceRule"
import { v4 as uuidv4 } from "uuid"
import OrderReceivingPriceRuleErrorsObject
  from "../../../../../core/domain/entities/order-receiving-price-rules/OrderReceivingPriceRuleErrorsObject"
import { OrderReceivingPriceRulesFields } from "./OrderReceivingPriceRulesFields"
import GetSettlementsUseCase from "../../../../settlements-core/domain/use-cases/GetSettlementsUseCase"
import Settlement from "../../../../../core/domain/entities/settlements/Settlement"
import OrderShippingMethod from "../../../../../core/domain/entities/order-shipping-methods/OrderShippingMethod"
import OrderShippingMethodType from "../../../../../core/domain/entities/order-shipping-methods/OrderShippingMethodType"
import OrderReceivingPriceRuleCalculationType
  from "../../../../../core/domain/entities/order-receiving-price-rules/OrderReceivingPriceRuleCalculationType"
import OrderShippingTariff from "../../../../../core/domain/entities/order-shipping-method-tariffs/OrderShippingTariff"
import GetOrderShippingTariffsUseCase
  from "../../../../order-receiving-methods-core/domain/use-cases/GetOrderShippingTariffsUseCase"
import isPresent from "../../../../../../sqadmin/lib/isPresent"

export default class OrderReceivingMethodViewModel extends ViewModel {
  private readonly coreI18n: CoreI18n
  private readonly coreUrlProvider: CoreUrlProvider
  private readonly broadcastObjectsEventUseCase: BroadcastObjectsEventUseCase
  private readonly getOrderReceivingMethodUseCase: GetOrderReceivingMethodUseCase
  private readonly createOrderReceivingMethodUseCase: CreateOrderReceivingMethodUseCase
  private readonly updateOrderReceivingMethodUseCase: UpdateOrderReceivingMethodUseCase
  private readonly destroyOrderReceivingMethodUseCase: DestroyOrderReceivingMethodUseCase
  private readonly getSettlementsUseCase: GetSettlementsUseCase
  private readonly getOrderShippingTariffsUseCase: GetOrderShippingTariffsUseCase
  private readonly orderReceivingMethodId?: number
  private orderReceivingMethod: OrderReceivingMethod | undefined | null

  private readonly objectPresentationLogic: ObjectPresentationLogic<
    OrderReceivingMethod,
    OrderReceivingMethodError,
    OrderReceivingMethodErrorsObject
  >

  readonly observableObjectViewState: StateObservable<ObjectViewState>

  constructor(parameters: {
    readonly coreI18n: CoreI18n
    readonly broadcastObjectsEventUseCase: BroadcastObjectsEventUseCase
    readonly getOrderReceivingMethodUseCase: GetOrderReceivingMethodUseCase
    readonly createOrderReceivingMethodUseCase: CreateOrderReceivingMethodUseCase
    readonly updateOrderReceivingMethodUseCase: UpdateOrderReceivingMethodUseCase
    readonly destroyOrderReceivingMethodUseCase: DestroyOrderReceivingMethodUseCase
    readonly getSettlementsUseCase: GetSettlementsUseCase
    readonly getOrderShippingTariffsUseCase: GetOrderShippingTariffsUseCase
    readonly orderReceivingMethodId?: number
  }) {
    super()
    this.coreI18n = parameters.coreI18n
    this.broadcastObjectsEventUseCase = parameters.broadcastObjectsEventUseCase
    this.getOrderReceivingMethodUseCase = parameters.getOrderReceivingMethodUseCase
    this.createOrderReceivingMethodUseCase = parameters.createOrderReceivingMethodUseCase
    this.updateOrderReceivingMethodUseCase = parameters.updateOrderReceivingMethodUseCase
    this.destroyOrderReceivingMethodUseCase = parameters.destroyOrderReceivingMethodUseCase
    this.getSettlementsUseCase = parameters.getSettlementsUseCase
    this.getOrderShippingTariffsUseCase = parameters.getOrderShippingTariffsUseCase
    this.orderReceivingMethodId = parameters.orderReceivingMethodId
    this.objectPresentationLogic = this.createObjectPresentationLogic()
    this.observableObjectViewState = this.objectPresentationLogic.observableObjectViewState
    this.coreUrlProvider = new CoreUrlProvider()
    this.orderReceivingMethod = undefined
    autoBind(this)
  }

  onViewObjectEvent(objectViewEvent: ObjectViewEvent) {
    this.objectPresentationLogic.onObjectViewEvent(objectViewEvent)
  }

  private createObjectPresentationLogic(): ObjectPresentationLogic<
    OrderReceivingMethod,
    OrderReceivingMethodError,
    OrderReceivingMethodErrorsObject
  > {
    return new ObjectPresentationLogic({
      // TODO: how to not pass?
      broadcastObjectsEventUseCase: this.broadcastObjectsEventUseCase,
      isNewObject: isBlank(this.orderReceivingMethodId),
      getObjectUrl: (orderReceivingMethod) => {
        return this.coreUrlProvider.buildOrderReceivingMethodUrl({
          id: orderReceivingMethod.id!
        })
      },
      buildObject: async() => ({
        id: undefined,
        type: undefined,
        name: undefined,
        position: undefined,
        isDefault: false,
        fulfillmentProviderType: undefined,
        orderReceivingPriceRules: undefined,
        orderShippingMethod: undefined,
        isAvailable: true,
        forOfflineSale: undefined
      }),
      loadObject: async() => {
        return await this.getOrderReceivingMethodUseCase.call({ orderReceivingMethodId: this.orderReceivingMethodId! })
      },
      createObject: async({ object: orderReceivingMethod }) => {
        return await this.createOrderReceivingMethodUseCase.call({ orderReceivingMethod })
      },
      updateObject: async({ object: orderReceivingMethod }) => {
        return await this.updateOrderReceivingMethodUseCase.call({
          orderReceivingMethodId: this.orderReceivingMethodId!,
          orderReceivingMethod
        })
      },
      destroyObject: async() => {
        return await this.destroyOrderReceivingMethodUseCase.call({
          orderReceivingMethodId: this.orderReceivingMethodId!
        })
      },
      onObjectChanged: (orderReceivingMethod: OrderReceivingMethod) => {
        this.orderReceivingMethod = orderReceivingMethod
      },
      getErrorsObject: ({ error: orderReceivingMethodError }) => orderReceivingMethodError
        ?.errorsObject,
      formFields: [
        this.createOrderReceivingMethodNameFormField(),
        this.createOrderReceivingMethodTypeFormField(),
        this.createFulfillmentProviderTypeFormField(),
        this.createOrderReceivingMethodPositionFormField(),
        this.createOrderReceivingMethodAvailableFormField(),
        this.createOrderReceivingMethodDefaultFormField(),
        this.createOrderReceivingMethodForOfflineSaleFormField(),
        this.createOrderReceivingMethodPriceRulesFormField(),
        this.createOrderShippingMethodTypeFormField(),
        this.createOrderShippingMethodSettlementFormField(),
        this.createOrderShippingTariffFormField()
      ]
    })
  }

  private createOrderReceivingMethodNameFormField() {
    return new StringFormField<OrderReceivingMethod, OrderReceivingMethodErrorsObject>({
      getTitle: () => this.coreI18n.getTextProvider().name(),
      getValue: (orderReceivingMethod: OrderReceivingMethod) => orderReceivingMethod.name,
      setValue: (orderReceivingMethod: OrderReceivingMethod, name: string) => ({ ...orderReceivingMethod, name }),
      getErrors: (orderReceivingMethodErrorsObject?: OrderReceivingMethodErrorsObject) => {
        return orderReceivingMethodErrorsObject
          ?.attributes
          ?.name
      }
    })
  }

  private createOrderReceivingMethodTypeFormField() {
    return new SingleSelectFormField<OrderReceivingMethod, OrderReceivingMethodErrorsObject, OrderReceivingMethodType>({
      isSearchBarVisible: false,
      getObjects: async() => {
        return {
          type: "success",
          data: {
            objects: Object.values(OrderReceivingMethodType),
            page: { hasMore: false }
          }
        }
      },
      getTitle: () => this.coreI18n.getTextProvider().type(),
      getValue: (orderReceivingMethod: OrderReceivingMethod) => orderReceivingMethod.type,
      setValue: (
        orderReceivingMethod: OrderReceivingMethod,
        orderReceivingMethodType: OrderReceivingMethodType | null | undefined
      ): OrderReceivingMethod => {
        return {
          ...orderReceivingMethod,
          type: orderReceivingMethodType
        }
      },
      getErrors: (
        orderReceivingMethodErrorsObject?: OrderReceivingMethodErrorsObject
      ) => orderReceivingMethodErrorsObject
        ?.attributes
        ?.type,
      getOptionId: (type: OrderReceivingMethodType) => type.valueOf(),
      getOptionText: (type: OrderReceivingMethodType) => this.detectOrderReceivingMethodTypeDisplayName(type)
    })
  }

  private detectOrderReceivingMethodTypeDisplayName(type: OrderReceivingMethodType) {
    const coreTextProvider: CoreTextProvider = this.coreI18n.getTextProvider()

    switch (type) {
      case OrderReceivingMethodType.PICK_UP:
        return coreTextProvider.pickUp()
      case OrderReceivingMethodType.DELIVERY:
        return coreTextProvider.delivery()
      default:
        assertNever(type)
    }
  }

  private createFulfillmentProviderTypeFormField() {
    return new SingleSelectFormField<OrderReceivingMethod, OrderReceivingMethodErrorsObject, FulfillmentProviderType>({
      isSearchBarVisible: false,
      getObjects: async() => {
        return {
          type: "success",
          data: {
            objects: Object.values(FulfillmentProviderType),
            page: { hasMore: false }
          }
        }
      },
      getTitle: () => this.coreI18n.getTextProvider().fulfillmentProviderType(),
      getValue: (orderReceivingMethod: OrderReceivingMethod) => orderReceivingMethod.fulfillmentProviderType,
      setValue: (
        orderReceivingMethod: OrderReceivingMethod,
        fulfillmentProviderType: FulfillmentProviderType | null | undefined
      ): OrderReceivingMethod => {
        return {
          ...orderReceivingMethod,
          fulfillmentProviderType: fulfillmentProviderType,
          orderShippingMethod: undefined,
          orderReceivingPriceRules: undefined
        }
      },
      getErrors: (
        orderReceivingMethodErrorsObject?: OrderReceivingMethodErrorsObject
      ) => orderReceivingMethodErrorsObject
        ?.attributes
        ?.fulfillmentProviderType,
      getOptionId: (type: FulfillmentProviderType) => type.valueOf(),
      getOptionText: (type: FulfillmentProviderType) => this.detectFulfillmentProviderTypeDisplayName(type)
    })
  }

  private detectFulfillmentProviderTypeDisplayName(type: FulfillmentProviderType) {
    const coreTextProvider: CoreTextProvider = this.coreI18n.getTextProvider()

    switch (type) {
      case FulfillmentProviderType.INTERNAL:
        return coreTextProvider.fulfillmentProviderTypeInternal()
      case FulfillmentProviderType.CDEK:
        return coreTextProvider.fulfillmentProviderTypeCdek()
      default:
        assertNever(type)
    }
  }

  private createOrderReceivingMethodPositionFormField() {
    return new NumberFormField<OrderReceivingMethod, OrderReceivingMethodErrorsObject>({
      getTitle: () => this.coreI18n.getTextProvider().position(),
      getValue: (orderReceivingMethod: OrderReceivingMethod) => orderReceivingMethod.position,
      setValue: (orderReceivingMethod: OrderReceivingMethod, position: number | undefined | null) => ({
        ...orderReceivingMethod,
        position
      }),
      getErrors: (orderReceivingMethodErrorsObject?: OrderReceivingMethodErrorsObject) => {
        return orderReceivingMethodErrorsObject
          ?.attributes
          ?.position
      }
    })
  }

  private createOrderReceivingMethodAvailableFormField() {
    return new BooleanFormField<OrderReceivingMethod, OrderReceivingMethodErrorsObject>({
      getValue: (orderReceivingMethod: OrderReceivingMethod) => orderReceivingMethod.isAvailable,
      setValue: (orderReceivingMethod: OrderReceivingMethod, isAvailable: boolean) => ({
        ...orderReceivingMethod,
        isAvailable
      }),
      getText: () => this.coreI18n.getTextProvider().isAvailableInCheckout(),
      getErrors: (
        orderReceivingMethodErrorsObject?: OrderReceivingMethodErrorsObject
      ) => orderReceivingMethodErrorsObject?.attributes?.isAvailable
    })
  }

  private createOrderReceivingMethodDefaultFormField() {
    return new BooleanFormField<OrderReceivingMethod, OrderReceivingMethodErrorsObject>({
      getValue: (orderReceivingMethod: OrderReceivingMethod) => orderReceivingMethod.isDefault,
      setValue: (orderReceivingMethod: OrderReceivingMethod, isDefault: boolean) => ({
        ...orderReceivingMethod,
        isDefault
      }),
      getText: () => this.coreI18n.getTextProvider().isDefaultInCheckout(),
      getErrors: (
        orderReceivingMethodErrorsObject?: OrderReceivingMethodErrorsObject
      ) => orderReceivingMethodErrorsObject?.attributes?.isDefault
    })
  }

  private createOrderReceivingMethodForOfflineSaleFormField() {
    return new BooleanFormField<OrderReceivingMethod, OrderReceivingMethodErrorsObject>({
      getValue: (orderReceivingMethod: OrderReceivingMethod) => orderReceivingMethod.forOfflineSale,
      setValue: (orderReceivingMethod: OrderReceivingMethod, forOfflineSale: boolean) => ({
        ...orderReceivingMethod,
        forOfflineSale
      }),
      getText: () => this.coreI18n.getTextProvider().useForOfflineSale(),
      getErrors: (
        orderReceivingMethodErrorsObject?: OrderReceivingMethodErrorsObject
      ) => orderReceivingMethodErrorsObject?.attributes?.forOfflineSale
    })
  }

  private createOrderReceivingMethodPriceRulesFormField() {
    return new ListFormField<OrderReceivingMethod, OrderReceivingMethodErrorsObject,
      OrderReceivingPriceRule, OrderReceivingPriceRuleErrorsObject>({
      isSwitchPositionVisible: true,
      getTitle: () => this.coreI18n.getTextProvider().orderReceivingPriceRules(),
      getValue: (orderReceivingMethod: OrderReceivingMethod) => orderReceivingMethod.orderReceivingPriceRules,
      getErrors: (
        orderReceivingMethodErrorsObject?: OrderReceivingMethodErrorsObject
      ) => orderReceivingMethodErrorsObject?.attributes?.orderReceivingPriceRules,
      setValue: (
        orderReceivingMethod: OrderReceivingMethod,
        orderReceivingPriceRules: OrderReceivingPriceRule[] | null
      ): OrderReceivingMethod => ({
        ...orderReceivingMethod,
        orderReceivingPriceRules
      }),
      getNestedObjectId: (orderReceivingPriceRule: OrderReceivingPriceRule) => orderReceivingPriceRule.clientId!,
      getNestedObjectTitle: (_, nestedObjectIndex) => this.coreI18n.getTextProvider()
        .orderReceivingPriceRule({ number: nestedObjectIndex + 1 }),
      getNestedErrorsObject: (
        orderReceivingPriceRule: OrderReceivingPriceRule,
        orderReceivingMethodErrorsObject?: OrderReceivingMethodErrorsObject
      ) => {
        return orderReceivingMethodErrorsObject?.orderReceivingPriceRules?.find(
          (orderReceivingPriceRuleErrorsObject: OrderReceivingPriceRuleErrorsObject) => {
            return orderReceivingPriceRuleErrorsObject.clientId === orderReceivingPriceRule.clientId
          })
      },
      getPosition: (orderReceivingPriceRule: OrderReceivingPriceRule) => orderReceivingPriceRule.position!,
      setPosition: (orderReceivingPriceRule: OrderReceivingPriceRule, position: number): OrderReceivingPriceRule => {
        return { ...orderReceivingPriceRule, position }
      },
      buildNewValue: () => ({
        id: undefined,
        clientId: uuidv4(),
        conditions: undefined,
        position: undefined,
        calculationType: undefined,
        calculator: undefined
      }),
      fields: this.createOrderReceivingPriceRulesFields()
    })
  }

  private createOrderReceivingPriceRulesFields() {
    return new OrderReceivingPriceRulesFields({ coreI18n: this.coreI18n })
      .createFields(() => this.orderReceivingMethod!)
  }

  private createOrderShippingMethodTypeFormField() {
    return new SingleSelectFormField<OrderReceivingMethod, OrderReceivingMethodErrorsObject, OrderShippingMethodType>({
      getVisible: (orderReceivingMethod: OrderReceivingMethod) => {
        return this.hasPriceRulesWithFulfillmentProvider(orderReceivingMethod)
      },
      isSearchBarVisible: false,
      getObjects: async() => {
        return {
          type: "success",
          data: {
            objects: Object.values(OrderShippingMethodType),
            page: { hasMore: false }
          }
        }
      },
      getTitle: () => this.coreI18n.getTextProvider().orderShippingMethodType(),
      getValue: (orderReceivingMethod: OrderReceivingMethod) => orderReceivingMethod.orderShippingMethod?.type,
      setValue: (
        orderReceivingMethod: OrderReceivingMethod,
        type: OrderShippingMethodType | null | undefined
      ): OrderReceivingMethod => {
        return {
          ...orderReceivingMethod,
          orderShippingMethod: {
            ...orderReceivingMethod.orderShippingMethod ?? this.createOrderShippingMethod(),
            type: type
          }
        }
      },
      getErrors: (
        orderReceivingMethodErrorsObject?: OrderReceivingMethodErrorsObject
      ) => orderReceivingMethodErrorsObject
        ?.orderShippingMethod
        ?.attributes
        ?.type,
      getOptionId: (type: OrderShippingMethodType) => type.valueOf(),
      getOptionText: (type: OrderShippingMethodType) => this.detectOrderShippingMethodTypeDisplayName(type)
    })
  }

  private createOrderShippingMethod(): OrderShippingMethod {
    return {
      type: undefined,
      id: undefined,
      settlementId: undefined,
      settlement: undefined,
      orderShippingTariff: undefined,
      orderShippingTariffId: undefined
    }
  }

  private detectOrderShippingMethodTypeDisplayName(type: OrderShippingMethodType) {
    const coreTextProvider: CoreTextProvider = this.coreI18n.getTextProvider()

    switch (type) {
      case OrderShippingMethodType.RECEIVING_POINT:
        return coreTextProvider.orderShippingMethodTypeReceivingPoint()
      case OrderShippingMethodType.DELIVERY:
        return coreTextProvider.orderShippingMethodTypeDelivery()
      default:
        assertNever(type)
    }
  }

  private hasPriceRulesWithFulfillmentProvider(orderReceivingMethod: OrderReceivingMethod) {
    const foundPriceRulesWithFulfillmentProvider = orderReceivingMethod.orderReceivingPriceRules
      ?.filter((priceRule: OrderReceivingPriceRule) => {
        return priceRule.calculationType === OrderReceivingPriceRuleCalculationType.FULFILLMENT_PROVIDER
      })

    return (foundPriceRulesWithFulfillmentProvider?.length ?? 0) > 0
  }

  private createOrderShippingMethodSettlementFormField() {
    return new SingleSelectFormField<OrderReceivingMethod, OrderReceivingMethodErrorsObject, Settlement>({
      getVisible: (orderReceivingMethod: OrderReceivingMethod) => {
        return this.hasPriceRulesWithFulfillmentProvider(orderReceivingMethod)
      },
      getObjects: async({
        query,
        lastObject
      }) => {
        return await this.getSettlementsUseCase.call({
          query: query,
          pagination: { id: lastObject?.id ?? undefined }
        })
      },
      getTitle: () => this.coreI18n.getTextProvider().orderShippingMethodSettlement(),
      getValue: (orderReceivingMethod: OrderReceivingMethod) => orderReceivingMethod?.orderShippingMethod?.settlement,
      setValue: (
        orderReceivingMethod: OrderReceivingMethod,
        settlement: Settlement | null | undefined
      ): OrderReceivingMethod => {
        return {
          ...orderReceivingMethod,
          orderShippingMethod: {
            ...orderReceivingMethod.orderShippingMethod ?? this.createOrderShippingMethod(),
            settlement,
            settlementId: settlement && settlement.id
          }
        }
      },
      getErrors: (
        orderReceivingMethodErrorsObject?: OrderReceivingMethodErrorsObject
      ) => orderReceivingMethodErrorsObject
        ?.orderShippingMethod
        ?.attributes
        ?.settlement,
      getOptionId: (settlement: Settlement) => settlement.id!.toString(),
      getOptionText: (settlement: Settlement) => settlement?.fullName
    })
  }

  private createOrderShippingTariffFormField() {
    return new SingleSelectFormField<OrderReceivingMethod, OrderReceivingMethodErrorsObject, OrderShippingTariff>({
      getVisible: (orderReceivingMethod: OrderReceivingMethod) => {
        const allTypesSelected = isPresent(orderReceivingMethod.fulfillmentProviderType) &&
          isPresent(orderReceivingMethod.type) && isPresent(orderReceivingMethod.orderShippingMethod?.type)

        return this.hasPriceRulesWithFulfillmentProvider(orderReceivingMethod) && allTypesSelected
      },
      isSearchBarVisible: false,
      getObjects: async({
        parentObject,
        lastObject
      }) => {
        return await this.getOrderShippingTariffsUseCase.call({
          filter: {
            fulfillmentProviderType: parentObject.fulfillmentProviderType,
            orderReceivingMethodType: parentObject.type,
            orderShippingMethodType: parentObject.orderShippingMethod?.type
          },
          sort: undefined,
          pagination: { id: lastObject?.id ?? undefined }
        })
      },
      getTitle: () => this.coreI18n.getTextProvider().orderShippingTariff(),
      getValue: (orderReceivingMethod: OrderReceivingMethod) => orderReceivingMethod
        ?.orderShippingMethod
        ?.orderShippingTariff,
      setValue: (
        orderReceivingMethod: OrderReceivingMethod,
        tariff: OrderShippingTariff | null | undefined
      ): OrderReceivingMethod => {
        return {
          ...orderReceivingMethod,
          orderShippingMethod: {
            ...orderReceivingMethod.orderShippingMethod ?? this.createOrderShippingMethod(),
            orderShippingTariff: tariff,
            orderShippingTariffId: tariff && tariff.id
          }
        }
      },
      getErrors: (
        orderReceivingMethodErrorsObject?: OrderReceivingMethodErrorsObject
      ) => orderReceivingMethodErrorsObject
        ?.orderShippingMethod
        ?.attributes
        ?.orderShippingTariff,
      getOptionId: (tariff: OrderShippingTariff) => tariff.id!.toString(),
      getOptionText: (tariff: OrderShippingTariff) => tariff?.name
    })
  }
}
