import { Expose, Type } from "class-transformer"
import NetworkProductsSetPropertyErrorsObjectAttributes from "./NetworkProductsSetPropertyErrorsObjectAttributes"
import ProductsSetPropertyErrorsObject
  from "../../../domain/entities/products-set-properties/ProductsSetPropertyErrorsObject"

export default class NetworkProductsSetPropertyErrorsObject implements ProductsSetPropertyErrorsObject {
  @Expose()
  readonly clientId: string | null | undefined

  @Expose()
  @Type(() => NetworkProductsSetPropertyErrorsObjectAttributes)
  readonly attributes: NetworkProductsSetPropertyErrorsObjectAttributes | null | undefined
}
