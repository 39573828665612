import Configuration from "../../../../../../core/domain/entities/configurations/Configuration"
import ConfigurationEntityName from "../../../../../../core/domain/entities/configurations/ConfigurationEntityName"
import ConfigurationEntityPropertyName
  from "../../../../../../core/domain/entities/configurations/ConfigurationEntityPropertyName"
import { ObjectEditor } from "./ObjectEditor"

export class ProductVariantOptionValueEditor {
  private readonly objectEditor: ObjectEditor

  constructor(parameters: { configuration: Configuration | null | undefined }) {
    this.objectEditor = new ObjectEditor()
    this.objectEditor.init(parameters.configuration, ConfigurationEntityName.PRODUCT_VARIANT_OPTION_VALUE)
  }

  isValueDisabled(id: number | null | undefined) {
    return this.objectEditor.isDisabled(id, ConfigurationEntityPropertyName.OPTION_VALUE_ID)
  }
}
