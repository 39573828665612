import OrderReceivingRecipient from "../../domain/entities/order-receiving-recipients/OrderReceivingRecipient"
import NetworkOrderReceivingRecipient from "../entities/order-receiving-recipients/NetworkOrderReceivingRecipient"

export default class OrderReceivingRecipientsMappers {
  mapNetworkToDomain({
    recipient
  }: {
    readonly recipient: NetworkOrderReceivingRecipient
  }): OrderReceivingRecipient {
    return {
      id: recipient.id,
      firstName: recipient.firstName,
      lastName: recipient.lastName,
      middleName: recipient.middleName
    }
  }

  mapDomainToNetwork({
    recipient
  }: {
    readonly recipient: OrderReceivingRecipient
  }): NetworkOrderReceivingRecipient {
    return new NetworkOrderReceivingRecipient({
      id: recipient.id,
      firstName: recipient.firstName,
      lastName: recipient.lastName,
      middleName: recipient.middleName
    })
  }
}
