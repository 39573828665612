import { Expose, Type } from "class-transformer"
import NetworkLastItemPagination from "../../../../core/data/entities/pagination/NetworkLastItemPagination"
import NetworkSettlementsRequestFilter from "./NetworkSettlementsRequestFilter"

export default class NetworkSettlementsRequestQuery {
  @Expose()
  @Type(() => NetworkSettlementsRequestFilter)
  readonly filter?: NetworkSettlementsRequestFilter | null

  @Expose()
  @Type(() => NetworkLastItemPagination)
  readonly pagination?: NetworkLastItemPagination | null

  @Expose()
  readonly sort?: string | null

  constructor(parameters: {
    readonly filter?: NetworkSettlementsRequestFilter | null
    readonly pagination?: NetworkLastItemPagination | null
    readonly sort?: string | null
  }) {
    this.filter = parameters.filter
    this.pagination = parameters.pagination
    this.sort = parameters.sort
  }
}
