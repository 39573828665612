import UsersFilter from "../../../../core/domain/entities/users/UsersFilter"
import NetworkUserRequestFilter from "../entities/NetworkUserRequestFilter"

export default class UserFiltersMapper {
  mapDomainToNetwork({
    filter
  }: {
    readonly filter: UsersFilter
  }): NetworkUserRequestFilter {
    return {
      query: filter.query,
      roleType: filter.roleType
    }
  }
}
