import { RouteObject } from "react-router-dom"
import CoreUrlProvider from "../../../core/presentation/services/CoreUrlProvider"
import AuthenticationDi from "../di/AuthenticationDi"

export default function createAuthenticationRoutes({
  di
}: {
  readonly di: AuthenticationDi
}): RouteObject[] {
  const urlProvider = new CoreUrlProvider()
  return [
    {
      path: urlProvider.buildAuthenticationPath(),
      element: di
        .sqAdminAuthenticationDiComponent
        .authenticationPresentationDiModule
        .provideAuthenticationPage()
    }
  ]
}
