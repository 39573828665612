import { Expose, Type } from "class-transformer"
import NetworkPage from "../../../../core/data/entities/pagination/NetworkPage"
import NetworkPaymentStatus from "../../../../core/data/entities/payment-statuses/NetworkPaymentStatus"

export default class NetworkPaymentStatusesResponseBody {
  @Expose()
  @Type(() => NetworkPaymentStatus)
  readonly paymentStatuses: NetworkPaymentStatus[] | null | undefined

  @Expose()
  @Type(() => NetworkPage)
  readonly page: NetworkPage | null | undefined
}
