import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../errors/NetworkAttributeError"
import VariantOptionErrorsObjectAttributes
  from "../../../domain/entities/variant-options/VariantOptionErrorsObjectAttributes"

export default class NetworkVariantOptionErrorsObjectAttributes implements VariantOptionErrorsObjectAttributes {
  @Expose()
  @Type(() => NetworkAttributeError)
  readonly optionId?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly optionValueId?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly option?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly optionValue?: NetworkAttributeError[] | null
}
