import { NetworkCreateSessionError } from "../../sources/sessions/SessionsNetworkSource"
import { CreateSessionError } from "../../../domain/use-cases/sessions/CreateSessionUseCase"
import CreateSessionErrorsObjectsMapper from "./CreateSessionErrorsObjectsMapper"

export default class CreateSessionErrorsMapper {
  mapNetworkToDomain({ object }: { readonly object: NetworkCreateSessionError }): CreateSessionError {
    return {
      message: object.message,
      errorsObject: object.errorsObject && new CreateSessionErrorsObjectsMapper().mapNetworkToDomain({
        object: object.errorsObject
      })
    }
  }
}
