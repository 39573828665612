import { DestroyObjectResult } from "../../../../../sqadmin/features/objects/domain/results/DestroyObjectResult"
import OrderProcessingStatusError from "../../../../core/domain/entities/order-processing-statuses/OrderProcessingStatusError"
import OrderProcessingStatusesRepository from "../repositories/OrderProcessingStatusesRepository"

export default class DestroyOrderProcessingStatusUseCase {
  private readonly orderProcessingStatusesRepository: OrderProcessingStatusesRepository

  constructor(parameters: {
    readonly orderProcessingStatusesRepository: OrderProcessingStatusesRepository
  }) {
    this.orderProcessingStatusesRepository = parameters.orderProcessingStatusesRepository
  }

  async call(parameters: {
    readonly orderProcessingStatusId: number
  }): Promise<DestroyObjectResult<OrderProcessingStatusError>> {
    return await this.orderProcessingStatusesRepository.destroyOrderProcessingStatus(parameters)
  }
}
