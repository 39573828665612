import BackendHttpClient, { BackendHttpClientResult } from "../../../../core/data/network/BackendHttpClient"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import { instanceToPlain, plainToInstance } from "class-transformer"
import NetworkExecutionError from "../../../../core/data/entities/errors/NetworkExecutionError"
import SuccessExecutionResult from "../../../../../sqadmin/core/domain/results/SuccessExecutionResult"
import ErrorExecutionResult from "../../../../../sqadmin/core/domain/results/ErrorExecutionResult"
import FailureExecutionResult from "../../../../../sqadmin/core/domain/results/FailureExecutionResult"
import NetworkLastItemPagination from "../../../../core/data/entities/pagination/NetworkLastItemPagination"
import NetworkSettlementsRequestQuery from "../entities/NetworkSettlementsRequestQuery"
import NetworkSettlementsRequestFilter from "../entities/NetworkSettlementsRequestFilter"
import NetworkSettlementsResponseBody from "../entities/NetworkSettlementsResponseBody"

const basePath = "/admin/settlements"

export default class SettlementsNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async getSettlements({
    filter,
    sort,
    pagination
  }: {
    readonly filter?: NetworkSettlementsRequestFilter | null
    readonly pagination?: NetworkLastItemPagination | null
    readonly sort?: string | null
  }): Promise<GetSettlementsNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: basePath,
      parameters: instanceToPlain(new NetworkSettlementsRequestQuery({
        filter,
        sort,
        pagination
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkSettlementsResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }
}

export type GetSettlementsNetworkResult =
  SuccessExecutionResult<NetworkSettlementsResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult
