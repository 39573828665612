import { RouteObject } from "react-router-dom"
import CoreUrlProvider from "../../../core/presentation/services/CoreUrlProvider"
import { objectIdUrlPart } from "../../../../sqadmin/features/objects/presentation/hooks/useObjectId"
import OrderPaymentMethodsDi from "../di/OrderPaymentMethodsDi"

export default function createOrderPaymentMethodsRoutes({
  di
}: {
  readonly di: OrderPaymentMethodsDi
}): RouteObject[] {
  const urlProvider = new CoreUrlProvider()
  return [
    {
      path: urlProvider.buildOrderPaymentMethodsPath(),
      element: di
        .sqCoreOrderPaymentMethodsDiComponent
        .orderPaymentMethodsPresentationDiModule
        .provideOrderPaymentMethodsPage()
    },
    {
      path: urlProvider.buildNewOrderPaymentMethodPath(),
      element: di
        .sqCoreOrderPaymentMethodsDiComponent
        .orderPaymentMethodsPresentationDiModule
        .provideOrderPaymentMethodPage()
    },
    {
      path: urlProvider.buildOrderPaymentMethodPath({ id: `:${objectIdUrlPart}` }),
      element: di
        .sqCoreOrderPaymentMethodsDiComponent
        .orderPaymentMethodsPresentationDiModule
        .provideOrderPaymentMethodPage()
    }
  ]
}
