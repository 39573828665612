import { Expose, Transform, Type } from "class-transformer"
import { Decimal } from "decimal.js"
import transformDecimal from "../../network/transformers/transformDecimal"
import NetworkBatch from "../batches/NetworkBatch"

export default class NetworkOrderItem {
  @Expose()
  readonly id: number | null | undefined

  @Expose()
  @Transform(transformDecimal)
  readonly count: Decimal | null | undefined

  @Expose()
  @Transform(transformDecimal)
  readonly price: Decimal | null | undefined

  @Expose()
  @Type(() => NetworkBatch)
  readonly batch: NetworkBatch | null | undefined

  @Expose()
  readonly clientId: string | null | undefined

  constructor(parameters?: {
    readonly id: number | null | undefined
    readonly count: Decimal | null | undefined
    readonly price: Decimal | null | undefined
    readonly batch: NetworkBatch | null | undefined
    readonly clientId: string | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.id = parameters.id
    this.count = parameters.count
    this.price = parameters.price
    this.batch = parameters.batch
    this.clientId = parameters.clientId
  }
}
