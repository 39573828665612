enum BlockType {
  BANNERS = "banners",
  SEARCH = "search",
  PRODUCT_CATEGORIES = "productCategories",
  PRODUCTS = "products",
  LOYALTY_PROGRAM = "loyaltyProgram",
  HEADER = "header",
  CURRENT_ORDERS = "currentOrders",
  MENU = "menu"
}

export default BlockType
