import { Expose, Type } from "class-transformer"
import NetworkLastItemPagination from "../../../../core/data/entities/pagination/NetworkLastItemPagination"
import NetworkOrderShippingTariffsRequestFilter from "./NetworkOrderShippingTariffsRequestFilter"

export default class NetworkOrderShippingTariffsRequestQuery {
  @Expose()
  @Type(() => NetworkOrderShippingTariffsRequestFilter)
  readonly filter: NetworkOrderShippingTariffsRequestFilter | null | undefined

  @Expose()
  @Type(() => NetworkLastItemPagination)
  readonly pagination: NetworkLastItemPagination | null | undefined

  @Expose()
  readonly sort: string | null | undefined

  constructor(parameters: {
    readonly filter: NetworkOrderShippingTariffsRequestFilter | null | undefined
    readonly pagination: NetworkLastItemPagination | null | undefined
    readonly sort: string | null | undefined
  }) {
    this.filter = parameters.filter
    this.pagination = parameters.pagination
    this.sort = parameters.sort
  }
}
