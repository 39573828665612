import React from "react"
import DiModule from "../../../../../sqadmin/lib/di/DiModule"
import ObjectsDomainDiModule from "../../../../../sqadmin/features/objects/di/modules/ObjectsDomainDiModule"
import autoBind from "auto-bind"
import ObjectsPresentationLogicParameters
  from "../../../../../sqadmin/features/objects/presentation/entities/objects/ObjectsPresentationLogicParameters"
import DashboardsPage from "../../presentation/pages/dashboards-page/DashboardsPage"
import DashboardsViewModel from "../../presentation/pages/dashboards-page/DashboardsViewModel"
import DashboardsDomainDiModule from "./DashboardsDomainDiModule"
import DashboardPage from "../../presentation/pages/dashboard-page/DashboardPage"
import DashboardViewModel from "../../presentation/pages/dashboard-page/DashboardViewModel"
import CoreI18nDiModule from "../../../../core/di/modules/CoreI18nDiModule"
import PropertyValuesCoreDomainDiModule from "../../../property-values-core/di/PropertyValuesCoreDomainDiModule"
import PropertiesCoreDomainDiModule from "../../../properties-core/di/PropertiesCoreDomainDiModule"
import ProductCategoriesCoreDomainDiModule
  from "../../../product-categories-core/di/ProductCategoriesCoreDomainDiModule"
import ProductsCoreDomainDiModule from "../../../products-core/di/ProductsCoreDomainDiModule"

export default interface DashboardsPresentationDiModule {
  provideDashboardsPage(): React.ReactNode

  provideDashboardPage(): React.ReactNode
}

export class DefaultDashboardsPresentationDiModule
  extends DiModule
  implements DashboardsPresentationDiModule {

  private readonly objectsDomainDiModule: ObjectsDomainDiModule
  private readonly dashboardsDomainDiModule: DashboardsDomainDiModule
  private readonly coreI18nDiModule: CoreI18nDiModule
  private readonly propertyValuesCoreDomainDiModule: PropertyValuesCoreDomainDiModule
  private readonly propertiesCoreDomainDiModule: PropertiesCoreDomainDiModule
  private readonly productCategoriesCoreDomainDiModule: ProductCategoriesCoreDomainDiModule
  private readonly productsCoreDomainDiModule: ProductsCoreDomainDiModule

  constructor(parameters: {
    readonly objectsDomainDiModule: ObjectsDomainDiModule
    readonly dashboardsDomainDiModule: DashboardsDomainDiModule
    readonly propertyValuesCoreDomainDiModule: PropertyValuesCoreDomainDiModule
    readonly propertiesCoreDomainDiModule: PropertiesCoreDomainDiModule
    readonly productCategoriesCoreDomainDiModule: ProductCategoriesCoreDomainDiModule
    readonly productsCoreDomainDiModule: ProductsCoreDomainDiModule
    readonly coreI18nDiModule: CoreI18nDiModule
  }) {
    super()
    autoBind(this)
    this.objectsDomainDiModule = parameters.objectsDomainDiModule
    this.dashboardsDomainDiModule = parameters.dashboardsDomainDiModule
    this.coreI18nDiModule = parameters.coreI18nDiModule
    this.propertyValuesCoreDomainDiModule = parameters.propertyValuesCoreDomainDiModule
    this.propertiesCoreDomainDiModule = parameters.propertiesCoreDomainDiModule
    this.productCategoriesCoreDomainDiModule = parameters.productCategoriesCoreDomainDiModule
    this.productsCoreDomainDiModule = parameters.productsCoreDomainDiModule
  }

  provideDashboardsPage(): React.ReactNode {
    return <DashboardsPage provideViewModel={this.provideDashboardsViewModel} />
  }

  provideDashboardPage(): React.ReactNode {
    return <DashboardPage provideViewModel={this.provideDashboardViewModel} />
  }

  private provideDashboardsViewModel({
    objectsPresentationLogicParameters
  }: {
    readonly objectsPresentationLogicParameters: ObjectsPresentationLogicParameters
  }): DashboardsViewModel {
    return new DashboardsViewModel({
      objectsPresentationLogicParameters,
      coreI18n: this.coreI18nDiModule.provideCoreI18n(),
      subscribeToObjectsEventsUseCase: this.objectsDomainDiModule.provideSubscribeToObjectsEventsUseCase({
        key: "dashboards"
      }),
      unsubscribeFromObjectsEventsUseCase: this.objectsDomainDiModule.provideUnsubscribeFromObjectsEventsUseCase({
        key: "dashboards"
      }),
      getDashboardsUseCase: this.dashboardsDomainDiModule.provideGetDashboardsUseCase()
    })
  }

  private provideDashboardViewModel({
    dashboardId
  }: {
    readonly dashboardId?: number
  }): DashboardViewModel {
    return new DashboardViewModel({
      coreI18n: this.coreI18nDiModule.provideCoreI18n(),
      broadcastObjectsEventUseCase: this.objectsDomainDiModule.provideBroadcastObjectsEventUseCase({
        key: "dashboards"
      }),
      getDashboardUseCase: this.dashboardsDomainDiModule.provideGetDashboardUseCase(),
      getDashboardsUseCase: this.dashboardsDomainDiModule.provideGetDashboardsUseCase(),
      updateDashboardUseCase: this.dashboardsDomainDiModule.provideUpdateDashboardUseCase(),
      createDashboardUseCase: this.dashboardsDomainDiModule.provideCreateDashboardUseCase(),
      createBannerImageUseCase: this.dashboardsDomainDiModule.provideCreateBannerImageUseCase(),
      destroyDashboardUseCase: this.dashboardsDomainDiModule.provideDestroyDashboardUseCase(),
      getProductCategoriesUseCase: this.productCategoriesCoreDomainDiModule.provideGetProductCategoriesUseCase(),
      getProductsUseCase: this.productsCoreDomainDiModule.provideGetProductsUseCase(),
      getPropertiesUseCase: this.propertiesCoreDomainDiModule.provideGetPropertiesUseCase(),
      getPropertyValuesUseCase: this.propertyValuesCoreDomainDiModule.provideGetPropertyValuesUseCase(),
      dashboardId
    })
  }
}
