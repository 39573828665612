import React from "react"
import styles from "./FormMenuComponent.module.scss"
import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import { useCoreTextProvider } from "../../../../../core/presentation/contexts/CoreTextProviderContext"
import {
  CreatingObjectViewState,
  DestroyingObjectViewState,
  LoadedObjectViewState,
  UpdatingObjectViewState
} from "../../view-states/ObjectViewState"
import ButtonComponent, { ButtonStyle } from "../../../../../design/button/ButtonComponent"
import TextComponent, { TextStyle } from "../../../../../design/text/TextComponent"

export default function FormMenuComponent({
  objectViewState,
  formErrorMessage,
  onCreateObjectClicked,
  onCreateObjectAndCloseClicked,
  onUpdateObjectClicked,
  onUpdateObjectAndCloseClicked,
  onDeleteObjectClicked
}: {
  readonly objectViewState:
    LoadedObjectViewState |
    CreatingObjectViewState |
    UpdatingObjectViewState |
    DestroyingObjectViewState
  readonly formErrorMessage: string | undefined | null
  readonly onCreateObjectClicked: () => void
  readonly onCreateObjectAndCloseClicked: () => void
  readonly onUpdateObjectClicked: () => void
  readonly onUpdateObjectAndCloseClicked: () => void
  readonly onDeleteObjectClicked: () => void
}) {
  const coreTextProvider: CoreTextProvider = useCoreTextProvider()

  const {
    isNewObject,
    formPermissionsSet
  } = objectViewState

  const {
    canCreate,
    canDestroy,
    canUpdate,
    canClosePageAfterUpdated
  } = formPermissionsSet

  const isButtonsDisabled = objectViewState.type !== "loaded"

  return (
    <div className={styles.buttons}>
      <div className={styles.leftContainer}>
        {isNewObject && canCreate && (
          <>
            <ButtonComponent
              text={coreTextProvider.createObject()}
              buttonStyle={ButtonStyle.FILLED1_PRIMARY}
              isDisabled={isButtonsDisabled}
              onClick={onCreateObjectClicked}
            />
            <ButtonComponent
              text={coreTextProvider.createObjectAndClose()}
              buttonStyle={ButtonStyle.OUTLINED1_PRIMARY}
              isDisabled={isButtonsDisabled}
              onClick={onCreateObjectAndCloseClicked}
            />
          </>
        )}
        {!isNewObject && canUpdate && (
          <>
            <ButtonComponent
              text={coreTextProvider.updateObject()}
              buttonStyle={ButtonStyle.FILLED1_PRIMARY}
              isDisabled={isButtonsDisabled}
              onClick={onUpdateObjectClicked}
            />
            {canClosePageAfterUpdated && (
              <ButtonComponent
                text={coreTextProvider.updateObjectAndClose()}
                buttonStyle={ButtonStyle.OUTLINED1_PRIMARY}
                isDisabled={isButtonsDisabled}
                onClick={onUpdateObjectAndCloseClicked}
              />
            )}
          </>
        )}
        {formErrorMessage && (
          <TextComponent textStyle={TextStyle.ERROR1_PRIMARY}>{formErrorMessage}</TextComponent>
        )}
      </div>
      <div className={styles.rightContainer}>
        {!isNewObject && canDestroy && (
          <ButtonComponent
            text={coreTextProvider.deleteObject()}
            buttonStyle={ButtonStyle.FILLED1_SECONDARY}
            isDisabled={isButtonsDisabled}
            onClick={onDeleteObjectClicked}
          />
        )}
      </div>
    </div>
  )
}
