import OrdersFilter from "../../domain/entities/orders/OrdersFilter"
import NetworkOrdersRequestFilter from "../entities/orders/NetworkOrdersRequestFilter"

export default class OrdersFiltersMapper {
  mapDomainToNetwork({
    filter
  }: {
    readonly filter: OrdersFilter
  }): NetworkOrdersRequestFilter {
    return new NetworkOrdersRequestFilter({
      query: filter.query
    })
  }
}
