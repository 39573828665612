import NetworkPayment from "../entities/payments/NetworkPayment"
import Payment from "../../domain/entities/payments/Payment"
import PaymentStatusesMapper from "./PaymentStatusesMapper"

export default class PaymentsMapper {
  mapNetworkToDomain({
    payment
  }: {
    readonly payment: NetworkPayment
  }): Payment {
    return {
      statusId: payment.statusId,
      status: payment.status && new PaymentStatusesMapper()
        .mapNetworkToDomain({ paymentStatus: payment.status })
    }
  }

  mapDomainToNetwork({
    payment
  }: {
    readonly payment: Payment
  }): NetworkPayment {
    return new NetworkPayment({
      statusId: payment.statusId,
      status: payment.status && new PaymentStatusesMapper()
        .mapDomainToNetwork({ paymentStatus: payment.status })
    })
  }
}
