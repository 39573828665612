import Product from "../../../../../core/domain/entities/products/Product"
import ProductsRepository from "../../repositories/ProductsRepository"
import { GetObjectsPageResult } from "../../../../../../sqadmin/features/objects/domain/results/GetObjectsPageResult"
import Sort from "../../../../../../sqadmin/features/objects/presentation/entities/tables/Sort"
import LastItemPagination from "../../../../../core/domain/entities/paginations/LastItemPagination"
import ProductsFilter from "../../../../../core/domain/entities/products/ProductsFilter"

export default class GetProductsUseCase {
  private readonly productsRepository: ProductsRepository

  constructor(parameters: {
    readonly productsRepository: ProductsRepository
  }) {
    this.productsRepository = parameters.productsRepository
  }

  async call(parameters: GetProductsParameters): Promise<GetObjectsPageResult<Product>> {
    return await this.productsRepository.getProducts(parameters)
  }
}

export interface GetProductsParameters {
  readonly filter: ProductsFilter | null | undefined
  readonly query: string | null | undefined
  readonly sort?: Sort
  readonly pagination?: LastItemPagination
}
