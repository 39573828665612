import { Expose } from "class-transformer"

export default class NetworkPropertiesRequestFilter {
  @Expose()
  readonly query?: string | null

  constructor(parameters: {
    readonly query?: string | null
  }) {
    this.query = parameters.query
  }
}
