import BackendHttpClient, { BackendHttpClientResult } from "../../network/BackendHttpClient"
import NetworkLastItemPagination from "../../entities/pagination/NetworkLastItemPagination"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import { instanceToPlain, plainToInstance } from "class-transformer"
import NetworkExecutionError from "../../entities/errors/NetworkExecutionError"
import GetObjectNetworkResult from "../../results/GetObjectNetworkResult"
import NetworkPlace from "../../entities/places/NetworkPlace"
import CreateObjectNetworkResult from "../../results/CreateObjectNetworkResult"
import NetworkPlaceError from "../../entities/places/NetworkPlaceError"
import UpdateObjectNetworkResult from "../../results/UpdateObjectNetworkResult"
import DestroyObjectNetworkResult from "../../results/DestroyObjectNetworkResult"
import SuccessExecutionResult from "../../../../../sqadmin/core/domain/results/SuccessExecutionResult"
import ErrorExecutionResult from "../../../../../sqadmin/core/domain/results/ErrorExecutionResult"
import FailureExecutionResult from "../../../../../sqadmin/core/domain/results/FailureExecutionResult"
import NetworkPlacesRequestFilter from "../../entities/places/NetworkPlacesRequestFilter"
import NetworkPlacesRequestQuery from "../../entities/places/NetworkPlacesRequestQuery"
import NetworkPlacesResponseBody from "../../entities/places/NetworkPlacesResponseBody"
import NetworkPlaceResponseBody from "../../entities/places/NetworkPlaceResponseBody"
import NetworkPlaceRequestBody from "../../entities/places/NetworkPlaceRequestBody"

const basePath = "/admin/places"

export default class PlacesNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async getPlaces({
    filter,
    pagination,
    sort
  }: {
    readonly filter?: NetworkPlacesRequestFilter | null
    readonly pagination?: NetworkLastItemPagination | null
    readonly sort?: string | null
  }): Promise<GetPlacesNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: basePath,
      parameters: instanceToPlain(new NetworkPlacesRequestQuery({
        filter,
        pagination,
        sort
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkPlacesResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async getPlace({
    placeId
  }: {
    readonly placeId: number
  }): Promise<GetObjectNetworkResult<NetworkPlace>> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}/${placeId}`
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkPlaceResponseBody, result.body).place!
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async createPlace({
    place
  }: {
    readonly place: NetworkPlace
  }): Promise<CreateObjectNetworkResult<NetworkPlace, NetworkPlaceError>> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.POST,
      path: basePath,
      body: instanceToPlain(new NetworkPlaceRequestBody({
        place
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkPlaceResponseBody, result.body).place!
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkPlaceError, result.body)
        }
      case "failure":
        return result
    }
  }

  async updatePlace({
    placeId,
    place
  }: {
    readonly placeId: number
    readonly place: NetworkPlace
  }): Promise<UpdateObjectNetworkResult<NetworkPlace, NetworkPlaceError>> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.PUT,
      path: `${basePath}/${placeId}`,
      body: instanceToPlain(new NetworkPlaceRequestBody({
        place
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkPlaceResponseBody, result.body).place!
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkPlaceError, result.body)
        }
      case "failure":
        return result
    }
  }

  async destroyPlace({
    placeId
  }: {
    readonly placeId: number
  }): Promise<DestroyObjectNetworkResult<NetworkPlaceError>> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.DELETE,
      path: `${basePath}/${placeId}`
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: undefined
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkPlaceError, result.body)
        }
      case "failure":
        return result
    }
  }
}

export type GetPlacesNetworkResult =
  SuccessExecutionResult<NetworkPlacesResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult
