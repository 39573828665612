import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import { useCoreTextProvider } from "../../../../../core/presentation/contexts/CoreTextProviderContext"
import { useNumberObjectId } from "../../../../../../sqadmin/features/objects/presentation/hooks/useObjectId"
import { useViewModel } from "../../../../../../sqadmin/lib/view-model/ViewModel"
import ObjectComponent from "../../../../../../sqadmin/features/objects/presentation/components/object/ObjectComponent"
import React from "react"
import ClientViewModel from "./ClientViewModel"

export interface ClientPageProps {
  readonly provideViewModel: (parameters: { readonly clientId?: number }) => ClientViewModel
}

export default function ClientPage({
  provideViewModel
}: ClientPageProps) {
  const coreTextProvider: CoreTextProvider = useCoreTextProvider()
  const clientId: number | undefined = useNumberObjectId()

  const viewModel: ClientViewModel = useViewModel(() => provideViewModel({
    clientId: clientId
  }))

  return (
    <ObjectComponent
      existedObjectTitle={coreTextProvider.client()}
      observableObjectViewState={viewModel.observableObjectViewState}
      onObjectViewEvent={viewModel.onViewObjectEvent}
    />
  )
}
