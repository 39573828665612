import GetObjectNetworkResult from "../../results/GetObjectNetworkResult"
import NetworkConfiguration from "../../entities/configurations/NetworkConfiguration"

export default class ConfigurationsLocalSource {
  private configurationResult: GetObjectNetworkResult<NetworkConfiguration> | null | undefined

  saveConfiguration({
    configurationResult
  }: {
    readonly configurationResult: GetObjectNetworkResult<NetworkConfiguration>
  }) {
    this.configurationResult = configurationResult
  }

  getConfiguration(): GetObjectNetworkResult<NetworkConfiguration> | null | undefined {
    return this.configurationResult
  }
}
