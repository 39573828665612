import { DestroyObjectResult } from "../../../../../sqadmin/features/objects/domain/results/DestroyObjectResult"
import OrderReceivingMethodsRepository from "../repositories/OrderReceivingMethodsRepository"
import OrderReceivingMethodError
  from "../../../../core/domain/entities/order-receiving-methods/OrderReceivingMethodError"

export default class DestroyOrderReceivingMethodUseCase {
  private readonly orderReceivingMethodsRepository: OrderReceivingMethodsRepository

  constructor(parameters: {
    readonly orderReceivingMethodsRepository: OrderReceivingMethodsRepository
  }) {
    this.orderReceivingMethodsRepository = parameters.orderReceivingMethodsRepository
  }

  async call(parameters: {
    readonly orderReceivingMethodId: number
  }): Promise<DestroyObjectResult<OrderReceivingMethodError>> {
    return await this.orderReceivingMethodsRepository.destroyOrderReceivingMethod(parameters)
  }
}
