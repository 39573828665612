import { Decimal } from "decimal.js"
import { Expose, Type } from "class-transformer"
import NetworkVariantOption from "../variant-options/NetworkVariantOption"
import NetworkVariantImage from "../variant-images/NetworkVariantImage"
import NetworkProduct from "../products/NetworkProduct"
import NetworkBatch from "../batches/NetworkBatch"

export default class NetworkProductVariant {
  @Expose()
  readonly id?: number | null

  @Expose()
  readonly externalCode?: string | null

  @Expose()
  readonly clientId?: string | null

  @Expose({ name: "variantOptionValues" })
  @Type(() => NetworkVariantOption)
  readonly variantOptions?: NetworkVariantOption[] | null

  @Expose()
  @Type(() => NetworkVariantImage)
  readonly variantImages?: NetworkVariantImage[] | null

  @Expose()
  @Type(() => NetworkProduct)
  readonly product: NetworkProduct | null | undefined

  @Expose()
  @Type(() => NetworkBatch)
  readonly mainBatch: NetworkBatch | null | undefined

  @Expose()
  @Type(() => NetworkBatch)
  readonly batches: NetworkBatch[] | null | undefined

  constructor(parameters?: {
    readonly id?: number | null
    readonly clientId?: string | null
    readonly externalCode?: string | null
    readonly variantOptions?: NetworkVariantOption[] | null
    readonly variantImages?: NetworkVariantImage[] | null
    readonly product: NetworkProduct | null | undefined
    readonly mainBatch: NetworkBatch | null | undefined
    readonly batches: NetworkBatch[] | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.id = parameters.id
    this.externalCode = parameters.externalCode
    this.clientId = parameters.clientId
    this.variantOptions = parameters.variantOptions
    this.variantImages = parameters.variantImages
    this.product = parameters.product
    this.mainBatch = parameters.mainBatch
    this.batches = parameters.batches
  }
}
