import Product from "../../../../../core/domain/entities/products/Product"
import ProductError from "../../../../../core/domain/entities/products/ProductError"
import ProductsRepository from "../../repositories/ProductsRepository"
import { CreateObjectResult } from "../../../../../../sqadmin/features/objects/domain/results/CreateObjectResult"

export default class CreateProductUseCase {
  private readonly productsRepository: ProductsRepository

  constructor(parameters: {
    readonly productsRepository: ProductsRepository
  }) {
    this.productsRepository = parameters.productsRepository
  }

  async call({ product }: { readonly product: Product }): Promise<CreateObjectResult<Product, ProductError>> {
    return await this.productsRepository.createProduct({ product })
  }
}
