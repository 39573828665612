import isBlank from "../../../../../../../sqadmin/lib/isBlank"
import Configuration from "../../../../../../core/domain/entities/configurations/Configuration"
import ConfigurationEntityName from "../../../../../../core/domain/entities/configurations/ConfigurationEntityName"
import ConfigurationEntityPropertyName
  from "../../../../../../core/domain/entities/configurations/ConfigurationEntityPropertyName"
import ProductProperty from "../../../../../../core/domain/entities/product-properties/ProductProperty"
import PropertyDataType from "../../../../../../core/domain/entities/properties/PropertyDataType"
import { ObjectEditor } from "./ObjectEditor"

export class ProductPropertyEditor {
  private readonly objectEditor: ObjectEditor

  constructor(parameters: { configuration: Configuration | null | undefined }) {
    this.objectEditor = new ObjectEditor()
    this.objectEditor.init(parameters.configuration, ConfigurationEntityName.PRODUCT_PROPERTY_VALUE)
  }

  isPropertyDisabled(productProperty: ProductProperty) {
    return this.objectEditor.isDisabled(productProperty.id, ConfigurationEntityPropertyName.PROPERTY_ID)
  }

  isPropertyValueDisabled(productProperty: ProductProperty) {
    return this.objectEditor.isDisabled(productProperty.id, ConfigurationEntityPropertyName.PROPERTY_VALUE_ID)
  }

  isStringPropertyDataType(productProperty: ProductProperty): boolean {
    const property = productProperty.property
    if (isBlank(property)) return false

    const propertyDataType = property.dataType
    if (isBlank(propertyDataType)) return false

    return propertyDataType === PropertyDataType.STRING
  }

  isDictionaryPropertyDataType(productProperty: ProductProperty): boolean {
    const property = productProperty.property
    if (isBlank(property)) return true

    const propertyDataType = property.dataType
    if (isBlank(propertyDataType)) return true

    return propertyDataType === PropertyDataType.DICTIONARY
  }

  canCreate() {
    return this.objectEditor.canCreate()
  }

  canDestroy(productProperty: ProductProperty) {
    return this.objectEditor.canDestroy(productProperty.id)
  }
}
