import DiModule from "../../../../sqadmin/lib/di/DiModule"
import ProductCategoriesRepository from "../domain/repositories/ProductCategoriesRepository"
import DefaultProductCategoriesRepository from "../data/repositories/DefaultProductCategoriesRepository"
import ProductCategoryImagesRepository from "../domain/repositories/ProductCategoryImagesRepository"
import DefaultProductCategoryImagesRepository from "../data/repositories/DefaultProductCategoryImagesRepository"
import CoreNetworkDiModule from "../../../core/di/modules/CoreNetworkDiModule"
import ProductCategoryImagesNetworkSource from "../data/sources/ProductCategoryImagesNetworkSource"
import ProductCategoriesNetworkSource from "../data/sources/ProductCategoriesNetworkSource"

export default interface ProductCategoriesCoreDataDiModule {
  provideProductCategoriesRepository(): ProductCategoriesRepository

  provideProductCategoryImagesRepository(): ProductCategoryImagesRepository
}

export class DefaultProductCategoriesCoreDataDiModule
  extends DiModule
  implements ProductCategoriesCoreDataDiModule {

  private readonly sqCoreCoreNetworkDiModule: CoreNetworkDiModule

  constructor(parameters: {
    readonly sqCoreCoreNetworkDiModule: CoreNetworkDiModule
  }) {
    super()
    this.sqCoreCoreNetworkDiModule = parameters.sqCoreCoreNetworkDiModule
  }

  provideProductCategoryImagesRepository(): ProductCategoryImagesRepository {
    return this.single(
      DefaultProductCategoryImagesRepository.name,
      () => new DefaultProductCategoryImagesRepository({
        productCategoryImagesNetworkSource: this.provideProductCategoryImagesNetworkSource()
      })
    )
  }

  provideProductCategoriesRepository(): ProductCategoriesRepository {
    return this.single(
      DefaultProductCategoriesRepository.name,
      () => new DefaultProductCategoriesRepository({
        productCategoriesNetworkSource: this.provideProductCategoriesNetworkSource()
      })
    )
  }

  private provideProductCategoryImagesNetworkSource(): ProductCategoryImagesNetworkSource {
    return this.single(
      ProductCategoryImagesNetworkSource.name,
      () => new ProductCategoryImagesNetworkSource({
        backendHttpClient: this.sqCoreCoreNetworkDiModule.provideBackendHttpClient()
      })
    )
  }

  private provideProductCategoriesNetworkSource(): ProductCategoriesNetworkSource {
    return this.single(
      ProductCategoriesNetworkSource.name,
      () => new ProductCategoriesNetworkSource({
        backendHttpClient: this.sqCoreCoreNetworkDiModule.provideBackendHttpClient()
      })
    )
  }
}
