import React from "react"
import styles from "./LoadingIndicatorComponent.module.scss"
import { ReactComponent as LoadingIndicatorSvg } from "../../core/assets/icons/ic_loading_indicator.svg"

export default function LoadingIndicatorComponent() {
  return (
    <div className={styles.loadingIndicator}>
      <LoadingIndicatorSvg />
    </div>
  )
}
