import { LoadObjectResult } from "../../../../../sqadmin/features/objects/domain/results/LoadObjectResult"
import OptionsRepository from "../repositories/OptionsRepository"
import Option from "../../../../core/domain/entities/options/Option"

export default class GetOptionUseCase {
  private readonly optionsRepository: OptionsRepository

  constructor(parameters: {
    readonly optionsRepository: OptionsRepository
  }) {
    this.optionsRepository = parameters.optionsRepository
  }

  async call(parameters: {
    readonly optionId: number
  }): Promise<LoadObjectResult<Option>> {
    return await this.optionsRepository.getOption(parameters)
  }
}
