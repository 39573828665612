import React, { useEffect, useRef } from "react"
import { ObjectsPageViewState } from "../../view-states/ObjectsViewState"
import ObjectsViewEvent from "../../view-events/ObjectsViewEvent"
import { ActionObservable, useActionObservable } from "../../../../../lib/view-model/ActionObservable"
import { ObjectsViewAction } from "../../view-actions/ObjectsViewAction"
import { Link, Location, useLocation, useSearchParams } from "react-router-dom"
import addSearchParam from "../../../../../lib/addSearchParam"
import Sort from "../../entities/tables/Sort"
import SortMapper from "../../mappers/SortMapper"
import { instanceToPlain } from "class-transformer"
import assertNever from "../../../../../lib/assertNever"
import TableColumn from "../../entities/tables/TableColumn"
import { TableColumnSortingType } from "../../entities/tables/TableColumnSortingType"
import styles from "./ObjectsComponent.module.scss"
import VerticalSpacingComponent
  from "../../../../../core/presentation/components/vertical-spacing/VerticalSpacingComponent"
import HeadingComponent from "../../../../../core/presentation/components/heading/HeadingComponent"
import ExceptionLocalizer from "../../../../../core/presentation/services/ExceptionLocalizer"
import TableValue from "../../entities/tables/TableValue"
import TextTableValue from "../../entities/tables/table-value-by-type/TextTableValue"
import TextTableValueComponent from "../table-value-by-type/text-table-value/TextTableValueComponent"
import NumberTableValue from "../../entities/tables/table-value-by-type/NumberTableValue"
import NumberTableValueComponent from "../table-value-by-type/number-table-value/NumberTableValueComponent"
import DecimalTableValue from "../../entities/tables/table-value-by-type/DecimalTableValue"
import DecimalTableValueComponent from "../table-value-by-type/decimal-table-value/DecimalTableValueComponent"
import isPresent from "../../../../../lib/isPresent"
import PaginationComponent from "../pagination/PaginationComponent"
import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import { useCoreTextProvider } from "../../../../../core/presentation/contexts/CoreTextProviderContext"
import { QUERY_KEY, SORT_KEY } from "../../hooks/useObjectsPresentationLogicParameters"
import { StateObservable, useStateObservable } from "../../../../../lib/view-model/StateObservable"
import SearchBarComponent from "../../../../../core/presentation/components/search-bar/SearchBarComponent"
import TextComponent, { TextStyle } from "../../../../../design/text/TextComponent"
import ButtonComponent, { ButtonStyle } from "../../../../../design/button/ButtonComponent"
import TableRowLoadingComponent
  from "../../../../../core/presentation/components/table-row-loading/TableRowLoadingComponent"
import TableRowErrorMessageComponent
  from "../../../../../core/presentation/components/table-row-error-message/TableRowErrorMessageComponent"
import TableRowEmptyDataComponent
  from "../../../../../core/presentation/components/table-row-empty-data/TableRowEmptyDataComponent"
import DateTimeTableValue from "../../entities/tables/table-value-by-type/DateTimeTableValue"
import DateTimeTableValueComponent from "../table-value-by-type/date-time-table-value/DateTimeTableValueComponent"
import FilterFieldsComponent from "../filter-fields/FilterFieldsComponent"
import wrapElement from "../../../../../lib/wrapElement"

export interface ObjectsComponentProps<DomainObject> {
  readonly title: string
  readonly getObjectUrl: (object: DomainObject) => string | null | undefined
  readonly newObjectUrl?: string
  readonly observableObjectsPageViewState: StateObservable<ObjectsPageViewState<DomainObject>>
  readonly observableObjectsViewAction: ActionObservable<ObjectsViewAction>
  readonly onObjectsViewEvent: (objectsViewEvent: ObjectsViewEvent) => void
  readonly isSearchBarVisible?: boolean
  readonly isSearchBarDisabled?: boolean
}

export default function ObjectsComponent<DomainObject>({
  title,
  getObjectUrl,
  newObjectUrl,
  observableObjectsPageViewState,
  onObjectsViewEvent,
  observableObjectsViewAction,
  isSearchBarVisible = true,
  isSearchBarDisabled = false
}: ObjectsComponentProps<DomainObject>) {
  const coreTextProvider: CoreTextProvider = useCoreTextProvider()
  const [searchParams, setSearchParams] = useSearchParams()
  const location: Location = useLocation()
  const objectsPageViewState: ObjectsPageViewState<DomainObject> = useStateObservable(observableObjectsPageViewState)
  const { searchViewState, objectsViewState, filterViewState } = objectsPageViewState

  useEffect(handleResumed, [])
  useActionObservable(observableObjectsViewAction, handleObjectsViewAction)

  const headerElementRef = useRef<HTMLDivElement | null>(null)
  const tableContainerElementRef = useRef<HTMLDivElement | null>(null)
  const tableHeaderRowElementRef = useRef<HTMLTableRowElement | null>(null)

  useEffect(() => {
    const headerElement = headerElementRef.current!

    const handleResize = () => {
      const headerView = wrapElement(headerElement)
      const headerViewHeight = headerView?.getOuterHeight()
      const offsetStyleValue = `${headerViewHeight}px`

      if (isPresent(tableHeaderRowElementRef.current)) {
        tableHeaderRowElementRef.current.style.top = offsetStyleValue
      }

      if (isPresent(tableContainerElementRef.current)) {
        tableContainerElementRef.current.style.paddingTop = offsetStyleValue
      }
    }

    handleResize()

    const observer = new ResizeObserver(handleResize)
    observer.observe(headerElement)

    return () => {
      observer.unobserve(headerElement)
    }
  }, [])

  function handleResumed() {
    onObjectsViewEvent({ type: "resumed" })
  }

  function handleQueryChanged(query: string) {
    onObjectsViewEvent({ type: "query_changed", query })
  }

  function handleSearchRequested() {
    onObjectsViewEvent({ type: "search_requested" })
  }

  function handleColumnClicked(columnName: string) {
    onObjectsViewEvent({ type: "sort_changing_requested", columnName: columnName })
  }

  function handleNextPageRequested() {
    onObjectsViewEvent({ type: "next_page_requested" })
  }

  function handleRetryLoadingFirstPageClicked() {
    onObjectsViewEvent({ type: "retry_loading_first_page_requested" })
  }

  function handleRetryLoadingNextPageClicked() {
    onObjectsViewEvent({ type: "retry_loading_next_page_requested" })
  }

  function handleObjectsViewAction(objectsViewAction: ObjectsViewAction) {
    switch (objectsViewAction.type) {
      case "cache_sort":
        addSortToSearchParams(objectsViewAction.sort)
        break
      case "cache_query":
        addQueryToSearchParams(objectsViewAction.query)
        break
      default:
        assertNever(objectsViewAction)
    }
  }

  function addQueryToSearchParams(query: string | null | undefined) {
    let newSearchParams: URLSearchParams = searchParams

    newSearchParams = addSearchParam({
      searchParams: newSearchParams,
      key: QUERY_KEY,
      value: query
    })

    setSearchParams(newSearchParams, { replace: true, state: location.state })
  }

  function addSortToSearchParams(sort?: Sort) {
    let newSearchParams: URLSearchParams = searchParams

    newSearchParams = addSearchParam({
      searchParams: newSearchParams,
      key: SORT_KEY,
      value: serializeSort(sort)
    })

    setSearchParams(newSearchParams, { replace: true, state: location.state })
  }

  function serializeSort(sort?: Sort) {
    return JSON.stringify(
      instanceToPlain(
        new SortMapper().mapDomainSortToBrowser({
          sort: sort
        })
      )
    )
  }

  function getSortingImage(column: TableColumn<DomainObject>, sort ?: Sort) {
    if (!column.getSortingAvailable() || column.getId() !== sort?.id) return <div />

    return (() => {
      switch (sort?.type) {
        case TableColumnSortingType.ASC:
          return <div className={`${styles.sorting} ${styles.asc}`} />
        case TableColumnSortingType.DESC:
          return <div className={`${styles.sorting} ${styles.desc}`} />
        default:
          return <div />
      }
    })()
  }

  return (
    <>
      <div
        ref={headerElementRef}
        className={styles.listPageHeader}
      >
        <VerticalSpacingComponent />
        <div className={styles.row}>
          <HeadingComponent>
            <TextComponent textStyle={TextStyle.HEADER1_PRIMARY}>{title}</TextComponent>
          </HeadingComponent>

          {isSearchBarVisible && (
            <div className={styles.searchBarContainer}>
              {(() => {
                switch (searchViewState.type) {
                  case "initial":
                    return <></>
                  case "idle": {
                    const { query } = searchViewState

                    return (
                      <SearchBarComponent
                        query={query}
                        disabled={isSearchBarDisabled}
                        onQueryChanged={handleQueryChanged}
                        onSearchRequested={handleSearchRequested}
                      />
                    )
                  }
                }
              })()}
            </div>
          )}

          {isPresent(newObjectUrl) && (
            <ButtonComponent
              buttonStyle={ButtonStyle.OUTLINED1_PRIMARY}
              text={coreTextProvider.goToNewObject()}
              url={newObjectUrl}
            />
          )}
        </div>
        {(() => {
          switch (filterViewState.type) {
            case "initial":
              return <></>
            case "loading":
              return <></>
            case "idle":
              return (
                <>
                  {isPresent(filterViewState.fieldViewStates) && filterViewState.fieldViewStates.length > 0 && (
                    <>
                      <VerticalSpacingComponent />
                      <div className={styles.row}>
                        <FilterFieldsComponent
                          fieldViewStates={filterViewState.fieldViewStates}
                          isDisabled={false}
                        />
                      </div>
                    </>
                  )}
                </>
              )
          }
        })()}
        <VerticalSpacingComponent />
      </div>
      <div
        ref={tableContainerElementRef}
        className={styles.tableContainer}
      >
        {(() => {
          switch (objectsViewState.type) {
            case "initial":
              return <></>
            default: {
              const { getObjectId, columns, sort } = objectsViewState

              return (
                <table className={styles.table}>
                  <thead>
                  <tr ref={tableHeaderRowElementRef}>
                    {columns.map((column: TableColumn<DomainObject>) => (
                      <th
                        onClick={() => column.getSortingAvailable() && handleColumnClicked(column.getId())}
                        className={column.getSortingAvailable() ? styles.clickable : undefined}
                        key={column.getId()}
                      >
                        <div className={styles.thContent}>
                          <TextComponent textStyle={TextStyle.TABLE1_PRIMARY}>
                            {column.getTitle()}
                          </TextComponent>
                          {getSortingImage(column, sort)}
                        </div>
                      </th>
                    ))}
                  </tr>
                  </thead>
                  <tbody>
                  {(() => {
                    switch (objectsViewState.type) {
                      case "loading":
                        return (<TableRowLoadingComponent columnsCount={columns.length} />)
                      case "loading_error":
                        return (
                          <TableRowErrorMessageComponent
                            columnsCount={columns.length}
                            errorMessage={objectsViewState.error.message}
                            onRetryClick={handleRetryLoadingFirstPageClicked}
                          />
                        )
                      case "loading_failure":
                        return (
                          <TableRowErrorMessageComponent
                            columnsCount={columns.length}
                            errorMessage={new ExceptionLocalizer({ coreTextProvider })
                              .localizeException(objectsViewState.exception)}
                            onRetryClick={handleRetryLoadingFirstPageClicked}
                          />
                        )
                      case "loaded":
                      case "next_page_loading":
                      case "next_page_loading_error":
                      case "next_page_loading_failure":
                        switch (objectsViewState.type) {
                          case "loaded":
                            if (objectsViewState.objects.length === 0) {
                              return (<TableRowEmptyDataComponent columnsCount={columns.length} />)
                            }
                        }

                        return (
                          <>
                            {objectsViewState.objects.map((object: DomainObject) => {
                              const objectUrl: string | null | undefined = getObjectUrl(object)

                              return (
                                <tr key={getObjectId(object)}>
                                  {columns.map((column: TableColumn<DomainObject>) => {
                                    const tableValue: TableValue | null = column.createValue(object)
                                    const cellUrl: string | null | undefined = tableValue?.getUrl()

                                    const cellContent: React.ReactNode = (() => {
                                      if (tableValue === null) {
                                        return <></>
                                      }

                                      if (tableValue instanceof TextTableValue) {
                                        return <TextTableValueComponent textTableValue={tableValue} />
                                      }

                                      if (tableValue instanceof NumberTableValue) {
                                        return <NumberTableValueComponent numberTableValue={tableValue} />
                                      }

                                      if (tableValue instanceof DecimalTableValue) {
                                        return <DecimalTableValueComponent decimalTableValue={tableValue} />
                                      }

                                      if (tableValue instanceof DateTimeTableValue) {
                                        return <DateTimeTableValueComponent dateTimeTableValue={tableValue} />
                                      }

                                      throw "Unknown table value type"
                                    })()

                                    return (
                                      <td key={column.getId()}>
                                        {isPresent(objectUrl) && <Link to={objectUrl} className={styles.rowLink} />}
                                        {isPresent(cellUrl) ?
                                          <Link to={cellUrl} className={styles.cellLink}>
                                            <TextComponent
                                              textStyle={TextStyle.TABLE2_PRIMARY}>{cellContent}</TextComponent>
                                          </Link> :
                                          <TextComponent
                                            textStyle={TextStyle.TABLE2_PRIMARY}>{cellContent}</TextComponent>}
                                      </td>
                                    )
                                  })}
                                </tr>
                              )
                            })}
                            {(() => {
                              switch (objectsViewState.type) {
                                case "loaded":
                                  return (
                                    <tr>
                                      <td className={styles.paginateComponent}>
                                        <PaginationComponent onComponentAppeared={handleNextPageRequested} />
                                      </td>
                                    </tr>
                                  )
                                case "next_page_loading":
                                  return (<TableRowLoadingComponent columnsCount={columns.length} />)
                                case "next_page_loading_error":
                                  return (
                                    <TableRowErrorMessageComponent
                                      columnsCount={columns.length}
                                      errorMessage={objectsViewState.error.message}
                                      onRetryClick={handleRetryLoadingNextPageClicked}
                                    />
                                  )
                                case "next_page_loading_failure":
                                  return (
                                    <TableRowErrorMessageComponent
                                      columnsCount={columns.length}
                                      errorMessage={new ExceptionLocalizer({ coreTextProvider })
                                        .localizeException(objectsViewState.exception)}
                                      onRetryClick={handleRetryLoadingNextPageClicked}
                                    />
                                  )
                                default:
                                  assertNever(objectsViewState)
                              }
                            })()}
                          </>
                        )
                      default:
                        assertNever(objectsViewState)
                    }
                  })()}
                  </tbody>
                </table>
              )
            }
          }
        })()}
      </div>
      <VerticalSpacingComponent />
    </>
  )
}
