import DiModule from "../../../../sqadmin/lib/di/DiModule"
import CoreSessionsNetworkDiModule from "../../../../sqadmin/core/di/modules/CoreSessionsNetworkDiModule"
import SessionsNetworkSource from "../../../../sqadmin/core/data/sources/sessions/SessionsNetworkSource"
import DefaultSessionNetworkSource from "../../data/sources/network/DefaultSessionNetworkSource"
import CoreNetworkDiModule from "./CoreNetworkDiModule"

export default class DefaultCoreSessionsNetworkDiModule
  extends DiModule
  implements CoreSessionsNetworkDiModule {

  private readonly coreNetworkDiModule: CoreNetworkDiModule

  constructor(parameters: {
    readonly coreNetworkDiModule: CoreNetworkDiModule
  }) {
    super()
    this.coreNetworkDiModule = parameters.coreNetworkDiModule
  }

  provideSessionNetworkSource(): SessionsNetworkSource {
    return this.single(
      DefaultSessionNetworkSource.name,
      () => new DefaultSessionNetworkSource({
        backendHttpClient: this.coreNetworkDiModule.provideBackendHttpClient()
      })
    )
  }
}
