import { GetObjectsPageResult } from "../../../../../sqadmin/features/objects/domain/results/GetObjectsPageResult"
import OptionValue from "../../../../core/domain/entities/option-values/OptionValue"
import OptionValuesRepository from "../repositories/OptionValuesRepository"

export default class GetOptionValuesUseCase {
  private readonly optionValuesRepository: OptionValuesRepository

  constructor(parameters: {
    readonly optionValuesRepository: OptionValuesRepository
  }) {
    this.optionValuesRepository = parameters.optionValuesRepository
  }

  async call(parameters: GetOptionValuesParameters): Promise<GetOptionValuesResult> {
    return await this.optionValuesRepository.getOptionValues(parameters)
  }
}

export interface GetOptionValuesParameters {
  readonly query: string | null | undefined
  readonly optionId: number
}

export type GetOptionValuesResult = GetObjectsPageResult<OptionValue>
