import BackendHttpClient, { BackendHttpClientResult } from "../../../../core/data/network/BackendHttpClient"
import NetworkLastItemPagination from "../../../../core/data/entities/pagination/NetworkLastItemPagination"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import { instanceToPlain, plainToInstance } from "class-transformer"
import NetworkExecutionError from "../../../../core/data/entities/errors/NetworkExecutionError"
import SuccessExecutionResult from "../../../../../sqadmin/core/domain/results/SuccessExecutionResult"
import ErrorExecutionResult from "../../../../../sqadmin/core/domain/results/ErrorExecutionResult"
import FailureExecutionResult from "../../../../../sqadmin/core/domain/results/FailureExecutionResult"
import GetObjectNetworkResult from "../../../../core/data/results/GetObjectNetworkResult"
import CreateObjectNetworkResult from "../../../../core/data/results/CreateObjectNetworkResult"
import UpdateObjectNetworkResult from "../../../../core/data/results/UpdateObjectNetworkResult"
import DestroyObjectNetworkResult from "../../../../core/data/results/DestroyObjectNetworkResult"
import NetworkPropertiesRequestFilter from "../entities/NetworkPropertiesRequestFilter"
import NetworkPropertiesResponseBody from "../entities/NetworkPropertiesResponseBody"
import NetworkPropertiesRequestQuery from "../entities/NetworkPropertiesRequestQuery"
import NetworkProperty from "../../../../core/data/entities/properties/NetworkProperty"
import NetworkPropertyResponseBody from "../entities/NetworkPropertyResponseBody"
import NetworkPropertyError from "../../../../core/data/entities/properties/NetworkPropertyError"
import NetworkPropertyRequestBody from "../entities/NetworkPropertyRequestBody"

const basePath = "/admin/properties"

export default class PropertiesNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async getProperties({
    filter,
    pagination,
    sort
  }: {
    readonly filter?: NetworkPropertiesRequestFilter | null
    readonly pagination?: NetworkLastItemPagination | null
    readonly sort?: string | null
  }): Promise<GetPropertiesNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: basePath,
      parameters: instanceToPlain(new NetworkPropertiesRequestQuery({
        filter,
        pagination,
        sort
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkPropertiesResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async getProperty({
    propertyId
  }: {
    readonly propertyId: number
  }): Promise<GetPropertyNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}/${propertyId}`
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkPropertyResponseBody, result.body).property!
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async createProperty({
    property
  }: {
    readonly property: NetworkProperty
  }): Promise<CreatePropertyNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.POST,
      path: basePath,
      body: instanceToPlain(new NetworkPropertyRequestBody({
        property
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkPropertyResponseBody, result.body).property!
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkPropertyError, result.body)
        }
      case "failure":
        return result
    }
  }

  async updateProperty({
    propertyId,
    property
  }: {
    readonly propertyId: number
    readonly property: NetworkProperty
  }): Promise<UpdatePropertyNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.PUT,
      path: `${basePath}/${propertyId}`,
      body: instanceToPlain(new NetworkPropertyRequestBody({
        property
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkPropertyResponseBody, result.body).property!
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkPropertyError, result.body)
        }
      case "failure":
        return result
    }
  }

  async destroyProperty({
    propertyId
  }: {
    readonly propertyId: number
  }): Promise<DestroyPropertyNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.DELETE,
      path: `${basePath}/${propertyId}`
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: undefined
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkPropertyError, result.body)
        }
      case "failure":
        return result
    }
  }
}

export type GetPropertiesNetworkResult =
  SuccessExecutionResult<NetworkPropertiesResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult

export type GetPropertyNetworkResult = GetObjectNetworkResult<NetworkProperty>

export type CreatePropertyNetworkResult = CreateObjectNetworkResult<NetworkProperty, NetworkPropertyError>

export type UpdatePropertyNetworkResult = UpdateObjectNetworkResult<NetworkProperty, NetworkPropertyError>

export type DestroyPropertyNetworkResult = DestroyObjectNetworkResult<NetworkPropertyError>
