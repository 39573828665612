import React from "react"
import DateTimeTableValue from "../../../entities/tables/table-value-by-type/DateTimeTableValue"

export default function DateTimeTableValueComponent({
  dateTimeTableValue
}: {
  readonly dateTimeTableValue: DateTimeTableValue
}) {
  return <span>{dateTimeTableValue.getValue()}</span>
}
