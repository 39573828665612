import DiModule from "../../../../../sqadmin/lib/di/DiModule"
import CoreDataDiModule from "../../../../core/di/modules/CoreDataDiModule"
import PlacesRepository from "../../domian/repositories/PlacesRepository"
import DefaultPlacesRepository from "../../data/DefaultPlacesRepository"

export default interface PlacesDataDiModule {
  providePlacesRepository(): PlacesRepository
}

export class DefaultPlacesDataDiModule
  extends DiModule
  implements PlacesDataDiModule {

  private readonly coreDataDiModule: CoreDataDiModule

  constructor(parameters: {
    readonly coreDataDiModule: CoreDataDiModule
  }) {
    super()
    this.coreDataDiModule = parameters.coreDataDiModule
  }

  providePlacesRepository(): PlacesRepository {
    return this.single(
      DefaultPlacesRepository.name,
      () => new DefaultPlacesRepository({
        placesNetworkSource: this.coreDataDiModule.providePlacesNetworkSource()
      })
    )
  }
}
