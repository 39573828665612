import { RouteObject } from "react-router-dom"
import CoreUrlProvider from "../../../core/presentation/services/CoreUrlProvider"
import BonusPointsBurningRulesDi from "../di/BonusPointsBurningRulesDi"

export default function createBonusPointsBurningRulesRoutes({
  di
}: {
  readonly di: BonusPointsBurningRulesDi
}): RouteObject[] {
  const urlProvider = new CoreUrlProvider()
  return [
    {
      path: urlProvider.buildBonusPointsBurningRulePath(),
      element: di
        .sqCoreBonusPointsBurningRulesDiComponent
        .bonusPointsBurningRulesPresentationDiModule
        .provideBonusPointsBurningRulePage()
    }
  ]
}
