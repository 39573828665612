import { RouteObject } from "react-router-dom"
import { objectIdUrlPart } from "../../../../sqadmin/features/objects/presentation/hooks/useObjectId"
import CoreUrlProvider from "../../../core/presentation/services/CoreUrlProvider"
import PaymentStatusesDi from "../di/PaymentStatusesDi"

export default function createPaymentStatusesRoutes({
  di
}: {
  readonly di: PaymentStatusesDi
}): RouteObject[] {
  const urlProvider = new CoreUrlProvider()
  return [
    {
      path: urlProvider.buildPaymentStatusesPath(),
      element: di
        .sqCorePaymentStatusesDiComponent
        .paymentStatusesPresentationDiModule
        .providePaymentStatusesPage()
    },
    {
      path: urlProvider.buildNewPaymentStatusPath(),
      element: di
        .sqCorePaymentStatusesDiComponent
        .paymentStatusesPresentationDiModule
        .providePaymentStatusPage()
    },
    {
      path: urlProvider.buildPaymentStatusPath({ id: `:${objectIdUrlPart}` }),
      element: di
        .sqCorePaymentStatusesDiComponent
        .paymentStatusesPresentationDiModule
        .providePaymentStatusPage()
    }
  ]
}
