import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../errors/NetworkAttributeError"
import AttributeError from "../../../../../sqadmin/core/domain/entities/AttributeError"
import BonusProgramLevelTransitionRuleErrorsObjectAttributes
  from "../../../domain/entities/bonus-program-level-transition-rules/BonusProgramLevelTransitionRuleErrorsObjectAttributes"

export default class NetworkBonusProgramLevelTransitionRuleErrorsObjectAttributes
  implements BonusProgramLevelTransitionRuleErrorsObjectAttributes {
  @Expose()
  @Type(() => NetworkAttributeError)
  readonly type: AttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly periodType: AttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly periodsCount: AttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly checkingValueType: AttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly checkingValue: AttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly productsSets: AttributeError[] | null | undefined
}
