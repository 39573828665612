import { FormFieldViewState } from "../../entities/form-fields/FormField"
import { StringFormFieldViewState } from "../../entities/form-fields/form-field-by-type/StringFormField"
import StringFormFieldComponent from "../form-field-by-type/string-form-field/StringFormFieldComponent"
import { TextFormFieldViewState } from "../../entities/form-fields/form-field-by-type/TextFormField"
import TextFormFieldComponent from "../form-field-by-type/text-form-field/TextFormFieldComponent"
import { SingleSelectFormFieldViewState } from "../../entities/form-fields/form-field-by-type/SingleSelectFormField"
import SingleSelectFormFieldComponent
  from "../form-field-by-type/single-select-form-field/SingleSelectFormFieldComponent"
import { MultiSelectFormFieldViewState } from "../../entities/form-fields/form-field-by-type/MultiSelectFormField"
import MultiSelectFormFieldComponent from "../form-field-by-type/multi-select-form-field/MultiSelectFormFieldComponent"
import { DecimalFormFieldViewState } from "../../entities/form-fields/form-field-by-type/DecimalFormField"
import DecimalFormFieldComponent from "../form-field-by-type/decimal-form-field/DecimalFormValueComponent"
import { DateFormFieldViewState } from "../../entities/form-fields/form-field-by-type/DateFormField"
import DateFormFieldComponent from "../form-field-by-type/date-form-field/DateFormFieldComponent"
import { DateRangeFormFieldViewState } from "../../entities/form-fields/form-field-by-type/DateRangeFormField"
import DateRangeFormFieldComponent from "../form-field-by-type/date-range-form-field/DateRangeFormFieldComponent"
import { NumberFormFieldViewState } from "../../entities/form-fields/form-field-by-type/NumberFormField"
import NumberFormFieldComponent from "../form-field-by-type/number-form-field/NumberFormFieldComponent"
import { BooleanFormFieldViewState } from "../../entities/form-fields/form-field-by-type/BooleanFormField"
import BooleanFormFieldComponent from "../form-field-by-type/boolean-form-field/BooleanFormFieldComponent"
import React from "react"
import styles from "./FilterFieldsComponent.module.scss"

export interface Props {
  readonly fieldViewStates: FormFieldViewState[]
  readonly isDisabled: boolean
}

export default function FilterFieldsComponent({
  fieldViewStates,
  isDisabled
}: Props) {
  return (
    <div className={styles.root}>
      {fieldViewStates
        .filter((fieldViewState: FormFieldViewState) => fieldViewState.isVisible())
        .map((fieldViewState: FormFieldViewState) => {
          const id: string = fieldViewState.getId()

          return (
            <div key={id} className={styles.field}>
              {(() => {
                if (fieldViewState instanceof StringFormFieldViewState) {
                  return (
                    <StringFormFieldComponent
                      fieldViewState={fieldViewState}
                      isDisabled={isDisabled || fieldViewState.isDisabled()}
                    />
                  )
                }

                if (fieldViewState instanceof TextFormFieldViewState) {
                  return (
                    <TextFormFieldComponent
                      fieldViewState={fieldViewState}
                      isDisabled={isDisabled}
                    />
                  )
                }

                if (fieldViewState instanceof SingleSelectFormFieldViewState) {
                  return (
                    <SingleSelectFormFieldComponent
                      fieldViewState={fieldViewState}
                      isDisabled={isDisabled || fieldViewState.isDisabled()}
                    />
                  )
                }

                if (fieldViewState instanceof MultiSelectFormFieldViewState) {
                  return (
                    <MultiSelectFormFieldComponent
                      fieldViewState={fieldViewState}
                      isDisabled={isDisabled || fieldViewState.isDisabled()}
                    />
                  )
                }

                if (fieldViewState instanceof DecimalFormFieldViewState) {
                  return (
                    <DecimalFormFieldComponent
                      fieldViewState={fieldViewState}
                      isDisabled={isDisabled || fieldViewState.isDisabled()}
                    />
                  )
                }

                if (fieldViewState instanceof DateFormFieldViewState) {
                  return (
                    <DateFormFieldComponent
                      fieldViewState={fieldViewState}
                      isDisabled={isDisabled || fieldViewState.isDisabled()}
                    />
                  )
                }

                if (fieldViewState instanceof DateRangeFormFieldViewState) {
                  return (
                    <DateRangeFormFieldComponent
                      fieldViewState={fieldViewState}
                      isDisabled={isDisabled}
                    />
                  )
                }

                if (fieldViewState instanceof NumberFormFieldViewState) {
                  return (
                    <NumberFormFieldComponent
                      fieldViewState={fieldViewState}
                      isDisabled={isDisabled || fieldViewState.isDisabled()}
                    />
                  )
                }

                if (fieldViewState instanceof BooleanFormFieldViewState) {
                  return (
                    <BooleanFormFieldComponent
                      fieldViewState={fieldViewState}
                      isDisabled={isDisabled || fieldViewState.isDisabled()}
                    />
                  )
                }

                throw "Unknown form field type"
              })()}
            </div>
          )
        })}
    </div>
  )
}
