import Settlement from "../../domain/entities/settlements/Settlement"
import NetworkSettlement from "../entities/settlements/NetworkSettlement"

export default class SettlementsMapper {
  mapNetworkToDomain({
    settlement
  }: {
    readonly settlement: NetworkSettlement
  }): Settlement {
    return {
      id: settlement.id,
      fullName: settlement.fullName
    }
  }

  mapDomainToNetwork({
    settlement
  }: {
    readonly settlement: Settlement
  }): NetworkSettlement {
    return new NetworkSettlement({
      id: settlement.id,
      fullName: settlement.fullName
    })
  }
}
