import React from "react"
import DiModule from "../../../../../sqadmin/lib/di/DiModule"
import ObjectsDomainDiModule from "../../../../../sqadmin/features/objects/di/modules/ObjectsDomainDiModule"
import CoreI18nDiModule from "../../../../core/di/modules/CoreI18nDiModule"
import autoBind from "auto-bind"
import ObjectsPresentationLogicParameters
  from "../../../../../sqadmin/features/objects/presentation/entities/objects/ObjectsPresentationLogicParameters"
import DiscountRulesViewModel from "../../presentation/pages/discount-rules-page/DiscountRulesViewModel"
import DiscountRulePage from "../../presentation/pages/discount-rule-page/DiscountRulePage"
import DiscountRulesPage from "../../presentation/pages/discount-rules-page/DiscountRulesPage"
import DiscountRuleViewModel from "../../presentation/pages/discount-rule-page/DiscountRuleViewModel"
import DiscountRulesCoreDomainDiModule from "../../../discount-rules-core/di/DiscountRulesCoreDomainDiModule"
import PropertyValuesCoreDomainDiModule from "../../../property-values-core/di/PropertyValuesCoreDomainDiModule"
import PropertiesCoreDomainDiModule from "../../../properties-core/di/PropertiesCoreDomainDiModule"
import ProductCategoriesCoreDomainDiModule
  from "../../../product-categories-core/di/ProductCategoriesCoreDomainDiModule"
import ProductsCoreDomainDiModule from "../../../products-core/di/ProductsCoreDomainDiModule"

export default interface DiscountRulesPresentationDiModule {
  provideDiscountRulesPage(): React.ReactNode

  provideDiscountRulePage(): React.ReactNode
}

export class DefaultDiscountRulesPresentationDiModule
  extends DiModule
  implements DiscountRulesPresentationDiModule {

  private readonly objectsDomainDiModule: ObjectsDomainDiModule
  private readonly discountRulesCoreDomainDiModule: DiscountRulesCoreDomainDiModule
  private readonly coreI18nDiModule: CoreI18nDiModule
  private readonly propertyValuesCoreDomainDiModule: PropertyValuesCoreDomainDiModule
  private readonly propertiesCoreDomainDiModule: PropertiesCoreDomainDiModule
  private readonly productCategoriesCoreDomainDiModule: ProductCategoriesCoreDomainDiModule
  private readonly productsCoreDomainDiModule: ProductsCoreDomainDiModule

  constructor(parameters: {
    readonly objectsDomainDiModule: ObjectsDomainDiModule
    readonly discountRulesCoreDomainDiModule: DiscountRulesCoreDomainDiModule
    readonly coreI18nDiModule: CoreI18nDiModule
    readonly propertyValuesCoreDomainDiModule: PropertyValuesCoreDomainDiModule
    readonly propertiesCoreDomainDiModule: PropertiesCoreDomainDiModule
    readonly productCategoriesCoreDomainDiModule: ProductCategoriesCoreDomainDiModule
    readonly productsCoreDomainDiModule: ProductsCoreDomainDiModule
  }) {
    super()
    autoBind(this)
    this.objectsDomainDiModule = parameters.objectsDomainDiModule
    this.discountRulesCoreDomainDiModule = parameters.discountRulesCoreDomainDiModule
    this.coreI18nDiModule = parameters.coreI18nDiModule
    this.propertyValuesCoreDomainDiModule = parameters.propertyValuesCoreDomainDiModule
    this.propertiesCoreDomainDiModule = parameters.propertiesCoreDomainDiModule
    this.productCategoriesCoreDomainDiModule = parameters.productCategoriesCoreDomainDiModule
    this.productsCoreDomainDiModule = parameters.productsCoreDomainDiModule
  }

  provideDiscountRulesPage(): React.ReactNode {
    return <DiscountRulesPage provideViewModel={this.provideDiscountRulesViewModel} />
  }

  provideDiscountRulePage(): React.ReactNode {
    return <DiscountRulePage provideViewModel={this.provideDiscountRuleViewModel} />
  }

  private provideDiscountRulesViewModel({
    objectsPresentationLogicParameters
  }: {
    readonly objectsPresentationLogicParameters: ObjectsPresentationLogicParameters
  }): DiscountRulesViewModel {
    return new DiscountRulesViewModel({
      coreI18n: this.coreI18nDiModule.provideCoreI18n(),
      objectsPresentationLogicParameters,
      subscribeToObjectsEventsUseCase: this.objectsDomainDiModule.provideSubscribeToObjectsEventsUseCase({
        key: "discountRules"
      }),
      unsubscribeFromObjectsEventsUseCase: this.objectsDomainDiModule.provideUnsubscribeFromObjectsEventsUseCase({
        key: "discountRules"
      }),
      getDiscountRulesUseCase: this.discountRulesCoreDomainDiModule
        .provideGetDiscountRulesUseCase()
    })
  }

  private provideDiscountRuleViewModel({
    discountRuleId
  }: {
    readonly discountRuleId?: number
  }): DiscountRuleViewModel {
    return new DiscountRuleViewModel({
      coreI18n: this.coreI18nDiModule.provideCoreI18n(),
      broadcastObjectsEventUseCase: this.objectsDomainDiModule.provideBroadcastObjectsEventUseCase({
        key: "discountRules"
      }),
      getDiscountRuleUseCase: this.discountRulesCoreDomainDiModule
        .provideGetDiscountRuleUseCase(),
      createDiscountRuleUseCase: this.discountRulesCoreDomainDiModule
        .provideCreateDiscountRuleUseCase(),
      updateDiscountRuleUseCase: this.discountRulesCoreDomainDiModule
        .provideUpdateDiscountRuleUseCase(),
      destroyDiscountRuleUseCase: this.discountRulesCoreDomainDiModule
        .provideDestroyDiscountRuleUseCase(),
      getProductCategoriesUseCase: this.productCategoriesCoreDomainDiModule.provideGetProductCategoriesUseCase(),
      getProductsUseCase: this.productsCoreDomainDiModule.provideGetProductsUseCase(),
      getPropertiesUseCase: this.propertiesCoreDomainDiModule.provideGetPropertiesUseCase(),
      getPropertyValuesUseCase: this.propertyValuesCoreDomainDiModule.provideGetPropertyValuesUseCase(),
      discountRuleId: discountRuleId
    })
  }
}
