import { Expose } from "class-transformer"
import NetworkOption from "./NetworkOption"

export default class NetworkOptionRequestBody {
  @Expose()
  readonly option: NetworkOption

  constructor(parameters: {
    readonly option: NetworkOption
  }) {
    this.option = parameters.option
  }
}
