import NetworkPaymentStatusesRequestFilter from "../entities/NetworkPaymentStatusesRequestFilter"
import NetworkLastItemPagination from "../../../../core/data/entities/pagination/NetworkLastItemPagination"
import BackendHttpClient, { BackendHttpClientResult } from "../../../../core/data/network/BackendHttpClient"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import { instanceToPlain, plainToInstance } from "class-transformer"
import NetworkPaymentStatusesRequestQuery from "../entities/NetworkPaymentStatusesRequestQuery"
import NetworkPaymentStatusesResponseBody from "../entities/NetworkPaymentStatusesResponseBody"
import NetworkExecutionError from "../../../../core/data/entities/errors/NetworkExecutionError"
import GetObjectNetworkResult from "../../../../core/data/results/GetObjectNetworkResult"
import NetworkPaymentStatus from "../../../../core/data/entities/payment-statuses/NetworkPaymentStatus"
import NetworkPaymentStatusResponseBody from "../entities/NetworkPaymentStatusResponseBody"
import CreateObjectNetworkResult from "../../../../core/data/results/CreateObjectNetworkResult"
import NetworkPaymentStatusError from "../../../../core/data/entities/payment-statuses/NetworkPaymentStatusError"
import NetworkPaymentStatusRequestBody from "../entities/NetworkPaymentStatusRequestBody"
import UpdateObjectNetworkResult from "../../../../core/data/results/UpdateObjectNetworkResult"
import DestroyObjectNetworkResult from "../../../../core/data/results/DestroyObjectNetworkResult"
import ErrorExecutionResult from "../../../../../sqadmin/core/domain/results/ErrorExecutionResult"
import FailureExecutionResult from "../../../../../sqadmin/core/domain/results/FailureExecutionResult"
import SuccessExecutionResult from "../../../../../sqadmin/core/domain/results/SuccessExecutionResult"

const basePath = "/admin/payment_statuses"

export default class PaymentStatusesNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async getPaymentStatuses({
    filter,
    pagination,
    sort
  }: {
    readonly filter?: NetworkPaymentStatusesRequestFilter | null
    readonly pagination?: NetworkLastItemPagination | null
    readonly sort?: string | null
  }): Promise<GetPaymentStatusesNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: basePath,
      parameters: instanceToPlain(new NetworkPaymentStatusesRequestQuery({
        filter,
        pagination,
        sort
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkPaymentStatusesResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async getPaymentStatus({
    paymentStatusId
  }: {
    readonly paymentStatusId: number
  }): Promise<GetObjectNetworkResult<NetworkPaymentStatus>> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}/${paymentStatusId}`
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkPaymentStatusResponseBody, result.body).paymentStatus!
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async createPaymentStatus({
    paymentStatus
  }: {
    readonly paymentStatus: NetworkPaymentStatus
  }): Promise<CreateObjectNetworkResult<NetworkPaymentStatus, NetworkPaymentStatusError>> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.POST,
      path: basePath,
      body: instanceToPlain(new NetworkPaymentStatusRequestBody({
        paymentStatus: paymentStatus
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkPaymentStatusResponseBody, result.body).paymentStatus!
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkPaymentStatusError, result.body)
        }
      case "failure":
        return result
    }
  }

  async updatePaymentStatus({
    paymentStatusId,
    paymentStatus
  }: {
    readonly paymentStatusId: number
    readonly paymentStatus: NetworkPaymentStatus
  }): Promise<UpdateObjectNetworkResult<NetworkPaymentStatus, NetworkPaymentStatusError>> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.PUT,
      path: `${basePath}/${paymentStatusId}`,
      body: instanceToPlain(new NetworkPaymentStatusRequestBody({
        paymentStatus: paymentStatus
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkPaymentStatusResponseBody, result.body).paymentStatus!
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkPaymentStatusError, result.body)
        }
      case "failure":
        return result
    }
  }

  async destroyPaymentStatus({
    paymentStatusId
  }: {
    readonly paymentStatusId: number
  }): Promise<DestroyObjectNetworkResult<NetworkPaymentStatusError>> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.DELETE,
      path: `${basePath}/${paymentStatusId}`
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: undefined
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkPaymentStatusError, result.body)
        }
      case "failure":
        return result
    }
  }
}

export type GetPaymentStatusesNetworkResult =
  SuccessExecutionResult<NetworkPaymentStatusesResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult
