import { DestroyObjectResult } from "../../../../../sqadmin/features/objects/domain/results/DestroyObjectResult"
import BonusProgramLevelsRepository from "../repositories/BonusProgramLevelsRepository"
import BonusProgramLevelError from "../../../../core/domain/entities/bonus-program-levels/BonusProgramLevelError"

export default class DestroyBonusProgramLevelUseCase {
  private readonly bonusProgramLevelsRepository: BonusProgramLevelsRepository

  constructor(parameters: {
    readonly bonusProgramLevelsRepository: BonusProgramLevelsRepository
  }) {
    this.bonusProgramLevelsRepository = parameters.bonusProgramLevelsRepository
  }

  async call(parameters: {
    readonly bonusProgramLevelId: number
  }): Promise<DestroyObjectResult<BonusProgramLevelError>> {
    return await this.bonusProgramLevelsRepository.destroyBonusProgramLevel(parameters)
  }
}
