import { useNumberObjectId } from "../../../../../../sqadmin/features/objects/presentation/hooks/useObjectId"
import { useViewModel } from "../../../../../../sqadmin/lib/view-model/ViewModel"
import ObjectComponent from "../../../../../../sqadmin/features/objects/presentation/components/object/ObjectComponent"
import React from "react"
import ProductCategoryViewModel from "./ProductCategoryViewModel"
import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import { useCoreTextProvider } from "../../../../../core/presentation/contexts/CoreTextProviderContext"

export interface ProductCategoryPageProps {
  readonly provideViewModel: (parameters: { readonly productCategoryId?: number }) => ProductCategoryViewModel
}

export default function ProductCategoryPage({
  provideViewModel
}: ProductCategoryPageProps) {
  const coreTextProvider: CoreTextProvider = useCoreTextProvider()
  const productCategoryId: number | undefined = useNumberObjectId()

  const viewModel: ProductCategoryViewModel = useViewModel(() => provideViewModel({
    productCategoryId
  }))

  return (
    <ObjectComponent
      newObjectTitle={coreTextProvider.newProductCategory()}
      existedObjectTitle={coreTextProvider.productCategory()}
      observableObjectViewState={viewModel.observableObjectViewState}
      onObjectViewEvent={viewModel.onViewObjectEvent}
    />
  )
}
