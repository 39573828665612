import { GetObjectsPageResult } from "../../../../../sqadmin/features/objects/domain/results/GetObjectsPageResult"
import LastItemPaginationsMapper from "../../../../core/data/mappers/LastItemPaginationsMapper"
import SortMapper from "../../../../core/data/mappers/SortMapper"
import PagesMapper from "../../../../core/data/mappers/PagesMapper"
import { LoadObjectResult } from "../../../../../sqadmin/features/objects/domain/results/LoadObjectResult"
import GetObjectNetworkResult from "../../../../core/data/results/GetObjectNetworkResult"
import { CreateObjectResult } from "../../../../../sqadmin/features/objects/domain/results/CreateObjectResult"
import CreateObjectNetworkResult from "../../../../core/data/results/CreateObjectNetworkResult"
import { UpdateObjectResult } from "../../../../../sqadmin/features/objects/domain/results/UpdateObjectResult"
import UpdateObjectNetworkResult from "../../../../core/data/results/UpdateObjectNetworkResult"
import { DestroyObjectResult } from "../../../../../sqadmin/features/objects/domain/results/DestroyObjectResult"
import DestroyObjectNetworkResult from "../../../../core/data/results/DestroyObjectNetworkResult"
import BonusProgramLevelsRepository from "../../domain/repositories/BonusProgramLevelsRepository"
import BonusProgramLevelsNetworkSource, {
  GetBonusProgramLevelsNetworkResult
} from "../sources/BonusProgramLevelsNetworkSource"
import BonusProgramLevel from "../../../../core/domain/entities/bonus-program-levels/BonusProgramLevel"
import NetworkBonusProgramLevel from "../../../../core/data/entities/bonus-program-levels/NetworkBonusProgramLevel"
import BonusProgramLevelError from "../../../../core/domain/entities/bonus-program-levels/BonusProgramLevelError"
import NetworkBonusProgramLevelError
  from "../../../../core/data/entities/bonus-program-levels/NetworkBonusProgramLevelError"
import { GetBonusProgramLevelsParameters } from "../../domain/use-cases/GetBonusProgramLevelsUseCase"
import BonusProgramLevelFiltersMapper from "../mappers/BonusProgramLevelFiltersMapper"
import BonusProgramLevelsMapper from "../../../../core/data/mappers/BonusProgramLevelsMapper"

export default class DefaultBonusProgramLevelsRepository implements BonusProgramLevelsRepository {
  private readonly bonusProgramLevelsNetworkSource: BonusProgramLevelsNetworkSource

  constructor(parameters: {
    readonly bonusProgramLevelsNetworkSource: BonusProgramLevelsNetworkSource
  }) {
    this.bonusProgramLevelsNetworkSource = parameters.bonusProgramLevelsNetworkSource
  }

  async getBonusProgramLevels({
    filter,
    sort,
    pagination
  }: GetBonusProgramLevelsParameters): Promise<GetObjectsPageResult<BonusProgramLevel>> {
    const result: GetBonusProgramLevelsNetworkResult = await this.bonusProgramLevelsNetworkSource
      .getBonusProgramLevels({
        filter: filter && new BonusProgramLevelFiltersMapper().mapDomainToNetwork({ filter }),
        pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({ pagination }),
        sort: sort && new SortMapper().mapDomainToNetwork({ sort })
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: {
            objects: result.data.bonusProgramLevels!.map((networkBonusProgramLevel: NetworkBonusProgramLevel) => {
              return new BonusProgramLevelsMapper().mapNetworkToDomain({
                bonusProgramLevel: networkBonusProgramLevel
              })
            }),
            page: new PagesMapper().mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      default:
        return result
    }
  }

  async getBonusProgramLevel(parameters: {
    readonly bonusProgramLevelId: number
  }): Promise<LoadObjectResult<BonusProgramLevel>> {
    const result: GetObjectNetworkResult<NetworkBonusProgramLevel> = await this.bonusProgramLevelsNetworkSource
      .getBonusProgramLevel(parameters)

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new BonusProgramLevelsMapper().mapNetworkToDomain({
            bonusProgramLevel: result.data
          })
        }
      default:
        return result
    }
  }

  async createBonusProgramLevel({
    bonusProgramLevel
  }: {
    readonly bonusProgramLevel: BonusProgramLevel
  }): Promise<CreateObjectResult<BonusProgramLevel, BonusProgramLevelError>> {
    const result: CreateObjectNetworkResult<NetworkBonusProgramLevel, NetworkBonusProgramLevelError> =
      await this.bonusProgramLevelsNetworkSource.createBonusProgramLevel({
        bonusProgramLevel: new BonusProgramLevelsMapper().mapDomainToNetwork({
          bonusProgramLevel
        })
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new BonusProgramLevelsMapper().mapNetworkToDomain({
            bonusProgramLevel: result.data
          })
        }
      default:
        return result
    }
  }

  async updateBonusProgramLevel({
    bonusProgramLevelId,
    bonusProgramLevel
  }: {
    readonly bonusProgramLevelId: number
    readonly bonusProgramLevel: BonusProgramLevel
  }): Promise<UpdateObjectResult<BonusProgramLevel, BonusProgramLevelError>> {
    const result: UpdateObjectNetworkResult<NetworkBonusProgramLevel, NetworkBonusProgramLevelError> =
      await this.bonusProgramLevelsNetworkSource.updateBonusProgramLevel({
        bonusProgramLevelId,
        bonusProgramLevel: new BonusProgramLevelsMapper().mapDomainToNetwork({
          bonusProgramLevel
        })
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new BonusProgramLevelsMapper().mapNetworkToDomain({
            bonusProgramLevel: result.data
          })
        }
      default:
        return result
    }
  }

  async destroyBonusProgramLevel({
    bonusProgramLevelId
  }: {
    readonly bonusProgramLevelId: number
  }): Promise<DestroyObjectResult<BonusProgramLevelError>> {
    const result: DestroyObjectNetworkResult<NetworkBonusProgramLevelError> =
      await this.bonusProgramLevelsNetworkSource.destroyBonusProgramLevel({
        bonusProgramLevelId
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: undefined
        }
      default:
        return result
    }
  }
}
