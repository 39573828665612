import DiModule from "../../../../sqadmin/lib/di/DiModule"
import CreatePaymentStatusUseCase from "../domain/use-cases/CreatePaymentStatusUseCase"
import DestroyPaymentStatusUseCase from "../domain/use-cases/DestroyPaymentStatusUseCase"
import GetPaymentStatusesUseCase from "../domain/use-cases/GetPaymentStatusesUseCase"
import GetPaymentStatusUseCase from "../domain/use-cases/GetPaymentStatusUseCase"
import UpdatePaymentStatusUseCase from "../domain/use-cases/UpdatePaymentStatusUseCase"
import PaymentStatusesCoreDataDiModule from "./PaymentStatusesCoreDataDiModule"

export default interface PaymentStatusesCoreDomainDiModule {
  provideCreatePaymentStatusUseCase(): CreatePaymentStatusUseCase

  provideDestroyPaymentStatusUseCase(): DestroyPaymentStatusUseCase

  provideGetPaymentStatusesUseCase(): GetPaymentStatusesUseCase

  provideGetPaymentStatusUseCase(): GetPaymentStatusUseCase

  provideUpdatePaymentStatusUseCase(): UpdatePaymentStatusUseCase
}

export class DefaultPaymentStatusesCoreDomainDiModule extends DiModule
  implements PaymentStatusesCoreDomainDiModule {
  private readonly paymentStatusesCoreDataDiModule: PaymentStatusesCoreDataDiModule

  constructor(parameters: {
    readonly paymentStatusesCoreDataDiModule: PaymentStatusesCoreDataDiModule
  }) {
    super()
    this.paymentStatusesCoreDataDiModule = parameters.paymentStatusesCoreDataDiModule
  }

  provideCreatePaymentStatusUseCase(): CreatePaymentStatusUseCase {
    return this.single(
      CreatePaymentStatusUseCase.name,
      () => new CreatePaymentStatusUseCase({
        paymentStatusesRepository: this.paymentStatusesCoreDataDiModule.providePaymentStatusesRepository()
      })
    )
  }

  provideDestroyPaymentStatusUseCase(): DestroyPaymentStatusUseCase {
    return this.single(
      DestroyPaymentStatusUseCase.name,
      () => new DestroyPaymentStatusUseCase({
        paymentStatusesRepository: this.paymentStatusesCoreDataDiModule.providePaymentStatusesRepository()
      })
    )
  }

  provideGetPaymentStatusesUseCase(): GetPaymentStatusesUseCase {
    return this.single(
      GetPaymentStatusesUseCase.name,
      () => new GetPaymentStatusesUseCase({
        paymentStatusesRepository: this.paymentStatusesCoreDataDiModule.providePaymentStatusesRepository()
      })
    )
  }

  provideGetPaymentStatusUseCase(): GetPaymentStatusUseCase {
    return this.single(
      GetPaymentStatusUseCase.name,
      () => new GetPaymentStatusUseCase({
        paymentStatusesRepository: this.paymentStatusesCoreDataDiModule.providePaymentStatusesRepository()
      })
    )
  }

  provideUpdatePaymentStatusUseCase(): UpdatePaymentStatusUseCase {
    return this.single(
      UpdatePaymentStatusUseCase.name,
      () => new UpdatePaymentStatusUseCase({
        paymentStatusesRepository: this.paymentStatusesCoreDataDiModule.providePaymentStatusesRepository()
      })
    )
  }
}
