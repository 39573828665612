import ObjectsRepository from "../../repositories/ObjectsRepository"
import { ObjectsEventCallback } from "../../entities/ObjectsEvent"

export default class UnsubscribeFromObjectsEventsUseCase {
  private readonly objectsRepository: ObjectsRepository

  constructor(parameters: {
    readonly objectsRepository: ObjectsRepository
  }) {
    this.objectsRepository = parameters.objectsRepository
  }

  call(callback: ObjectsEventCallback): void {
    this.objectsRepository.unsubscribeFromObjectsEvents(callback)
  }
}
