import { Expose } from "class-transformer"
import NetworkProperty from "../../../../core/data/entities/properties/NetworkProperty"

export default class NetworkPropertyRequestBody {
  @Expose()
  readonly property: NetworkProperty

  constructor(parameters: {
    readonly property: NetworkProperty
  }) {
    this.property = parameters.property
  }
}
