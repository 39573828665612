import { CreateObjectResult } from "../../../../../sqadmin/features/objects/domain/results/CreateObjectResult"
import BonusProgramLevelsRepository from "../repositories/BonusProgramLevelsRepository"
import BonusProgramLevel from "../../../../core/domain/entities/bonus-program-levels/BonusProgramLevel"
import BonusProgramLevelError from "../../../../core/domain/entities/bonus-program-levels/BonusProgramLevelError"

export default class CreateBonusProgramLevelUseCase {
  private readonly bonusProgramLevelsRepository: BonusProgramLevelsRepository

  constructor(parameters: {
    readonly bonusProgramLevelsRepository: BonusProgramLevelsRepository
  }) {
    this.bonusProgramLevelsRepository = parameters.bonusProgramLevelsRepository
  }

  async call({
    bonusProgramLevel
  }: {
    readonly bonusProgramLevel: BonusProgramLevel
  }): Promise<CreateObjectResult<BonusProgramLevel, BonusProgramLevelError>> {
    return await this.bonusProgramLevelsRepository.createBonusProgramLevel({ bonusProgramLevel })
  }
}
