import VerticalSpacingComponent
  from "../../../../../core/presentation/components/vertical-spacing/VerticalSpacingComponent"
import HeadingComponent from "../../../../../core/presentation/components/heading/HeadingComponent"
import ContentLoadingComponent
  from "../../../../../core/presentation/components/content-loading/ContentLoadingComponent"
import ContentLoadingErrorComponent
  from "../../../../../core/presentation/components/content-loading-error/ContentLoadingErrorComponent"
import ContentLoadingFailureComponent
  from "../../../../../core/presentation/components/content-loading-failure/ContentLoadingFailureComponent"
import assertNever from "../../../../../lib/assertNever"
import React from "react"
import { ObjectViewState } from "../../view-states/ObjectViewState"
import { Navigate, NavigateFunction, useNavigate } from "react-router-dom"
import ObjectViewEvent from "../../view-events/ObjectViewEvent"
import styles from "./ObjectComponent.module.scss"
import FormFieldsComponent from "../form-fields/FormFieldsComponent"
import FormMenuComponent from "../form-menu/FormMenuComponent"
import ExceptionLocalizer from "../../../../../core/presentation/services/ExceptionLocalizer"
import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import { useCoreTextProvider } from "../../../../../core/presentation/contexts/CoreTextProviderContext"
import { StateObservable, useStateObservable } from "../../../../../lib/view-model/StateObservable"
import TextComponent, { TextStyle } from "../../../../../design/text/TextComponent"
import { FormFieldViewState } from "../../entities/form-fields/FormField"
import {
  ListFormFieldViewState,
  ObjectFieldViewState
} from "../../entities/form-fields/form-field-by-type/ListFormField"
import isPresent from "../../../../../lib/isPresent"
import FormFieldPlaceType from "../../entities/form-fields/FormFieldPlaceType"
import isBlank from "../../../../../lib/isBlank"
import ButtonComponent, { ButtonStyle } from "../../../../../design/button/ButtonComponent"
import RightModalWindowComponent from "../modal-windows/right-modal-window/RightModalWindowComponent"

export interface ObjectPageComponentProps {
  readonly newObjectTitle?: string
  readonly existedObjectTitle?: string
  readonly observableObjectViewState: StateObservable<ObjectViewState>
  readonly onObjectViewEvent: (objectViewEvent: ObjectViewEvent) => void
}

export default function ObjectComponent({
  newObjectTitle,
  existedObjectTitle,
  observableObjectViewState,
  onObjectViewEvent
}: ObjectPageComponentProps) {
  const navigate: NavigateFunction = useNavigate()
  const coreTextProvider: CoreTextProvider = useCoreTextProvider()
  const objectViewState: ObjectViewState = useStateObservable(observableObjectViewState)

  if (objectViewState.type === "initial") {
    return <></>
  }

  function handleOnCreateObjectClicked() {
    onObjectViewEvent({ type: "on_create_clicked" })
  }

  function handleOnCreateAndCloseObjectClicked() {
    onObjectViewEvent({ type: "on_create_and_close_clicked" })
  }

  function handleOnUpdateObjectClicked() {
    onObjectViewEvent({ type: "on_update_clicked" })
  }

  function handleOnUpdateAndCloseObjectClicked() {
    onObjectViewEvent({ type: "on_update_and_close_clicked" })
  }

  function handleOnDeleteObjectClicked() {
    onObjectViewEvent({ type: "on_delete_clicked" })
  }

  function handleRetryLoadingObjectClicked() {
    onObjectViewEvent({ type: "on_retry_loading_clicked" })
  }

  function findEditingObjectFieldViewStates(fieldViewStates: FormFieldViewState[]) {
    const objectFieldViewStates: ObjectFieldViewState<unknown>[] = []
    let objectFieldViewState = findEditingObjectFieldViewStateByFieldViewStates(fieldViewStates)

    if (isBlank(objectFieldViewState)) return objectFieldViewStates

    objectFieldViewStates.push(objectFieldViewState)

    while (isPresent(objectFieldViewState)) {
      objectFieldViewState = findEditingObjectFieldViewStateByFieldViewStates(objectFieldViewState.fieldViewStates)

      if (isPresent(objectFieldViewState)) objectFieldViewStates.push(objectFieldViewState)
    }

    return objectFieldViewStates
  }

  function findEditingObjectFieldViewStateByFieldViewStates(fieldViewStates: FormFieldViewState[]) {
    for (const fieldViewState of fieldViewStates) {
      if (fieldViewState.isVisible() && fieldViewState instanceof ListFormFieldViewState) {
        const objectFieldViewState =
          findEditingObjectFieldViewStateByObjectFieldViewStates(fieldViewState.getObjectFieldViewStates())

        if (isPresent(objectFieldViewState)) {
          return objectFieldViewState
        }
      }
    }

    return null
  }

  function findEditingObjectFieldViewStateByObjectFieldViewStates(
    objectFieldViewStates: ObjectFieldViewState<unknown>[]
  ): ObjectFieldViewState<unknown> | null {
    for (const objectFieldViewState of objectFieldViewStates) {
      if (objectFieldViewState.editingId === objectFieldViewState.id) {
        return objectFieldViewState
      }

      const fieldViewStates = objectFieldViewState.fieldViewStates
      if (isPresent(fieldViewStates)) {
        const foundObjectFieldViewState = findEditingObjectFieldViewStateByFieldViewStates(fieldViewStates)
        if (isPresent(foundObjectFieldViewState)) return foundObjectFieldViewState
      }
    }

    return null
  }

  const formErrorMessage: string | null | undefined = (() => {
    if (objectViewState.type !== "loaded") {
      return
    }

    if (objectViewState.changingError !== undefined) {
      return objectViewState.changingError.message
    }

    if (objectViewState.changingException !== undefined) {
      return new ExceptionLocalizer({ coreTextProvider })
        .localizeException(objectViewState.changingException)
    }

    return undefined
  })()

  return (
    <>
      <div className={styles.pageHeader}>
        <VerticalSpacingComponent />
        <div className={styles.row}>
          <HeadingComponent>
            <TextComponent textStyle={TextStyle.HEADER1_PRIMARY}>
              {objectViewState.isNewObject ? newObjectTitle : existedObjectTitle}
            </TextComponent>
          </HeadingComponent>
        </div>
        <VerticalSpacingComponent />
      </div>
      {(() => {
        switch (objectViewState.type) {
          case "loading":
            return (
              <div className={styles.root}>
                <div className={styles.loading}>
                  <ContentLoadingComponent />
                </div>
              </div>
            )
          case "loading_error":
            return (
              <div className={styles.root}>
                <div className={styles.error}>
                  <ContentLoadingErrorComponent
                    error={objectViewState.error}
                    onRetryClick={handleRetryLoadingObjectClicked}
                  />
                </div>
              </div>
            )
          case "loading_failure":
            return (
              <div className={`${styles.root} ${styles.error}`}>
                <div className={styles.error}>
                  <ContentLoadingFailureComponent
                    exception={objectViewState.exception}
                    onRetryClick={handleRetryLoadingObjectClicked}
                  />
                </div>
              </div>
            )
          case "loaded":
          case "creating":
          case "updating":
          case "destroying": {
            const editingObjectFieldViewStates: ObjectFieldViewState<unknown>[] =
              findEditingObjectFieldViewStates(objectViewState.fieldViewStates)

            const isDisabled = objectViewState.type !== "loaded"

            return (
              <>
                <div
                  className={[styles.root, isPresent(editingObjectFieldViewStates) ? styles.editMode : ""].join(" ")}
                >
                  <FormFieldsComponent
                    fieldViewStates={objectViewState.fieldViewStates}
                    isDisabled={isDisabled}
                  />
                  {editingObjectFieldViewStates.map((editingObjectFieldViewState, index) => {
                    return (
                      <RightModalWindowComponent
                        key={editingObjectFieldViewState.id}
                        isVisible={true}
                        onCloseRequested={editingObjectFieldViewState.onEditingCanceled}
                      >
                        <div
                          className={[
                            styles.additionalForm,
                            index === 0 ?
                              styles.additionalFormContentWidthLevel0 : styles.additionalFormContentWidthLevel1
                          ].join(" ")}
                        >
                          <div className={styles.additionalFormContent}>
                            <FormFieldsComponent
                              fieldViewStates={editingObjectFieldViewStates[index].fieldViewStates
                                .filter(value => value.getPlaceType() === FormFieldPlaceType.ADDITIONAL)}
                              isDisabled={isDisabled}
                            />
                          </div>
                          <div className={styles.additionalFormFooter}>
                            <ButtonComponent
                              text={coreTextProvider.close()}
                              buttonStyle={ButtonStyle.FILLED1_PRIMARY}
                              isDisabled={isDisabled}
                              onClick={editingObjectFieldViewState.onEditingCanceled}
                            />
                          </div>
                        </div>
                      </RightModalWindowComponent>
                    )
                  })}
                </div>
                <div className={styles.footer}>
                  <FormMenuComponent
                    objectViewState={objectViewState}
                    formErrorMessage={formErrorMessage}
                    onCreateObjectClicked={handleOnCreateObjectClicked}
                    onCreateObjectAndCloseClicked={handleOnCreateAndCloseObjectClicked}
                    onUpdateObjectClicked={handleOnUpdateObjectClicked}
                    onUpdateObjectAndCloseClicked={handleOnUpdateAndCloseObjectClicked}
                    onDeleteObjectClicked={handleOnDeleteObjectClicked}
                  />
                </div>
              </>
            )
          }
          case "list":
            navigate(-1)
            return <></>
          case "created":
            return (
              <Navigate
                to={objectViewState.url}
                replace
              />
            )
          default:
            assertNever(objectViewState)
        }
      })()}
    </>
  )
}
