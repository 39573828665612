import { GetObjectsPageResult } from "../../../../../sqadmin/features/objects/domain/results/GetObjectsPageResult"
import Sort from "../../../../../sqadmin/features/objects/presentation/entities/tables/Sort"
import LastItemPagination from "../../../../core/domain/entities/paginations/LastItemPagination"
import UsersRepository from "../repositories/UsersRepository"
import User from "../../../../core/domain/entities/users/User"
import UsersFilter from "../../../../core/domain/entities/users/UsersFilter"

export default class GetUsersUseCase {
  private readonly usersRepository: UsersRepository

  constructor(parameters: {
    readonly usersRepository: UsersRepository
  }) {
    this.usersRepository = parameters.usersRepository
  }

  async call(parameters: GetUsersParameters): Promise<GetObjectsPageResult<User>> {
    return await this.usersRepository.getUsers(parameters)
  }
}

export interface GetUsersParameters {
  readonly filter?: UsersFilter
  readonly sort?: Sort
  readonly pagination?: LastItemPagination
}
