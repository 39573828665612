import { LoadObjectResult } from "../../../../../sqadmin/features/objects/domain/results/LoadObjectResult"
import OrderProcessingStatus from "../../../../core/domain/entities/order-processing-statuses/OrderProcessingStatus"
import OrderProcessingStatusesRepository from "../repositories/OrderProcessingStatusesRepository"

export default class GetOrderProcessingStatusUseCase {
  private readonly orderProcessingStatusesRepository: OrderProcessingStatusesRepository

  constructor(parameters: {
    readonly orderProcessingStatusesRepository: OrderProcessingStatusesRepository
  }) {
    this.orderProcessingStatusesRepository = parameters.orderProcessingStatusesRepository
  }

  async call(parameters: {
    readonly orderProcessingStatusId: number
  }): Promise<LoadObjectResult<OrderProcessingStatus>> {
    return await this.orderProcessingStatusesRepository.getOrderProcessingStatus(parameters)
  }
}
