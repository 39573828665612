import DiModule from "../../../../sqadmin/lib/di/DiModule"
import OptionValuesCoreDataDiModule from "./DefaultOptionValuesCoreDataDiModule"
import GetOptionValuesUseCase from "../domain/use-cases/GetOptionValuesUseCase"

export default interface OptionValuesCoreDomainDiModule {
  provideGetOptionValuesUseCase(): GetOptionValuesUseCase
}

export class DefaultOptionValuesCoreDomainDiModule extends DiModule implements OptionValuesCoreDomainDiModule {
  private readonly optionValuesCoreDataDiModule: OptionValuesCoreDataDiModule

  constructor(parameters: {
    readonly optionValuesCoreDataDiModule: OptionValuesCoreDataDiModule
  }) {
    super()
    this.optionValuesCoreDataDiModule = parameters.optionValuesCoreDataDiModule
  }

  provideGetOptionValuesUseCase(): GetOptionValuesUseCase {
    return this.single(
      GetOptionValuesUseCase.name,
      () => new GetOptionValuesUseCase({
        optionValuesRepository: this.optionValuesCoreDataDiModule.provideOptionValuesRepository()
      })
    )
  }
}
