import { Expose } from "class-transformer"
import HeaderContentType from "../../../domain/entities/header/HeaderContentType"

export default class NetworkHeader {
  @Expose()
  readonly leftContentType: HeaderContentType | null | undefined

  @Expose()
  readonly rightContentType: HeaderContentType | null | undefined

  @Expose()
  readonly centerContentType: HeaderContentType | null | undefined

  constructor(parameters?: {
    readonly leftContentType: HeaderContentType | null | undefined
    readonly rightContentType: HeaderContentType | null | undefined
    readonly centerContentType: HeaderContentType | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.leftContentType = parameters.leftContentType
    this.rightContentType = parameters.rightContentType
    this.centerContentType = parameters.centerContentType
  }
}
