import { Expose, Transform, Type } from "class-transformer"
import transformDecimal from "../../network/transformers/transformDecimal"
import { Decimal } from "decimal.js"
import NetworkBatchPlace from "../batch-places/NetworkBatchPlace"
import NetworkProductVariant from "../product-variants/NetworkProductVariant"

export default class NetworkBatch {
  @Expose()
  readonly id: number | null | undefined

  @Expose()
  readonly externalCode: string | null | undefined

  @Expose()
  @Transform(transformDecimal)
  readonly price: Decimal | null | undefined

  @Expose()
  @Transform(transformDecimal)
  readonly originalPrice: Decimal | null | undefined

  @Expose()
  readonly bonusPointsPrice: number | null | undefined

  @Expose()
  readonly clientId: string | null | undefined

  @Expose()
  @Type(() => NetworkBatchPlace)
  readonly batchPlaces: NetworkBatchPlace[] | null | undefined

  @Expose({ name: "productVariant" })
  @Type(() => NetworkProductVariant)
  readonly variant: NetworkProductVariant | null | undefined

  constructor(parameters?: {
    readonly id?: number | null | undefined
    readonly price?: Decimal | null | undefined
    readonly originalPrice?: Decimal | null | undefined
    readonly clientId?: string | null | undefined
    readonly externalCode?: string | null | undefined
    readonly batchPlaces: NetworkBatchPlace[] | null | undefined
    readonly variant: NetworkProductVariant | null | undefined
    readonly bonusPointsPrice: number | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.id = parameters.id
    this.externalCode = parameters.externalCode
    this.price = parameters.price
    this.originalPrice = parameters.originalPrice
    this.clientId = parameters.clientId
    this.batchPlaces = parameters.batchPlaces
    this.variant = parameters.variant
    this.bonusPointsPrice = parameters.bonusPointsPrice
  }
}
