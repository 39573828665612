import { Expose, Type } from "class-transformer"
import NetworkLastItemPagination from "../pagination/NetworkLastItemPagination"
import NetworkPlacesRequestFilter from "./NetworkPlacesRequestFilter"

export default class NetworkPlacesRequestQuery {
  @Expose()
  @Type(() => NetworkPlacesRequestFilter)
  readonly filter?: NetworkPlacesRequestFilter | null

  @Expose()
  @Type(() => NetworkLastItemPagination)
  readonly pagination?: NetworkLastItemPagination | null

  @Expose()
  readonly sort?: string | null

  constructor(parameters: {
    readonly filter?: NetworkPlacesRequestFilter | null
    readonly pagination?: NetworkLastItemPagination | null
    readonly sort?: string | null
  }) {
    this.filter = parameters.filter
    this.pagination = parameters.pagination
    this.sort = parameters.sort
  }
}
