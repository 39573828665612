import { Expose, Type } from "class-transformer"
import NetworkDashboardErrorsObjectAttributes from "./NetworkDashboardErrorsObjectAttributes"
import DashboardErrorsObject from "../../../domain/entities/dashboards/DashboardErrorsObject"
import NetworkBlockErrorsObject from "../blocks/NetworkBlockErrorsObject"

export default class NetworkDashboardErrorsObject implements DashboardErrorsObject {
  @Expose()
  @Type(() => NetworkDashboardErrorsObjectAttributes)
  readonly attributes: NetworkDashboardErrorsObjectAttributes | null | undefined

  @Expose()
  @Type(() => NetworkBlockErrorsObject)
  readonly blocks: NetworkBlockErrorsObject[] | null | undefined
}
