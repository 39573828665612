import DiModule from "../../../../sqadmin/lib/di/DiModule"
import GetSettlementsUseCase from "../domain/use-cases/GetSettlementsUseCase"
import SettlementsCoreDataDiModule from "./SettlementsCoreDataDiModule"

export default interface SettlementsCoreDomainDiModule {
  provideGetSettlementsUseCase(): GetSettlementsUseCase
}

export class DefaultSettlementsCoreDomainDiModule extends DiModule
  implements SettlementsCoreDomainDiModule {
  private readonly settlementsCoreDataDiModule: SettlementsCoreDataDiModule

  constructor(parameters: {
    readonly settlementsCoreDataDiModule: SettlementsCoreDataDiModule
  }) {
    super()
    this.settlementsCoreDataDiModule = parameters.settlementsCoreDataDiModule
  }

  provideGetSettlementsUseCase(): GetSettlementsUseCase {
    return this.single(
      GetSettlementsUseCase.name,
      () => new GetSettlementsUseCase({
        settlementsRepository: this.settlementsCoreDataDiModule.provideSettlementsRepository()
      })
    )
  }
}
