import NetworkOptionValuesRequestFilter from "../entities/NetworkOptionValuesRequestFilter"

export default class OptionValuesFiltersMapper {
  mapDomainToNetwork({
    query
  }: {
    readonly query: string | undefined | null
  }): NetworkOptionValuesRequestFilter {
    return {
      query: query
    }
  }
}
