import React, { useEffect, useRef, useState } from "react"
import ExtendedDate from "../extended-date/ExtendedDate"
import DropdownComponent from "../../core/presentation/components/dropdown/DropdownComponent"
import CalendarBorderComponent from "../calendar-border/CalendarBorderComponent"
import CalendarDropdownComponent from "../calendar-dropdown/CalendarDropdownComponent"
import IMask from "imask"
import styles from "./DatePickerComponent.module.scss"
import { SingleDateCalendarComponent } from "../single-calendar/SingleDateCalendarComponent"

export default function DatePickerComponent({
  value,
  onChange,
  weekDayNameByNumber,
  monthNameByNumber,
  disabled,
  selectButtonText,
  clearButtonText
}: {
  readonly value: Date | null
  readonly onChange: (date: Date | null) => void
  readonly weekDayNameByNumber: { [_: number]: string }
  readonly monthNameByNumber: { [_: number]: string }
  readonly disabled?: boolean
  readonly selectButtonText: string
  readonly clearButtonText: string
}) {
  const dateInputRef: React.MutableRefObject<HTMLInputElement | null> = useRef(null)
  const dateInputMaskRef: React.MutableRefObject<IMask.InputMask<{ mask: DateConstructor }> | null> = useRef(null)
  const [isCalendarVisible, setIsCalendarVisible] = useState(false)
  const formattedDate: string | null = value && new ExtendedDate(value).formatDate()

  useEffect(() => {
    if (disabled && isCalendarVisible) {
      setIsCalendarVisible(false)
    }
  }, [disabled])

  useEffect(() => {
    dateInputMaskRef.current = IMask(dateInputRef.current!, {
      mask: Date,
      lazy: true
    })
  }, [])

  useEffect(() => {
    const dateInputMask = dateInputMaskRef.current!
    dateInputMask.value = formattedDate ?? ""
    const onComplete = () => hideCalendarAndChangeDate(dateInputMask.typedValue)
    dateInputMask.on("complete", onComplete)

    return () => {
      dateInputMask.off("complete", onComplete)
    }
  }, [value])

  function handleOnInputChange() {
    if (dateInputMaskRef.current!.masked.isComplete) return

    if (!isCalendarVisible) {
      setIsCalendarVisible(true)
    }
  }

  function handleDropdownRequestHide() {
    dateInputMaskRef.current!.value = formattedDate ?? ""
    hideCalendarAndChangeDate(value)
  }

  function hideCalendarAndChangeDate(date: Date | null) {
    setIsCalendarVisible(false)
    onDataChanged(date)
  }

  function handleClear() {
    setIsCalendarVisible(false)
    onDataChanged(null)
  }

  function onDataChanged(date: Date | null) {
    if (value === date) return
    onChange(date)
  }

  return (
    <div className={styles.root}>
      <div className={styles.changeDateContainer}>
        <div className={styles.inputContainer}>
          <input
            className={styles.input}
            placeholder={selectButtonText}
            ref={dateInputRef}
            onChange={handleOnInputChange}
            disabled={disabled}
            onClick={() => !disabled && setIsCalendarVisible(!isCalendarVisible)}
          />
          <div className={`${styles.calendarIcon} ${isCalendarVisible ? styles.active : styles.inactive}`} />
        </div>
        <button
          className={styles.clearButton}
          onClick={handleClear}
          disabled={disabled}
        >
          {clearButtonText}
        </button>
      </div>
      <DropdownComponent
        needSetWidthByParent={false}
        isVisible={isCalendarVisible}
        hideOnClickOutside={true}
        requestHide={handleDropdownRequestHide}
      >
        <CalendarDropdownComponent>
          <CalendarBorderComponent>
            <SingleDateCalendarComponent
              date={value}
              onChange={hideCalendarAndChangeDate}
              weekDayNameByNumber={weekDayNameByNumber}
              monthNameByNumber={monthNameByNumber}
            />
          </CalendarBorderComponent>
        </CalendarDropdownComponent>
      </DropdownComponent>
    </div>
  )
}
