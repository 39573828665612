import { Expose, Type } from "class-transformer"

export default class NetworkCreateSessionUserPartial {
  @Expose()
  @Type(() => String)
  readonly emailAddress?: string | null

  @Expose()
  @Type(() => String)
  readonly password?: string | null

  constructor(parameters: {
    readonly emailAddress?: string | null
    readonly password?: string | null
  }) {
    this.emailAddress = parameters.emailAddress
    this.password = parameters.password
  }
}
