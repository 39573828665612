import UrlProvider from "./UrlProvider"

export default class CoreUrlProvider extends UrlProvider {
  buildRootUrl(): string {
    return this.buildUrl({ path: this.buildRootPath() })
  }

  buildAuthenticationUrl(): string {
    return this.buildUrl({ path: this.buildAuthenticationPath() })
  }

  buildRootPath(): string {
    return ""
  }

  buildAuthenticationPath(): string {
    return "authentication"
  }
}
