import NetworkLink from "../entities/links/NetworkLink"
import Link from "../../domain/entities/links/Link"

export default class LinksMapper {
  mapNetworkToDomain({
    link
  }: {
    readonly link: NetworkLink
  }): Link {
    return {
      url: link.url,
      type: link.type
    }
  }

  mapDomainToNetwork({
    link
  }: {
    readonly link: Link
  }): NetworkLink {
    return new NetworkLink({
      url: link.url,
      type: link.type
    })
  }
}
