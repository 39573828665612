import BackendHttpClient, { BackendHttpClientResult } from "../../../../core/data/network/BackendHttpClient"
import NetworkLastItemPagination from "../../../../core/data/entities/pagination/NetworkLastItemPagination"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import { instanceToPlain, plainToInstance } from "class-transformer"
import NetworkExecutionError from "../../../../core/data/entities/errors/NetworkExecutionError"
import GetObjectNetworkResult from "../../../../core/data/results/GetObjectNetworkResult"
import CreateObjectNetworkResult from "../../../../core/data/results/CreateObjectNetworkResult"
import UpdateObjectNetworkResult from "../../../../core/data/results/UpdateObjectNetworkResult"
import DestroyObjectNetworkResult from "../../../../core/data/results/DestroyObjectNetworkResult"
import SuccessExecutionResult from "../../../../../sqadmin/core/domain/results/SuccessExecutionResult"
import ErrorExecutionResult from "../../../../../sqadmin/core/domain/results/ErrorExecutionResult"
import FailureExecutionResult from "../../../../../sqadmin/core/domain/results/FailureExecutionResult"
import NetworkBonusProgramLevelsRequestFilter
  from "../entities/NetworkBonusProgramLevelsRequestFilter"
import NetworkBonusProgramLevel from "../../../../core/data/entities/bonus-program-levels/NetworkBonusProgramLevel"
import NetworkBonusProgramLevelError
  from "../../../../core/data/entities/bonus-program-levels/NetworkBonusProgramLevelError"
import NetworkBonusProgramLevelsRequestQuery
  from "../entities/NetworkBonusProgramLevelsRequestQuery"
import NetworkBonusProgramLevelsResponseBody
  from "../entities/NetworkBonusProgramLevelsResponseBody"
import NetworkBonusProgramLevelResponseBody
  from "../entities/NetworkBonusProgramLevelResponseBody"
import NetworkBonusProgramLevelRequestBody
  from "../entities/NetworkBonusProgramLevelRequestBody"

const basePath = "/admin/bonus_program_levels"

export default class BonusProgramLevelsNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async getBonusProgramLevels({
    filter,
    pagination,
    sort
  }: {
    readonly filter?: NetworkBonusProgramLevelsRequestFilter | null
    readonly pagination?: NetworkLastItemPagination | null
    readonly sort?: string | null
  }): Promise<GetBonusProgramLevelsNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: basePath,
      parameters: instanceToPlain(new NetworkBonusProgramLevelsRequestQuery({
        filter,
        pagination,
        sort
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkBonusProgramLevelsResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async getBonusProgramLevel({
    bonusProgramLevelId
  }: {
    readonly bonusProgramLevelId: number
  }): Promise<GetObjectNetworkResult<NetworkBonusProgramLevel>> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}/${bonusProgramLevelId}`
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkBonusProgramLevelResponseBody, result.body).bonusProgramLevel!
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async createBonusProgramLevel({
    bonusProgramLevel
  }: {
    readonly bonusProgramLevel: NetworkBonusProgramLevel
  }): Promise<CreateObjectNetworkResult<NetworkBonusProgramLevel, NetworkBonusProgramLevelError>> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.POST,
      path: basePath,
      body: instanceToPlain(new NetworkBonusProgramLevelRequestBody({
        bonusProgramLevel
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkBonusProgramLevelResponseBody, result.body).bonusProgramLevel!
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkBonusProgramLevelError, result.body)
        }
      case "failure":
        return result
    }
  }

  async updateBonusProgramLevel({
    bonusProgramLevelId,
    bonusProgramLevel
  }: {
    readonly bonusProgramLevelId: number
    readonly bonusProgramLevel: NetworkBonusProgramLevel
  }): Promise<UpdateObjectNetworkResult<NetworkBonusProgramLevel, NetworkBonusProgramLevelError>> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.PUT,
      path: `${basePath}/${bonusProgramLevelId}`,
      body: instanceToPlain(new NetworkBonusProgramLevelRequestBody({
        bonusProgramLevel
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkBonusProgramLevelResponseBody, result.body).bonusProgramLevel!
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkBonusProgramLevelError, result.body)
        }
      case "failure":
        return result
    }
  }

  async destroyBonusProgramLevel({
    bonusProgramLevelId
  }: {
    readonly bonusProgramLevelId: number
  }): Promise<DestroyObjectNetworkResult<NetworkBonusProgramLevelError>> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.DELETE,
      path: `${basePath}/${bonusProgramLevelId}`
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: undefined
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkBonusProgramLevelError, result.body)
        }
      case "failure":
        return result
    }
  }
}

export type GetBonusProgramLevelsNetworkResult =
  SuccessExecutionResult<NetworkBonusProgramLevelsResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult
