import { DestroyObjectResult } from "../../../../../sqadmin/features/objects/domain/results/DestroyObjectResult"
import UsersRepository from "../repositories/UsersRepository"
import UserError from "../../../../core/domain/entities/users/UserError"

export default class DestroyUserUseCase {
  private readonly usersRepository: UsersRepository

  constructor(parameters: {
    readonly usersRepository: UsersRepository
  }) {
    this.usersRepository = parameters.usersRepository
  }

  async call(parameters: {
    readonly userId: number
  }): Promise<DestroyObjectResult<UserError>> {
    return await this.usersRepository.destroyUser(parameters)
  }
}
