import { Expose, Type } from "class-transformer"
import OrderReceivingPriceRuleErrorsObject
  from "../../../domain/entities/order-receiving-price-rules/OrderReceivingPriceRuleErrorsObject"
import NetworkOrderReceivingPriceRuleErrorsObjectAttributes
  from "./NetworkOrderReceivingPriceRuleErrorsObjectAttributes"
import NetworkOrderReceivingPriceRuleConditionErrorsObject
  from "../order-receiving-price-rule-conditions/NetworkOrderReceivingPriceRuleConditionErrorsObject"
import NetworkCalculatorErrorsObject from "../calculators/NetworkCalculatorErrorsObject"

export default class NetworkOrderReceivingPriceRuleErrorsObject implements OrderReceivingPriceRuleErrorsObject {
  @Expose()
  @Type(() => NetworkOrderReceivingPriceRuleErrorsObjectAttributes)
  readonly attributes: NetworkOrderReceivingPriceRuleErrorsObjectAttributes | null | undefined

  @Expose()
  readonly clientId: string | null | undefined

  @Expose()
  @Type(() => NetworkOrderReceivingPriceRuleConditionErrorsObject)
  readonly conditions: NetworkOrderReceivingPriceRuleConditionErrorsObject[] | null | undefined

  @Expose()
  @Type(() => NetworkCalculatorErrorsObject)
  readonly calculator: NetworkCalculatorErrorsObject | null | undefined
}
