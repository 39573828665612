import DashboardsRepository from "../../repositories/DashboardsRepository"
import Dashboard from "../../../../../core/domain/entities/dashboards/Dashboard"
import Sort from "../../../../../../sqadmin/features/objects/presentation/entities/tables/Sort"
import LastItemPagination from "../../../../../core/domain/entities/paginations/LastItemPagination"
import DashboardsFilter from "../../../../../core/domain/entities/dashboards/DashboardsFilter"
import { GetObjectsPageResult } from "../../../../../../sqadmin/features/objects/domain/results/GetObjectsPageResult"

export default class GetDashboardsUseCase {
  private readonly dashboardsRepository: DashboardsRepository

  constructor(parameters: {
    readonly dashboardsRepository: DashboardsRepository
  }) {
    this.dashboardsRepository = parameters.dashboardsRepository
  }

  async call(parameters: GetDashboardParameters): Promise<GetObjectsPageResult<Dashboard>> {
    return this.dashboardsRepository.getDashboards(parameters)
  }
}

export interface GetDashboardParameters {
  readonly filter?: DashboardsFilter
  readonly sort?: Sort
  readonly pagination?: LastItemPagination
}
