import { Expose, Type } from "class-transformer"
import NetworkPropertyValueErrorsObjectAttributes from "./NetworkPropertyValueErrorsObjectAttributes"
import PropertyValueErrorsObject from "../../../domain/entities/property-values/PropertyValueErrorsObject"

export default class NetworkPropertyValueErrorsObject implements PropertyValueErrorsObject {
  @Expose()
  readonly clientId: string | null | undefined

  @Expose()
  @Type(() => NetworkPropertyValueErrorsObjectAttributes)
  readonly attributes: NetworkPropertyValueErrorsObjectAttributes | null | undefined
}
