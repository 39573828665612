import { Expose } from "class-transformer"
import NetworkBonusProgramLevel from "../../../../core/data/entities/bonus-program-levels/NetworkBonusProgramLevel"

export default class NetworkBonusProgramLevelRequestBody {
  @Expose()
  readonly bonusProgramLevel: NetworkBonusProgramLevel

  constructor(parameters: {
    readonly bonusProgramLevel: NetworkBonusProgramLevel
  }) {
    this.bonusProgramLevel = parameters.bonusProgramLevel
  }
}
