import React from "react"
import { StringFormFieldViewState } from "../../../entities/form-fields/form-field-by-type/StringFormField"
import { TextInputComponent, TextInputStyle } from "../../../../../../design/text-input/TextInputComponent"
import isPresent from "../../../../../../lib/isPresent"
import styles from "./StringFormFieldComponent.module.scss"
import TextComponent, { TextStyle } from "../../../../../../design/text/TextComponent"

export default function StringFormFieldComponent({
  fieldViewState,
  isDisabled
}: {
  readonly fieldViewState: StringFormFieldViewState
  readonly isDisabled: boolean
}) {
  function handleOnChange(value: string) {
    fieldViewState.onChange(value)
  }

  return (
    <div className={styles.root}>
      {
        fieldViewState.getTitle() && (
          <div className={styles.title}>
            <TextComponent textStyle={TextStyle.LABEL1_PRIMARY}>{fieldViewState.getTitle()}</TextComponent>
          </div>
        )
      }
      <TextInputComponent
        value={fieldViewState.value ?? ""}
        isDisabled={isDisabled}
        onChange={handleOnChange}
        textInputStyle={TextInputStyle.TEXT_INPUT1_PRIMARY}
        hasError={isPresent(fieldViewState.getErrors())}
        placeholder={null}
      />
    </div>
  )
}
