import NetworkProductCategory from "../entities/product-categories/NetworkProductCategory"
import ProductCategory from "../../domain/entities/product-categories/ProductCategory"
import ImagesMapper from "./ImagesMapper"
import BonusProgramRulesMapper from "./BonusProgramRulesMapper"

export default class ProductCategoriesMapper {
  mapNetworkToDomain({
    productCategory
  }: {
    readonly productCategory: NetworkProductCategory
  }): ProductCategory {
    return {
      id: productCategory.id,
      externalCode: productCategory.externalCode,
      name: productCategory.name,
      imageId: productCategory.imageId,
      image: productCategory.image && new ImagesMapper().mapNetworkToDomain({ image: productCategory.image }),
      position: productCategory.position,
      bonusProgramRule: productCategory.bonusProgramRule && new BonusProgramRulesMapper()
        .mapNetworkToDomain({ bonusProgramRule: productCategory.bonusProgramRule })
    }
  }

  mapDomainToNetwork({
    productCategory
  }: {
    readonly productCategory: ProductCategory
  }): NetworkProductCategory {
    return new NetworkProductCategory({
      id: productCategory.id,
      externalCode: productCategory.externalCode,
      name: productCategory.name,
      imageId: productCategory.imageId,
      image: productCategory.image && new ImagesMapper().mapDomainToNetwork({ image: productCategory.image }),
      position: productCategory.position,
      bonusProgramRule: productCategory.bonusProgramRule && new BonusProgramRulesMapper()
        .mapDomainToNetwork({ bonusProgramRule: productCategory.bonusProgramRule })
    })
  }
}
