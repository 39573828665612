import { GetObjectsPageResult } from "../../../../../sqadmin/features/objects/domain/results/GetObjectsPageResult"
import Sort from "../../../../../sqadmin/features/objects/presentation/entities/tables/Sort"
import LastItemPagination from "../../../../core/domain/entities/paginations/LastItemPagination"
import PropertiesRepository from "../repositories/PropertiesRepository"
import PropertiesFilter from "../../../../core/domain/entities/properties/PropertiesFilter"
import Property from "../../../../core/domain/entities/properties/Property"

export default class GetPropertiesUseCase {
  private readonly propertiesRepository: PropertiesRepository

  constructor(parameters: {
    readonly propertiesRepository: PropertiesRepository
  }) {
    this.propertiesRepository = parameters.propertiesRepository
  }

  async call(parameters: GetPropertiesParameters): Promise<GetObjectsPageResult<Property>> {
    return this.propertiesRepository.getProperties(parameters)
  }
}

export interface GetPropertiesParameters {
  readonly filter?: PropertiesFilter
  readonly sort?: Sort
  readonly pagination?: LastItemPagination
}

export type GetPropertiesResult = GetObjectsPageResult<Property>
