import { Expose, Type } from "class-transformer"
import NetworkProductCategoryErrorsObjectAttributes from "./NetworkProductCategoryErrorsObjectAttributes"
import ProductCategoryErrorsObject from "../../../domain/entities/product-categories/ProductCategoryErrorsObject"
import NetworkImageErrorsObject from "../images/NetworkImageErrorsObject"
import NetworkBonusProgramRuleErrorsObject from "../bonus-program-rules/NetworkBonusProgramRuleErrorsObject"

export default class NetworkProductCategoryErrorsObject implements ProductCategoryErrorsObject {
  @Expose()
  @Type(() => NetworkProductCategoryErrorsObjectAttributes)
  readonly attributes: NetworkProductCategoryErrorsObjectAttributes | null | undefined

  @Expose()
  @Type(() => NetworkImageErrorsObject)
  readonly image: NetworkImageErrorsObject | null | undefined

  @Expose()
  @Type(() => NetworkBonusProgramRuleErrorsObject)
  readonly bonusProgramRule: NetworkBonusProgramRuleErrorsObject | null | undefined
}
