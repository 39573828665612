import { Expose, Type } from "class-transformer"
import NetworkPropertyValuesRequestFilter from "./NetworkPropertyValuesRequestFilter"

export default class NetworkPropertyValuesRequestQuery {
  @Expose()
  @Type(() => NetworkPropertyValuesRequestFilter)
  readonly filter: NetworkPropertyValuesRequestFilter | null | undefined

  @Expose()
  readonly sort: string | null | undefined

  constructor(parameters: {
    readonly filter: NetworkPropertyValuesRequestFilter | null | undefined
    readonly sort: string | null | undefined
  }) {
    this.filter = parameters.filter
    this.sort = parameters.sort
  }
}
