import { RouteObject } from "react-router-dom"
import RootDi from "../di/components/RootDi"
import UiMenuItem from "../../../core/presentation/entities/UiMenuItem"
import CoreUrlProvider from "../../../core/presentation/services/CoreUrlProvider"

export default function createRootRoute({
  di,
  menuItems,
  childrenRoutes
}: {
  readonly di: RootDi
  readonly menuItems: UiMenuItem[]
  readonly childrenRoutes: RouteObject[]
}): RouteObject {
  const urlProvider = new CoreUrlProvider()

  return {
    path: urlProvider.buildRootPath(),
    element: di.sqAdminRootDiComponent
      .rootPresentationDiModule
      .provideRootPage({
        menuItems
      }),
    children: childrenRoutes
  }
}
