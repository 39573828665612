import { Expose, Type } from "class-transformer"
import NetworkProductCategoryErrorsObject
  from "./NetworkProductCategoryErrorsObject"
import NetworkExecutionError from "../errors/NetworkExecutionError"

export default class NetworkProductCategoryError extends NetworkExecutionError {
  @Expose()
  @Type(() => NetworkProductCategoryErrorsObject)
  readonly errorsObject?: NetworkProductCategoryErrorsObject | null
}
