import NetworkProductsRequestFilter from "../entities/NetworkProductsRequestFilter"
import ProductsFilter from "../../../../core/domain/entities/products/ProductsFilter"
import isBlank from "../../../../../sqadmin/lib/isBlank"
import IsArchivedFilterValue from "../../../../core/domain/entities/filters/IsArchivedFilterValue"

export default class ProductFiltersMapper {
  mapDomainToNetwork({
    filter,
    query
  }: {
    readonly filter: ProductsFilter | null | undefined
    readonly query: string | null | undefined
  }): NetworkProductsRequestFilter {
    return {
      query: query,
      isArchived: this.detectIsArchivedValue(filter)
    }
  }

  private detectIsArchivedValue(filter: ProductsFilter | null | undefined) {
    if (isBlank(filter)) return undefined

    switch (filter.isArchivedFilterValue) {
      case IsArchivedFilterValue.ALL:
        return undefined
      case IsArchivedFilterValue.IN_ARCHIVE:
        return true
      case IsArchivedFilterValue.NOT_IN_ARCHIVE:
        return false
    }
  }
}
