import { useEffect, useState } from "react"

type StateObserver<T> = (val: T) => void

export class StateObservable<T> {
  private observers: StateObserver<T>[] = []
  private value: T

  constructor(value: T) {
    this.value = value
  }

  getValue(): T {
    return this.value
  }

  setValue(newValue: T) {
    if (this.value === newValue) return

    this.value = newValue
    this.observers.forEach(observer => {
        observer(this.value)
      }
    )
  }

  subscribe(observer: StateObserver<T>) {
    this.observers.push(observer)
  }

  unsubscribe(observer: StateObserver<T>) {
    this.observers = this.observers.filter(stateObserver => stateObserver !== observer)
  }
}

export function useStateObservable<T>(observable: StateObservable<T>): T {
  const [value, setValue] = useState(observable.getValue())

  useEffect(() => {
    if (value !== observable.getValue()) {
      setValue(observable.getValue())
    }

    observable.subscribe(setValue)

    return () => {
      observable.unsubscribe(setValue)
    }
  }, [observable])

  return value
}
