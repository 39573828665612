import CreateDiscountRuleUseCase from "../domain/use-cases/CreateDiscountRuleUseCase"
import DestroyDiscountRuleUseCase from "../domain/use-cases/DestroyDiscountRuleUseCase"
import GetDiscountRulesUseCase from "../domain/use-cases/GetDiscountRulesUseCase"
import GetDiscountRuleUseCase from "../domain/use-cases/GetDiscountRuleUseCase"
import UpdateDiscountRuleUseCase from "../domain/use-cases/UpdateDiscountRuleUseCase"
import DiModule from "../../../../sqadmin/lib/di/DiModule"
import DiscountRulesCoreDataDiModule from "./DiscountRulesCoreDataDiModule"

export default interface DiscountRulesCoreDomainDiModule {
  provideCreateDiscountRuleUseCase(): CreateDiscountRuleUseCase

  provideDestroyDiscountRuleUseCase(): DestroyDiscountRuleUseCase

  provideGetDiscountRulesUseCase(): GetDiscountRulesUseCase

  provideGetDiscountRuleUseCase(): GetDiscountRuleUseCase

  provideUpdateDiscountRuleUseCase(): UpdateDiscountRuleUseCase
}

export class DefaultDiscountRulesCoreDomainDiModule extends DiModule
  implements DiscountRulesCoreDomainDiModule {
  private readonly discountRulesCoreDataDiModule: DiscountRulesCoreDataDiModule

  constructor(parameters: {
    readonly discountRulesCoreDataDiModule: DiscountRulesCoreDataDiModule
  }) {
    super()
    this.discountRulesCoreDataDiModule = parameters.discountRulesCoreDataDiModule
  }

  provideCreateDiscountRuleUseCase(): CreateDiscountRuleUseCase {
    return this.single(
      CreateDiscountRuleUseCase.name,
      () => new CreateDiscountRuleUseCase({
        discountRulesRepository: this.discountRulesCoreDataDiModule.provideDiscountRulesRepository()
      })
    )
  }

  provideDestroyDiscountRuleUseCase(): DestroyDiscountRuleUseCase {
    return this.single(
      DestroyDiscountRuleUseCase.name,
      () => new DestroyDiscountRuleUseCase({
        discountRulesRepository: this.discountRulesCoreDataDiModule.provideDiscountRulesRepository()
      })
    )
  }

  provideGetDiscountRulesUseCase(): GetDiscountRulesUseCase {
    return this.single(
      GetDiscountRulesUseCase.name,
      () => new GetDiscountRulesUseCase({
        discountRulesRepository: this.discountRulesCoreDataDiModule.provideDiscountRulesRepository()
      })
    )
  }

  provideGetDiscountRuleUseCase(): GetDiscountRuleUseCase {
    return this.single(
      GetDiscountRuleUseCase.name,
      () => new GetDiscountRuleUseCase({
        discountRulesRepository: this.discountRulesCoreDataDiModule.provideDiscountRulesRepository()
      })
    )
  }

  provideUpdateDiscountRuleUseCase(): UpdateDiscountRuleUseCase {
    return this.single(
      UpdateDiscountRuleUseCase.name,
      () => new UpdateDiscountRuleUseCase({
        discountRulesRepository: this.discountRulesCoreDataDiModule.provideDiscountRulesRepository()
      })
    )
  }
}
