import ProductsRepository from "../domain/repositories/ProductsRepository"
import DiModule from "../../../../sqadmin/lib/di/DiModule"
import DefaultProductsRepository from "../data/repositories/DefaultProductsRepository"
import ProductImagesRepository from "../domain/repositories/ProductImagesRepository"
import DefaultProductImagesRepository from "../data/repositories/DefaultProductImagesRepository"
import ProductsNetworkSource from "../data/sources/ProductsNetworkSource"
import ProductImagesNetworkSource from "../../../core/data/sources/network/ProductImagesNetworkSource"
import CoreNetworkDiModule from "../../../core/di/modules/CoreNetworkDiModule"

export default interface ProductsCoreDataDiModule {
  provideProductsRepository(): ProductsRepository

  provideProductImagesRepository(): ProductImagesRepository
}

export class DefaultProductsCoreDataDiModule extends DiModule implements ProductsCoreDataDiModule {
  private readonly sqCoreCoreNetworkDiModule: CoreNetworkDiModule

  constructor(parameters: {
    readonly sqCoreCoreNetworkDiModule: CoreNetworkDiModule
  }) {
    super()
    this.sqCoreCoreNetworkDiModule = parameters.sqCoreCoreNetworkDiModule
  }

  provideProductImagesRepository(): ProductImagesRepository {
    return this.single(
      DefaultProductImagesRepository.name,
      () => new DefaultProductImagesRepository({
        productImagesNetworkSource: this.provideProductImagesNetworkSource()
      })
    )
  }

  provideProductsRepository(): ProductsRepository {
    return this.single(
      DefaultProductsRepository.name,
      () => new DefaultProductsRepository({
        productsNetworkSource: this.provideProductsNetworkSource()
      })
    )
  }

  private provideProductsNetworkSource(): ProductsNetworkSource {
    return this.single(
      ProductsNetworkSource.name,
      () => new ProductsNetworkSource({
        backendHttpClient: this.sqCoreCoreNetworkDiModule.provideBackendHttpClient()
      })
    )
  }

  private provideProductImagesNetworkSource(): ProductImagesNetworkSource {
    return this.single(
      ProductImagesNetworkSource.name,
      () => new ProductImagesNetworkSource({
        backendHttpClient: this.sqCoreCoreNetworkDiModule.provideBackendHttpClient()
      })
    )
  }
}
