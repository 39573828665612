import DiModule from "../../../../sqadmin/lib/di/DiModule"
import ApiLogItemsCoreDataDiModule from "./ApiLogItemsCoreDataDiModule"
import GetApiLogItemUseCase from "../domain/use-cases/GetApiLogItemUseCase"
import GetApiLogItemsUseCase from "../domain/use-cases/GetApiLogItemsUseCase"

export default interface ApiLogItemsCoreDomainDiModule {
  provideGetApiLogItemUseCase(): GetApiLogItemUseCase

  provideGetApiLogItemsUseCase(): GetApiLogItemsUseCase
}

export class DefaultApiLogItemsCoreDomainDiModule extends DiModule
  implements ApiLogItemsCoreDomainDiModule {
  private readonly apiLogItemsCoreDataDiModule: ApiLogItemsCoreDataDiModule

  constructor(parameters: {
    readonly apiLogItemsCoreDataDiModule: ApiLogItemsCoreDataDiModule
  }) {
    super()
    this.apiLogItemsCoreDataDiModule = parameters.apiLogItemsCoreDataDiModule
  }

  provideGetApiLogItemUseCase(): GetApiLogItemUseCase {
    return this.single(
      GetApiLogItemUseCase.name,
      () => new GetApiLogItemUseCase({
        apiLogItemsRepository: this.apiLogItemsCoreDataDiModule.provideApiLogItemsRepository()
      })
    )
  }

  provideGetApiLogItemsUseCase(): GetApiLogItemsUseCase {
    return this.single(
      GetApiLogItemsUseCase.name,
      () => new GetApiLogItemsUseCase({
        apiLogItemsRepository: this.apiLogItemsCoreDataDiModule.provideApiLogItemsRepository()
      })
    )
  }
}
