import DiModule from "../../../../sqadmin/lib/di/DiModule"
import CoreNetworkDiModule from "../../../core/di/modules/CoreNetworkDiModule"
import ConfigurationsRepository from "../domain/repositories/ConfigurationsRepository"
import DefaultConfigurationsRepository from "../data/repositories/DefaultConfigurationsRepository"
import ConfigurationsNetworkSource from "../../../core/data/sources/network/ConfigurationsNetworkSource"
import ConfigurationsLocalSource from "../../../core/data/sources/network/ConfigurationsLocalSource"

export default interface ConfigurationsCoreDataDiModule {
  provideConfigurationsRepository(): ConfigurationsRepository
}

export class DefaultConfigurationsCoreDataDiModule extends DiModule implements ConfigurationsCoreDataDiModule {
  private readonly sqCoreCoreNetworkDiModule: CoreNetworkDiModule

  constructor(parameters: {
    readonly sqCoreCoreNetworkDiModule: CoreNetworkDiModule
  }) {
    super()
    this.sqCoreCoreNetworkDiModule = parameters.sqCoreCoreNetworkDiModule
  }

  provideConfigurationsRepository(): ConfigurationsRepository {
    return this.single(
      DefaultConfigurationsRepository.name,
      () => new DefaultConfigurationsRepository({
        configurationsNetworkSource: this.provideConfigurationsNetworkSource(),
        configurationsLocalSource: this.provideConfigurationsLocalSource()
      })
    )
  }

  private provideConfigurationsNetworkSource(): ConfigurationsNetworkSource {
    return this.single(
      ConfigurationsNetworkSource.name,
      () => new ConfigurationsNetworkSource({
        backendHttpClient: this.sqCoreCoreNetworkDiModule.provideBackendHttpClient()
      })
    )
  }

  private provideConfigurationsLocalSource(): ConfigurationsLocalSource {
    return this.single(
      ConfigurationsLocalSource.name,
      () => new ConfigurationsLocalSource()
    )
  }
}
