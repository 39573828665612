import ObjectsPresentationLogicParameters
  from "../../../../../../sqadmin/features/objects/presentation/entities/objects/ObjectsPresentationLogicParameters"
import useObjectsPresentationLogicParameters
  from "../../../../../../sqadmin/features/objects/presentation/hooks/useObjectsPresentationLogicParameters"
import { useViewModel } from "../../../../../../sqadmin/lib/view-model/ViewModel"
import ObjectsComponent
  from "../../../../../../sqadmin/features/objects/presentation/components/objects/ObjectsComponent"
import React from "react"
import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import { useCoreTextProvider } from "../../../../../core/presentation/contexts/CoreTextProviderContext"
import CoreUrlProvider from "../../../../../core/presentation/services/CoreUrlProvider"
import OptionsViewModel from "./OptionsViewModel"
import Option from "../../../../../core/domain/entities/options/Option"

export interface OptionsPageParameters {
  readonly provideViewModel: (parameters: {
    readonly objectsPresentationLogicParameters: ObjectsPresentationLogicParameters
  }) => OptionsViewModel
}

export default function OptionsPage({
  provideViewModel
}: OptionsPageParameters) {
  const coreTextProvider: CoreTextProvider = useCoreTextProvider()
  const coreUrlProvider: CoreUrlProvider = new CoreUrlProvider()
  const objectsPresentationLogicParameters: ObjectsPresentationLogicParameters = useObjectsPresentationLogicParameters()

  const viewModel: OptionsViewModel = useViewModel(() => provideViewModel({
    objectsPresentationLogicParameters
  }))

  function getRowUrl(option: Option): string {
    return coreUrlProvider.buildOptionUrl({ id: option.id! })
  }

  return (
    <ObjectsComponent
      title={coreTextProvider.options()}
      getObjectUrl={getRowUrl}
      newObjectUrl={coreUrlProvider.buildNewOptionUrl()}
      observableObjectsPageViewState={viewModel.observableObjectsPageViewState}
      observableObjectsViewAction={viewModel.observableObjectsViewAction}
      onObjectsViewEvent={viewModel.onObjectsViewEvent}
    />
  )
}
