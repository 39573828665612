import { Expose, Type } from "class-transformer"
import NetworkBonusProgramLevelTransitionRuleErrorsObjectAttributes
  from "./NetworkBonusProgramLevelTransitionRuleErrorsObjectAttributes"
import BonusProgramLevelTransitionRuleErrorsObject
  from "../../../domain/entities/bonus-program-level-transition-rules/BonusProgramLevelTransitionRuleErrorsObject"
import NetworkProductsSetErrorsObject from "../products-sets/NetworkProductsSetErrorsObject"

export default class NetworkBonusProgramLevelTransitionRuleErrorsObject
  implements BonusProgramLevelTransitionRuleErrorsObject {

  @Expose()
  @Type(() => NetworkBonusProgramLevelTransitionRuleErrorsObjectAttributes)
  readonly attributes: NetworkBonusProgramLevelTransitionRuleErrorsObjectAttributes | null | undefined

  @Expose()
  @Type(() => NetworkProductsSetErrorsObject)
  readonly productsSets: NetworkProductsSetErrorsObject[] | null | undefined
}
