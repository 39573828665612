enum NetworkBlockType {
  BANNERS = "banners",
  BANNERS_PAGER = "bannersPager",
  BANNERS_SLIDER = "bannersSlider",
  SEARCH = "search",
  PRODUCT_CATEGORIES = "productCategories",
  PRODUCT_CATEGORIES_SLIDER = "productCategoriesSlider",
  PRODUCTS_SLIDER = "productsSlider",
  PRODUCTS = "products",
  LOYALTY_PROGRAM = "loyaltyProgram",
  HEADER = "header",
  CURRENT_ORDERS = "currentOrders",
  MENU = "menu"
}

export default NetworkBlockType
