import React from "react"
import styles from "./TableRowErrorMessageComponent.module.scss"
import { TextStyle } from "../../../../design/text/TextComponent"
import AppErrorMessageComponent from "../app-error-message/AppErrorMessageComponent"

export default function TableRowErrorMessageComponent({
  columnsCount,
  errorMessage,
  onRetryClick
}: {
  readonly columnsCount: number
  readonly errorMessage: string | null | undefined
  readonly onRetryClick: () => void
}) {
  return (
    <tr className={styles.errorMessageRow}>
      <td colSpan={columnsCount}>
        <AppErrorMessageComponent
          text={errorMessage}
          onClick={onRetryClick}
          messageTextStyle={TextStyle.TABLE3_PRIMARY}
          buttonTextStyle={TextStyle.TABLE3_SECONDARY}
        />
      </td>
    </tr>
  )
}
