import DiModule from "../../../../sqadmin/lib/di/DiModule"
import BonusPointsBurningRulesRepository from "../domain/repositories/BonusPointsBurningRulesRepository"
import DefaultBonusPointsBurningRulesRepository from "../data/repositories/DefaultBonusPointsBurningRulesRepository"
import CoreNetworkDiModule from "../../../core/di/modules/CoreNetworkDiModule"
import BonusPointsBurningRuleNetworkSource from "../data/sources/BonusPointsBurningRulesNetworkSource"

export default interface BonusPointsBurningRulesCoreDataDiModule {
  provideBonusPointsBurningRulesRepository(): BonusPointsBurningRulesRepository
}

export class DefaultBonusPointsBurningRulesCoreDataDiModule extends DiModule
  implements BonusPointsBurningRulesCoreDataDiModule {
  private readonly sqCoreCoreNetworkDiModule: CoreNetworkDiModule

  constructor(parameters: {
    readonly sqCoreCoreNetworkDiModule: CoreNetworkDiModule
  }) {
    super()
    this.sqCoreCoreNetworkDiModule = parameters.sqCoreCoreNetworkDiModule
  }

  provideBonusPointsBurningRulesRepository(): BonusPointsBurningRulesRepository {
    return this.single(
      DefaultBonusPointsBurningRulesRepository.name,
      () => new DefaultBonusPointsBurningRulesRepository({
        bonusPointsBurningRulesNetworkSource: this.provideBonusProgramLevelsNetworkSource()
      })
    )
  }

  private provideBonusProgramLevelsNetworkSource(): BonusPointsBurningRuleNetworkSource {
    return this.single(
      BonusPointsBurningRuleNetworkSource.name,
      () => new BonusPointsBurningRuleNetworkSource({
        backendHttpClient: this.sqCoreCoreNetworkDiModule.provideBackendHttpClient()
      })
    )
  }
}
