import { DestroyObjectResult } from "../../../../../sqadmin/features/objects/domain/results/DestroyObjectResult"
import PaymentStatusesRepository from "../repositories/PaymentStatusesRepository"
import PaymentStatusError from "../../../../core/domain/entities/payment-statuses/PaymentStatusError"

export default class DestroyPaymentStatusUseCase {
  private readonly paymentStatusesRepository: PaymentStatusesRepository

  constructor(parameters: {
    readonly paymentStatusesRepository: PaymentStatusesRepository
  }) {
    this.paymentStatusesRepository = parameters.paymentStatusesRepository
  }

  async call(parameters: {
    readonly paymentStatusId: number
  }): Promise<DestroyObjectResult<PaymentStatusError>> {
    return await this.paymentStatusesRepository.destroyPaymentStatus(parameters)
  }
}
