import { Expose, Type } from "class-transformer"
import OrderItemErrorsObject from "../../../domain/entities/order-items/OrderItemErrorsObject"
import NetworkOrderItemErrorsObjectAttributes from "./NetworkOrderItemErrorsObjectAttributes"
import NetworkBatchErrorsObject from "../batches/NetworkBatchErrorsObject"

export default class NetworkOrderItemErrorsObject implements OrderItemErrorsObject {
  @Expose()
  @Type(() => NetworkOrderItemErrorsObjectAttributes)
  readonly attributes: NetworkOrderItemErrorsObjectAttributes | null | undefined

  @Expose()
  readonly clientId: string | null | undefined

  @Expose()
  @Type(() => NetworkBatchErrorsObject)
  readonly batch: NetworkBatchErrorsObject | null | undefined
}
