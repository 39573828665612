import ObjectsRepository from "../../domain/repositories/ObjectsRepository"
import ObjectsEvent, { ObjectsEventCallback } from "../../domain/entities/ObjectsEvent"

export default class DefaultObjectsRepository implements ObjectsRepository {
  private readonly objectsEventsCallbacks: ObjectsEventCallback[]

  constructor() {
    this.objectsEventsCallbacks = []
  }

  broadcastObjectsEvent(objectsEvent: ObjectsEvent) {
    this.objectsEventsCallbacks.forEach((objectsEventsCallback: ObjectsEventCallback) => {
      objectsEventsCallback(objectsEvent)
    })
  }

  subscribeToObjectsEvents(callback: ObjectsEventCallback): ObjectsEventCallback {
    this.objectsEventsCallbacks.push(callback)
    return callback
  }

  unsubscribeFromObjectsEvents(callback: ObjectsEventCallback) {
    const callbackIndex: number = this.objectsEventsCallbacks.indexOf(callback)

    if (callbackIndex < 0) {
      return
    }

    this.objectsEventsCallbacks.splice(callbackIndex, 1)
  }
}
