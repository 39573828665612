import { UpdateObjectResult } from "../../../../../sqadmin/features/objects/domain/results/UpdateObjectResult"
import BonusProgramLevelsRepository from "../repositories/BonusProgramLevelsRepository"
import BonusProgramLevelError from "../../../../core/domain/entities/bonus-program-levels/BonusProgramLevelError"
import BonusProgramLevel from "../../../../core/domain/entities/bonus-program-levels/BonusProgramLevel"

export default class UpdateBonusProgramLevelUseCase {
  private readonly bonusProgramLevelsRepository: BonusProgramLevelsRepository

  constructor(parameters: {
    readonly bonusProgramLevelsRepository: BonusProgramLevelsRepository
  }) {
    this.bonusProgramLevelsRepository = parameters.bonusProgramLevelsRepository
  }

  async call(parameters: {
    readonly bonusProgramLevelId: number
    readonly bonusProgramLevel: BonusProgramLevel
  }): Promise<UpdateObjectResult<BonusProgramLevel, BonusProgramLevelError>> {
    return await this.bonusProgramLevelsRepository.updateBonusProgramLevel(parameters)
  }
}
