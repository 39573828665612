import { RouteObject } from "react-router-dom"
import { objectIdUrlPart } from "../../../../sqadmin/features/objects/presentation/hooks/useObjectId"
import CoreUrlProvider from "../../../core/presentation/services/CoreUrlProvider"
import ApiLogItemsDi from "../di/ApiLogItemsDi"

export default function createApiLogItemsRoutes({
  di
}: {
  readonly di: ApiLogItemsDi
}): RouteObject[] {
  const urlProvider = new CoreUrlProvider()
  return [
    {
      path: urlProvider.buildApiLogItemsPath(),
      element: di
        .sqCoreApiLogItemsDiComponent
        .apiLogItemsPresentationDiModule
        .provideApiLogItemsPage()
    },
    {
      path: urlProvider.buildApiLogItemPath({ id: `:${objectIdUrlPart}` }),
      element: di
        .sqCoreApiLogItemsDiComponent
        .apiLogItemsPresentationDiModule
        .provideApiLogItemPage()
    }
  ]
}
