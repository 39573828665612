import { LoadObjectResult } from "../../../../../sqadmin/features/objects/domain/results/LoadObjectResult"
import OrderReceivingMethod from "../../../../core/domain/entities/order-receiving-methods/OrderReceivingMethod"
import OrderReceivingMethodsRepository from "../repositories/OrderReceivingMethodsRepository"

export default class GetOrderReceivingMethodUseCase {
  private readonly orderReceivingMethodsRepository: OrderReceivingMethodsRepository

  constructor(parameters: {
    readonly orderReceivingMethodsRepository: OrderReceivingMethodsRepository
  }) {
    this.orderReceivingMethodsRepository = parameters.orderReceivingMethodsRepository
  }

  async call(parameters: {
    readonly orderReceivingMethodId: number
  }): Promise<LoadObjectResult<OrderReceivingMethod>> {
    return await this.orderReceivingMethodsRepository.getOrderReceivingMethod(parameters)
  }
}
