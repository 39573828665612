import { Expose, Type } from "class-transformer"
import NetworkPage from "../../../../core/data/entities/pagination/NetworkPage"
import NetworkOrderShippingTariff
  from "../../../../core/data/entities/order-shipping-method-tariffs/NetworkOrderShippingTariff"

export default class NetworkOrderShippingTariffsResponseBody {
  @Expose()
  @Type(() => NetworkOrderShippingTariff)
  readonly orderShippingTariffs: NetworkOrderShippingTariff[] | null | undefined

  @Expose()
  @Type(() => NetworkPage)
  readonly page: NetworkPage | null | undefined
}
