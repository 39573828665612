import { Expose, Type } from "class-transformer"
import NetworkPage from "../../../../core/data/entities/pagination/NetworkPage"
import NetworkPaymentProvider from "../../../../core/data/entities/payment-providers/NetworkPaymentProvider"

export default class NetworkPaymentProvidersResponseBody {
  @Expose()
  @Type(() => NetworkPaymentProvider)
  readonly paymentProviders: NetworkPaymentProvider[] | null | undefined

  @Expose()
  @Type(() => NetworkPage)
  readonly page: NetworkPage | null | undefined
}
