import { LoadObjectResult } from "../../../../sqadmin/features/objects/domain/results/LoadObjectResult"
import { UpdateObjectResult } from "../../../../sqadmin/features/objects/domain/results/UpdateObjectResult"
import UpdateObjectNetworkResult from "../../../core/data/results/UpdateObjectNetworkResult"
import DashboardsRepository from "../domain/repositories/DashboardsRepository"
import Dashboard from "../../../core/domain/entities/dashboards/Dashboard"
import DashboardsNetworkSource, {
  GetDashboardsNetworkResult
} from "../../../core/data/sources/network/DashboardsNetworkSource"
import NetworkDashboard from "../../../core/data/entities/dashboards/NetworkDashboard"
import NetworkDashboardError from "../../../core/data/entities/dashboards/NetworkDashboardError"
import DashboardError from "../../../core/domain/entities/dashboards/DashboardError"
import DashboardsMapper from "../../../core/data/mappers/DashboardsMapper"
import { UpdateDashboardParameters } from "../domain/use-cases/dashboards/UpdateDashboardUseCase"
import GetObjectNetworkResult from "../../../core/data/results/GetObjectNetworkResult"
import { GetDashboardParameters } from "../domain/use-cases/dashboards/GetDashboardsUseCase"
import LastItemPaginationsMapper from "../../../core/data/mappers/LastItemPaginationsMapper"
import SortMapper from "../../../core/data/mappers/SortMapper"
import DashboardsFiltersMapper from "../../../core/data/mappers/DashboardsFiltersMapper"
import { CreateObjectResult } from "../../../../sqadmin/features/objects/domain/results/CreateObjectResult"
import CreateObjectNetworkResult from "../../../core/data/results/CreateObjectNetworkResult"
import { DestroyObjectResult } from "../../../../sqadmin/features/objects/domain/results/DestroyObjectResult"
import DestroyObjectNetworkResult from "../../../core/data/results/DestroyObjectNetworkResult"
import { GetObjectsPageResult } from "../../../../sqadmin/features/objects/domain/results/GetObjectsPageResult"
import PagesMapper from "../../../core/data/mappers/PagesMapper"

export default class DefaultDashboardsRepository implements DashboardsRepository {
  private readonly dashboardsNetworkSource: DashboardsNetworkSource

  constructor(parameters: {
    readonly dashboardsNetworkSource: DashboardsNetworkSource
  }) {
    this.dashboardsNetworkSource = parameters.dashboardsNetworkSource
  }

  async getDashboards({
    filter,
    pagination,
    sort
  }: GetDashboardParameters): Promise<GetObjectsPageResult<Dashboard>> {
    const result: GetDashboardsNetworkResult = await this.dashboardsNetworkSource.getDashboards({
      filter: filter && new DashboardsFiltersMapper().mapDomainToNetwork({ filter }),
      pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({ pagination }),
      sort: sort && new SortMapper().mapDomainToNetwork({ sort })
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: {
            objects: result.data.dashboards!.map((dashboard: NetworkDashboard) => {
              return new DashboardsMapper().mapNetworkToDomain({
                dashboard: dashboard
              })
            }),
            page: new PagesMapper().mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      default:
        return result
    }
  }

  async getDashboard({
    dashboardId
  }: {
    readonly dashboardId: number
  }): Promise<LoadObjectResult<Dashboard>> {
    const result: GetObjectNetworkResult<NetworkDashboard> =
      await this.dashboardsNetworkSource.getDashboard({
        dashboardId
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new DashboardsMapper().mapNetworkToDomain({
            dashboard: result.data
          })
        }
      default:
        return result
    }
  }

  async updateDashboard({
    dashboardId,
    dashboard
  }: UpdateDashboardParameters): Promise<UpdateObjectResult<Dashboard, DashboardError>> {
    const result: UpdateObjectNetworkResult<NetworkDashboard, NetworkDashboardError> =
      await this.dashboardsNetworkSource.updateDashboard({
        dashboardId,
        dashboard: new DashboardsMapper().mapDomainToNetwork({
          dashboard: dashboard
        })
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new DashboardsMapper().mapNetworkToDomain({
            dashboard: result.data
          })
        }
      default:
        return result
    }
  }

  async createDashboard(parameters: {
    readonly dashboard: Dashboard
  }): Promise<CreateObjectResult<Dashboard, DashboardError>> {
    const result: CreateObjectNetworkResult<NetworkDashboard, NetworkDashboardError> =
      await this.dashboardsNetworkSource.createDashboard({
        dashboard: new DashboardsMapper().mapDomainToNetwork({
          dashboard: parameters.dashboard
        })
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new DashboardsMapper().mapNetworkToDomain({
            dashboard: result.data
          })
        }
      default:
        return result
    }
  }

  async destroyDashboard({
    dashboardId
  }: {
    readonly dashboardId: number
  }): Promise<DestroyObjectResult<DashboardError>> {
    const result: DestroyObjectNetworkResult<NetworkDashboardError> =
      await this.dashboardsNetworkSource.destroyDashboard({
        dashboardId
      })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: undefined
        }
      default:
        return result
    }
  }
}
