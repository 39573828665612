import { Expose, Type } from "class-transformer"
import NetworkOptionErrorsObjectAttributes from "./NetworkOptionErrorsObjectAttributes"
import OptionErrorsObject from "../../../domain/entities/options/OptionErrorsObject"
import NetworkOptionValueErrorsObject from "../option-values/NetworkOptionValueErrorsObject"

export default class NetworkOptionErrorsObject implements OptionErrorsObject {
  @Expose()
  @Type(() => NetworkOptionErrorsObjectAttributes)
  readonly attributes: NetworkOptionErrorsObjectAttributes | null | undefined

  @Expose()
  @Type(() => NetworkOptionValueErrorsObject)
  readonly values: NetworkOptionValueErrorsObject[] | null | undefined
}
