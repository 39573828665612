import DiModule from "../../../../../sqadmin/lib/di/DiModule"
import ObjectsDomainDiModule from "../../../../../sqadmin/features/objects/di/modules/ObjectsDomainDiModule"
import CoreI18nDiModule from "../../../../core/di/modules/CoreI18nDiModule"
import autoBind from "auto-bind"
import React from "react"
import ObjectsPresentationLogicParameters
  from "../../../../../sqadmin/features/objects/presentation/entities/objects/ObjectsPresentationLogicParameters"
import ApiLogItemsViewModel from "../../presentation/pages/api-log-items-page/ApiLogItemsViewModel"
import ApiLogItemsPage from "../../presentation/pages/api-log-items-page/ApiLogItemsPage"
import ApiLogItemViewModel from "../../presentation/pages/api-log-item-page/ApiLogItemViewModel"
import ApiLogItemPage from "../../presentation/pages/api-log-item-page/ApiLogItemPage"
import ApiLogItemsCoreDomainDiModule from "../../../api-log-items-core/di/ApiLogItemsCoreDomainDiModule"

export default interface ApiLogItemsPresentationDiModule {
  provideApiLogItemsPage(): React.ReactNode

  provideApiLogItemPage(): React.ReactNode
}

export class DefaultApiLogItemsPresentationDiModule
  extends DiModule
  implements ApiLogItemsPresentationDiModule {

  private readonly objectsDomainDiModule: ObjectsDomainDiModule
  private readonly apiLogItemsCoreDomainDiModule: ApiLogItemsCoreDomainDiModule
  private readonly coreI18nDiModule: CoreI18nDiModule

  constructor(parameters: {
    readonly objectsDomainDiModule: ObjectsDomainDiModule
    readonly apiLogItemsCoreDomainDiModule: ApiLogItemsCoreDomainDiModule
    readonly coreI18nDiModule: CoreI18nDiModule
  }) {
    super()
    autoBind(this)
    this.objectsDomainDiModule = parameters.objectsDomainDiModule
    this.apiLogItemsCoreDomainDiModule = parameters.apiLogItemsCoreDomainDiModule
    this.coreI18nDiModule = parameters.coreI18nDiModule
  }

  provideApiLogItemsPage(): React.ReactNode {
    return <ApiLogItemsPage provideViewModel={this.provideApiLogItemsViewModel} />
  }

  provideApiLogItemPage(): React.ReactNode {
    return <ApiLogItemPage provideViewModel={this.provideApiLogItemViewModel} />
  }

  private provideApiLogItemsViewModel({
    objectsPresentationLogicParameters
  }: {
    readonly objectsPresentationLogicParameters: ObjectsPresentationLogicParameters
  }): ApiLogItemsViewModel {
    return new ApiLogItemsViewModel({
      coreI18n: this.coreI18nDiModule.provideCoreI18n(),
      objectsPresentationLogicParameters,
      subscribeToObjectsEventsUseCase: this.objectsDomainDiModule.provideSubscribeToObjectsEventsUseCase({
        key: "apiLogItems"
      }),
      unsubscribeFromObjectsEventsUseCase: this.objectsDomainDiModule.provideUnsubscribeFromObjectsEventsUseCase({
        key: "apiLogItems"
      }),
      getApiLogItemsUseCase: this.apiLogItemsCoreDomainDiModule.provideGetApiLogItemsUseCase()
    })
  }

  private provideApiLogItemViewModel({
    apiLogItemId
  }: {
    readonly apiLogItemId?: number
  }): ApiLogItemViewModel {
    return new ApiLogItemViewModel({
      coreI18n: this.coreI18nDiModule.provideCoreI18n(),
      broadcastObjectsEventUseCase: this.objectsDomainDiModule.provideBroadcastObjectsEventUseCase({
        key: "apiLogItems"
      }),
      getApiLogItemUseCase: this.apiLogItemsCoreDomainDiModule.provideGetApiLogItemUseCase(),
      apiLogItemId: apiLogItemId
    })
  }
}
