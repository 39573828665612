import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../errors/NetworkAttributeError"
import OptionErrorsObjectAttributes from "../../../domain/entities/options/OptionErrorsObjectAttributes"

export default class NetworkOptionErrorsObjectAttributes implements OptionErrorsObjectAttributes {
  @Expose()
  @Type(() => NetworkAttributeError)
  readonly name?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly externalCode: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly position?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly displayStyle?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly values?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly shouldDisplayInFilters?: NetworkAttributeError[] | null
}
