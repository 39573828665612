import NetworkBonusProgramMember from "../entities/bonus-program-member/NetworkBonusProgramMember"
import BonusProgramMember from "../../domain/entities/bonus-program-member/BonusProgramMember"
import BonusProgramLevelsMapper from "./BonusProgramLevelsMapper"

export default class BonusProgramMembersMapper {
  mapNetworkToDomain({
    bonusProgramMember
  }: {
    readonly bonusProgramMember: NetworkBonusProgramMember
  }): BonusProgramMember {
    return {
      id: bonusProgramMember.id,
      bonusPointsCount: bonusProgramMember.bonusPointsCount,
      bonusProgramLevel: bonusProgramMember.bonusProgramLevel && new BonusProgramLevelsMapper()
        .mapNetworkToDomain({ bonusProgramLevel: bonusProgramMember.bonusProgramLevel })
    }
  }

  mapDomainToNetwork({
    bonusProgramMember
  }: {
    readonly bonusProgramMember: BonusProgramMember
  }): NetworkBonusProgramMember {
    return new NetworkBonusProgramMember({
      id: bonusProgramMember.id,
      bonusPointsCount: bonusProgramMember.bonusPointsCount,
      bonusProgramLevel: bonusProgramMember.bonusProgramLevel && new BonusProgramLevelsMapper()
        .mapDomainToNetwork({ bonusProgramLevel: bonusProgramMember.bonusProgramLevel })
    })
  }
}
