import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import { useCoreTextProvider } from "../../../../../core/presentation/contexts/CoreTextProviderContext"
import { useNumberObjectId } from "../../../../../../sqadmin/features/objects/presentation/hooks/useObjectId"
import { useViewModel } from "../../../../../../sqadmin/lib/view-model/ViewModel"
import ObjectComponent from "../../../../../../sqadmin/features/objects/presentation/components/object/ObjectComponent"
import React from "react"
import ApiLogItemViewModel from "./ApiLogItemViewModel"

export interface ApiLogItemPageProps {
  readonly provideViewModel: (parameters: { readonly apiLogItemId?: number }) => ApiLogItemViewModel
}

export default function ApiLogItemPage({
  provideViewModel
}: ApiLogItemPageProps) {
  const coreTextProvider: CoreTextProvider = useCoreTextProvider()
  const apiLogItemId: number | undefined = useNumberObjectId()

  const viewModel: ApiLogItemViewModel = useViewModel(() => provideViewModel({
    apiLogItemId
  }))

  return (
    <ObjectComponent
      existedObjectTitle={coreTextProvider.logItem()}
      observableObjectViewState={viewModel.observableObjectViewState}
      onObjectViewEvent={viewModel.onViewObjectEvent}
    />
  )
}
