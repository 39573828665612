import React from "react"
import ExecutionError from "../../../domain/entities/ExecutionError"
import { TextStyle } from "../../../../design/text/TextComponent"
import AppErrorMessageComponent from "../app-error-message/AppErrorMessageComponent"

export default function ContentLoadingErrorComponent({
  error,
  onRetryClick
}: {
  readonly error: ExecutionError
  readonly onRetryClick: () => void
}) {
  return (
    <AppErrorMessageComponent
      text={error.message}
      onClick={onRetryClick}
      messageTextStyle={TextStyle.BODY2_PRIMARY}
      buttonTextStyle={TextStyle.BODY2_SECONDARY}
    />
  )
}
