import { Expose, Type } from "class-transformer"
import ProductsSetProductErrorsObject
  from "../../../domain/entities/products-set-products/ProductsSetProductErrorsObject"
import NetworkProductsSetProductErrorsObjectAttributes from "./NetworkProductsSetProductErrorsObjectAttributes"

export default class NetworkProductsSetProductErrorsObject implements ProductsSetProductErrorsObject {
  @Expose()
  @Type(() => NetworkProductsSetProductErrorsObjectAttributes)
  readonly attributes: NetworkProductsSetProductErrorsObjectAttributes | null | undefined

  @Expose()
  readonly clientId: string | null | undefined
}
