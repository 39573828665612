import DiModule from "../../../../../sqadmin/lib/di/DiModule"
import ProductsDataDiModule from "./ProductsDataDiModule"
import GetPlacesUseCase from "../../domain/use-cases/places/GetPlacesUseCase"
import GetOptionsUseCase from "../../domain/use-cases/options/GetOptionsUseCase"

export default interface ProductsDomainDiModule {
  provideGetOptionsUseCase(): GetOptionsUseCase

  provideGetPlacesUseCase(): GetPlacesUseCase
}

export class DefaultProductsDomainDiModule extends DiModule implements ProductsDomainDiModule {
  private readonly productsDataDiModule: ProductsDataDiModule

  constructor(parameters: {
    readonly productsDataDiModule: ProductsDataDiModule
  }) {
    super()
    this.productsDataDiModule = parameters.productsDataDiModule
  }

  provideGetPlacesUseCase(): GetPlacesUseCase {
    return this.single(
      GetPlacesUseCase.name,
      () => new GetPlacesUseCase({
        placesRepository: this.productsDataDiModule.providePlacesRepository()
      })
    )
  }

  provideGetOptionsUseCase(): GetOptionsUseCase {
    return this.single(
      GetOptionsUseCase.name,
      () => new GetOptionsUseCase({
        optionsRepository: this.productsDataDiModule.provideOptionsRepository()
      })
    )
  }
}
