import getTimeZone from "./getTimeZone"

const yearCharactersCount = 4
const monthCharactersCount = 2
const dayCharactersCount = 2
const hoursCharactersCount = 2
const minutesCharactersCount = 2

const millisecondsInSecond = 1000
const secondsInMinute = 60
const minutesInHour = 60

const timeZoneSignIndex = 0
const timeZoneHourFirstIndex = 1
const timeZoneHourSecondIndex = 2
const timeZoneMinuteFirstIndex = 4
const timeZoneMinuteSecondIndex = 5

export default class DateTimeFormatter {
  private readonly date: Date
  private readonly timeZone: string

  constructor(parameters: {
    readonly date: Date
    readonly timeZone?: string | null
  }) {
    this.date = parameters.date
    this.timeZone = parameters.timeZone ?? getTimeZone(parameters.date)
  }

  formatDateTime(): string {
    const year = this.getSerializedFullYear()
    const month = this.getSerializedMonth()
    const day = this.getSerializedMonthDay()
    const hours = this.getSerializedHours()
    const minutes = this.getSerializedMinutes()
    return `${day}.${month}.${year} ${hours}:${minutes}`
  }

  formatDate(): string {
    const year = this.getSerializedFullYear()
    const month = this.getSerializedMonth()
    const day = this.getSerializedMonthDay()
    return `${day}.${month}.${year}`
  }

  private getSerializedFullYear(): string {
    return this.getFullYear().toString()
      .padStart(yearCharactersCount, "0")
  }

  private getSerializedMonth(): string {
    return this.getMonth().toString()
      .padStart(monthCharactersCount, "0")
  }

  private getSerializedMonthDay(): string {
    return this.getMonthDay().toString()
      .padStart(dayCharactersCount, "0")
  }

  private getSerializedHours(): string {
    return this.getHours().toString()
      .padStart(hoursCharactersCount, "0")
  }

  private getSerializedMinutes(): string {
    return this.getMinutes().toString()
      .padStart(minutesCharactersCount, "0")
  }

  private getFullYear(): number {
    return (this.dateWithOffset()).getFullYear()
  }

  private getMonth(): number {
    return (this.dateWithOffset()).getMonth() + 1
  }

  private getMonthDay(): number {
    return (this.dateWithOffset()).getDate()
  }

  private getHours(): number {
    return (this.dateWithOffset()).getHours()
  }

  private getMinutes(): number {
    return (this.dateWithOffset()).getMinutes()
  }

  private dateWithOffset(): Date {
    const utcDate: Date = new Date(
      this.date.getUTCFullYear(),
      this.date.getUTCMonth(),
      this.date.getUTCDate(),
      this.date.getUTCHours(),
      this.date.getUTCMinutes(),
      this.date.getUTCSeconds(),
      this.date.getUTCMilliseconds()
    )

    const sign: number = this.timeZone[timeZoneSignIndex] === "+" ? 1 : -1
    const hoursOffset: number = parseInt(`${this.timeZone[timeZoneHourFirstIndex]}${this.timeZone[timeZoneHourSecondIndex]}`)
    const minutesOffset: number = parseInt(`${this.timeZone[timeZoneMinuteFirstIndex]}${this.timeZone[timeZoneMinuteSecondIndex]}`)
    const offsetInMilliseconds: number = sign * (
      hoursOffset * minutesInHour * secondsInMinute * millisecondsInSecond +
      minutesOffset * secondsInMinute * millisecondsInSecond
    )

    return new Date(utcDate.getTime() + offsetInMilliseconds)
  }
}
