import NetworkUserProfile from "../entities/user-profiles/NetworkUserProfile"
import UserProfile from "../../domain/entities/user-profiles/UserProfile"

export default class UserProfilesMapper {
  mapNetworkToDomain({
    userProfile
  }: {
    readonly userProfile: NetworkUserProfile
  }): UserProfile {
    return {
      id: userProfile.id,
      firstName: userProfile.firstName,
      lastName: userProfile.lastName,
      middleName: userProfile.middleName
    }
  }

  mapDomainToNetwork({
    userProfile
  }: {
    readonly userProfile: UserProfile
  }): NetworkUserProfile {
    return new NetworkUserProfile({
      id: userProfile.id,
      firstName: userProfile.firstName,
      lastName: userProfile.lastName,
      middleName: userProfile.middleName
    })
  }
}
