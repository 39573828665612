import { v4 as uuidv4 } from "uuid"
import NetworkOptionValue from "../entities/option-values/NetworkOptionValue"
import OptionValue from "../../domain/entities/option-values/OptionValue"

export default class OptionValuesMapper {
  mapNetworkToDomain({
    value
  }: {
    readonly value: NetworkOptionValue
  }): OptionValue {
    return {
      id: value.id,
      clientId: uuidv4(),
      externalCode: value.externalCode,
      name: value.name,
      position: value.position
    }
  }

  mapDomainToNetwork({
    value
  }: {
    readonly value: OptionValue
  }): NetworkOptionValue {
    return new NetworkOptionValue({
      id: value.id,
      clientId: value.clientId,
      externalCode: value.externalCode,
      name: value.name,
      position: value.position
    })
  }
}
