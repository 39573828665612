import ProductCategoriesRepository from "../../repositories/ProductCategoriesRepository"
import ProductCategoryError from "../../../../../core/domain/entities/product-categories/ProductCategoryError"
import { DestroyObjectResult } from "../../../../../../sqadmin/features/objects/domain/results/DestroyObjectResult"

export default class DestroyProductCategoryUseCase {
  private readonly productCategoriesRepository: ProductCategoriesRepository

  constructor(parameters: {
    readonly productCategoriesRepository: ProductCategoriesRepository
  }) {
    this.productCategoriesRepository = parameters.productCategoriesRepository
  }

  async call(parameters: {
    readonly productCategoryId: number
  }): Promise<DestroyObjectResult<ProductCategoryError>> {
    return await this.productCategoriesRepository.destroyProductCategory(parameters)
  }
}
