import AdminsDi from "../di/AdminsDi"
import { RouteObject } from "react-router-dom"
import { objectIdUrlPart } from "../../../../sqadmin/features/objects/presentation/hooks/useObjectId"
import CoreUrlProvider from "../../../core/presentation/services/CoreUrlProvider"

export default function createAdminsRoutes({
  di
}: {
  readonly di: AdminsDi
}): RouteObject[] {
  const urlProvider = new CoreUrlProvider()
  return [
    {
      path: urlProvider.buildAdminsPath(),
      element: di.sqCoreAdminsDiComponent.adminsPresentationDiModule.provideAdminsPage()
    },
    {
      path: urlProvider.buildNewAdminPath(),
      element: di.sqCoreAdminsDiComponent.adminsPresentationDiModule.provideAdminPage()
    },
    {
      path: urlProvider.buildAdminPath({ id: `:${objectIdUrlPart}` }),
      element: di.sqCoreAdminsDiComponent.adminsPresentationDiModule.provideAdminPage()
    }
  ]
}
