import ViewModel from "../../../../../../sqadmin/lib/view-model/ViewModel"
import CoreI18n from "../../../../../core/i18n/CoreI18n"
import CoreUrlProvider from "../../../../../core/presentation/services/CoreUrlProvider"
import BroadcastObjectsEventUseCase
  from "../../../../../../sqadmin/features/objects/domain/use-cases/objects/BroadcastObjectsEventUseCase"
import ObjectPresentationLogic
  from "../../../../../../sqadmin/features/objects/presentation/presentation-logics/ObjectPresentationLogic"
import { StateObservable } from "../../../../../../sqadmin/lib/view-model/StateObservable"
import { ObjectViewState } from "../../../../../../sqadmin/features/objects/presentation/view-states/ObjectViewState"
import autoBind from "auto-bind"
import ObjectViewEvent from "../../../../../../sqadmin/features/objects/presentation/view-events/ObjectViewEvent"
import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import isBlank from "../../../../../../sqadmin/lib/isBlank"
import StringFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/StringFormField"
import GetUserUseCase from "../../../../users-core/domain/use-cases/GetUserUseCase"
import UpdateUserUseCase from "../../../../users-core/domain/use-cases/UpdateUserUseCase"
import UserError from "../../../../../core/domain/entities/users/UserError"
import User from "../../../../../core/domain/entities/users/User"
import UserErrorsObject from "../../../../../core/domain/entities/users/UserErrorsObject"
import CreateUserUseCase from "../../../../users-core/domain/use-cases/CreateUserUseCase"
import UserRoleType from "../../../../../core/domain/entities/users/UserRoleType"
import BooleanFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/BooleanFormField"

export default class AdminViewModel extends ViewModel {
  private readonly coreI18n: CoreI18n
  private readonly coreUrlProvider: CoreUrlProvider
  private readonly broadcastObjectsEventUseCase: BroadcastObjectsEventUseCase
  private readonly getUserUseCase: GetUserUseCase
  private readonly updateUserUseCase: UpdateUserUseCase
  private readonly createUserUseCase: CreateUserUseCase
  private readonly adminId?: number

  private readonly objectPresentationLogic: ObjectPresentationLogic<
    User,
    UserError,
    UserErrorsObject
  >

  readonly observableObjectViewState: StateObservable<ObjectViewState>

  constructor(parameters: {
    readonly coreI18n: CoreI18n
    readonly broadcastObjectsEventUseCase: BroadcastObjectsEventUseCase
    readonly getUserUseCase: GetUserUseCase
    readonly updateUserUseCase: UpdateUserUseCase
    readonly createUserUseCase: CreateUserUseCase
    readonly adminId?: number
  }) {
    super()
    this.coreI18n = parameters.coreI18n
    this.broadcastObjectsEventUseCase = parameters.broadcastObjectsEventUseCase
    this.getUserUseCase = parameters.getUserUseCase
    this.updateUserUseCase = parameters.updateUserUseCase
    this.createUserUseCase = parameters.createUserUseCase
    this.adminId = parameters.adminId
    this.objectPresentationLogic = this.createObjectPresentationLogic()
    this.observableObjectViewState = this.objectPresentationLogic.observableObjectViewState
    this.coreUrlProvider = new CoreUrlProvider()
    autoBind(this)
  }

  onViewObjectEvent(objectViewEvent: ObjectViewEvent) {
    this.objectPresentationLogic.onObjectViewEvent(objectViewEvent)
  }

  private createObjectPresentationLogic(): ObjectPresentationLogic<
    User,
    UserError,
    UserErrorsObject
  > {
    const coreTextProvider: CoreTextProvider = this.coreI18n.getTextProvider()
    const isNewObject = isBlank(this.adminId)
    return new ObjectPresentationLogic({
      // TODO: how to not pass?
      broadcastObjectsEventUseCase: this.broadcastObjectsEventUseCase,
      isNewObject: isNewObject,
      buildObject: async() => ({
        id: undefined,
        profile: undefined,
        phoneNumber: undefined,
        bonusProgramMember: undefined,
        emailAddress: undefined,
        roleType: UserRoleType.ADMIN,
        password: undefined,
        passwordConfirmation: undefined,
        isAdminEmailNotificationsEnabled: undefined
      }),
      getObjectUrl: (user) => {
        return this.coreUrlProvider.buildAdminUrl({
          id: user.id!
        })
      },
      loadObject: async() => {
        return await this.getUserUseCase.call({ userId: this.adminId! })
      },
      updateObject: async({ object: user }) => {
        return await this.updateUserUseCase.call({
          userId: this.adminId!,
          user
        })
      },
      createObject: async({ object: user }) => {
        return await this.createUserUseCase.call({ user: user })
      },
      getErrorsObject: ({ error: userError }) => userError
        ?.errorsObject,
      formFields: [
        new StringFormField<User, UserErrorsObject>({
          getTitle: () => coreTextProvider.firstName(),
          getValue: (user: User) => user.profile?.firstName,
          setValue: (user: User, value: string): User => ({
            ...user,
            profile: {
              ...user.profile ?? this.createProfile(),
              firstName: value
            }
          }),
          getErrors: (userErrorsObject?: UserErrorsObject) => userErrorsObject
            ?.profile
            ?.attributes
            ?.firstName
        }),
        new StringFormField<User, UserErrorsObject>({
          getTitle: () => coreTextProvider.lastName(),
          getValue: (user: User) => user.profile?.lastName,
          setValue: (user: User, value: string): User => ({
            ...user,
            profile: {
              ...user.profile ?? this.createProfile(),
              lastName: value
            }
          }),
          getErrors: (userErrorsObject?: UserErrorsObject) => userErrorsObject
            ?.profile
            ?.attributes
            ?.lastName
        }),
        new StringFormField<User, UserErrorsObject>({
          getDisabled: () => !isNewObject,
          getTitle: () => coreTextProvider.emailAddress(),
          getValue: (user: User) => user.emailAddress,
          setValue: (user: User, value: string): User => ({
            ...user,
            emailAddress: value
          }),
          getErrors: (userErrorsObject?: UserErrorsObject) => userErrorsObject
            ?.attributes
            ?.emailAddress
        }),
        new StringFormField<User, UserErrorsObject>({
          getVisible: () => isNewObject,
          getTitle: () => coreTextProvider.password(),
          getValue: (user: User) => user.password,
          setValue: (user: User, value: string): User => ({
            ...user,
            password: value
          }),
          getErrors: (userErrorsObject?: UserErrorsObject) => userErrorsObject
            ?.attributes
            ?.password
        }),
        new StringFormField<User, UserErrorsObject>({
          getVisible: () => isNewObject,
          getTitle: () => coreTextProvider.passwordConfirmation(),
          getValue: (user: User) => user.passwordConfirmation,
          setValue: (user: User, value: string): User => ({
            ...user,
            passwordConfirmation: value
          }),
          getErrors: (userErrorsObject?: UserErrorsObject) => userErrorsObject
            ?.attributes
            ?.passwordConfirmation
        }),
        new BooleanFormField<User, UserErrorsObject>({
          getText: () => coreTextProvider.emailNotificationsEnabled(),
          getValue: (user: User) => user.isAdminEmailNotificationsEnabled,
          setValue: (user: User, value: boolean | undefined): User => ({
            ...user,
            isAdminEmailNotificationsEnabled: value
          }),
          getErrors: (userErrorsObject?: UserErrorsObject) => userErrorsObject
            ?.attributes
            ?.isAdminEmailNotificationsEnabled
        })
      ]
    })
  }

  private createProfile() {
    return {
      firstName: undefined,
      lastName: undefined,
      middleName: undefined,
      id: undefined
    }
  }
}
