import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../errors/NetworkAttributeError"
import BannerErrorsObjectAttributes from "../../../domain/entities/banners/BannerErrorsObjectAttributes"

export default class NetworkBannerErrorsObjectAttributes implements BannerErrorsObjectAttributes {
  @Expose()
  @Type(() => NetworkAttributeError)
  readonly id: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly position: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly clientId: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly imageId: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly image: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly link: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly productsSet: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly dataType: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly dashboardId: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly dashboard: NetworkAttributeError[] | null | undefined
}
