import { Expose, Type } from "class-transformer"
import NetworkPage from "../pagination/NetworkPage"
import NetworkOrderProcessingStatus from "./NetworkOrderProcessingStatus"

export default class NetworkOrderProcessingStatusesResponseBody {
  @Expose()
  @Type(() => NetworkOrderProcessingStatus)
  readonly orderProcessingStatuses: NetworkOrderProcessingStatus[] | null | undefined

  @Expose()
  @Type(() => NetworkPage)
  readonly page: NetworkPage | null | undefined
}
