import NetworkSession from "../../entities/network/sessions/NetworkSession"
import LocalSession from "../../entities/local/sessions/LocalSession"

export default class SessionsMapper {
  mapNetworkToLocal({ object }: { readonly object: NetworkSession }): LocalSession {
    return new LocalSession({
      accessToken: object.accessToken
    })
  }
}
