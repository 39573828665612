import { Expose, Type } from "class-transformer"
import ProductsSetErrorsObject from "../../../domain/entities/products-sets/ProductsSetErrorsObject"
import NetworkProductsSetErrorsObjectAttributes from "./NetworkProductsSetErrorsObjectAttributes"
import NetworkProductsSetProductErrorsObject from "../products-set-products/NetworkProductsSetProductErrorsObject"
import NetworkProductsSetPropertyErrorsObject from "../products-set-properties/NetworkProductsSetPropertyErrorsObject"

export default class NetworkProductsSetErrorsObject implements ProductsSetErrorsObject {
  @Expose()
  @Type(() => NetworkProductsSetErrorsObjectAttributes)
  readonly attributes: NetworkProductsSetErrorsObjectAttributes | null | undefined

  @Expose()
  @Type(() => NetworkProductsSetProductErrorsObject)
  readonly productsSetProducts: NetworkProductsSetProductErrorsObject[] | null | undefined

  @Expose()
  @Type(() => NetworkProductsSetPropertyErrorsObject)
  readonly productsSetProperties: NetworkProductsSetPropertyErrorsObject[] | null | undefined

  @Expose()
  readonly clientId: string | null | undefined
}
