import ObjectsPresentationLogicParameters
  from "../../../../../../sqadmin/features/objects/presentation/entities/objects/ObjectsPresentationLogicParameters"
import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import { useCoreTextProvider } from "../../../../../core/presentation/contexts/CoreTextProviderContext"
import CoreUrlProvider from "../../../../../core/presentation/services/CoreUrlProvider"
import useObjectsPresentationLogicParameters
  from "../../../../../../sqadmin/features/objects/presentation/hooks/useObjectsPresentationLogicParameters"
import { useViewModel } from "../../../../../../sqadmin/lib/view-model/ViewModel"
import ObjectsComponent
  from "../../../../../../sqadmin/features/objects/presentation/components/objects/ObjectsComponent"
import React from "react"
import OrderProcessingStatus from "../../../../../core/domain/entities/order-processing-statuses/OrderProcessingStatus"
import OrderProcessingStatusesViewModel from "./OrderProcessingStatusesViewModel"

export interface OrderSubStatusesPageParameters {
  readonly provideViewModel: (parameters: {
    readonly objectsPresentationLogicParameters: ObjectsPresentationLogicParameters
  }) => OrderProcessingStatusesViewModel
}

export default function OrderProcessingStatusesPage({
  provideViewModel
}: OrderSubStatusesPageParameters) {
  const coreTextProvider: CoreTextProvider = useCoreTextProvider()
  const coreUrlProvider: CoreUrlProvider = new CoreUrlProvider()
  const objectsPresentationLogicParameters: ObjectsPresentationLogicParameters = useObjectsPresentationLogicParameters()

  const viewModel: OrderProcessingStatusesViewModel = useViewModel(() => provideViewModel({
    objectsPresentationLogicParameters
  }))

  function getRowUrl(orderProcessingStatus: OrderProcessingStatus): string {
    return coreUrlProvider.buildOrderProcessingStatusUrl({ id: orderProcessingStatus.id! })
  }

  return (
    <ObjectsComponent
      title={coreTextProvider.orderProcessingStatuses()}
      getObjectUrl={getRowUrl}
      newObjectUrl={coreUrlProvider.buildNewOrderProcessingStatusUrl()}
      observableObjectsPageViewState={viewModel.observableObjectsPageViewState}
      observableObjectsViewAction={viewModel.observableObjectsViewAction}
      onObjectsViewEvent={viewModel.onObjectsViewEvent}
    />
  )
}
