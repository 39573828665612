import GetOptionsUseCase from "../../domain/use-cases/GetOptionsUseCase"
import DiModule from "../../../../../sqadmin/lib/di/DiModule"
import OptionsDataDiModule from "./OptionsDataDiModule"
import GetOptionUseCase from "../../domain/use-cases/GetOptionUseCase"
import CreateOptionUseCase from "../../domain/use-cases/CreateOptionUseCase"
import UpdateOptionUseCase from "../../domain/use-cases/UpdateOptionUseCase"
import DestroyOptionUseCase from "../../domain/use-cases/DestroyOptionUseCase"

export default interface OptionsDomainDiModule {
  provideGetOptionsUseCase(): GetOptionsUseCase

  provideGetOptionUseCase(): GetOptionUseCase

  provideCreateOptionUseCase(): CreateOptionUseCase

  provideUpdateOptionUseCase(): UpdateOptionUseCase

  provideDestroyOptionUseCase(): DestroyOptionUseCase
}

export class DefaultOptionsDomainDiModule
  extends DiModule
  implements OptionsDomainDiModule {

  private readonly sqCoreOptionsDataDiModule: OptionsDataDiModule

  constructor(parameters: {
    readonly sqCoreOptionsDataDiModule: OptionsDataDiModule
  }) {
    super()
    this.sqCoreOptionsDataDiModule = parameters.sqCoreOptionsDataDiModule
  }

  provideGetOptionsUseCase(): GetOptionsUseCase {
    return this.single(
      GetOptionsUseCase.name,
      () => new GetOptionsUseCase({
        optionsRepository: this.sqCoreOptionsDataDiModule.provideOptionsRepository()
      })
    )
  }

  provideGetOptionUseCase(): GetOptionUseCase {
    return this.single(
      GetOptionUseCase.name,
      () => new GetOptionUseCase({
        optionsRepository: this.sqCoreOptionsDataDiModule.provideOptionsRepository()
      })
    )
  }

  provideCreateOptionUseCase(): CreateOptionUseCase {
    return this.single(
      CreateOptionUseCase.name,
      () => new CreateOptionUseCase({
        optionsRepository: this.sqCoreOptionsDataDiModule.provideOptionsRepository()
      })
    )
  }

  provideUpdateOptionUseCase(): UpdateOptionUseCase {
    return this.single(
      UpdateOptionUseCase.name,
      () => new UpdateOptionUseCase({
        optionsRepository: this.sqCoreOptionsDataDiModule.provideOptionsRepository()
      })
    )
  }

  provideDestroyOptionUseCase(): DestroyOptionUseCase {
    return this.single(
      DestroyOptionUseCase.name,
      () => new DestroyOptionUseCase({
        optionsRepository: this.sqCoreOptionsDataDiModule.provideOptionsRepository()
      })
    )
  }
}
