import { LoadObjectResult } from "../../../../../../sqadmin/features/objects/domain/results/LoadObjectResult"
import DashboardsRepository from "../../repositories/DashboardsRepository"
import Dashboard from "../../../../../core/domain/entities/dashboards/Dashboard"

export default class GetDashboardUseCase {
  private readonly dashboardsRepository: DashboardsRepository

  constructor(parameters: {
    readonly dashboardsRepository: DashboardsRepository
  }) {
    this.dashboardsRepository = parameters.dashboardsRepository
  }

  async call(parameters: {
    readonly dashboardId: number
  }): Promise<LoadObjectResult<Dashboard>> {
    return await this.dashboardsRepository.getDashboard(parameters)
  }
}
