import FormField, { FormFieldParameters, FormFieldViewState, FormFieldViewStateParameters } from "../FormField"
import Page from "../../../../../../core/domain/entities/Page"
import isBlank from "../../../../../../lib/isBlank"
import SelectOption from "../../../../../../core/presentation/entities/SelectOption"
import { GetObjectsPageResult } from "../../../../domain/results/GetObjectsPageResult"

const queryInputDebounceTimeoutInMilliseconds = 300
const defaultStateId = "defaultState"

export default class MultiSelectFormField<DomainObject, ErrorsObject, OptionObject>
  extends FormField<DomainObject, ErrorsObject> {

  private readonly getObjects: GetObjectsFunction<DomainObject, OptionObject>
  private readonly getValue: (object: DomainObject) => OptionObject[] | null | undefined
  private readonly setValue: (object: DomainObject, value: OptionObject[] | null) => DomainObject
  private readonly getOptionId: (optionObject: OptionObject) => string
  private readonly getOptionText: (optionObject: OptionObject) => string | null | undefined
  private isLoadingByStateId: { [key: string]: boolean }
  private optionObjectsByStateId: { [key: string]: OptionObject[] }
  private queryByStateId: { [key: string]: string | undefined }
  private queryInputTimeoutByStateId: { [key: string]: NodeJS.Timeout }
  private lastLoadingTimestampByStateId: { [key: string]: number }
  private pageByStateId: { [key: string]: Page | undefined }

  constructor(parameters: FormFieldParameters<DomainObject, ErrorsObject> & {
    readonly getObjects: GetObjectsFunction<DomainObject, OptionObject>
    readonly getValue: (object: DomainObject) => OptionObject[] | null | undefined
    readonly setValue: (object: DomainObject, value: OptionObject[] | null) => DomainObject
    readonly getOptionId: (optionObject: OptionObject) => string
    readonly getOptionText: (optionObject: OptionObject) => string | null | undefined
  }) {
    super(parameters)
    this.getObjects = parameters.getObjects
    this.getValue = parameters.getValue
    this.setValue = parameters.setValue
    this.getOptionId = parameters.getOptionId
    this.getOptionText = parameters.getOptionText
    this.isLoadingByStateId = {}
    this.optionObjectsByStateId = {}
    this.queryByStateId = {}
    this.queryInputTimeoutByStateId = {}
    this.lastLoadingTimestampByStateId = {}
    this.pageByStateId = {}
  }

  getViewState(object: DomainObject, errorsObject?: ErrorsObject): FormFieldViewState {
    const stateId: string = this.getObjectId ? this.getObjectId(object) : defaultStateId
    const selectedValues: OptionObject[] | null | undefined = this.getValue(object)
    const selectedOptions: SelectOption<OptionObject>[] = this.createOptions(selectedValues)
    const isLoading: boolean = this.isLoadingByStateId[stateId] ?? false
    const query: string | null | undefined = this.queryByStateId[stateId]
    const page: Page | undefined = this.pageByStateId[stateId]
    const options: SelectOption<OptionObject>[] = this.createOptions(this.optionObjectsByStateId[stateId])

    const loadAndShowOptions = async(): Promise<void> => {
      const timestamp: number = new Date().getTime()
      this.lastLoadingTimestampByStateId = { ...this.lastLoadingTimestampByStateId, [stateId]: timestamp }
      this.optionObjectsByStateId = { ...this.optionObjectsByStateId, [stateId]: [] }
      this.pageByStateId = { ...this.pageByStateId, [stateId]: undefined }
      this.isLoadingByStateId = { ...this.isLoadingByStateId, [stateId]: true }
      this.setAndShowLoadedObjectViewState()

      const result: GetObjectsPageResult<OptionObject> = await this.getObjects({
        query: this.queryByStateId[stateId],
        parentObject: object
      })

      const isLastLoading: boolean = timestamp === this.lastLoadingTimestampByStateId[stateId]

      if (!isLastLoading) {
        return
      }

      this.isLoadingByStateId = { ...this.isLoadingByStateId, [stateId]: false }

      switch (result.type) {
        case "error":
          // TODO: show error
          break
        case "failure":
          // TODO: show failure
          break
        case "success":
          this.optionObjectsByStateId = { ...this.optionObjectsByStateId, [stateId]: result.data.objects }
          this.pageByStateId = { ...this.pageByStateId, [stateId]: result.data.page }
          break
      }

      this.setAndShowLoadedObjectViewState()
    }

    const loadNextOptionsPageAndShowOptions = async(): Promise<void> => {
      const timestamp: number = new Date().getTime()
      this.lastLoadingTimestampByStateId = { ...this.lastLoadingTimestampByStateId, [stateId]: timestamp }
      this.isLoadingByStateId = { ...this.isLoadingByStateId, [stateId]: true }
      this.setAndShowLoadedObjectViewState()

      const optionObjects: OptionObject[] = this.optionObjectsByStateId[stateId] ?? []
      const lastOptionObjectIndex: number = optionObjects.length - 1
      const lastOptionObject: OptionObject = optionObjects[lastOptionObjectIndex]

      const result: GetObjectsPageResult<OptionObject> = await this.getObjects({
        query: this.queryByStateId[stateId],
        lastObject: lastOptionObject,
        parentObject: object
      })

      const isLastLoading: boolean = timestamp === this.lastLoadingTimestampByStateId[stateId]

      if (!isLastLoading) {
        return
      }

      this.isLoadingByStateId = { ...this.isLoadingByStateId, [stateId]: false }

      switch (result.type) {
        case "error":
          // TODO: show error
          break
        case "failure":
          // TODO: show failure
          break
        case "success":
          this.optionObjectsByStateId = {
            ...this.optionObjectsByStateId,
            [stateId]: [...optionObjects, ...result.data.objects]
          }
          this.pageByStateId = { ...this.pageByStateId, [stateId]: result.data.page }
          break
      }

      this.setAndShowLoadedObjectViewState()
    }

    return new MultiSelectFormFieldViewState<OptionObject>({
      ...this.getFormFieldViewStateParameters(object, errorsObject),
      selectedOptions,
      isLoading,
      options,
      query,
      page,
      onSelectedItemClicked: (selectOption: SelectOption<OptionObject>) => {
        const currentSelectedValues = selectedValues ?? []
        const filteredSelectedValues = currentSelectedValues.filter((selectedValue) => {
          return selectedValue !== selectOption.originalObject
        })

        this.setObject(this.setValue(object, filteredSelectedValues))
        this.setAndShowLoadedObjectViewState()
      },
      onSelect: (value: SelectOption<OptionObject>) => {
        const originalObject = value.originalObject
        if (!originalObject) return

        const currentSelectedOptions = selectedOptions ?? []
        const isAlreadySelected = currentSelectedOptions.find((selectedOption) => {
          return selectedOption.id === value?.id
        })

        if (isAlreadySelected) return

        const currentSelectedValues = selectedValues ?? []
        currentSelectedValues.push(originalObject)

        this.setObject(this.setValue(object, currentSelectedValues))
        this.setAndShowLoadedObjectViewState()
      },
      onOpened: () => {
        loadAndShowOptions().then()
      },
      onQueryChanged: (query: string) => {
        this.queryByStateId = { ...this.queryByStateId, [stateId]: query }
        this.setAndShowLoadedObjectViewState()

        clearTimeout(this.queryInputTimeoutByStateId[stateId])

        this.queryInputTimeoutByStateId = {
          ...this.queryInputTimeoutByStateId,
          [stateId]: setTimeout(() => {
            loadAndShowOptions().then()
          }, queryInputDebounceTimeoutInMilliseconds)
        }
      },
      onSearchRequested: () => {
        loadAndShowOptions().then()
      },
      onNextPageRequested: () => {
        loadNextOptionsPageAndShowOptions().then()
      }
    })
  }

  private createOptions(selectedValues: OptionObject[] | null | undefined): SelectOption<OptionObject>[] {
    if (isBlank(selectedValues)) return []

    return selectedValues.map((selectedValue) => this.createOption(selectedValue))
  }

  private createOption(optionObject: OptionObject): SelectOption<OptionObject> {
    return {
      id: this.getOptionId(optionObject),
      text: this.getOptionText(optionObject),
      originalObject: optionObject
    }
  }
}

export class MultiSelectFormFieldViewState<OptionObject> extends FormFieldViewState {
  readonly selectedOptions: SelectOption<OptionObject>[]
  readonly isLoading: boolean
  readonly options: SelectOption<OptionObject>[]
  readonly query?: string | null
  readonly page?: Page
  readonly onSelect: (value: SelectOption<OptionObject>) => void
  readonly onOpened: () => void
  readonly onSearchRequested: () => void
  readonly onQueryChanged: (query: string) => void
  readonly onNextPageRequested: () => void
  readonly onSelectedItemClicked: (selectOption: SelectOption<OptionObject>) => void

  constructor(parameters: FormFieldViewStateParameters & {
    readonly onSelect: (value: SelectOption<OptionObject>) => void
    readonly onOpened: () => void
    readonly selectedOptions: SelectOption<OptionObject>[]
    readonly isLoading: boolean
    readonly options: SelectOption<OptionObject>[]
    readonly query?: string | null
    readonly page?: Page
    readonly onSearchRequested: () => void
    readonly onQueryChanged: (query: string) => void
    readonly onNextPageRequested: () => void
    readonly onSelectedItemClicked: (selectOption: SelectOption<OptionObject>) => void
  }) {
    super(parameters)
    this.onSelect = parameters.onSelect
    this.onOpened = parameters.onOpened
    this.onSearchRequested = parameters.onSearchRequested
    this.onQueryChanged = parameters.onQueryChanged
    this.onNextPageRequested = parameters.onNextPageRequested
    this.onSelectedItemClicked = parameters.onSelectedItemClicked
    this.selectedOptions = parameters.selectedOptions
    this.isLoading = parameters.isLoading
    this.options = parameters.options
    this.query = parameters.query
    this.page = parameters.page
  }
}

export interface GetObjectsParameters<DomainObject, OptionObject> {
  readonly query?: string
  readonly lastObject?: OptionObject
  readonly parentObject?: DomainObject
}

export type GetObjectsFunction<DomainObject, OptionObject> =
  (parameters: GetObjectsParameters<DomainObject, OptionObject>) =>
    Promise<GetObjectsPageResult<OptionObject>>
