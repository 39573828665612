import { GetObjectsPageResult } from "../../../../../../sqadmin/features/objects/domain/results/GetObjectsPageResult"
import Sort from "../../../../../../sqadmin/features/objects/presentation/entities/tables/Sort"
import LastItemPagination from "../../../../../core/domain/entities/paginations/LastItemPagination"
import OrdersRepository from "../../repositories/OrdersRepository"
import Order from "../../../../../core/domain/entities/orders/Order"
import OrdersFilter from "../../../../../core/domain/entities/orders/OrdersFilter"

export default class GetOrdersUseCase {
  private readonly ordersRepository: OrdersRepository

  constructor(parameters: {
    readonly ordersRepository: OrdersRepository
  }) {
    this.ordersRepository = parameters.ordersRepository
  }

  async call(parameters: GetOrdersParameters): Promise<GetObjectsPageResult<Order>> {
    return this.ordersRepository.getOrders(parameters)
  }
}

export interface GetOrdersParameters {
  readonly filter?: OrdersFilter
  readonly sort?: Sort
  readonly pagination?: LastItemPagination
}
