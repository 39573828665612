import BackendHttpClient, { BackendHttpClientResult } from "../../network/BackendHttpClient"
import NetworkLastItemPagination from "../../entities/pagination/NetworkLastItemPagination"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import { instanceToPlain, plainToInstance } from "class-transformer"
import NetworkExecutionError from "../../entities/errors/NetworkExecutionError"
import SuccessExecutionResult from "../../../../../sqadmin/core/domain/results/SuccessExecutionResult"
import ErrorExecutionResult from "../../../../../sqadmin/core/domain/results/ErrorExecutionResult"
import FailureExecutionResult from "../../../../../sqadmin/core/domain/results/FailureExecutionResult"
import NetworkOptionsResponseBody from "../../entities/options/NetworkOptionsResponseBody"
import NetworkOptionsRequestQuery from "../../entities/options/NetworkOptionsRequestQuery"
import NetworkOptionsRequestFilter from "../../entities/options/NetworkOptionsRequestFilter"
import GetObjectNetworkResult from "../../results/GetObjectNetworkResult"
import CreateObjectNetworkResult from "../../results/CreateObjectNetworkResult"
import UpdateObjectNetworkResult from "../../results/UpdateObjectNetworkResult"
import DestroyObjectNetworkResult from "../../results/DestroyObjectNetworkResult"
import NetworkOption from "../../entities/options/NetworkOption"
import NetworkOptionResponseBody from "../../entities/options/NetworkOptionResponseBody"
import NetworkOptionError from "../../entities/options/NetworkOptionError"
import NetworkOptionRequestBody from "../../entities/options/NetworkOptionRequestBody"

const basePath = "/admin/options"

export default class OptionsNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async getOptions({
    filter,
    pagination,
    sort
  }: {
    readonly filter?: NetworkOptionsRequestFilter | null
    readonly pagination?: NetworkLastItemPagination | null
    readonly sort?: string | null
  }): Promise<GetOptionsNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: basePath,
      parameters: instanceToPlain(new NetworkOptionsRequestQuery({
        filter,
        pagination,
        sort
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkOptionsResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async getOption({
    optionId
  }: {
    readonly optionId: number
  }): Promise<GetObjectNetworkResult<NetworkOption>> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}/${optionId}`
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkOptionResponseBody, result.body).option!
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async createOption({
    option
  }: {
    readonly option: NetworkOption
  }): Promise<CreateObjectNetworkResult<NetworkOption, NetworkOptionError>> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.POST,
      path: basePath,
      body: instanceToPlain(new NetworkOptionRequestBody({
        option
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkOptionResponseBody, result.body).option!
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkOptionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async updateOption({
    optionId,
    option
  }: {
    readonly optionId: number
    readonly option: NetworkOption
  }): Promise<UpdateObjectNetworkResult<NetworkOption, NetworkOptionError>> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.PUT,
      path: `${basePath}/${optionId}`,
      body: instanceToPlain(new NetworkOptionRequestBody({
        option
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkOptionResponseBody, result.body).option!
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkOptionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async destroyOption({
    optionId
  }: {
    readonly optionId: number
  }): Promise<DestroyObjectNetworkResult<NetworkOptionError>> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.DELETE,
      path: `${basePath}/${optionId}`
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: undefined
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkOptionError, result.body)
        }
      case "failure":
        return result
    }
  }
}

export type GetOptionsNetworkResult =
  SuccessExecutionResult<NetworkOptionsResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult
