import NetworkBonusPointsBurningRule from "../entities/bonus-points-burning-rules/NetworkBonusPointsBurningRule"
import BonusPointsBurningRule from "../../domain/entities/bonus-points-burning-rules/BonusPointsBurningRule"

export default class BonusPointsBurningRulesMapper {
  mapNetworkToDomain({
    bonusPointsBurningRule
  }: {
    readonly bonusPointsBurningRule: NetworkBonusPointsBurningRule
  }): BonusPointsBurningRule {
    return {
      minEarnedSum: bonusPointsBurningRule.minEarnedSum,
      periodType: bonusPointsBurningRule.periodType
    }
  }

  mapDomainToNetwork({
    bonusPointsBurningRule
  }: {
    readonly bonusPointsBurningRule: BonusPointsBurningRule
  }): NetworkBonusPointsBurningRule {
    return new NetworkBonusPointsBurningRule({
      minEarnedSum: bonusPointsBurningRule.minEarnedSum,
      periodType: bonusPointsBurningRule.periodType
    })
  }
}
