import FormField, { FormFieldParameters, FormFieldViewState, FormFieldViewStateParameters } from "../FormField"
import { ListFormFieldDisplayType } from "../../../components/form-field-by-type/list-form-field/ListFormFieldComponent"
import isBlank from "../../../../../../lib/isBlank"
import isPresent from "../../../../../../lib/isPresent"

export default class ListFormField<DomainObject, ErrorsObject, NestedObject, NestedErrorsObject>
  extends FormField<DomainObject, ErrorsObject> {

  private readonly getValue: (object: DomainObject) => NestedObject[] | null | undefined
  private readonly setValue: (object: DomainObject, value: NestedObject[]) => DomainObject
  private readonly getNestedObjectId: (nestedObject: NestedObject) => string
  private readonly getNestedObjectTitle: (
    nestedObject: NestedObject,
    nestedObjectIndex: number
  ) => string | null | undefined

  private readonly getNestedErrorsObject: (
    nestedObject: NestedObject,
    errorsObject?: ErrorsObject
  ) => NestedErrorsObject | null | undefined

  readonly hasNestedErrorsObjects?: (
    errorsObject?: ErrorsObject
  ) => boolean

  private readonly fields: FormField<NestedObject, NestedErrorsObject>[]
  private readonly buildNewValue: (object: DomainObject) => NestedObject
  private objectFieldViewStateById: { [key: string]: ObjectFieldViewState<NestedObject> }

  private readonly getRemoveObjectVisible?: (nestedObject: NestedObject) => boolean
  private readonly isSwitchPositionVisible: boolean
  private readonly isAddObjectVisible: boolean
  private readonly isEditVisible: boolean
  private readonly addObjectButtonName: string | null | undefined
  private readonly displayType?: ListFormFieldDisplayType | null | undefined

  private editingFieldId: string | null | undefined
  readonly setPosition?: (nestedObject: NestedObject, position: number) => NestedObject
  readonly getPosition?: (nestedObject: NestedObject) => number

  constructor(parameters: FormFieldParameters<DomainObject, ErrorsObject> & {
    readonly getValue: (object: DomainObject) => NestedObject[] | null | undefined
    readonly setValue: (object: DomainObject, value: NestedObject[]) => DomainObject
    readonly getNestedObjectId: (nestedObject: NestedObject) => string
    readonly setPosition?: (nestedObject: NestedObject, position: number) => NestedObject
    readonly getPosition?: (nestedObject: NestedObject) => number
    readonly getNestedObjectTitle: (
      nestedObject: NestedObject,
      nestedObjectIndex: number
    ) => string | null | undefined
    readonly getNestedErrorsObject: (
      nestedObject: NestedObject,
      errorsObject?: ErrorsObject
    ) => NestedErrorsObject | null | undefined
    readonly hasNestedErrorsObjects?: (
      errorsObject?: ErrorsObject
    ) => boolean
    readonly fields: FormField<NestedObject, NestedErrorsObject>[]
    readonly buildNewValue: (object: DomainObject) => NestedObject
    readonly getRemoveObjectVisible?: (nestedObject: NestedObject) => boolean
    readonly isAddObjectVisible?: boolean
    readonly isSwitchPositionVisible?: boolean
    readonly isEditVisible?: boolean
    readonly addObjectButtonName?: string
    readonly displayType?: ListFormFieldDisplayType | null | undefined
  }) {
    super(parameters)

    this.fields = parameters.fields
    this.getValue = parameters.getValue
    this.setValue = parameters.setValue
    this.getNestedObjectId = parameters.getNestedObjectId
    this.getNestedObjectTitle = parameters.getNestedObjectTitle
    this.getNestedErrorsObject = parameters.getNestedErrorsObject
    this.buildNewValue = parameters.buildNewValue
    this.objectFieldViewStateById = {}
    this.getRemoveObjectVisible = parameters.getRemoveObjectVisible
    this.isAddObjectVisible = parameters.isAddObjectVisible ?? true
    this.addObjectButtonName = parameters.addObjectButtonName
    this.displayType = parameters.displayType
    this.setPosition = parameters.setPosition
    this.getPosition = parameters.getPosition
    this.isSwitchPositionVisible = parameters.isSwitchPositionVisible ?? false
    this.isEditVisible = parameters.isEditVisible ?? false
    this.hasNestedErrorsObjects = parameters.hasNestedErrorsObjects

    this.fields.forEach((field: FormField<NestedObject, NestedErrorsObject>) => {
      field.setGetObjectId(this.getNestedObjectId)

      field.setSetAndShowLoadedObjectViewState(() => {
        this.setAndShowLoadedObjectViewState()
      })

      field.setSetObject((nestedObject: NestedObject) => {
        this.objectFieldViewStateById[this.getNestedObjectId(nestedObject)].setObject(nestedObject)
      })
    })
  }

  getViewState(object: DomainObject, errorsObject?: ErrorsObject): FormFieldViewState {
    const nestedObjects: NestedObject[] = this.getValue(object) ?? []

    const objectFieldViewStates: ObjectFieldViewState<NestedObject>[] = nestedObjects.map(
      (nestedObject: NestedObject, nestedObjectIndex: number): ObjectFieldViewState<NestedObject> => {
        const nestedErrorsObject: NestedErrorsObject | undefined =
          this.getNestedErrorsObject(nestedObject, errorsObject) ?? undefined

        const hasErrorsObject: boolean = this.fields.find((field: FormField<NestedObject, NestedErrorsObject>) => {
          return field.getParentErrorsVisible(nestedObject, nestedErrorsObject)
        }) !== undefined

        return new ObjectFieldViewState({
          id: this.getNestedObjectId(nestedObject),
          title: this.getNestedObjectTitle(nestedObject, nestedObjectIndex),
          hasErrorsObject: hasErrorsObject,
          fieldViewStates: this.fields.map((field: FormField<NestedObject, NestedErrorsObject>) => {
            return field.getViewState(nestedObject, nestedErrorsObject)
          }),
          setObject: (newNestedObject: NestedObject) => {
            const newNestedObjects: NestedObject[] = [...nestedObjects]
            newNestedObjects[nestedObjectIndex] = newNestedObject
            this.setObject(this.setValue(object, newNestedObjects))
          },
          onEditingCanceled: () => {
            this.editingFieldId = null
            this.setAndShowLoadedObjectViewState()
          },
          editingId: this.editingFieldId
        })
      }
    )

    // todo не удаляются, если менять список вручную
    objectFieldViewStates.forEach((objectFieldViewState: ObjectFieldViewState<NestedObject>) => {
      this.objectFieldViewStateById = {
        ...this.objectFieldViewStateById,
        [objectFieldViewState.id]: objectFieldViewState
      }
    })

    return new ListFormFieldViewState({
      ...this.getFormFieldViewStateParameters(object, errorsObject),
      objectFieldViewStates,
      isParentErrorsVisible: this.hasNestedErrorsObjects?.(errorsObject) ?? false,
      addObjectButtonName: this.addObjectButtonName,
      displayType: this.displayType,
      onAddObjectClicked: () => {
        let newNestedObject = this.buildNewValue(object)

        if (this.isSwitchPositionVisible && isPresent(this.setPosition)) {
          const maxPosition = this.findLastPosition(nestedObjects)
          newNestedObject = this.setPosition(newNestedObject, maxPosition + 1)
        }

        const newNestedObjects: NestedObject[] = [...nestedObjects]
        newNestedObjects.push(newNestedObject)

        this.setObject(this.setValue(object, newNestedObjects))
        this.setAndShowLoadedObjectViewState()
      },
      onRemoveObjectClicked: (id: string) => {
        const newNestedObjects: NestedObject[] = [...nestedObjects]
          .filter((object) => {
            const nestedObjectId = this.getNestedObjectId(object)
            return nestedObjectId !== id
          })

        const objectFieldViewStateById = {
          ...this.objectFieldViewStateById
        }

        delete objectFieldViewStateById[id]
        this.objectFieldViewStateById = objectFieldViewStateById

        this.setObject(this.setValue(object, newNestedObjects))
        this.setAndShowLoadedObjectViewState()
      },
      onPositionSwitched: (firstId: string, secondId: string) => {
        const firstNestedObject: NestedObject | undefined = nestedObjects
          .find((object) => {
            return this.getNestedObjectId(object) === firstId
          })

        const secondNestedObject: NestedObject | undefined = nestedObjects
          .find((object) => {
            return this.getNestedObjectId(object) === secondId
          })

        if (isBlank(firstNestedObject) || isBlank(secondNestedObject)) return

        const newNestedObjects: NestedObject[] = nestedObjects
          .map((object) => {
            const nestedObjectId = this.getNestedObjectId(object)

            if (firstId === nestedObjectId) {
              return this.setPosition!(object, this.getPosition!(secondNestedObject))
            }

            if (secondId === nestedObjectId) {
              return this.setPosition!(object, this.getPosition!(firstNestedObject))
            }

            return object
          }).sort((a, b) => this.getPosition!(a) - this.getPosition!(b))

        this.setObject(this.setValue(object, newNestedObjects))
        this.setAndShowLoadedObjectViewState()
      },
      onEditClicked: (id: string) => {
        this.editingFieldId = id
        this.setAndShowLoadedObjectViewState()
      },
      getRemoveObjectVisible: (id: string) => {
        const nestedObject: NestedObject | null | undefined = [...nestedObjects]
          .find((object) => {
            const nestedObjectId = this.getNestedObjectId(object)
            return nestedObjectId === id
          })

        return isBlank(nestedObject) || isBlank(this.getRemoveObjectVisible) ?
          true : this.getRemoveObjectVisible(nestedObject)
      },
      isAddObjectVisible: this.isAddObjectVisible,
      isSwitchPositionVisible: this.isSwitchPositionVisible,
      isEditVisible: this.isEditVisible
    })
  }

  private findLastPosition(nestedObjects: NestedObject[]) {
    let maxPosition = 0
    nestedObjects?.forEach((nestedObject: NestedObject) => {
      const position = this.getPosition?.(nestedObject)
      if (isPresent(position) && maxPosition <= position) {
        maxPosition = position
      }
    })
    return maxPosition
  }

  prepareObject(object: DomainObject): DomainObject {
    if (!this.isSwitchPositionVisible || isBlank(this.setPosition) || isBlank(this.getPosition)) {
      return super.prepareObject(object)
    }

    const nestedObjects = this.getValue(object) ?? []
    let maxPosition = this.findLastPosition(nestedObjects)
    const updatedNestedObjects = nestedObjects
      .map((nestedObject: NestedObject) => {
        const position = this.getPosition!(nestedObject)
        if (isPresent(position)) {
          return nestedObject
        } else {
          maxPosition = maxPosition + 1
          return this.setPosition!(nestedObject, maxPosition)
        }
      })
      .map((nestedObject: NestedObject) => {
        return this.fields.reduce((result, formField) => formField.prepareObject(result), nestedObject)
      })
      .sort((a, b) => this.getPosition!(a) - this.getPosition!(b))

    return this.setValue(object, updatedNestedObjects)
  }

  getParentErrorsVisible(object: DomainObject, errorsObject?: ErrorsObject): boolean {
    return super.getParentErrorsVisible(object, errorsObject) || isPresent(this.hasNestedErrorsObjects) &&
      this.hasNestedErrorsObjects(errorsObject)
  }
}

export class ListFormFieldViewState<DomainObject> extends FormFieldViewState {
  private readonly objectFieldViewStates: ObjectFieldViewState<DomainObject>[]
  readonly onAddObjectClicked: () => void
  readonly onRemoveObjectClicked: (id: string) => void
  readonly onPositionSwitched: (firstId: string, secondId: string) => void
  readonly onEditClicked: (id: string) => void
  readonly addObjectButtonName: string | null | undefined
  readonly getRemoveObjectVisible: (id: string) => boolean
  readonly isAddObjectVisible: boolean
  readonly isSwitchPositionVisible: boolean
  readonly isEditVisible: boolean
  readonly displayType?: ListFormFieldDisplayType | null | undefined
  readonly isParentErrorsVisible: boolean

  constructor(parameters: FormFieldViewStateParameters & {
    readonly objectFieldViewStates: ObjectFieldViewState<DomainObject>[]
    readonly onAddObjectClicked: () => void
    readonly onRemoveObjectClicked: (id: string) => void
    readonly onPositionSwitched: (firstId: string, secondId: string) => void
    readonly onEditClicked: (id: string) => void
    readonly addObjectButtonName: string | null | undefined
    readonly getRemoveObjectVisible: (id: string) => boolean
    readonly isAddObjectVisible: boolean
    readonly isSwitchPositionVisible: boolean
    readonly isEditVisible: boolean
    readonly displayType?: ListFormFieldDisplayType | null | undefined
    readonly isParentErrorsVisible: boolean
  }) {
    super(parameters)
    this.objectFieldViewStates = parameters.objectFieldViewStates
    this.onAddObjectClicked = parameters.onAddObjectClicked
    this.onRemoveObjectClicked = parameters.onRemoveObjectClicked
    this.onPositionSwitched = parameters.onPositionSwitched
    this.onEditClicked = parameters.onEditClicked
    this.getRemoveObjectVisible = parameters.getRemoveObjectVisible
    this.isAddObjectVisible = parameters.isAddObjectVisible
    this.addObjectButtonName = parameters.addObjectButtonName
    this.displayType = parameters.displayType
    this.isSwitchPositionVisible = parameters.isSwitchPositionVisible
    this.isEditVisible = parameters.isEditVisible
    this.isParentErrorsVisible = parameters.isParentErrorsVisible
  }

  getObjectFieldViewStates(): ObjectFieldViewState<DomainObject>[] {
    return this.objectFieldViewStates
  }
}

export class ObjectFieldViewState<DomainObject> {
  readonly id: string
  readonly title: string | null | undefined
  readonly fieldViewStates: FormFieldViewState[]
  readonly editingId: string | null | undefined
  readonly hasErrorsObject: boolean
  readonly setObject: (newNestedObject: DomainObject) => void
  readonly onEditingCanceled: () => void

  constructor(parameters: {
    readonly id: string
    readonly title: string | null | undefined
    readonly editingId: string | null | undefined
    readonly hasErrorsObject: boolean
    readonly fieldViewStates: FormFieldViewState[]
    readonly setObject: (newNestedObject: DomainObject) => void
    readonly onEditingCanceled: () => void
  }) {
    this.id = parameters.id
    this.title = parameters.title
    this.fieldViewStates = parameters.fieldViewStates
    this.editingId = parameters.editingId
    this.hasErrorsObject = parameters.hasErrorsObject
    this.setObject = parameters.setObject
    this.onEditingCanceled = parameters.onEditingCanceled
  }
}
