import SettlementsRepository from "../../domain/repositories/SettlementsRepository"
import { GetSettlementsParameters, GetSettlementsResult } from "../../domain/use-cases/GetSettlementsUseCase"
import SettlementsMapper from "../../../../core/data/mappers/SettlementsMapper"
import NetworkSettlement from "../../../../core/data/entities/settlements/NetworkSettlement"
import LastItemPaginationsMapper from "../../../../core/data/mappers/LastItemPaginationsMapper"
import SettlementsNetworkSource, { GetSettlementsNetworkResult } from "../sources/SettlementsNetworkSource"
import SettlementsFiltersMapper from "../../../../core/data/mappers/SettlementsFiltersMapper"

export default class DefaultSettlementsRepository implements SettlementsRepository {
  private readonly settlementsNetworkSource: SettlementsNetworkSource

  constructor(parameters: {
    readonly settlementsNetworkSource: SettlementsNetworkSource
  }) {
    this.settlementsNetworkSource = parameters.settlementsNetworkSource
  }

  async getSettlements({
    query,
    pagination
  }: GetSettlementsParameters): Promise<GetSettlementsResult> {
    const result: GetSettlementsNetworkResult = await this.settlementsNetworkSource.getSettlements({
      filter: new SettlementsFiltersMapper().mapDomainToNetwork({ query }),
      pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({ pagination })
    })

    switch (result.type) {
      case "success": {
        const settlementsMapper = new SettlementsMapper()

        return {
          type: "success",
          data: {
            objects: result.data.settlements!.map((settlement: NetworkSettlement) => {
              return settlementsMapper.mapNetworkToDomain({ settlement })
            }),
            page: {
              hasMore: false
            }
          }
        }
      }
      default:
        return result
    }
  }
}
