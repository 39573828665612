import ObjectsEvent from "../../entities/ObjectsEvent"
import ObjectsRepository from "../../repositories/ObjectsRepository"

export default class BroadcastObjectsEventUseCase {
  private readonly objectsRepository: ObjectsRepository

  constructor(parameters: {
    readonly objectsRepository: ObjectsRepository
  }) {
    this.objectsRepository = parameters.objectsRepository
  }

  call(objectsEvent: ObjectsEvent) {
    this.objectsRepository.broadcastObjectsEvent(objectsEvent)
  }
}
