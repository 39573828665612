import { Expose, Type } from "class-transformer"
import NetworkConfigurationEntityPolicy from "./NetworkConfigurationEntityPolicy"
import NetworkConfigurationEntityProperty from "./NetworkConfigurationEntityProperty"

export default class NetworkConfigurationEntity {

  @Expose()
  @Type(() => NetworkConfigurationEntityProperty)
  readonly properties: NetworkConfigurationEntityProperty[] | null | undefined

  @Expose()
  @Type(() => NetworkConfigurationEntityPolicy)
  readonly policy: NetworkConfigurationEntityPolicy | null | undefined

  @Expose()
  readonly name: string | null | undefined

  constructor(parameters?: {
    readonly policy: NetworkConfigurationEntityPolicy | null | undefined
    readonly properties: NetworkConfigurationEntityProperty[] | null | undefined
    readonly name: string | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.policy = parameters.policy
    this.name = parameters.name
    this.properties = parameters.properties
  }
}
