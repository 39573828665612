import { CreateObjectResult } from "../../../../../../sqadmin/features/objects/domain/results/CreateObjectResult"
import ProductImagesRepository from "../../repositories/ProductImagesRepository"
import Image from "../../../../../core/domain/entities/images/Image"
import ImageError from "../../../../../core/domain/entities/images/ImageError"

export default class CreateProductImageUseCase {
  private readonly productImagesRepository: ProductImagesRepository

  constructor(parameters: {
    readonly productImagesRepository: ProductImagesRepository
  }) {
    this.productImagesRepository = parameters.productImagesRepository
  }

  async call({ file }: { readonly file: File }): Promise<CreateObjectResult<Image, ImageError>> {
    return await this.productImagesRepository.createImage({ file })
  }
}
