import { LoadObjectResult } from "../../../../../sqadmin/features/objects/domain/results/LoadObjectResult"
import Place from "../../../../core/domain/entities/places/Place"
import PlacesRepository from "../repositories/PlacesRepository"

export default class GetPlaceUseCase {
  private readonly placesRepository: PlacesRepository

  constructor(parameters: {
    readonly placesRepository: PlacesRepository
  }) {
    this.placesRepository = parameters.placesRepository
  }

  async call(parameters: {
    readonly placeId: number
  }): Promise<LoadObjectResult<Place>> {
    return await this.placesRepository.getPlace(parameters)
  }
}
