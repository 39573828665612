import React, { useMemo } from "react"
import { v4 as uuidv4 } from "uuid"
import styles from "./CheckboxComponent.module.scss"
import TextComponent, { TextStyle } from "../text/TextComponent"

export default function CheckboxComponent({
  value,
  text,
  onChange,
  isDisabled
}: {
  readonly value: boolean
  readonly text: string
  readonly isDisabled: boolean
  readonly onChange: (value: boolean) => void
}) {
  const uuid = useMemo(() => uuidv4(), [])

  function handleOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    onChange(event.target.checked)
  }

  return (
    <div className={styles.checkboxContainer}>

      <input
        className={styles.checkbox}
        id={uuid}
        type="checkbox"
        checked={value}
        disabled={isDisabled}
        onChange={handleOnChange}
      />

      <label htmlFor={uuid} style={isDisabled ? { opacity: 0.5 } : undefined}>
        <TextComponent textStyle={TextStyle.CHECKBOX1_PRIMARY}>
          {text}
        </TextComponent>
      </label>
    </div>
  )
}
