import NetworkPaymentStatus from "../entities/payment-statuses/NetworkPaymentStatus"
import PaymentStatus from "../../domain/entities/payment-statuses/PaymentStatus"

export default class PaymentStatusesMapper {
  mapNetworkToDomain({
    paymentStatus
  }: {
    readonly paymentStatus: NetworkPaymentStatus
  }): PaymentStatus {
    return {
      id: paymentStatus.id,
      name: paymentStatus.name,
      externalCode: paymentStatus.externalCode,
      displayName: paymentStatus.displayName
    }
  }

  mapDomainToNetwork({
    paymentStatus
  }: {
    readonly paymentStatus: PaymentStatus
  }): NetworkPaymentStatus {
    return new NetworkPaymentStatus({
      id: paymentStatus.id,
      name: paymentStatus.name,
      externalCode: paymentStatus.externalCode,
      displayName: paymentStatus.displayName
    })
  }
}
