import { CreateObjectResult } from "../../../../../sqadmin/features/objects/domain/results/CreateObjectResult"
import PropertiesRepository from "../repositories/PropertiesRepository"
import Property from "../../../../core/domain/entities/properties/Property"
import PropertyError from "../../../../core/domain/entities/properties/PropertyError"

export default class CreatePropertyUseCase {
  private readonly propertiesRepository: PropertiesRepository

  constructor(parameters: {
    readonly propertiesRepository: PropertiesRepository
  }) {
    this.propertiesRepository = parameters.propertiesRepository
  }

  async call(parameters: CreatePropertyParameters): Promise<CreatePropertyResult> {
    return await this.propertiesRepository.createProperty(parameters)
  }
}

export interface CreatePropertyParameters {
  readonly property: Property
}

export type CreatePropertyResult = CreateObjectResult<Property, PropertyError>
