import React from "react"
import { useAuthenticationTextProvider } from "../../contexts/AuthenticationTextProviderContext"
import AuthenticationTextProvider from "../../../i18n/AuthenticationTextProvider"
import CreateSessionUserPartial from "../../../../../core/domain/entities/CreateSessionUserPartial"
import ApplicationException from "../../../../../core/domain/exceptions/ApplicationException"
import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import { useCoreTextProvider } from "../../../../../core/presentation/contexts/CoreTextProviderContext"
import AttributeError from "../../../../../core/domain/entities/AttributeError"
import { CreateSessionError } from "../../../../../core/domain/use-cases/sessions/CreateSessionUseCase"
import HeadingComponent from "../../../../../core/presentation/components/heading/HeadingComponent"
import TextComponent, { TextStyle } from "../../../../../design/text/TextComponent"
import ButtonComponent, { ButtonStyle, ButtonType } from "../../../../../design/button/ButtonComponent"
import styles from "./AuthenticationComponent.module.scss"
import CoreTheme from "../../../../../core/presentation/entities/CoreTheme"
import { useCoreTheme } from "../../../../../core/presentation/contexts/CoreThemeProviderContext"
import { TextInputComponent, TextInputStyle, TextInputType } from "../../../../../design/text-input/TextInputComponent"

interface Props {
  readonly onEmailAddressChanged: (parameters: { readonly emailAddress: string }) => void
  readonly onPasswordChanged: (parameters: { readonly password: string }) => void
  readonly onAuthenticateClicked: () => void
  readonly user: CreateSessionUserPartial
  readonly isAuthenticating: boolean
  readonly authenticationError?: CreateSessionError
  readonly authenticationFailureException?: ApplicationException
}

export default function AuthenticationComponent({
  onEmailAddressChanged,
  onPasswordChanged,
  onAuthenticateClicked,
  user,
  isAuthenticating,
  authenticationError,
  authenticationFailureException
}: Props) {
  const coreTextProvider: CoreTextProvider = useCoreTextProvider()
  const authenticationTextProvider: AuthenticationTextProvider = useAuthenticationTextProvider()
  const isFormDisabled = isAuthenticating
  const emailAddressAttributeErrors = authenticationError?.errorsObject?.attributes?.emailAddress
  const passwordAttributeErrors = authenticationError?.errorsObject?.attributes?.password
  const hasEmailAddressErrors = (emailAddressAttributeErrors && emailAddressAttributeErrors.length > 0) ?? false
  const hasPasswordErrors = (passwordAttributeErrors && passwordAttributeErrors.length > 0) ?? false

  const coreTheme: CoreTheme = useCoreTheme()

  function handleEmailAddressChange(value: string) {
    onEmailAddressChanged({ emailAddress: value })
  }

  function handlePasswordChange(value: string) {
    onPasswordChanged({ password: value })
  }

  function handleOnSubmit(event: React.FormEvent) {
    event.preventDefault()
    onAuthenticateClicked()
  }

  return (
    <div className={styles.root}>
      <form onSubmit={handleOnSubmit} className={styles.formContainer}>
        <div className={styles.formHeader}>
          <img src={coreTheme.logoUrl} />
          <HeadingComponent>
            <TextComponent textStyle={TextStyle.HEADER1_PRIMARY}>
              {authenticationTextProvider.authentication()}
            </TextComponent>
          </HeadingComponent>
        </div>
        <div className={styles.formFields}>
          <div className={styles.formRow}>
            <div className={styles.title}>
              <TextComponent
                textStyle={TextStyle.LABEL1_PRIMARY}
              >
                {authenticationTextProvider.emailAddress()}
              </TextComponent>
            </div>
            <TextInputComponent
              textInputStyle={TextInputStyle.TEXT_INPUT1_PRIMARY}
              hasError={hasEmailAddressErrors}
              value={user.emailAddress ?? ""}
              type={TextInputType.EMAIL}
              onChange={handleEmailAddressChange}
              placeholder={undefined}
            />
            {hasEmailAddressErrors && (
              <div>
                {emailAddressAttributeErrors!.map((attributeError: AttributeError) => (
                  <div key={attributeError.message} className={styles.error}>
                    <TextComponent textStyle={TextStyle.ERROR1_PRIMARY}>{attributeError.message}</TextComponent>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className={styles.formRow}>
            <div className={styles.title}>
              <TextComponent textStyle={TextStyle.LABEL1_PRIMARY}>
                {authenticationTextProvider.password()}
              </TextComponent>
            </div>
            <TextInputComponent
              textInputStyle={TextInputStyle.TEXT_INPUT1_PRIMARY}
              hasError={hasPasswordErrors}
              value={user.password ?? ""}
              type={TextInputType.PASSWORD}
              onChange={handlePasswordChange}
              placeholder={undefined}
            />
            {hasPasswordErrors && (
              <div>
                {passwordAttributeErrors!.map((attributeError: AttributeError) => (
                  <div key={attributeError.message} className={styles.error}>
                    <TextComponent textStyle={TextStyle.ERROR1_PRIMARY}>{attributeError.message}</TextComponent>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <ButtonComponent
          buttonStyle={ButtonStyle.FILLED1_PRIMARY}
          type={ButtonType.SUBMIT}
          text={authenticationTextProvider.authenticate()}
          isDisabled={isFormDisabled}
        />
        {authenticationFailureException && (
          <div>{coreTextProvider.somethingWentWrong()}</div>
        )}
        {authenticationError && (
          <div className={styles.error}>
            <TextComponent textStyle={TextStyle.ERROR1_PRIMARY}>
              {authenticationError.message ?? coreTextProvider.somethingWentWrong()}
            </TextComponent>
          </div>
        )}
      </form>
    </div>
  )
}
