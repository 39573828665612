import OrderShippingMethod from "../../domain/entities/order-shipping-methods/OrderShippingMethod"
import NetworkOrderShippingMethod from "../entities/order-shipping-methods/NetworkOrderShippingMethod"
import SettlementsMapper from "./SettlementsMapper"
import OrderShippingTariffsMapper from "./OrderShippingTariffsMapper"

export default class OrderShippingMethodsMapper {
  mapNetworkToDomain({
    orderShippingMethod
  }: {
    readonly orderShippingMethod: NetworkOrderShippingMethod
  }): OrderShippingMethod {
    return {
      id: orderShippingMethod.id,
      type: orderShippingMethod.type,
      settlementId: orderShippingMethod.settlementId,
      settlement: orderShippingMethod.settlement && new SettlementsMapper().mapNetworkToDomain({
        settlement: orderShippingMethod.settlement
      }),
      orderShippingTariff: orderShippingMethod.orderShippingTariff && new OrderShippingTariffsMapper()
        .mapNetworkToDomain({ orderShippingTariff: orderShippingMethod.orderShippingTariff }),
      orderShippingTariffId: orderShippingMethod.orderShippingTariffId
    }
  }

  mapDomainToNetwork({
    orderShippingMethod
  }: {
    readonly orderShippingMethod: OrderShippingMethod
  }): NetworkOrderShippingMethod {
    return new NetworkOrderShippingMethod({
      id: orderShippingMethod.id,
      type: orderShippingMethod.type,
      settlementId: orderShippingMethod.settlementId,
      settlement: orderShippingMethod.settlement && new SettlementsMapper().mapDomainToNetwork({
        settlement: orderShippingMethod.settlement
      }),
      orderShippingTariff: orderShippingMethod.orderShippingTariff && new OrderShippingTariffsMapper()
        .mapDomainToNetwork({ orderShippingTariff: orderShippingMethod.orderShippingTariff }),
      orderShippingTariffId: orderShippingMethod.orderShippingTariffId
    })
  }
}
