import { v4 as uuidv4 } from "uuid"
import NetworkBatch from "../entities/batches/NetworkBatch"
import Batch from "../../domain/entities/batch/Batch"
import BatchPlacesMapper from "./BatchPlacesMapper"
import ProductVariantsMapper from "./ProductVariantsMapper"

export default class BatchesMapper {
  mapNetworkToDomain({
    batch
  }: {
    readonly batch: NetworkBatch
  }): Batch {
    return {
      id: batch.id,
      externalCode: batch.externalCode,
      price: batch.price,
      clientId: uuidv4(),
      originalPrice: batch.originalPrice,
      bonusPointsPrice: batch.bonusPointsPrice,
      batchPlaces: batch.batchPlaces && batch.batchPlaces.map(batchPlace => {
        return new BatchPlacesMapper().mapNetworkToDomain({ batchPlace: batchPlace })
      }),
      variant: batch.variant && new ProductVariantsMapper()
        .mapNetworkToDomain({ productVariant: batch.variant })
    }
  }

  mapDomainToNetwork({
    batch
  }: {
    readonly batch: Batch
  }): NetworkBatch {
    return new NetworkBatch({
      id: batch.id,
      externalCode: batch.externalCode,
      price: batch.price,
      clientId: batch.clientId,
      originalPrice: batch.originalPrice,
      bonusPointsPrice: batch.bonusPointsPrice,
      batchPlaces: batch.batchPlaces && batch.batchPlaces.map(batchPlace => {
        return new BatchPlacesMapper().mapDomainToNetwork({ batchPlace: batchPlace })
      }),
      variant: batch.variant && new ProductVariantsMapper()
        .mapDomainToNetwork({ productVariant: batch.variant })
    })
  }
}
