import BonusProgramLevelTransitionRule
  from "../../domain/entities/bonus-program-level-transition-rules/BonusProgramLevelTransitionRule"
import NetworkBonusProgramLevelTransitionRule
  from "../entities/bonus-program-level-transition-rules/NetworkBonusProgramLevelTransitionRule"
import ProductsSetsMapper from "./ProductsSetsMapper"

export default class BonusProgramLevelTransitionRulesMapper {
  mapNetworkToDomain({
    bonusProgramLevelTransitionRule
  }: {
    readonly bonusProgramLevelTransitionRule: NetworkBonusProgramLevelTransitionRule
  }): BonusProgramLevelTransitionRule {
    return {
      type: bonusProgramLevelTransitionRule.type,
      periodsCount: bonusProgramLevelTransitionRule.periodsCount,
      periodType: bonusProgramLevelTransitionRule.periodType,
      checkingValue: bonusProgramLevelTransitionRule.checkingValue,
      checkingValueType: bonusProgramLevelTransitionRule.checkingValueType,
      productsSets: bonusProgramLevelTransitionRule.productsSets && bonusProgramLevelTransitionRule.productsSets
        .map(productsSet => new ProductsSetsMapper().mapNetworkToDomain({ productsSet: productsSet }))
    }
  }

  mapDomainToNetwork({
    bonusProgramLevelTransitionRule
  }: {
    readonly bonusProgramLevelTransitionRule: BonusProgramLevelTransitionRule
  }): NetworkBonusProgramLevelTransitionRule {
    return new NetworkBonusProgramLevelTransitionRule({
      type: bonusProgramLevelTransitionRule.type,
      periodsCount: bonusProgramLevelTransitionRule.periodsCount,
      periodType: bonusProgramLevelTransitionRule.periodType,
      checkingValue: bonusProgramLevelTransitionRule.checkingValue,
      checkingValueType: bonusProgramLevelTransitionRule.checkingValueType,
      productsSets: bonusProgramLevelTransitionRule.productsSets && bonusProgramLevelTransitionRule.productsSets
        .map(productsSet => new ProductsSetsMapper().mapDomainToNetwork({ productsSet: productsSet }))
    })
  }
}
