import React, { createContext, useContext } from "react"
import CoreTheme from "../entities/CoreTheme"

export const CoreThemeContext: React.Context<CoreTheme | null> =
  createContext<CoreTheme | null>(null)

export function useCoreTheme(): CoreTheme {
  return useContext(CoreThemeContext)!
}

export interface CoreThemeProviderProps {
  readonly children?: React.ReactNode
  readonly coreTheme: CoreTheme
}

export function CoreThemeProvider({
  children,
  coreTheme
}: CoreThemeProviderProps) {
  return (
    <CoreThemeContext.Provider value={coreTheme}>
      {children}
    </CoreThemeContext.Provider>
  )
}
