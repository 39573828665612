import { Expose, Type } from "class-transformer"
import OrderPaymentMethodErrorsObject
  from "../../../domain/entities/order-payment-methods/OrderPaymentMethodErrorsObject"
import NetworkOrderPaymentMethodErrorsObjectAttributes from "./NetworkOrderPaymentMethodErrorsObjectAttributes"

export default class NetworkOrderPaymentMethodErrorsObject implements OrderPaymentMethodErrorsObject {
  @Expose()
  @Type(() => NetworkOrderPaymentMethodErrorsObjectAttributes)
  readonly attributes: NetworkOrderPaymentMethodErrorsObjectAttributes | null | undefined
}
