import NetworkCreateSessionUserPartial from "./NetworkCreateSessionUserPartial"
import { Expose, Type } from "class-transformer"

export default class NetworkCreateSessionRequestBody {
  @Expose()
  @Type(() => NetworkCreateSessionUserPartial)
  readonly user?: NetworkCreateSessionUserPartial | null

  constructor(parameters: {
    readonly user?: NetworkCreateSessionUserPartial | null
  }) {
    this.user = parameters.user
  }
}
