import React from "react"
import { ImageFormFieldViewState } from "../../../entities/form-fields/form-field-by-type/ImageFormField"
import CoreTextProvider from "../../../../../../core/i18n/CoreTextProvider"
import { useCoreTextProvider } from "../../../../../../core/presentation/contexts/CoreTextProviderContext"
import ImagePickerComponent from "../../../../../../core/presentation/components/image-picker/ImagePickerComponent"
import styles from "./ImageFormFieldComponent.module.scss"
import TextComponent, { TextStyle } from "../../../../../../design/text/TextComponent"

export default function ImageFormFieldComponent({
  fieldViewState,
  isDisabled
}: {
  readonly fieldViewState: ImageFormFieldViewState
  readonly isDisabled: boolean
}) {
  const coreTextProvider: CoreTextProvider = useCoreTextProvider()
  return (
    <div className={styles.root}>
      {
        fieldViewState.getTitle() && (
          <div className={styles.title}>
            <TextComponent textStyle={TextStyle.LABEL1_PRIMARY}>{fieldViewState.getTitle()}</TextComponent>
          </div>
        )
      }
      {!fieldViewState.imageOptionsExistAndLoading && (
        <ImagePickerComponent
          imageOptions={fieldViewState.imageOptions}
          disabled={isDisabled}
          onChange={fieldViewState.onChange}
          hasError={fieldViewState.hasErrors}
          isUploadingFile={fieldViewState.isUploadingFile}
          selectButtonText={coreTextProvider.select()}
          errorMessage={coreTextProvider.somethingWentWrong()}
        />
      )}

      {fieldViewState.imageOptionsExistAndLoading && (
        <TextComponent textStyle={TextStyle.LABEL1_SECONDARY}>{coreTextProvider.imageLoading()}</TextComponent>
      )}

    </div>
  )
}
