import { GetObjectsPageResult } from "../../../../../sqadmin/features/objects/domain/results/GetObjectsPageResult"
import Sort from "../../../../../sqadmin/features/objects/presentation/entities/tables/Sort"
import LastItemPagination from "../../../../core/domain/entities/paginations/LastItemPagination"
import PaymentStatusesRepository from "../repositories/PaymentStatusesRepository"
import PaymentStatus from "../../../../core/domain/entities/payment-statuses/PaymentStatus"
import PaymentStatusesFilter from "../../../../core/domain/entities/payment-statuses/PaymentStatusesFilter"

export default class GetPaymentStatusesUseCase {
  private readonly paymentStatusesRepository: PaymentStatusesRepository

  constructor(parameters: {
    readonly paymentStatusesRepository: PaymentStatusesRepository
  }) {
    this.paymentStatusesRepository = parameters.paymentStatusesRepository
  }

  async call(parameters: GetPaymentStatusesParameters): Promise<GetObjectsPageResult<PaymentStatus>> {
    return this.paymentStatusesRepository.getPaymentStatuses(parameters)
  }
}

export interface GetPaymentStatusesParameters {
  readonly filter?: PaymentStatusesFilter
  readonly sort?: Sort
  readonly pagination?: LastItemPagination
}
