import DiModule from "../../../../sqadmin/lib/di/DiModule"
import GetPropertyValuesUseCase from "../domain/use-cases/GetPropertyValuesUseCase"
import PropertyValuesCoreDataDiModule from "./DefaultPropertyValuesCoreDataDiModule"

export default interface PropertyValuesCoreDomainDiModule {
  provideGetPropertyValuesUseCase(): GetPropertyValuesUseCase
}

export class DefaultPropertyValuesCoreDomainDiModule extends DiModule implements PropertyValuesCoreDomainDiModule {
  private readonly propertyValuesCoreDataDiModule: PropertyValuesCoreDataDiModule

  constructor(parameters: {
    readonly propertyValuesCoreDataDiModule: PropertyValuesCoreDataDiModule
  }) {
    super()
    this.propertyValuesCoreDataDiModule = parameters.propertyValuesCoreDataDiModule
  }

  provideGetPropertyValuesUseCase(): GetPropertyValuesUseCase {
    return this.single(
      GetPropertyValuesUseCase.name,
      () => new GetPropertyValuesUseCase({
        propertyValuesRepository: this.propertyValuesCoreDataDiModule.providePropertyValuesRepository()
      })
    )
  }
}
