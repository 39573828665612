import { UpdateObjectResult } from "../../../../../sqadmin/features/objects/domain/results/UpdateObjectResult"
import PropertiesRepository from "../repositories/PropertiesRepository"
import Property from "../../../../core/domain/entities/properties/Property"
import PropertyError from "../../../../core/domain/entities/properties/PropertyError"

export default class UpdatePropertyUseCase {
  private readonly propertiesRepository: PropertiesRepository

  constructor(parameters: {
    readonly propertiesRepository: PropertiesRepository
  }) {
    this.propertiesRepository = parameters.propertiesRepository
  }

  async call(parameters: UpdatePropertyParameters): Promise<UpdatePropertyResult> {
    return await this.propertiesRepository.updateProperty(parameters)
  }
}

export interface UpdatePropertyParameters {
  readonly propertyId: number
  readonly property: Property
}

export type UpdatePropertyResult = UpdateObjectResult<Property, PropertyError>
