import React from "react"
import DiModule from "../../../../../sqadmin/lib/di/DiModule"
import ObjectsDomainDiModule from "../../../../../sqadmin/features/objects/di/modules/ObjectsDomainDiModule"
import CoreI18nDiModule from "../../../../core/di/modules/CoreI18nDiModule"
import autoBind from "auto-bind"
import ObjectsPresentationLogicParameters
  from "../../../../../sqadmin/features/objects/presentation/entities/objects/ObjectsPresentationLogicParameters"
import BonusProgramLevelsCoreDomainDiModule
  from "../../../bonus-program-levels-core/di/BonusProgramLevelsCoreDomainDiModule"
import BonusProgramLevelsPage from "../../presentation/pages/bonus-program-levels-page/BonusProgramLevelsPage"
import BonusProgramLevelsViewModel from "../../presentation/pages/bonus-program-levels-page/BonusProgramLevelsViewModel"
import BonusProgramLevelViewModel from "../../presentation/pages/bonus-program-level-page/BonusProgramLevelViewModel"
import BonusProgramLevelPage from "../../presentation/pages/bonus-program-level-page/BonusProgramLevelPage"
import PropertiesCoreDomainDiModule from "../../../properties-core/di/PropertiesCoreDomainDiModule"
import PropertyValuesCoreDomainDiModule from "../../../property-values-core/di/PropertyValuesCoreDomainDiModule"
import ProductCategoriesCoreDomainDiModule
  from "../../../product-categories-core/di/ProductCategoriesCoreDomainDiModule"
import ProductsCoreDomainDiModule from "../../../products-core/di/ProductsCoreDomainDiModule"

export default interface BonusProgramLevelsPresentationDiModule {
  provideBonusProgramLevelsPage(): React.ReactNode

  provideBonusProgramLevelPage(): React.ReactNode
}

export class DefaultBonusProgramLevelsPresentationDiModule
  extends DiModule
  implements BonusProgramLevelsPresentationDiModule {

  private readonly objectsDomainDiModule: ObjectsDomainDiModule
  private readonly bonusProgramLevelsCoreDomainDiModule: BonusProgramLevelsCoreDomainDiModule
  private readonly propertiesCoreDomainDiModule: PropertiesCoreDomainDiModule
  private readonly propertyValuesCoreDomainDiModule: PropertyValuesCoreDomainDiModule
  private readonly productCategoriesCoreDomainDiModule: ProductCategoriesCoreDomainDiModule
  private readonly productsCoreDomainDiModule: ProductsCoreDomainDiModule
  private readonly coreI18nDiModule: CoreI18nDiModule

  constructor(parameters: {
    readonly objectsDomainDiModule: ObjectsDomainDiModule
    readonly bonusProgramLevelsCoreDomainDiModule: BonusProgramLevelsCoreDomainDiModule
    readonly propertiesCoreDomainDiModule: PropertiesCoreDomainDiModule
    readonly propertyValuesCoreDomainDiModule: PropertyValuesCoreDomainDiModule
    readonly productCategoriesCoreDomainDiModule: ProductCategoriesCoreDomainDiModule
    readonly productsCoreDomainDiModule: ProductsCoreDomainDiModule
    readonly coreI18nDiModule: CoreI18nDiModule
  }) {
    super()
    autoBind(this)
    this.objectsDomainDiModule = parameters.objectsDomainDiModule
    this.bonusProgramLevelsCoreDomainDiModule = parameters.bonusProgramLevelsCoreDomainDiModule
    this.propertiesCoreDomainDiModule = parameters.propertiesCoreDomainDiModule
    this.propertyValuesCoreDomainDiModule = parameters.propertyValuesCoreDomainDiModule
    this.productCategoriesCoreDomainDiModule = parameters.productCategoriesCoreDomainDiModule
    this.productsCoreDomainDiModule = parameters.productsCoreDomainDiModule
    this.coreI18nDiModule = parameters.coreI18nDiModule
  }

  provideBonusProgramLevelsPage(): React.ReactNode {
    return <BonusProgramLevelsPage provideViewModel={this.provideBonusProgramLevelsViewModel} />
  }

  provideBonusProgramLevelPage(): React.ReactNode {
    return <BonusProgramLevelPage provideViewModel={this.provideBonusProgramLevelViewModel} />
  }

  private provideBonusProgramLevelViewModel({ bonusProgramLevelId }: {
    readonly bonusProgramLevelId?: number
  }): BonusProgramLevelViewModel {
    return new BonusProgramLevelViewModel({
      coreI18n: this.coreI18nDiModule.provideCoreI18n(),
      broadcastObjectsEventUseCase: this.objectsDomainDiModule.provideBroadcastObjectsEventUseCase({
        key: "bonusProgramLevels"
      }),
      createBonusProgramLevelUseCase: this.bonusProgramLevelsCoreDomainDiModule.provideCreateBonusProgramLevelUseCase(),
      getBonusProgramLevelUseCase: this.bonusProgramLevelsCoreDomainDiModule.provideGetBonusProgramLevelUseCase(),
      updateBonusProgramLevelUseCase: this.bonusProgramLevelsCoreDomainDiModule.provideUpdateBonusProgramLevelUseCase(),
      destroyBonusProgramLevelUseCase: this.bonusProgramLevelsCoreDomainDiModule
        .provideDestroyBonusProgramLevelUseCase(),
      getPropertiesUseCase: this.propertiesCoreDomainDiModule.provideGetPropertiesUseCase(),
      getPropertyValuesUseCase: this.propertyValuesCoreDomainDiModule.provideGetPropertyValuesUseCase(),
      getProductCategoriesUseCase: this.productCategoriesCoreDomainDiModule.provideGetProductCategoriesUseCase(),
      getProductsUseCase: this.productsCoreDomainDiModule.provideGetProductsUseCase(),
      bonusProgramLevelId
    })
  }

  private provideBonusProgramLevelsViewModel({
    objectsPresentationLogicParameters
  }: {
    readonly objectsPresentationLogicParameters: ObjectsPresentationLogicParameters
  }): BonusProgramLevelsViewModel {
    return new BonusProgramLevelsViewModel({
      objectsPresentationLogicParameters,
      coreI18n: this.coreI18nDiModule.provideCoreI18n(),
      subscribeToObjectsEventsUseCase: this.objectsDomainDiModule.provideSubscribeToObjectsEventsUseCase({
        key: "bonusProgramLevels"
      }),
      unsubscribeFromObjectsEventsUseCase: this.objectsDomainDiModule.provideUnsubscribeFromObjectsEventsUseCase({
        key: "bonusProgramLevels"
      }),
      getBonusProgramLevelsUseCase: this.bonusProgramLevelsCoreDomainDiModule.provideGetBonusProgramLevelsUseCase()
    })
  }
}
