import ProductError from "../../../../../core/domain/entities/products/ProductError"
import ProductsRepository from "../../repositories/ProductsRepository"
import { DestroyObjectResult } from "../../../../../../sqadmin/features/objects/domain/results/DestroyObjectResult"

export default class DestroyProductUseCase {
  private readonly productsRepository: ProductsRepository

  constructor(parameters: {
    readonly productsRepository: ProductsRepository
  }) {
    this.productsRepository = parameters.productsRepository
  }

  async call(parameters: { readonly productId: number }): Promise<DestroyObjectResult<ProductError>> {
    return await this.productsRepository.destroyProduct(parameters)
  }
}
