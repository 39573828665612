import { Expose } from "class-transformer"
import FulfillmentProviderType from "../../../../core/domain/entities/order-receiving-methods/FulfillmentProviderType"
import OrderShippingMethodType from "../../../../core/domain/entities/order-shipping-methods/OrderShippingMethodType"
import OrderReceivingMethodType from "../../../../core/domain/entities/order-receiving-methods/OrderReceivingMethodType"

export default class NetworkOrderShippingTariffsRequestFilter {
  @Expose()
  readonly fulfillmentProviderType: FulfillmentProviderType | null | undefined

  @Expose()
  readonly orderShippingMethodType: OrderShippingMethodType | null | undefined

  @Expose()
  readonly orderReceivingMethodType: OrderReceivingMethodType | null | undefined

  constructor(parameters: {
    readonly fulfillmentProviderType: FulfillmentProviderType | null | undefined
    readonly orderShippingMethodType: OrderShippingMethodType | null | undefined
    readonly orderReceivingMethodType: OrderReceivingMethodType | null | undefined
  }) {
    this.fulfillmentProviderType = parameters.fulfillmentProviderType
    this.orderShippingMethodType = parameters.orderShippingMethodType
    this.orderReceivingMethodType = parameters.orderReceivingMethodType
  }
}
