import Place from "../../../../core/domain/entities/places/Place"
import { UpdateObjectResult } from "../../../../../sqadmin/features/objects/domain/results/UpdateObjectResult"
import PlacesRepository from "../repositories/PlacesRepository"
import PlaceError from "../../../../core/domain/entities/places/PlaceError"

export default class UpdatePlaceUseCase {
  private readonly placesRepository: PlacesRepository

  constructor(parameters: {
    readonly placesRepository: PlacesRepository
  }) {
    this.placesRepository = parameters.placesRepository
  }

  async call(parameters: UpdatePlaceParameters): Promise<UpdateObjectResult<Place, PlaceError>> {
    return await this.placesRepository.updatePlace(parameters)
  }
}

export interface UpdatePlaceParameters {
  readonly placeId: number
  readonly place: Place
}
