import React, { useCallback, useEffect, useRef } from "react"

export interface PaginationComponentProps {
  readonly onComponentAppeared: () => void
}

export default function PaginationComponent({
  onComponentAppeared
}: PaginationComponentProps) {
  const componentRef = useRef(null)

  const handleObserver = useCallback((entries: IntersectionObserverEntry[]) => {
    const target = entries[0]
    if (target.isIntersecting) onComponentAppeared()
  }, [onComponentAppeared])

  useEffect(() => {
    const options: IntersectionObserverInit = { threshold: 1 }

    const observer = new IntersectionObserver(handleObserver, options)
    if (componentRef.current) observer.observe(componentRef.current)

    return () => {
      observer.disconnect()
    }
  }, [handleObserver])

  return (
    <div ref={componentRef} />
  )
}
