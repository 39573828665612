import { RouteObject } from "react-router-dom"
import { objectIdUrlPart } from "../../../../sqadmin/features/objects/presentation/hooks/useObjectId"
import CoreUrlProvider from "../../../core/presentation/services/CoreUrlProvider"
import DashboardsDi from "../di/DashboardsDi"

export default function createDashboardsRoutes({
  di
}: {
  readonly di: DashboardsDi
}): RouteObject[] {
  const urlProvider = new CoreUrlProvider()
  return [
    {
      path: urlProvider.buildDashboardsPath(),
      element: di
        .sqCoreDashboardsDiComponent
        .dashboardsPresentationDiModule
        .provideDashboardsPage()
    },
    {
      path: urlProvider.buildNewDashboardPath(),
      element: di
        .sqCoreDashboardsDiComponent
        .dashboardsPresentationDiModule
        .provideDashboardPage()
    },
    {
      path: urlProvider.buildDashboardPath({ id: `:${objectIdUrlPart}` }),
      element: di
        .sqCoreDashboardsDiComponent
        .dashboardsPresentationDiModule
        .provideDashboardPage()
    }
  ]
}
