import DiModule from "../../../../sqadmin/lib/di/DiModule"
import CoreNetworkDiModule from "../../../core/di/modules/CoreNetworkDiModule"
import DefaultOptionValuesRepository from "../data/repositories/DefaultOptionValuesRepository"
import OptionValuesNetworkSource from "../data/sources/OptionValuesNetworkSource"
import OptionValuesRepository from "../domain/repositories/OptionValuesRepository"

export default interface OptionValuesCoreDataDiModule {
  provideOptionValuesRepository(): OptionValuesRepository
}

export class DefaultOptionValuesCoreDataDiModule extends DiModule implements OptionValuesCoreDataDiModule {
  private readonly sqCoreCoreNetworkDiModule: CoreNetworkDiModule

  constructor(parameters: {
    readonly sqCoreCoreNetworkDiModule: CoreNetworkDiModule
  }) {
    super()
    this.sqCoreCoreNetworkDiModule = parameters.sqCoreCoreNetworkDiModule
  }

  provideOptionValuesRepository(): OptionValuesRepository {
    return this.single(
      DefaultOptionValuesRepository.name,
      () => new DefaultOptionValuesRepository({
        optionValuesNetworkSource: this.provideOptionValuesNetworkSource()
      })
    )
  }

  private provideOptionValuesNetworkSource(): OptionValuesNetworkSource {
    return this.single(
      OptionValuesNetworkSource.name,
      () => new OptionValuesNetworkSource({
        backendHttpClient: this.sqCoreCoreNetworkDiModule.provideBackendHttpClient()
      })
    )
  }
}
