import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../errors/NetworkAttributeError"
import HeaderErrorsObjectAttributes from "../../../domain/entities/header/HeaderErrorsObjectAttributes"

export default class NetworkHeaderErrorsObjectAttributes implements HeaderErrorsObjectAttributes {
  @Expose()
  @Type(() => NetworkAttributeError)
  leftContentType: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  rightContentType: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  centerContentType: NetworkAttributeError[] | null | undefined
}
