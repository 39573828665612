import ViewModel from "../../../../../../sqadmin/lib/view-model/ViewModel"
import CoreI18n from "../../../../../core/i18n/CoreI18n"
import CoreUrlProvider from "../../../../../core/presentation/services/CoreUrlProvider"
import BroadcastObjectsEventUseCase
  from "../../../../../../sqadmin/features/objects/domain/use-cases/objects/BroadcastObjectsEventUseCase"
import ObjectPresentationLogic
  from "../../../../../../sqadmin/features/objects/presentation/presentation-logics/ObjectPresentationLogic"
import { StateObservable } from "../../../../../../sqadmin/lib/view-model/StateObservable"
import { ObjectViewState } from "../../../../../../sqadmin/features/objects/presentation/view-states/ObjectViewState"
import autoBind from "auto-bind"
import ObjectViewEvent from "../../../../../../sqadmin/features/objects/presentation/view-events/ObjectViewEvent"
import isBlank from "../../../../../../sqadmin/lib/isBlank"
import StringFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/StringFormField"
import OrderPaymentMethod from "../../../../../core/domain/entities/order-payment-methods/OrderPaymentMethod"
import OrderPaymentMethodError from "../../../../../core/domain/entities/order-payment-methods/OrderPaymentMethodError"
import OrderPaymentMethodErrorsObject
  from "../../../../../core/domain/entities/order-payment-methods/OrderPaymentMethodErrorsObject"
import GetOrderPaymentMethodUseCase
  from "../../../../order-payment-methods-core/domain/use-cases/GetOrderPaymentMethodUseCase"
import DestroyOrderPaymentMethodUseCase
  from "../../../../order-payment-methods-core/domain/use-cases/DestroyOrderPaymentMethodUseCase"
import UpdateOrderPaymentMethodUseCase
  from "../../../../order-payment-methods-core/domain/use-cases/UpdateOrderPaymentMethodUseCase"
import CreateOrderPaymentMethodUseCase
  from "../../../../order-payment-methods-core/domain/use-cases/CreateOrderPaymentMethodUseCase"
import NumberFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/NumberFormField"
import BooleanFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/BooleanFormField"
import SingleSelectFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/SingleSelectFormField"
import OrderPaymentMethodType from "../../../../../core/domain/entities/order-payment-methods/OrderPaymentMethodType"
import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import MultiSelectFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/MultiSelectFormField"
import OrderReceivingMethod from "../../../../../core/domain/entities/order-receiving-methods/OrderReceivingMethod"
import GetOrderReceivingMethodsUseCase
  from "../../../../order-receiving-methods-core/domain/use-cases/GetOrderReceivingMethodsUseCase"
import PaymentProvider from "../../../../../core/domain/entities/payment-providers/PaymentProvider"
import GetPaymentProvidersUseCase
  from "../../../../order-payment-methods-core/domain/use-cases/GetPaymentProvidersUseCase"
import isPresent from "../../../../../../sqadmin/lib/isPresent"
import assertNever from "../../../../../../sqadmin/lib/assertNever"

export default class OrderPaymentMethodViewModel extends ViewModel {
  private readonly coreI18n: CoreI18n
  private readonly coreUrlProvider: CoreUrlProvider
  private readonly broadcastObjectsEventUseCase: BroadcastObjectsEventUseCase
  private readonly getOrderPaymentMethodUseCase: GetOrderPaymentMethodUseCase
  private readonly createOrderPaymentMethodUseCase: CreateOrderPaymentMethodUseCase
  private readonly updateOrderPaymentMethodUseCase: UpdateOrderPaymentMethodUseCase
  private readonly destroyOrderPaymentMethodUseCase: DestroyOrderPaymentMethodUseCase
  private readonly getOrderReceivingMethodsUseCase: GetOrderReceivingMethodsUseCase
  private readonly getPaymentProvidersUseCase: GetPaymentProvidersUseCase
  private readonly orderPaymentMethodId?: number

  private readonly objectPresentationLogic: ObjectPresentationLogic<
    OrderPaymentMethod,
    OrderPaymentMethodError,
    OrderPaymentMethodErrorsObject
  >

  readonly observableObjectViewState: StateObservable<ObjectViewState>

  constructor(parameters: {
    readonly coreI18n: CoreI18n
    readonly broadcastObjectsEventUseCase: BroadcastObjectsEventUseCase
    readonly getOrderPaymentMethodeUseCase: GetOrderPaymentMethodUseCase
    readonly createOrderPaymentMethodUseCase: CreateOrderPaymentMethodUseCase
    readonly updateOrderPaymentMethodUseCase: UpdateOrderPaymentMethodUseCase
    readonly destroyOrderPaymentMethodUseCase: DestroyOrderPaymentMethodUseCase
    readonly getOrderReceivingMethodsUseCase: GetOrderReceivingMethodsUseCase
    readonly getPaymentProvidersUseCase: GetPaymentProvidersUseCase
    readonly orderPaymentMethodId?: number
  }) {
    super()
    this.coreI18n = parameters.coreI18n
    this.broadcastObjectsEventUseCase = parameters.broadcastObjectsEventUseCase
    this.getOrderPaymentMethodUseCase = parameters.getOrderPaymentMethodeUseCase
    this.createOrderPaymentMethodUseCase = parameters.createOrderPaymentMethodUseCase
    this.updateOrderPaymentMethodUseCase = parameters.updateOrderPaymentMethodUseCase
    this.destroyOrderPaymentMethodUseCase = parameters.destroyOrderPaymentMethodUseCase
    this.getOrderReceivingMethodsUseCase = parameters.getOrderReceivingMethodsUseCase
    this.getPaymentProvidersUseCase = parameters.getPaymentProvidersUseCase
    this.orderPaymentMethodId = parameters.orderPaymentMethodId
    this.objectPresentationLogic = this.createObjectPresentationLogic()
    this.observableObjectViewState = this.objectPresentationLogic.observableObjectViewState
    this.coreUrlProvider = new CoreUrlProvider()
    autoBind(this)
  }

  onViewObjectEvent(objectViewEvent: ObjectViewEvent) {
    this.objectPresentationLogic.onObjectViewEvent(objectViewEvent)
  }

  private createObjectPresentationLogic(): ObjectPresentationLogic<
    OrderPaymentMethod,
    OrderPaymentMethodError,
    OrderPaymentMethodErrorsObject
  > {
    return new ObjectPresentationLogic({
      // TODO: how to not pass?
      broadcastObjectsEventUseCase: this.broadcastObjectsEventUseCase,
      isNewObject: isBlank(this.orderPaymentMethodId),
      getObjectUrl: (orderPaymentMethod) => {
        return this.coreUrlProvider.buildOrderPaymentMethodUrl({
          id: orderPaymentMethod.id!
        })
      },
      buildObject: async() => ({
        id: undefined,
        name: undefined,
        type: undefined,
        position: undefined,
        isDefault: false,
        isAvailable: true,
        paymentProviderType: undefined,
        orderReceivingMethods: undefined,
        orderReceivingMethodIds: undefined,
        paymentProvider: undefined,
        forOfflineSale: undefined
      }),
      loadObject: async() => {
        return await this.getOrderPaymentMethodUseCase.call({ orderPaymentMethodId: this.orderPaymentMethodId! })
      },
      createObject: async({ object: orderPaymentMethod }) => {
        return await this.createOrderPaymentMethodUseCase.call({ orderPaymentMethod })
      },
      updateObject: async({ object: orderPaymentMethod }) => {
        return await this.updateOrderPaymentMethodUseCase.call({
          orderPaymentMethodId: this.orderPaymentMethodId!,
          orderPaymentMethod
        })
      },
      destroyObject: async() => {
        return await this.destroyOrderPaymentMethodUseCase.call({ orderPaymentMethodId: this.orderPaymentMethodId! })
      },
      getErrorsObject: ({ error: discountRuleError }) => discountRuleError
        ?.errorsObject,
      formFields: [
        this.createOrderPaymentMethodNameFormField(),
        this.createOrderPaymentMethodTypeFormField(),
        this.createOrderPaymentMethodProviderTypeFormField(),
        this.createOrderReceivingMethodsFormField(),
        this.createOrderPaymentMethodAvailableFormField(),
        this.createOrderPaymentMethodDefaultFormField(),
        this.createOrderPaymentMethodForOfflineSaleFormField(),
        this.createOrderPaymentMethodPositionFormField()
      ]
    })
  }

  private createOrderPaymentMethodNameFormField() {
    return new StringFormField<OrderPaymentMethod, OrderPaymentMethodErrorsObject>({
      getTitle: () => this.coreI18n.getTextProvider().name(),
      getValue: (orderPaymentMethod: OrderPaymentMethod) => orderPaymentMethod.name,
      setValue: (orderPaymentMethod: OrderPaymentMethod, name: string) => ({ ...orderPaymentMethod, name }),
      getErrors: (orderPaymentMethodErrorsObject?: OrderPaymentMethodErrorsObject) => orderPaymentMethodErrorsObject
        ?.attributes
        ?.name
    })
  }

  private createOrderPaymentMethodTypeFormField() {
    return new SingleSelectFormField<OrderPaymentMethod, OrderPaymentMethodErrorsObject, OrderPaymentMethodType>({
      isSearchBarVisible: false,
      getObjects: async() => {
        return {
          type: "success",
          data: {
            objects: Object.values(OrderPaymentMethodType),
            page: { hasMore: false }
          }
        }
      },
      getTitle: () => this.coreI18n.getTextProvider().type(),
      getValue: (orderPaymentMethod: OrderPaymentMethod) => orderPaymentMethod.type,
      setValue: (
        orderPaymentMethod: OrderPaymentMethod,
        type: OrderPaymentMethodType | null | undefined
      ): OrderPaymentMethod => {
        return {
          ...orderPaymentMethod,
          type: type,
          paymentProviderType: undefined,
          paymentProvider: undefined
        }
      },
      getErrors: (orderPaymentMethodErrorsObject?: OrderPaymentMethodErrorsObject) => orderPaymentMethodErrorsObject
        ?.attributes
        ?.type,
      getOptionId: (type: OrderPaymentMethodType) => type.valueOf(),
      getOptionText: (type: OrderPaymentMethodType) => this.detectOrderPaymentMethodTypeDisplayName(type)
    })
  }

  private detectOrderPaymentMethodTypeDisplayName(type: OrderPaymentMethodType) {
    const coreTextProvider: CoreTextProvider = this.coreI18n.getTextProvider()
    switch (type) {
      case OrderPaymentMethodType.BANK_CARD:
        return coreTextProvider.paymentMethodTypeBankCard()
      case OrderPaymentMethodType.SBP:
        return coreTextProvider.paymentMethodTypeSbp()
      case OrderPaymentMethodType.OFFLINE:
        return coreTextProvider.paymentMethodTypeOffline()
      case OrderPaymentMethodType.YOO_MONEY:
        return coreTextProvider.paymentMethodTypeYooMoney()
      case OrderPaymentMethodType.SBER_PAY:
        return coreTextProvider.paymentMethodTypeSberPay()
      case OrderPaymentMethodType.T_PAY:
        return coreTextProvider.paymentMethodTypeTPay()
      case OrderPaymentMethodType.DOLYAME:
        return coreTextProvider.paymentMethodTypeDolyame()
      default:
        assertNever(type)
    }
  }

  private createOrderPaymentMethodProviderTypeFormField() {
    return new SingleSelectFormField<
      OrderPaymentMethod,
      OrderPaymentMethodErrorsObject,
      PaymentProvider
    >({
      getVisible: (orderPaymentMethod: OrderPaymentMethod) => {
        return isPresent(orderPaymentMethod.type) && orderPaymentMethod.type !== OrderPaymentMethodType.OFFLINE
      },
      isSearchBarVisible: false,
      getObjects: async() => await this.getPaymentProvidersUseCase.call(),
      getTitle: () => this.coreI18n.getTextProvider().provider(),
      getValue: (orderPaymentMethod: OrderPaymentMethod) => orderPaymentMethod.paymentProvider,
      setValue: (
        orderPaymentMethod: OrderPaymentMethod,
        paymentProvider: PaymentProvider | null | undefined
      ): OrderPaymentMethod => {
        return {
          ...orderPaymentMethod,
          paymentProviderType: paymentProvider?.type,
          paymentProvider: paymentProvider
        }
      },
      getErrors: (orderPaymentMethodErrorsObject?: OrderPaymentMethodErrorsObject) => orderPaymentMethodErrorsObject
        ?.attributes
        ?.paymentProviderType,
      getOptionId: (paymentProvider: PaymentProvider) => paymentProvider.type!,
      getOptionText: (paymentProvider: PaymentProvider) => paymentProvider.name
    })
  }

  private createOrderReceivingMethodsFormField() {
    return new MultiSelectFormField<OrderPaymentMethod, OrderPaymentMethodErrorsObject, OrderReceivingMethod>({
      getObjects: async({
        query,
        lastObject: lastOrderSubStatus
      }) => {
        return await this.getOrderReceivingMethodsUseCase.call({
          query: query,
          sort: undefined,
          pagination: { id: lastOrderSubStatus?.id ?? undefined }
        })
      },
      getTitle: () => this.coreI18n.getTextProvider().availableForOrderReceivingMethods(),
      getValue: (orderPaymentMethod: OrderPaymentMethod) => orderPaymentMethod.orderReceivingMethods,
      setValue: (orderPaymentMethod: OrderPaymentMethod, orderReceivingMethods: OrderReceivingMethod[] | null) => {
        return {
          ...orderPaymentMethod,
          orderReceivingMethods,
          orderReceivingMethodIds: orderReceivingMethods?.map((orderReceivingMethod) => orderReceivingMethod.id!)
        }
      },
      getErrors: (productErrorsObject?: OrderPaymentMethodErrorsObject) => productErrorsObject
        ?.attributes
        ?.orderReceivingMethodIds,
      getOptionId: (orderReceivingMethod: OrderReceivingMethod) => orderReceivingMethod.id!.toString(),
      getOptionText: (orderReceivingMethod: OrderReceivingMethod) => orderReceivingMethod.name
    })
  }

  private createOrderPaymentMethodAvailableFormField() {
    return new BooleanFormField<OrderPaymentMethod, OrderPaymentMethodErrorsObject>({
      getValue: (orderPaymentMethod: OrderPaymentMethod) => orderPaymentMethod.isAvailable,
      setValue: (orderPaymentMethod: OrderPaymentMethod, isAvailable: boolean) => ({
        ...orderPaymentMethod,
        isAvailable
      }),
      getText: () => this.coreI18n.getTextProvider().isAvailableInCheckout(),
      getErrors: (
        orderPaymentMethodErrorsObject?: OrderPaymentMethodErrorsObject
      ) => orderPaymentMethodErrorsObject?.attributes?.isAvailable
    })
  }

  private createOrderPaymentMethodDefaultFormField() {
    return new BooleanFormField<OrderPaymentMethod, OrderPaymentMethodErrorsObject>({
      getValue: (orderPaymentMethod: OrderPaymentMethod) => orderPaymentMethod.isDefault,
      setValue: (orderPaymentMethod: OrderPaymentMethod, isDefault: boolean) => ({
        ...orderPaymentMethod,
        isDefault
      }),
      getText: () => this.coreI18n.getTextProvider().isDefaultInCheckout(),
      getErrors: (
        orderPaymentMethodErrorsObject?: OrderPaymentMethodErrorsObject
      ) => orderPaymentMethodErrorsObject?.attributes?.isDefault
    })
  }

  private createOrderPaymentMethodForOfflineSaleFormField() {
    return new BooleanFormField<OrderPaymentMethod, OrderPaymentMethodErrorsObject>({
      getValue: (orderPaymentMethod: OrderPaymentMethod) => orderPaymentMethod.forOfflineSale,
      setValue: (orderPaymentMethod: OrderPaymentMethod, forOfflineSale: boolean) => ({
        ...orderPaymentMethod,
        forOfflineSale
      }),
      getText: () => this.coreI18n.getTextProvider().useForOfflineSale(),
      getErrors: (
        orderPaymentMethodErrorsObject?: OrderPaymentMethodErrorsObject
      ) => orderPaymentMethodErrorsObject?.attributes?.forOfflineSale
    })
  }

  private createOrderPaymentMethodPositionFormField() {
    return new NumberFormField<OrderPaymentMethod, OrderPaymentMethodErrorsObject>({
      getTitle: () => this.coreI18n.getTextProvider().position(),
      getValue: (orderPaymentMethod: OrderPaymentMethod) => orderPaymentMethod.position,
      setValue: (orderPaymentMethod: OrderPaymentMethod, position: number | null) => ({
        ...orderPaymentMethod,
        position
      }),
      getErrors: (orderPaymentMethodErrorsObject?: OrderPaymentMethodErrorsObject) => orderPaymentMethodErrorsObject
        ?.attributes
        ?.position
    })
  }
}
