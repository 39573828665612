import NetworkProductCategory from "../../../../core/data/entities/product-categories/NetworkProductCategory"
import { Expose } from "class-transformer"

export default class NetworkProductCategoryRequestBody {
  @Expose()
  readonly productCategory: NetworkProductCategory

  constructor(parameters: {
    readonly productCategory: NetworkProductCategory
  }) {
    this.productCategory = parameters.productCategory
  }
}
