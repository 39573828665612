import OptionValuesFiltersMapper from "../mappers/OptionValuesFiltersMapper"
import OptionValuesMapper from "../../../../core/data/mappers/OptionValuesMapper"
import NetworkOptionValue from "../../../../core/data/entities/option-values/NetworkOptionValue"
import OptionValuesNetworkSource, { GetOptionValuesNetworkResult } from "../sources/OptionValuesNetworkSource"
import OptionValuesRepository from "../../domain/repositories/OptionValuesRepository"
import { GetOptionValuesParameters, GetOptionValuesResult } from "../../domain/use-cases/GetOptionValuesUseCase"

export default class DefaultOptionValuesRepository implements OptionValuesRepository {
  private readonly optionValuesNetworkSource: OptionValuesNetworkSource

  constructor(parameters: {
    readonly optionValuesNetworkSource: OptionValuesNetworkSource
  }) {
    this.optionValuesNetworkSource = parameters.optionValuesNetworkSource
  }

  async getOptionValues({
    query,
    optionId
  }: GetOptionValuesParameters): Promise<GetOptionValuesResult> {
    const result: GetOptionValuesNetworkResult = await this.optionValuesNetworkSource.getOptionValues({
      filter: new OptionValuesFiltersMapper().mapDomainToNetwork({ query }),
      optionId
    })

    switch (result.type) {
      case "success": {
        const optionValuesMapper = new OptionValuesMapper()

        return {
          type: "success",
          data: {
            objects: result.data.optionValues!.map((optionValue: NetworkOptionValue) => {
              return optionValuesMapper.mapNetworkToDomain({ value: optionValue })
            }),
            page: {
              hasMore: false
            }
          }
        }
      }
      default:
        return result
    }
  }
}
