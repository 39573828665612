enum ApiLogItemResultType {
  SUCCESS = "success",
  REDIRECT = "redirect",
  CLIENT_ERROR = "clientError",
  SERVER_ERROR = "serverError",
  UNKNOWN = "unknown",
  MISSING = "missing"
}

export default ApiLogItemResultType
