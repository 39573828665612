import SessionsRepository from "../../repositories/SessionsRepository"

export default class DestroySessionUseCase {
  private readonly sessionsRepository: SessionsRepository

  constructor(parameters: {
    readonly sessionsRepository: SessionsRepository
  }) {
    this.sessionsRepository = parameters.sessionsRepository
  }

  call() {
    this.sessionsRepository.destroySession()
  }
}
