import BackendHttpClient, { BackendHttpClientResult } from "../../../../core/data/network/BackendHttpClient"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import { instanceToPlain, plainToInstance } from "class-transformer"
import NetworkExecutionError from "../../../../core/data/entities/errors/NetworkExecutionError"
import SuccessExecutionResult from "../../../../../sqadmin/core/domain/results/SuccessExecutionResult"
import ErrorExecutionResult from "../../../../../sqadmin/core/domain/results/ErrorExecutionResult"
import FailureExecutionResult from "../../../../../sqadmin/core/domain/results/FailureExecutionResult"
import NetworkOptionValuesRequestFilter from "../entities/NetworkOptionValuesRequestFilter"
import NetworkOptionValuesRequestQuery from "../entities/NetworkOptionValuesRequestQuery"
import NetworkOptionValuesResponseBody from "../entities/NetworkOptionValuesResponseBody"

const basePath = "/admin/options"

export default class OptionValuesNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async getOptionValues({
    filter,
    sort,
    optionId
  }: {
    readonly filter?: NetworkOptionValuesRequestFilter | null
    readonly optionId: number
    readonly sort?: string | null
  }): Promise<GetOptionValuesNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}/${optionId}/values`,
      parameters: instanceToPlain(new NetworkOptionValuesRequestQuery({
        filter,
        sort
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkOptionValuesResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }
}

export type GetOptionValuesNetworkResult =
  SuccessExecutionResult<NetworkOptionValuesResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult
