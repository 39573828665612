import { TransformFnParams } from "class-transformer/types/interfaces"
import { TransformationType } from "class-transformer"
import assertNever from "./assertNever"

export default function transformDate({
  value,
  type
}: TransformFnParams) {
  switch (type) {
    case TransformationType.CLASS_TO_CLASS:
      return value
    case TransformationType.CLASS_TO_PLAIN:
      return value === null || value === undefined ?
        value : (value as Date).toISOString()
    case TransformationType.PLAIN_TO_CLASS:
      return value === null || value === undefined ? value : new Date(value)
    default:
      assertNever(type)
  }
}

