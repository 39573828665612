import { Expose, Type } from "class-transformer"
import NetworkImage from "../images/NetworkImage"
import NetworkBonusProgramRule from "../bonus-program-rules/NetworkBonusProgramRule"

export default class NetworkProductCategory {
  @Expose()
  readonly id: number | null | undefined

  @Expose()
  readonly externalCode: string | null | undefined

  @Expose()
  readonly name: string | null | undefined

  @Expose()
  @Type(() => NetworkImage)
  readonly image: NetworkImage | null | undefined

  @Expose()
  readonly imageId: number | null | undefined

  @Expose()
  readonly position: number | null | undefined

  @Expose()
  @Type(() => NetworkBonusProgramRule)
  readonly bonusProgramRule: NetworkBonusProgramRule | null | undefined

  constructor(parameters?: {
    readonly id: number | null | undefined
    readonly name: string | null | undefined
    readonly externalCode: string | null | undefined
    readonly image: NetworkImage | null | undefined
    readonly imageId: number | null | undefined,
    readonly position: number | null | undefined
    readonly bonusProgramRule: NetworkBonusProgramRule | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.id = parameters.id
    this.externalCode = parameters.externalCode
    this.name = parameters.name
    this.image = parameters.image
    this.imageId = parameters.imageId
    this.position = parameters.position
    this.bonusProgramRule = parameters.bonusProgramRule
  }
}
