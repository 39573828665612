import DiModule from "../../../../sqadmin/lib/di/DiModule"
import CreateBonusProgramLevelUseCase from "../domain/use-cases/CreateBonusProgramLevelUseCase"
import DestroyBonusProgramLevelUseCase from "../domain/use-cases/DestroyBonusProgramLevelUseCase"
import GetBonusProgramLevelsUseCase from "../domain/use-cases/GetBonusProgramLevelsUseCase"
import GetBonusProgramLevelUseCase from "../domain/use-cases/GetBonusProgramLevelUseCase"
import UpdateBonusProgramLevelUseCase from "../domain/use-cases/UpdateBonusProgramLevelUseCase"
import BonusProgramLevelsCoreDataDiModule from "./BonusProgramLevelsCoreDataDiModule"

export default interface BonusProgramLevelsCoreDomainDiModule {
  provideCreateBonusProgramLevelUseCase(): CreateBonusProgramLevelUseCase

  provideDestroyBonusProgramLevelUseCase(): DestroyBonusProgramLevelUseCase

  provideGetBonusProgramLevelsUseCase(): GetBonusProgramLevelsUseCase

  provideGetBonusProgramLevelUseCase(): GetBonusProgramLevelUseCase

  provideUpdateBonusProgramLevelUseCase(): UpdateBonusProgramLevelUseCase
}

export class DefaultBonusProgramLevelsCoreDomainDiModule extends DiModule
  implements BonusProgramLevelsCoreDomainDiModule {
  private readonly bonusProgramLevelsCoreDataDiModule: BonusProgramLevelsCoreDataDiModule

  constructor(parameters: {
    readonly bonusProgramLevelsCoreDataDiModule: BonusProgramLevelsCoreDataDiModule
  }) {
    super()
    this.bonusProgramLevelsCoreDataDiModule = parameters.bonusProgramLevelsCoreDataDiModule
  }

  provideCreateBonusProgramLevelUseCase(): CreateBonusProgramLevelUseCase {
    return this.single(
      CreateBonusProgramLevelUseCase.name,
      () => new CreateBonusProgramLevelUseCase({
        bonusProgramLevelsRepository: this.bonusProgramLevelsCoreDataDiModule.provideBonusProgramLevelsRepository()
      })
    )
  }

  provideDestroyBonusProgramLevelUseCase(): DestroyBonusProgramLevelUseCase {
    return this.single(
      DestroyBonusProgramLevelUseCase.name,
      () => new DestroyBonusProgramLevelUseCase({
        bonusProgramLevelsRepository: this.bonusProgramLevelsCoreDataDiModule.provideBonusProgramLevelsRepository()
      })
    )
  }

  provideGetBonusProgramLevelsUseCase(): GetBonusProgramLevelsUseCase {
    return this.single(
      GetBonusProgramLevelsUseCase.name,
      () => new GetBonusProgramLevelsUseCase({
        bonusProgramLevelsRepository: this.bonusProgramLevelsCoreDataDiModule.provideBonusProgramLevelsRepository()
      })
    )
  }

  provideGetBonusProgramLevelUseCase(): GetBonusProgramLevelUseCase {
    return this.single(
      GetBonusProgramLevelUseCase.name,
      () => new GetBonusProgramLevelUseCase({
        bonusProgramLevelsRepository: this.bonusProgramLevelsCoreDataDiModule.provideBonusProgramLevelsRepository()
      })
    )
  }

  provideUpdateBonusProgramLevelUseCase(): UpdateBonusProgramLevelUseCase {
    return this.single(
      UpdateBonusProgramLevelUseCase.name,
      () => new UpdateBonusProgramLevelUseCase({
        bonusProgramLevelsRepository: this.bonusProgramLevelsCoreDataDiModule.provideBonusProgramLevelsRepository()
      })
    )
  }
}
