import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../errors/NetworkAttributeError"
import GeoLocationErrorsObjectAttributes from "../../../domain/entities/geo-locations/GeoLocationErrorsObjectAttributes"

export default class NetworkGeoLocationErrorsObjectAttributes implements GeoLocationErrorsObjectAttributes {
  @Expose()
  @Type(() => NetworkAttributeError)
  readonly longitude: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly latitude: NetworkAttributeError[] | null | undefined
}
