import CoreI18n from "../../i18n/CoreI18n"
import SingleSelectFormField
  from "../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/SingleSelectFormField"
import BonusPointsRule from "../../domain/entities/bonus-points-rules/BonusPointsRule"
import BonusPointsRuleErrorsObject from "../../domain/entities/bonus-points-rules/BonusPointsRuleErrorsObject"
import BonusProgramLevel from "../../domain/entities/bonus-program-levels/BonusProgramLevel"
import DecimalFormField
  from "../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/DecimalFormField"
import { Decimal } from "decimal.js"
import isBlank from "../../../../sqadmin/lib/isBlank"
import { GetObjectsPageResult } from "../../../../sqadmin/features/objects/domain/results/GetObjectsPageResult"
import CoreTextProvider from "../../i18n/CoreTextProvider"
import assertNever from "../../../../sqadmin/lib/assertNever"
import CalculationType from "../../domain/entities/calculators/CalculationType"
import Calculator from "../../domain/entities/calculators/Calculator"

type GetBonusProgramLevels = ({
  query,
  lastObjectId
}: {
  readonly query: string | undefined | null
  readonly lastObjectId: number | undefined | null
}) => Promise<GetObjectsPageResult<BonusProgramLevel>>

export class BonusPointsRuleFields {
  private readonly coreI18n: CoreI18n
  private readonly getBonusProgramLevels: GetBonusProgramLevels

  constructor(parameters: {
    readonly coreI18n: CoreI18n
    readonly getBonusProgramLevels: GetBonusProgramLevels
  }) {
    this.coreI18n = parameters.coreI18n
    this.getBonusProgramLevels = parameters.getBonusProgramLevels
  }

  createFields() {
    return [
      new SingleSelectFormField<BonusPointsRule, BonusPointsRuleErrorsObject, BonusProgramLevel>({
        getObjects: async({
          query,
          lastObject
        }) => {
          return await this.getBonusProgramLevels({
            query,
            lastObjectId: lastObject?.id ?? undefined
          })
        },
        getTitle: () => this.coreI18n.getTextProvider().bonusProgramLevel(),
        getValue: (bonusPointsRule: BonusPointsRule) => bonusPointsRule.bonusProgramLevel,
        setValue: (bonusPointsRule: BonusPointsRule, bonusProgramLevel: BonusProgramLevel | null) => ({
          ...bonusPointsRule,
          bonusProgramLevel,
          bonusProgramLevelId: bonusProgramLevel && bonusProgramLevel.id
        }),
        getErrors: (bonusPointsRuleErrorsObject?: BonusPointsRuleErrorsObject) => bonusPointsRuleErrorsObject
          ?.attributes
          ?.bonusProgramLevelId,
        getOptionId: (bonusProgramLevel: BonusProgramLevel) => bonusProgramLevel.id!.toString(),
        getOptionText: (bonusProgramLevel: BonusProgramLevel) => this.createOptionText(bonusProgramLevel)
      }),
      new SingleSelectFormField<BonusPointsRule, BonusPointsRuleErrorsObject, CalculationType>({
        getObjects: async() => {
          return {
            type: "success",
            data: {
              objects: [CalculationType.PERCENTAGE, CalculationType.FIXED],
              page: { hasMore: false }
            }
          }
        },
        isSearchBarVisible: false,
        getTitle: () => this.coreI18n.getTextProvider().calculationType(),
        getValue: (bonusPointsRule: BonusPointsRule) => bonusPointsRule.calculator?.calculationType,
        setValue: (bonusPointsRule: BonusPointsRule, value: CalculationType | null) => ({
          ...bonusPointsRule,
          calculator: {
            ...bonusPointsRule.calculator ?? this.createCalculator(),
            calculationType: value
          }
        }),
        getErrors: (bonusPointsRuleErrorsObject?: BonusPointsRuleErrorsObject) => bonusPointsRuleErrorsObject
          ?.calculator
          ?.attributes
          ?.calculationType,
        getOptionId: (type: CalculationType) => type.valueOf(),
        getOptionText: (type: CalculationType) => {
          return this.detectCalculationTypeDisplayName(type)
        }
      }),
      new DecimalFormField<BonusPointsRule, BonusPointsRuleErrorsObject>({
        getTitle: () => this.coreI18n.getTextProvider().value(),
        getValue: (bonusPointsRule: BonusPointsRule) => bonusPointsRule.calculator?.value,
        setValue: (bonusPointsRule: BonusPointsRule, value: Decimal | null | undefined) => {
          return {
            ...bonusPointsRule,
            calculator: {
              ...bonusPointsRule.calculator ?? this.createCalculator(),
              value
            }
          }
        },
        getErrors: (bonusPointsRuleErrorsObject?: BonusPointsRuleErrorsObject) => bonusPointsRuleErrorsObject
          ?.calculator
          ?.attributes
          ?.value
      })
    ]
  }

  private createOptionText(bonusProgramLevel: BonusProgramLevel): string | null | undefined {
    const bonusPointsPercent = bonusProgramLevel.bonusPointsPercent
    if (isBlank(bonusPointsPercent)) {
      return bonusProgramLevel.name
    }

    return [
      bonusProgramLevel.name,
      `${bonusPointsPercent}%`
    ].join(", ")
  }

  private detectCalculationTypeDisplayName(type: CalculationType) {
    const coreTextProvider: CoreTextProvider = this.coreI18n.getTextProvider()

    switch (type) {
      case CalculationType.PERCENTAGE:
        return coreTextProvider.pricePercentBonusPointsCalculationType()
      case CalculationType.FIXED:
        return coreTextProvider.fixedBonusPointsCalculationType()
      case CalculationType.SUBTRACT_FIXED:
      case CalculationType.SUBTRACT_PERCENTAGE:
        return undefined
      default:
        assertNever(type)
    }
  }

  private createCalculator(): Calculator {
    return {
      calculationType: CalculationType.FIXED,
      id: undefined,
      value: undefined,
      roundingDecimalsCount: undefined
    }
  }
}
