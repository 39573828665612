import React, { useState } from "react"
import styles from "./ImageComponent.module.scss"
import CenterModalWindowComponent
  from "../../../../features/objects/presentation/components/modal-windows/center-modal-window/CenterModalWindowComponent"
import ZoomAreaComponent from "../../../../lib/zoom-area/ZoomAreaComponent"

export interface ImageComponentProps {
  readonly url: string
  readonly width: number | undefined | null
  readonly height: number | undefined | null
  readonly originalHeight: number | undefined | null
  readonly originalWidth: number | undefined | null
  readonly originalSizeUrl: string | undefined | null
}

export default function ImageComponent({
  url,
  width,
  height,
  originalSizeUrl,
  originalWidth,
  originalHeight
}: ImageComponentProps) {
  const [isFullScreenModalVisible, setIsFullScreenModalVisible] = useState(false)

  function showFullScreenModal() {
    setIsFullScreenModalVisible(true)
  }

  function closeFullScreenModal() {
    setIsFullScreenModalVisible(false)
  }

  return (
    <>
      <img
        alt=""
        className={styles.image}
        src={url}
        onClick={showFullScreenModal}
      />
      <CenterModalWindowComponent
        isVisible={isFullScreenModalVisible}
        onCloseRequested={closeFullScreenModal}
      >
        <ZoomAreaComponent
          onClick={closeFullScreenModal}
          originalHeight={originalHeight ?? height}
          originalWidth={originalWidth ?? width}
        >
          <img
            alt=""
            src={originalSizeUrl ?? url}
          />
        </ZoomAreaComponent>
      </CenterModalWindowComponent>
    </>
  )
}
