import { GetObjectsPageResult } from "../../../../../sqadmin/features/objects/domain/results/GetObjectsPageResult"
import Sort from "../../../../../sqadmin/features/objects/presentation/entities/tables/Sort"
import LastItemPagination from "../../../../core/domain/entities/paginations/LastItemPagination"
import DiscountRulesRepository from "../repositories/DiscountRulesRepository"
import DiscountRule from "../../../../core/domain/entities/discount-rules/DiscountRule"
import DiscountRulesFilter from "../../../../core/domain/entities/discount-rules/DiscountRulesFilter"

export default class GetDiscountRulesUseCase {
  private readonly discountRulesRepository: DiscountRulesRepository

  constructor(parameters: {
    readonly discountRulesRepository: DiscountRulesRepository
  }) {
    this.discountRulesRepository = parameters.discountRulesRepository
  }

  async call(parameters: GetDiscountRulesParameters): Promise<GetObjectsPageResult<DiscountRule>> {
    return this.discountRulesRepository.getDiscountRules(parameters)
  }
}

export interface GetDiscountRulesParameters {
  readonly filter?: DiscountRulesFilter
  readonly sort?: Sort
  readonly pagination?: LastItemPagination
}
