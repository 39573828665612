import FormField, { FormFieldParameters, FormFieldViewState, FormFieldViewStateParameters } from "../FormField"
import { CreateObjectResult } from "../../../../domain/results/CreateObjectResult"
import ExecutionError from "../../../../../../core/domain/entities/ExecutionError"
import ImageOption from "../../../../../../core/presentation/components/image-picker/ImageOption"

const defaultStateId = "defaultState"

export default class ImageFormField<DomainObject, ErrorsObject, OptionObject>
  extends FormField<DomainObject, ErrorsObject> {
  private readonly getValue: (object: DomainObject) => OptionObject | null | undefined
  private readonly setValue: (object: DomainObject, value: OptionObject | null) => DomainObject
  private readonly getOptionsExistAndLoading?: (object: DomainObject) => boolean | null | undefined
  private readonly getOptions: (optionObject: OptionObject) => ImageOption[] | null | undefined
  private readonly createObject: CreateObjectFunction<OptionObject>
  private isUploadingFileByStateId: { [key: string]: boolean }
  private filesByStateId: { [key: string]: File | undefined }
  private hasCreatingObjectErrorsByStateId: { [key: string]: boolean }

  constructor(parameters: FormFieldParameters<DomainObject, ErrorsObject> & {
    readonly getValue: (object: DomainObject) => OptionObject | null | undefined
    readonly setValue: (object: DomainObject, value: OptionObject | null) => DomainObject
    readonly getOptions: (optionObject: OptionObject) => ImageOption[] | null | undefined
    readonly createObject: CreateObjectFunction<OptionObject>
    readonly getOptionsExistAndLoading?: (object: DomainObject) => boolean | null | undefined
  }) {
    super(parameters)
    this.getValue = parameters.getValue
    this.setValue = parameters.setValue
    this.getOptions = parameters.getOptions
    this.getOptionsExistAndLoading = parameters.getOptionsExistAndLoading
    this.createObject = parameters.createObject
    this.isUploadingFileByStateId = {}
    this.filesByStateId = {}
    this.hasCreatingObjectErrorsByStateId = {}
  }

  getViewState(object: DomainObject, errorsObject?: ErrorsObject): FormFieldViewState {
    const image: OptionObject | null | undefined = this.getValue(object)
    const imageOptions = image ? this.getOptions(image) : null
    const stateId: string = this.getObjectId ? this.getObjectId(object) : defaultStateId
    const isUploadingFile: boolean = this.isUploadingFileByStateId[stateId] ?? false
    const hasCreatingObjectErrors: boolean = this.hasCreatingObjectErrorsByStateId[stateId] ?? false

    const createObject = async(): Promise<void> => {
      this.isUploadingFileByStateId = { ...this.isUploadingFileByStateId, [stateId]: true }
      this.hasCreatingObjectErrorsByStateId = { ...this.hasCreatingObjectErrorsByStateId, [stateId]: false }
      this.setAndShowLoadedObjectViewState()

      const file: File = this.filesByStateId[stateId]!
      const result: CreateObjectResult<OptionObject, ExecutionError> =
        await this.createObject({ file: file! })

      switch (result.type) {
        case "error":
        case "failure":
          this.hasCreatingObjectErrorsByStateId = { ...this.hasCreatingObjectErrorsByStateId, [stateId]: true }
          break
        case "success":
          this.setObject(this.setValue(object, result.data))
          break
      }

      this.isUploadingFileByStateId = { ...this.isUploadingFileByStateId, [stateId]: false }
      this.setAndShowLoadedObjectViewState()
    }

    return new ImageFormFieldViewState({
      ...this.getFormFieldViewStateParameters(object, errorsObject),
      imageOptions: imageOptions,
      isUploadingFile: isUploadingFile,
      hasErrors: hasCreatingObjectErrors,
      imageOptionsExistAndLoading: this.getOptionsExistAndLoading?.(object),
      onChange: (value: File | null | undefined) => {
        this.filesByStateId = { ...this.filesByStateId, [stateId]: value ?? undefined }

        if (value === null) {
          this.setObject(this.setValue(object, null))
          this.setAndShowLoadedObjectViewState()
        } else {
          createObject().then()
        }
      }
    })
  }
}

export class ImageFormFieldViewState extends FormFieldViewState {
  readonly isUploadingFile: boolean
  readonly hasErrors: boolean
  readonly imageOptionsExistAndLoading: boolean
  readonly imageOptions: ImageOption[] | null | undefined
  onChange: (value: File | null | undefined) => void

  constructor(parameters: FormFieldViewStateParameters & {
    readonly onChange: (value: File | null | undefined) => void
    readonly imageOptions: ImageOption[] | null | undefined
    readonly isUploadingFile: boolean | null | undefined
    readonly hasErrors: boolean | null | undefined
    readonly imageOptionsExistAndLoading: boolean | null | undefined
  }) {
    super(parameters)
    this.imageOptions = parameters.imageOptions
    this.isUploadingFile = parameters.isUploadingFile ?? false
    this.hasErrors = parameters.hasErrors ?? false
    this.imageOptionsExistAndLoading = parameters.imageOptionsExistAndLoading ?? false
    this.onChange = parameters.onChange
  }
}

export interface CreateObjectParameters {
  readonly file: File
}

export type CreateObjectFunction<OptionObject> =
  (parameters: CreateObjectParameters) => Promise<CreateObjectResult<OptionObject, ExecutionError>>
