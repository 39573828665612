import { Expose } from "class-transformer"
import NetworkPaymentStatus from "../../../../core/data/entities/payment-statuses/NetworkPaymentStatus"

export default class NetworkPaymentStatusRequestBody {
  @Expose()
  readonly paymentStatus: NetworkPaymentStatus

  constructor(parameters: {
    readonly paymentStatus: NetworkPaymentStatus
  }) {
    this.paymentStatus = parameters.paymentStatus
  }
}
